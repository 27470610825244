import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-chip',
    template: `
        <div
            class="flex w-max items-center justify-center gap-x-1 rounded-md bg-transparent px-2 py-1 text-xs font-medium capitalize"
            style="border: 1px solid ;"
            [ngStyle]="{ color: color, 'border-color': color }"
        >
            <ng-container *ngIf="icon"><i class="ph-bold text-base" [ngClass]="icon"></i></ng-container>
            {{ text }}
        </div>
    `,
    styles: [':host { display: block; }'],
})
export class ChipComponent {
    @Input() text: string = '';
    @Input() color?: string;
    @Input() icon?: string;
}
