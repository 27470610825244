import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';
import { ChatConfig, ChatsService } from 'src/app/services/chat.service';
import { SessionService } from 'src/app/services/session.service';
import { Tools } from 'src/app/tools';
import { environment } from 'src/environments/environment';

type Message = {
    id: number;
    name: string;
    files: File[];
    parent_id: number | null;
};

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
    @Input() config: ChatConfig;

    message = new UntypedFormControl('');
    messages: any[] = [];
    messageText: string;
    filter: any;
    chattingWith: any;
    user: any;

    subscriptions: Subscription[] = [];

    defaultImageUrl: string = '/assets/blank-profile.png';

    storageUrl: string = environment.storageUrl;

    @ViewChild('textArea') textArea: ElementRef;

    textAreaLimit: number = 300;

    isLoading: boolean = true;

    constructor(
        private chatService: ChatsService,
        private socket: Socket,
        private session: SessionService,
        private renderer: Renderer2,
    ) {
        this.subscriptions.push(
            this.chatService.getMessage().subscribe((message) => {
                if (this.config.room_id === message.room_id) {
                    this.messages.push(message);
                }
            }),
        );
        this.subscriptions.push(
            this.chatService.getSign().subscribe((data) => {
                if (this.config.room_id === data.room) {
                    this.messages = data.history || [];
                }
                this.isLoading = false;
            }),
        );
    }

    ngOnInit() {
        this.user = this.session.getCurrentUser();
        this.socket.connect();
        this.signInChat();
    }

    onInput() {
        const textArea = this.textArea.nativeElement;
        textArea.rows = textArea.value.length / 30 + 1;
    }

    signInChat() {
        this.socket.emit('sign_in', {
            ...this.config,
        });
    }

    ngOnDestroy() {
        this.socket.disconnect({
            ...this.config,
        });

        Tools.unsubscribeAll(this.subscriptions);
    }

    sendMessage() {
        if (this.message.value.length > 0) {
            this.chatService.sendMessage({
                message: this.message.value,
                ...this.config,
            });

            this.message.setValue('');
            const textArea = this.textArea.nativeElement;
            textArea.rows = 1;
        }
    }

    keyDownFunction(event) {
        if (event.keyCode === 13) {
            event.preventDefault(); // Prevent default behavior
            this.sendMessage();
        }
    }
}
