import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { MiniGamesService } from 'src/app/services/mini-games.service';
import { ManageMiniGamesTable } from 'src/app/tables/ManageMiniGamesTable';
import { Tools } from 'src/app/tools';

@Component({
    selector: 'app-manage-mini-games',
    templateUrl: './manage-mini-games.component.html',
    styleUrls: ['./manage-mini-games.component.css'],
})
export class ManageMiniGamesComponent implements OnInit {
    ENTRY_NAME: string = 'Game';

    miniGames: any[] = [];
    isLoading: boolean = false;
    storageUrl: string = environment.storageUrl;

    table = new ManageMiniGamesTable();

    constructor(
        private miniGamesService: MiniGamesService,
        private notificationService: NotificationService,
        public dialog: MatDialog,
        public sessionService: SessionService
    ) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.miniGamesService.getAll().subscribe((response: any) => {
            this.miniGames = response.data || [];
        });
    }

    create(data: any) {
        this.miniGamesService
            .create(Tools.getFormData(data))
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' created successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    update(data: any) {
        this.miniGamesService
            .update(data.id, Tools.getFormData(data))
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' updated successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    delete(data: any) {
        this.miniGamesService
            .delete(data.id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' deleted successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }
}
