import { Validators } from '@angular/forms';
import { Table } from './Table';

export class ManageDisciplinesTable extends Table {
    constructor() {
        super();
        this.buildColumns();
        this.buildForm();
    }

    buildColumns() {
        this.columns = [
            {
                name: 'ID',
                selector: 'id',
            },
            {
                name: 'Image',
                selector: 'image_path',
                type: 'image',
                headerClass: 'text-center',
                class: () => 'text-center',
            },
            {
                name: 'Name',
                selector: 'name',
            },
            {
                name: 'Info',
                selector: 'info',
            },
        ];
    }

    buildForm(): void {
        this.form = {
            title: () => 'Discipline',
            elements: [
                {
                    name: 'ID',
                    selector: 'id',
                    type: 'text',
                    visible: false,
                },
                {
                    name: 'Image',
                    selector: 'image_path',
                    type: 'image',
                    validators: [Validators.required],
                    class: 'col-span-12',
                },
                {
                    name: 'Name',
                    selector: 'name',
                    type: 'text',
                    class: 'col-span-12',
                    validators: [Validators.required],
                },
                {
                    name: 'Info',
                    selector: 'info',
                    class: 'col-span-12',
                    type: 'text',
                },
            ],
        };
    }
}
