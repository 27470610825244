import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { SchoolsService } from 'src/app/services/schools.service';
import { SessionService } from 'src/app/services/session.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
    selector: 'app-user-info-form',
    templateUrl: './user-info-form.component.html',
    styleUrls: ['./user-info-form.component.scss'],
})
export class UserInfoFormComponent {
    @Output() closed: EventEmitter<any> = new EventEmitter();

    infoFormGroup: UntypedFormGroup;
    grades: string[] = [];

    constructor(
        private notificationService: NotificationService,
        private _formBuilder: UntypedFormBuilder,
        private sessionService: SessionService,
        private schoolsService: SchoolsService,
        private userService: UsersService,
    ) {}

    ngOnInit() {
        this.getSchoolGrades();
        const currentUser = this.sessionService.getCurrentUser();
        this.infoFormGroup = this._formBuilder.group({
            nickname: [currentUser.nickname || '', Validators.required],
            grade: [currentUser.grade || '', Validators.required],
            gender: [currentUser.gender || '', Validators.required],
            birthday: [currentUser.birthday, [this.dateValidator]],
        });
    }

    dateValidator(control: UntypedFormControl): { [key: string]: any } | null {
        const inputDate = new Date(control.value);

        if (isNaN(inputDate.getTime())) {
            control.setErrors({ invalid: true });
            return { invalid: true };
        }

        return null;
    }

    getSchoolGrades() {
        this.schoolsService.getSchoolGrades().subscribe((response: any) => {
            this.grades = response.data.grades.map((grade: string) => ({ name: grade, value: grade })) || [];
        });
    }

    submitStep() {
        this.saveUserData();
    }

    saveUserData() {
        this.userService.updateCurrentUser(this.infoFormGroup.value).subscribe(
            () => {
                this.closed.emit();
            },
            (err: any) => {
                this.notificationService.error(err.error.reason, 'Error');
            },
        );
    }
}
