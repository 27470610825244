<div class="w-full">
    <div class="flex justify-between items-center">
        <app-un-gradient-text [text]="'profile.interests' | translate" size="big"> </app-un-gradient-text>

        <app-un-magic-button
            *ngIf="!user.is_admin"
            [text]="'profile.edit_button' | translate"
            [fullWidth]="true"
            [bordered]="true"
            (click)="goToQuestionary()"
        ></app-un-magic-button>
    </div>

    <app-user-data-info [user]="user" [isProfile]="true"></app-user-data-info>
</div>
