<div class="w-full p-3 roadmap-wrap main-card">
    <span class="card-info">{{ date }}</span>
    <button matRipple class="add-item-btn"><i class="ph-plus-bold"></i></button>

    <div class="w-full roadmap-wrap-header">
        <div>
            <h5 class="roadmap-title">Road map 2022</h5>
        </div>
        <div class="roadmap-wrap-filters flex">
            <span class="roadmap-wrap-filter"
                ><span class="date-span" style="font-weight: 600">Show</span>:<span class="ml-1 cursor-pointer"
                    >all teams</span
                >
                <i class="ph-caret-down"></i>
            </span>
            <span class="roadmap-wrap-filter"
                ><span class="date-span" style="font-weight: 600">Timeframe</span>:<span class="ml-1 cursor-pointer"
                    >all time</span
                >
                <i class="ph-caret-down"></i>
            </span>
        </div>
        <div></div>
    </div>

    <div class="gantt">
        <div class="gantt__row gantt__row--months">
            <div class="gantt__row-first"></div>
            <ng-container *ngFor="let day of days">
                <span [class.current]="isCurrentDay(day)">{{ day }}</span>
            </ng-container>
        </div>

        <div class="gantt__row gantt__row--lines">
            <ng-container *ngFor="let day of days">
                <span [class.current]="isCurrentDay(day)"></span>
            </ng-container>
        </div>

        <div class="gantt__row">
            <div class="gantt__row-first">Jerusalem student team</div>
            <ul class="gantt__row-bars">
                <li style="grid-column: 1/3; background: rgba(203, 118, 179, 0.3)">First scenario dra...</li>
                <li
                    style="
                        grid-column: 4/11;
                        background: linear-gradient(270deg, rgba(142, 145, 209, 0.3) 0%, rgba(159, 141, 208, 0.3) 100%);
                    "
                >
                    Even longer project
                </li>
            </ul>
        </div>

        <div class="gantt__row">
            <div class="gantt__row-first">
                <span>Hula pupils team</span>
            </div>
            <ul class="gantt__row-bars">
                <li style="grid-column: 1/6; background: rgba(203, 118, 179, 0.3)">
                    Scenario draft discussion and finalization of the general scenario
                </li>
                <li
                    style="
                        grid-column: 7/11;
                        background: linear-gradient(270deg, rgba(142, 145, 209, 0.3) 0%, rgba(159, 141, 208, 0.3) 100%);
                    "
                >
                    Start working on presentation
                </li>
                <li style="grid-column: 3/8; background: rgba(255, 249, 107, 0.4)">Check proposed game solutions</li>
            </ul>
        </div>
    </div>
</div>
