import { environment, settings } from 'src/environments/environment';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Tools } from 'src/app/tools';
import { flyBottomIn, flyInOut, flyRigthIn } from 'src/app/animations/fly-animations';

@Component({
    selector: 'app-studio-manage-project',
    templateUrl: './studio-manage-project.component.html',
    styleUrls: ['./studio-manage-project.component.scss'],
    providers: [],
    animations: [flyInOut, flyBottomIn, flyRigthIn],
})
export class StudioManageProjectComponent implements AfterViewInit {
    unityInstance: any;

    ASSETS_PREFIX: string = 'UnispherTools.';

    TOOLS_PATH: string = environment.unispherToolUrl;
    TOOLS_SCRIPT_PATH: string = this.TOOLS_PATH + 'Build/' + this.ASSETS_PREFIX + 'loader.js';

    isReady = false;
    progress: 0;

    tooltipOptions = settings.tooltipOptions;

    dataUrl = '/assets/studio/manage-project-unity';

    selectedGame: any;

    @ViewChild('ucontainer', { static: false }) container: ElementRef;
    @ViewChild('ucanvas', { static: false }) canvas: ElementRef;
    @ViewChild('uloading', { static: false }) loadingBar: ElementRef;
    @ViewChild('uprogress', { static: false }) progressBarFull: ElementRef;
    @ViewChild('ufullscreen', { static: false }) fullscreenButton: ElementRef;
    @ViewChild('umobile', { static: false }) mobileWarning: ElementRef;

    constructor(private router: Router) {}

    ngAfterViewInit() {
        Tools.loadScripts(this.TOOLS_SCRIPT_PATH).subscribe(
            (result: any) => {
                setTimeout(() => {
                    const container = document.getElementById('unity-container');
                    const canvas = document.getElementById('unity-canvas');
                    const loadingBar = document.getElementById('unity-loading-bar');
                    const progressBarFull = document.getElementById('unity-progress-bar-full');
                    const fullscreenButton = document.getElementById('unity-fullscreen-button');
                    const unityContainerBase = document.querySelector('.grid-system-container-unity') as HTMLElement;

                    const buildUrl = this.TOOLS_PATH + 'Build/';
                    const config = {
                        dataUrl: buildUrl + this.ASSETS_PREFIX + 'data.unityweb',
                        frameworkUrl: buildUrl + this.ASSETS_PREFIX + 'framework.js.unityweb',
                        codeUrl: buildUrl + this.ASSETS_PREFIX + 'wasm.unityweb',
                        streamingAssetsUrl: '/StreamingAssets',
                        companyName: 'Unispher',
                        productName: 'UnispherTools',
                        productVersion: '1.0',
                    };

                    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                        if (container) {
                            container.className = 'unity-mobile';
                        }
                    } else {
                        if (canvas) {
                            // canvas.style.width = unityContainerBase.offsetWidth + 'px';
                            // canvas.style.height = unityContainerBase.offsetHeight + 'px';
                        }
                    }

                    if (canvas && loadingBar) {
                        canvas.style.background = `url(${buildUrl}/WEBGL_BLOOM.jpg) center / cover`;
                        loadingBar.style.display = 'block';
                    }

                    createUnityInstance(canvas, config, (progress: any) => {
                        this.progress = progress;
                        if (progressBarFull) {
                            progressBarFull.style.width = 100 * progress + '%';
                        }
                    })
                        .then((unityInstance: any) => {
                            this.isReady = true;
                            this.unityInstance = unityInstance;

                            if (loadingBar) {
                                loadingBar.style.display = 'none';
                            }

                            if (fullscreenButton) {
                                fullscreenButton.onclick = () => {
                                    unityInstance.SetFullscreen(1);
                                };
                            }
                        })
                        .catch((message: any) => {
                            alert(message);
                        });
                }, 600);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    close() {
        this.unityInstance.Quit();
        this.router.navigate(['/studio']).then(() => {
            // window.location.reload();
        });
    }

    setFullScreen() {
        this.unityInstance.SetFullscreen(1);
    }

    ngOnDestroy() {
        window.location.reload();
    }
}
