import { settings, environment } from 'src/environments/environment';
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-team-squad',
    templateUrl: './team-squad.component.html',
    styleUrls: ['./team-squad.component.scss'],
    providers: [],
})
export class TeamSquadComponent implements OnInit {
    isLoading: boolean = false;

    teammates: any[] = [
        {
            id: 1,
            name: 'Sofi Heart',
            image: '/assets/rust.png',
            role: 'user',
        },
        {
            id: 2,
            name: 'Sofi Heart',
            image: '/assets/rust.png',
            role: 'user',
        },
        {
            id: 3,
            name: 'Sofi Heart',
            image: '/assets/rust.png',
            role: 'user',
        },
    ];

    users = new UntypedFormControl();

    usersList: string[] = ['Alex', 'Josh', 'Leon', 'Pedro'];

    @Output() teammateSelected: EventEmitter<any> = new EventEmitter();

    constructor(
        public dialog: MatDialog,
        private notificationService: NotificationService,
        public translate: TranslateService
    ) {}

    ngOnInit() {}

    openModal(dialogRef: TemplateRef<any>) {
        this.users.reset();
        this.dialog.open(dialogRef, { autoFocus: false });
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    addTeammate() {
        this.isLoading = true;

        const index = this.usersList.findIndex((name: string) => name === this.users.value);
        delete this.usersList[index];
        this.usersList = this.usersList.filter((n) => n);

        this.closeDialog();

        setTimeout(() => {
            this.teammates.push(this.getFakeUser(this.users.value));
            this.isLoading = false;
            this.notificationService.success('Teammate ' + this.users.value + ' added successfully');
            this.users.reset();
        }, 400);
    }

    getFakeUser(name: any) {
        return {
            id: this.teammates.length + 1,
            name: name,
            image: '/assets/rust.png',
            role: 'user',
        };
    }

    selectTeammate(teammate: any) {
        this.teammateSelected.emit(teammate);
    }
    get ADD_SUCCESS_TOASTR() {
        return this.translate.instant('communications.section.add_success_toastr');
    }
}
