<div class="default-theme" [ngClass]="getClasses()">
    <app-navbar *ngIf="loginService.isLoggedIn()" [simplified]="!simplified"></app-navbar>

    <main>
        <app-sidebar *ngIf="loginService.isLoggedIn() && simplified"></app-sidebar>

        <div class="pages w-full p-2 md:p-4 lg:px-6 un-scroll">
            <router-outlet></router-outlet>
        </div>
        <div class="overlay" (click)="toggleSidebar()"></div>
    </main>
</div>

<!--
<div
    class="flex flex-col h-screen"
    style="background: radial-gradient(50% 50% at 50% 50%, #4222a5 0%, rgba(40, 45, 191, 0) 100%)"
>
    <nav class="px-4 py-2">
        <app-navbar *ngIf="loginService.isLoggedIn()" [simplified]="!simplified"></app-navbar>
    </nav>
    <div class="flex flex-1">
        <div class="p-4">
            <app-sidebar *ngIf="loginService.isLoggedIn() && simplified"></app-sidebar>
        </div>
        <main class="flex-1 p-4 pages">
            <router-outlet></router-outlet>
        </main>
    </div>
</div> -->
