import { Validators } from '@angular/forms';
import { Form } from './Form';

export class GenerateTeamForm extends Form {
    elements = [];
    title: () => '';

    buildForm(topics: any): void {
        this.form = {
            title: (): string => '',
            elements: [
                {
                    name: 'Select project',
                    selector: 'project_id',
                    class: 'col-span-12',
                    type: 'select',
                    options: topics,
                    optionValueSelector: 'id',
                    optionTextSelector: 'title',
                    validators: [Validators.required],
                },
            ],
        };
    }
}
