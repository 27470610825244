import { Component, OnInit } from '@angular/core';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { environment } from 'src/environments/environment';

interface Institution {
    id?: number;
    image_path: string | null;
    name: string;
    info: string | null;
}

@Component({
    selector: 'app-institutions',
    templateUrl: './institutions.component.html',
    styleUrls: ['./institutions.component.scss'],
    providers: [],
})
export class InstitutionsComponent implements OnInit {
    isLoading: boolean = false;

    institutions: any[] = [];
    storageUrl: string = environment.storageUrl;

    constructor(private institutionsService: InstitutionsService) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.institutionsService.getAll().subscribe((response: any) => {
            this.institutions = response.data;
        });
    }
}
