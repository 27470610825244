import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'time-left-translate',
})
export class TimeLeftTranslatePipe implements PipeTransform {
    constructor(public translate: TranslateService) {}
    transform(value: string) {
        if (!value) {
            return '';
        }

        return this.translate.instant('ui.time_items' + value);
    }
}
