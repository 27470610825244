import { Component, ContentChildren, QueryList, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { TabImageComponent } from './tab-image.component';

@Component({
    selector: 'app-tabs-image',
    template: `
        <ul class="flex gap-0.5">
            <li
                *ngFor="let tab of tabs"
                (click)="selectTab(tab)"
                [ngClass]="{ 'bg-custom-gradient': tab.active }"
                class="cursor-pointer rounded-t-lg border border-gray-600/25 border-b-transparent px-2 py-2"
            >
                <span
                    [ngClass]="{
                        'border-b-2 border-b-[#FF02D0] text-[#FF02D0]': tab.active,
                        'text-[#8E91D1]': !tab.activate
                    }"
                    >{{ tab.title }}</span
                >
            </li>
        </ul>
        <ng-content></ng-content>
    `,
})
export class TabsImageComponent implements AfterContentInit {
    @ContentChildren(TabImageComponent) tabs: QueryList<TabImageComponent>;

    @Output() tabChanged: EventEmitter<any> = new EventEmitter();

    // contentChildren are set
    ngAfterContentInit() {
        // get all active tabs
        let activeTabs = this.tabs.filter((tab) => tab.active);

        // if there is no active tab set, activate the first
        if (activeTabs.length === 0) {
            this.selectTab(this.tabs.first);
        }
    }

    selectTab(tab: any) {
        // deactivate all tabs
        this.tabs.toArray().forEach((tab) => (tab.active = false));

        // activate the tab the user has clicked on.
        tab.active = true;
    }
}
