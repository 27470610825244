<app-card class="mb-3 h-full">
    <div class="flex mb-3">
        <div class="text-xl mb-3 font-medium">{{ ENTRY_NAME }}</div>

        <div class="ml-auto justify-end">
            <app-pre-users-select
                [users]="teachers"
                [loading]="invitesLoading"
                (usersUpdated)="sendInvites($event)"
            ></app-pre-users-select>
            <button mat-button color="accent" (click)="openDialog(uploadFormTemplate)">
                <i class="ph-upload-simple text-large"></i>
            </button>
        </div>
    </div>

    <div>
        <app-table
            [columns]="table.columns"
            [data]="teachers"
            [form]="table.form"
            [create]="true"
            (onCreate)="create($event)"
            [edit]="true"
            (onEdit)="update($event)"
            [delete]="true"
            (onDelete)="delete($event)"
            [deletionConfirm]="true"
            [itemsPerPage]="usersPerPage"
            [dialogConfig]="{ width: '450px' }"
        >
        </app-table>
    </div>
</app-card>

<ng-template #invitationTemplate let-user="user">
    <app-send-invite [user]="user" [roleId]="TEACHER_ROLE_ID"></app-send-invite>
</ng-template>

<ng-template #uploadFormTemplate>
    <div mat-dialog-content style="width: 400px">
        <app-table-form
            #uploadModalForm
            [form]="uploadForm"
            [formElements]="uploadForm.form.elements"
            (onSubmit)="upload($event)"
        ></app-table-form>
    </div>
    <div mat-dialog-actions class="row justify-between">
        <div class="col-auto"></div>
        <div class="col-auto">
            <button mat-flat-button color="primary" class="mr-2" (click)="closeDialog()">Cancel</button>
            <button mat-flat-button color="accent" (click)="upload(uploadModalForm.getValue())">Submit</button>
        </div>
    </div>
</ng-template>
