<!-- <div class="w-full">
    <ngx-file-drag-drop [formControl]="file" activeBorderColor="#FF02D0" style="width: 100%;"></ngx-file-drag-drop>
</div> -->

<!-- <div class="mt-6 flex-1 text-center">
    <button *ngIf="!imgURL" type="button" class="block mx-auto text-center" mat-raised-button
      (click)="file.click()" style="width: 100px;"><i class="fas fa-image"></i></button>
    <img [src]="imgURL" height="200" *ngIf="imgURL" class="mt-1 cursor-pointer image-preview"
      (click)="file.click()">
      <span *ngIf="imageError" class="mat-error">Please fill in</span>
  </div> -->

<input hidden #file type="file" accept="image/*" (change)="preview(file.files)" />

<ng-container *ngIf="!imgURL">
    <i class="ph-camera" (click)="file.click()"></i>
</ng-container>

<ng-container>
    <!-- <img [src]="imgURL" height="200" *ngIf="imgURL" class="mt-1 cursor-pointer image-preview" (click)="file.click()"> -->
    <div
        *ngIf="imgURL"
        class="w-full h-full image-preview cursor-pointer"
        [ngStyle]="{ 'background-image': 'url(' + imgURL + ')' }"
        (click)="file.click()"
    ></div>
</ng-container>
