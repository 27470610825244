import { environment } from 'src/environments/environment';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { VotesTopicsService } from 'src/app/services/votes-topics.service';
import { style, trigger, state, transition, animate } from '@angular/animations';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Tools } from 'src/app/tools';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

const ANIMATION_DURATION = 400;

@Component({
    selector: 'app-suggest-theme',
    templateUrl: './suggest-theme.component.html',
    styleUrls: ['./suggest-theme.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('in', style({ opacity: 1, transform: 'translateY(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateY(-100%)' }),
                animate(ANIMATION_DURATION),
            ]),
            transition('* => void', [
                animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateY(100%)' })),
            ]),
        ]),
        trigger('flyleftIn', [
            state('in', style({ opacity: 1, transform: 'translateX(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateX(-100%)' }),
                animate(ANIMATION_DURATION),
            ]),
            transition('* => void', [
                animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateX(100%)' })),
            ]),
        ]),
        trigger('flyRigthIn', [
            state('in', style({ opacity: 1, transform: 'translateX(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateX(100%)' }),
                animate(ANIMATION_DURATION),
            ]),
            transition('* => void', [
                animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateX(-100%)' })),
            ]),
        ]),
        trigger('flyBottomIn', [
            state('in', style({ opacity: 1, transform: 'translateY(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateY(100%)' }),
                animate(ANIMATION_DURATION),
            ]),
            transition('* => void', [
                animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateY(-100%)' })),
            ]),
        ]),
    ],
})
export class SuggestThemeComponent implements OnInit {
    @Output() backTrigerred: EventEmitter<any> = new EventEmitter<any>();
    @Output() themeSelected: EventEmitter<any> = new EventEmitter<any>();

    storageUrl: string = environment.storageUrl;

    themeForm = new UntypedFormGroup({
        title: new UntypedFormControl('', [Validators.required]),
        description: new UntypedFormControl('', [Validators.required]),
        type: new UntypedFormControl('custom'),
        image: new UntypedFormControl(''),
    });

    constructor(
        private notificationService: NotificationService,
        private votesService: VotesTopicsService,
        public translate: TranslateService
    ) {}

    ngOnInit() {}

    onSubmit() {
        this.votesService.create(Tools.getFormData(this.themeForm.value)).subscribe(
            (response: any) => {
                this.notificationService.success(this.VOTE_SUBMIT_TOASTR);
                // this.goBack();
                this.themeSelected.emit(response.data);
            },
            (err) => {
                this.notificationService.error(err.error.message, this.ERROR_SUBMIT_TOASTR);
            }
        );
    }

    setImage(image: any) {
        this.themeForm.get('image')?.setValue(image);
    }

    goBack() {
        this.backTrigerred.emit();
    }

    get VOTE_SUBMIT_TOASTR() {
        return this.translate.instant('questionnaire.suggest.vote_toastr');
    }

    get ERROR_SUBMIT_TOASTR() {
        return this.translate.instant('questionnaire.suggest.error_toastr');
    }
}
