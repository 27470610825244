<!-- <div class="gradient-border"><img class="slider-placeholder" src="/assets/games-slider/3.jpg" alt=""></div>


<section id="slider">

    <i class="fas fa-chevron-left fa-3x slider-arrows l"></i>
    <i class="fas fa-chevron-right fa-3x slider-arrows r"></i>

    <ng-container *ngFor="let item of slides">
        <input type="radio" name="slider" id="s{{item.id}}" [checked]="item.selected">
    </ng-container>

    <ng-container *ngFor="let item of slides">
        <label for="s{{item.id}}" id="slide{{item.id}}" (click)="slideChange(item)">

            <ng-container [ngSwitch]="item.type">

                <ng-container *ngSwitchCase="'image'">
                    <img [src]="item.src">
                </ng-container>

                <video *ngSwitchCase="'video'" #videoPlayer id="game-video" width="100%" height="100%" muted>
                    <source [src]="item.src" type="video/mp4">
                    Your browser does not support HTML video.
                </video>

                <ng-container *ngIf="!paused">
                    <span class="play-btn" (click)="playPause()"><i class="fas fa-pause"></i></span>
                </ng-container>

                <ng-container *ngIf="paused">
                    <span class="play-btn" (click)="playPause()"><i class="fas fa-play"></i></span>
                </ng-container>

            </ng-container>

        </label>
    </ng-container>

</section> -->

<div class="main-card">
    <div class="px-3 py-2 flex items-center" style="gap: 18px">
        <div class="flex" (click)="openDialog(videoModal)">
            <div class="play cursor-pointer"><i class="ph-play"></i></div>
        </div>
        <div>
            <div class="mb-0">
                <span class="name text-large">Unispher</span>
            </div>
            <div class="student-level-bage">
                <span class="position" style="color: #ff02d0">{{ 'games.game_studio' | translate }}</span>
            </div>
        </div>
    </div>
</div>

<ng-template #videoModal>
    <div mat-dialog-content style="width: 600px">
        <video #videoPlayer id="game-video" width="100%" height="100%" muted controls>
            <source [src]="'/assets/videos/preview-game.mp4'" type="video/mp4" />
            Your browser does not support HTML video.
        </video>
    </div>
</ng-template>
