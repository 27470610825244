<nav class="navbar navbar-expand-lg navbar-dark bg-light fixed top-0 right-0 left-0 z-[1000] print-none header-element">
    <div class="flex align-center">
        <div class="col-auto" style="font-size: 0">
            <a routerLink="/">
                <app-logo></app-logo>
            </a>
        </div>
    </div>

    <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="flex no-gutter items-center w-full justify-between">
            <ng-container>
                <div class="col">
                    <ul class="navbar-nav mr-auto"></ul>
                </div>

                <div class="col-auto">
                    <ul id="right-menu" class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link">
                                <app-search></app-search>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link">
                                Filter
                                <i class="fas fa-sort-amount-up"></i>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [matMenuTriggerFor]="userMenu">
                                <i class="far fa-user-circle fa-lg"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </ng-container>
        </div>
    </div>
    <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
    >
        <span class="navbar-toggler-icon"></span>
    </button>
</nav>

<mat-menu #userMenu="matMenu">
    <button mat-menu-item (click)="logOut()"><i class="fas fa-sign-out-alt p-2"></i>Logout</button>
    <button mat-menu-item (click)="navigateMenu()"><i class="fas fa-user-edit p-2"></i>Edit</button>
</mat-menu>
