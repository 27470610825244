import { TableColumn } from 'src/app/ui/table/table.component';
import { FcbForm } from '../ui/table/table-form/table-form.component';

export class Table {
    columns: TableColumn[] = [];

    form: FcbForm = {
        title: () => '',
        elements: [],
    };
}
