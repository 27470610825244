<div class="mx-auto">
  <div style="max-width: 100%;">
    <mat-tab-group mat-align-tabs="center">
      <mat-tab>

        <ng-template mat-tab-label>
          <i class="far fa-user-circle blue-icon"></i>
          <span class="tab-name">{{ 'communications.section.personal' | translate }}</span>
        </ng-template>

        <app-com-personal-info></app-com-personal-info>

      </mat-tab>

      <mat-tab>

        <ng-template mat-tab-label>
          <i class="fas fa-user-friends blue-icon"></i>
          <span class="tab-name">{{ 'communications.section.team' | translate }}</span>
        </ng-template>

        <app-team-section></app-team-section>

      </mat-tab>

      <mat-tab>

        <ng-template mat-tab-label>
          <i class="fas fa-briefcase blue-icon"></i>
          <span class="tab-name">{{ 'communications.section.school' | translate }}</span>
        </ng-template>

       <app-school-section></app-school-section>

      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <i class="far fa-hand-paper blue-icon"></i>
          <span class="tab-name">{{ 'communications.section.network' | translate }}</span>
        </ng-template>

        <app-network-section></app-network-section>

      </mat-tab>

    </mat-tab-group>
  </div>
</div>