import { Component, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-un-back-button',
  templateUrl: './un-back-button.component.html',
  styleUrls: ['./un-back-button.component.scss']
})
export class UnBackButtonComponent {


  @Output() сlicked: EventEmitter<any> = new EventEmitter();


  emitClick() {
    this.сlicked.emit();
  }

}
