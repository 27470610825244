import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class TeamsService {
    public API: string = '/teams';

    private changeTeamSource = new Subject<any>();
    changeTeam$ = this.changeTeamSource.asObservable();

    constructor(private http: HttpClient) {}

    getAll() {
        return this.http.get(new Url(this.API).path);
    }

    getById(id: number) {
        return this.http.get(new Url(this.API + '/' + id).path);
    }

    create(data: any) {
        return this.http.post(environment.apiUrl + this.API, data);
    }

    update(id: number, data: any) {
        return this.http.put(environment.apiUrl + this.API + '/' + id, data);
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + this.API + '/' + id);
    }

    getTeamStats(id: number) {
        return this.http.get(new Url(this.API + '/' + id + '/stats').path);
    }

    getTeamsByProjectId(id: number) {
        return this.http.get(new Url(this.API + '/project/' + id).path);
    }

    getTeamProfile(teamId: number) {
        return this.http.get(new Url(this.API + '/' + teamId + '/profile').path);
    }

    getTeamChart(teamId: number) {
        return this.http.get(new Url(this.API + '/' + teamId + '/chart').path);
    }

    generateTeams(data: any) {
        return this.http.post(new Url('/generate-teams').path, data);
    }

    changeStudents(data: any) {
        return this.http.post(new Url('/teams/change').path, data);
    }

    getTeamColors() {
        return this.http.get(environment.apiUrl + '/team-icons');
    }

    changeTeam(teamId: number) {
        this.changeTeamSource.next(teamId);
    }

    getAllowedExtensions(): string[] {
        return ['png', 'jpg'];
    }

    deleteTeamLogo(teamId: number) {
        return this.http.delete(environment.apiUrl + '/team-logo/' + teamId);
    }

    addUserToTeam(team: number, user: number) {
        return this.http.post(new Url('/teams/' + team + '/users/' + user + '/add').path, {});
    }

    deleteUserFromTeam(team: number, user: number) {
        return this.http.delete(new Url('/teams/' + team + '/users/' + user + '/remove').path);
    }
}
