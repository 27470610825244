import { Validators } from '@angular/forms';
import { Form } from './Form';

export class UploadInvitationsForm extends Form {
    elements = [];
    title: () => 'Upload document';

    buildForm(projects): void {
        this.form = {
            title: (): string => 'Upload document',
            elements: [
                {
                    name: 'Select project',
                    selector: 'project_id',
                    class: 'col-span-12',
                    type: 'select',
                    options: projects,
                    optionValueSelector: 'id',
                    optionTextSelector: 'title',
                    validators: [Validators.required],
                },
                {
                    name: 'File',
                    selector: 'file',
                    class: 'col-span-12',
                    type: 'image',
                    validators: [Validators.required],
                },
            ],
        };
    }
}
