import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class PresentationService {
    constructor(private http: HttpClient) {}

    generate() {
        return this.http.post(new Url('/generate-presentation').path, {});
    }

    downloadPPTX(base64Data: any, name: string) {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {
            type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${name}.pptx`;
        link.click();
    }
}
