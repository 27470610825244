<div *ngIf="barOpened" [@enterAnimation] class="friends-bar-wrapper">
    <div>
        <div class="flex justify-between py-2 px-4 items-center">
            <h5 class="m-0">{{ 'friend_bar.friends' | translate }}</h5>
            <span class="cursor-pointer" (click)="close()">
                <img src="/assets/cross.png" alt="" style="width: 14px; height: 14px" />
                <!-- <i class="fas fa-times cursor-pointer fa-2x"></i> -->
            </span>
        </div>
        <hr class="m-0" />
    </div>

    <ng-content></ng-content>
</div>
