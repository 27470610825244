<mat-dialog-actions class="row no-gutters w-full justify-between">
    <div class="col">
        <button mat-flat-button color="warn" class="mr-2" (click)="delete()" *ngIf="showDelete">
            <i class="ph ph-trash"></i>
        </button>
    </div>
    <div class="col-auto">
        <button mat-flat-button color="primary" class="mr-2" (click)="close()">
            <i class="ph ph-arrow-bend-up-left"></i>
        </button>
        <button
            mat-flat-button
            color="accent"
            *ngIf="showSave"
            (click)="save()"
            [disabled]="!isFormValid || loading"
            style="min-width: 94px"
        >
            <i class="ph ph-check-circle"></i>

            <i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
        </button>
    </div>
</mat-dialog-actions>
