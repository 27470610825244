<label *ngIf="label" class="block text-sm font-medium text-gray-500 dark:font-normal dark:text-gray-400">
    <span>{{ label }}</span>
</label>
<div class="flex items-center gap-x-4">
    <div class="w-auto">
        <app-date-input
            class="w-[120px]"
            label="Begin"
            [(ngModel)]="begin"
            (ngModelChange)="update('input')"
            [isDisabled]="disabled"
        >
        </app-date-input>
    </div>

    <div class="w-auto">
        <app-date-input
            label="End"
            class="w-[120px]"
            [(ngModel)]="end"
            (ngModelChange)="update('input')"
            [isDisabled]="disabled"
        >
        </app-date-input>
    </div>
</div>
