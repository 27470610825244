import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class TutorialsService {
    public API: string = '/tutorials';

    constructor(private http: HttpClient) {}

    getByPage(page: string) {
        const url = new Url(this.API);
        url.addQueryParameter('name', page);

        return this.http.get(url.path);
    }
}
