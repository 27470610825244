import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-string-list',
    templateUrl: './string-list.component.html',
    styleUrls: ['./string-list.component.scss'],
})
export class StringListComponent implements OnInit {
    @Input() strings: string[] = [];
    @Output() stringsChange = new EventEmitter<string[]>();
    @Input() title: string = '';

    newString = new UntypedFormControl('');
    inputLimit: number = 200;
    placeholder: string = 'Add a new option';

    ngOnInit() {
        // Ensure strings is always an array, even if the input is null or undefined
        this.strings = this.strings || [];
    }

    addString() {
        if (this.newString.value?.trim() !== '') {
            this.strings.push(this.newString.value.trim());
            this.newString.setValue('');
            this.stringsChange.emit([...this.strings]);
        }
    }

    keyDownFunction(event: any) {
        if (event.keyCode === 13) {
            this.addString();
        }
    }

    removeString(index: number) {
        this.strings.splice(index, 1);
        this.stringsChange.emit([...this.strings]);
    }
}
