import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';
import { flyBottomIn, flyInOut, flyleftIn, flyRigthIn } from 'src/app/animations/fly-animations';
import { MiniGamesService } from 'src/app/services/mini-games.service';
import { environment } from 'src/environments/environment';

interface Game {
    id?: number;
    image_path: string | null;
    authors: any;
    progress: number | null;
    like: boolean;
}

@Component({
    selector: 'app-game-view',
    templateUrl: './game-view.component.html',
    styleUrls: ['./game-view.component.scss'],
    animations: [flyleftIn, flyRigthIn, flyInOut],
})
export class GamesViewComponent {
    isLoading: boolean = true;

    storageUrl: string = environment.storageUrl;

    game: any;

    constructor(
        private notificationService: NotificationService,
        private route: ActivatedRoute,
        private router: Router,
        private miniGamesService: MiniGamesService
    ) {}

    ngOnInit() {
        const gameId = this.route.snapshot.paramMap.get('id');
        if (gameId) {
            this.getMiniGame(parseInt(gameId));
        }
    }

    getMiniGame(gameId: any) {
        this.isLoading = true;
        this.miniGamesService
            .getById(gameId)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe((response: any) => {
                this.game = response.data;
            });
    }

    goToGamePage(gameUrl: string) {
        this.router.navigate(['games/game'], { queryParams: { src: gameUrl } });
    }
}
