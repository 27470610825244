import { Directive, ElementRef, Output, Input, EventEmitter, OnInit, HostListener } from '@angular/core';

@Directive({
    selector: '[appResizable]',
})
export class ResizableDirective {
    private startX: number | undefined;
    private startWidth: number;

    constructor(private el: ElementRef) {}

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        this.startX = event.clientX;
        this.startWidth = this.el.nativeElement.clientWidth;
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (this.startX) {
            const offset = event.clientX - this.startX;
            const newWidth = this.startWidth + offset;
            this.el.nativeElement.style.width = newWidth + 'px';
        }
    }

    @HostListener('mouseup', ['$event'])
    onMouseUp(event: MouseEvent) {
        this.startX = undefined;
    }
}
