<div id="particles-js" class="w-full h-full z-10 fixed"></div>

<div
    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[360px] md:w-[420px] rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 login-card z-10"
>
    <div>
        <div class="p-2 sm:px-8 sm:py-2 border-b border-un-pink">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                <app-logo></app-logo>
            </h1>
        </div>
        <div class="p-2 sm:p-8">
            <ng-content></ng-content>
            <div class="flex flex-col justify-center items-center">
                <p class="text-sm font-light text-gray-500 dark:text-gray-400">Any questions? Please contact us</p>
                <a
                    href="mailto:support@unispher.com"
                    class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                    support&#64;unispher.com
                </a>
            </div>
        </div>
    </div>
</div>
