import { settings } from 'src/environments/environment';
import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Tools } from 'src/app/tools';
import { MiniGamesService } from 'src/app/services/mini-games.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-server-game-container',
    templateUrl: './server-game-container.component.html',
    styleUrls: ['./server-game-container.component.scss'],
})
export class ServerGameContainerComponent implements OnInit, OnDestroy {
    @Input() game: any;

    @Output() gameLoaded: EventEmitter<any> = new EventEmitter();
    @Output() gameClosed: EventEmitter<any> = new EventEmitter();

    isLoading: boolean = false;
    subscriptions: Subscription[] = [];

    gameUrl: string = '';

    folder = environment.serverGamesFolder;
    unityInstance: any;

    ASSETS_PREFIX: string = 'UnispherTools.';

    TOOLS_PATH: string = '';
    TOOLS_SCRIPT_PATH: string = '';

    isReady = false;
    progress: 0;

    tooltipOptions = settings.tooltipOptions;

    dataUrl = '/assets/studio/manage-project-unity';

    @ViewChild('ucontainer', { static: false }) container: ElementRef;
    @ViewChild('ucanvas', { static: false }) canvas: ElementRef;
    @ViewChild('uloading', { static: false }) loadingBar: ElementRef;
    @ViewChild('uprogress', { static: false }) progressBarFull: ElementRef;
    @ViewChild('ufullscreen', { static: false }) fullscreenButton: ElementRef;
    @ViewChild('umobile', { static: false }) mobileWarning: ElementRef;

    constructor(private miniGamesService: MiniGamesService) {
        this.subscriptions.push(
            this.miniGamesService.closeGame$.subscribe(() => {
                this.close();
            })
        );
    }

    ngOnInit() {
        this.setGame();
    }

    setGame() {
        this.TOOLS_PATH = this.folder + this.game.gameUrl;
        this.TOOLS_SCRIPT_PATH = this.TOOLS_PATH + 'Build/loader.js';
    }

    ngAfterViewInit() {
        Tools.loadScripts(this.TOOLS_SCRIPT_PATH).subscribe(
            (result: any) => {
                setTimeout(() => {
                    const container = document.getElementById('unity-container');
                    const canvas = document.getElementById('unity-canvas');
                    const loadingBar = document.getElementById('unity-loading-bar');
                    const progressBarFull = document.getElementById('unity-progress-bar-full');
                    const fullscreenButton = document.getElementById('unity-fullscreen-button');
                    const unityContainerBase = document.querySelector('.grid-system-container-unity') as HTMLElement;

                    const buildUrl = this.TOOLS_PATH + 'Build/';
                    const config = {
                        dataUrl: buildUrl + 'data.unityweb',
                        frameworkUrl: buildUrl + 'framework.js.unityweb',
                        codeUrl: buildUrl + 'wasm.unityweb',
                        streamingAssetsUrl: '/StreamingAssets',
                        companyName: 'Unispher',
                        productName: 'UnispherTools',
                        productVersion: '1.0',
                    };

                    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                        if (container) {
                            container.className = 'unity-mobile';
                        }
                    } else {
                        // if (canvas) {
                        //     canvas.style.width = unityContainerBase.offsetWidth + 'px';
                        //     canvas.style.height = unityContainerBase.offsetHeight + 'px';
                        // }
                    }

                    if (canvas && loadingBar) {
                        canvas.style.background = `url(${buildUrl}/WEBGL_BLOOM.jpg) center / cover`;
                        loadingBar.style.display = 'block';
                    }

                    createUnityInstance(canvas, config, (progress: any) => {
                        this.progress = progress;
                        if (progressBarFull) {
                            progressBarFull.style.width = 100 * progress + '%';
                        }
                    })
                        .then((unityInstance: any) => {
                            this.isReady = true;
                            this.unityInstance = unityInstance;

                            this.gameLoaded.emit();

                            // this.unityInstance.SetFullscreen(1);

                            if (loadingBar) {
                                loadingBar.style.display = 'none';
                            }

                            if (fullscreenButton) {
                                fullscreenButton.onclick = () => {
                                    unityInstance.SetFullscreen(1);
                                };
                            }
                        })
                        .catch((message: any) => {
                            alert(message);
                        });
                }, 600);
            },
            (error: any) => {
                console.log(error);
            }
        );
    }

    ngOnDestroy() {
        if (this.unityInstance) {
            this.unityInstance.Quit();
        }
        Tools.unsubscribeAll(this.subscriptions);
    }

    @HostListener('fullscreenchange', ['$event'])
    @HostListener('webkitfullscreenchange', ['$event'])
    @HostListener('mozfullscreenchange', ['$event'])
    @HostListener('MSFullscreenChange', ['$event'])
    screenChange(event: any) {
        if (window.innerWidth == screen.width && window.innerHeight == screen.height) {
        } else {
            this.close();
        }
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.close();
    }

    close() {
        this.unityInstance.Quit();
        this.gameClosed.emit();
    }

    setFullScreen() {
        this.unityInstance.SetFullscreen(1);
    }
}
