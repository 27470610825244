import { settings, environment } from 'src/environments/environment';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Tools } from 'src/app/tools';
import { Subscription } from 'rxjs';

type Language = {
    value: string;
    name: string;
};

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.scss'],
    providers: [],
})
export class MyAccountComponent implements OnInit {
    isLoading: boolean = false;
    user: any | undefined;

    nicknameFormControl = new UntypedFormControl('', [Validators.required]);
    nicknameLimit: number = 30;

    emailFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);

    birthdayFormControl = new UntypedFormControl('', [Validators.required]);

    imagePath: string;
    imgURL: any;
    message: string;
    imageUploadHelper: any;

    storageUrl: string = environment.storageUrl;

    langs: Language[] = [
        {
            value: 'en',
            name: 'English',
        },
        {
            value: 'heb',
            name: 'Hebrew',
        },
    ];

    selectedLang = localStorage.getItem('language') || environment.defaultLanguage;

    isChangePassword: boolean = false;

    subscriptions: Subscription[] = [];

    constructor(
        private title: Title,
        private userService: UserService,
        private sessionService: SessionService,
        private notificationService: NotificationService,
        private router: Router,
        private _bottomSheet: MatBottomSheet,
        public dialog: MatDialog
    ) {
        this.subscriptions.push(
            this.sessionService.changeLang$.subscribe(() => {
                this.selectedLang = localStorage.getItem('language') || environment.defaultLanguage;
            })
        );
    }

    get langName() {
        return this.langs.find((lang) => lang.value === this.selectedLang)?.name;
    }

    ngOnInit() {
        this.title.setTitle(settings.title + 'My account');
        this.getUser();
    }

    ngOnDestroy() {
        Tools.unsubscribeAll(this.subscriptions);
    }

    getUser() {
        this.user = this.sessionService.getCurrentUser();
        this.imgURL = this.user.main_image ? this.storageUrl + this.user.main_image : '';
    }

    openModal(dialogRef: TemplateRef<any>) {
        this.updateItem();
        this.dialog.open(dialogRef);
    }

    openBottomSheet(sheetRef: TemplateRef<any>): void {
        // this._bottomSheet.open(sheetRef);
    }

    setLang(event: MouseEvent, lang: Language): void {
        this.selectedLang = lang.value;
        localStorage.setItem('language', lang.value);
        this.sessionService.changeLanguage();
        this._bottomSheet.dismiss();
        event.preventDefault();
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    updateItem() {
        this.emailFormControl.setValue(this.user.email);
        this.nicknameFormControl.setValue(this.user.nickname);
        this.birthdayFormControl.setValue(moment(this.user.birthday));
    }

    updateUser(input: string) {
        this.userService
            .updateUser(this.user.id, this.getFormData(input))
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success('Updated successfully');
                    this.mapUserData(response.data);
                    this.getUser();
                    this.closeDialog();
                    this.sessionService.changeUser();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    mapUserData(userData: any) {
        this.sessionService.setCurrentUser({
            ...this.sessionService.getCurrentUser(),
            ...{ nickname: userData.nickname, birthday: userData.birthday },
        });
    }

    getFormData(input: string) {
        const nickname = this.nicknameFormControl.value;
        const birthday = this.birthdayFormControl.value;

        const data = new FormData();

        if (input === 'nickname') {
            data.append('nickname', nickname);
        }

        if (input === 'birthday') {
            data.append('birthday', moment(birthday).format('YYYY-MM-DD'));
        }

        return data;
    }

    preview(files: any) {
        if (files.length === 0) {
            return;
        }

        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = 'Only images are supported.';
            return;
        }

        const reader = new FileReader();
        this.imagePath = files;
        this.imageUploadHelper = files[0];
        reader.readAsDataURL(files[0]);

        reader.onload = () => {
            this.imgURL = reader.result;
        };
    }

    saveProfileImage() {
        if (!this.imageUploadHelper) {
            return;
        }

        const data = new FormData();
        data.append('main_image', this.imageUploadHelper, this.imageUploadHelper.name);

        this.userService
            .updateUser(this.user.id, data)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success('Updated successfully');
                    this.sessionService.setCurrentUser(response.data);
                    this.getUser();
                    this.closeDialog();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    goToQuestionary() {
        this.sessionService.setQuestionnaire(false);
        this.router.navigateByUrl('/home');
    }

    toggleChangePassword() {
        this.isChangePassword = !this.isChangePassword;
    }
}
