import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class QuestionnaireService {
    public API: string = '/questionnaire';

    constructor(private http: HttpClient) {}

    getByUser(id: number) {
        return this.http.get(new Url(this.API + '/' + id).path);
    }

    update(id: number, data: any, type: string) {
        return this.http.put(new Url(this.API + '/me').path, data);
    }

    get() {
        return this.http.get(new Url(this.API + '/me').path);
    }

    getDisciplinesInterests() {
        return this.http.get(new Url('/student/questionnaire/interests/me').path);
    }

    getBySchool() {
        return this.http.get(new Url('/school-survey').path);
    }

    saveResult(result: any) {
        return this.http.post(new Url('/questionnaire-result').path, result);
    }
}
