<app-card class="mb-3 h-full" title="Disciplines" [paddingX]="false">
    <app-table
        [columns]="table.columns"
        [data]="disciplines"
        [form]="table.form"
        [create]="true"
        [edit]="true"
        [delete]="true"
        [deletionConfirm]="true"
        (onCreate)="create($event)"
        (onEdit)="update($event)"
        (onDelete)="delete($event)"
    >
    </app-table>
</app-card>
