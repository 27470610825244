export class TextDirectionController {
    public textDirection: string;

    constructor() {
        this.checkDirection();
    }

    public checkDirection(): void {
        const lang = localStorage.getItem('language');
        this.textDirection = lang === 'heb' ? 'rtl' : 'ltr';
    }
}
