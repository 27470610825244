<div class="mb-3 flex items-center">
    <h4 class="mr-3">{{ 'communications.section.project_calendar' | translate }}</h4>

    <div class="ml-auto">
        <button matRipple class="mr-2 btn-primary small-control">
            <i class="fas fa-cog fa-lg"></i>
        </button>
        <button matRipple class="btn-danger bg-pink small-control">
            <i class="fas fa-plus fa-lg"></i>
        </button>
    </div>
</div>

<div class="w-full flex items-center">
    <div class="flex items-center">
        <h5 class="mr-5" style="font-weight: 600; letter-spacing: 1px">
            <i class="far fa-calendar mr-3"></i>
            {{ date }}
        </h5>
        <!-- <mat-form-field>
                    <mat-select [(value)]="viewMode">
                        <mat-select-trigger class="flex items-center">
                            <mat-icon>view_list</mat-icon>&nbsp;{{ viewName }}
                        </mat-select-trigger>
                        <mat-option *ngFor="let option of viewModes" [value]="option.value">
                            <mat-icon>view_list</mat-icon>{{ option.viewValue }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
    </div>
    <div class="ml-auto">
        <ng-container *ngFor="let range of ranges">
            <span
                class="mr-4 date-range-item"
                [ngClass]="{ underline: range === selectedRange }"
                (click)="selectRange(range)"
            >
                {{ range }}
            </span>
        </ng-container>
    </div>
</div>

<div class="w-full flex mb-3">
    <hr class="w-full" style="height: 3px; margin: 0" />
</div>

<app-table [columns]="table.columns" [data]="planSteps" [slim]="true"></app-table>

<div class="flex justify-end poppins-light">
    <div>
        <div><span>Total hours Week: 24</span></div>
        <div><span>Total Projects: 5</span></div>
    </div>
</div>
