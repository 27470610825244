import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export class Notification {
    constructor(
        public id: number,
        public type: NotificationType,
        public title: string | undefined,
        public message: string,
        public timeout: number,
        public position?: NotificationPosition,
        public lastBreath?: boolean | undefined,
    ) {}
}

export enum NotificationType {
    success = 'success',
    warning = 'warning',
    error = 'error',
    info = 'info',
}

export enum NotificationPosition {
    topLeft = 0,
    topRight = 1,
    bottomLeft = 2,
    bottomRight = 3,
}

@Injectable()
export class NotificationService {
    private notificationClearSource = new Subject<void>();
    notificationClear$ = this.notificationClearSource.asObservable();

    private triggerToastSource = new Subject<any>();
    triggerToast$ = this.triggerToastSource.asObservable();

    private _subject = new Subject<Notification>();
    private _idx = 0;
    private timeout = 6000;

    constructor() {}

    getObservable(): Observable<Notification> {
        return this._subject.asObservable();
    }

    info(message: string, title?: string, timeout = this.timeout) {
        this.triggerToastSource.next(new Notification(this._idx++, NotificationType.info, title, message, timeout));
    }

    success(message: string, title?: string, timeout = this.timeout) {
        this.triggerToastSource.next(new Notification(this._idx++, NotificationType.success, title, message, timeout));
    }

    warning(message: string, title?: string, timeout = this.timeout) {
        this.triggerToastSource.next(new Notification(this._idx++, NotificationType.warning, title, message, timeout));
    }

    error(message: string, title?: string, timeout = this.timeout) {
        this.triggerToastSource.next(new Notification(this._idx++, NotificationType.error, title, message, timeout));
    }

    clear() {
        this.notificationClearSource.next();
    }
}
