import { Validators } from '@angular/forms';
import { Table } from './Table';

export class OrganizationsTable extends Table {
    constructor() {
        super();
        this.buildColumns();
        this.buildForm();
    }

    buildColumns() {
        this.columns = [
            {
                name: 'ID',
                selector: 'id',
            },
            {
                name: 'Name',
                selector: 'name',
                class: () => 'text-nowrap',
            },
            {
                name: 'Admin',
                selector: 'admin',
            },
            {
                name: 'Email',
                selector: 'email',
            },
            {
                name: 'Students',
                selector: 'students',
                headerClass: 'text-nowrap text-center',
                class: () => 'text-center',
            },
            {
                name: 'Teachers',
                selector: 'teachers',
                headerClass: 'text-nowrap text-center',
                class: () => 'text-center',
            },
        ];
    }

    buildForm(): void {
        this.form = {
            title: () => '',
            elements: [
                {
                    name: 'ID',
                    selector: 'id',
                    type: 'text',
                    visible: false,
                },
                {
                    name: 'Nickname',
                    selector: 'nickname',
                    type: 'text',
                    validators: [Validators.required],
                },
                {
                    name: 'Email',
                    selector: 'email',
                    type: 'text',
                    validators: [Validators.required],
                },
                {
                    name: 'Password',
                    selector: 'password',
                    type: 'text',
                },
                {
                    name: 'Birthday',
                    selector: 'birthday',
                    type: 'date',
                    validators: [Validators.required],
                },
                {
                    name: 'Mentor',
                    selector: 'mentor',
                    defaultValue: false,
                    type: 'checkbox',
                },
                {
                    name: 'Active',
                    selector: 'active',
                    defaultValue: false,
                    type: 'checkbox',
                },
            ],
        };
    }
}
