<div *ngIf="project" id="custom-scroll" class="project-side">
    <div class="flex justify-end mb-2">
        <i class="fas fa-times fa-lg cursor-pointer project-side-close" (click)="close()"></i>
    </div>

    <div class="st-title">
        <h4 class="mb-2">{{ project.name }}</h4>
        <span class="mb-2">{{ 'studio.sidebar.desc' | translate }}</span>
    </div>

    <hr />

    <div>
        <div class="st-title">
            <h5 class="mb-2">{{ 'studio.sidebar.recent_contributors' | translate }}</h5>
        </div>

        <ng-container *ngFor="let contributor of project.contributors">
            <div class="grid-container items-center">
                <div class="grid-item"><img class="author" [src]="contributor.user.image_path" alt="" /></div>

                <div class="grid-item contributor" style="font-weight: 400">
                    <div>{{ contributor.user.name }}</div>
                    <span style="font-size: 0.7em; opacity: 0.7">{{ contributor.role }}</span>
                </div>
            </div>
        </ng-container>
    </div>

    <hr />

    <div>
        <h5 class="mb-2">{{ 'studio.sidebar.activity' | translate }}</h5>
        <div>
            <div class="calendar">
                <ul class="weekdays">
                    <li>
                        <abbr title="S">Su</abbr>
                    </li>
                    <li>
                        <abbr title="M">Mo</abbr>
                    </li>
                    <li>
                        <abbr title="T">Tu</abbr>
                    </li>
                    <li>
                        <abbr title="W">We</abbr>
                    </li>
                    <li>
                        <abbr title="T">Th</abbr>
                    </li>
                    <li>
                        <abbr title="F">Fr</abbr>
                    </li>
                    <li>
                        <abbr title="S">Sa</abbr>
                    </li>
                </ul>

                <ol class="day-grid">
                    <li class="month=prev"></li>
                    <li class="month=prev"></li>
                    <li class="month=prev"></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li class="active"></li>
                    <li class="active"></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li class="active"></li>
                    <li class="active"></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li class="month-next"></li>
                    <li class="month-next"></li>
                </ol>
            </div>
        </div>
    </div>

    <hr />

    <div class="st-title">
        <h5 class="mb-2">{{ 'studio.sidebar.info' | translate }}</h5>

        <div class="flex justify-between mb-2">
            <span>{{ 'studio.sidebar.level' | translate }} </span>
            <span>Junior project</span>
        </div>

        <div class="flex justify-between mb-2">
            <span>{{ 'studio.sidebar.views' | translate }} </span>
            <span>153</span>
        </div>

        <div class="flex justify-between mb-2">
            <span>{{ 'studio.sidebar.plays' | translate }} </span>
            <span>120</span>
        </div>

        <div class="flex justify-between mb-2">
            <span>{{ 'studio.sidebar.created' | translate }} </span>
            <span>Nov 11, 2021</span>
        </div>

        <div class="flex justify-between mb-2">
            <span>{{ 'studio.sidebar.access' | translate }} </span>
            <span>Public</span>
        </div>

        <div class="flex justify-between mb-2">
            <span>{{ 'studio.sidebar.permissions' | translate }} </span>
            <span>Owner</span>
        </div>

        <div class="flex justify-between mb-2">
            <span>{{ 'studio.sidebar.size' | translate }} </span>
            <span>28 MB</span>
        </div>
    </div>
</div>
