<button
    matRipple
    [class.selected]="selected"
    [ngClass]="{ 'w-full': fullWidth, bordered: bordered }"
    [disabled]="disabled"
>
    <i class="ph-{{ icon }}"></i>
    <span class="hidden lg:block">{{ text | truncate : truncate }}</span>

    <ng-container *ngIf="isDropdown">
        <i class="ph-caret-down-bold dropdown-caret"></i>
    </ng-container>
</button>
