
import { settings, environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';



@Component({
  selector: 'app-com-overall-card',
  templateUrl: './com-overall-card.component.html',
  styleUrls: ['./com-overall-card.component.scss'],
  providers: [],
})
export class ComOverallCardComponent implements OnInit {

  isLoading: boolean = false;

  date: string = moment().format('MMM DD, YYYY');

  activities: any[] = [
    {
      user: {
        name: 'Sagi Vaksin',
        image_path: '/assets/rust.png'
      },
      activity: 'Create Task Connect with Team',
      date: '1 Mins ago'
    },
    {
      user: {
        name: 'Team Qwerty',
        image_path: '/assets/rust.png'
      },
      activity: 'Сompleted  Task Create name project',
      date: '3 Mins ago'
    },
    {
      user: {
        name: 'Lina Kadlen',
        image_path: '/assets/rust.png'
      },
      activity: 'Completed  Challenge Сreate 10 Tasks',
      date: '5 Mins ago'
    }
  ];



  constructor() { }


  ngOnInit() {
  }



}
