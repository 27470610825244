<app-login-card>
    <form class="space-y-4 md:space-y-6 mb-4" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div>
            <div *ngIf="submited">
                <span class="text-white/75">Link with reset password was sent to selected email</span>
            </div>
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Your email </label>
            <input
                type="email"
                name="email"
                id="email"
                formControlName="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@company.com"
                required=""
            />
        </div>
        <div class="flex items-center justify-center mb-3">
            <a routerLink="/login" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">
                Go back to login?
            </a>
        </div>
        <div>
            <app-un-magic-button
                style="width: 100%"
                (clicked)="onSubmit()"
                [text]="'Submit'"
                [fullWidth]="true"
                [disabled]="!formGroup.valid"
                [bordered]="true"
                [fullWidth]="true"
            ></app-un-magic-button>
        </div>
    </form>
</app-login-card>
