<div class="relative overflow-hidden">
    <div
        [@flyRigthIn]
        class="flex items-center gap-4 border-b border-b-slate-300/50 px-3 py-2 text-2xl font-medium text-slate-300"
    >
        <span>{{ title }} </span>
        <span
            class="me-2 inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 text-xs font-semibold text-gray-800 dark:bg-gray-700 dark:text-gray-300"
        >
            {{ tasksArray.length > 99 ? '99+' : tasksArray.length + guides.length }}
        </span>
        <div class="flex items-center justify-end gap-4 ltr:ml-auto rtl:mr-auto">
            <button
                class="flex items-center text-3xl font-medium text-slate-300"
                (mousedown)="startScroll('left')"
                (mouseup)="stopScroll()"
                (mouseleave)="stopScroll()"
                (click)="scrollLeft()"
            >
                <i class="ph ph-caret-circle-left text-3xl"></i>
            </button>

            <button
                class="flex items-center text-3xl font-medium text-slate-300"
                (mousedown)="startScroll('right')"
                (mouseup)="stopScroll()"
                (mouseleave)="stopScroll()"
                (click)="scrollRight()"
            >
                <i class="ph ph-caret-circle-right text-3xl"></i>
            </button>

            <button class="flex items-center text-3xl font-medium text-slate-300" (click)="toggleStage()">
                <i [ngClass]="{ 'ph ph-caret-circle-down': isClosed, 'ph ph-caret-circle-up': !isClosed }"></i>
            </button>
        </div>
    </div>

    <div *ngIf="!isClosed" [@flyInOut] class="flex gap-4">
        <div *ngIf="guides.length > 0" class="guides-section my-3 flex gap-3 pb-3">
            <app-simple-card
                *ngFor="let guideTask of guides"
                class="m-1"
                [card]="guideTask"
                (taskUpdated)="taskUpdated.emit()"
            ></app-simple-card>
        </div>
        <div
            #scrollContainer
            cdkDropList
            [cdkDropListData]="tasksArray"
            cdkDropListOrientation="horizontal"
            (cdkDropListDropped)="drop($event)"
            class="drop-list un-scroll my-3 flex min-h-[280px] min-w-[224px] gap-3 overflow-x-auto pb-3 after:block after:w-screen"
        >
            <app-simple-card
                class="drag-element m-1"
                *ngFor="let task of tasksArray"
                [card]="task"
                (taskUpdated)="taskUpdated.emit()"
                cdkDrag
            >
                <div cdkDragHandle class="drag-handle">
                    <i class="ph-arrows-out-cardinal-bold cursor-grab text-2xl text-un-pink"></i>
                </div>

                <div
                    class="m-1 h-[280px] min-w-[224px] rounded-[8px] border-4 border-dashed border-un-pink/80 bg-[#08051F]/70 p-4 text-center align-middle text-un-pink transition duration-200"
                    *cdkDragPlaceholder
                >
                    <div>move your task here</div>
                </div>
            </app-simple-card>
        </div>
    </div>
</div>
