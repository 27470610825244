import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { SessionService } from 'src/app/services/session.service';
import { User } from '../../login/login.component';
import { environment } from 'src/environments/environment';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-sign-up-teacher',
    templateUrl: './sign-up-teacher.component.html',
    styleUrls: ['./sign-up-teacher.component.scss'],
})
export class SignUpTeacherComponent implements OnInit {
    @Input() email: string = '';

    isLoading: boolean = false;
    hasError: boolean = false;
    htmlTag: any;
    version = '';
    returnUrl: string = '';

    matcher = new MyErrorStateMatcher();

    errors: any[] = ['E-Mail oder Passwort ungültig', 'Die Email-Adresse wurde nicht gefunden'];

    isSent: boolean = false;

    formGroup = this.fb.group(
        {
            email: ['', Validators.required],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
        },
        {
            validator: this.passwordMatchValidator,
        }
    );

    showPassword: boolean = false;
    showConfirmPassword: boolean = false;

    questionnaireUrl: string = environment.questionnaireUrl;

    constructor(
        private notificationService: NotificationService,
        private loginService: LoginService,
        private router: Router,
        private fb: UntypedFormBuilder,
        public sessionService: SessionService
    ) {}

    ngOnInit() {
        this.htmlTag = document.getElementsByTagName('html')[0];
        this.htmlTag.classList.add('login');
        this.version = environment.version;
        this.returnUrl = '/';
        this.formGroup.get('email')?.setValue(this.email);
        this.formGroup.get('email')?.disable();
    }

    onSubmit() {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;

        if (this.formGroup.valid) {
            this.register();
        }
    }

    register() {
        const email = this.formGroup.get('email')?.value;
        const nickname = this.formGroup.get('nickname')?.value;
        const password = this.formGroup.get('password')?.value;

        const data = new FormData();

        data.append('email', email);
        data.append('password', password);
        data.append('nickname', nickname);

        this.loginService
            .register(data)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success('User registered successfully, login to continue.');
                    this.sessionService.setCurrentUser(response.data.user);
                    this.sessionService.setToken(response.data.auth_token);
                    this.sessionService.setQuestionnaire(this.isQuestionnaireDone(response.data.user));
                    this.sessionService.setCurrentUserSchool(response.data.school);

                    this.returnUrl = this.getReturnUrlByUser(response.data.user);

                    setTimeout(() => {
                        this.router.navigateByUrl(this.returnUrl);
                    }, 200);

                    this.htmlTag?.classList.remove('login');
                    sessionStorage.removeItem('redirected');
                    localStorage.setItem('lang', response.data.user?.language || 'en');
                    localStorage.setItem('language', response.data.user?.language || 'en');
                    this.sessionService.changeLanguage();
                },
                (error: any) => {
                    this.notificationService.error(error.error.reason, 'Error');
                    this.isLoading = false;
                    this.hasError = true;
                }
            );
    }

    passwordMatchValidator(formGroup: UntypedFormGroup) {
        const password = formGroup.get('password')?.value;
        const confirmPassword = formGroup.get('confirmPassword')?.value;
        if (password !== confirmPassword) {
            formGroup.get('confirmPassword')?.setErrors({ notMatching: true });
        } else {
            formGroup.get('confirmPassword')?.setErrors(null);
        }
    }

    getReturnUrlByUser(user: User): string {
        return this.isQuestionnaireDone(user) ? this.returnUrl : this.questionnaireUrl;
    }

    isQuestionnaireDone(user: User): boolean {
        return !user.questionnaire;
    }

    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }

    toggleConfirmPasswordVisibility() {
        this.showConfirmPassword = !this.showConfirmPassword;
    }
}
