import { AfterViewInit, Component, Input, ViewChild, forwardRef } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    ValidationErrors,
    Validators,
} from '@angular/forms';

@Component({
    selector: 'app-textarea',
    template: `
        <div>
            <label class="block text-sm font-medium text-gray-500 dark:font-normal dark:text-gray-400">
                <span>{{ label }}</span>
                <span
                    *ngIf="isRequired"
                    class="ml-0.5 "
                    [ngClass]="{ 'text-red-500 dark:text-rose-500': control.errors?.['required'] }"
                >
                    *
                </span>
            </label>
            <div class="mt-0.5">
                <textarea
                    [value]="value"
                    (input)="onTextareaInput($event)"
                    (blur)="onTextareaBlur()"
                    [attr.rows]="rows"
                    [attr.placeholder]="placeholder"
                    [disabled]="isDisabled"
                    [maxLength]="maxLength !== -1 ? maxLength : 9999"
                    class="block w-full rounded-md border-0 bg-transparent px-2 py-2 text-sm text-gray-900 outline-none ring-1 ring-inset ring-gray-300 transition duration-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 dark:text-white dark:ring-gray-600 dark:placeholder:text-gray-500 dark:focus:ring-sky-400"
                ></textarea>
            </div>

            <div *ngIf="maxLength !== -1" class="flex justify-end text-xs text-gray-500 dark:text-gray-400">
                {{ value.length }} / {{ maxLength }}
            </div>

            <ng-container *ngIf="control?.touched || control?.dirty">
                <div
                    class="text-xs text-red-500 dark:font-semibold dark:text-rose-500"
                    *ngIf="control?.errors?.['required']"
                >
                    Please fill in
                </div>

                <div
                    class="text-xs text-red-500 dark:font-semibold dark:text-rose-500"
                    *ngIf="control?.errors?.['minlength']"
                >
                    Bitte mindestens {{ this.control.errors.minlength.requiredLength }} Zeichen verwenden
                </div>
            </ng-container>
        </div>
    `,
    styles: [':host { display: block; }'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TextareaComponent),
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TextareaComponent),
            multi: true,
        },
    ],
})
export class TextareaComponent implements ControlValueAccessor, AfterViewInit {
    @Input() label: string = '';
    @Input() value: string = '';
    @Input() placeholder: string = '';
    @Input() rows: number = 3;
    @Input() isDisabled: boolean = false;
    @Input() maxLength: number = -1;

    private onChange: (value: string) => void;
    private onTouched: () => void;

    control: any;
    @ViewChild('input', { static: false, read: NgControl }) input: any;

    constructor() {}

    ngAfterViewInit() {
        this.validate(null);
    }

    validate(control: AbstractControl | null): ValidationErrors | null {
        if (!this.control) this.control = control;

        if (this.control && this.input) {
            this.input.control.setValidators(this.control.validator);

            if (this.maxLength > 0) {
                this.input.control.setValidators([
                    ...this.input.control.validator,
                    Validators.maxLength(this.maxLength),
                ]);
            }
        }

        return null;
    }

    writeValue(value: string): void {
        this.value = value || '';
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onTextareaInput(event: Event): void {
        const textarea = event.target as HTMLTextAreaElement;
        this.value = textarea.value;
        this.onChange(this.value);
    }

    onTextareaBlur(): void {
        this.onTouched();
    }

    get isRequired() {
        return this.control?.hasValidator(Validators.required);
    }
}
