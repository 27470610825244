import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class SchoolsService {
    public API: string = '/schools';

    constructor(private http: HttpClient) {}

    getAll() {
        return this.http.get(new Url(this.API).path);
    }

    getById(id: number) {
        return this.http.get(new Url(this.API + '/' + id).path);
    }

    getUsersBySchool(id: number) {
        return this.http.get(new Url(this.API + '/' + id + '/users').path);
    }

    getByTeam(teamId: number) {
        return this.http.get(new Url(this.API + '/team/' + teamId).path);
    }

    create(data: any) {
        return this.http.post(environment.apiUrl + this.API, data);
    }

    update(id: number, data: any) {
        return this.http.put(environment.apiUrl + this.API + '/' + id, data);
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + this.API + '/' + id);
    }

    getSchoolTeams(schoolId: number) {
        return this.http.get(new Url(this.API + '/' + schoolId + '/teams').path);
    }

    getSchoolGrades() {
        return this.http.get(new Url('/school-grades').path);
    }

    getStudents() {
        return this.http.get(new Url('/school/students').path);
    }

    getManagers() {
        return this.http.get(new Url('/school/managers').path);
    }
}
