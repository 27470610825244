import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { finalize } from 'rxjs/operators';
import { ProjectsService } from 'src/app/services/projects.service';
import { ManageProjectsTable } from 'src/app/tables/ManageProjectsTable';

interface Project {
    id?: number;
    active: boolean;
    description: string;
    end_date: string;
    image_path: string;
    school_id: number;
    start_date: string;
    title: string;
}

@Component({
    selector: 'app-manage-projects',
    templateUrl: './manage-projects.component.html',
    styleUrls: ['./manage-projects.component.css'],
})
export class ManageProjectsComponent implements OnInit {
    projects: Project[] = [];

    ENTRY_NAME: string = 'Projects';

    table = new ManageProjectsTable();

    search: string = '';

    storageUrl: string = environment.storageUrl;

    isLoading: boolean = true;

    constructor(
        private projecsServie: ProjectsService,
        private notificationService: NotificationService,
        public sessionService: SessionService,
    ) {}

    ngOnInit() {
        this.table.buildForm();
        this.fetch();
    }

    fetch() {
        this.isLoading = true;
        this.projecsServie
            .getAll(this.search)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(({ data }: any) => {
                this.projects = data || [];
                this.isLoading = false;
            });
    }

    create(data: Project) {
        this.projecsServie
            .create(data)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success(this.ENTRY_NAME + ' created successfully');
                    this.fetch();
                },
                error: (error) => {
                    this.notificationService.error(error.error.message, 'Error');
                },
            });
    }

    update(data: Project) {
        this.projecsServie
            .update(data.id, data)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success(this.ENTRY_NAME + ' updated successfully');
                    this.fetch();
                },
                error: (error) => {
                    this.notificationService.error(error.error.message, 'Error');
                },
            });
    }

    delete(data: Project) {
        this.projecsServie
            .delete(data.id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success(this.ENTRY_NAME + ' deleted successfully');
                    this.fetch();
                },
                error: (error) => {
                    this.notificationService.error(error.error.message, 'Error');
                },
            });
    }

    onSearch(search: string) {
        this.search = search;
        this.fetch();
    }
}
