import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-un-magic-button',
    templateUrl: './un-magic-button.component.html',
    styleUrls: ['./un-magic-button.component.scss'],
})
export class UnMagicButtonComponent implements OnInit {
    @Input() text: string = '';
    @Input() selected: boolean = false;
    @Input() fullWidth: boolean = false;
    @Input() bordered: boolean = false;
    @Input() disabled: boolean = false;
    @Input() isLoading: boolean = false;

    @Output() сhanged: EventEmitter<any> = new EventEmitter();

    ngOnInit(): void {}
}
