import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UniChatService {
    constructor(private http: HttpClient) {}

    sendQuestion(question: string) {
        return this.http.post(environment.apiUrl + '/uni-chat', { question: question });
    }
}
