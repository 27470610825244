<div class="task-card p-3 main-card">
    <div class="flex justify-end">
        <span
            class="flex cursor-pointer rounded-sm p-1 hover:text-gray-400 transition"
            style="border: 1px solid rgba(237, 242, 255, 0.1)"
            (click)="close()"
        >
            <i class="ph ph-x text-base"></i>
        </span>
    </div>
    <div *ngIf="isLoading" class="flex justify-center">
        <mat-spinner [diameter]="70"></mat-spinner>
    </div>

    <div class="col-span-11 px-1 mb-2 mt-2">
        <mat-form-field class="mb-2 w-full" hintLabel="Max {{ maxTaskTitleLength }} characters">
            <input
                #inputText
                matInput
                type="text"
                [placeholder]="'communications.workspace.type_task_title_here' | translate"
                autofocus
                [maxlength]="maxTaskTitleLength"
                [(ngModel)]="title"
            />
            <mat-hint align="end">{{ inputText.value.length || 0 }}/{{ maxTaskTitleLength }}</mat-hint>
        </mat-form-field>
    </div>

    <div *ngIf="!isLoading" class="task-card-body">
        <div (keyup.enter)="save()" class="grid grid-cols-12 items-center">
            <div class="col-span-12 px-1 mb-2">
                <div class="flex flex-col gap-2 text-start">
                    <span class="text-xs leading-4 text-[#8E91D1]">
                        {{ 'communications.workspace.task_tags_crate' | translate }}
                    </span>
                    <div class="flex flex-wrap gap-2">
                        <ng-container *ngFor="let tag of selectedTags">
                            <app-tag [tag]="tag" (clicked)="removeTag(tag)"></app-tag>
                        </ng-container>
                        <ng-container *ngFor="let tag of taskTags">
                            <span
                                class="bg-[#363160] text-[#9F8DD0] px-2 py-1 capitalize rounded-md cursor-pointer transition-all"
                                (click)="pushTag(tag)"
                            >
                                <div class="flex items-center gap-x-1">
                                    <i class="ph text-base" [ngClass]="'ph-' + tag.icon"></i>
                                    <span>
                                        {{ tag.label }}
                                    </span>
                                </div>
                            </span>
                        </ng-container>
                    </div>
                </div>
                <!-- <mat-form-field class="col-12 mb-2">
                    <mat-label>
                        {{ 'communications.workspace.task_types_crate' | translate }}
                    </mat-label>
                    <mat-select [formControl]="taskTypeControl">
                        <ng-container *ngFor="let taskType of taskTypes">
                            <mat-option [value]="taskType.id">{{ taskType.title }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field> -->
            </div>

            <div class="colspan-1 p-0 mt-1">
                <div *ngIf="title.length > 3" class="save-task-circe" (click)="save()"><i class="ph-check"></i></div>
            </div>
        </div>
    </div>
</div>
