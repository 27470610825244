import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-team-box',
    templateUrl: './team-box.component.html',
    styleUrls: ['./team-box.component.scss'],
    providers: [],
})
export class TeamBoxComponent {
    @Input() team: any;
    @Input() teacherMentors: any[] = [];
    @Input() studentMentors: any[] = [];

    @Output() showTeamChartClicked: EventEmitter<any> = new EventEmitter();
    @Output() changeMentorClicked: EventEmitter<any> = new EventEmitter();

    @ViewChild('menu') menuTemplate: any;

    isAdmin: boolean = this.session.getCurrentUser().is_admin;

    constructor(private router: Router, private session: SessionService) {}

    goToViewPage(teamId: number) {
        this.router.navigateByUrl('/communications/team/' + teamId);
    }

    openMenu(event: any) {
        const parent = event.target.parentElement.closest('.main-card');
        if (parent) {
            const menuButton = parent.getElementsByClassName('menu-trigger');
            if (menuButton) {
                menuButton[0].click();
            }
        }
    }

    getChartData(team: any) {
        this.showTeamChartClicked.emit(team);
    }

    changeMentor(type: string, id: number) {
        this.changeMentorClicked.emit({
            type: type,
            teamId: id,
        });
    }
}
