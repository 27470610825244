import { Table } from './Table';

export class SurveysAnswersTable extends Table {
  constructor() {
    super();
    this.buildColumns();
  }

  buildColumns() {
    this.columns = [
      {
        name: 'ID',
        selector: 'id',
        headerClass: 'text-center',
        class: () => 'text-center',
      },
      {
        name: 'Project',
        selector: 'project_id',
        headerClass: 'text-left',
        class: () => 'text-left',
      },
      {
        name: 'Response',
        selector: 'response',
        headerClass: 'text-left',
        class: () => 'text-left',
        transform: (response) => response.length > 50 ? response.substring(0, 50) + '...' : response,
      },
      {
        name: 'School',
        selector: 'school_id',
        headerClass: 'text-center',
        class: () => 'text-center',
      },
      {
        name: 'Question',
        selector: 'school_survey_question_id',
        headerClass: 'text-center',
        class: () => 'text-center',
      },
      {
        name: 'Target Student',
        selector: 'target_student_id',
        headerClass: 'text-center',
        class: () => 'text-center',
      },
      {
        name: 'Team',
        selector: 'team_id',
        headerClass: 'text-center',
        class: () => 'text-center',
      },
      {
        name: 'User',
        selector: 'user_id',
        headerClass: 'text-center',
        class: () => 'text-center',
      },
    ];
  }
}
