import { Component, ElementRef, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NotificationService } from 'src/app/services/notification.service';
import { Subscription, TimeoutError } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { flyleftIn, flyRigthIn } from 'src/app/animations/fly-animations';
import { TasksService } from 'src/app/services/tasks.service';
import { Tools } from 'src/app/tools';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'src/app/services/session.service';
import { TagsService } from 'src/app/services/tags.service';

@Component({
    selector: 'app-workplace-tasks',
    templateUrl: './workplace-tasks.component.html',
    styleUrls: ['./workplace-tasks.component.scss'],
    animations: [flyRigthIn, flyleftIn],
})
export class WorkplaceTasksComponent implements OnInit {
    @Input() teamId: number = 0;
    @Input() viewTask: boolean = false;

    date: string = moment().format('DD MMM YYYY');
    @ViewChild('scroll') scroll: ElementRef<HTMLElement>;

    isLoading: boolean = true;

    public innerWidth: any;

    stageIndex = 0;
    stages: any[] = [];
    taskTypes: TaskType[] = [];
    taskTags: any[] = [];

    subscriptions: Subscription[] = [];

    emptyDataMessage: string = this.NO_DATA_FOR_TEAM_TOASTR;
    timerId: any = 0;

    constructor(
        private tagsService: TagsService,
        private tasksService: TasksService,
        private router: Router,
        private notificationsService: NotificationService,
        private sessionService: SessionService,

        public translate: TranslateService,
    ) {
        this.subscriptions.push(this.sessionService.changeLang$.subscribe(() => this.fetch()));

        this.subscriptions.push(this.sessionService.viewChanged$.subscribe(() => this.fetch()));
    }

    ngOnInit() {
        this.getTaskTypes();
        this.getTaskTags();
        this.innerWidth = window.innerWidth;
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('teamId' in changes && changes.teamId?.currentValue) {
            this.fetch();
        }
    }

    ngOnDestroy() {
        Tools.unsubscribeAll(this.subscriptions);
    }

    scrollTasks(direction: number): void {
        let frameStep = 14;
        const duration = 600;

        this.scrollTo(direction, frameStep, duration);

        this.timerId = setInterval(() => {
            frameStep += frameStep > 16 ? frameStep * 1.2 : 2;
            // this.scroll.nativeElement.scrollLeft = this.scroll.nativeElement.scrollLeft - direction * frameStep;
            this.scrollTo(direction, frameStep, duration - 20);
        }, duration);
    }

    scrollTo(direction: number, frameStep: any, duration: number) {
        const easeOutCubic = (t: any) => --t * t * t + 1;

        const startTime = performance.now();

        const step = () => {
            const progress = (performance.now() - startTime) / duration;
            const amount = easeOutCubic(progress);
            this.scroll.nativeElement.scrollTo({
                left: this.scroll.nativeElement.scrollLeft - direction * frameStep + amount,
            });
            if (progress < 0.99) {
                window.requestAnimationFrame(step);
            }
        };

        step();
    }

    stopScroll() {
        clearInterval(this.timerId);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.innerWidth = window.innerWidth;
    }

    get columnsCount() {
        return this.innerWidth >= 1536 ? 4 : 3;
    }

    fetch() {
        this.isLoading = true;
        // if (!this.teamId) {
        //     this.notificationsService.error(this.NO_TEAMS_FOR_USER_TOASTR, this.ERROR_TOASTR);
        //     return;
        // }
        this.tasksService
            .getTeamTasksStages(this.teamId)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe((response: any) => {
                this.stages = response.data;
            });
    }

    getTaskTypes() {
        this.tasksService.getTaskTypes().subscribe((response: any) => {
            this.taskTypes = response.data;
        });
    }

    getTaskTags() {
        this.tagsService.getAll().subscribe(({ data }: any) => {
            this.taskTags = data || [];
        });
    }

    goHomePage() {
        this.router.navigateByUrl('/home');
    }

    moveStage(index: number) {
        this.stageIndex = this.stageIndex + index;
    }

    get NO_DATA_FOR_TEAM_TOASTR() {
        return this.translate.instant('communications.workspace.no_data_for_team_toastr');
    }

    get NO_TEAMS_FOR_USER_TOASTR() {
        return this.translate.instant('communications.workspace.no_teams_for_user_toastr');
    }

    get ERROR_TOASTR() {
        return this.translate.instant('communications.info.error_toastr');
    }
}
