<div class="my-5 flex" style="flex-wrap: wrap; gap: 10px">
    <ng-container *ngFor="let tag of userTags">
        <span class="text-capitalize text-center tag px-3 mr-1 {{ tag.color }} type-tag">{{ tag.name }}</span>
    </ng-container>
</div>

<div class="flex justify-end my-3">
    <mat-slide-toggle color="warn" [(ngModel)]="isHebrew" (ngModelChange)="changeLang()">Hebrew</mat-slide-toggle>
</div>

<div class="flex">
    <div class="col-12">
        <highcharts-chart
            [Highcharts]="chart.highcharts"
            [options]="chart.options"
            style="width: 100%; height: 600px; display: block"
        >
        </highcharts-chart>
    </div>
</div>

<div class="flex">
    <div class="col-12">
        <highcharts-chart
            [Highcharts]="groupChart.highcharts"
            [options]="groupChart.options"
            style="width: 100%; height: 600px; display: block"
        >
        </highcharts-chart>
    </div>
</div>
