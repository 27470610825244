import { Component, OnInit } from '@angular/core';
import { DisciplinesService } from 'src/app/services/disciplines.service';

interface Discipline {
    id?: number;
    image_path: string | null;
    name: string;
    title: string;
}

@Component({
    selector: 'app-disciplines',
    templateUrl: './disciplines.component.html',
    styleUrls: ['./disciplines.component.scss'],
    providers: [],
})
export class DisciplinesComponent implements OnInit {
    isLoading: boolean = false;

    disciplines: Discipline[] = [];

    constructor(private disciplinesService: DisciplinesService) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.disciplinesService.getAll().subscribe((response: any) => {
            this.disciplines = response.data || [];
        });
    }
}
