import { Chart } from './Chart';


export class StudentDevelopmentChart extends Chart {

    build(seriesData: any) {

        this.options = {
            ...this.options, ...{
                exporting: {
                    enabled: true,
                },
                chart: {
                    type: 'spline',
                    zoomType: 'x'
                },
                legend: {
                    enabled: false,
                    verticalAlign: 'top',
                },
                xAxis: {
                    type: 'category',
                    tickInterval: 1,
                    categories: [1, 2, 3, 5, 5],
                    min: 0,
                    max: 8,
                    tickmarkPlacement: 'on',
                    lineWidth: 0,
                    labels: {
                        style: {
                            color: '#fff'
                        }
                    }
                },
                yAxis: this.yAxis(7),
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                        }
                    },
                },
                series: [
                    {
                        type: 'spline',
                        name: 'Rating',
                        data: seriesData,
                        yAxis: 0
                    }
                ]
            }
        };

    }


}
