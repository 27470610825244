import { settings } from 'src/environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';

enum Level {
    JUNIOR,
    MIDDLE,
    SENIOR,
    PROFESSOR,
}

type LevelTypes = keyof typeof Level;

export interface ProjectPreview {
    name: string;
    image_path: string;
    type: LevelTypes;
    description: string;
}

@Component({
    selector: 'app-project-sidebar',
    templateUrl: './project-sidebar.component.html',
    styleUrls: ['./project-sidebar.component.scss'],
    providers: [],
})
export class ProjectSidebarComponent implements OnInit {
    @Input() project: any;
    @Output() closed: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    close() {
        this.closed.emit();
    }
}
