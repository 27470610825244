import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { flyInOut, flyleftIn, flyRigthIn } from 'src/app/animations/fly-animations';
import { NotificationService } from 'src/app/services/notification.service';
import { PromptStyle, Text2imgService } from 'src/app/services/text2img.service';
import { environment } from 'src/environments/environment';

enum AIServices {
    StableDiffusion = 'stablediffusion',
    Dalle = 'dalle',
}

@Component({
    selector: 'app-image-generator',
    templateUrl: './image-generator.component.html',
    styleUrls: ['./image-generator.component.scss'],
    animations: [flyleftIn, flyRigthIn, flyInOut],
})
export class ImageGeneratoComponent {
    isLoading: boolean = false;

    activeService = AIServices.Dalle;
    readonly AIServices = AIServices;

    storageUrl: string = environment.storageUrl;

    promtFormControl = new UntypedFormControl('', [Validators.required]);

    IMAGE_PREFIX = 'data:image/png;base64,';
    imageString = '';

    images = {
        [AIServices.StableDiffusion]: [],
        [AIServices.Dalle]: [],
    };

    imagesDalle: string[] = [];

    selectedImage: string = '';

    remainingTime: number;
    timerRunning: boolean;
    interval: any;
    styles: PromptStyle[] = this.text2imgService.getPropmtStyles();

    selectedStyle = this.styles[0].prompt;

    @ViewChild('childRef', { static: false }) childComponent: any;

    constructor(
        private text2imgService: Text2imgService,
        private notificationService: NotificationService,
    ) {}

    getActiveTabSelector() {
        let activeTabs = this.childComponent.tabs.filter((tab) => tab.active);
        return activeTabs[0].selector;
    }

    generate() {
        this.isLoading = true;
        this.images[AIServices.StableDiffusion] = [];
        this.images[AIServices.Dalle] = [];

        this.selectImage('');
        this.startTimer();

        if (this.getActiveTabSelector() === AIServices.StableDiffusion) {
            this.text2imgService
                .generate(this.promtFormControl.value + ', ' + this.selectedStyle)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe({
                    next: ({ data }: any) => {
                        this.images[AIServices.StableDiffusion] = data.map(
                            (image: any) => this.storageUrl + image.name,
                        );
                    },
                    error: (err) => this.notificationService.error(err.error.reason, 'Error'),
                });
        } else {
            this.text2imgService
                .generateDalle(this.promtFormControl.value + ', ' + this.selectedStyle)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe({
                    next: ({ data }: any) => {
                        this.images[AIServices.Dalle] = [this.storageUrl + data.name];
                    },
                    error: (err) => this.notificationService.error(err.error.reason, 'Error'),
                });
        }
    }

    selectImage(image: string): void {
        this.selectedImage = image;
    }

    async downloadImage(event: any, url: string, name: string) {
        event.stopPropagation();
        const image = await fetch(url);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);

        const link = document.createElement('a');
        link.href = imageURL;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    startTimer() {
        this.timerRunning = true;
        this.remainingTime = 22; // Change this value to the desired number of seconds

        this.interval = setInterval(() => {
            this.remainingTime--;
            if (this.remainingTime === 0) {
                this.stopTimer();
            }
        }, 1000);
    }

    stopTimer() {
        clearInterval(this.interval);
        this.timerRunning = false;
    }

    calculateWidthPercentage(): number {
        return (this.remainingTime / 22) * 100;
    }

    setStyle(style) {
        this.selectedStyle = style.prompt;
    }
}
