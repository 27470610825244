import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';
import { items } from './data';
import { UserSkillCardsComponent } from 'src/app/components/studio/user-skills-cards/user-skills-cards.component';
import { retry } from 'rxjs/operators';

@Component({
    selector: 'app-un-user-dashboadr-view',
    templateUrl: './un-user-dashboadr-view.component.html',
    styleUrls: ['./un-user-dashboadr-view.component.scss'],
})
export class UnUserDashboadrViewComponent implements OnInit {
    user: any = {};
    projects: any[] = items;
    stars: any[] = [1, 2, 3, 4, 5];

    display: boolean = false;
    modalOpened: boolean = false;
    projectsSelected: boolean = false;
    skillsSelected: boolean = false;

    card: any = {
        name: 'Creation',
        progress: 91,
        modified_at: '1 days ago',
    };

    selectedLevel: any;

    storageUrl: string = environment.storageUrl;

    constructor(
        private router: Router,
        private notificationService: NotificationService,
        public sessionService: SessionService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.user = this.sessionService.getCurrentUser();
    }

    ngAfterViewInit() {
        setTimeout(() => (this.display = true), 100);
    }

    toggle() {
        this.display = !this.display;
    }

    openDialog(dialogRef: TemplateRef<any>, skills?: boolean) {
        this.selectedLevel = undefined;

        let modal: any = UserSkillCardsComponent;

        if (skills) {
            this.skillsSelected = true;
        }

        if (!skills) {
            this.projectsSelected = true;
            modal = dialogRef;
        }

        const dialog = this.dialog.open(modal, {
            data: '',
            panelClass: 'dialog-container-projects',
        });

        dialog.afterClosed().subscribe(() => {
            this.projectsSelected = false;
            this.skillsSelected = false;
        });
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    openUnityGame(name: string) {
        name !== ''
            ? this.goToGame(name)
            : this.notificationService.success(
                  'Please choose Junior or Middle level',
                  'There are no games for this level yet'
              );
    }

    goToGame(name: string) {
        this.closeDialog();
        this.router.navigate(['/' + name]);
    }

    openDescription(level: any) {
        this.selectedLevel = level;
    }

    setSelectedLevel(level: any) {
        this.selectedLevel = level;
    }

    isDisplayLevel(level: any) {
        if (!this.selectedLevel) {
            return true;
        }

        return this.selectedLevel.name === level.name;
    }
}
