import { NotificationService } from 'src/app/services/notification.service';
import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    FormGroupDirective,
    NgForm,
    ValidatorFn,
} from '@angular/forms';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS_CUSTOM } from 'src/app/app.component';
import * as moment from 'moment';
import { items } from '../../un-user-dashboadr-view/data';
import { environment } from 'src/environments/environment';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-table-form',
    templateUrl: './table-form.component.html',
    styleUrls: ['./table-form.component.css'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS_CUSTOM },
    ],
})
export class TableFormComponent implements OnInit, OnChanges {
    @Input() form?: FcbForm = new FcbForm();
    @Input() formElements: FcbFormElement[] = [];

    @Output() onSubmit: EventEmitter<any> = new EventEmitter();
    @Output() legendClicked: EventEmitter<any> = new EventEmitter();

    matcher = new MyErrorStateMatcher();

    formGroup: UntypedFormGroup = new UntypedFormGroup({});
    formElementsOrig: FcbFormElement[] = [];

    // save change if it occurs before ng init (angular bug?)
    // save change if it occurs before ng init (angular bug?)
    change!: { run: () => void };
    inited: boolean = false;

    rangeHours: any[] = new Array(24);
    rangeMinutes: any[] = new Array(60);

    uploadFiles: any[] = [];

    charactersLimit: number = 50;
    storageUrl: string = environment.storageUrl;

    constructor(private notificationService: NotificationService) {}

    /**
     * on component init
     */
    ngOnInit() {
        // copy original form elements
        if (this.change) {
            this.change.run();
        }
        this.init();
    }

    /**
     * on component @Input params change
     * prevent ngOnChanges to change anything before
     * component was initialized (angular bug)
     * @see https://stackoverflow.com/questions/43111474/how-to-stop-ngonchanges-called-before-ngoninit
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        const run = () => {
            if ('form' in changes) {
                this.form = changes.form.currentValue;
            }

            if ('formElements' in changes) {
                this.formElements = changes.formElements.currentValue;
            }
        };

        if (!this.inited) {
            this.change = {
                run: run,
            };
        } else {
            run();
        }
    }

    /**
     * initialize form elements
     */
    init() {
        const scope = this;
        this.formElementsOrig = [];
        this.formElements.forEach((fe: FcbFormElement) => {
            if (fe !== undefined) {
                if (fe.type === 'image') {
                    fe['showUpload'] = false;
                }

                // if (fe.type === 'file-upload' && 'uploadGetUploadedFiles' in fe) {
                //   fe.uploadGetUploadedFiles?.(this.formGroup, this.formElements).subscribe((response: any) => {
                //     this.uploadFiles = response.data;
                //   }, () => {
                //     this.notificationService.error('Fehler beim Laden von Daten');
                //     this.uploadFiles = [];
                //   });
                // } else {
                //   this.uploadFiles = [];
                // }

                // if (fe.type === 'date') {
                //     if (!fe.minValue) {
                //         fe.minValue = new Date(1900, 0, 1);
                //     }
                //     if (!fe.maxValue) {
                //         fe.maxValue = new Date(2199, 0, 1);
                //     }
                // }

                if (!('value' in fe)) {
                    fe.value = 'defaultValue' in fe ? fe.defaultValue : '';
                }

                fe.formControl = new UntypedFormControl(
                    fe.value,
                    fe.validators && fe.validators.length ? fe.validators : [],
                );

                if (fe.type === 'datetime') {
                    // min and max values
                    if (!fe.minValue) {
                        fe.minValue = new Date(1900, 0, 1);
                    }
                    if (!fe.maxValue) {
                        fe.maxValue = new Date(2199, 0, 1);
                    }
                    // mm and hh elements
                    const d = fe.value ? moment(fe.value) : null;
                    if (!fe.mm) {
                        fe.mm = fe.value ? d?.get('m') : 0;
                    }
                    if (!fe.hh) {
                        fe.hh = fe.value ? d?.get('h') : 0;
                    }
                    fe.mmFormControl = new UntypedFormControl(fe.mm, []);
                    fe.hhFormControl = new UntypedFormControl(fe.hh, []);
                    fe.hhFormControl.valueChanges.subscribe((val: any) => {
                        const checkDate = fe.formControl?.value.format('YYYYMMDD HH:mm');
                        const checkMoment = fe.formControl?.value.clone();
                        checkMoment.hour(val);
                        if (checkMoment.format('YYYYMMDD HH:mm') !== checkDate) {
                            fe.formControl?.setValue(checkMoment);
                        }
                    });
                    fe.mmFormControl.valueChanges.subscribe((val: any) => {
                        const checkDate = fe.formControl?.value.format('YYYYMMDD HH:mm');
                        const checkMoment = fe.formControl?.value.clone();
                        checkMoment.minute(val);
                        if (checkMoment.format('YYYYMMDD HH:mm') !== checkDate) {
                            fe.formControl?.setValue(checkMoment);
                        }
                    });
                    fe.formControl.valueChanges.subscribe((val) => {
                        const checkDate = val.format('YYYYMMDD HH:mm');
                        const checkMoment = val.clone();
                        checkMoment.hour(fe.hhFormControl.value);
                        checkMoment.minute(fe.mmFormControl.value);
                        if (checkMoment.format('YYYYMMDD HH:mm') !== checkDate) {
                            fe.formControl?.setValue(checkMoment);
                        }
                    });
                }

                if (fe.type === 'time') {
                    const d = fe.value ? fe.value.slice(0, -11) : null;
                    const timeArr = d !== null ? d.split(':') : ['00', '00'];

                    if (!fe.hh) {
                        fe.hh = fe.value ? timeArr[0] : '';
                    }

                    if (!fe.mm) {
                        fe.mm = fe.value ? timeArr[1] : '';
                    }

                    fe.mmFormControl = new UntypedFormControl(fe.mm, []);
                    fe.hhFormControl = new UntypedFormControl(fe.hh, []);

                    fe.mmFormControl.setValue(parseInt(fe.mm, 10));
                    fe.hhFormControl.setValue(parseInt(fe.hh, 10));
                    fe.hhFormControl.valueChanges.subscribe(() => {
                        fe.formControl?.setValue(fe.hhFormControl.value);
                    });
                    fe.mmFormControl.valueChanges.subscribe(() => {
                        fe.formControl?.setValue(fe.mmFormControl.value);
                    });
                }

                if (fe.type === 'date' && fe.value) {
                    fe.formControl.setValue(moment(fe.value).format('YYYY-MM-DD'));
                }

                if (fe.type === 'checkboxes') {
                    const values = fe.formControl.value !== null ? fe.formControl.value : [];
                    fe.formControl.setValue(values.map((value) => value[fe.optionValueSelector]));
                }

                if (fe.disabled) {
                    fe.formControl.disable();
                }

                if (fe.onChange) {
                    fe.formControl.valueChanges.subscribe((val) => {
                        fe.onChange(val, scope);
                    });
                }

                this.formElementsOrig.push(Object.assign({}, fe));
            }
        });

        this.formElements = this.filterFormElements(this.formElements);
        this.formGroup = this.toFormGroup(this.formElements);
        this.inited = true;
    }

    /**
     * submit modal
     */
    submit(): void {
        this.onSubmit.emit(this.getValue());
    }

    /**
     * return modal value
     */
    getValue(): any {
        const value: any = {};
        this.formElementsOrig.forEach((fe: FcbFormElement) => {
            if (this.isElementPresent(fe)) {
                value[fe.selector] = this.formGroup.value[fe.selector];

                // why do we need it? check by undefined
                if (
                    !value[fe.selector] &&
                    value[fe.selector] !== '' &&
                    value[fe.selector] !== false &&
                    value[fe.selector] !== 0 &&
                    value[fe.selector] !== null
                ) {
                    // fallback
                    value[fe.selector] = 'value' in fe ? fe.value : fe.defaultValue || '';
                }

                // checkbox default false value to boolean
                if (fe.type === 'checkbox' && value[fe.selector] === null) {
                    value[fe.selector] = false;
                }

                if (fe.type === 'datetime') {
                    value[fe.selector].hour(this.formGroup.value[fe.selector + '_hh']);
                    value[fe.selector].minute(this.formGroup.value[fe.selector + '_mm']);
                }

                if (fe.type === 'time') {
                    let hours = this.formGroup.value[fe.selector + '_hh'];
                    hours = hours && parseInt(hours, 10) < 10 ? '0' + parseInt(hours, 10) : hours;
                    const minutes = this.formGroup.value[fe.selector + '_mm'] || '00';
                    value[fe.selector] = hours ? hours + ':' + minutes : '';
                }

                if (fe.type === 'image') {
                    const file = this.uploadFiles.find((fileObject: any) => fileObject.selector === fe.selector);
                    value[fe.selector] = file ? file.file : undefined;
                }
            }
        });

        return value;
    }

    clearFormControl(formControl: any): void {
        formControl.setValue('');
    }

    /**
     * checks whether the whole form is valid or not
     */
    isValid(): boolean {
        let result = true;

        this.formElementsOrig.forEach((fe: FcbFormElement, i: number) => {
            if (this.isElementPresent(fe) && !this.isElementValid(fe, this.formGroup.get(fe.selector), i)) {
                result = fe.type !== 'image' ? false : this.isImageValid(fe);
            }
        });

        return result;
    }

    isImageValid(fe: any): boolean {
        return this.uploadFiles.findIndex((item: any) => item.selector === fe.selector) !== -1;
    }

    /**
     * checks whether form control element is valid or not
     * @param element
     * @param formControl
     */
    isElementValid(element: FcbFormElement, formControl: AbstractControl | null, index?: number): boolean {
        let result = true;
        const validators = element.validators || [];

        validators.forEach((validator: ValidatorFn | any) => {
            if (result) {
                // if not false yet
                if (validator(formControl)) {
                    // returns null if element is okay
                    result = false;
                }
            }
        });

        // element.formControl.markAsTouched();
        const mustValidate = (element.validators && element.validators.length > 0) || false;
        const hasError = !result;
        const errorText = element.errorText ? element.errorText : 'Invalid or empty entry';

        if (mustValidate) {
            const errorClass = 'mat-form-field-invalid';
            element.validate = mustValidate;
            element.error = hasError;
            element.errorText = errorText;
            // set current output as well
            for (let i = 0; i < this.formElements.length; i++) {
                const fe = this.formElements[i];
                if (element.selector === fe.selector) {
                    fe.validate = mustValidate;
                    fe.error = hasError;
                    fe.errorText = errorText;
                }
            }
        }
        return result;
    }

    removeValidators(selector: string) {
        const element = this.getFormElementOrigBySelector(selector);
        if (element) {
            element.formControl?.clearValidators();
            element.error = false;
            element.validate = false;
            element.validators = [];
        }
    }

    setValidators(selector: string, validators: any) {
        const element = this.getFormElementOrigBySelector(selector);
        if (element !== undefined) {
            element.formControl?.setValidators(validators);
            element.formControl?.updateValueAndValidity();
            element.validators = validators;
        }
    }

    getTextAreaMinRows(element: FcbFormElement): number {
        return element.textareaMinRows ? element.textareaMinRows : 3;
    }

    getFormElementOrigBySelector(selector: string) {
        return this.formElementsOrig.find((item: any) => item.selector === selector);
    }

    /**
     * converts FcbFormElements to a FormGroup
     *
     * @param elements a array of FcbFormElement
     */
    toFormGroup(elements: FcbFormElement[]) {
        const group: any = {};
        elements.forEach((element) => {
            group[element.selector] = element.formControl;
            if (element.type === 'datetime') {
                group[element.selector + '_hh'] = element.hhFormControl;
                group[element.selector + '_mm'] = element.mmFormControl;
            }
            if (element.type === 'time') {
                group[element.selector + '_hh'] = element.hhFormControl;
                group[element.selector + '_mm'] = element.mmFormControl;
            }
        });
        return new UntypedFormGroup(group);
    }

    /**
     * filters form elements
     */
    filterFormElements(elements: FcbFormElement[]): FcbFormElement[] {
        const filteredElements: FcbFormElement[] = [];
        elements.forEach((element) => {
            // add element if visible is not false
            if (!('visible' in element && element.visible === false)) {
                filteredElements.push(element);
            }
        });
        return filteredElements;
    }

    /**
     * returns a id string
     * @param base
     * @param unique
     */
    getElementId(base: string, unique: string): string {
        return base + '-' + unique;
    }

    /**
     * returns a array of option groups
     * @param element
     */
    getOptionGroups(element: FcbFormElement): string[] {
        const groups: any[] = [];
        let lastGroup = '';
        // element.options.forEach((o) => {
        //   const group = o[element.optionGroupSelector];
        //   if (group !== lastGroup) {
        //     groups.push(group);
        //   }
        //   lastGroup = group;
        // });
        return groups;
    }

    /**
     * returns options for a option group
     */
    getOptionsForGroup(element: FcbFormElement, group: string): string[] {
        const options: any[] = [];
        // element.options.forEach((o) => {
        //   if (o[element.optionGroupSelector] === group) {
        //     options.push(o);
        //   }
        // });
        return options;
    }

    /**
     * checks whether the element is present or not
     * @param element
     */
    isElementPresent(element: FcbFormElement): boolean {
        // if ('isPresent' in element) {
        //   return element.isPresent(this.formGroup, this.formElementsOrig);
        // }
        return true;
    }

    /**
     * load uploaded files
     * @param fe
     */
    loadUploadedFiles(fe: FcbFormElement) {
        if (fe !== undefined) {
            this.uploadFiles = [];

            // fe.uploadGetUploadedFiles(this.formGroup, this.formElements).subscribe((response: any) => {
            //   this.uploadFiles = response.data;
            // }, () => {
            //   this.notificationService.error('Fehler beim Laden von Daten');
            //   this.uploadFiles = [];
            // });
        }
    }

    /**
     * toggle checkbox
     * @param item
     */
    checkbox(item: any) {
        this.formElements.forEach((fe: FcbFormElement) => {
            if (fe.selector === item && fe.type === 'checkbox') {
                fe.value = !fe.value;
            }
        });
        this.formElementsOrig.forEach((fe: FcbFormElement) => {
            if (fe.selector === item && fe.type === 'checkbox') {
                fe.value = !fe.value;
            }
        });
    }

    isFirstCheckboxArr(item: any) {
        const result = this.formElements.filter(
            (fe: FcbFormElement) => fe.sectionName === item.sectionName && fe.type === 'checkbox-array',
        );
        return item.selector === result[0].selector;
    }

    showLegend(element: any) {
        this.legendClicked.emit(element);
    }

    /**
     * show toolip with full element name if element length more than limit
     */
    getToolip(name: string): string {
        return name.length > this.charactersLimit ? name : '';
    }

    uploadFile(fileEvent: any, selector: string) {
        const index = this.uploadFiles.findIndex((item: any) => item.selector === selector);

        if (index === -1) {
            this.uploadFiles.push({
                selector: selector,
                file: fileEvent.file[0],
            });
        } else {
            if (fileEvent.file.length === 0) {
                this.uploadFiles.splice(index, 1);
            }

            if (fileEvent.file.length !== 0) {
                this.uploadFiles[index] = {
                    selector: selector,
                    file: fileEvent.file[0],
                };
            }
        }
    }
}

/**
 * represents a single form
 */
export class FcbForm {
    elements: FcbFormElement[] = [];
    format?: string = ''; // '' (default) | 'horizontal'
    title(item: any): string {
        return '';
    }
}

/**
 * modal form element definition
 */
export class FcbFormElement {
    /**
     * the name of the element
     */
    name: string = '';

    /**
     * the selector of the element
     */
    selector: string = '';

    checkboxLabel?: string;

    /**
     * the type of the element
     * e.g. 'text'
     */
    type: string = '';

    /**
     * only for type image
     */
    showUpload?: boolean = false;

    /**
     * a initial value for the element
     */
    value?: any;

    /**
     * the default value
     */
    defaultValue?: any;

    /**
     * html input min and max, step for number
     */
    minValue?: any;
    maxValue?: any;
    step?: any;

    /**
     * order of element
     */
    order?: number;

    /**
     * is element visible
     */
    visible?: boolean;

    /**
     * options if type is 'select'
     */
    options?: any[];

    /**
     * section name if type is 'checkbox-array'
     */
    sectionName?: string;

    legend?: string;
    legendName?: string;

    rightSide?: boolean = false;

    optionTextSelector?: string;
    optionValueSelector?: string;
    optionGroupSelector?: string = '';
    optionEmpty?: boolean;
    optionEmptyText?: string;
    optionEmptyValue?: any;

    textareaMinRows?: number;

    dropzoneConfig?: any;

    /**
     * add class names for formatting
     */
    class?: string = '';

    disabled?: boolean = false;
    onChange?: any;
    amount?: number;
    mm?: any; // datetime
    hh?: any; // datetime
    mmFormControl?: any; // formControl
    hhFormControl?: any; // formControl
    /**
     * element validator
     */
    validators?: any[];
    validate?: boolean;
    error?: boolean;
    errorText?: string;
    formControl?: UntypedFormControl;
    matcher?: ErrorStateMatcher = new ErrorStateMatcher();

    /**
     * checks if current element is present
     * @param formGroup
     */
    isPresent?(formGroup?: UntypedFormGroup, formElementsOrig?: FcbFormElement[]): boolean;

    /**
     * is element in form value
     * @param formGroup
     */
    isInFormValue?(formGroup?: UntypedFormGroup): boolean;

    /**
     * return a list of files for the file upload component
     * @param formGroup
     */
    uploadGetUploadedFiles?(formGroup?: UntypedFormGroup, formElementsOrig?: FcbFormElement[]): Observable<Object>;

    /**
     * on delete file
     * @param file
     */
    uploadOnDelete?(file: any, formElementsOrig?: FcbFormElement[]): void;

    uploadOnUpload?(file: any, formElementsOrig?: FcbFormElement[]): void;

    dropzoneError?(response: any): void;

    dropzoneSuccess?(response: any): void;
}
