<div class="team-drive-container un-scroll" [class.move-left]="isChatOpened">
    <app-drive
        [documents]="documents"
        [folders]="folders"
        [teamId]="teamId"
        [finalVersion]="finalVersion"
        [isLoading]="isLoading"
        [isUpload]="false"
        [isBreadcrumbs]="true"
        (fileUpload)="upload($event)"
    >
    </app-drive>
</div>
