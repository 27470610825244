import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { finalize } from 'rxjs/operators';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { ManageInstitutionsTable } from 'src/app/tables/ManageInstitutionsTable';
import { Tools } from 'src/app/tools';

@Component({
    selector: 'app-manage-institutions',
    templateUrl: './manage-institutions.component.html',
    styleUrls: ['./manage-institutions.component.css'],
})
export class ManageInstitutionsComponent implements OnInit {
    institutions: any[] = [];
    isLoading: boolean = false;
    ENTRY_NAME: string = 'Institution';

    storageUrl: string = environment.storageUrl;

    table = new ManageInstitutionsTable();

    constructor(
        private institutionsService: InstitutionsService,
        private notificationService: NotificationService,
        public sessionService: SessionService
    ) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.institutionsService.getAll().subscribe((response: any) => {
            this.institutions = response.data || [];
        });
    }

    create(data: any) {
        this.institutionsService
            .create(Tools.getFormData(data))
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' created successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    update(data: any) {
        this.institutionsService
            .update(data.id, Tools.getFormData(data))
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' updated successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    delete(data: any) {
        this.institutionsService
            .delete(data.id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' deleted successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }
}
