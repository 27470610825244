<nav
    class="navbar navbar-expand-md navbar-dark fixed left-0 right-0 top-0 z-[1000] flex items-center justify-between bg-[#08051F] md:bg-transparent"
>
    <div class="navbar-header flex items-center gap-x-2 pl-3 text-center">
        <div class="lg:hidden">
            <app-un-header-button [icon]="'list'" (click)="toggleSideBar()"> </app-un-header-button>
        </div>
        <a class="navbar-brand m-0 w-full" routerLink="/">
            <app-logo></app-logo>
        </a>
    </div>

    <div class="right-nav">
        <ul class="navbar-nav">
            <li *ngIf="!simplified" class="nav-item">
                <app-un-header-button
                    *ngIf="isVideoChatActive"
                    [text]="'navbar.video_chat' | translate"
                    [icon]="'video-camera'"
                    (click)="toggleVideoChat()"
                ></app-un-header-button>
            </li>

            <li *ngIf="isVideoChatActive && !simplified" class="nav-item">
                <app-un-header-button
                    [text]="'navbar.team_drive' | translate"
                    [icon]="'folders'"
                    [selected]="isDriveActive"
                    (click)="toggleDrive()"
                >
                </app-un-header-button>
            </li>

            <li *ngIf="isVideoChatActive && !simplified" class="nav-item">
                <app-un-header-button
                    [text]="'navbar.team_chat' | translate"
                    [icon]="'chat-circle-dots'"
                    [selected]="isChatActive"
                    (click)="toggleChat()"
                ></app-un-header-button>
            </li>

            <li class="dropdown flex items-center">
                <app-un-header-button
                    [matMenuTriggerFor]="menu"
                    [text]="currentUser.nickname"
                    [icon]="'student'"
                    [isDropdown]="true"
                    [truncate]="8"
                >
                </app-un-header-button>

                <mat-menu #menu="matMenu" backdropClass="customize" style="top: -58px">
                    <div mat-menu-item (click)="logOut()">
                        <div class="flex items-center" style="gap: 8px">
                            <i class="ph-sign-out"></i>
                            <span>{{ 'navbar.logout' | translate }}</span>
                        </div>
                    </div>

                    <div *ngIf="isAbleChangeLang" mat-menu-item>
                        <app-un-lang-switch></app-un-lang-switch>
                    </div>

                    <div *ngIf="!simplified" mat-menu-item (click)="navigateMenu('/my-account')">
                        <div class="flex items-center" style="gap: 8px">
                            <i class="ph-identification-badge"></i>
                            <span>{{ 'navbar.edit' | translate }}</span>
                        </div>
                    </div>
                    <div *ngIf="!simplified" mat-menu-item (click)="navigateMenu('/profile')">
                        <div class="flex items-center" style="gap: 8px">
                            <i class="ph-user"></i>
                            <span>{{ 'navbar.profile' | translate }}</span>
                        </div>
                    </div>
                </mat-menu>
            </li>
        </ul>
    </div>
</nav>

<ng-container *ngIf="isChatActive && globalTeamId && route.snapshot.queryParamMap.get('tab') === 'tasks'">
    <app-team-chat [team]="globalTeamId"></app-team-chat>
</ng-container>

<ng-container *ngIf="isDriveActive && globalTeamId && route.snapshot.queryParamMap.get('tab') === 'tasks'">
    <app-team-drive [isChatOpened]="isDriveActive" [teamId]="globalTeamId"></app-team-drive>
</ng-container>
