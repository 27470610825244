import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
    selector: 'app-un-select',
    templateUrl: './un-select.component.html',
    styleUrls: ['./un-select.component.scss'],
})
export class UnSelectComponent implements OnChanges {
    @Input() options: any = [];
    @Input() selectedOptionId: number = 0;
    @Input() disabled: boolean | undefined = false;

    selectedOption: any;

    @Output() selected: EventEmitter<any> = new EventEmitter();

    setSelectedOption() {
        return this.options.find((option: any) => option.id === this.selectedOptionId);
    }

    ngOnInit() {
        this.selectedOption = this.setSelectedOption();
    }

    ngOnChanges() {
        this.selectedOption = this.setSelectedOption();
    }

    select(option: any) {
        this.selectedOption = option;
        this.selected.emit(option.id);
    }
}
