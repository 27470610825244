import { Component, OnInit } from '@angular/core';
import { LocationsService } from 'src/app/services/locations.service';

@Component({
    selector: 'app-locations',
    templateUrl: './locations.component.html',
    styleUrls: ['./locations.component.scss'],
    providers: [],
})
export class LocationsComponent implements OnInit {
    isLoading: boolean = false;

    locations: any[] = [];

    constructor(private locationsService: LocationsService) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.locationsService.getAll().subscribe((response: any) => {
            this.locations = response.data || [];
        });
    }
}
