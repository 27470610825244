import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { DriveService } from 'src/app/services/drive.service';
import { animateOpacity } from 'src/app/animations/fly-animations';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Tools } from 'src/app/tools';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-task-drive',
    templateUrl: './task-drive.component.html',
    styleUrls: ['./task-drive.component.scss'],
    animations: [animateOpacity],
})
export class TaskDriveComponent implements OnInit {
    @Input() taskId: number = 0;

    documents: any[] = [];

    isLoading: boolean = false;

    teamId: number = 0;

    subscriptions: Subscription[] = [];

    constructor(
        private driveService: DriveService,
        private notificationService: NotificationService,
        public dialog: MatDialog,
        public translate: TranslateService,
        public session: SessionService
    ) {
        this.subscriptions.push(this.driveService.folderContentChanged$.subscribe(() => this.fetch()));
    }

    ngOnInit() {
        this.fetch();
        this.teamId = this.session.getCurrentUser().team_id;
    }

    ngOnDestroy() {
        Tools.unsubscribeAll(this.subscriptions);
    }

    fetch() {
        this.isLoading = true;
        this.driveService
            .getByTaskId(this.taskId)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe((response: any) => {
                this.documents = response.data.documents || [];
            });
    }

    upload(uploadEvent: any) {
        const formData = new FormData();
        formData.append('file', uploadEvent.file, uploadEvent.file.name);

        this.isLoading = true;

        this.driveService
            .addDocumentByTask(this.taskId, formData)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                () => {
                    this.dialog.closeAll();
                    this.fetch();
                    this.notificationService.success(this.DOC_UPLOAD_SUCCESS_TOASTR);
                    this.driveService.folderContentChangedEmit();
                },
                (err) => {
                    this.notificationService.error(err.error.message, this.ERROR_TOASTR);
                }
            );
    }

    get DOC_UPLOAD_SUCCESS_TOASTR() {
        return this.translate.instant('communications.section.doc_upload_success_toastr');
    }

    get ERROR_TOASTR() {
        return this.translate.instant('communications.section.error_toastr');
    }
}
