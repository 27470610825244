<div class="flex h-full">
    <div class="w-full h-full">
        <app-un-tabs [tabs]="tabs" (tabChanged)="setTab($event)">
            <div header class="flex flex-1 items-start" style="gap: 48px">
                <div class="w-full">
                    <app-un-gradient-text text="Manage games" size="big"></app-un-gradient-text>
                </div>
            </div>

            <ng-container body>
                <app-un-tab *ngIf="activeTab.name === 'Disciplines'">
                    <div class="my-4">
                        <app-manage-disciplines></app-manage-disciplines>
                    </div>
                </app-un-tab>

                <app-un-tab *ngIf="activeTab.name === 'Institutions'">
                    <div class="my-4">
                        <app-manage-institutions></app-manage-institutions>
                    </div>
                </app-un-tab>

                <app-un-tab *ngIf="activeTab.name === 'Locations'">
                    <div class="my-4">
                        <app-manage-locations></app-manage-locations>
                    </div>
                </app-un-tab>

                <app-un-tab *ngIf="activeTab.name === 'Mini-games'">
                    <div class="my-4">
                        <app-manage-mini-games></app-manage-mini-games>
                    </div>
                </app-un-tab>
            </ng-container>
        </app-un-tabs>
    </div>
</div>
