import { Component, Input, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-un-game-card',
  templateUrl: './un-game-card.component.html',
  styleUrls: ['./un-game-card.component.scss']
})
export class UnGameCardComponent {

  @Input() card: any;

  storageUrl: string = environment.storageUrl;
  selectedEpisode: any;


  constructor(
    private router: Router,
    public dialog: MatDialog,
  ) { }


  like() {

    if (this.card['animation']) {
      return;
    }

    this.card['animation'] = true;

    setTimeout(() => {
      this.card.like = !this.card.like;
      this.card['animation'] = false;
    }, 1200);

  }

  openUnityGame() {
    this.router.navigate(['/' + 'space-game']);
  }

  openDialog(e: any, dialogRef: TemplateRef<any>, episode: string) {
    if (e.target.classList.contains('like')) {
      return;
    }
    this.dialog.open(dialogRef);
    this.selectedEpisode = episode;
  }

  yes() {
    this.dialog.closeAll();
    this.openUnityGame();
  }

  no() {
    this.dialog.closeAll();
  }

}
