<div class="step-header mb-5">
    <div class="flex step-header-wrapper">
        <div class="col-2">
            <img [src]="imagePath" style="max-width: 100%" />
        </div>
        <div class="col-10 flex text-start" [class.flex-col]="!centerText" [class.items-center]="centerText">
            <h4 [innerHTML]="name | nl2br | safe: 'html'"></h4>
            <div class="mb-3">
                <h4 [innerHTML]="description | nl2br | safe: 'html'"></h4>
            </div>
        </div>
    </div>
</div>
