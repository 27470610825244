import { settings, environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-com-personal-challenges',
  templateUrl: './com-personal-challenges.component.html',
  styleUrls: ['./com-personal-challenges.component.scss'],
  providers: [],
})
export class СomPersonalChallengesComponent implements OnInit {

  isLoading: boolean = false;

  challenges: any[] = [
    {
      users: [
        {
          name: 'Sagi Vaksin',
          image_path: '/assets/rust.jpg'
        },
        {
          name: 'Sagi Vaksin',
          image_path: '/assets/rust.jpg'
        },
        {
          name: 'Sagi Vaksin',
          image_path: '/assets/rust.jpg'
        }
      ],
      name: 'Create 10 Personal tasks',
      type: 'Communication',
      progress: 75,
      contributors: 125,
      approved: 87,
      time_left: '2 Days left'
    },
    {
      users: [
        {
          name: 'Sagi Vaksin',
          image_path: '/assets/rust.jpg'
        },
        {
          name: 'Sagi Vaksin',
          image_path: '/assets/rust.jpg'
        }
      ],
      name: 'Create 10 Personal tasks',
      type: 'Game',
      progress: 50,
      contributors: 125,
      approved: 87,
      time_left: '2 Days left'
    },
    {
      users: [
        {
          name: 'Sagi Vaksin',
          image_path: '/assets/rust.jpg'
        }
      ],
      name: 'Create 10 Personal tasks',
      type: 'Communication',
      progress: 90,
      contributors: 125,
      approved: 87,
      time_left: '2 Days left'
    },
  ];



  constructor() { }


  ngOnInit() {
  }



}
