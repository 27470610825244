<div>
    <div class="flex flex-col">
        <div class="friends-zone un-scroll">
            <mat-slide-toggle class="mb-2" [checked]="isSent" (change)="slideChanged($event)">{{
                'friend_bar.incoming_outgoing' | translate
            }}</mat-slide-toggle>

            <div *ngIf="!isLoading">
                <ng-container *ngIf="selectedRequests.length > 0">
                    <ng-container *ngFor="let friend of selectedRequests">
                        <ng-template *ngTemplateOutlet="templateFriend; context: { $implicit: friend }"></ng-template>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="selectedRequests.length === 0">
                    <span>{{ 'friend_bar.no_new_requests' | translate }}</span>
                </ng-container>
            </div>
        </div>

        <div class="flex justify-center my-5">
            <mat-spinner *ngIf="isLoading" [diameter]="70"></mat-spinner>
        </div>
    </div>
</div>

<ng-template #templateFriend let-friend>
    <div class="grid-container items-center">
        <div class="grid-item position-relative">
            <div class="position-relative" style="width: 35px; height: 35px">
                <img class="friend-img" [src]="friend?.image_path" />
            </div>
        </div>

        <div class="grid-item font-weight-400 ml-2" style="font-size: 0.85em">
            <div class="cursor-pointer" (click)="goToChat(friend.nickname)">{{ friend.nickname }}</div>
            <div class="font-weight-400 cursor-pointer flex" style="gap: 8px">
                <ng-container *ngIf="!isSent">
                    <button
                        matRipple
                        class="set-friend-button accept"
                        (click)="sumbitFriendsRequest(friend.friend_user_id, 'approved')"
                    >
                        {{ 'friend_bar.accept' | translate }}
                    </button>
                    <button
                        matRipple
                        class="set-friend-button decline"
                        (click)="sumbitFriendsRequest(friend.friend_user_id, 'blocked')"
                    >
                        {{ 'friend_bar.decline' | translate }}
                    </button>
                </ng-container>
                <ng-container *ngIf="isSent">
                    <button matRipple class="set-friend-button decline">
                        {{ 'friend_bar.waiting_response' | translate }}
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>
