import { settings, environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-administration',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.css'],
    providers: [],
})
export class AdministrationComponent implements OnInit {
    isLoading: boolean = false;

    // route params
    baseRoute: string = '';
    component: string = 'dashboard'; // set by current route, defaults to 'dashboard'

    nav: any[] = [
        // {
        //   component: 'administration',
        //   title: 'Administration',
        //   visible: true
        // },
        {
            component: 'users',
            title: 'Users',
            visible: true,
        },
        {
            component: 'games',
            title: 'Games',
            visible: true,
        },
        {
            component: 'schools',
            title: 'School',
            visible: true,
        },
        // {
        //     component: 'vote',
        //     title: 'Vote',
        //     visible: true,
        // },
    ];

    playerList: any[] = [];

    routerSubscriber: any;

    storageUrl: string = environment.storageUrl;

    constructor(
        private title: Title,
        private route: ActivatedRoute,
        private router: Router,
        private notificationService: NotificationService,
        public sessionService: SessionService,
    ) {}

    ngOnInit() {
        this.title.setTitle(settings.title + 'Administration');
        this.routerSubscriber = this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.updateRoute();
            }
        });
        this.updateRoute();
    }

    ngOnChanges() {
        this.updateRoute();
    }

    // get title() {
    //   return ''
    //   return this.nav.find((nav: any) => nav.component === this.component).title;
    // }

    isActive(component: any): any {
        const permissions: {
            [key: string]: number | string | boolean;
        } = {
            administration: true,
            users: true,
            games: true,
            schools: true,
            vote: true,
        };
        return permissions[component];
    }

    ngOnDestroy() {
        this.routerSubscriber.unsubscribe();
    }

    /**
     * set and update the current route
     */
    updateRoute() {
        const component = this.route.snapshot.paramMap.get('component') || 'administration';
        let foundEntry = false;
        for (const entry of this.nav) {
            if (entry.component === component) {
                foundEntry = true;
                break;
            }
        }
        this.component = foundEntry ? component : 'administration';
        this.baseRoute = '/administration/';
    }
}
