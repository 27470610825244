<div class="flex mb-3 items-center">
    <span class="font-weight-bold text-uppercase text-sm">
        <span>{{ 'communications.info.change_mentors' | translate }}</span>
    </span>
    <i class="ph-info-bold interactive mx-2 text-lg"></i>
</div>

<div mat-dialog-content class="un-scroll h-[44vh] min-h[400px] min-w-[380px]">
    <ng-container *ngFor="let user of users">
        <app-un-user-row [user]="user" (click)="toggleMentor(user)"></app-un-user-row>
    </ng-container>
</div>

<div mat-dialog-actions class="flex justify-center">
    <div style="zoom: 0.7">
        <app-un-save-button [text]="'communications.workspace.save' | translate" (click)="submit()">
        </app-un-save-button>
    </div>
</div>
