<app-login-card>
    <form class="space-y-4 md:space-y-6 mb-4" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div>
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Your email </label>
            <input
                type="email"
                name="email"
                id="email"
                formControlName="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@company.com"
                required=""
            />
        </div>
        <div>
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
            <input
                type="password"
                name="password"
                id="password"
                formControlName="password"
                placeholder="••••••••"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required=""
            />
        </div>
        <div class="flex items-center justify-between mb-3">
            <div class="flex items-start">
                <div class="flex items-center h-5">
                    <input
                        id="remember"
                        aria-describedby="remember"
                        type="checkbox"
                        class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        required=""
                    />
                </div>
                <div class="ml-3 text-sm">
                    <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>
                </div>
            </div>
            <a
                routerLink="/forgot-password"
                class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
            >
                Forgot password?
            </a>
        </div>
        <div>
            <app-un-magic-button
                style="width: 100%"
                (click)="onSubmit()"
                [text]="'SIGN IN'"
                [fullWidth]="true"
                [disabled]="!formGroup.valid || isLoading"
                [bordered]="true"
                [fullWidth]="true"
            ></app-un-magic-button>
        </div>
    </form>
</app-login-card>
