<form [formGroup]="changePasswordForm" class="flex justify-center flex-col items-center gap-4">
    <app-text-input
        class="w-full"
        [type]="'password'"
        [placeholder]="'account.old_password' | translate"
        formControlName="current"
    >
    </app-text-input>
    <app-text-input
        class="w-full"
        [type]="'password'"
        [placeholder]="'account.new_password' | translate"
        formControlName="new"
    >
    </app-text-input>
    <app-text-input
        class="w-full"
        [type]="'password'"
        [placeholder]="'account.confirm_password' | translate"
        formControlName="confirmPassword"
    >
    </app-text-input>

    <div
        *ngIf="!newPassword?.errors?.minlength && newPassword?.value && !isConfirmed"
        class="flex w-full justify-start text-sm text-red-500"
    >
        {{ 'account.password_match' | translate }}
    </div>
    <div class="flex justify-end w-full">
        <app-un-save-button
            [text]="'account.change_password' | translate"
            [disabled]="!isValid || isLoading"
            (click)="changePassword()"
        ></app-un-save-button>
    </div>
</form>
