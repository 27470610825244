<app-un-back-button (сlicked)="goBack()"></app-un-back-button>

<div class="start-screen-wrapper container">
    <div class="flex justify-center mb-5" style="gap: 40px">
        <div class="col-6 text-start">
            <h1 [@flyInOut] class="mb-5" style="width: 80%">
                {{ 'questionnaire.suggest.greeting_header_project' | translate }}
            </h1>
        </div>

        <div class="col-6 text-start">
            <div class="text-mute mb-4">
                <div class="mb-3" style="width: 80%">
                    <div [@flyRigthIn] class="mb-5">{{ 'questionnaire.suggest.greeting_header_desc' | translate }}</div>

                    <div [@flyleftIn] class="underline-spacer"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex mb-4" style="gap: 40px">
        <div [@flyInOut] class="flex-fill col-6">
            <form [formGroup]="themeForm" class="flex flex-col" (ngSubmit)="onSubmit()">
                <mat-form-field class="mb-5 px-3 py-2 main-card hi-image">
                    <mat-label>{{ 'questionnaire.suggest.info_form_name_project_placeholder' | translate }}</mat-label>
                    <input matInput formControlName="title" />
                </mat-form-field>

                <mat-form-field class="px-3 py-2 main-card">
                    <mat-label>{{ 'questionnaire.suggest.info_form_desc_placeholder' | translate }}</mat-label>
                    <textarea
                        matInput
                        formControlName="description"
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="4"
                        cdkAutosizeMaxRows="5"
                    ></textarea>
                </mat-form-field>
            </form>
        </div>

        <div [@flyBottomIn] class="flex-fill col-6 flex flex-col justify-center items-center main-card">
            <!-- <img class="mb-2" src="/assets/media.png" alt=""> -->

            <div class="mb-2" style="width: 80px; height: 80px; font-size: 2em">
                <app-un-image-upload (fileChanged)="setImage($event)"></app-un-image-upload>
            </div>

            <div>
                <span class="text-mute">{{ 'questionnaire.suggest.upload' | translate }}</span>
            </div>

            <div>
                <span class="text-mute">{{ 'questionnaire.suggest.example' | translate }}</span>
            </div>
        </div>
    </div>

    <div [@flyInOut]>
        <app-un-save-button
            [text]="'questionnaire.suggest.info_form_save_topic_button' | translate"
            [disabled]="!themeForm.valid"
            (сlicked)="onSubmit()"
        ></app-un-save-button>
    </div>
</div>
