<div>
    <app-un-page-sub-panel
        [title]="user?.nickname"
        [backUrl]="'/communications/team/' + user?.team_id"
        [onlyBack]="true"
        [enableAvatarByName]="true"
    >
    </app-un-page-sub-panel>
</div>

<app-user-data-info *ngIf="user" [user]="user"></app-user-data-info>
