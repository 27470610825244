import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-team-info-user-box',
    templateUrl: './team-info-user-box.component.html',
    styleUrls: ['./team-info-user-box.component.scss'],
})
export class TeamInfoUserBoxComponent {
    @Input() user: any;
    @Input() isLeader: boolean = false;
    @Input() isAdmin: boolean = false;

    @Output() updateTeamLeader: EventEmitter<any> = new EventEmitter<any>();
    @Output() changeStudent: EventEmitter<any> = new EventEmitter<any>();
    @Output() removeStudentFromTeam: EventEmitter<any> = new EventEmitter<any>();

    defaultImageUrl: string = '/assets/blank-profile.png';
    storageUrl: string = environment.storageUrl;

    constructor(public session: SessionService) {}

    openMenu(event: any, user: any) {
        const parent = event.target.parentElement.closest('.user-info-row');
        if (parent) {
            const menuButton = parent.getElementsByClassName('menu-trigger');
            if (menuButton) {
                menuButton[0].click();
            }
        }
    }

    updateTeamLeaderEmit() {
        this.updateTeamLeader.emit(this.user);
    }

    changeStudentEmit() {
        this.changeStudent.emit(this.user);
    }

    removeFromTeam() {
        this.removeStudentFromTeam.emit(this.user);
    }

    ableManageStudents(): boolean {
        return (
            this.isAdmin || (this.session.getCurrentUser()?.role === 'Teacher' && this.session.getCurrentUser()?.mentor)
        );
    }

    ableChangeTeamLeader(): boolean {
        return (
            this.isAdmin ||
            (this.session.getCurrentUser()?.role === 'Teacher' && this.session.getCurrentUser()?.mentor) ||
            (this.session.getCurrentUser()?.role === 'Student' && this.session.getCurrentUser()?.mentor)
        );
    }
}
