import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Tools } from '../tools';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class FriendsService {
    private toggleFriendsSidebarSource = new Subject<void>();
    toggleFriendsSidebar$ = this.toggleFriendsSidebarSource.asObservable();

    private searchFriendsSource = new Subject<any>();
    searchFriends$ = this.searchFriendsSource.asObservable();

    private findFriendsSource = new Subject<any>();
    findFriends$ = this.findFriendsSource.asObservable();

    private sideBarFriendsListModeChangeSource = new Subject<any>();
    sideBarFriendsListModeChange$ = this.sideBarFriendsListModeChangeSource.asObservable();

    constructor(private http: HttpClient) {}

    getAll() {
        return this.http.get(new Url('/friends').path);
    }

    getUserFriends(nickname?: string) {
        const url = new Url('/users/friends');

        url.addQueryParameter('nickname', nickname);

        return this.http.get(url.path);
    }

    getFriendsRequests() {
        return this.http.get(new Url('/friends-invites').path);
    }

    sumbitFriendsRequest(data: any) {
        return this.http.put(new Url('/friends/request').path, data);
    }

    sendFriendsRequest(data: any) {
        return this.http.post(new Url('/friends/add').path, data);
    }

    getFriensByNickname(nickname: string) {
        return this.http.get(new Url('/users/nickname/' + nickname).path);
    }

    toggleFriendsSidebar() {
        this.toggleFriendsSidebarSource.next();
    }

    searchFriends(search: string) {
        this.searchFriendsSource.next(search);
    }

    findFriends(search: string) {
        this.findFriendsSource.next(search);
    }

    changeSideBarFriendsListMode(mode: string) {
        this.sideBarFriendsListModeChangeSource.next(mode);
    }
}
