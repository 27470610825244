<div class="flexbox">
  <div class="search">
    <div>
      <i class="fas fa-search fa-lg" style="color:#DADADA;"></i>
      <form (ngSubmit)="submit()" style="display: inline-flex;">
         <input
          #searchbox
          autofocus
          class="field"
          type="text"
          placeholder="'navbar.search_for_subject_or_game' | translate"
          required
          autocomplete="off">
      </form>
    </div>
  </div>
</div>
