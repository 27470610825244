<div *ngIf="!isLoading" class="flex flex-col justify-between h-full">
    <div class="sticky w-full top-0">
        <div class="flex items-center p-2 text-[#8E91D1]">
            <div *ngIf="showRecent" (click)="toggleDateFilter()" class="flex items-center cursor-pointer gap-2">
                <span>{{ 'communications.workspace.show_recent' | translate }}</span>
                <i class="ph-caret-down-bold"></i>
            </div>

            <div *ngIf="!showRecent" (click)="toggleDateFilter()" class="flex items-center gap-2">
                <span>{{ 'communications.workspace.show_first' | translate }}</span>
                <i class="ph-caret-up-bold"></i>
            </div>

            <div class="ml-auto rtl:mr-auto rtl:ml-0 flex items-center gap-5">
                <div class="flex gap-1">
                    <input
                        *ngIf="showSearch"
                        [@animateOpacity]
                        class="documents-search-input px-2"
                        [(ngModel)]="query"
                    />
                    <i class="ph-magnifying-glass-bold" style="font-size: 1.4em" (click)="toggleSearch()"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="p-2 un-scroll flex flex-col mb-auto gap-y-2 overflow-y-auto">
        <ng-container *ngFor="let activity of activities | search : 'name,userNickname' : query">
            <div class="flex p-2 activity-box w-full gap-2">
                <div>
                    <img
                        class="rounded-full w-8 h-8"
                        [src]="activity.user.main_image ? storageUrl + activity.user.main_image : defaultImageUrl"
                        src="/assets/profile-placeholder.jpg"
                    />
                </div>
                <div class="flex flex-col">
                    <span class="activity-box-name">
                        {{ activity.user.nickname }}
                        - {{ activity.datetime | date : 'dd.MM.yy HH:mm' }}
                    </span>
                    <span class="activity-box-activity text-nowrap">
                        <span>{{ activity.activity_type.title }}</span>
                        <ng-container *ngIf="isDocumentType(activity.activity_type.type)">
                            <a *ngIf="!isImageType(activity.document.doc_type.type)" [href]="activity.document.link">
                                <span> {{ activity.document.file_name | truncate : 20 }}</span>
                            </a>
                            <span
                                *ngIf="isImageType(activity.document.doc_type.type)"
                                (click)="openImageModal(viewImageModal, activity.document)"
                                class="interactive"
                            >
                                {{ activity.document.file_name | truncate : 20 }}
                            </span>
                        </ng-container>

                        <ng-container *ngIf="isDeleted(activity.activity_type.type)">
                            {{ activity.document.file_name | truncate : 20 }}
                        </ng-container>
                    </span>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div *ngIf="isLoading" class="flex justify-center items-center h-[400px]">
    <mat-spinner></mat-spinner>
</div>

<ng-template #viewImageModal>
    <div class="flex justify-between my-2">
        <app-un-gradient-text [text]="selectedImage.file_name" size="small"> </app-un-gradient-text>
        <div class="col-auto flex items-center">
            <a class="text-decoration-none shadow-none outline-none" [href]="selectedImage.link">
                <i class="ph-download-simple text-lg"></i>
            </a>
        </div>
    </div>
    <div class="un-scroll" mat-dialog-content style="min-width: 580px; min-height: 300px">
        <div class="w-full h-full">
            <img class="w-full h-auto" [src]="selectedImage.link" alt="" />
        </div>
    </div>
</ng-template>
