import { settings, environment } from 'src/environments/environment';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-communications-sections',
  templateUrl: './communications-sections.component.html',
  styleUrls: ['./communications-sections.component.scss'],
  providers: [],
})
export class CommunicationsSectionsComponent implements OnInit, AfterViewInit {

  isLoading: boolean = false;
  tabIndex = 0;
  subscriptions: Subscription[] = [];



  constructor(
    private title: Title,
    private activateRouter: ActivatedRoute,
  ) { }


  ngOnInit() {
    this.title.setTitle(settings.title + 'Games');
  }

  setGamesFromURl() {
    // this.subscriptions.push(
    //   this.activateRouter.queryParams.subscribe(params => {
    //     if (params['tab'] === 'games') {
    //       this.setTabIndex(3);
    //     }
    //   })
    // );
  }

  ngAfterViewInit() {
    this.setGamesFromURl();
  }

  watch() {
    const sliderVideo: any = document.getElementById('game-video');
    sliderVideo.pause();
    let video: any = document.getElementById('locations-video');
    video.play();
  }

  setTabIndex(index: number) {
    const tabCount = 4;
    this.tabIndex = (index + 1) % tabCount;
  }


}
