import { Component, Input, OnInit } from '@angular/core';
import { ChatConfig, ChatsService } from 'src/app/services/chat.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-task-chat',
    templateUrl: './task-chat.component.html',
    styleUrls: ['./task-chat.component.scss'],
    providers: [],
})
export class TaskChatComponent implements OnInit {
    @Input() taskId: number = 0;
    @Input() teamId: number = 0;

    config: ChatConfig;
    roomType: string = 'task';

    constructor(private session: SessionService, private chatsService: ChatsService) {}

    ngOnInit() {
        this.config = {
            type: this.roomType,
            user: this.session.getCurrentUser(),
            team_id: this.session.getCurrentUser().team_id || this.teamId,
            team_task_id: this.taskId,
            room_id: this.chatsService.getRoomId(this.roomType, this.session.getCurrentUser().team_id, this.taskId),
            school_id: this.session.getCurrentUserSchool()?.id,
        };
    }
}
