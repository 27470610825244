<div class="flex h-full">
    <div class="h-full w-full">
        <div class="w-full">
            <app-un-gradient-text text="Manage users" size="big"></app-un-gradient-text>
        </div>

        <div class="my-4">
            <app-card [paddingX]="false">
                <app-table
                    [columns]="usersTable.columns"
                    [data]="users"
                    [form]="usersTable.form"
                    [create]="false"
                    [showOnlyEdit]="true"
                    [edit]="true"
                    [delete]="true"
                    [filters]="filters"
                    [search]="true"
                    (onSearch)="onSearch($event)"
                    [deletionConfirm]="true"
                    (onCreate)="create($event)"
                    (onEdit)="update($event)"
                    (onDelete)="delete($event)"
                    (onFiltersChanged)="filter($event)"
                    [totalItems]="totalItems"
                    (pageChange)="pageChange($event)"
                    (limitChange)="limitChange($event)"
                    [dialogConfig]="{ width: '500px' }"
                >
                </app-table>
            </app-card>
        </div>
    </div>
</div>
