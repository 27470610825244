import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SchoolNetworkChart } from 'src/app/charts/SchoolNetworkChart';
import { NetworkService } from 'src/app/services/network.service';
import { NetworkProjectsTable } from 'src/app/tables/NetworkProjectsTable';


type statisticBlock = {
  name: string;
  total: number;
  last_year: number;
  info: string;
}

@Component({
  selector: 'app-network-section',
  templateUrl: './network-section.component.html',
  styleUrls: ['./network-section.component.scss'],
  providers: [],
})
export class NetworkSectionComponent implements OnInit {

  isLoading: boolean = true;

  viewModes: any[] = [
    { value: 'default-0', viewValue: 'Default view' },
    { value: 'pro', viewValue: 'Detailed view' },
  ];

  viewMode = this.viewModes[0].value;

  statisticBlocks: statisticBlock[] = [
    {
      name: 'User Statistic',
      total: 0,
      last_year: 0,
      info: 'Сommon  Today',
    },
    {
      name: 'Project Statistic',
      total: 0,
      last_year: 0,
      info: 'Сommon  Today',
    },
    {
      name: 'School Statistic',
      total: 0,
      last_year: 0,
      info: 'Сommon  Today',
    }
  ];

  institution: any = {
    name: 'School ENG',
    teachers: 8,
    mentors: 15,
    students: 200
  };

  projects: any[] = [];

  opened = false;

  selectedTeam: any;

  chart = new SchoolNetworkChart();
  table = new NetworkProjectsTable();



  constructor(
    private networkService: NetworkService,
    private router: Router,
  ) { }


  get viewName(): string {
    return this.viewModes.find((mode: any) => mode.value === this.viewMode).viewValue;
  }

  ngOnInit() {
    this.fetch();
  }

  fetch() {
    this.getStats();
    this.getProjects();
    this.getGraphData();
  }

  getStats() {
    this.networkService.getStats().subscribe((response: any) => {

      this.statisticBlocks[0].total = response.data[0].user.total;
      this.statisticBlocks[0].last_year = response.data[0].user.last_year;

      this.statisticBlocks[1].total = response.data[0].project.total;
      this.statisticBlocks[1].last_year = response.data[0].project.last_year;

      this.statisticBlocks[2].total = response.data[0].school.total;
      this.statisticBlocks[2].last_year = response.data[0].school.last_year;

    }, (error: any) => {
      if (error.status === 403) {
        this.router.navigate(['/forbidden']);
      }
    });

  }

  getProjects() {
    this.networkService.getProjects().subscribe((response: any) => {
      this.projects = response.data || [];
    }, (error: any) => {
      if (error.status === 403) {
        this.router.navigate(['/forbidden']);
      }
    });
  }

  getGraphData() {
    this.networkService.getСountryGraph().subscribe((response: any) => {
      const graphSet: any = [];

      response.data.forEach((country: any) => {

        graphSet.push(
          ['School UNIVERSE', country.country]
        );

        country.schools.forEach((school: any) => {
          graphSet.push(
            [country.country, school.title]
          );
        });

      });

      this.chart.build(graphSet);

      this.isLoading = false;

    });
  }



}
