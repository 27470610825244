import { Validators } from '@angular/forms';
import { Table } from './Table';
import * as moment from 'moment';

export class ManageSchoolStudentsTable extends Table {
    public invitationTemplate: any;

    // constructor() {
    //     super();
    //     this.buildColumns();
    //     this.buildForm();
    // }

    buildColumns() {
        this.columns = [
            {
                name: 'ID',
                selector: 'id',
            },
            {
                name: 'Name',
                selector: 'name',
                type: 'text',
                headerClass: 'text-left',
                class: () => 'text-left',
            },
            {
                name: 'Email',
                selector: 'email',
            },
            {
                name: 'Birthday',
                selector: 'birthday',
                transform: (birthday) => moment(birthday).format('DD.MM.YYYY'),
            },
            {
                name: 'Active',
                selector: 'is_active',
                headerClass: 'text-center',
                class: () => 'text-center',
                transform: (is_active) =>
                    is_active ? '<i class="ph ph-circle text-green"></i>' : '<i class="ph ph-circle text-red"></i>',
            },
            {
                name: '',
                selector: 'invite',
                headerClass: 'text-center',
                class: () => 'text-center cursor-pointer',
                content: {
                    template: this.invitationTemplate,
                    context: (row) => {
                        return {
                            user: row,
                        };
                    },
                },
                // transform: () => '<i class="ph ph-envelope interactive"></i>',
                // tooltip: 'Send invite code'
            },
        ];
    }

    buildForm(): void {
        this.form = {
            title: () => '',
            elements: [
                {
                    name: 'ID',
                    selector: 'id',
                    type: 'text',
                    visible: false,
                },
                {
                    name: 'Name',
                    selector: 'name',
                    type: 'text',
                },
                {
                    name: 'Email',
                    selector: 'email',
                    type: 'text',
                    validators: [Validators.required],
                },
                {
                    name: 'Birthday',
                    selector: 'birthday',
                    type: 'date',
                },
                {
                    name: 'Active',
                    selector: 'is_active',
                    type: 'checkbox',
                    defaultValue: false,
                    // validators: [Validators.required]
                },
            ],
        };
    }
}
