import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';
import { TasksService } from 'src/app/services/tasks.service';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-tasks-column',
    templateUrl: './tasks-column.component.html',
    styleUrls: ['./tasks-column.component.scss'],
})
export class TasksColumnComponent {
    @Input() title: string = '';
    @Input() tasks: any[] = [];

    @Input() stageId: number = 0;
    @Input() teamId: number = 0;

    @Input() taskTypes: any;
    @Input() taskTags: any[] = [];

    taskTemplates: any[] = [];
    taskPrototype: any[] = [];
    tasksArray: any[] = [];
    guides: any[] = [];

    @ViewChild('tasksContainer', { static: false, read: ElementRef })
    private tasksContainer: ElementRef;

    isLoading: boolean = false;

    @Output() taskUpdated: EventEmitter<any> = new EventEmitter();

    constructor(
        private router: Router,
        private tasksService: TasksService,
        private notificationService: NotificationService,
        private translate: TranslateService,
    ) {}

    drop(event: CdkDragDrop<any[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }

        // Prepare the data for the PUT request
        const movedTask = event.container.data[event.currentIndex];
        const updateData = {
            order_id: event.currentIndex + this.guides.length,
            team_dev_stage_id: this.stageId,
        };

        this.reorderTask(movedTask, updateData);
    }

    reorderTask(movedTask: any, updateData: any) {
        this.isLoading = true;
        this.tasksService
            .setTaskOrder(movedTask.id, updateData)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (response) => {
                    //this.taskUpdated.emit();
                },
                error: (error) => {
                    this.notificationService.error(this.SOMETHING_WRONG_TOASTR);
                },
                complete: () => {},
            });
    }

    ngOnInit() {
        this.tasksArray = this.tasks.filter((task) => !this.tasksService.isGuide(task.task_type.type));
        this.guides = this.tasks.filter((task) => this.tasksService.isGuide(task.task_type.type));
    }

    ngAfterViewInit() {
        this.initScrollSection();
    }

    initScrollSection() {
        const ele = this.tasksContainer.nativeElement;

        if (ele !== null) {
            let pos = { top: 0, left: 0, x: 0, y: 0 };

            const mouseDownHandler = function (e: any) {
                if (e.target.tagName !== 'IMG') {
                    ele.style.userSelect = 'none';

                    pos = {
                        left: ele.scrollLeft,
                        top: ele.scrollTop,
                        // Get the current mouse position
                        x: e.clientX,
                        y: e.clientY,
                    };

                    document.addEventListener('mousemove', mouseMoveHandler);
                    document.addEventListener('mouseup', mouseUpHandler);
                }
            };

            const mouseMoveHandler = function (e: any) {
                // How far the mouse has been moved
                const dx = e.clientX - pos.x;
                const dy = e.clientY - pos.y;

                // Scroll the element
                ele.scrollTop = pos.top - dy;
                ele.scrollLeft = pos.left - dx;
            };

            const mouseUpHandler = function () {
                ele.style.removeProperty('user-select');

                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);
            };

            // Attach the handler
            ele.addEventListener('mousedown', mouseDownHandler);
        }
    }

    createNewTask() {
        this.taskTemplates = [];
        this.taskTemplates.push({});
    }

    addTask(task: any) {
        this.isLoading = true;
        this.taskTemplates = [];
        setTimeout(() => {
            this.tasksService
                .create(task)
                .pipe(finalize(() => (this.isLoading = false)))
                .subscribe({
                    next: () => {
                        this.taskUpdated.emit();
                        this.tasksArray.unshift(task);
                        this.isLoading = false;
                        this.initScrollSection();
                    },
                    error: () => {
                        this.notificationService.error(this.SOMETHING_WRONG_TOASTR);
                        this.router.navigate(['communications'], { queryParams: { tab: 'tasks' } });
                    },
                });
        }, 800);

        task['team_id'] = this.teamId;
        task['team_dev_stage_id'] = this.stageId;
    }

    goToManageTask(taskId: number) {
        this.router.navigateByUrl('/communications/manage-task/' + taskId);
    }

    restoreTemplate() {
        this.taskTemplates = [];
    }

    get doneTasksPercent(): number {
        const donePercent =
            (this.tasksArray.filter((task) => task.status === 'completed').length / this.tasksArray.length) * 100;
        return donePercent;
    }

    get SOMETHING_WRONG_TOASTR() {
        return this.translate.instant('communications.workspace.something_wrong_toastr');
    }
}
