import {Component, Input} from '@angular/core';
import {Tools} from "../../tools";

@Component({
  selector: 'app-progress-slider-round',
  templateUrl: './progress-slider-round.component.html',
  styleUrls: ['./progress-slider-round.component.scss']
})
export class ProgressSliderRoundComponent {

    @Input() rate: number = 0;
    @Input() precision: number = 1;
    @Input() steps: any = undefined;
    @Input() actualValue: any;
    @Input() inputColor: string = '';

    constructor() {}

    get displayValue() {
        return this.actualValue ? this.actualValue : this.roundedRate;
    }

    get roundedRate() {
        return this.rate !== null && !isNaN(this.rate) && isFinite(this.rate)
            ? Tools.round(this.rate, this.precision)
            : '';
    }

    get color() {
        if (!this.steps) {
            return 'bg-[#7CA38B]';
        }

        if (this.rate <= this.steps[0]) {
            return 'bg-[#BE6969]';
        }
        if (this.rate <= this.steps[1]) {
            return 'bg-[#ECBF69]';
        }
        return 'bg-[#7CA38B]';
    }

    get bgColor() {
        return 'bg-' + this.inputColor;
    }

    get stringEnd() {
        return this.actualValue === undefined ? '%' : '';
    }
}

