import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-un-radio-set',
    templateUrl: './un-radio-set.component.html',
    styleUrls: ['./un-radio-set.component.scss'],
})
export class UnRadioSetComponent implements OnInit {
    @Input() options: any[] = [];
    @Input() horizontal: boolean = false;
    @Input() value: any = '';

    @Output() optionChanged: EventEmitter<any> = new EventEmitter<any>();

    selectedOptionValue: any = '';

    ngOnInit() {
        this.selectedOptionValue = this.value ? this.value : '';
    }

    select(option: any) {
        this.selectedOptionValue = option.id;
        this.optionChanged.emit(option.id);
    }
}
