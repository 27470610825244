import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { flyInOut, flyleftIn, flyRigthIn } from 'src/app/animations/fly-animations';
import { NotificationService } from 'src/app/services/notification.service';
import { Text2imgService } from 'src/app/services/text2img.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-image-generator-dalle',
    templateUrl: './image-generator-dalle.component.html',
    styleUrls: ['./image-generator-dalle.component.scss'],
    animations: [flyleftIn, flyRigthIn, flyInOut],
})
export class ImageGeneratorDalleComponent {
    isLoading: boolean = false;

    storageUrl: string = environment.storageUrl;

    promtFormControl = new UntypedFormControl('', [Validators.required]);

    IMAGE_PREFIX = 'data:image/png;base64,';
    imageString = '';

    images: string[] = [];
    selectedImage: string = '';

    remainingTime: number;
    timerRunning: boolean;
    interval: any;

    constructor(
        private text2imgService: Text2imgService,
        private notificationService: NotificationService,
    ) {}

    generate() {
        this.isLoading = true;
        this.images = [];
        this.selectImage('');
        this.startTimer();

        this.text2imgService
            .generateDalle(this.promtFormControl.value)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: ({ data }: any) => {
                    this.images = [this.storageUrl + data.name];
                },
                error: (err) => this.notificationService.error(err.error.reason, 'Error'),
            });
    }

    selectImage(image: string): void {
        this.selectedImage = image;
    }

    async downloadImage(event: any, url: string, name: string) {
        event.stopPropagation();
        const image = await fetch(url);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);

        const link = document.createElement('a');
        link.href = imageURL;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    startTimer() {
        this.timerRunning = true;
        this.remainingTime = 22; // Change this value to the desired number of seconds

        this.interval = setInterval(() => {
            this.remainingTime--;
            if (this.remainingTime === 0) {
                this.stopTimer();
            }
        }, 1000);
    }

    stopTimer() {
        clearInterval(this.interval);
        this.timerRunning = false;
    }

    calculateWidthPercentage(): number {
        return (this.remainingTime / 22) * 100;
    }
}
