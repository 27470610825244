<app-card class="mb-3 h-full" [paddingX]="false">
    <div class="flex mb-4 px-3 items-center justify-between">
        <div class="font-medium text-lg">{{ ENTRY_NAME }}</div>
        <div class="flex items-center gap-x-7">
            <app-date-range-picker [defaultBegin]="begin" [defaultEnd]="end"></app-date-range-picker>
            <div class="ml-auto flex">
                <app-un-save-button
                    text="Start vote"
                    (сlicked)="startVote()"
                    [disabled]="!isVoteAvailable"
                    #tooltip="matTooltip"
                    matTooltip="select at least 2 active voting topic to start"
                    matTooltipPosition="above"
                    [matTooltipDisabled]="isVoteAvailable"
                    matTooltipHideDelay="100000"
                >
                </app-un-save-button>
            </div>
        </div>
    </div>

    <div>
        <app-table
            [columns]="table.columns"
            [data]="votesTopics"
            [form]="table.form"
            [create]="true"
            (onCreate)="create($event)"
            [edit]="true"
            (onEdit)="update($event)"
            [delete]="true"
            (onDelete)="delete($event)"
            [deletionConfirm]="true"
        >
        </app-table>
    </div>
</app-card>
