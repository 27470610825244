import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { cards } from './data';

type SkillCard = {
    name: string;
    progress: number;
    modified_at: string;
};

@Component({
    selector: 'app-user-skills-cards',
    templateUrl: './user-skills-cards.component.html',
    styleUrls: ['./user-skills-cards.component.scss'],
})
export class UserSkillCardsComponent {
    cards: SkillCard[] = cards;

    storageUrl: string = environment.storageUrl;

    constructor(@Inject(MAT_DIALOG_DATA) public data: string) {}
}
