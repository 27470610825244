import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'level',
})
export class LevelPipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    public transform(type: string): any {
        switch (type.toLowerCase()) {
            case 'junior':
                return '/assets/project/levels/earth.png';
            case 'middle':
                return '/assets/project/levels/cloud.png';
            case 'senior':
                return '/assets/project/levels/water.png';
            case 'professor':
                return '/assets/project/levels/fire.png';
            default:
                '';
        }
    }
}
