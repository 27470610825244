import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-un-avatar',
    templateUrl: './un-avatar.component.html',
    styleUrls: ['./un-avatar.component.scss'],
})
export class UnAvatarComponent {
    @Input() name: string = '';
    @Input() color?: string = '#7CA38B';
    @Input() icon?: string = '';
    @Input() small?: boolean = true;
    @Input() select?: boolean = false;
    @Input() extraLarge?: boolean = false;

    @Input() image?: string = '';

    bgColor: string = '';
    storageUrl: string = environment.storageUrl + '/';
    defaultImageUrl: string = '/assets/blank-profile.png';

    constructor() {}

    ngOnInit() {}

    get letterName() {
        return this.name ? this.name[0] : '';
    }
}
