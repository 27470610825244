import { Validators } from '@angular/forms';
import { Table } from './Table';
import * as moment from 'moment';

export class InviteStudentsListTable extends Table {
    public invitationTemplate: any;

    //todo invate sent date

    buildColumns() {
        this.columns = [
            {
                name: 'ID',
                selector: 'id',
            },
            // {
            //     name: 'Name',
            //     selector: 'name',
            // },
            {
                name: 'Email',
                selector: 'email',
            },
            {
                name: 'Mentor',
                selector: 'mentor',
                headerClass: 'text-center',
                class: () => 'text-center',
                transform: (is_active) =>
                    is_active ? '<i class="ph ph-circle text-green"></i>' : '<i class="ph ph-circle text-red"></i>',
            },
            {
                name: 'Send Invite',
                selector: 'invited',
                headerClass: 'text-center',
                class: () => 'text-center cursor-pointer',
                content: {
                    template: this.invitationTemplate,
                    context: (row) => {
                        return {
                            user: row,
                        };
                    },
                },
            },
        ];
    }

    buildForm(role_id: number): void {
        this.form = {
            title: () => '',
            elements: [
                {
                    name: 'ID',
                    selector: 'id',
                    type: 'text',
                    visible: false,
                },
                // {
                //     name: 'Name',
                //     selector: 'name',
                //     type: 'text',
                // },
                {
                    name: 'Email',
                    selector: 'email',
                    type: 'text',
                    class: 'col-span-12',
                    validators: [Validators.required],
                },
                {
                    name: 'Mentor',
                    selector: 'mentor',
                    type: 'checkbox',
                    class: 'col-span-12',
                },
                {
                    name: 'Role',
                    selector: 'role_id',
                    type: 'text',
                    class: 'col-span-12',
                    defaultValue: role_id,
                    visible: false,
                },
            ],
        };
    }
}
