<div class="flex justify-center" (click)="openModal(usersModal)">
    <ng-container *ngIf="assignedUsers.length === 0">
        <app-un-create-button></app-un-create-button>
    </ng-container>

    <div class="flex -space-x-3 rtl:space-x-0">
        <ng-container *ngFor="let user of assignedUsersPreview; let i = index">
            <!-- <img
                class="user-preview-circle cursor-pointer"
                [ngStyle]="{ transform: 'translateX(' + i * 8 * (this.dir === 'ltr' ? -1 : 1) + 'px)' }"
                [src]="user.main_image ? storageUrl + user.main_image : defaultImageUrl"
            /> -->
            <img
                class="w-6 h-6 cursor-pointer rounded-full"
                [src]="user.main_image ? storageUrl + user.main_image : defaultImageUrl"
                alt=""
            />
        </ng-container>
    </div>

    <ng-container *ngIf="assignedUsers.length > limit">
        <div
            class="px-2 flex justify-center items-center center count mx-1 cursor-pointer"
            style="font-size: 0.7em; opacity: 0.8"
        >
            <span>+</span><span>{{ assignedUsers.length - limit }}</span>
        </div>
    </ng-container>
</div>

<ng-template #usersModal>
    <div class="un-scroll" mat-dialog-content style="height: 44vh; min-height: 400px; min-width: 380px">
        <div class="flex mb-2 items-center">
            <span class="font-weight-bold text-uppercase text-large" style="font-size: 14px">
                {{ 'communications.workspace.assign_students' | translate }}
            </span>
            <i class="ph-info-bold interactive mx-2" style="font-size: 1.4em"></i>
        </div>
        <ng-container *ngFor="let user of users">
            <app-un-user-row class="students" [user]="user" (click)="selectStudent(user)"></app-un-user-row>
        </ng-container>
    </div>

    <div mat-dialog-actions class="row justify-center">
        <div class="col-auto" style="zoom: 0.7">
            <app-un-save-button
                [text]="'communications.workspace.save' | translate"
                (click)="submit()"
            ></app-un-save-button>
        </div>
    </div>
</ng-template>
