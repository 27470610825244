<div class="flex my-3">
    <div class="col-md-6 col-xs-12 mb-3">
        <app-manage-students></app-manage-students>
    </div>
    <div class="col-md-6 col-xs-12 mb-3">
        <app-manage-teachers></app-manage-teachers>
    </div>
</div>
<div class="flex">
    <!-- <div class="col-12 col-xs-12 mb-3">
        <app-manage-groups></app-manage-groups>
    </div> -->
    <!-- <div class="col-md-6 col-xs-12 mb-3">
        <app-manage-student-mentors></app-manage-student-mentors>
    </div> -->
</div>
