<div class="chat-wrapp_fixed">
    <div matRipple class="ml-2 chat-btn cursor-pointer" (click)="toggle()">
        <!-- <span class="mr-2 pink">{{ teamUsers.length + '1' }}</span> -->
        <!-- <i class="far fa-comment-dots fa-2x"></i> -->
        <img class="chat-icon" src="/assets/chat.png" alt="" />
    </div>

    <div class="box flex" [class.open]="displayChat">
        <ng-container *ngIf="canLoadChat">
            <div class="chats-list">
                <ng-container *ngFor="let chat of chats">
                    <div
                        class="chat-name px-3 py-2 flex items-center cursor-pointer"
                        style="gap: 15px"
                        [class.active-chat]="chat.name === this.selectedChat?.name"
                        (click)="selectChat(chat)"
                    >
                        <div class="chat-image-wrap">
                            <img
                                class="friend-img"
                                [src]="
                                    chat.main_image ? CHAT_ORIGIN + chat.main_image : '/assets/profile-placeholder.jpg'
                                "
                            />
                        </div>
                        <div>
                            <span style="font-size: 0.8em">{{ chat.name }}</span>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="flex flex-col w-full">
                <div
                    class="px-3 py-4 flex justify-between"
                    style="background-color: #181c25; border-bottom: 1px solid #2c293b"
                >
                    <span class="text-capitalize">{{ chatName }}</span>
                    <span class="cursor-pointer" (click)="close()">
                        <i class="fas fa-times cursor-pointer fa-lg fa-spin-hover"></i>
                    </span>
                </div>
                <div class="flex h-full">
                    <app-chat-iframe class="w-full" (chatNameChanged)="setChatName($event)"></app-chat-iframe>

                    <div class="chat-users-list p-2">
                        <app-friends-list [outer]="false" (userClicked)="userSelect($event)"></app-friends-list>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
