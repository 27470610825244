import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    forwardRef,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';

@Component({
    selector: 'app-text-input',
    template: `
        <div>
            <label
                class="block text-sm font-medium dark:font-normal "
                [ngClass]="{
                    'text-gray-500/50 dark:text-gray-400/50': isDisabled,
                    'text-gray-500 dark:text-gray-400': !isDisabled
                }"
            >
                <span>{{ label }}</span>
                <span
                    *ngIf="isRequired && !skipRequiredSign"
                    class="ml-0.5 "
                    [ngClass]="{ 'text-red-500 dark:text-rose-500': control.errors?.['required'] }"
                >
                    *
                </span>
            </label>
            <div class="relative mt-0.5">
                <input
                    #textInput
                    [type]="type"
                    [value]="value"
                    (input)="onInputChange($event)"
                    (blur)="onInputBlur()"
                    [disabled]="isDisabled"
                    [attr.placeholder]="placeholder"
                    [maxLength]="maxLength !== -1 ? maxLength : 9999"
                    [ngClass]="{ 'ps-6': clear && !isDisabled && value.length >= 3 }"
                    class="block w-full rounded-md border-0  bg-transparent px-2 py-2 text-sm text-gray-900 outline-none ring-1 ring-inset ring-gray-300 transition duration-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400  disabled:opacity-40 dark:text-white dark:ring-gray-600 dark:placeholder:text-gray-500 dark:focus:ring-sky-400"
                />
                <button
                    *ngIf="clear && !isDisabled && value.length >= 3"
                    class="absolute end-2 top-0 flex h-full items-center"
                    aria-label="Clear"
                    (click)="clearInput()"
                >
                    <i class="ph ph-x text-base font-medium text-gray-500 dark:font-normal dark:text-gray-400"></i>
                </button>

                <div
                    *ngIf="maxLength !== -1"
                    class="absolute inset-x-0 -bottom-5 flex justify-end text-xs text-gray-500 dark:text-gray-400"
                >
                    {{ value.length }} / {{ maxLength }}
                </div>
            </div>

            <ng-container *ngIf="control?.touched || control?.dirty">
                <div
                    class="text-xs text-red-500 dark:font-semibold dark:text-rose-500"
                    *ngIf="control?.errors?.['required']"
                >
                    Please fill in
                </div>

                <div
                    class="text-xs text-red-500 dark:font-semibold dark:text-rose-500"
                    *ngIf="!control?.errors?.['required'] && control?.errors?.['minlength']"
                >
                    Password must be at least
                    {{ this.control.errors.minlength.requiredLength }} characters long
                </div>

                <!-- <div
                    class="text-xs text-red-500 dark:font-semibold dark:text-rose-500"
                    *ngIf="!control?.errors?.['required'] && control?.errors?.['notSame']"
                >
                    die Passwörter stimmen nicht überein
                </div> -->
            </ng-container>
        </div>
    `,
    styles: [':host { display: block; }'],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextInputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TextInputComponent),
            multi: true,
        },
    ],
})
export class TextInputComponent implements ControlValueAccessor, Validator, AfterViewInit {
    @Input() label: string = '';
    @Input() value: string = '';
    @Input() placeholder: string = '';
    @Input() type: string = 'text';

    @Input() isDisabled: boolean = false;
    @Input() clear: boolean = false;
    @Input() skipRequiredSign: boolean = false;
    @Input() maxLength: number = -1;

    @Output() inputCleared = new EventEmitter<any>();

    private onChange: (value: string) => void;
    private onTouched: () => void;

    control: any;
    @ViewChild('input', { static: false, read: NgControl }) input: any;
    @ViewChild('textInput', { static: false }) textInput: ElementRef;

    ngAfterViewInit() {
        this.validate(null);
    }

    validate(control: AbstractControl | null): ValidationErrors | null {
        if (!this.control) {
            this.control = control;
        }

        if (this.control && this.input) {
            this.input.control.setValidators(this.control.validator);

            if (this.maxLength > 0) {
                this.input.control.setValidators([
                    ...this.input.control.validator,
                    Validators.maxLength(this.maxLength),
                ]);
            }
        }

        return null;
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // setDisabledState(disabled: boolean): void {
    //     this.isDisabled = disabled;
    // }

    onInputBlur(): void {
        this.onTouched();
    }

    onInputChange(event: Event): void {
        const inputValue = (event.target as HTMLInputElement).value;
        this.value = inputValue;
        this.onChange(inputValue);
        this.onTouched();
    }

    get isRequired() {
        return this.control?.hasValidator(Validators.required);
    }

    clearInput(): void {
        this.value = '';
        this.onChange('');
        this.onTouched();
        this.textInput.nativeElement.focus();
        this.inputCleared.emit();
    }
}
