<div *ngIf="project" (click)="navigateToProject(project.id)" class="main-card project-box h-full p-2 xl:p-3">
    <div class="topic-box-row">
        <div class="image-row">
            <img [class.image-border]="project.image_path" [src]="project.image_path" />
        </div>

        <div *ngIf="!project.loading" class="py-1 text-start xl:py-3">
            <div class="flex justify-between">
                <app-chip
                    *ngIf="project.applicationType"
                    class="my-1 xl:mb-2"
                    [text]="project.applicationType.type"
                    [color]="project.applicationType.color"
                    [icon]="project.applicationType.icon"
                >
                </app-chip>

                <ng-container *ngFor="let tag of project.tags">
                    <app-chip class="my-1 xl:mb-2" [text]="tag.name" [icon]="tag.icon" [color]="tag.color"> </app-chip>
                </ng-container>

                <a
                    *ngIf="project.downloadable"
                    (click)="downloaProject($event, project.link)"
                    class="no-underline hover:no-underline"
                >
                    <div
                        class="my-1 flex w-max items-center justify-center rounded-md p-1 xl:mb-2"
                        style="border: 1px solid #615b8e"
                    >
                        <i class="ph-bold ph-download-simple interactive text-base"></i>
                    </div>
                </a>
            </div>
            <div
                *ngIf="project.type"
                class="type my-1 xl:mb-2"
                [class.junior]="project.type === 'junior'"
                [class.middle]="project.type === 'middle'"
                [class.senior]="project.type === 'senior'"
            >
                <i
                    *ngIf="project.type"
                    [ngClass]="{
                        'ph-game-controller': project.type === 'junior',
                        'ph-mountains': project.type === 'middle',
                        'ph-planet': project.type === 'senior'
                    }"
                ></i>

                <span>{{ project.type }} </span>
                <span>project</span>
            </div>
            <div class="topic-box-row-name">{{ project.name }}</div>
            <div class="flex">
                <span class="topic-box-row-desc">{{ project.description }}</span>
                <div *ngIf="project.description.length > 45" class="flex items-end">
                    <button>...{{ 'studio.more' | translate }}</button>
                </div>
            </div>
        </div>
        <ng-container *ngIf="project.loading">
            <div class="flex items-center justify-center py-1 xl:py-3">
                <mat-spinner></mat-spinner>
            </div>
        </ng-container>
    </div>
</div>

<div
    *ngIf="create"
    class="main-card project-box flex h-full cursor-pointer items-center justify-center p-2"
    style="border: 1px dashed; border-color: #615b8e"
    [routerLink]="'/manage-project'"
>
    <div class="p-3">
        <div class="create-project mx-auto mb-3">
            <i class="ph-plus"></i>
        </div>
        <div>
            <span class="text-large text-uppercase text-nowrap p-3 text-[#9d97c3]">
                {{ 'studio.create_tasks' | translate }}
            </span>
        </div>
    </div>
</div>

<div
    *ngIf="image"
    class="main-card project-box flex h-full cursor-pointer items-center justify-center p-2"
    style="border: 1px dashed; border-color: #615b8e"
    routerLink="/generate-images"
>
    <div class="p-3">
        <div class="create-project mx-auto mb-3">
            <i class="ph-plus"></i>
        </div>
        <div>
            <span class="text-large text-uppercase text-nowrap p-3 text-[#9d97c3]">
                {{ 'studio.create_image' | translate }}
            </span>
        </div>
    </div>
</div>
