<div class="task-tools-switch card-box mb-2">
    <div class="switch-item" [class.active]="selectedTool === 'drive'" (click)="setTool('drive')">
        <i class="ph-folder-simple-plus"></i>
        <span>{{ 'communications.workspace.drive' | translate }}</span>
    </div>
    <div class="switch-item" [class.active]="selectedTool === 'chat'" (click)="setTool('chat')">
        <i class="ph-chat-circle-dots"></i>
        <span>{{ 'communications.workspace.chat' | translate }}</span>
    </div>
    <div class="switch-item" [class.active]="selectedTool === 'history'" (click)="setTool('history')">
        <i class="ph-book-bookmark"></i>
        <span>{{ 'communications.workspace.history' | translate }}</span>
    </div>
</div>

<div [ngSwitch]="selectedTool">
    <ng-template [ngSwitchCase]="'drive'">
        <div class="tool-wrapper un-scroll card-box">
            <app-task-drive [taskId]="taskId"></app-task-drive>
        </div>
    </ng-template>
    <ng-template [ngSwitchCase]="'chat'">
        <div class="tool-wrapper un-scroll card-box">
            <app-task-chat [taskId]="taskId" [teamId]="teamId"></app-task-chat>
        </div>
    </ng-template>
    <ng-template [ngSwitchCase]="'history'">
        <div class="tool-wrapper un-scroll card-box">
            <app-history [taskId]="taskId"></app-history>
        </div>
    </ng-template>
    <ng-template ngSwitchDefault>
        <span>{{ 'drive' }}</span>
    </ng-template>
</div>
