<div class="tabs-row grid grid-cols-12">
    <div class="col-span-12 lg:col-span-8">
        <ng-content select="[header]"></ng-content>
    </div>

    <div class="col-span-12 flex justify-end md:col-span-4">
        <ul class="flex items-end text-nowrap">
            <ng-container *ngFor="let tab of tabs">
                <li *ngIf="tab.visible" class="un-tab" (click)="selectTab(tab)" [class.active]="tab.active" matRipple>
                    <span>{{ tab.name }}</span>
                </li>
            </ng-container>
        </ul>
    </div>
</div>

<div class="h-full">
    <app-un-tab>
        <ng-content select="[body]"></ng-content>
    </app-un-tab>
</div>
