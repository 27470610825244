import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DriveService } from 'src/app/services/drive.service';
import * as moment from 'moment';
import { animateOpacity } from 'src/app/animations/fly-animations';
import { Tools } from 'src/app/tools';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

type Document = any;
type Folder = any;

@Component({
    selector: 'app-drive',
    templateUrl: './drive.component.html',
    styleUrls: ['./drive.component.scss'],
    animations: [animateOpacity],
})
export class DriveComponent {
    @Input() documents: Document[] = [];
    @Input() folders: Folder[] = [];
    @Input() finalVersion: Folder[] = [];

    @Input() isLoading: boolean = false;
    @Input() isUpload: boolean = true;
    @Input() isBreadcrumbs: boolean = false;

    @Input() teamId: number = 0;
    @Input() taskId?: number = 0;

    @Output() fileUpload: EventEmitter<any> = new EventEmitter<any>();

    date: string = moment().format('DD MMM YYYY');

    storageUrl: string = environment.storageUrl;

    uploadHelper: any = {
        file: undefined,
        valid: false,
    };

    selectedFolder: Folder;

    query: string = '';
    showSearch: boolean = false;

    showRecent: boolean = true;

    allowedExtensions = this.driveService.getAllowedExtensions();

    selectedImage: any;
    selectedVideo: any;
    selectedAudio: any;
    selectedDocument: any;

    finalVersionFolders: Folder[] = [];
    isFinalVersionFolder: boolean = false;
    isFinalVersionFolderRoot: boolean = false;

    isFolderDownloading: boolean = false;

    breadcrumbs: string[] = ['home'];

    constructor(
        private driveService: DriveService,
        private notificationService: NotificationService,
        public dialog: MatDialog,
        public translate: TranslateService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (this.selectedFolder) {
            this.documents =
                this.selectedFolder.name === 'Final Version'
                    ? changes.finalVersion?.currentValue
                    : this.getDocumentsByFolder(this.selectedFolder.id, changes.folders?.currentValue);
        }
    }

    getDocumentsByFolder(folderId: number, folders: any) {
        if (!folders || (folders && folders.length === 0)) {
            return [];
        }
        const searchFolder = folders.find((folder: any) => folder.id === folderId);
        return searchFolder.documents || [];
    }

    openImageModal(dialogRef: TemplateRef<any>, image: any) {
        this.selectedImage = image;
        this.dialog.open(dialogRef);
    }

    openVideoModal(dialogRef: TemplateRef<any>, video: any) {
        this.selectedVideo = video;
        this.dialog.open(dialogRef);
    }

    openAudioModal(dialogRef: TemplateRef<any>, audio: any) {
        this.selectedAudio = audio;
        this.dialog.open(dialogRef);
    }

    openDocumentModal(dialogRef: TemplateRef<any>, document: any) {
        this.selectedDocument = document;
        this.dialog.open(dialogRef);
    }

    openDialog(dialogRef: TemplateRef<any>) {
        this.uploadHelper.file = undefined;
        this.uploadHelper.valid = false;
        this.dialog.open(dialogRef);
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    fileChange(fileChangeEvent: any) {
        this.uploadHelper.file = fileChangeEvent.file[0] || undefined;
        this.uploadHelper.valid = fileChangeEvent.valid;
    }

    getIconByType(type: string) {
        const icons: any = {
            pdf: 'ph-file-pdf',
            doc: 'ph-file-doc',
            video: 'ph-file-video',
            audio: 'ph-file-audio',
            other: 'ph-file',
            default: 'ph-file',
        };
        return icons[type] || icons['default'];
    }

    toggleSearch() {
        this.showSearch = !this.showSearch;
    }

    toggleDateFilter() {
        this.showRecent = !this.showRecent;
        this.sortByDate();
    }

    sortByDate() {
        this.documents.sort((a, b) => {
            return this.showRecent
                ? moment(b.date).unix() - moment(a.date).unix()
                : moment(a.date).unix() - moment(b.date).unix();
        });
        this.documents = Tools.cloneObject(this.documents);
    }

    isImageType(type: string): boolean {
        return type === 'image';
    }

    isVideoType(type: string): boolean {
        return type === 'video';
    }

    isAudioType(type: string): boolean {
        return type === 'audio';
    }

    isNonPreviewType(type: string): boolean {
        return ['pdf', 'doc', 'txt', 'other'].includes(type);
    }

    upload() {
        const formData = new FormData();
        formData.append('file', this.uploadHelper.file, this.uploadHelper.file.name);
        this.fileUpload.emit(this.uploadHelper);
    }

    openFolder(folder: Folder) {
        if (this.selectedFolder && this.selectedFolder.final) {
            this.isFinalVersionFolderRoot = true;
        }
        this.selectedFolder = folder;

        this.documents = folder.documents;
        this.breadcrumbs.push(this.selectedFolder.name);
    }

    openFinalVersionFolder(folder: Folder, isBack?: boolean) {
        this.isFinalVersionFolder = true;
        this.selectedFolder = { name: 'Final Version', final: true };
        this.finalVersionFolders = [];
        this.finalVersionFolders = folder;
        if (!isBack) {
            this.breadcrumbs.push(this.selectedFolder.name);
        }
    }

    closeFolder() {
        if (this.isFinalVersionFolderRoot) {
            this.breadcrumbsBack();
            this.openFinalVersionFolder(this.finalVersion, true);
            return;
        }
        this.selectedFolder = undefined;
        this.documents = [];
        this.breadcrumbsBack();
    }

    closeFinalFolder(toRoot?: boolean) {
        this.isFinalVersionFolderRoot = false;
        this.selectedFolder = undefined;
        this.documents = [];
        this.breadcrumbsBack(toRoot);
    }

    breadcrumbsBack(toRoot?: boolean) {
        this.breadcrumbs.splice(toRoot ? 1 : -1);
        this.breadcrumbs = this.uniqByFilter(this.breadcrumbs);
    }

    uniqByFilter<T>(array: T[]) {
        return array.filter((value, index) => array.indexOf(value) === index);
    }

    deleteDocument(document: any) {
        this.isLoading = true;
        this.driveService
            .deleteDocument(document.id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                () => {
                    this.notificationService.success(this.translate.instant('ui.drive.document_deleted'));
                    this.documents = this.documents.filter((document) => document.id !== document.id);
                    this.driveService.folderContentChangedEmit();
                    this.closeDialog();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                }
            );
    }

    addToFavorite(file: any) {
        this.isLoading = true;
        this.driveService
            .setFavorite(file.id, !file.favorite)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    const documentIndex = this.documents.findIndex((document) => document.id === response.data.id);
                    this.documents[documentIndex]['favorite'] = response.data.favorite;
                    this.driveService.folderContentChangedEmit();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                }
            );
    }

    download() {
        this.driveService.downloadFinalVersion().subscribe((response: any) => {
            console.log(response);
        });
    }

    downloadFolder() {
        this.isFolderDownloading = true;
        this.driveService
            .downloadFolder(this.teamId, this.taskId, this.isFinalVersionFolder)
            .pipe(finalize(() => (this.isFolderDownloading = false)))
            .subscribe(
                (response: any) => {
                    window.open(response.data, '_blank');
                },
                (err) => {
                    this.notificationService.error(err.error.reason, 'Error');
                }
            );
    }

    navigateByBreadcrumbs(index: number): void {
        if (index === 0 && this.breadcrumbs.length !== 1) {
            if (this.isFinalVersionFolderRoot) {
                this.closeFinalFolder(true);
                return;
            }

            if (!this.isFinalVersionFolderRoot) {
                this.closeFolder();
                return;
            }
        }

        if (index === 1 && this.breadcrumbs.length > 2) {
            this.closeFolder();
        }
    }
}
