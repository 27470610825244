import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

interface Game {
    id?: number;
    image_path: string | null;
    authors: any;
    progress: number | null;
    like: boolean;
}

@Component({
    selector: 'app-games-selector',
    templateUrl: './games-selector.component.html',
    styleUrls: ['./games-selector.component.scss'],
    providers: [],
})
export class GamesSelectorComponent implements OnInit {
    isLoading: boolean = false;

    @Input() category: any;
    @ViewChild('banner', { static: false }) banner: ElementRef;

    institutions: Game[] = [];
    videoURl: string = '';

    storageUrl: string = environment.storageUrl;

    games: any[] = [
        {
            name: 'Episode 1',
            image_path: 'institutions/238c8db2f802c8f210bf53e96f036d1b.jpg',
            authors: [
                {
                    name: 'Elloy',
                    image_path: 'institutions/238c8db2f802c8f210bf53e96f036d1b.jpg',
                },
                {
                    name: 'Elloy',
                    image_path: 'institutions/238c8db2f802c8f210bf53e96f036d1b.jpg',
                },
                {
                    name: 'Elloy',
                    image_path: 'institutions/238c8db2f802c8f210bf53e96f036d1b.jpg',
                },
            ],
            progress: 78,
            like: false,
        },
        {
            name: 'Episode 2',
            image_path: 'institutions/238c8db2f802c8f210bf53e96f036d1b.jpg',
            authors: [
                {
                    name: 'Elloy',
                    image_path: 'institutions/238c8db2f802c8f210bf53e96f036d1b.jpg',
                },
            ],
            progress: 78,
            like: false,
        },
        {
            name: 'Episode 3',
            image_path: 'institutions/238c8db2f802c8f210bf53e96f036d1b.jpg',
            authors: [
                {
                    name: 'Elloy',
                    image_path: 'institutions/238c8db2f802c8f210bf53e96f036d1b.jpg',
                },
            ],
            progress: 78,
            like: false,
        },
        {
            name: 'Episode 4',
            image_path: 'institutions/238c8db2f802c8f210bf53e96f036d1b.jpg',
            authors: [
                {
                    name: 'Elloy',
                    image_path: 'institutions/238c8db2f802c8f210bf53e96f036d1b.jpg',
                },
                {
                    name: 'Elloy',
                    image_path: 'institutions/238c8db2f802c8f210bf53e96f036d1b.jpg',
                },
            ],
            progress: 78,
            like: false,
        },
    ];

    constructor() {}

    ngOnInit() {
        this.fetch();
    }

    ngAfterViewInit() {
        // this.banner.nativeElement.scrollIntoView();
    }

    ngOnChanges() {
        setTimeout(() => {
            if (this.banner) {
                this.banner.nativeElement.scrollIntoView({ behavior: 'smooth' });
            }
        }, 200);
    }

    fetch() {
        // this.institutionsService.getAll().subscribe((response: any) => {
        //   this.institutions = response.data;
        // });
    }
}
