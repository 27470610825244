import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { animateOpacity } from 'src/app/animations/fly-animations';
import { DriveService } from 'src/app/services/drive.service';
import { finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Tools } from 'src/app/tools';

@Component({
    selector: 'app-team-drive',
    templateUrl: './team-drive.component.html',
    styleUrls: ['./team-drive.component.scss'],
    animations: [animateOpacity],
})
export class TeamDriveComponent implements OnInit {
    @Input() isChatOpened: boolean = false;
    @Input() teamId: number = 0;

    documents: any[] = [];
    folders: any[] = [];
    finalVersion: any[] = [];

    isLoading: boolean = false;
    subscriptions: Subscription[] = [];

    constructor(
        private driveService: DriveService,
        private notificationService: NotificationService,
        public dialog: MatDialog,
        public sessionService: SessionService,
        public translate: TranslateService,
    ) {
        this.subscriptions.push(this.driveService.folderContentChanged$.subscribe(() => this.fetch()));
    }

    ngOnInit() {
        this.fetch();
    }

    ngOnDestroy() {
        Tools.unsubscribeAll(this.subscriptions);
    }

    fetch() {
        this.isLoading = true;
        this.driveService
            .getCurrentTeamkDrive()
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe((response: any) => {
                this.documents = response.data.documents || [];
                this.folders = response.data.team_tasks || [];
                this.finalVersion = response.data.final_version || [];
            });
    }

    upload(uploadEvent: any) {
        const formData = new FormData();
        formData.append('file', uploadEvent.file, uploadEvent.file.name);

        this.isLoading = true;

        this.driveService
            .addDocumentByTeam(this.teamId, formData)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialog.closeAll();
                    this.fetch();
                    this.driveService.folderContentChangedEmit();
                    this.notificationService.success(this.DOC_UPLOAD_SUCCESS_TOASTR);
                },
                error: (err) => {
                    this.notificationService.error(err.error.message, this.ERROR_TOASTR);
                },
            });
    }

    get DOC_UPLOAD_SUCCESS_TOASTR() {
        return this.translate.instant('communications.section.doc_upload_success_toastr');
    }

    get ERROR_TOASTR() {
        return this.translate.instant('communications.section.error_toastr');
    }
}
