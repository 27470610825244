import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-info-box',
    templateUrl: './info-box.component.html',
    styleUrls: ['./info-box.component.scss'],
    providers: [],
})
export class InfoBoxComponent {
    isLoading: boolean = false;

    @Input() name: string = '';
    @Input() description: string = '';
    @Input() imagePath: string = '/assets/uni-1.png';
    @Input() centerText: boolean = false;
}
