<ng-container *ngIf="!isLoading">
    <div class="mb-4">
        <app-un-page-sub-panel
            [title]="taskName"
            [backUrl]="'/communications?tab=tasks'"
            [textDecoration]="isCompleted ? 'line-through' : ''"
            [saveText]="'communications.workspace.save_task' | translate"
            (onSubmit)="save()"
            [allowRename]="true"
            (onRename)="openRenameTaskDialog(renameTaskTemplate)"
        >
            <div class="flex justify-end gap-8">
                <button
                    *ngIf="task.is_presentation_btn"
                    matRipple
                    class="group flex items-center justify-center gap-x-1 rounded-lg px-3 py-1 text-xs"
                    (click)="generetePresentation()"
                    [disabled]="isPresentationLoading"
                    [ngClass]="{
                        'bg-gray-500/25 text-gray-600': isPresentationLoading,
                        'bg-slate-50/[.06]  text-[#C6C2DF]': !isPresentationLoading
                    }"
                >
                    {{ 'communications.workspace.generate_presentation' | translate }}
                    <i class="ph ph-disc interactive text-lg" [ngClass]="{ 'animate-spin': isPresentationLoading }"></i>
                </button>
                <div>
                    <span class="whitespace-normal text-sm text-[#C6C2DF]">{{
                        'communications.workspace.responsible' | translate
                    }}</span>
                    <div class="flex">
                        <app-task-students
                            [users]="students"
                            (usersUpdated)="updateStudents($event)"
                        ></app-task-students>
                    </div>
                </div>
            </div>
        </app-un-page-sub-panel>
    </div>

    <div *ngIf="task" class="grid grid-cols-12 gap-x-3">
        <div class="col-span-12 lg:col-span-7">
            <div *ngIf="!tasksService.isGuide(taskData.task_type.type)" class="mb-3 flex flex-wrap items-center gap-2">
                <ng-container *ngFor="let tag of taskTags">
                    <span class="task-chip" [ngStyle]="{ color: tag.color, 'border-color': tag.border }">
                        <i [ngClass]="'ph-' + tag.icon"></i>
                        {{ tag?.label }}
                    </span>
                </ng-container>
                <ng-container *ngIf="taskTags.length > 0">
                    <button class="flex" (click)="openEditTagsModal(editTagsTemplate)">
                        <i class="ph ph-pencil-simple"></i>
                    </button>
                </ng-container>
                <ng-container *ngIf="taskTags.length === 0">
                    <button
                        class="flex h-[26px] items-center gap-2 rounded-md px-4 text-xs capitalize text-[#8e91d1]"
                        (click)="openEditTagsModal(editTagsTemplate)"
                        style="border: 1px solid #8e91d1"
                    >
                        <i class="ph ph-plus"></i> add tags
                    </button>
                </ng-container>
            </div>

            <div class="team-info-row flex">
                <div class="mb-3 flex gap-8">
                    <div *ngIf="taskData.task_type.id !== 1" class="mb-3 flex flex-wrap items-center gap-2">
                        <div class="flex items-center">
                            <div>
                                <app-date-range-picker
                                    [defaultBegin]="start"
                                    [defaultEnd]="end"
                                    (dateChanged)="setTaskDate($event)"
                                ></app-date-range-picker>
                            </div>

                            <div
                                *ngIf="taskData.task_type && tasksService.isGuide(taskData.task_type.type)"
                                class="mb-3 flex flex-wrap items-center justify-between gap-2"
                            >
                                <span
                                    class="task-chip bg-[#FF02D0]"
                                    [ngStyle]="{
                                        color: taskData.task_type.color,
                                        'border-color': taskData.task_type.border
                                    }"
                                >
                                    <i [ngClass]="taskData.task_type.icon"></i>
                                    {{ taskData.task_type?.title }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3 flex flex-wrap items-center justify-between gap-2">
                        <app-select
                            class="w-[240px]"
                            [label]="'communications.workspace.status' | translate"
                            [placeholder]="'communications.workspace.change_status' | translate"
                            [options]="statuses"
                            [optionValueSelector]="'id'"
                            [optionTextSelector]="'label'"
                            [(ngModel)]="currentStatus"
                            (ngModelChange)="changeStatus(currentStatus)"
                        >
                        </app-select>
                    </div>
                </div>
            </div>

            <div class="mb-3 flex flex-col gap-6">
                <div
                    #editor
                    class="word-break flex-fill prose prose-sm mb-4 flex max-w-none items-center rounded-lg text-[#8E91D1]"
                    [ngClass]="{
                        'h-20 border border-dashed border-[#8E91D1] hover:bg-[#FFFFFF1A]':
                            htmlContent === '' && !enableEditor
                    }"
                >
                    <ng-container *ngIf="enableEditor">
                        <angular-editor
                            class="w-full"
                            [placeholder]="'communications.workspace.task_description' | translate"
                            [(ngModel)]="htmlContent"
                            [config]="editorConfig"
                        >
                        </angular-editor>
                    </ng-container>
                    <ng-container *ngIf="!enableEditor">
                        <div
                            class="w-full"
                            [class.px-4]="htmlContent === ''"
                            [innerHtml]="
                                htmlContent
                                    ? (htmlContent | safe: 'html')
                                    : ('communications.workspace.task_description' | translate)
                            "
                        ></div>
                    </ng-container>
                </div>

                <div>
                    <app-control-list
                        [taskId]="task?.id"
                        [subtasks]="taskData.team_subtasks"
                        (subTaskUpdated)="loadTask()"
                    >
                    </app-control-list>
                </div>

                <app-textarea
                    class="mt-4"
                    [label]="'communications.workspace.final_version' | translate"
                    [formControl]="finalVersion"
                    [rows]="4"
                    maxLength="480"
                >
                </app-textarea>
            </div>
        </div>

        <div class="col-span-12 flex justify-center lg:col-span-5 lg:justify-end">
            <app-task-tools *ngIf="taskId" [taskId]="taskId" [teamId]="task.team_id"></app-task-tools>
        </div>

        <mat-menu #menu="matMenu" backdropClass="customize" style="top: -58px">
            <ng-container *ngFor="let status of statuses">
                <div mat-menu-item [disabled]="task.status === TASK_DEFAULT_STATUS" (click)="setStatus(status.id)">
                    <span>{{ status.label }}</span>
                </div>
            </ng-container>
            <!-- <div
                mat-menu-item
                [disabled]="task.status === TASK_DEFAULT_STATUS"
                (click)="setStatus(TASK_DEFAULT_STATUS)"
            >
                <div class="flex items-center gap-2">
                    <i class="ph-play-circle-bold"></i>
                    <span>{{ 'communications.workspace.start_task' | translate }}</span>
                </div>
            </div>
            <div mat-menu-item [disabled]="task.status === 'completed'" (click)="setStatus('completed')">
                <div class="flex items-center gap-2">
                    <i class="ph-check-circle"></i>
                    <span>{{ 'communications.workspace.complete_task' | translate }}</span>
                </div>
            </div> -->
        </mat-menu>
    </div>
</ng-container>

<div *ngIf="isLoading" class="flex h-full items-center justify-center" style="max-height: 400px">
    <mat-spinner></mat-spinner>
</div>

<mat-menu #taskTypeMenu="matMenu" backdropClass="customize">
    <ng-template matMenuContent>
        <ng-container *ngFor="let taskType of availableTypes">
            <div mat-menu-item (click)="setTaskType(taskType)">
                <div class="flex items-center gap-2">
                    <i [ngClass]="taskType.icon"></i>
                    <span>{{ taskType.title }}</span>
                </div>
            </div>
        </ng-container>
    </ng-template>
</mat-menu>

<ng-template #editTagsTemplate>
    <div
        class="bg-r flex h-[520px] min-w-[942px] max-w-[1000px] flex-col overflow-hidden px-14 py-8 2xl:px-16 2xl:py-10"
    >
        <div class="mb-3 text-start">
            <span class="text-3xl font-medium uppercase leading-snug text-[#E1DEF5]">
                {{ 'welcome_pop_up.tags_title' | translate }}
            </span>
        </div>

        <div class="mb-2 text-start">
            <div class="text-sm leading-7 text-[#EDF2FF]">
                <span class="flex"> {{ 'welcome_pop_up.tags_desc' | translate }} </span>
            </div>
        </div>
        <div class="my-3 text-start">
            <span class="text-xs leading-4 text-[#8E91D1]">{{ 'welcome_pop_up.user_choice' | translate }}:</span>
        </div>
        <div class="mb-4 flex flex-wrap gap-2">
            <ng-container *ngFor="let tag of selectedTags">
                <app-tag [tag]="tag" (clicked)="removeTag(tag)"></app-tag>
            </ng-container>
        </div>
        <div class="mb-3"></div>
        <div class="mb-3 text-start">
            <span class="text-xs leading-4 text-[#8E91D1]">{{ 'welcome_pop_up.all_tags' | translate }}:</span>
        </div>
        <div class="mb-6 flex flex-wrap gap-2">
            <ng-container *ngFor="let tag of availableTags">
                <span
                    class="cursor-pointer rounded-md bg-[#363160] px-2 py-1 capitalize text-[#9F8DD0] transition-all"
                    (click)="pushTag(tag)"
                >
                    <div class="flex items-center gap-x-1">
                        <i class="ph text-base" [ngClass]="'ph-' + tag.icon"></i>
                        <span>
                            {{ tag.label }}
                        </span>
                    </div>
                </span>
            </ng-container>
        </div>

        <div class="mt-auto flex items-center">
            <div class="col-span-2 flex justify-end ltr:mr-auto rtl:ml-auto">
                <div>
                    <app-un-back-button (click)="closeDialog()"></app-un-back-button>
                </div>
            </div>

            <div class="col-span-10 flex justify-center gap-x-2"></div>

            <div class="col-span-2 flex justify-end ltr:ml-auto rtl:mr-auto">
                <div>
                    <app-un-save-button
                        [text]="'welcome_pop_up.save_button' | translate"
                        (click)="setTaskTags()"
                    ></app-un-save-button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #renameTaskTemplate>
    <div class="bg-r flex h-72 min-w-[942px] max-w-[1000px] flex-col overflow-hidden px-14 py-8 2xl:px-16 2xl:py-10">
        <div class="mb-3 text-start">
            <span class="text-3xl font-medium uppercase leading-snug text-[#E1DEF5]">
                {{ 'communications.workspace.type_task_title_here' | translate }}
            </span>
        </div>

        <div class="mb-2 text-start">
            <app-text-input class="w-full" [formControl]="taskNameControl" maxLength="48"></app-text-input>
        </div>

        <div class="mt-auto flex items-center">
            <div class="col-span-2 flex justify-end ltr:mr-auto rtl:ml-auto">
                <div>
                    <app-un-back-button (click)="closeDialog()"></app-un-back-button>
                </div>
            </div>

            <div class="col-span-10 flex justify-center gap-x-2"></div>

            <div class="col-span-2 flex justify-end ltr:ml-auto rtl:mr-auto">
                <div>
                    <app-un-save-button
                        [text]="'welcome_pop_up.save_button' | translate"
                        (click)="saveRenamedTaskName()"
                    ></app-un-save-button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
