import { AngularEditorConfig } from '@kolkov/angular-editor';

export const config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '80px',
    maxHeight: 'auto',
    // width: '100%',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
        ['strikeThrough', 'subscript', ],
        [
            'customClasses',
            'insertImage',
            'insertVideo',
            'toggleEditorMode',
        ],
    ],
};
