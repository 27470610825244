import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable()
export class QuestionControlService {
    toFormGroup(questions: any) {
        const group: any = {};

        // questions.forEach((question: any) => {
        //     group[question.id] = question.required
        //         ? new FormControl(question.value || '', Validators.required)
        //         : new FormControl(question.value || '');
        // });

        questions.forEach((question: any) => {
            group[question.id] = new UntypedFormControl(this.getAnswer(question), Validators.required);
        });
        return new UntypedFormGroup(group);
    }

    getAnswer(question: any) {
        if (question.answer) {
            return question.field_type === 'radio' ? question.answer.id : question.answer.open_answer;
        }

        return '';
    }
}
