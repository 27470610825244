import { Validators } from '@angular/forms';
import { Form } from './Form';

export class SetTeacherMentorForm extends Form {
    elements = [];
    title: () => '';

    buildForm(teachers: any, teamId: number): void {
        this.form = {
            title: (): string => 'Upload document',
            elements: [
                {
                    name: 'Teacher mentor',
                    selector: 'teacher_id',
                    type: 'select',
                    options: teachers,
                    optionValueSelector: 'id',
                    optionTextSelector: 'name',
                    validators: [Validators.required],
                },
                {
                    name: 'Team',
                    selector: 'team_id',
                    type: 'text',
                    value: teamId,
                    visible: false,
                },
            ],
        };
    }
}
