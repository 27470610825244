<ng-container *ngIf="!isLoading">
    <div [@flyInOut] class="mb-4">
        <app-un-page-sub-panel [title]="game?.name" [backUrl]="'/games'" [onlyBack]="true"></app-un-page-sub-panel>
    </div>

    <div class="grid grid-cols-12 gap-x-4 lg:gap-x-6 game-details">
        <div *ngIf="!selectedGame" class="col-span-12 lg:col-span-8 order-2 lg:order-1">
            <img class="aspect-16 object-cover w-full" [src]="game?.image_path" alt="" />
        </div>
        <div *ngIf="selectedGame" class="col-span-12 lg:col-span-8 order-2 lg:order-1">
            <app-server-game-container [game]="game" (gameLoaded)="gameLoad()" (gameClosed)="exit()">
            </app-server-game-container>
        </div>

        <div [@flyRigthIn] class="col-span-12 lg:col-span-4 order-1 lg:order-2 flex flex-col">
            <button
                *ngIf="!selectedGame"
                matRipple
                class="play-game-button text-uppercase font-weight-bold mb-3"
                (click)="start()"
            >
                Start Play
            </button>
            <button
                *ngIf="selectedGame && !isAbleExit"
                matRipple
                class="play-game-button text-uppercase font-weight-bold mb-3"
                [disabled]="!isAbleExit"
            >
                <ng-container *ngIf="!isAbleExit">
                    <mat-spinner diameter="24"></mat-spinner>
                </ng-container>
            </button>
            <button
                *ngIf="selectedGame && isAbleExit"
                matRipple
                class="play-game-button text-uppercase font-weight-bold mb-3"
                (click)="closeGame()"
            >
                Exit
            </button>
            <div class="flex justify-between game-details-row py-2">
                <div><span>Platform</span></div>
                <div>
                    <span><i class="ph-google-chrome-logo"></i></span>
                </div>
            </div>
            <div class="flex justify-between game-details-row py-2">
                <div><span>Developer</span></div>
                <div>
                    <span>{{ game.author }}</span>
                </div>
            </div>
            <div class="flex justify-between game-details-row mb-4 py-2">
                <div><span>Size</span></div>
                <div><span>1.34 MB</span></div>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="isLoading" class="flex justify-center items-center" style="height: 440px">
    <app-un-game-loader></app-un-game-loader>
</div>
