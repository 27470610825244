<div *ngIf="!selectedTheme && !isFormOpen" class="start-screen-wrapper">
    <div class="flex justify-center mb-5">
        <div [@flyInOut] class="col-3 text-start">
            <h1 class="mb-0 hi-image">{{ 'questionnaire.topic.greeting_header_choose' | translate }}</h1>
            <h1>{{ 'questionnaire.topic.greeting_header_topic' | translate }}</h1>
        </div>

        <div class="col-6">
            <div class="text-mute mb-4">
                <div [@flyRigthIn] class="mb-3 ml-auto" style="width: 80%">
                    <div class="mb-5 text-start">{{ 'questionnaire.topic.greeting_header_desc' | translate }}</div>

                    <div class="underline-spacer"></div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isLoading" [@flyleftIn] class="topics-grid justify-center pb-3">
        <div *ngIf="suggestThemeMode" class="suggest-topic p-3 flex justify-center items-center flex-col">
            <div class="suggest-topic-button mb-2 cursor-pointer" (click)="isFormOpen = true">
                <i class="ph-plus"></i>
            </div>
            <div>{{ 'questionnaire.topic.add_topic' | translate }}</div>
        </div>

        <ng-container *ngFor="let theme of themes">
            <div class="main-card p-3" style="min-width: 300px">
                <div class="mb-3 topic-box-row">
                    <div>
                        <img
                            [class.image-border]="theme.image"
                            [src]="theme.image ? storageUrl + theme.image : '/assets/img.png'"
                        />
                    </div>
                    <div class="text-start">
                        <div class="mb-2 topic-box-row-name">{{ theme.title }}</div>
                        <div class="topic-box-row-desc">{{ theme.description }}</div>
                    </div>
                </div>

                <div style="width: max-content">
                    <app-un-save-button
                        [text]="'questionnaire.topic.read_more_button' | translate"
                        style="zoom: 0.75"
                        (click)="selectTheme(theme)"
                    ></app-un-save-button>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<app-theme-view
    *ngIf="selectedTheme"
    [theme]="selectedTheme"
    (backTrigerred)="closeThemeView()"
    (themeSelected)="chooseTheme($event)"
></app-theme-view>

<app-suggest-theme
    *ngIf="isFormOpen"
    (backTrigerred)="isFormOpen = false"
    (themeSelected)="chooseTheme($event)"
></app-suggest-theme>
