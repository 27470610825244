// import { AppService } from './../../services/app.service';
import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Route, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { ChatsService } from 'src/app/services/chat.service';
import { FriendsService } from 'src/app/services/friends.service';
import { LoginService } from 'src/app/services/login.service';
import { NavProgressService } from 'src/app/services/nav-progress.service';
import { SessionService } from 'src/app/services/session.service';
import { Tools } from 'src/app/tools';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    @Input() simplified: boolean = false;

    isCollapsed = true;
    rate: number = 0;

    friendsSidebarMode: string = 'friends';

    friendsListMode: string = 'list';

    subscriptions: Subscription[] = [];

    isChatActive: boolean = false;
    isDriveActive: boolean = false;
    isVideoChatActive: boolean = false;

    currentUser = this.session.getCurrentUser();
    userTeamId: number | undefined;

    globalTeamId: number = 0;

    videoChatRoutes = ['/communications', 'communications/team/'];

    constructor(
        private navProgressService: NavProgressService,
        private appService: AppService,
        private router: Router,
        private chatService: ChatsService,
        public session: SessionService,
        public loginService: LoginService,
        public route: ActivatedRoute,
    ) {
        this.subscriptions.push(
            this.navProgressService.rateChanged$.subscribe((rate: number) => {
                this.rate = rate;
            }),
        );
        this.subscriptions.push(
            this.session.changeUser$.subscribe(() => {
                this.currentUser = this.session.getCurrentUser();
            }),
        );

        this.subscriptions.push(
            this.session.teamChanged$.subscribe(() => {
                this.isChatActive = false;
                this.isDriveActive = false;
            }),
        );

        this.subscriptions.push(
            this.session.projectChanged$.subscribe(() => {
                this.isChatActive = false;
                this.isDriveActive = false;
            }),
        );

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Check for the tab query parameter
                const urlParams = new URLSearchParams(window.location.search);
                const currentTab = urlParams.get('tab');

                // Set isVideoChatActive to true only if on the 'tasks' tab
                this.isVideoChatActive = currentTab === 'tasks';
            }
        });
    }

    ngOnInit() {
        this.userTeamId = this.currentUser.team_id;
    }

    get isProgress(): boolean {
        return this.router.url.includes('questionnaire');
    }

    ngOnDestroy() {
        Tools.unsubscribeAll(this.subscriptions);
    }

    toggleSidebarPin() {
        this.appService.toggleSidebarPin();
    }

    toggleSidebar() {
        this.appService.toggleSidebar();
    }

    logOut() {
        this.loginService.logOut().subscribe(() => {
            this.router.navigateByUrl('/login');
        });
    }

    navigateMenu(link: string) {
        this.router.navigate([link]);
    }

    toggleChat() {
        this.isChatActive = !this.isChatActive;
        this.setChatUrl();
    }

    toggleDrive() {
        this.isDriveActive = !this.isDriveActive;
        this.setChatUrl();
    }

    toggleSideBar() {
        this.session.toggleSideBar();
    }

    get isAbleChangeLang(): boolean {
        return this.session.getCurrentUserSchool()?.is_change_language;
    }

    toggleVideoChat() {
        this.chatService.getTeamChat().subscribe({
            next: ({ data }: any) => {
                this.openExternalLink(data.link);
            },
        });
    }

    setChatUrl() {
        this.chatService.getTeamChat().subscribe({
            next: ({ data }: any) => {
                this.globalTeamId = data.team_id;
            },
        });
    }

    openExternalLink(url: string) {
        window.open(url, '_blank');
    }
}
