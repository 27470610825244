<app-card class="mb-3 h-full" [title]="ENTRY_NAME">
    <div class="flex items-center">
        <div class="text-xl mb-3 font-medium">{{ ENTRY_NAME }}</div>

        <div class="col-auto">
            <button *ngIf="!isFullView" mat-button (click)="toggleView()">
                <span *ngIf="isTableView" class="text-large"><i class="fas fa-chart-bar"></i></span>
                <span *ngIf="!isTableView" class="text-large"><i class="fas fa-list"></i></span>
            </button>

            <button mat-button (click)="toggleFullView()">
                <span class="text-large"><i class="fas fa-tasks" [class.interactive]="isFullView"></i></span>
            </button>
        </div>
    </div>

    <div>
        <app-un-game-loader *ngIf="isLoading"></app-un-game-loader>

        <div *ngIf="(!isLoading && !isTableView) || (!isLoading && isFullView)" class="flex flex-wrap mb-3">
            <ng-container *ngFor="let chart of charts.slice(page - 1, page + 1)">
                <div class="col-6">
                    <div class="flex items-center justify-around relative">
                        <div class="stripe left"></div>
                        <div class="user-title">
                            <span>Group mentor</span>
                        </div>
                        <div class="text-right my-2">
                            <mat-form-field>
                                <mat-select [(value)]="selectedMentor_3">
                                    <mat-option *ngFor="let mentor of mentors" [value]="mentor.id">
                                        {{ mentor.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <highcharts-chart
                        class="team-chart-block my-3"
                        [Highcharts]="chart.chart.highcharts"
                        [options]="chart.chart.options"
                    ></highcharts-chart>

                    <div class="flex items-center justify-around relative">
                        <div class="stripe right"></div>

                        <div class="user-title">
                            <span>Student mentor</span>
                        </div>
                        <div class="text-right my-2">
                            <mat-form-field>
                                <mat-select [(value)]="selectedMentor_3">
                                    <mat-option *ngFor="let mentor of users" [value]="mentor.id">
                                        {{ mentor.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div *ngIf="!isLoading && charts.length === 0" class="col-2 mx-auto">
            <div class="w-full">
                <app-un-magic-button
                    [text]="'Create Teams'"
                    [fullWidth]="true"
                    [bordered]="true"
                    (click)="generateTeams()"
                ></app-un-magic-button>
            </div>
        </div>

        <div *ngIf="(!isLoading && isTableView) || (!isLoading && isFullView)" class="row mb-3">
            <ng-container *ngFor="let table of tables.slice(page - 1, page + 1)">
                <div class="col-md-6 col-xs-12 mb-3">
                    <div class="col">
                        <app-table [columns]="table.table.columns" [data]="table.rows" [pagination]="false">
                        </app-table>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="w-full flex justify-center">
            <app-paginator
                [columns]="charts"
                [limit]="2"
                [totalItems]="charts.length"
                [hidePerPageSelect]="true"
                (pageChanged)="setPage($event)"
            ></app-paginator>
        </div>
    </div>
</app-card>
