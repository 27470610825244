import { Chart } from './Chart';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
HighchartsMore(Highcharts);


export class GroupsChart extends Chart {


    constructor(chartData: any) {
        super();
        this.build(chartData);
    }

    build(chartData: any) {

        this.options = {
            ...this.options, ...{
                colors: [
                    '#FF02D0',
                    '#FF9900',
                    '#00A3FF',
                    '#7B61FF',
                    '#B6A8FF',
                    '#00A3FF',
                    '#FF02D0',
                    '#FF9900',
                ],
                chart: {
                    polar: true,
                    // type: 'line'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: chartData.title
                },
                // pane: {
                //     startAngle: 0,
                //     endAngle: 360
                // },
                legend: {
                    enabled: true,
                },
                xAxis: {
                    type: 'category',
                    tickInterval: 1,
                    categories: chartData.categories,
                    min: 0,
                    max: 8,
                    tickmarkPlacement: 'on',
                    lineWidth: 0,
                    labels: {
                        style: {
                            color: '#fff'
                        }
                    }
                },
                yAxis: {
                    gridLineInterpolation: 'polygon',
                    min: 0,
                    max: 5,
                    tickInterval: 1,
                    minorTickInterval: 0.5,
                    showLastLabel: 'true',
                },
                plotOptions: {
                    area: {
                        shadow: true,
                        fillOpacity: 0.4,
                        dataLabels: {
                            enabled: true
                        }
                    },
                    series: {
                        pointStart: 0,
                        pointInterval: 1,

                    },
                    column: {
                        pointPadding: 0,
                        groupPadding: 0
                    }
                },
                series: chartData.series
            }
        };

    }



}

