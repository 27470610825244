import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';
import { TasksService } from 'src/app/services/tasks.service';
import { Tools } from 'src/app/tools';
import { TranslateService } from '@ngx-translate/core';
import { ConfettiService } from '../../../../services/confetti.service';
import { SessionService } from '../../../../services/session.service';

enum ProgressColors {
    red = '#BE9A54',
    yellow = '#BE6969',
    green = '#7CA38B',
}

export enum TaskStatuses {
    ToDo = 'to_do',
    Progress = 'in_progress',
    Review = 'in_review',
    Done = 'done',
}

export type TaskType = {
    id: number;
    type: string;
    title: string;
    icon: string;
    color: string;
};

@Component({
    selector: 'app-task-card',
    templateUrl: './task-card.component.html',
    styleUrls: ['./task-card.component.scss'],
})
export class TaskCardComponent {
    date: string = moment().format('DD MMM YYYY');

    readonly TaskStatuses = TaskStatuses;

    @Input() card: any;
    @Input() displayTags: boolean = false;
    @Input() isFooter: boolean = true;

    @Output() taskUpdated: EventEmitter<any> = new EventEmitter();

    isLoading: boolean = true;

    constructor(
        private router: Router,
        private notificationService: NotificationService,
        public translate: TranslateService,
        public confettiService: ConfettiService,
        public tasksService: TasksService,
        public session: SessionService,
    ) {}

    get isTaskComplete(): boolean {
        return this.card.status?.status === TaskStatuses.Done;
    }

    getColorByValue(value: number) {
        let color = '';

        if (value >= 0) {
            color = ProgressColors.red;
        }
        if (value >= 50) {
            color = ProgressColors.yellow;
        }
        if (value > 75) {
            color = ProgressColors.green;
        }

        return color;
    }

    getTaskTimeLeft(deadlineTime: string): any {
        const deadlineComputedTime = Tools.getTaskTimeLeft(deadlineTime);
        return { time: deadlineComputedTime.time, units: 'ui.time_items.' + Tools.getTaskTimeLeft(deadlineTime).units };
    }

    openMenu(event: any) {
        const parent = event.target.parentElement.closest('.main-card');
        if (parent) {
            const menuButton = parent.getElementsByClassName('menu-trigger');
            if (menuButton) {
                menuButton[0].click();
            }
        }
    }

    goToTaskPage() {
        this.router.navigateByUrl('/communications/manage-task/' + this.card.id);
    }

    removeTagsFromString(string: string) {
        return Tools.removeTagsFromString(string);
    }

    deleteTask() {
        this.tasksService
            .delete(this.card.id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success(this.TASK_DELETED_SUCCESS_TOASTR);
                    this.taskUpdated.emit();
                },
                error: (err) => {
                    this.notificationService.error(err.error.message, this.ERROR_TOASTR);
                },
            });
    }

    runFireWorks() {
        let count = 0;

        const intervalId = setInterval(() => {
            if (count < 3) {
                this.confettiService.shoot();
                count++;
            } else {
                clearInterval(intervalId);
            }
        }, 800);
    }

    setStatus(status: string) {
        this.tasksService.update(this.card.id, { status: status }).subscribe({
            next: (): any => {
                this.notificationService.success(this.TASK_STATUS_CHANGED_SUCCESS_TOASTR);
                this.taskUpdated.emit();

                if (status === 'completed') {
                    this.runFireWorks();
                }
            },
            error: (err: any) => {
                this.notificationService.error(this.SOMETHING_WRONG_TOASTR);
            },
        });
    }

    canDeleteTask(card: any): boolean {
        return this.canAdminDelete(card) || this.canTeacherDelete(card) || this.canOtherRoleDelete(card);
    }

    canAdminDelete(card: any): boolean {
        return this.session.isAdmin() && !this.tasksService.isGuide(card.task_type.type);
    }

    canTeacherDelete(card: any): boolean {
        return this.session.isTeacher() && !this.tasksService.isGuide(card.task_type.type);
    }

    canOtherRoleDelete(card: any): boolean {
        return (
            !this.session.isAdmin() &&
            !this.session.isTeacher() &&
            !this.tasksService.isGuide(card.task_type.type) &&
            !this.tasksService.isBasic(card.created_type)
        );
    }

    get TASK_DELETED_SUCCESS_TOASTR() {
        return this.translate.instant('communications.workspace.task_deleted_success_toastr');
    }

    get ERROR_TOASTR() {
        return this.translate.instant('communications.info.error_toastr');
    }

    get TASK_STATUS_CHANGED_SUCCESS_TOASTR() {
        return this.translate.instant('communications.workspace.task_status_changed_success_toastr');
    }

    get SOMETHING_WRONG_TOASTR() {
        return this.translate.instant('communications.workspace.something_wrong_toastr');
    }
}
