import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-roadmap',
    templateUrl: './roadmap.component.html',
    styleUrls: ['./roadmap.component.scss'],
    providers: [],
})
export class RoadmapComponent implements OnInit {
    date: string = moment().format('DD MMM YYYY');

    isLoading: boolean = true;

    days: string[] = ['05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '16', '17', '18', '19'];

    constructor() {}

    ngOnInit() {}

    isCurrentDay(day: string): boolean {
        return day === '06';
    }
}
