import { Component, Input, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-tutorial',
    template: `
        <div class="flex items-center justify-center">
            <div
                class="flex justify-center items-center w-7 h-7 rounded-full cursor-pointer bg-[#9F8DD0] hover:bg-[#7CA38B] duration-300"
                (click)="openDialog(videoModal)"
            >
                <i class="ph-play"></i>
            </div>
        </div>

        <ng-template #videoModal>
            <div mat-dialog-content style="width: 600px;">
                <video #videoPlayer id="game-video" width="100%" height="100%" muted controls>
                    <source [src]="tutorialSource" type="video/mp4" />
                    Your browser does not support HTML video.
                </video>
            </div>
        </ng-template>
    `,
    styles: [':host { display: block; }'],
})
export class TutorialComponent {
    @Input() tutorialSource: string = '';

    constructor(private dialog: MatDialog) {}

    openDialog(dialogRef: TemplateRef<any>) {
        this.dialog.open(dialogRef);
        const myVideo: any = document.getElementById('game-video');
        myVideo.play();
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }
}
