import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Tools } from '../tools';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class GroupsService {
    constructor(private http: HttpClient) {}

    getAll() {
        return this.http.get(new Url('/groups').path);
    }

    getUsers() {
        return [
            {
                id: 1,
                name: 'Carl',
            },
            {
                id: 2,
                name: 'Peter',
            },
            {
                id: 3,
                name: 'Josh',
            },
            {
                id: 4,
                name: 'Mikel',
            },
            {
                id: 5,
                name: 'Keren',
            },
            {
                id: 6,
                name: 'Obiang',
            },
            {
                id: 7,
                name: 'Samuel',
            },
            {
                id: 8,
                name: 'Marek',
            },
        ];
    }

    getGroupsChartData() {
        return {
            title: '',
            categories: ['Biology', 'Chemistry', 'Physics', 'History', 'Art', 'Literature', 'Tanah', 'Israel culture'],
            series: [
                {
                    type: 'area',
                    name: 'Carl',
                    data: this.getSeriesData(),
                    pointPlacement: 'on',
                },
                {
                    type: 'area',
                    name: 'Peter',
                    data: this.getSeriesData(),
                    pointPlacement: 'on',
                },
                {
                    type: 'area',
                    name: 'Josh',
                    data: this.getSeriesData(),
                    pointPlacement: 'on',
                },
                {
                    type: 'area',
                    name: 'Mikel',
                    data: this.getSeriesData(),
                    pointPlacement: 'on',
                },
                {
                    type: 'area',
                    name: 'Keren',
                    data: this.getSeriesData(),
                    pointPlacement: 'on',
                },
                {
                    type: 'area',
                    name: 'Obiang',
                    data: this.getSeriesData(),
                    pointPlacement: 'on',
                },
                {
                    type: 'area',
                    name: 'Samuel',
                    data: this.getSeriesData(),
                    pointPlacement: 'on',
                },
                {
                    type: 'area',
                    name: 'Marek',
                    data: this.getSeriesData(),
                    pointPlacement: 'on',
                },
            ],
        };
    }

    getSeriesData() {
        return Array.from({ length: 8 }, (_, i) => this.generateRandom());
    }

    generateRandom(min = 1, max = 6) {
        // find diff
        let difference = max - min;

        // generate random number
        let rand = Math.random();

        // multiply with difference
        rand = Math.floor(rand * difference);

        // add with min value
        rand = rand + min;

        return rand;
    }

    getGroupsInfo() {
        return this.http.get(new Url('/teams/teams-info').path);
    }
}
