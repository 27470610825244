<div class="flex flex-col gap-4">
    <div class="mb-2 flex items-center">
        <span class="font-weight-bold text-uppercase text-large">{{ testName }}</span>
        <i class="ph-info-bold interactive mx-2" style="font-size: 1.4em"></i>
    </div>

    <div class="table-responsive">
        <table class="table-sm table-bordered table-hover test-table table w-full">
            <tbody>
                <ng-container *ngFor="let row of typeCategories">
                    <tr style="border: 1px solid rgba(237, 242, 255, 0.1)">
                        <td colspan="2" class="whitespace-nowrap text-end">
                            <div class="flex flex-nowrap items-center gap-4">
                                <div class="cell-name">
                                    {{ row.name }}
                                </div>
                                <app-un-users-group-view class="ml-auto px-2" [users]="row.students" [limit]="3">
                                </app-un-users-group-view>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
