<div
    *ngIf="team"
    class="main-card team-box z-10 h-full p-2 2xl:p-3"
    (mouseenter)="team.hover = true"
    (mouseleave)="team.hover = false"
>
    <div class="team-box-name w-full">
        <div class="flex items-center" style="gap: 10px">
            <app-un-avatar
                [name]="team.name"
                [color]="team.icon_color.class"
                [icon]="team.icon_class.class"
                [image]="team.image_path"
            ></app-un-avatar>

            <span class="overflow-hidden break-words">{{ team.name }}</span>

            <button mat-button class="ml-auto" [class.invisible]="!team.hover" (click)="openMenu($event)">
                <i class="ph-dots-three-vertical pointer-events-none text-lg text-un-pink"> </i>
            </button>

            <div class="menu-trigger invisible absolute right-2 top-1" [matMenuTriggerFor]="menu"></div>
        </div>
    </div>

    <div class="mb-2">
        <span class="team-box-label">{{ 'communications.info.team' | translate }}</span>
        <div class="flex">
            <app-un-users-group-view class="px-2" [users]="team.members.students"></app-un-users-group-view>
        </div>
    </div>

    <div class="mb-2">
        <span class="team-box-label">{{ 'communications.info.teacher_mentor' | translate }}</span>
        <div class="flex">
            <app-un-users-group-view class="px-2" [users]="team.members.teacher_mentors"> </app-un-users-group-view>
        </div>
    </div>

    <div class="mb-2">
        <span class="team-box-label">{{ 'communications.info.student_mentors' | translate }}</span>
        <div class="flex">
            <app-un-users-group-view class="px-2" [users]="team.members.student_mentors"> </app-un-users-group-view>
        </div>
    </div>
</div>

<mat-menu #menu="matMenu" backdropClass="customize" style="top: -58px">
    <div mat-menu-item (click)="goToViewPage(team.id)">
        <div class="flex items-center" style="gap: 14px">
            <i class="ph-eye"></i>
            <span>{{ 'communications.info.view' | translate }}</span>
        </div>
    </div>
    <div mat-menu-item (click)="getChartData(team)">
        <div class="flex items-center" style="gap: 14px">
            <i class="ph-chart-bar"></i>
            <span>{{ 'communications.info.show_chart' | translate }}</span>
        </div>
    </div>
    <div *ngIf="isAdmin" mat-menu-item (click)="changeMentor('teacher', team.id)">
        <div class="flex items-center" style="gap: 14px">
            <i class="ph-user-switch"></i>
            <span>{{ 'communications.info.change_teacher_mentor' | translate }}</span>
        </div>
    </div>
    <div *ngIf="isAdmin" mat-menu-item (click)="changeMentor('student', team.id)">
        <div class="flex items-center" style="gap: 14px">
            <i class="ph-user-switch"></i>
            <span>{{ 'communications.info.change_student_mentor' | translate }}</span>
        </div>
    </div>
</mat-menu>
