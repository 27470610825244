<div class="flex w-full justify-center mt-3 mb-3">
    <i class="fas fa-chevron-down fa-2x" style="opacity: 0.7"></i>
</div>

<mat-sidenav-container class="team-user-sidebar-container" hasBackdrop="false">
    <mat-sidenav-content>
        <div class="overflow-hidden w-full">
            <div style="min-height: 65vh; overflow-x: hidden">
                <div class="flex mt-5 mb-5">
                    <div class="st-title">
                        <h3>{{ 'communications.section.project_team' | translate }}</h3>
                        <span>Team Qwerty</span>
                    </div>
                </div>

                <div class="flex">
                    <div class="col-7">
                        <div class="mb-5">
                            <app-team-squad (teammateSelected)="showInfo($event)"></app-team-squad>
                        </div>

                        <div class="flex flex align-items-baseline mb-4" style="gap: 4px">
                            <h4>Project {{ project }}</h4>
                            <i class="fas fa-chevron-down fa-lg cursor-pointer" [matMenuTriggerFor]="menu"></i>
                        </div>

                        <div class="flex justify-center mb-5">
                            <div style="width: 70%">
                                <div class="flex justify-between px-1 py-2">
                                    <span>2 Weeks left</span>
                                    <span>67 %</span>
                                </div>
                                <div class="progress">
                                    <span class="progress-bar" [style.width.%]="67"></span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <app-team-project-plan></app-team-project-plan>
                        </div>
                    </div>

                    <div class="col-3 ml-5">
                        <app-team-project-drive></app-team-project-drive>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav-content>

    <mat-sidenav #sidenav mode="over" position="end" [(opened)]="opened">
        <app-team-manage-user [user]="selectedUser" (closed)="closeInfo()"></app-team-manage-user>
    </mat-sidenav>
</mat-sidenav-container>

<mat-menu #menu="matMenu">
    <ng-container *ngFor="let project of projects">
        <button mat-menu-item (click)="selectProject(project.name)">
            <mat-icon>{{ project.icon }}</mat-icon>
            <span>{{ project.name }}</span>
        </button>
    </ng-container>
</mat-menu>
