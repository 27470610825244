<div class="mb-4">
    <app-un-page-sub-panel
        [title]="team?.name"
        [backUrl]="'/communications?tab=information'"
        [onlyBack]="true"
        [enableAvatarByName]="true"
        [avatarColor]="team?.icon_color.class"
        [avatarIcon]="team?.icon_class.class"
        [image]="team?.image_path"
        (avatarClicked)="openEditTeamDialog(editModal)"
    >
        <div class="flex justify-end gap-x-10">
            <div class="flex flex-col">
                <span class="label-top-name">{{ 'communications.info.teacher_mentor' | translate }}</span>
                <div class="flex">
                    <app-un-users-group-view
                        class="px-2"
                        [users]="team?.members.teacher_mentors"
                    ></app-un-users-group-view>
                </div>
            </div>

            <div class="flex flex-col">
                <span class="label-top-name">{{ 'communications.info.student_mentors' | translate }}</span>
                <div class="flex">
                    <app-un-users-group-view class="px-2" [users]="team?.members.student_mentors">
                    </app-un-users-group-view>
                </div>
            </div>
        </div>
    </app-un-page-sub-panel>
</div>

<div class="mb-3 grid grid-cols-1 gap-4 lg:grid-cols-12">
    <app-card *ngIf="!isLoading" class="col-span-12 lg:col-span-5">
        <ng-container *ngFor="let user of users">
            <app-team-info-user-box
                [user]="user"
                [isLeader]="user.id === leaderId"
                [isAdmin]="isAdmin"
                (updateTeamLeader)="updateTeamLeader($event)"
                (changeStudent)="openDialog(changeStudentTemplate, $event)"
                (removeStudentFromTeam)="deleteUserFromTeam($event)"
            ></app-team-info-user-box>
        </ng-container>
        <div class="relative flex items-center justify-center gap-2 rounded-lg p-2">
            <button
                *ngIf="ableManageStudents()"
                mat-button
                (click)="openAddStudentModal(addStudentModal)"
                class="flex items-center justify-center bg-slate-50/[.06] px-3 py-2 text-xs text-gray-200"
            >
                <i class="ph ph-plus-circle flex text-lg text-[#FF02D0]"></i>
            </button>
        </div>
    </app-card>

    <div *ngIf="isLoading" class="col-span-12 flex h-max min-h-[370px] w-full items-center justify-center p-3">
        <app-un-game-loader message="data loading, please wait patiently shein"></app-un-game-loader>
    </div>

    <div *ngIf="!isLoading" class="col-span-12 flex flex-col gap-4 lg:col-span-7">
        <ng-container *ngIf="ableManageStudents()">
            <app-card>
                <div class="grid grid-cols-2">
                    <div>
                        <span class="font-weight-bold text-uppercase text-large">
                            {{ 'communications.info.disciplines' | translate }}
                        </span>
                        <app-team-chart *ngIf="teamChartData" [chart]="teamChartData"> </app-team-chart>
                    </div>
                    <div>
                        <span class="font-weight-bold text-uppercase text-large">
                            {{ 'communications.info.skills' | translate }}
                        </span>
                        <app-skills-chart [chart]="skillsChartData"></app-skills-chart>
                    </div>
                </div>
            </app-card>
            <div class="grid grid-cols-2 gap-3">
                <app-card>
                    <app-student-types-table testName="Holland test" [students]="users" type="personal_type">
                    </app-student-types-table>
                </app-card>
                <app-card>
                    <app-student-types-table testName="Adizes types" [students]="users" type="social_type">
                    </app-student-types-table>
                </app-card>
            </div>
        </ng-container>

        <div *ngIf="!isLoading" class="col-span-12 flex flex-col gap-4 lg:col-span-7">
            <app-card>
                <div class="flex justify-between">
                    <div class="mb-3 text-xl font-medium capitalize">
                        {{ 'communications.info.logotype' | translate }}
                    </div>
                    <!--                   <div
                        class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-[#7CA38B] text-[#382480]"
                    >
                        <i class="ph-check"></i>
                    </div>-->
                </div>

                <figure>
                    <div *ngIf="team.image_path" class="group relative cursor-pointer" (click)="team.image_path = null">
                        <img
                            class="h-auto max-w-full rounded-lg"
                            [src]="storageUrl + team.image_path"
                            alt="team logo image"
                        />
                        <div
                            class="absolute inset-0 flex items-center justify-center rounded-lg bg-black bg-opacity-50 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                        >
                            <span class="text-white"><i class="ph ph-arrows-clockwise text-2xl"></i></span>
                        </div>
                    </div>

                    <figcaption *ngIf="team.image_path" class="mt-2 text-center text-xs text-[#9D97C3]">
                        {{ 'communications.info.change_image' | translate }}
                    </figcaption>
                </figure>

                <div *ngIf="!team.image_path" class="flex flex-col items-center gap-4 text-[#9D97C3]">
                    <i class="ph ph-mountains text-4xl"></i>
                    <span class="text-xs">
                        {{ 'communications.info.image_requirments' | translate }}
                    </span>

                    <app-un-file-upload
                        [allowedExtensions]="allowedExtensions"
                        (fileChanged)="fileChange($event)"
                    ></app-un-file-upload>
                </div>
            </app-card>
            <app-card [title]="'communications.info.slogan' | translate">
                <div class="mb-4 grid grid-cols-12 gap-3">
                    <app-text-input
                        class="col-span-11 w-full"
                        [formControl]="sloganControl"
                        maxLength="48"
                        placeholder="Add your team's slogan here so that you can be motivated every time you look at it."
                    >
                    </app-text-input>
                    <div
                        matRipple
                        class="send-message-button interactive col-span-1 flex items-center justify-center rounded-full bg-gray-500/25"
                        (click)="updateSlogan()"
                    >
                        <i class="ph-paper-plane-right text-lg"></i>
                    </div>
                </div>
            </app-card>
        </div>
    </div>
</div>

<ng-template #changeStudentTemplate>
    <div class="un-scroll" mat-dialog-content style="width: 400px">
        <div class="mb-2 flex items-center">
            <span class="font-weight-bold text-uppercase text-base">
                {{ 'communications.info.switch_student' | translate }}
            </span>
            <i class="ph-info-bold interactive mx-2" style="font-size: 1.4em"></i>
        </div>
        <div class="user-info-row flex gap-2.5 p-2">
            <div class="w-7">
                <img
                    class="friend-img"
                    [src]="changeStudent.main_image ? storageUrl + changeStudent.main_image : defaultImageUrl"
                />
            </div>
            <div class="flex flex-col">
                <span class="name text-sm">{{ changeStudent.nickname }} </span>
                <span class="role text-nowrap text-xs text-[#8e91d1]">
                    <span *ngIf="changeStudent.personal_type?.types">{{ changeStudent.personal_type?.types }}</span>
                    <span *ngIf="changeStudent.social_type?.types && changeStudent.personal_type?.types">, </span>
                    <span *ngIf="changeStudent.social_type?.types">{{ changeStudent.social_type?.types }}</span>
                </span>
            </div>
            <div class="ms-auto">
                <div class="user-color-stick" [style.backgroundColor]="changeStudent.color"></div>
            </div>
        </div>

        <div class="my-4 flex h-6 w-full items-center justify-center" style="background: rgba(237, 242, 255, 0.05)">
            <span class="text-[#9d97c3]">to:</span>
        </div>

        <div class="my-1 p-2">
            <app-text-input
                placeholder="Search"
                [formControl]="searchText"
                [clear]="true"
                (keyup)="searchInput($event)"
                (inputCleared)="otherStudentsPageChange(1)"
            >
            </app-text-input>
        </div>

        <ng-container *ngFor="let otherStudent of otherStudents">
            <div class="user-info-row flex gap-x-2 p-2" (click)="changeStudentFromOtherTeam(otherStudent)">
                <div class="w-7">
                    <img
                        class="friend-img"
                        [src]="otherStudent.main_image ? storageUrl + otherStudent.main_image : defaultImageUrl"
                    />
                </div>
                <div class="flex flex-col">
                    <span class="name text-sm">{{ otherStudent.nickname }} </span>
                    <span class="role text-nowrap text-xs text-[#8e91d1]">
                        <span *ngIf="otherStudent.personal_type?.types">{{ otherStudent.personal_type?.types }}</span>
                        <span *ngIf="otherStudent.social_type?.types && otherStudent.personal_type?.types">, </span>
                        <span *ngIf="otherStudent.social_type?.types">{{ otherStudent.social_type?.types }}</span>
                    </span>
                </div>
                <div class="ms-auto">
                    <div class="user-color-stick" [style.backgroundColor]="otherStudent.color"></div>
                </div>
            </div>
        </ng-container>

        <div class="my-2 flex justify-center">
            <app-pagination
                [totalItems]="otherStudentsTotal"
                [pageSize]="8"
                (pageChange)="otherStudentsPageChange($event)"
            ></app-pagination>
        </div>
    </div>
</ng-template>

<ng-template #editModal>
    <div class="un-scroll" mat-dialog-content>
        <div class="">
            <div class="flex items-center gap-x-3">
                <div class="group relative" (click)="openUploadDialog(uploadModal)">
                    <app-un-avatar
                        [name]="team?.name"
                        [extraLarge]="true"
                        [color]="currentColor"
                        [icon]="currentTeamIcon"
                        [image]="team?.image_path"
                    ></app-un-avatar>
                    <div
                        class="absolute inset-0 hidden h-full w-full cursor-pointer items-center justify-center rounded-full transition duration-150 ease-out group-hover:flex group-hover:bg-gray-800/75"
                    >
                        <i class="ph-bold ph-pencil-simple"></i>
                    </div>
                </div>

                <app-text-input class="w-full" [formControl]="teamNameControl" maxLength="48"></app-text-input>
            </div>
        </div>

        <div class="mt-3 flex flex-row flex-wrap gap-x-4 gap-y-2 text-lg">
            <ng-container *ngFor="let color of colorsItems">
                <button
                    class="flex h-6 w-20 items-center justify-center rounded-full text-base"
                    [style.background-color]="color.class"
                    (click)="setCurrentColor(color)"
                >
                    <ng-container *ngIf="currentColor === color.class">
                        <i class="ph-check drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,1)]"></i>
                    </ng-container>
                </button>
            </ng-container>
        </div>

        <hr class="my-3" />

        <div class="flex flex-row flex-wrap gap-x-4 text-lg">
            <ng-container *ngFor="let icon of icons">
                <button
                    class="flex h-6 w-20 items-center justify-center rounded-full text-base"
                    [ngClass]="{ 'bg-slate-50/25': currentTeamIcon === icon.class }"
                    (click)="setCurrentIcon(icon)"
                >
                    <i [attr.class]="icon.class" style="color: #d4d5f2"></i>
                </button>
            </ng-container>
        </div>
    </div>

    <div mat-dialog-actions class="flex justify-end">
        <div class="w-auto" style="zoom: 0.7">
            <app-un-save-button text="save" (click)="saveTeamEdit()"></app-un-save-button>
        </div>
    </div>
</ng-template>

<ng-template #uploadModal>
    <div class="un-scroll" mat-dialog-content style="min-width: 380px; min-height: 200px">
        <div class="mb-2 flex items-center justify-start">
            <span class="font-weight-bold text-uppercase text-large text-sm">
                {{ 'communications.info.upload_image' | translate }}
            </span>
            <app-info-icon text="Select file and press upload button"></app-info-icon>
        </div>
        <div *ngIf="!isLoading" class="my-5 h-full items-center justify-center">
            <app-un-file-upload
                [allowedExtensions]="allowedExtensions"
                (fileChanged)="fileChange($event)"
            ></app-un-file-upload>
        </div>

        <div *ngIf="isLoading" class="flex items-center justify-center" style="height: 200px">
            <app-un-game-loader style="zoom: 0.7"></app-un-game-loader>
        </div>
    </div>

    <div *ngIf="!isLoading" mat-dialog-actions class="flex justify-between">
        <div>
            <button *ngIf="team?.image_path" mat-flat-button color="warn" class="mr-2" (click)="deleteTeamImage()">
                <i class="ph ph-trash"></i>
            </button>
        </div>
        <div style="zoom: 0.7">
            <app-un-save-button
                [text]="'ui.drive.upload_button' | translate"
                (сlicked)="upload()"
                [disabled]="!uploadHelper.valid"
            >
            </app-un-save-button>
        </div>
    </div>
</ng-template>

<ng-template #addStudentModal>
    <div class="un-scroll" mat-dialog-content style="width: 400px">
        <div class="mb-2 flex items-center">
            <span class="font-weight-bold text-uppercase text-large" style="font-size: 14px">
                {{ 'communications.info.switch_student' | translate }}
            </span>
            <i class="ph-info-bold interactive mx-2" style="font-size: 1.4em"></i>
        </div>

        <ng-container *ngIf="!isAssignedUsersLoading; else loadingBlock">
            <ng-container *ngFor="let user of assignedUsers">
                <div class="user-info-row flex gap-x-2 p-2" (click)="addUserToTeam(user)">
                    <div class="w-7">
                        <img
                            class="friend-img"
                            [src]="user.main_image ? storageUrl + user.main_image : defaultImageUrl"
                        />
                    </div>
                    <div class="flex flex-col">
                        <span class="name text-start text-sm">{{ user.nickname }} </span>
                        <span class="role text-nowrap text-xs text-[#8e91d1]">
                            <span *ngIf="user.personal_type?.types">
                                {{ user.personal_type?.types }}
                            </span>
                            <span *ngIf="user.social_type?.types && user.personal_type?.types">, </span>
                            <span *ngIf="user.social_type?.types">{{ user.social_type?.types }}</span>
                        </span>
                    </div>
                    <div class="ms-auto">
                        <div class="user-color-stick" [style.backgroundColor]="user.color"></div>
                    </div>
                </div> </ng-container
        ></ng-container>

        <ng-template #loadingBlock>
            <div class="flex h-64 items-center justify-center">
                <mat-spinner diameter="36"></mat-spinner>
            </div>
        </ng-template>

        <div class="my-2 flex justify-center">
            <app-pagination
                [totalItems]="otherStudentsTotal"
                [pageSize]="8"
                (pageChange)="otherStudentsPageChange($event)"
            ></app-pagination>
        </div>
    </div>
</ng-template>
