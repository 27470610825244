<div class="flex">
    <div class="col-auto ml-auto">
        <button class="btn btn-danger ml-auto" [routerLink]="['/studio']" style="background-color: #ff02d0">
            <i class="fas fa-long-arrow-alt-left"></i>{{ 'games.back' | translate }}
        </button>
    </div>
</div>

<div class="webgl-content">
    <div id="unityContainer" style="width: 1280px; height: 720px"></div>
    <div class="footer">
        <div class="webgl-logo"></div>
        <div class="fullscreen" (click)="unityInstance.SetFullscreen(1)"></div>
        <div class="title">Dungeon Warrior</div>
    </div>
</div>
