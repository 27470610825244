import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';
import { InviteService } from 'src/app/services/invite.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { InvitationsService } from 'src/app/services/invitations.service';

@Component({
    selector: 'app-send-invite',
    templateUrl: './send-invite.component.html',
    styles: [':host { display: block; }'],
})
export class SendInviteComponent implements OnInit {
    @Input() invitation;

    isLoading: boolean = false;
    isSent: boolean = false;

    constructor(
        private inviteService: InviteService,
        private invitationsService: InvitationsService,
        private notificationService: NotificationService,
        private translate: TranslateService,
        public dialog: MatDialog,
    ) {}

    ngOnInit() {
        this.isSent = this.invitation?.active;
    }

    send() {
        if (!this.isSent) {
            this.isLoading = true;
            setTimeout(() => {
                this.sendInvite();
            }, 400);

            return;
        }

        this.dialog
            .open(ConfirmDialogComponent, {
                data: { title: this.translate.instant('administration.confirm_send_invite') },
                width: '450px',
            })
            .afterClosed()
            .subscribe((isSendAgain: boolean) => {
                if (isSendAgain) {
                    this.isLoading = true;
                    this.sendInvite();
                }
            });
    }

    sendInvite() {
        this.invitationsService
            .sendInviteById(this.invitation.id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.isSent = true;
                    this.notificationService.success('Invitation sent successfully');
                },
                error: (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                },
            });
    }
}
