import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { settings } from 'src/environments/environment';

@Component({
    selector: 'app-checkbox-array-input',
    template: `
        <div>
            <label class="block text-sm font-medium dark:font-normal text-gray-500 dark:text-gray-400">
                {{ label }}
                <span
                    *ngIf="isRequired"
                    class="ml-0.5"
                    [ngClass]="{ 'text-red-500 dark:text-rose-500': control.errors?.['required'] }"
                >
                    *
                </span>
            </label>
            <div class="flex flex-wrap gap-x-5">
                <ng-container *ngFor="let option of options; let i = index">
                    <div class="mt-0.5 flex items-center">
                        <input
                            [id]="selector + i"
                            type="checkbox"
                            [value]="option[optionValueSelector]"
                            [checked]="isChecked(option[optionValueSelector])"
                            (change)="onCheckboxChange($event, $event.target.value, $event.target.checked)"
                            [disabled]="isDisabled"
                            class="w-5 h-5 text-sky-500 dark:text-sky-500 bg-transparent border-gray-300 dark:border-gray-600 rounded-sm focus:ring-sky-500 dark:focus:ring-sky-500 dark:ring-offset-sky-900 transition duration-300"
                        />
                        <label
                            [attr.for]="selector + i"
                            class="ml-2 py-2 text-sm select-none"
                            [tooltip]="getToolip(option[optionTextSelector])"
                            [options]="tooltipOptions"
                        >
                            {{ option[optionTextSelector] | truncate: charactersLimit }}
                        </label>
                    </div>
                </ng-container>
            </div>

            <div
                *ngIf="(control.dirty || control.touched) && control.errors?.['required']"
                class="text-xs text-red-500 dark:font-semibold dark:text-rose-500"
            >
                Please fill in
            </div>
        </div>
    `,
    styles: [':host { display: block; }'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxArrayInputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CheckboxArrayInputComponent),
            multi: true,
        },
    ],
})
export class CheckboxArrayInputComponent implements ControlValueAccessor, AfterViewInit {
    @Input() label: string = '';
    @Input() options: { id: any; name: string }[] = [];
    @Input() optionValueSelector: string = 'value';
    @Input() optionTextSelector: string = 'name';
    @Input() selector: string = this.generateUniqueId();

    selectedValues: any[] = [];

    private onChange: Function | null = null;
    public onTouched: Function | null = null;

    @Input() isDisabled: boolean = false;
    @Input() limit: number = 0;

    charactersLimit: number = 50;

    tooltipOptions = settings.tooltipOptions;

    control: any;
    @ViewChild('input', { static: false, read: NgControl }) input;

    ngAfterViewInit() {
        this.validate(null);
    }

    generateUniqueId(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!this.control) this.control = control;
        if (this.control && this.input) this.input.control.setValidators(this.control.validator);
        return null;
    }

    isChecked(value: any) {
        return this.selectedValues.includes(value.toString());
    }

    onCheckboxChange(event, value: any, checked: boolean) {
        if (this.limit && this.selectedValues.length >= this.limit && checked) {
            // Limit reached, uncheck the checkbox
            event.target.checked = false;
            return;
        }

        if (checked) {
            this.selectedValues.push(value);
        } else {
            this.selectedValues = this.selectedValues.filter((itemValue) => value !== itemValue);
        }

        if (this.onChange) {
            this.onChange(this.selectedValues);
        }
    }

    writeValue(value: any) {
        if (value !== undefined && value !== null) {
            this.selectedValues = value.map((value) => value.toString());
        }
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = () => {
            fn();
        };
    }

    setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }

    get isRequired() {
        return this.control?.hasValidator(Validators.required);
    }

    get isTouched() {
        return this.control.touched;
    }

    getToolip(name: string): string {
        return name.length > this.charactersLimit ? name : '';
    }
}
