<div>
    <div class="py-3 px-4 flex" style="flex-direction: column; font-size: 0.85em; gap: 12px">
        <div
            class="flex active-hover"
            style="gap: 8px"
            [class.enabled-mode]="mode === 'request'"
            (click)="setMode('request')"
        >
            <span>
                <img src="/assets/friends-request.png" alt="" style="width: 20px; height: 12px" />
            </span>
            <span>{{ 'friend_bar.friends_request' | translate }}</span>
        </div>

        <div
            class="flex active-hover"
            style="gap: 8px"
            [class.enabled-mode]="mode === 'find'"
            (click)="setMode('find')"
        >
            <span>
                <img src="/assets/find-request.png" style="width: 20px; height: 12px" />
            </span>
            <span>{{ 'friend_bar.find_friends' | translate }}</span>
        </div>

        <div>
            <span>
                <div class="search-wrap">
                    <i class="fas fa-search" style="color: #fff"></i>
                    <div style="display: inline-flex">
                        <input
                            matInput
                            #searchbox
                            autofocus
                            class="friends-search"
                            type="text"
                            placeholder="{{ 'friend_bar.search_friends' | translate }}"
                            (keyup)="submitByKey()"
                            autocomplete="off"
                            [(ngModel)]="search"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </div>
                </div>
            </span>

            <div *ngIf="mode === 'find'" class="w-full my-2 justify-end flex">
                <button matRipple class="cancel-btn mx-2" (click)="setMode('list')">
                    {{ 'friend_bar.cancel' | translate }}
                </button>
                <app-un-magic-button
                    class=""
                    [text]="'friend_bar.find' | translate"
                    [fullWidth]="false"
                    [bordered]="true"
                    (click)="findFriend()"
                ></app-un-magic-button>
            </div>
        </div>
    </div>

    <hr class="m-0" />
</div>
