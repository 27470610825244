import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-user-avatar-selector',
    templateUrl: './user-avatar-selector.component.html',
    styleUrls: ['./user-avatar-selector.component.scss'],
    providers: [],
})
export class UserAvatarSelectorComponent implements OnInit {
    isLoading: boolean = false;

    user: any;
    imagePath: string = '/assets/uni-love.png';

    storageUrl: string = environment.storageUrl;
    imgURL: any;
    message: string;
    imageUploadHelper: any;

    constructor(
        private userService: UserService,
        private sessionService: SessionService,
        private notificationService: NotificationService
    ) {}

    ngOnInit() {
        this.getUser();
    }

    getUser() {
        this.user = this.sessionService.getCurrentUser();
        this.imgURL = this.user.main_image ? this.storageUrl + '/' + this.user.main_image : '';
    }

    preview(files: any) {
        if (files.length === 0) {
            return;
        }

        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            this.message = 'Only images are supported.';
            return;
        }

        const reader = new FileReader();
        this.imagePath = files;
        this.imageUploadHelper = files[0];
        reader.readAsDataURL(files[0]);

        reader.onload = () => {
            this.imgURL = reader.result;
        };

        this.saveProfileImage();
    }

    saveProfileImage() {
        if (!this.imageUploadHelper) {
            return;
        }

        const data = new FormData();
        data.append('main_image', this.imageUploadHelper, this.imageUploadHelper.name);

        this.userService
            .updateUser(this.user.id, data)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success('Avatar updated successfully');
                    this.sessionService.setCurrentUser(response.data);
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }
}
