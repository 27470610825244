<ng-container *ngIf="!isLoading">
    <div [@flyInOut] class="mb-4">
        <app-un-page-sub-panel [title]="project?.name" [backUrl]="'/studio'" [onlyBack]="true"></app-un-page-sub-panel>
    </div>

    <div class="game-details grid grid-cols-12 gap-6">
        <div class="col-span-12 xl:col-span-8">
            <img class="game-image-screen" [src]="project?.image_path" alt="" />
            <div class="mt-4 text-start">
                <span class="text-lg leading-tight text-[#EDF2FF]">{{ project.description }}</span>
            </div>
        </div>

        <div [@flyRigthIn] class="col-span-12 flex flex-col xl:col-span-4">
            <a *ngIf="project.link && !project.outerLink" [href]="project.link" class="no-underline hover:no-underline">
                <button matRipple class="play-game-button text-uppercase font-weight-bold mb-3 w-full">
                    {{ 'studio.download_plugin' | translate }}
                </button>
            </a>

            <a
                *ngIf="project.outerLink"
                (click)="openLinkInNewTab(project.outerLink)"
                class="no-underline hover:no-underline"
            >
                <button matRipple class="play-game-button text-uppercase font-weight-bold mb-3 w-full">
                    {{ 'studio.open_page_new_tab' | translate }}

                    <i class="ph-bold ph-arrow-square-out text-lg font-semibold"></i>
                </button>
            </a>

            <div class="game-details-row flex justify-between py-2">
                <div><span>Platform</span></div>
                <div>
                    <span><i class="ph-google-chrome-logo"></i></span>
                </div>
            </div>
            <div class="game-details-row flex justify-between py-2">
                <div><span>Developer</span></div>
                <div>
                    <span>{{ project.author }}</span>
                </div>
            </div>
            <div class="game-details-row mb-4 flex justify-between py-2">
                <div><span>Size</span></div>
                <div><span>1.34 MB</span></div>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="isLoading" class="flex items-center justify-center" style="height: 440px">
    <app-un-game-loader></app-un-game-loader>
</div>
