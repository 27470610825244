<div
    class="flex items-center justify-between flex-wrap gap-2"
    [ngClass]="{ 'opacity-75 pointer-events-none': disabled }"
>
    <div
        *ngIf="selectedOption"
        class="status-select-button flex items-center gap-x-1 px-1 py-1 rounded-3xl cursor-pointer whitespace-nowrap"
        [ngClass]="{ '!border-dashed bg-transparent': disabled }"
        [matMenuTriggerFor]="menu"
    >
        <div class="flex items-center gap-x-2">
            <app-un-avatar
                [name]="selectedOption.name"
                [color]="selectedOption.icon_color.class"
                [icon]="selectedOption.icon_class.class"
                [image]="selectedOption.image_path"
                [select]="true"
            ></app-un-avatar>
            <span>{{ selectedOption.name }}</span>
        </div>
        <i class="ph-caret-down-bold mx-1"></i>
    </div>
</div>

<mat-menu #menu="matMenu" backdropClass="customize" style="top: -58px">
    <ng-container *ngFor="let option of options">
        <div mat-menu-item (click)="select(option)">
            <div class="flex items-center gap-2">
                <app-un-avatar
                    [name]="option.name"
                    [color]="option.icon_color.class"
                    [icon]="option.icon_class.class"
                    [image]="option.image_path"
                ></app-un-avatar>
                <span class="whitespace-break-spaces" style="white-space: break-spaces">{{ option.name }}</span>
            </div>
        </div>
    </ng-container>
</mat-menu>
