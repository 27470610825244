import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class VotesTopicsService {
    public API: string = '/voting-topics';

    constructor(private http: HttpClient) {}

    getAll() {
        return this.http.get(new Url(this.API).path);
    }

    getStudentsTopics() {
        return this.http.get(new Url(this.API + '/basic').path);
    }

    getById(id: number) {
        return this.http.get(new Url(this.API + '/' + id).path);
    }

    create(data: any) {
        return this.http.post(environment.apiUrl + this.API, data);
    }

    update(id: number, data: any) {
        return this.http.put(environment.apiUrl + this.API + '/' + id, data);
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + this.API + '/' + id);
    }

    getTasksStats() {
        return this.http.get(environment.apiUrl + this.API + '/stats');
    }

    getResults() {
        return this.http.get(environment.apiUrl + '/voting/result');
    }

    getVoteStatus() {
        return this.http.get(environment.apiUrl + '/get-user-vote-status');
    }

    voteByTheme(data: any) {
        return this.http.post(environment.apiUrl + '/voting', data);
    }

    startVotes(data: any) {
        return this.http.put(environment.apiUrl + '/vote/start', data);
    }
}
