import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';
import { TeamsService } from 'src/app/services/team.service';

@Component({
    selector: 'app-no-data',
    templateUrl: './no-data.component.html',
    styleUrls: ['./no-data.component.scss'],
})
export class NoDataComponent {
    isLoading: boolean = false;

    @Input() message: string = 'no data';
    @Input() actionMessage: string = 'back';

    @Output() actionActivated: EventEmitter<any> = new EventEmitter();

    constructor() {}

    action() {
        this.isLoading = true;
        setTimeout(() => {
            this.actionActivated.emit();
        }, 800);
    }
}
