import { Component, Input } from '@angular/core';
import { TabsComponent } from '../tabs.component';

@Component({
    selector: 'app-tab',
    template: `
        <ng-container *ngIf="tabs.activeTab === this">
            <ng-content></ng-content>
        </ng-container>
    `,
})
export class TabComponent {
    @Input() title: string;
    @Input() defaultTab: boolean;

    constructor(public tabs: TabsComponent) {}
}
