import { Component, OnInit } from '@angular/core';
import { ChatsService } from 'src/app/services/chat.service';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-chat-users',
  templateUrl: './chat-users.component.html',
  styleUrls: ['./chat-users.component.scss']
})
export class ChatUsersComponent implements OnInit {

  displayChat: boolean = false;
  canLoadChat: boolean = false;

  CHAT_ORIGIN: string = environment.chatUrl;
  CHAT_URL: string = '';
  CHAT_DIRECT: string = 'direct/';
  chatName: string = 'General';
  chats: any[] = [];
  storageUrl: string = environment.storageUrl;
  user: any;

  selectedChat: any;



  constructor(
    public session: SessionService,
    private chatsService: ChatsService
  ) { }



  ngOnInit() {
    this.user = this.session.getCurrentUser();
    this.fetch();
  }

  fetch() {

    this.chatsService.get().subscribe((response: any) => {
      this.chats = response.data.channel.concat(response.data.direct || []) || [];
    });

  }


  toggle() {
    this.displayChat = !this.displayChat;
    this.canLoadChat = true;
  }

  close() {
    this.displayChat = false;
  }

  selectChat(chat: any) {
    this.selectedChat = chat;
    this.chatsService.chatSelect(chat);
  }

  userSelect(nickname: string) {
    const chat = {
      name: nickname,
      url: this.CHAT_DIRECT + nickname
    }
    this.selectChat(chat);
  }

  setChatName(chatName: string): void {
    this.chatName = chatName;
  }

}
