import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class DriveService {
    private folderContentChangedSource = new Subject<void>();
    folderContentChanged$ = this.folderContentChangedSource.asObservable();

    constructor(private http: HttpClient) {}

    getAll() {
        return this.http.get(new Url('/drive').path);
    }

    getByTaskId(taskId: number) {
        const url = new Url('/drive');

        url.addQueryParameter('team_task_id', taskId);

        return this.http.get(url.path);
    }

    getByTeamkId(teamId: number) {
        const url = new Url('/drive');

        url.addQueryParameter('team_id', teamId);

        return this.http.get(url.path);
    }

    getCurrentTeamkDrive() {
        const url = new Url('/drive/team');
        return this.http.get(url.path);
    }

    getBySchool() {
        return this.http.get(new Url('/drive').path);
    }

    addDocumentByTask(taskId: number, data: any) {
        return this.http.post(new Url('/drive/team-task/' + taskId).path, data);
    }

    addDocumentByTeam(teamId: number, data: any) {
        return this.http.post(new Url('/drive/team-task/team/' + teamId).path, data);
    }

    deleteDocument(documentId: number) {
        return this.http.delete(environment.apiUrl + '/drive/remove-document/' + documentId);
    }

    setFavorite(id: number, favorite: boolean) {
        const formData = new FormData();
        formData.append('favorite', favorite.toString());
        return this.http.put(new Url('/team-tasks-documents/' + id).path, formData);
    }

    getAllowedExtensions(): string[] {
        return [
            'pdf',
            'pptx',
            'ppt',
            'txt',
            'doc',
            'docx',
            'png',
            'jpg',
            'mp3',
            'ogg',
            'wav',
            'webm',
            'mp4',
            'mkv',
            'flv',
            '3gp',
        ];
    }

    folderContentChangedEmit() {
        this.folderContentChangedSource.next();
    }

    downloadFinalVersion() {
        const url = new Url('/drive/final-version');
        return this.http.get(url.path);
    }

    downloadFolder(teamId: number, teamTaskId?: number, finalVersion?: boolean) {
        const url = new Url('/drive/download-folder');

        url.addQueryParameter('team_id', teamId);
        url.addQueryParameter('team_task_id', teamTaskId);
        url.addQueryParameter('final_version', finalVersion);

        return this.http.get(url.path);
    }
}
