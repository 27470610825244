<div class="notifications fixed bottom-3 right-3 pointer-events-none z-[1001] space-y-2">
    <ng-container *ngFor="let notification of notifications">
        <div
            class="notification cursor-pointer text-lg text-white font-light p-3 max-w-md w-auto rounded relative overflow-hidden pointer-events-auto shadow transition-all duration-500 ease-out"
            (click)="close(notification)"
            [class.fadeInBottom]="!notification.lastBreath"
            [class.no-transform]="notifications.length > 1"
            [class.fadeOutTop]="notification.lastBreath"
            [ngClass]="getClassName(notification.type)"
        >
            <ng-container *ngTemplateOutlet="notificationTpl; context: { notification: notification }"></ng-container>
        </div>
    </ng-container>
</div>

<ng-template #notificationTpl let-notification="notification">
    <div class="font-bold">
        <div>{{ notification.title }}</div>
    </div>

    <div class="overflow-y-auto">{{ notification.message }}</div>
</ng-template>
