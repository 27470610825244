import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * filter definition
 */
export class DataFilter {
    /**
     * url key
     */
    key: string;

    label: string;

    class?: string;

    /**
     * values: '' (default) | 'checkbox'
     */
    type?: string;

    /**
     * holds current value
     */
    selected?: any;

    visible?: boolean;

    /**
     * possible values
     */
    options?: any[];

    optionTextSelector?: string;
    optionValueSelector?: string;
    optionGroupSelector?: string;

    optionEmpty?: boolean;
    optionEmptyText?: string;
    optionEmptyValue?: any;
}

@Component({
    selector: 'app-table-filters',
    templateUrl: './table-filters.component.html',
    styleUrls: ['./table-filters.component.css'],
    styles: [':host { display: block; }'],
})
export class TableFiltersComponent {
    @Input() filters: DataFilter[] = [];
    @Input() hideEmptyFilter?: boolean;

    @Output() filtersChanged = new EventEmitter<DataFilter[]>();

    isFilterVisible(filter: DataFilter) {
        if ('visible' in filter) {
            return filter.visible;
        }
        return true;
    }

    filtersChangedEmit(): void {
        this.filtersChanged.emit(this.filters);
    }
}
