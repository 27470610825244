import { AfterViewInit, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Tools } from 'src/app/tools';

declare var UnityLoader: any;


@Component({
  selector: 'app-warrior',
  templateUrl: './warrior.component.html',
  styleUrls: ['./warrior.component.scss'],
})
export class WarriorComponent implements AfterViewInit, OnDestroy {

  GAME_SCRIPT_PATH: string = '/assets/demo/Build/UnityLoader.js';

  unityInstance: any;

  constructor(private router: Router) { }


  ngAfterViewInit() {
    Tools.loadScripts(this.GAME_SCRIPT_PATH).subscribe((result: any) => {
      this.unityInstance = UnityLoader.instantiate('unityContainer', 'assets/demo/Build/build.json');
    }, (error) => {
      console.log(error);
    });
  }

  ngOnDestroy() {
    this.unityInstance.Quit();
    location.reload();
  }

}