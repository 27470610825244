<app-sign-up-wrapper>
    <div class="login">
        <div class="form">
            <form class="log-in" autocomplete="off" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                <h2>
                    <app-logo></app-logo>
                </h2>

                <div class="block text-sm font-medium text-gray-900 dark:text-white mx-10">Your email</div>
                <div class="form-field mx-10 mb-7">
                    <label for="login-mail mb-0"><i class="fa fa-user"></i></label>
                    <input
                        id="login-mail"
                        formControlName="email"
                        type="text"
                        name="mail"
                        placeholder="E-Mail"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    />
                </div>

                <div class="block text-sm font-medium text-gray-900 dark:text-white mx-10">
                    Create a strong password
                </div>

                <div class="form-field mx-10 mb-2">
                    <label for="login-password mb-0"><i class="fa fa-lock"></i></label>
                    <input
                        formControlName="password"
                        type="{{ showPassword ? 'text' : 'password' }}"
                        name="password"
                        placeholder="Password"
                        pattern=".{8,}"
                        required
                    />
                    <i class="material-icons text-white cursor-pointer" (click)="togglePasswordVisibility()">
                        {{ showPassword ? 'visibility_off' : 'visibility' }}
                    </i>
                </div>

                <span class="flex text-xs font-medium text-gray-400 mx-10 mb-7">
                    *minimum password length 8 characters
                </span>

                <div class="block text-sm font-medium text-gray-900 dark:text-white mx-10">Confirm password</div>

                <div class="form-field mx-10 mb-2">
                    <label for="login-password mb-0"><i class="fa fa-lock"></i></label>
                    <input
                        formControlName="confirmPassword"
                        type="{{ showConfirmPassword ? 'text' : 'password' }}"
                        name="Confirm password"
                        placeholder="Confirm password"
                        pattern=".{8,}"
                        required
                    />
                    <i class="material-icons text-white cursor-pointer" (click)="toggleConfirmPasswordVisibility()">
                        {{ showConfirmPassword ? 'visibility_off' : 'visibility' }}
                    </i>
                </div>

                <div
                    *ngIf="
                        formGroup.get('confirmPassword')?.invalid &&
                        (formGroup.get('confirmPassword')?.dirty || formGroup.get('confirmPassword')?.touched)
                    "
                    class="mx-10 text-red-400 text-xs"
                >
                    <div *ngIf="formGroup.get('confirmPassword')?.errors?.required">Please confirm your password.</div>
                    <div *ngIf="formGroup.get('confirmPassword')?.errors?.notMatching">Passwords do not match.</div>
                </div>

                <div class="submit-wrap mt-7">
                    <app-un-magic-button
                        style="width: 100%"
                        (click)="onSubmit()"
                        [text]="'SIGN UP'"
                        [fullWidth]="true"
                        [disabled]="!formGroup.valid"
                        [bordered]="true"
                        [fullWidth]="true"
                    ></app-un-magic-button>
                </div>
            </form>
        </div>
    </div>
</app-sign-up-wrapper>
