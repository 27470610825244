<div class="p-4 overall-card">
    <div class="st-title mb-4">
        <h3>{{ 'communications.section.project_drive' | translate }}</h3>
        <span>{{ 'communications.section.all_documentation' | translate }}</span>
    </div>

    <div class="mb-4 col-4 mx-auto">
        <app-index-radial [inputIndex]="78" type="overal"></app-index-radial>
    </div>

    <div class="st-title mb-4">
        <h3>{{ 'communications.section.recent_updates' | translate }}</h3>
        <span *ngIf="updates.length > 0">{{ formatDate(updates[updates.length - 1].date) }}</span>
    </div>

    <div class="mb-3">
        <ng-container *ngFor="let update of updates.slice(-3)">
            <div class="grid-container items-center">
                <div class="grid-item"><img class="update-icon" [src]="getTypeIcon(update.type)" alt="" /></div>

                <!-- <div class="grid-item" style="font-weight: 400;" (click)="openDocument(docViewModal, update.path)"> -->
                <div class="grid-item" style="font-weight: 400">
                    <div>{{ update.name }}</div>
                </div>

                <div class="grid-item" style="font-weight: 400">
                    <span style="font-size: 0.7em; opacity: 0.7">{{ update.date | date : 'dd.MM.yyyy' }}</span>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="flex mb-3 p-3" style="gap: 15px">
        <div class="w-full">
            <app-un-magic-button
                [text]="'+ New'"
                [fullWidth]="true"
                [bordered]="true"
                (click)="openDialog(uploadFormTemplate)"
            ></app-un-magic-button>
        </div>
        <div class="w-full">
            <app-un-magic-button [text]="'Open'" [fullWidth]="true" [bordered]="true"></app-un-magic-button>
        </div>
    </div>
</div>

<ng-template #uploadFormTemplate>
    <div mat-dialog-content style="width: 400px">
        <app-table-form
            #uploadModalForm
            [form]="uploadForm"
            [formElements]="uploadForm.form.elements"
            (onSubmit)="upload($event)"
        ></app-table-form>
    </div>
    <div mat-dialog-actions class="row justify-between">
        <div class="col-auto"></div>
        <div class="col-auto">
            <button mat-flat-button color="primary" class="mr-2" (click)="closeDialog()">
                {{ 'communications.info.cancel' | translate }}
            </button>
            <button mat-flat-button color="accent" (click)="upload(uploadModalForm.getValue())">
                {{ 'communications.info.submit' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #docViewModal>
    <div mat-dialog-content style="width: 400px">
        <ngx-doc-viewer [url]="doc" viewer="google" style="width: 100%; height: 50vh"> </ngx-doc-viewer>
    </div>
    <div mat-dialog-actions class="row justify-between">
        <div class="col-auto"></div>
        <div class="col-auto">
            <button mat-flat-button color="primary" class="mr-2" (click)="closeDialog()">
                {{ 'communications.info.cancel' | translate }}
            </button>
            <!-- <button mat-flat-button color="accent" (click)="upload(uploadModalForm.getValue())">Submit</button> -->
        </div>
    </div>
</ng-template>
