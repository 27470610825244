import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Url } from '../url';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

export interface ChatConfig {
    type: 'team' | 'task' | string;
    team_id: number;
    user: any;
    room_id?: string;
    team_task_id?: number;
    school_id?: number;
}

@Injectable({
    providedIn: 'root',
})
export class ChatsService {
    messages: any[] = [];
    chattingWith = new Subject<any>();

    private chatSelectSource = new Subject<any>();
    chatSelect$ = this.chatSelectSource.asObservable();

    constructor(
        private http: HttpClient,
        private socket: Socket,
    ) {}

    get() {
        return this.http.get(new Url('/chats').path);
    }

    getLastActivity() {
        return this.http.get(new Url('/chats/last-activity').path);
    }

    chatSelect(chat: any) {
        this.chatSelectSource.next(chat);
    }

    sendMessage(message: any) {
        this.socket.emit('message', message);
    }

    getMessage() {
        return this.socket.fromEvent('message').pipe(map((data: any) => data));
    }

    getUsers() {
        return this.socket.fromEvent('current_users').pipe(map((data: any) => data));
    }

    getSign() {
        return this.socket.fromEvent('sign_in').pipe(map((data: any) => data));
    }

    getRoomId(type: string, team_id: number, team_task_id?: number) {
        return type + '_' + (type === 'team' ? team_id : team_task_id);
    }

    getTeamChat() {
        return this.http.get(new Url('/teams-chat').path);
    }
}
