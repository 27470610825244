import { settings, environment } from 'src/environments/environment';
import { Component, OnInit, TemplateRef } from '@angular/core';
import * as moment from 'moment';
import { UploadProjectDocumentForm } from 'src/app/forms/UploadProjectDocumentForm';
import { DocumentsService } from 'src/app/services/documents.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from 'src/app/services/session.service';
import { finalize } from 'rxjs/operators';
import { Tools } from 'src/app/tools';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-team-project-drive',
    templateUrl: './team-project-drive.component.html',
    styleUrls: ['./team-project-drive.component.scss'],
    providers: [],
})
export class TeamProjectDriveComponent implements OnInit {
    isLoading: boolean = false;

    date: string = moment().format('DD MMMM YYYY');

    storageUrl: string = environment.storageUrl;

    updates: any[] = [];

    uploadForm = new UploadProjectDocumentForm();

    doc: string = '';

    constructor(
        private documentsService: DocumentsService,
        private notificationService: NotificationService,
        private dialog: MatDialog,
        public sessionService: SessionService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.uploadForm.buildForm();
        this.fetch();
    }

    fetch() {
        this.documentsService.getAll().subscribe((response: any) => {
            this.updates = response.data;
        });
    }

    openDialog(dialogRef: TemplateRef<any>) {
        this.dialog.open(dialogRef);
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    openDocument(dialogRef: TemplateRef<any>, path: string) {
        this.doc = this.storageUrl + path;
        this.openDialog(dialogRef);
    }

    create(data: any) {
        this.documentsService
            .create(Tools.getFormData(data))
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    // this.notificationService.success(this.ENTRY_NAME + ' created successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    delete(data: any) {
        this.documentsService
            .delete(data.id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    // this.notificationService.success(this.ENTRY_NAME + ' deleted successfully');
                    this.fetch();
                },
                (err: any) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    upload(data: any) {
        const formData = new FormData();
        formData.append('file', data.file, data.file.name);
        this.documentsService.create(formData).subscribe(
            (response: any) => {
                this.fetch();
                this.closeDialog();
                this.notificationService.success('Document created successfully');
            },
            (err) => {
                this.notificationService.error(err.error.reason, 'Error');
            }
        );
    }

    formatDate(date: string): string {
        return moment(date).format('MMM DD, YYYY');
    }

    getTypeIcon(type: string) {
        const types: any = {
            '.txt': '/assets/communications/team/updates/text.png',
            '.ppx': '/assets/communications/team/updates/presentation.png',
            '.csv': '/assets/communications/team/updates/table.png',
            default: '/assets/communications/team/updates/text.png',
        };
        return types[type] || types['default'];
    }
    get ERROR_TOASTR() {
        return this.translate.instant('communications.section.error_toastr');
    }

    get DOC_CREATED_SUCCESS_TOASTR() {
        return this.translate.instant('communications.section.doc_created_success_toastr');
    }
}
