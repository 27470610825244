<div [@flyInOut] class="mb-4">
    <app-un-page-sub-panel
        [title]="'studio.create_image' | translate"
        [backUrl]="'/studio'"
        [onlyBack]="true"
    ></app-un-page-sub-panel>
</div>

<div class="flex flex-col-reverse gap-6 lg:flex-row">
    <div class="mb-6 w-full xl:w-8/12">
        <app-tabs-image #childRef>
            <app-tab-image tabTitle="Dall-E" [selector]="AIServices.Dalle">
                <div
                    *ngIf="images[AIServices.Dalle].length === 0"
                    class="placeholder-image flex h-96 w-full flex-col items-center justify-center gap-4 rounded-lg"
                >
                    <i class="ph-bold ph-file-image text-5xl text-[#FF02D0]"></i>
                    <span class="text-center text-lg uppercase text-[#9D97C3]">
                        {{ 'studio.image_placeholder_text' | translate }}
                    </span>
                </div>

                <ng-container *ngIf="images[AIServices.Dalle].length > 0">
                    <div class="image-container relative col-span-4 h-full rounded-lg" (click)="selectImage('')">
                        <img
                            class="h-[32rem] w-full rounded-lg object-contain"
                            [src]="images[AIServices.Dalle][0]"
                            alt=""
                        />

                        <a
                            (click)="downloadImage($event, images[AIServices.Dalle][0], 'image')"
                            class="absolute right-1 top-1 no-underline hover:no-underline"
                            style="inset-inline-end: 8px"
                        >
                            <div
                                class="my-1 flex w-max items-center justify-center rounded-md bg-[#FF02D0] p-1 xl:mb-2"
                            >
                                <i class="ph-bold ph-download-simple text-base text-[#E1DEF5]"></i>
                            </div>
                        </a>
                    </div>
                </ng-container>
            </app-tab-image>
            <app-tab-image [tabTitle]="'Stable diffusion'" [selector]="AIServices.StableDiffusion">
                <div
                    *ngIf="images[AIServices.StableDiffusion].length === 0"
                    class="placeholder-image flex h-96 w-full flex-col items-center justify-center gap-4 rounded-lg"
                >
                    <i class="ph-bold ph-file-image text-5xl text-[#FF02D0]"></i>
                    <span class="text-center text-lg uppercase text-[#9D97C3]">
                        {{ 'studio.image_placeholder_text' | translate }}
                    </span>
                </div>
                <div
                    *ngIf="images[AIServices.StableDiffusion].length > 0 && !selectedImage"
                    class="grid h-96 grid-cols-4 gap-4 rounded-lg"
                >
                    <div
                        *ngFor="let image of images[AIServices.StableDiffusion]; let i = index"
                        class="image-container relative col-span-4 rounded-lg lg:col-span-2"
                        (click)="selectImage(image)"
                    >
                        <img class="w-full rounded-lg object-contain md:h-60 xl:h-96" [src]="image" alt="" />

                        <a
                            (click)="downloadImage($event, image, 'image')"
                            class="absolute right-1 top-1 no-underline hover:no-underline"
                            style="inset-inline-end: 8px"
                        >
                            <div
                                class="my-1 flex w-max items-center justify-center rounded-md bg-[#FF02D0] p-1 xl:mb-2"
                            >
                                <i class="ph-bold ph-download-simple text-base text-[#E1DEF5]"></i>
                            </div>
                        </a>
                    </div>
                </div>
                <ng-container *ngIf="selectedImage">
                    <div class="image-container relative col-span-4 h-full rounded-lg" (click)="selectImage('')">
                        <img class="h-[32rem] w-full rounded-lg object-contain" [src]="selectedImage" alt="" />

                        <a
                            (click)="downloadImage($event, selectedImage, 'image')"
                            class="absolute right-1 top-1 no-underline hover:no-underline"
                            style="inset-inline-end: 8px"
                        >
                            <div
                                class="my-1 flex w-max items-center justify-center rounded-md bg-[#FF02D0] p-1 xl:mb-2"
                            >
                                <i class="ph-bold ph-download-simple text-base text-[#E1DEF5]"></i>
                            </div>
                        </a>
                    </div>
                </ng-container>
            </app-tab-image>
        </app-tabs-image>
    </div>

    <div class="flex w-full flex-col gap-4 xl:w-4/12">
        <div class="flex items-center gap-x-4 text-start">
            <span class="text-lg text-[#EDF2FF]">{{ 'studio.what_you_want_to_create' | translate }}</span>
            <app-notificator></app-notificator>
        </div>
        <div class="image-prompt mb-3 min-h-[6rem] w-full rounded-lg p-3">
            <app-textarea
                class="w-full"
                [label]="'studio.promt_placeholder' | translate"
                [formControl]="promtFormControl"
                [isDisabled]="isLoading"
                [rows]="4"
                maxLength="320"
            >
            </app-textarea>

            <!-- [ngStyle]="{
                'background-image': 'url(' + '/assets/studio/image-styles/' + style.image + ')'
            }" -->

            <div *ngIf="selectedStyle" class="mt-4 w-full text-sm font-medium text-un-pink-500 dark:font-normal dark:text-gray-400">
                Style added in prompt:
                <div>{{ selectedStyle }}</div>
            </div>

            <div class="mt-4 w-full text-sm font-medium text-gray-500 dark:font-normal dark:text-gray-400">
                Visual style
                <span></span>
                <div class="mt-2 flex gap-3">
                    <div *ngFor="let style of styles" class="flex cursor-pointer flex-col items-center gap-3">
                        <div
                            (click)="setStyle(style)"
                            class="h-16 w-16 rounded-lg border-2 bg-gray-400 hover:border-[#FF02D0]"
                            [ngClass]="{ ' border-[#FF02D0]': selectedStyle === style.prompt }"
                        >
                            <img
                                [src]="'/assets/studio/image-styles/' + style.image"
                                class="box-border w-full"
                                alt=""
                            />
                        </div>
                        <span [ngClass]="{ 'text-[#FF02D0]': selectedStyle === style.prompt }"> {{ style.name }}</span>
                    </div>
                </div>
            </div>

            <div class="relative mt-4 w-full">
                <button
                    matRipple
                    class="text-uppercase font-weight-bold relative flex w-full items-center justify-center overflow-hidden rounded-lg bg-[#FF02D0] py-3 text-base tracking-widest disabled:pointer-events-none disabled:bg-[#626262]"
                    (click)="generate()"
                    [disabled]="!promtFormControl.valid || isLoading"
                >
                    <ng-container *ngIf="!isLoading"> {{ 'studio.create_image' | translate }} </ng-container>
                    <ng-container *ngIf="isLoading">
                        <div class="flex items-center gap-1">
                            <mat-spinner diameter="24"></mat-spinner>
                            <span *ngIf="timerRunning">about {{ remainingTime }} seconds left </span>
                            <span *ngIf="isLoading && !timerRunning">wait a bit more, pls</span>
                        </div>
                    </ng-container>
                </button>
                <div
                    *ngIf="timerRunning"
                    class="absolute top-0 h-full rounded-lg bg-gray-50/25"
                    [style.width.%]="100 - calculateWidthPercentage()"
                ></div>
            </div>
        </div>
    </div>
</div>
