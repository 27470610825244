<div class="flex">
    <div class="col-10">
        <div class="flex gray-card-bg squad p-4 pt-5" style="gap: 15px; flex-wrap: wrap">
            <div
                *ngFor="let team of teams"
                class="flex justify-center text-center flex-col team-wrap"
                (click)="selectTeam(team)"
            >
                <div class="team mb-1">
                    <img [src]="team.image_path" style="width: 100%; height: 100%; border-radius: 14px" />
                </div>
                <div class="text-left">
                    <span class="team-title">{{ team.name }}</span>
                </div>
                <span class="team-desc">{{ team.project.name }}</span>
            </div>

            <div class="see-all poppins-light" (click)="openModal(allTeamsModal)">
                {{ 'communications.section.see_all' | translate }} <i class="fas fa-long-arrow-alt-right"></i>
            </div>
        </div>
    </div>
</div>

<!-- <ng-template #addTeamModal>

    <div mat-dialog-content style="height: 44vh; min-height: 400px; min-width: 320px;">

        <div class="flex justify-center row">

            <mat-form-field class="w-full">
                <mat-label>Users</mat-label>
                <mat-select [formControl]="users">
                    <mat-option *ngFor="let user of usersList" [value]="user">{{ user }}</mat-option>
                </mat-select>
            </mat-form-field>

        </div>

    </div>

    <div mat-dialog-actions class="row justify-between">
        <div class="col-auto"></div>
        <div class="col-auto">
            <button class="btn btn-primary mr-2" (click)="closeDialog()">Cancel</button>
            <button class="btn btn-success" type="submit" [disabled]="!users.value" (click)="addTeammate()">Submit</button>
        </div>
    </div>

</ng-template> -->

<ng-template #allTeamsModal>
    <div mat-dialog-content style="height: 56vh; min-height: 400px">
        <ng-container *ngFor="let team of teams">
            <div class="grid-container items-center">
                <div class="grid-item position-relative">
                    <div class="position-relative" style="width: 85px; height: 85px">
                        <img class="team-img" [src]="team?.image_path" />
                    </div>
                </div>

                <div class="grid-item font-weight-400" style="font-size: 1em">
                    <div class="cursor-pointer">{{ team.name }}</div>
                    <span style="font-size: 0.9em; opacity: 0.7">{{ team.desc }}</span>
                </div>

                <div class="grid-item msg font-weight-400 cursor-pointer">
                    <span><i class="fas fa-share-square"></i></span>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
