import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { InviteService } from 'src/app/services/invite.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
    userRole: string = '';
    email: string = '';

    isLoading: boolean = false;
    hasError: boolean = false;
    htmlTag: any;
    version = '';
    returnUrl: string = '';

    constructor(
        private notificationService: NotificationService,
        private loginService: LoginService,
        private inviteService: InviteService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        if (this.loginService.isLoggedIn()) {
            this.router.navigateByUrl('/home');
        } else {
            this.htmlTag = document.getElementsByTagName('html')[0];
            this.htmlTag.classList.add('login');
            this.version = environment.version;
            const code = this.route.snapshot.queryParams['invite_code'] || '';

            this.checkCode(code);
        }
    }

    checkCode(code: string) {
        const formData = new FormData();
        formData.append('code', code);

        this.inviteService.check(formData).subscribe(
            (response: any) => {
                this.email = response.data.email;
                this.userRole = response.data.role;
            },
            () => {
                this.notificationService.error('Invalid invite code', 'Error');
                this.router.navigateByUrl('/forbidden');
            }
        );
    }
}
