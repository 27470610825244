import { environment } from 'src/environments/environment';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { Router } from '@angular/router';
import { VotesTopicsService } from 'src/app/services/votes-topics.service';

@Component({
    selector: 'app-submit-selected-theme',
    templateUrl: './submit-selected-theme.component.html',
    styleUrls: ['./submit-selected-theme.component.scss'],
    providers: [],
})
export class SubmitSelectedThemeComponent implements OnInit {
    isLoading: boolean = false;

    @Input() user: any;

    @Output() reselectTheme: EventEmitter<any> = new EventEmitter<any>();

    name: string = `Welcome to UnispherTM Platform!
                  Thank you for registering! We'll have a great time!`;
    description: string = '';
    imagePath: string = '/assets/uni-love.png';

    storageUrl: string = environment.storageUrl;

    theme: any;

    isVoteCompleted: boolean = false;

    constructor(
        private router: Router,
        private sessionService: SessionService,
        private votesService: VotesTopicsService
    ) {}

    ngOnInit() {
        this.votesService.getVoteStatus().subscribe((response: any) => {
            this.isVoteCompleted = response.data?.vote_status === 'completed';
            this.theme = response.data.user_voting_topic;
        });
    }

    finish() {
        this.sessionService.setQuestionnaire(true);
        this.router.navigateByUrl('/home');
    }

    selectAnotherTheme() {
        this.reselectTheme.emit();
    }
}
