import * as Highcharts from 'highcharts';

import HC_networkgraph from 'highcharts/modules/networkgraph';
HC_networkgraph(Highcharts);

import { settings } from 'src/environments/environment';

export class Chart {
    highcharts: any = Highcharts;

    options: any = {
        credits: {
            enabled: false,
        },
        title: {
            text: null,
        },
        accessibility: {
            enabled: false,
        },
    };

    constructor() {
        Highcharts.setOptions(settings.highcharts.global);
        Highcharts.setOptions(settings.highcharts.colors['dark'].theme);
        Highcharts.setOptions(settings.highcharts.print);
        Highcharts.setOptions(settings.highcharts.timeZone);
    }

    yAxis(count: number, options: any = null) {
        const yAxis = [];

        options = options || {
            title: {
                text: null,
            },
            labels: {
                enabled: false,
            },
        };

        for (let i = 0; i < count; i++) {
            yAxis.push(Object.assign({}, options));
        }

        return yAxis;
    }
}
