import { Component, Input, OnInit } from '@angular/core';
import { animateOpacity } from 'src/app/animations/fly-animations';
import { ChatConfig, ChatsService } from 'src/app/services/chat.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-team-chat',
    templateUrl: './team-chat.component.html',
    styleUrls: ['./team-chat.component.scss'],
    animations: [animateOpacity],
})
export class TeamChatComponent implements OnInit {
    @Input() team: number;

    roomType: string = 'team';
    config: ChatConfig;

    constructor(
        private session: SessionService,
        private chatsService: ChatsService,
    ) {}

    ngOnInit() {
        this.config = {
            type: this.roomType,
            user: this.session.getCurrentUser(),
            team_id: this.team,
            room_id: this.chatsService.getRoomId(this.roomType, this.team),
            school_id: this.session.getCurrentUserSchool()?.id,
        };
    }
}
