<div class="mb-2">
    <div class="flex items-center mb-2">
        <div class="flex-fill">
            <app-un-gradient-text [text]="'games.games_students' | translate" size="big"> </app-un-gradient-text>
        </div>

        <div class="col-auto ml-auto mb-3">
            <app-games-slider></app-games-slider>
        </div>
    </div>

    <div class="mb-5">
        <app-students-games></app-students-games>
    </div>

    <div class="mt-5">
        <div class="flex justify-start mb-3">
            <span class="tab-name">{{ 'games.mini_games' | translate }}</span>
        </div>

        <app-mini-games></app-mini-games>
    </div>

    <div class="mt-5">
        <div class="flex justify-start mb-3">
            <span class="tab-name">{{ 'games.institutions' | translate }}</span>
        </div>

        <app-institutions></app-institutions>
    </div>

    <div class="mt-5">
        <div class="flex justify-start mb-3">
            <span class="tab-name">{{ 'games.disciplines' | translate }}</span>
        </div>

        <app-disciplines></app-disciplines>
    </div>

    <div class="mt-5">
        <div class="flex justify-start mb-3">
            <span class="tab-name">{{ 'games.locations' | translate }}</span>
        </div>

        <app-locations></app-locations>
    </div>
</div>
