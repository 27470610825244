import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-un-info-card',
  templateUrl: './un-info-card.component.html',
  styleUrls: ['./un-info-card.component.scss']
})
export class UnInfoCardComponent {

  @Input() card: any;
  @Input() blur: boolean = false;
  @Input() game: boolean = false;
  @Input() info: boolean = false;
  @Input() selected: boolean = false;


  storageUrl: string = environment.storageUrl;



  constructor() { }


}
