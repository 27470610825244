<div *ngIf="!isLoading" class="generate-wrapper">

    <img class="mb-2" src="/assets/uni-1.png" alt="" style="max-width: 100px;">

    <div class="generate-wrapper-text mb-3">
        <span>{{ message }}</span>
        <!-- <span>To generate team, click button below:</span> -->
    </div>

    <app-un-save-button class="mx-auto" [text]="actionMessage" (click)="action()"></app-un-save-button>

</div>

<ng-container *ngIf="isLoading">
    <app-un-game-loader></app-un-game-loader>

    <div class="generate-wrapper-text mt-5">
        <span>please wait</span>
    </div>
</ng-container>