import { Chart } from './Chart';

interface ChartData {
    categories: string[];
    data: {
        y: number;
        color: string;
        drilldown: {
            categories: string[];
            data: number[];
        };
    }[];
}

export class StudentStatisticChart extends Chart {
    inited = false;
    updateFlag = false;

    update() {
        this.updateFlag = true;
    }

    build(chartData: ChartData) {
        const { categories, data } = chartData;
        const browserData = [];
        const versionsData = [];

        for (let i = 0; i < data.length; i += 1) {
            const browser = data[i];
            browserData.push({
                name: categories[i],
                y: browser.y,
                color: browser.color,
            });

            const drilldown = browser.drilldown;
            for (let j = 0; j < drilldown.data.length; j += 1) {
                versionsData.push({
                    name: drilldown.categories[j],
                    y: drilldown.data[j],
                });
            }
        }

        this.options = {
            ...this.options,
            colors: ['#6C22A6', '#6962AD', '#83C0C1', '#96E9C6', '#FF6384', '#FFCE56', '#4BC0C0', '#9966FF'],
            chart: {
                type: 'pie',
            },
            plotOptions: {
                pie: {
                    innerSize: '30%',
                    depth: 45,
                    borderColor: '#ffffff63',
                },
            },
            series: [
                {
                    name: categories[0],
                    data: browserData,
                    size: '45%',
                    dataLabels: {
                        color: '#ffffff',
                        distance: '-50%',
                    },
                },
                {
                    name: categories[1],
                    data: versionsData,
                    size: '80%',
                    innerSize: '60%',
                    dataLabels: {
                        format: '<b>{point.name}:</b> <span style="opacity: 0.5">{y}%</span>',
                        filter: {
                            property: 'y',
                            operator: '>',
                            value: 1,
                        },
                        style: {
                            fontWeight: 'normal',
                        },
                    },
                },
            ],
            xAxis: {
                type: 'category',
            },
            yAxis: {
                title: {
                    text: 'Count',
                },
            },
        };
    }
}
