import { settings, environment } from 'src/environments/environment';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { ProjectStepsTable } from 'src/app/tables/ProjectStepsTable';

@Component({
    selector: 'app-team-project-plan',
    templateUrl: './team-project-plan.component.html',
    styleUrls: ['./team-project-plan.component.scss'],
    providers: [],
})
export class TeamProjectPlanComponent implements OnInit {
    isLoading: boolean = false;

    viewModes: any[] = [
        { value: 'default-0', viewValue: 'Default view' },
        { value: 'pro', viewValue: 'Detailed view' },
    ];

    viewMode = this.viewModes[0].value;

    planSteps: any[] = [
        {
            id: 1,
            name: 'Scenario for Game',
            image: '/assets/rust.png',
            type: 'personal',
            materials: 'Materials',
            created: 'January 30, 2021 12:51 PM',
            status: 'done',
            users: [
                {
                    id: 2,
                    name: 'Sofi Heart',
                    image: '/assets/rust.png',
                },
                {
                    id: 2,
                    name: 'Sofi Heart',
                    image: '/assets/rust.png',
                },
                {
                    id: 2,
                    name: 'Sofi Heart',
                    image: '/assets/rust.png',
                },
            ],
        },
        {
            id: 2,
            name: 'Presentation for Project',
            image: '/assets/rust.png',
            type: 'team',
            materials: 'Materials',
            created: 'January 30, 2021 12:51 PM',
            status: 'done',
            users: [
                {
                    id: 2,
                    name: 'Sofi Heart',
                    image: '/assets/rust.png',
                },
                {
                    id: 2,
                    name: 'Sofi Heart',
                    image: '/assets/rust.png',
                },
                {
                    id: 2,
                    name: 'Sofi Heart',
                    image: '/assets/rust.png',
                },
            ],
        },
        {
            id: 3,
            name: 'Meetings',
            image: '/assets/rust.png',
            type: 'team',
            materials: 'Materials',
            created: 'January 30, 2021 12:51 PM',
            status: 'progress',
            users: [
                {
                    id: 2,
                    name: 'Sofi Heart',
                    image: '/assets/rust.png',
                },
                {
                    id: 2,
                    name: 'Sofi Heart',
                    image: '/assets/rust.png',
                },
                {
                    id: 2,
                    name: 'Sofi Heart',
                    image: '/assets/rust.png',
                },
            ],
        },
        {
            id: 3,
            name: 'Visiting locations',
            image: '/assets/rust.png',
            type: 'school',
            materials: 'Materials',
            created: 'January 30, 2021 12:51 PM',
            status: 'false',
            users: [
                {
                    id: 2,
                    name: 'Sofi Heart',
                    image: '/assets/rust.png',
                },
                {
                    id: 2,
                    name: 'Sofi Heart',
                    image: '/assets/rust.png',
                },
                {
                    id: 2,
                    name: 'Sofi Heart',
                    image: '/assets/rust.png',
                },
            ],
        },
    ];

    table = new ProjectStepsTable();

    usersList: string[] = ['Alex', 'Josh', 'Leon', 'Pedro'];

    constructor(public dialog: MatDialog, private notificationService: NotificationService) {}

    ngOnInit() {}

    get viewName(): string {
        return this.viewModes.find((mode: any) => mode.value === this.viewMode).viewValue;
    }

    openModal(dialogRef: TemplateRef<any>) {
        this.dialog.open(dialogRef, { autoFocus: false });
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }
}
