import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Tools } from '../tools';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class NavProgressService {
    private changedRateSource = new Subject<any>();
    rateChanged$ = this.changedRateSource.asObservable();

    constructor(private http: HttpClient) {}

    changeRate(rate: number) {
        this.changedRateSource.next(rate);
    }
}
