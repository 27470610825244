import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Tools } from '../tools';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class InviteService {
    constructor(private http: HttpClient) {}

    check(data: any) {
        return this.http.post(new Url('/check-invite-code').path, data);
    }

    sendInvite(userId: number) {
        return this.http.post(new Url('/send-invite-code/' + userId).path, undefined);
    }

    sendInvites(usersIds: number[]) {
        return this.http.post(new Url('/send-invite-codes').path, { users: usersIds });
    }
}
