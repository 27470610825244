import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MiniGamesService } from 'src/app/services/mini-games.service';
import { environment } from 'src/environments/environment';

const ANIMATION_DURATION = 400;

@Component({
    selector: 'app-all-minigames',
    templateUrl: './all-minigames.component.html',
    styleUrls: ['./all-minigames.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('in', style({ opacity: 1, transform: 'translateY(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateY(-100%)' }),
                animate(ANIMATION_DURATION),
            ]),
            transition('* => void', [
                animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateY(100%)' })),
            ]),
        ]),
    ],
})
export class AllMinigamesComponent implements OnInit {
    isLoading: boolean = false;

    games: any[] = [];
    storageUrl: string = environment.storageUrl;

    constructor(private miniGamesService: MiniGamesService) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.miniGamesService.getAll().subscribe((response: any) => {
            this.games = response.data || [];
        });
    }
}
