import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class StatisticsService {
    public API: string = '/statistics';

    constructor(private http: HttpClient) {}

    getAll(projectId, teamId, studentId) {
        const url = new Url(this.API);

        url.addQueryParameter('project_id', projectId);
        url.addQueryParameter('team_id', teamId);
        url.addQueryParameter('user_id', studentId);

        return this.http.get(url.path);
    }

    getById(teamId: number = 7) {
        const url = new Url(this.API);

        return this.http.get(url.path);
    }
}
