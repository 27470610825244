import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { Tools } from 'src/app/tools';
import { Subscription } from 'rxjs';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    providers: [],
})
export class CalendarComponent implements OnInit {
    @Output() viewUpdated: EventEmitter<any> = new EventEmitter<any>();

    data: any = [];

    today: any = moment();
    days: any[] = [];
    weeksQty: any = [0];

    isLoading: boolean = true;

    subscriptions: Subscription[] = [];

    sunday: string = '';
    range: {
        from: moment.Moment;
        to: moment.Moment;
    };

    weekdays = moment.weekdays(true);

    openingHours: any[] = [9, 19];
    hours: any[] = [];

    constructor(
        private notificationService: NotificationService,
        public sessionService: SessionService,
        public dialogForm: MatDialog
    ) {}

    ngOnInit() {
        this.getDateRange();
    }

    ngOnDestroy() {
        Tools.unsubscribeAll(this.subscriptions);
    }

    fetch() {
        this.mapData();
    }

    getDateRange() {
        const date = this.today.format('YYYY-MM-DD');
        this.range = { from: this.today, to: moment(date).add(1, 'weeks') };
        this.sunday = moment(date).add(2, 'weeks').endOf('week').format('YYYY-MM-DD');
        this.fetch();
    }

    mapData() {
        this.hours = this.getSlots(this.today.format('YYYY-MM-DD'));
        const weekRange = moment.range(
            this.range.from.clone().add(0, 'weeks'),
            this.range.from.clone().add(1, 'weeks')
        );

        this.days = Array.from(weekRange.by('days')).map((day) => ({
            date: day.clone(),
            tasks: [],
            hours: this.getSlots(day.format('YYYY-MM-DD')),
        }));

        this.isLoading = false;
    }

    getEvents(day: moment.Moment) {
        return [];
    }

    moveWeek(number: number) {
        this.today = number ? this.today.add(number, 'week') : moment().startOf('week');
        this.getDateRange();
    }

    formatDate(date: any) {
        return date ? date.slice(0, -11) : '';
    }

    closeDialog(): void {
        this.dialogForm.closeAll();
    }

    toggleView() {
        this.viewUpdated.emit();
    }

    getSlots(day: string) {
        const slots = [];

        for (let i = this.openingHours[0]; i < this.openingHours[1]; i++) {
            const slotBegin = this.getFullTime(day, i + ':00');
            const slotEnd = this.getFullTime(day, i + 1 + ':00');

            slots.push({ begin: slotBegin, end: slotEnd });
        }

        return slots;
    }

    isWeekend(day: any): boolean {
        return day.day() === 6 || day.day() === 0;
    }

    getFullTime(day: string, time: any): string {
        const timeParts = time.split(':');
        let hours = timeParts[0];
        hours = hours.length < 2 ? '0' + hours : hours;
        const minutes = timeParts[1];
        return `${day}T${hours}:${minutes}:00`;
    }

    format(date: any) {
        return moment(date).format('HH:mm');
    }
}
