<div *ngIf="card" class="com-info-card cursor-pointer game-card p-3">
    <div class="footer">
        <div class="mb-4">
            <div class="name mt-2">
                <h6 style="font-weight: 900">{{ card.name }}</h6>
            </div>

            <div class="name" style="margin-top: -12px">
                <span style="opacity: 0.5">Platform level</span>
            </div>
        </div>

        <div class="mb-4">
            <div class="flex justify-between" style="font-size: 0.7em; opacity: 0.8">
                <span>Progress</span>
                <span>{{ card.progress }}%</span>
            </div>

            <div class="mb-2">
                <div class="progress">
                    <span class="progress-bar" style="width: 75%"></span>
                </div>
            </div>
        </div>

        <div class="flex justify-between" style="font-size: 0.7em; opacity: 0.8">
            <span> <i class="far fa-clock mr-1"></i> Last modified {{ card.modified_at }} </span>
        </div>
    </div>
</div>
