import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import * as moment from 'moment';
import { ProjectCalendarTable } from 'src/app/tables/ProjectCalendarTable';

@Component({
    selector: 'app-school-project-calendar',
    templateUrl: './school-project-calendar.component.html',
    styleUrls: ['./school-project-calendar.component.scss'],
    providers: [],
})
export class SchoolProjectCalendarComponent implements OnInit {
    isLoading: boolean = false;

    date: string = moment().format('MMM DD, YYYY');

    ranges: string[] = ['day', 'week', 'month', 'year'];
    selectedRange: string = this.ranges[1];

    viewModes: any[] = [
        { value: 'default-0', viewValue: 'Default view' },
        { value: 'pro', viewValue: 'Detailed view' },
    ];

    viewMode = this.viewModes[0].value;

    planSteps: any[] = [
        {
            id: 1,
            sunday: 'Сhemistry',
            monday: 'Project',
            tuesday: 'Biology',
            wednesday: 'Biology',
            thursday: 'Сhemistry',
            friday: 'Project',
        },
        {
            id: 2,
            sunday: 'Biology',
            monday: 'Biology',
            tuesday: 'Сhemistry',
            wednesday: 'Literature',
            thursday: 'Biology',
            friday: 'Сhemistry',
        },
        {
            id: 3,
            sunday: 'History',
            monday: 'History',
            tuesday: 'History',
            wednesday: 'Project',
            thursday: 'History',
            friday: 'Biology',
        },
        {
            id: 4,
            sunday: 'Project',
            monday: 'Literature',
            tuesday: 'Literature',
            wednesday: 'Biology',
            thursday: 'Project',
            friday: 'History',
        },
    ];

    table = new ProjectCalendarTable();

    usersList: string[] = ['Alex', 'Josh', 'Leon', 'Pedro'];

    constructor(public dialog: MatDialog, private notificationService: NotificationService) {}

    ngOnInit() {}

    get viewName(): string {
        return this.viewModes.find((mode: any) => mode.value === this.viewMode).viewValue;
    }

    openModal(dialogRef: TemplateRef<any>) {
        this.dialog.open(dialogRef, { autoFocus: false });
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    selectRange(range: string) {
        this.selectedRange = range;
    }
}
