<ng-container *ngIf="!isLoading">
    <div *ngIf="!viewTask && stages.length > 3" class="mt-2 flex items-center justify-end">
        <button mat-button (click)="moveStage(-1)" [disabled]="stageIndex === 0">
            <i class="ph-caret-double-left text-large rtl:-rotate-180"></i>
        </button>

        <div class="text-gray-400">{{ 'communications.component.move_stage' | translate }}</div>

        <button mat-button (click)="moveStage(1)" [disabled]="stageIndex === stages.length - columnsCount">
            <i class="ph-caret-double-right text-large rtl:-rotate-180"></i>
        </button>
    </div>

    <div *ngIf="!viewTask" cdkDropListGroup class="tasks-row grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        <ng-container *ngFor="let stage of stages.slice(stageIndex, stageIndex + columnsCount)">
            <div class="relative">
                <app-tasks-column
                    [@flyleftIn]
                    [title]="stage.title"
                    [tasks]="stage.tasks"
                    [stageId]="stage.id"
                    [taskTypes]="taskTypes"
                    [taskTags]="taskTags"
                    [teamId]="stage.team_id"
                    (taskUpdated)="fetch()"
                >
                </app-tasks-column>
            </div>
        </ng-container>
    </div>
</ng-container>

<div *ngIf="isLoading" class="flex h-full items-center justify-center" style="max-height: 400px">
    <mat-spinner></mat-spinner>
</div>

<ng-container *ngIf="viewTask && !isLoading">
    <div #scroll cdkDropListGroup class="un-scroll flex flex-col transition-all">
        <ng-container *ngFor="let stage of stages">
            <app-simple-stage-view
                [title]="stage.title"
                [tasks]="stage.tasks"
                [stageId]="stage.id"
                [teamId]="stage.team_id"
                [stageStatus]="stage.status"
                (taskUpdated)="fetch()"
            >
            </app-simple-stage-view>
        </ng-container>
    </div>
</ng-container>

<div *ngIf="!isLoading && stages.length === 0" class="mt-4 flex items-center justify-center" style="height: 75%">
    <app-no-data [message]="emptyDataMessage" [actionMessage]="'home page'" (actionActivated)="goHomePage()">
    </app-no-data>
</div>
