<div [@flyBottomIn] class="items-center mb-5">
    <div class="games-grid flex-wrap">
        <ng-container *ngFor="let game of gamesCategories">
            <div class="main-card p-2 game-box cursor-pointer">
                <div class="game-box-row">
                    <div
                        class="image-row"
                        [ngStyle]="{
                            'background-image':
                                'linear-gradient(180deg, rgba(0, 0, 0, 0) 48.96%, rgba(0, 0, 0, 0.75) 100%), url(' +
                                storageUrl +
                                game.image_path +
                                ')'
                        }"
                    >
                        <div class="px-2 pb-2 flex justify-between w-full game-info-block">
                            <div class="flex items-center" style="gap: 2px">
                                <i class="ph-game-controller" style="font-size: 1.4em"></i> <span>0</span>
                            </div>
                            <div class="flex items-center" style="gap: 4px">
                                <i class="ph-code" style="font-size: 1.4em"></i> <span>1</span>
                            </div>
                        </div>
                    </div>
                    <div class="text-center p-1">
                        <div class="game-box-row-name">{{ game.name | truncate : 80 }}</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
