import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild, forwardRef } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { MatInput } from '@angular/material/input';

@Component({
    selector: 'app-date-input',
    template: `
        <div>
            <label class="block text-sm font-medium text-gray-500 dark:font-normal dark:text-gray-400">
                <span>{{ label }}</span>
                <span
                    *ngIf="isRequired"
                    class="ml-0.5 "
                    [ngClass]="{ 'text-red-500 dark:text-rose-500': control.errors?.['required'] }"
                >
                    *
                </span>
            </label>
            <div class="mt-0.5">
                <input
                    type="date"
                    [disabled]="isDisabled"
                    [value]="value"
                    (input)="onInputChange($event)"
                    (blur)="onInputBlur()"
                    class="block w-full rounded-md border-0 bg-transparent px-2 py-2 text-sm text-gray-900 outline-none ring-1 ring-inset ring-gray-300 transition duration-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 disabled:opacity-40 dark:text-white dark:ring-gray-600 dark:placeholder:text-gray-500 dark:focus:ring-sky-400"
                />
            </div>

            <ng-container *ngIf="control?.touched || control?.dirty">
                <div
                    *ngIf="control.errors?.['required']"
                    class="text-xs text-red-500 dark:font-semibold dark:text-rose-500"
                >
                    Please fill in
                </div>
            </ng-container>
        </div>
    `,
    styles: [':host { display: block; }', ':host-context(.dark) input {color-scheme: dark;}'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DateInputComponent),
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DateInputComponent),
            multi: true,
        },
    ],
})
export class DateInputComponent implements ControlValueAccessor, AfterViewInit {
    @Input() label: string = '';
    @Input() isDisabled: boolean = false;

    private onChange: (value: any) => void;
    private onTouched: () => void;
    value: any;

    control: any;
    @ViewChild('input', { static: false, read: NgControl }) input;
    @ViewChild('ref') dateInput;

    dateControl: FormControl = new FormControl();

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.validate(null);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!this.control) this.control = control;

        if (this.control && this.input) this.input.control.setValidators(this.control.validator);

        return null;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        if (isDisabled) {
            this.dateControl.disable();
        } else {
            this.dateControl.enable();
        }
    }

    onInputChange(event: Event): void {
        const input = event.target as HTMLInputElement;
        this.value = input.value;
        this.onChange(this.value);
    }

    onInputBlur(): void {
        this.onTouched();
    }

    get isRequired() {
        return this.control?.hasValidator(Validators.required);
    }

    clearInput() {
        this.value = null;
        this.onChange(this.value);

        // Reset the input value
        if (this.input && this.input instanceof MatInput) {
            this.input.value = '';
        }
    }

    orgValueChange(value: any) {
        this.value = value;
        this.onChange(this.value);
    }

    writeValue(value: any) {
        if (value) {
            this.value = value;
            this.dateControl.setValue(value);
        } else {
            this.value = null;
            this.dateControl.reset();
        }

        this.cdr.detectChanges();
    }

    updateDate(date: Date) {
        this.onChange(date);
        this.onTouched();
    }
}
