<aside
    class="sidebar rounded-lg backdrop-blur-3xl md:my-10 md:mr-3 md:h-[calc(100%_-_65px)] md:backdrop-blur-sm ltr:md:ml-3 rtl:md:ml-3"
    [ngClass]="{ minimized: isMinimized }"
>
    <div class="relative h-full">
        <nav class="sidebar-menu flex h-full flex-col bg-[#08051F] lg:bg-transparent">
            <ul class="menu-items to-hide-mobile">
                <ng-container *ngFor="let item of items">
                    <li *ngIf="item.visible" class="relative">
                        <a
                            class="relative h-12 text-2xl md:text-base leading-10 flex {{
                                isMinimized ? 'justify-center' : ''
                            }}"
                            [routerLink]="item.link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                        >
                            <i class="{{ item.icon }}"></i>
                            <span *ngIf="!isMinimized" class="nav-name">{{ item.name }}</span>
                            <div
                                routerLinkActive="active:duration-400 absolute inset-0 top-0 h-full w-0.5 rounded-bl-none rounded-br-md rounded-tl-none rounded-tr-md bg-[#FF02D0] transition ease-in-out"
                            ></div>
                        </a>
                    </li>
                </ng-container>
            </ul>

            <div class="to-hide-mobile my-2">
                <hr class="menu-saparator" />
            </div>

            <ul class="menu-items to-hide-mobile">
                <ng-container *ngFor="let item of adminItems">
                    <li *ngIf="item.visible" class="relative">
                        <a
                            href="#"
                            class="relative h-12 text-2xl md:text-base leading-10 flex {{
                                isMinimized ? 'justify-center' : ''
                            }}"
                            [routerLink]="item.link"
                            [class.active]="href === item.link"
                            routerLinkActive="active"
                        >
                            <i class="{{ item.icon }}"></i>
                            <span *ngIf="!isMinimized" class="nav-name">{{ item.name }}</span>
                            <div
                                routerLinkActive="active:duration-400 absolute inset-0 top-0 h-full w-0.5 rounded-bl-none rounded-br-md rounded-tl-none rounded-tr-md bg-[#FF02D0] transition ease-in-out"
                            ></div>
                        </a>
                    </li>
                </ng-container>
            </ul>

            <ul class="menu-items" style="margin-top: auto">
                <li class="cursor-pointer" (click)="toggleFullView()">
                    <a class="h-12 text-2xl leading-10 md:text-base" [ngClass]="{ 'justify-center': isMinimized }">
                        <i class="ph-sidebar"></i>
                        <span *ngIf="!isMinimized" class="nav-name">{{ 'sidebar.minimize' | translate }}</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</aside>
