import { SessionService } from '../services/session.service';

export class MenuFactory {
    constructor(public session: SessionService) {}

    make() {
        return [
            {
                name: 'Training',
                display: this.session.can('read', 'training', 'training'),
                link: '/training',
                dropdownItems: [
                    {
                        name: 'Übungen',
                        display: true,
                        link: '/training/exercise',
                    },
                ],
            },
        ];
    }
}
