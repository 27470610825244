import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-un-create-button',
    templateUrl: './un-create-button.component.html',
    styleUrls: ['./un-create-button.component.scss'],
})
export class UnCreateButtonComponent {
    @Output() сlicked: EventEmitter<any> = new EventEmitter();

    emitClick() {
        this.сlicked.emit();
    }
}
