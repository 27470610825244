import { Component, OnInit } from '@angular/core';
import { MiniGamesService } from 'src/app/services/mini-games.service';



@Component({
  selector: 'app-mini-games',
  templateUrl: './mini-games.component.html',
  styleUrls: ['./mini-games.component.scss'],
  providers: [],
})
export class MiniGamesComponent implements OnInit {

  isLoading: boolean = false;

  games: any[] = [];

  constructor(
    private miniGamesService: MiniGamesService,
  ) { }


  ngOnInit() {
    this.fetch();
  }

  fetch() {
    this.miniGamesService.getAll().subscribe((response: any) => {
      this.games = response.data || [];
    });
  }



}
