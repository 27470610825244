import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { OrganizationsTable } from 'src/app/tables/OrganizationsTable';
import { SchoolsService } from 'src/app/services/schools.service';

@Component({
    selector: 'app-manage-organizations',
    templateUrl: './manage-organizations.component.html',
    styleUrls: ['./manage-organizations.component.css'],
})
export class ManageOrganizationsComponent implements OnInit {
    organizations: any[] = [];

    table = new OrganizationsTable();

    isLoading: boolean = true;

    constructor(public sessionService: SessionService, private schoolsService: SchoolsService) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.schoolsService.getAll().subscribe((response: any) => {
            this.organizations = response.data || [];
        });
    }
}
