import { transform } from 'typescript';
import { Table } from './Table';

export class ProjectStepsTable extends Table {
    constructor() {
        super();
        this.buildColumns();
    }

    buildColumns() {
        this.columns = [
            {
                name: 'Name',
                selector: 'name',
                headerClass: 'text-left',
                class: () => 'text-left',
            },
            {
                name: 'Users',
                selector: 'name',
                transform: (value: any) => '<i class="fas fa-user-friends"></i>&nbsp; Josh, Kevin',
            },
            {
                name: 'Type',
                selector: 'type',
                trustHtml: true,
                transform: (item) =>
                    `<span class="text-capitalize text-center tag px-3 mr-1 ${this.getColor(
                        item
                    )}" style="color: #fff !important;min-width: 78px;display: block;">${item}</span>`,
            },
            {
                name: 'Materials',
                selector: 'materials',
            },
            {
                name: 'Created',
                selector: 'created',
            },
            {
                name: 'Done',
                selector: 'status',
                headerClass: 'text-center',
                class: () => 'text-center',
                // transform: (value: any) => value ? '<i class="ph ph-circle text-green"></i>' : '<i class="ph ph-circle text-red"></i>',
                transform: (value: any) => this.getStatus(value),
            },
        ];
    }

    getColor(item: any) {
        let color = 'blue';

        if (item === 'team') {
            color = 'orange';
        }

        if (item === 'school') {
            color = 'pink';
        }

        return color;
    }

    getStatus(status: string): any {
        return {
            false: '<i class="far fa-square"></i>',
            progress: '<i class="fas fa-square"></i>',
            done: '<i class="far fa-check-square"></i>',
        }[status];
    }
}
