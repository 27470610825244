<div class="overflow-hidden w-full">
    <div>
        <div class="flex items-center mt-3 mb-4">
            <div class="col-auto">
                <div>
                    <app-un-gradient-text text="{{ 'studio.creative_studio_part_one' | translate }}" size="big">
                    </app-un-gradient-text>
                    <app-un-gradient-text text="{{ 'studio.creative_studio_part_two' | translate }}" size="big">
                    </app-un-gradient-text>
                </div>
            </div>

            <div class="col-auto ml-auto rtl:ml-0 rtl:mr-auto mb-3">
                <app-un-user-dashboadr-view></app-un-user-dashboadr-view>
            </div>
        </div>

        <div class="items-center mb-5">
            <div class="my-3 text-start">
                <span class="font-semibold text-base uppercase text-[#E1DEF5]">
                    {{ 'studio.tools' | translate }}
                </span>
            </div>

            <div class="flex justify-center sm:justify-start flex-wrap gap-6">
                <ng-container *ngFor="let item of tools">
                    <app-project-item [project]="item"></app-project-item>
                </ng-container>
            </div>

            <div class="my-3 text-start">
                <span class="font-semibold text-base uppercase text-[#E1DEF5]">
                    {{ 'studio.projects' | translate }}
                </span>
            </div>

            <div class="flex justify-center sm:justify-start flex-wrap gap-6">
                <ng-container *ngFor="let item of projects">
                    <app-project-item [project]="item"></app-project-item>
                </ng-container>
            </div>
        </div>
    </div>
</div>
