<div *ngIf="card" class="com-info-card cursor-pointer core-info-card" [class.game]="game" [class.selected]="selected">

    <div class="footer">

        <div class="info px-3">
            <div class="name">
                <ng-container *ngIf="game">
                    {{ card.name }}
                </ng-container>
                <ng-container *ngIf="!game">
                    <h5>{{ card.name }}</h5>
                </ng-container>
            </div>
            <div *ngIf="info" class="desc"> {{ card.info }}</div>
        </div>

    </div>

    <div class="card-blur" [class.blur]="blur" [ngStyle]="{'background-image': 'url(' + storageUrl +  card.image_path + ')'}"></div>

</div>