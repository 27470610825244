import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
    forwardRef,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    ValidationErrors,
    Validators,
} from '@angular/forms';

@Component({
    selector: 'app-select',
    template: `
        <div>
            <label
                class="block text-sm font-medium dark:font-normal"
                [ngClass]="{
                    'text-gray-500/50 dark:text-gray-400/50': isDisabled,
                    'text-gray-500 dark:text-gray-400': !isDisabled
                }"
            >
                <span>{{ label }}</span>
                <span
                    *ngIf="isRequired"
                    class="ml-0.5"
                    [ngClass]="{ 'text-red-500 dark:text-rose-500': control.errors?.['required'] }"
                >
                    *
                </span>
            </label>

            <div>
                <button
                    [disabled]="isDisabled"
                    #selectButton
                    (click)="toggle($event)"
                    (blur)="onSelectBlur()"
                    class="mt-0.5 flex h-9 w-full items-center justify-between rounded-lg border-0 bg-transparent px-2 py-2 ring-1 ring-inset transition duration-300 focus:ring-sky-400 dark:focus:ring-sky-400"
                    [ngClass]="{
                        'text-gray-900/50 ring-gray-300/50 dark:text-white/50 dark:ring-gray-600/50': isDisabled,
                        'text-gray-900 ring-gray-300 dark:text-white dark:ring-gray-600': !isDisabled
                    }"
                >
                    <div class="flex w-full items-center justify-between overflow-hidden">
                        <span
                            class="col-span-11 overflow-hidden truncate text-ellipsis text-start"
                            [ngClass]="{
                                'text-gray-900/50 dark:text-white/50 ': !inputText
                            }"
                            [textContent]="inputText || placeholder"
                        >
                        </span>
                        <!-- <i class="ph   col-span-1 text-end text-slate-600"></i> -->
                        <i
                            class="ph col-span-1 text-end text-slate-600"
                            [ngClass]="{ 'ph-caret-down': !open, 'ph-caret-up': open }"
                        ></i>
                    </div>
                </button>

                <div *ngIf="open && isListInited" class="fixed inset-0 z-30">
                    <ul
                        #list
                        [@flowAnimation]="options.length"
                        class="fixed max-h-[280px]  overflow-auto rounded border border-gray-300 bg-white shadow-lg transition duration-300 dark:border-gray-600 dark:bg-slate-600 2xl:max-h-[320px]"
                        [style.width.px]="listWidth"
                        [style.top.px]="xPosition.top"
                        [style.left.px]="xPosition.left"
                    >
                        <ng-container *ngIf="this.optionEmpty">
                            <li
                                class="relative flex min-h-[32px] break-all cursor-pointer select-none items-center py-2 pl-2 pr-8 transition duration-300 hover:bg-gray-200 dark:hover:bg-slate-700"
                                (click)="setValue(this.optionEmptyValue, this.optionEmptyText)"
                            >
                                {{ this.optionEmptyText }}

                                <span
                                    *ngIf="selectControl.value === this.optionEmptyValue"
                                    class="absolute inset-y-0 right-0 flex items-center pr-1 text-sky-400"
                                >
                                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path
                                            fill-rule="evenodd"
                                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </span>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="!optionGroupSelector">
                            <ng-container *ngFor="let option of options">
                                <li
                                    class="relative flex min-h-[32px] break-all cursor-pointer select-none items-center py-2 pl-2 pr-8 transition duration-300 hover:bg-gray-200 dark:hover:bg-slate-700"
                                    (click)="setValue(option[optionValueSelector], option[optionTextSelector])"
                                >
                                    {{ option[optionTextSelector] }}

                                    <span
                                        *ngIf="selectControl.value === option[optionValueSelector]"
                                        class="absolute inset-y-0 right-0 flex items-center pr-1 text-sky-400"
                                    >
                                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </span>
                                </li>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="optionGroupSelector">
                            <div *ngFor="let group of getOptionGroups()">
                                <li
                                    class="relative flex min-h-[32px] select-none items-center bg-gray-300 py-2 pl-2 pr-8 transition duration-300 dark:bg-slate-800"
                                >
                                    {{ group }}
                                </li>
                                <li
                                    *ngFor="let option of getOptionsForGroup(group)"
                                    class="relative cursor-pointer select-none py-2 pl-5 pr-8 transition duration-300 hover:bg-gray-200 dark:hover:bg-slate-700"
                                    (click)="setValue(option[optionValueSelector], option[optionTextSelector])"
                                >
                                    {{ option[optionTextSelector] }}

                                    <span
                                        *ngIf="selectControl.value === option[optionValueSelector]"
                                        class="absolute inset-y-0 right-0 flex items-center pr-1 text-sky-400"
                                    >
                                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path
                                                fill-rule="evenodd"
                                                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </span>
                                </li>
                            </div>
                        </ng-container>
                    </ul>
                </div>
            </div>

            <ng-container *ngIf="selectControl?.touched || selectControl?.dirty">
                <div
                    *ngIf="selectControl?.errors?.['required']"
                    class="absolute text-xs text-red-500 dark:font-semibold dark:text-rose-500"
                >
                    Please fill in
                </div>
            </ng-container>
        </div>
    `,
    styles: [':host { display: block; }'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectComponent),
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
    animations: [
        trigger('waterflowAnimation', [
            transition('* => *', [
                query(
                    ':enter',
                    [
                        style({ opacity: 0, transform: 'translateY(-20px)' }),
                        stagger('10ms', animate('20ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))),
                    ],
                    { optional: true },
                ),
            ]),
        ]),
        trigger('flowAnimation', [
            transition('* => *', [
                query(
                    ':enter',
                    [
                        style({ opacity: 0, transform: 'translateY(-40px)' }),
                        stagger('20ms', animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))),
                    ],
                    { optional: true },
                ),
            ]),
        ]),
    ],
})
export class SelectComponent implements ControlValueAccessor, AfterViewInit {
    @Input() label: string = '';
    @Input() options: any[] = [];
    @Input() isDisabled: boolean = false;
    @Input() optionValueSelector: string = 'id';
    @Input() optionTextSelector: string = 'name';
    @Input() optionGroupSelector: string = '';

    @Input() optionEmpty: boolean = false;

    @Input() optionEmptyValue: string = '';
    @Input() optionEmptyText: string = '';
    @Input() placeholder: string = '';

    @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();

    private onChange: (value: any) => void;
    private onTouched: () => void;
    public value: any;

    public inputText: any;

    control: any;
    @ViewChild('input', { static: false, read: NgControl }) input: any;

    @ViewChild('list') list: any;
    @ViewChild('selectButton') selectButton: any;

    selectControl: FormControl = new FormControl();

    open: boolean = false;

    xPosition = {
        top: 0,
        left: 0,
    };

    isListInited = false;

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.validate(null);
        this.checkPosition();
    }

    @HostListener('window:resize')
    onWindowResize() {
        this.checkPosition();
    }

    checkPosition() {
        if (this.open && this.isListInited) {
            this.setXposition();
        }
    }

    validate(control: AbstractControl | null): ValidationErrors | null {
        if (!this.control) this.control = control;

        if (this.control && this.input) this.input.control.setValidators(this.control.validator);

        return null;
    }

    getTextByValue(value: any): string {
        const searchOptions = this.optionEmpty
            ? [
                  {
                      [this.optionValueSelector]: this.optionEmptyValue,
                      [this.optionTextSelector]: this.optionEmptyText,
                  },
                  ...this.options,
              ]
            : this.options;

        const searchOption = searchOptions.find((option) => option[this.optionValueSelector] === value);

        return searchOption ? searchOption[this.optionTextSelector] : '';
    }

    writeValue(value: any) {
        if (value || value === null) {
            this.inputText = this.getTextByValue(value);
            this.selectControl.setValue(value);
        } else {
            this.inputText = '';
            this.selectControl.reset();
        }
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = () => {
            fn();
        };
    }

    setDisabledState(isDisabled: boolean) {
        if (isDisabled) {
            this.selectControl.disable();
        } else {
            this.selectControl.enable();
        }
    }

    onSelectChange(event: Event): void {
        const select = event.target as HTMLSelectElement;
        this.value = select.value;
        this.onChange(this.value);
    }

    onSelectBlur(): void {
        this.onTouched();
    }

    get isRequired() {
        return this.control?.hasValidator(Validators.required);
    }

    getOptionGroups(): string[] {
        return Array.from(new Set(this.options.map((item) => item[this.optionGroupSelector]))) as string[];
    }

    getOptionsForGroup(group: string): string[] {
        return this.options.filter((option) => option[this.optionGroupSelector] === group);
    }

    toggle(event: any) {
        event.stopPropagation();
        !this.open ? this.openList() : this.closeList();
        this.open = !this.open;
        this.cdr.detectChanges();
    }

    openList() {
        setTimeout(() => {
            this.setXposition();
            this.isListInited = true;
        }, 110);
    }

    closeList() {
        this.isListInited = false;
    }

    setXposition() {
        this.xPosition = { ...this.xPosition, ...this.getPosition() };
    }

    get listWidth(): string {
        return this.selectButton?.nativeElement.offsetWidth || '0';
    }

    getPosition() {
        // Get the position and dimensions of the select button element
        const selectButtonRect = this.selectButton?.nativeElement.getBoundingClientRect();

        // Get the height of the window or viewport
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        // Calculate the distance from the bottom of the select button to the bottom of the viewport
        const distanceToBottom = windowHeight - selectButtonRect.bottom;
        const distanceToTop = windowHeight - selectButtonRect.top;

        // Calculate the height of the select list based on the number of options
        const listItemHeight = 32; // Height of each list item in pixels
        const maxListHeight = 320; // Maximum height of the select list in pixels
        const selectListHeight = Math.min(this.options.length * listItemHeight, maxListHeight);

        // Check if there's enough space at the top to accommodate the select list or if not enough use direction where is more space
        const isEnoughSpaceAtTop = selectButtonRect.top >= selectListHeight || distanceToTop > distanceToBottom;

        // Determine if the select list should be flipped based on available space
        const isFlipped = isEnoughSpaceAtTop && selectListHeight > distanceToBottom;

        // Calculate the top position of the select list based on whether it is flipped or not
        const selectButtonHeight = 43; // Height of the select button in pixels
        const selectListPadding = 8; // Padding between the select button and the select list in pixels
        const selectListTop = isFlipped
            ? selectButtonRect.top - selectListHeight - selectListPadding
            : selectButtonRect.top + selectButtonHeight;

        // Return the calculated position as an object with top and left properties
        return { top: selectButtonRect ? selectListTop : 0, left: selectButtonRect ? selectButtonRect.left : 0 };
    }

    setValue(value: any, text: string) {
        this.inputText = text;
        this.selectControl.setValue(value);
        this.onChange(value);
        this.selectionChange.emit(value);
        this.open = false;
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        if (this.open) {
            const target = event.target as HTMLElement;

            if (!this.list?.nativeElement.contains(target)) {
                this.open = false;
            }
        }
    }
}
