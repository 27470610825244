import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-task-template',
    templateUrl: './task-template.component.html',
    styleUrls: ['./task-template.component.scss'],
})
export class TaskTemplateComponent {
    @Input() isLoading: boolean = false;
    @Input() taskTypes: any[] = [];
    @Input() taskTags: any[] = [];

    @Output() taskCreated: EventEmitter<any> = new EventEmitter();
    @Output() taskClosed: EventEmitter<any> = new EventEmitter();

    title: string = '';
    @ViewChild('inputText') inputEl: ElementRef;

    taskTypeControl = new UntypedFormControl(2);

    maxTaskTitleLength: number = 75;

    selectedTags: any[] = [];

    ngAfterViewInit() {
        if (this.isLoading) {
            return;
        }
        this.inputEl.nativeElement.focus();
    }

    save() {
        if (this.title.length >= 3) {
            this.createTasks();
        }
    }

    createTasks() {
        this.taskCreated.emit({
            name: this.title,
            task_type_id: this.taskTypeControl.value,
            tags: this.selectedTags.map((tag) => tag.id),
        });
    }

    close() {
        this.taskClosed.emit();
    }

    pushTag(tag: any) {
        this.taskTags = this.taskTags.filter((tagItem) => tagItem.id !== tag.id);
        this.selectedTags.push(tag);
    }

    removeTag(tag: any) {
        this.selectedTags = this.selectedTags.filter((tagItem) => tagItem.id !== tag.id);
        this.taskTags.push(tag);
    }
}
