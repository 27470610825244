import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class NetworkService {
    public API: string = '/network-tab';

    constructor(private http: HttpClient) {}

    getStats() {
        return this.http.get(new Url(this.API + '/stats').path);
    }

    getProjects() {
        return this.http.get(new Url(this.API + '/projects').path);
    }

    getСountryGraph() {
        return this.http.get(new Url('/countries/graph').path);
    }

    getFakeProjects() {
        return [
            {
                school: 'School 123',
                projects: 'HULA',
                users: '235',
                location: 'Israel',
                sphere: 'Mathematical ',
            },
            {
                school: 'School 87',
                projects: 'HULA',
                users: '233',
                location: 'Russia',
                sphere: 'Mathematical ',
            },
            {
                school: 'School 88',
                projects: 'HULA',
                users: '198',
                location: 'Russia',
                sphere: 'Physical',
            },
            {
                school: 'School ITL',
                projects: 'Jerusalem',
                users: '56',
                location: 'Italy',
                sphere: 'Economic',
            },
            {
                school: 'Jabotinsky School',
                projects: 'Jerusalem',
                users: '57',
                location: 'Israel',
                sphere: 'Economic  ',
            },
            {
                school: 'School Wolf',
                projects: 'Jerusalem',
                users: '55',
                location: 'The United States',
                sphere: 'Linguistic',
            },
            {
                school: 'Atid School',
                projects: 'Ramon',
                users: '100',
                location: 'Israel',
                sphere: 'Linguistic',
            },
        ];
    }
}
