import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { finalize } from 'rxjs/operators';
import { LocationsService } from 'src/app/services/locations.service';
import { ManageLocationsTable } from 'src/app/tables/ManageLocationsTable';
import { Tools } from 'src/app/tools';

@Component({
    selector: 'app-manage-locations',
    templateUrl: './manage-locations.component.html',
    styleUrls: ['./manage-locations.component.css'],
})
export class ManageLocationsComponent implements OnInit {
    locations: any[] = [];
    isLoading: boolean = false;
    ENTRY_NAME: string = 'Location';
    storageUrl: string = environment.storageUrl;

    table = new ManageLocationsTable();

    constructor(
        private locationsService: LocationsService,
        private notificationService: NotificationService,
        public sessionService: SessionService,
    ) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.locationsService.getAll().subscribe((response: any) => {
            this.locations = response.data || [];
        });
    }

    create(data: any) {
        this.locationsService
            .create(Tools.getFormData(data))
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' created successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                },
            );
    }

    update(data: any) {
        this.locationsService
            .update(data.id, Tools.getFormData(data))
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' updated successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                },
            );
    }

    delete(data: any) {
        this.locationsService
            .delete(data.id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' deleted successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                },
            );
    }
}
