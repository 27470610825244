import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SessionService } from 'src/app/services/session.service';
import { UniChatService } from 'src/app/services/uni-chat.service';
import { Tools } from 'src/app/tools';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-uni-chat',
    templateUrl: './uni-chat.component.html',
    styleUrls: ['./uni-chat.component.scss'],
})
export class UniChatComponent implements OnInit {
    message = new UntypedFormControl('');

    uniBot = {
        id: 0,
        nickname: 'uni chat',
    };
    messages: any[] = [
        {
            user: this.uniBot,
            date: moment().format(),
            message: this.HI_UNI,
        },
        {
            user: this.uniBot,
            date: moment().format(),
            message: this.WHAT_I_CAN_DO,
        },
    ];
    requestsHistory: string[] = [];
    historyPosition: number = 0;

    messageText: string;
    filter: any;
    chattingWith: any;
    user: any;

    subscriptions: Subscription[] = [];

    defaultImageUrl: string = '/assets/uni-1.png';

    storageUrl: string = environment.storageUrl;

    isLoading: boolean = true;
    isTyping: boolean = false;
    @ViewChildren('messages') messagesContainer: QueryList<any>;
    @ViewChild('content') content: ElementRef;

    @ViewChild('textArea') textArea: ElementRef;

    constructor(
        private uniChatService: UniChatService,
        private notificationService: NotificationService,
        private session: SessionService,
        public translate: TranslateService,
    ) {}

    ngOnInit() {
        this.user = this.session.getCurrentUser();
        this.requestsHistory = this.session.getCurrentUserUniChatHistory();
        this.historyPosition = this.requestsHistory.length - 1;
        setTimeout(() => (this.isLoading = false), 800);
    }

    ngOnDestroy() {
        Tools.unsubscribeAll(this.subscriptions);
    }

    ngAfterViewInit() {
        this.scrollToBottom();
        this.messagesContainer.changes.subscribe(this.scrollToBottom);
    }

    scrollToBottom = () => {
        try {
            this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
        } catch (err) {}
    };

    sendMessage() {
        if (this.message.value.length > 0) {
            const tempMessage = this.message.value;
            this.message.setValue('');

            this.messages.push({
                user: this.user,
                date: moment().format(),
                message: tempMessage,
            });

            this.pushRequestToHistory(tempMessage);
            this.historyPosition = this.requestsHistory.length - 1;
            this.askQuestuion(tempMessage);

            const textArea = this.textArea.nativeElement;
            textArea.rows = 1;
        }
    }

    pushRequestToHistory(request: string): void {
        // this.requestHistory.push(request);
        this.session.pushCurrentUserUniChatHistory(request);
        this.requestsHistory = this.session.getCurrentUserUniChatHistory();
    }

    moveByHistory(direction: number) {
        this.historyPosition += direction;
        if (this.historyPosition < 0) {
            this.historyPosition = this.requestsHistory.length - 1;
        } else if (this.historyPosition >= this.requestsHistory.length) {
            this.historyPosition = 0;
        }
        this.message.setValue(this.requestsHistory[this.historyPosition]);
    }

    askQuestuion(question: string) {
        this.isTyping = true;

        this.uniChatService
            .sendQuestion(question)
            .pipe(finalize(() => (this.isTyping = false)))
            .subscribe({
                next: (response: any) => {
                    this.messages.push({
                        user: this.uniBot,
                        date: moment().format(),
                        message: response.data,
                    });
                },
                error: (err) => {
                    this.notificationService.error(err.error.reason, 'Error');
                    this.isLoading = false;
                },
            });
    }

    keyDownFunction(event) {
        if (event.keyCode === 13) {
            event.preventDefault(); // Prevent default behavior
            this.sendMessage();
        }
    }

    onInput() {
        const textArea = this.textArea.nativeElement;
        textArea.rows = textArea.value.length / 30 + 1;
    }

    get HI_UNI() {
        return this.translate.instant('chat_gpt.greeting');
    }

    get WHAT_I_CAN_DO() {
        return this.translate.instant('chat_gpt.what_i_can_do');
    }
}
