import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-firework',
    templateUrl: './firework.component.html',
    styleUrls: ['./firework.component.scss'],
})
export class FireworkComponent implements OnInit {
    ngOnInit() {
        this.shoot();
    }

    toggle() {
        this.shoot();
    }

    shoot() {
        try {
            this.confetti({
                angle: this.random(60, 120),
                spread: this.random(10, 50),
                particleCount: this.random(40, 50),
                origin: {
                    y: 0.6,
                },
            });
        } catch (e) {}
    }

    random(min: number, max: number) {
        return Math.random() * (max - min) + min;
    }

    confetti(args: any) {
        return window['confetti'].apply(this, arguments);
    }
}
