import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-dashboard-level-view',
  templateUrl: './dashboard-level-view.component.html',
  styleUrls: ['./dashboard-level-view.component.scss']
})
export class DashboadrLevelViewComponent implements OnInit {

  @Input() level: any;

  @Output() selected: EventEmitter<any> = new EventEmitter();

  stars: any[] = [1, 2, 3, 4, 5];

  display: boolean = false;
  slideReady: boolean = true;
  descType: string = 'text';

  selectedLevel: any;
  selectedSlide = 0;

  storageUrl: string = environment.storageUrl;

  content: null | string = '';


  constructor(
    public sessionService: SessionService,
    public translate: TranslateService
  ) { }
  
  get displayButtonText(): string {
    return this.selectedLevel ? this.HIDE_BUTTON : this.MORE_BUTTON;
  }

  ngOnInit() {
    this.selectedLevel = false;
    this.content = this.level.description.slides[this.selectedSlide].content;
  }

  ngAfterViewInit() {
    setTimeout(() => this.display = true, 100);
  }

  openDescription(level: any) {
    this.selectedLevel = this.selectedLevel ? undefined : level;
    this.selected.emit(this.selectedLevel);
  }

  setSlide(i: number) {

    if (this.selectedSlide === i) {
      return;
    }

    this.slideReady = false;
    this.selectedSlide = i;
    const preloadTime = this.level.description.slides[i].type === 'video' ? 600 : 100;
    this.content = null;


    setTimeout(() => {
      this.content = this.level.description.slides[this.selectedSlide].content;
    }, 100)

    setTimeout(() => {
      this.slideReady = true;
    }, preloadTime)

  }

  get HIDE_BUTTON() {
    return this.translate.instant('ui.dashboard.hide');
  }

  get MORE_BUTTON() {
    return this.translate.instant('ui.dashboard.more');
  }

}
