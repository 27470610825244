<app-card class="mb-3 h-full" [paddingX]="false">
    <app-table
        [columns]="table.columns"
        [data]="projects"
        [form]="table.form"
        [create]="true"
        (onCreate)="create($event)"
        [edit]="true"
        (onEdit)="update($event)"
        [delete]="true"
        (onDelete)="delete($event)"
        [search]="true"
        (onSearch)="onSearch($event)"
        [deletionConfirm]="true"
        [dialogConfig]="{ width: '600px' }"
    >
    </app-table>
</app-card>
