import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// import { FcbForm, FcbFormElement } from './table-form/table-form.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/ui/confirm-dialog/confirm-dialog.component';
import { DataTableModule } from '@pascalhonegger/ng-datatable';
import { environment } from 'src/environments/environment';
import { FcbForm, FcbFormElement } from './table-form/table-form.component';
import { items } from '../un-user-dashboadr-view/data';
import { Tools } from 'src/app/tools';
import { FormControl } from '@angular/forms';

export interface DialogData {
    animal: 'panda' | 'unicorn' | 'lion';
}

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.css'],
})
export class TableComponent implements OnInit, OnChanges {
    @Input() data: any[] = [];
    @Input() columns: TableColumn[] = [];
    @Input() slim: boolean = false;

    @Input() title: string = '';

    @Input() search?: boolean = false;
    // @Input() sortable?: boolean = false;
    @Input() totalItems?: number = 0;
    @Input() delete?: boolean = false;
    @Input() create?: boolean = false;
    @Input() edit?: boolean = false;
    @Input() showOnlyEdit?: boolean = false;
    @Input() form?: FcbForm = new FcbForm();
    @Input() showHeader?: boolean = true;
    // @Input() showDeleteButtonPerRow?: boolean = false;
    @Input() dialogConfig?: any = {
        width: '400px',
    };

    @Input() select?: boolean = false;

    @Input() pagination?: boolean = true;
    @Input() itemsPerPage: number = 15;
    @Input() itemsPerPageSelect: number[] = [5, 10, 15, 30, 100];

    @Input() hideEmptyFilter?: boolean;
    @Input() filters: FcbDataFilter[] = [];

    // @Input() download?: boolean = false;
    @Input() deletionConfirm?: boolean = false;

    @Output() onSort: EventEmitter<any> = new EventEmitter();
    @Output() onDelete: EventEmitter<any> = new EventEmitter();
    @Output() onEdit: EventEmitter<any> = new EventEmitter();
    // @Output() onEditModal?: EventEmitter<any> = new EventEmitter();

    @Output() onCreate: EventEmitter<any> = new EventEmitter();
    @Output() onFiltersChanged: EventEmitter<any> = new EventEmitter();
    @Output() onSearch?: EventEmitter<any> = new EventEmitter();

    @Output() pageChange?: EventEmitter<any> = new EventEmitter();
    @Output() limitChange?: EventEmitter<any> = new EventEmitter();

    // @Output() dialogOpened?: EventEmitter<any> = new EventEmitter();

    @ViewChild('createModal') createModal: any;
    @ViewChild('createModalContent') createModalContent: any;
    @ViewChild('dataTable') dataTable: DataTableModule | undefined;

    modal: any;

    tableData = Tools.cloneObject(this.data);

    searchText = new FormControl('');

    formElements: FcbFormElement[] = [];
    // @Input() sortColumn: TableColumn | undefined;
    currentItem: any;
    // filterColumns: TableColumn[] = [];
    selectedItems: any[] = [];

    // optionPlaceholder: any;
    // pages: any[] = [];
    page: number = 1;

    constructor(
        public sanitize: DomSanitizer,
        public dialogForm: MatDialog,
    ) {}

    ngOnInit(): void {}

    // changeLimit(limit: number) {
    //   this.itemsPerPage = limit;
    //   this.limitChange?.emit(limit);
    //   this.page = 1;
    //   this.getPages();
    // }

    // changePage(page: number) {
    //   if (this.page === page) {
    //     return;
    //   }
    //   this.page = page !== -1 ? page : this.getTotalPages();
    //   this.pageChange?.emit(this.page);
    //   this.getPages();
    // }

    // getPages() {
    //   this.pages = [];
    //   const totalPages = this.getTotalPages();
    //   for (let i = 1; i <= totalPages; i++) {
    //     this.pages.push(i);
    //   }

    //   if (this.pages.length > 5) {
    //     let start = this.page > 3 ? this.page - 3 : 0;
    //     if (this.page > 5 && totalPages - this.page < 2) {
    //       start = this.page - (5 - (totalPages - this.page));
    //     }
    //     const end = this.page > 3 ? this.page + 2 : 5;
    //     this.pages = this.pages.slice(start, end);
    //   }
    // }

    // getTotalPages() {
    //   if (this.totalItems) {
    //     const totalPages_pre = (this.totalItems / this.itemsPerPage);
    //     return Math.floor((this.totalItems % this.itemsPerPage) === 0 ? totalPages_pre : totalPages_pre + 1);
    //   }

    //   return 0;

    // }
    changeLimit(limit: number) {
        this.itemsPerPage = limit;
        this.page = 1;
        if (!this.totalItems) {
            this.tableData = this.paginate(Tools.cloneObject(this.data), this.itemsPerPage, this.page);
            return;
        }

        this.limitChange?.emit(limit);
    }

    changePage(page: number) {
        this.page = page;
        if (!this.totalItems) {
            this.tableData = this.paginate(Tools.cloneObject(this.data), this.itemsPerPage, this.page);
            return;
        }

        this.pageChange?.emit(this.page);
    }

    paginate(array: any, pageSize: number, pageNumber: number): any {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }

    get rowsOnPage() {
        return this.pagination ? this.itemsPerPage : this.data.length;
    }

    /**
     * react on parameter changes
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if ('filters' in changes) {
            this.filters = changes.filters.currentValue;
        }

        if ('data' in changes) {
            this.data = changes.data.currentValue || [];

            if (this.isUiPagination) {
                this.tableData = this.paginate(Tools.cloneObject(this.data), this.itemsPerPage, this.page);
                return;
            }

            this.tableData = changes.data.currentValue ? Tools.cloneObject(changes.data.currentValue) : [];
        }

        if ('columns' in changes) {
            this.columns = changes.columns.currentValue;
        }

        if ('form' in changes) {
            this.form = changes.form.currentValue;
        }

        if ('sortColumn' in changes) {
            this.sortColumn = changes.sortColumn.currentValue;
        }

        if ('totalItems' in changes) {
            this.totalItems = changes.totalItems.currentValue;
            // this.getPages();
        }
    }

    get isUiPagination() {
        return this.pagination && !this.totalItems;
    }

    /**
     * apply class(es) for a single cell depending on cell value
     * @param column the column
     */
    getHeaderClass(column: TableColumn): string {
        if (column.headerClass) {
            return column.headerClass;
        }
        return '';
    }

    /**
     * apply class(es) for a single cell depending on cell value
     * @param column the column
     * @param entry the data entry
     */
    getClass(column: TableColumn, entry: any): string {
        if (column.class) {
            return column.class(this.getValue(column, entry), entry);
        }
        return '';
    }

    /**
     * returns style for cell depending in cell value
     * @param column the column
     * @param entry the data entry
     */
    getStyle(column: TableColumn, entry: any): string {
        if (column.style) {
            return column.style(this.getValue(column, entry));
        }
        return '';
    }

    /**
     * returns style for cell depending in cell value
     * @param column the column
     * @param entry the data entry
     */
    getCallback(column: TableColumn, entry: any): void {
        if ('callback' in column && column.callback) {
            column.callback(Object.assign({}, entry));
        }
    }

    /**
     * returns style for cell depending in cell value
     * @param column the column
     * @param entry the data entry
     */
    getRoute(column: TableColumn, entry: any, key: any): string {
        if ('route' in column && column.route) {
            const route = column.route(Object.assign({}, entry));
            return key in route ? route[key] : {};
        }

        return '';
    }

    /**
     * returns the value for the given column/entry and applies transform to value.
     * returns the whole entry if selector is not set or invalid.
     * @param column the column
     * @param entry the data entry
     */
    getValue(column: TableColumn, entry: any): any {
        let value = entry[column.selector];

        if (!value && value !== 0 && value !== '' && value !== false) {
            value = entry;
        }

        if (column.transform) {
            return column.transform(value, entry);
        }

        return typeof value === 'object' ? '' : value;
    }

    /**
     * triggers sort for a column
     * @param column the column
     */
    // sort(column: TableColumn): void {
    //   if (!('sort' in column) || column.sort === false) {
    //     return;
    //   }

    //   column.sort = column.sort === true ? '' : column.sort;

    //   if (column.sort === '') {
    //     column.sort = column.sortDesc ? 'desc' : 'asc';
    //     this.setSortColumn(column);
    //     return;
    //   }

    //   if (column.sort === 'desc') {
    //     column.sort = column.sortDesc ? 'asc' : '';
    //     this.setSortColumn(column);
    //     return;
    //   }

    //   if (column.sort === 'asc') {
    //     column.sort = column.sortDesc ? '' : 'desc';
    //     this.setSortColumn(column);
    //     return;
    //   }

    // }

    // setSortColumn(column: any) {
    //   this.sortColumn = column.sort ? JSON.parse(JSON.stringify(column)) : undefined;
    //   column.selector = column.selector === 'name' ? 'last_name' : column.selector;
    //   this.onSort?.emit(column);
    // }

    sortColumn(column: TableColumn) {
        if (!('sort' in column) || column.sort === false) {
            return;
        }

        column.sort = column.sort === true ? '' : column.sort;

        if (column.sort === '') {
            column.sort = column.sortDesc ? 'desc' : 'asc';
            this.data = this.data.sort((a, b) => b[column.selector] - a[column.selector]);
            return;
        }

        if (column.sort === 'desc') {
            column.sort = column.sortDesc ? 'asc' : '';
            this.data = this.data.sort((a, b) => a[column.selector] - b[column.selector]);
            return;
        }

        if (column.sort === 'asc') {
            column.sort = column.sortDesc ? '' : 'desc';
            this.data = this.data.sort((a, b) => a[column.selector] - b[column.selector]);
            return;
        }
    }

    /**
     * returns a single column by selector
     * @param selector attribute selector
     */
    getColumn(selector: string): TableColumn | undefined {
        for (const column of this.columns) {
            if (column.selector === selector) {
                return column;
            }
        }

        return undefined;
    }

    openImageDialogModal(src: string) {
        // this.modal = this.dialogForm.open(templateRef);
        const imagePath = src ? environment.storageUrl + src : '/assets/img.png';
        this.dialogForm.open(ImageDialogData, {
            data: imagePath,
        });
    }

    /**
     * create a new item
     */
    createItem(templateRef: TemplateRef<any>): void {
        this.currentItem = {};
        this.formElements = this.getFormElements();
        this.openDialogModal(templateRef);
    }

    /**
     * edit a single item
     * @param item
     */
    editItem(templateRef: TemplateRef<any>, item: any): void {
        this.currentItem = item;
        this.formElements = this.getFormElements(item);
        // this.onEditModal?.emit(item);
        this.openDialogModal(templateRef);
    }

    openDialogModal(templateRef: TemplateRef<any>) {
        this.modal = this.dialogForm.open(templateRef, this.dialogConfig);
        // this.dialogOpened?.emit();
    }

    close() {
        this.modal.close();
    }

    /**
     * delete a single item
     * @param item
     */
    deleteItem(item: any): void {
        if (this.deletionConfirm) {
            this.dialogForm
                .open(ConfirmDialogComponent, { data: { title: 'Are you sure you want to delete the entry?' } })
                .afterClosed()
                .subscribe((result: boolean) => {
                    if (result) {
                        this.emitDelete(item);
                    }
                });
        } else {
            this.emitDelete(item);
        }

        // this.emitDelete(item);
    }

    emitDelete(item: any): void {
        this.onDelete?.emit(item);
        this.data = this.data.filter((itm) => itm !== item);
        this.close();
    }

    /**
     * gets called from modal save button was clicked
     */
    itemCreated(item: any): void {
        if (item.id && item.id !== 0) {
            this.onEdit?.emit(item);
        } else {
            this.onCreate?.emit(item);
        }
        this.close();
    }

    /**
     * Returns form elements for table
     * if optional parameter item is set then the values of
     * item will be preset in form
     *
     * @param item a single table data item
     */
    getFormElements(item?: any, formElements?: any[]): FcbFormElement[] {
        const elements = this.form ? this.form.elements : [];
        const _formElements: FcbFormElement[] = [...(formElements || elements)];

        if (item) {
            // set to item value
            _formElements.forEach((fe: FcbFormElement) => {
                if (fe.selector in item) {
                    fe.value =
                        item[fe.selector] !== undefined
                            ? item[fe.selector]
                            : fe.defaultValue
                              ? fe.defaultValue
                              : item[fe.selector];
                }
            });
        } else {
            // set default or empty
            _formElements.forEach((fe: FcbFormElement) => {
                if (fe.value && fe.defaultValue) {
                    fe.value = fe.defaultValue;
                } else if (fe.defaultValue) {
                    fe.value = fe.defaultValue;
                } else {
                    fe.value = undefined;
                }
            });
        }
        return _formElements;
    }

    /**
     * returns form title
     */
    getFormTitle(): string | undefined {
        return this.form?.title(this.currentItem);
    }

    /**
     * generates a element id
     * @param base
     * @param unique
     */
    // getElementId(base: string, unique: string): string {
    //   return base + '-' + unique;
    // }

    /**
     * toggles a selected element
     */
    // toggleSelectedItem(item: any): void {
    //   if (this.isSelected(item)) {
    //     // remove item
    //     this.selectedItems = this.selectedItems.filter(i => i !== item);
    //   } else {
    //     // add item
    //     this.selectedItems.push(item);
    //   }
    // }

    /**
     * is item selected
     */
    isSelected(item: any): boolean {
        return this.selectedItems.some((i) => i === item);
    }

    /**
     * returns all selected elements of table
     */
    getSelectedItems(): any[] {
        return this.selectedItems;
    }

    /**
     * select none
     */
    selectNone(): void {
        this.selectedItems = [];
    }

    /**
     * select all elements
     */
    selectAll(): void {
        this.selectedItems = this.data;
    }

    /**
     * gets called on filter model change
     */
    filtersChanged(): void {
        this.page = 1;
        this.onFiltersChanged.emit(this.filters);
    }

    /**
     * returns a array of option groups
     * @param filter
     */
    // getFilterOptionGroups(filter: FcbDataFilter): any {
    //   const groups: any = [];
    //   let lastGroup = '';
    //   if (filter) {
    //     filter.options.forEach((o) => {
    //       const group = o[filter.optionGroupSelector];
    //       if (group !== lastGroup) {
    //         groups.push(group);
    //       }
    //       lastGroup = group;
    //     });
    //   }
    //   return groups;
    // }

    /**
     * returns options for a option group
     */
    // getFilterOptionsForGroup(filter: FcbFormElement, group: string): string[] {
    //   const options: any = [];
    //   if (filter) {
    //     filter.options.forEach((o: any) => {
    //       if (o[filter.optionGroupSelector] === group) {
    //         options.push(o);
    //       }
    //     });
    //   }
    //   return options;
    // }

    /**
     * search field event for keyUp
     */
    searchInput(event: any) {
        event.preventDefault();
        if (event.keyCode === 13) {
            this.page = 1;
            this.onSearch?.emit(event.target.value);
        }
    }

    /**
     * checks if filter is visible
     * @param filter
     */
    isFilterVisible(filter: FcbDataFilter) {
        if ('visible' in filter) {
            return filter.visible;
        }
        return true;
    }

    getFilterOptionName(option: any, optionSelector: string | undefined) {
        return optionSelector !== undefined ? option[optionSelector] : '';
    }
    /**
     * export Table to csv
     */
    // downloadTable(): void {
    //   if (this.data) {
    //     this.export(this.data);
    //   }
    // }

    // export(jsonData: any, showLabels = true) {
    //   const data = typeof jsonData !== 'object' ? JSON.parse(jsonData) : jsonData;
    //   let csv = '';
    //   const delimiter = ';';

    //   if (showLabels) {
    //     csv += this.getLabels(data, delimiter) + '\r\n';
    //   }

    //   csv += this.getRows(data, delimiter) + '\r\n';

    //   if (csv === '') {
    //     return;
    //   }

    //   this.getTableFile(csv);
    // }

    // getLabels(data: any, delimiter: any) {
    //   let labels = '';
    //   for (const index in data[0]) {
    //     if (index in data[0] || data[0][index] === '') {
    //       const column = this.columns.find((item: any) => item.selector === index || this.isUnderscore(item.selector, index));
    //       labels += column ? column.name + delimiter : index + delimiter;
    //     }
    //   }

    //   return labels.slice(0, -1);
    // }

    // isUnderscore(selector: string, index: string) {
    //   if (selector.charAt(0) === '_') {
    //     const realName = selector.substring(1);
    //     return index === realName;
    //   }
    //   return false;
    // }

    // getRows(data: any, delimiter: any) {
    //   let rows = '';
    //   for (let i = 0; i < data.length; i++) {
    //     let row = '';

    //     for (const index in data[i]) {
    //       if (data[i][index] || data[i][index] === '' || data[i][index] === 0) {
    //         const value = (data[i][index] === null || data[i][index] === '') ? '' : data[i][index];
    //         row += '"' + value + '"' + delimiter;
    //       }
    //     }

    //     row.slice(0, row.length - 1);
    //     rows += row + '\r\n';
    //   }

    //   return rows;
    // }

    // getTableFile(csv: any) {
    //   const fileName = 'csv-table';

    //   const uri = 'data:text/csv;charset=utf-8,' + escape(csv);

    //   const link = document.createElement('a');
    //   link.href = uri;
    //   link.download = fileName + '.csv';
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);

    // }
}

/**
 * filter definition
 */
export class FcbDataFilter {
    /**
     * url key
     */
    key?: string;

    label?: string;

    /**
     * values: '' (default) | 'checkbox'
     */
    type?: string;

    /**
     * holds current value
     */
    selected?: any;

    visible?: boolean;

    /**
     * possible values
     */
    options?: any[] = [];

    optionTextSelector?: string = '';
    optionValueSelector?: string;
    optionGroupSelector?: string = '';

    optionEmpty?: boolean;
    optionEmptyText?: string;
    optionEmptyValue?: any;
}

/**
 * column definition
 */
export class TableColumn {
    /**
     * the name of the column
     */
    name: string = '';

    /**
     * the selector of the attribute value in a single element of data
     */
    selector: string = '';

    /**
     * column sort
     * values: '', 'asc', 'desc' | false
     */
    sort?: string | boolean | undefined = false;

    /**
     * column sort
     * values: '', 'image', 'boolean'
     */
    type?: string | boolean | undefined = false;

    /**
     * column sort
     * values: true, false
     */
    sortDesc?: boolean = false;

    /**
     * is column searchable
     */
    search?: boolean = true;

    /**
     * apply formats for column content
     * e.g.: []
     */
    format?: string[] = [];

    /**
     * column style attribute for width
     */
    width?: any;

    colspan?: number = 1;
    headerClass?: string;
    trustHtml?: boolean;
    /**
     * insert a element into table cell
     */
    content?: {
        template: TemplateRef<any>;
        context: (row: any) => {};
    };
    tooltip?: string;

    /**
     * value filter
     * added item as optional, for if you want to output multiple data aspects of the current entry
     */
    transform?(value: any, item?: any): any {
        return value;
    }

    /**
     * callback filter
     */
    callback?(value: any): any {
        return value;
    }

    /**
     * route filter
     */
    route?(value: any): any {
        return {};
    }

    /**
     * add class(es) to cell depending on cell value
     * @param value cell value
     */
    class?(value: any, item?: any): string {
        return '';
    }

    /**
     * add style to cell depending on cell value
     * @param value cell value
     */
    style?(value: any): string {
        return '';
    }
}

@Component({
    selector: 'image-dialog-data',
    templateUrl: 'image-dialog-data.html',
})
export class ImageDialogData {
    constructor(@Inject(MAT_DIALOG_DATA) public data: string) {}
}
