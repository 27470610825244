import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Tools } from '../tools';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(private http: HttpClient) {}

    getAll() {
        return this.http.get(new Url('/documents').path);
    }

    getById(id: number) {
        return this.http.get(new Url('/documents/' + id).path);
    }

    create(data: any) {
        return this.http.post(environment.apiUrl + '/documents', data);
    }

    update(id: number, data: any) {
        return this.http.put(environment.apiUrl + '/documents/' + id, data);
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + '/documents/' + id);
    }
}
