import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';
import { flyBottomIn, flyInOut, flyleftIn, flyRigthIn } from 'src/app/animations/fly-animations';
import { MiniGamesService } from 'src/app/services/mini-games.service';
import { environment } from 'src/environments/environment';

interface Game {
    id?: number;
    image_path: string | null;
    authors: any;
    progress: number | null;
    like: boolean;
}

@Component({
    selector: 'app-student-game-view',
    templateUrl: './student-game-view.component.html',
    styleUrls: ['./student-game-view.component.scss'],
    animations: [flyleftIn, flyRigthIn, flyInOut],
})
export class StudentGameViewComponent {
    isLoading: boolean = true;

    storageUrl: string = environment.storageUrl;

    game: any;
    selectedGame: any;
    isAbleExit: boolean = false;

    constructor(
        private notificationService: NotificationService,
        private route: ActivatedRoute,
        private router: Router,
        private miniGamesService: MiniGamesService,
    ) {}

    ngOnInit() {
        this.getGame();
    }

    getGame() {
        this.isLoading = true;
        const gameId = this.route.snapshot.paramMap.get('id');
        if (gameId) {
            setTimeout(() => {
                this.game = this.miniGamesService.getStudentGameById(parseInt(gameId));
                this.isLoading = false;
            }, 800);
        }
    }

    goToGamePage(gameUrl: string) {
        this.router.navigate(['games/game'], { queryParams: { src: gameUrl } });
    }

    start() {
        this.isAbleExit = false;
        this.selectedGame = this.game;
    }

    closeGame() {
        this.miniGamesService.closeGame();
    }

    exit() {
        this.selectedGame = undefined;
    }

    gameLoad() {
        this.isAbleExit = true;
    }
}
