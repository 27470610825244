<div *ngIf="question" [formGroup]="form" class="w-full">
    <div class="flex flex-col mb-4">
        <div class="question-wrapper mb-3">
            <span>{{ question.question_en }}</span>
        </div>

        <div [ngSwitch]="question.field_type">
            <ng-container *ngSwitchCase="'text'">
                <mat-form-field class="w-full" hintLabel="Max {{ textAreaLimit }} characters">
                    <textarea
                        matInput
                        #textAreaInput
                        [formControlName]="question.id"
                        [maxLength]="textAreaLimit"
                        required
                    >
                    </textarea>
                    <mat-hint align="end"> {{ textAreaInput.value.length || 0 }}/{{ textAreaLimit }} </mat-hint>
                </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="'radio'">
                <app-un-radio-set
                    [options]="options"
                    [value]="question.answer?.id"
                    [horizontal]="true"
                    (optionChanged)="setFormValue($event)"
                >
                </app-un-radio-set>
            </ng-container>
        </div>
    </div>

    <!-- <div class="errorMessage" *ngIf="!isValid">{{ question.label }} is required</div> -->
</div>
