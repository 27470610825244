import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class ActivitiesService {
    public API: string = '/activities/team-tasks';

    constructor(private http: HttpClient) {}

    getAll() {
        return this.http.get(new Url(this.API).path);
    }

    getByTaskId(id: number) {
        return this.http.get(new Url(this.API + '/' + id).path);
    }

    create(data: any) {
        return this.http.post(environment.apiUrl + this.API, data);
    }

    update(id: number, data: any) {
        return this.http.put(environment.apiUrl + this.API + '/' + id, data);
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + this.API + '/' + id);
    }

    getUserActivities(userId: number) {
        return this.http.get(new Url(this.API + '/users/' + userId).path);
    }

    getUserActivitiesProgress(userId: number) {
        return this.http.get(new Url(this.API + '/users/' + userId + '/progress').path);
    }
}
