import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-un-gradient-text',
    templateUrl: './un-gradient-text.component.html',
    styleUrls: ['./un-gradient-text.component.scss'],
    styles: [':host { display: flex; flex: 1 1 auto !important; }'],
})
export class UnGradientTextComponent {
    @Input() text: string = '';
    @Input() size: 'small' | 'big' | 'medium' = 'medium';
    @Input() decoration: string = 'none';

    constructor() {}

    get height() {
        return this.size + 4;
    }

    get fontSizeClass(): string {
        return {
            small: 'text-xl',
            medium: 'text-2xl',
            big: 'text-4xl',
        }[this.size];
    }
}
