import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-un-menu',
    templateUrl: './un-menu.component.html',
    styleUrls: ['./un-menu.component.scss'],
})
export class UnMenuComponent {
    @Input() name: string = '';

    @Output() menuItemSelected: EventEmitter<any> = new EventEmitter<any>();

    active: boolean = false;

    constructor() {}

    itemClick() {
        this.menuItemSelected.emit();
    }

    public displayMenu() {
        this.active = true;
    }
}
