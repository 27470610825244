<div class="flex student-data-row max-w-[800px] justify-between">
    <div class="flex flex-col">
        <div class="student-data-row-name">
            <span>{{ 'communications.info.age' | translate }}</span>
        </div>
        <div class="student-data-row-data"><span>12</span></div>
    </div>

    <div class="flex flex-col">
        <div class="student-data-row-name">
            <span>{{ 'communications.info.average_rating' | translate }}</span>
        </div>
        <div class="student-data-row-data"><span>95</span></div>
    </div>

    <div class="flex flex-col">
        <div class="student-data-row-name">
            <span>{{ 'communications.info.tasks_completed' | translate }}</span>
        </div>
        <div class="student-data-row-data"><span>14</span></div>
    </div>

    <div class="flex flex-col">
        <div class="student-data-row-name">
            <span>{{ 'communications.info.unacted_tasks' | translate }}</span>
        </div>
        <div class="student-data-row-data"><span>2</span></div>
    </div>
</div>

<div *ngIf="!isLoading" class="grid grid-cols-12 team-info-row">
    <div class="col-span-12 xl:col-span-4 main-card p-3">
        <div class="flex items-center mb-2">
            <span class="text-base font-bold leading-tight uppercase text-[#E1DEF5]">
                {{ 'communications.info.' + selectedChart | translate }}
            </span>
            <i class="ph-info-bold interactive text-base mx-2"></i>
        </div>
        <div class="flex flex-col items-center justify-center">
            <app-user-info-chart
                *ngIf="selectedChart === 'disciplines'"
                [inputChartData]="disciplinesChartData"
            ></app-user-info-chart>

            <app-user-skills-chart *ngIf="selectedChart === 'skills'" [chart]="skillsChartData"></app-user-skills-chart>

            <ul
                class="hidden mt-3 text-sm font-medium text-center text-gray-500 divide-gray-200 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400"
            >
                <li
                    *ngFor="let chart of charts; let last = last; let first = first"
                    class="w-full"
                    (click)="selectChart(chart)"
                >
                    <span
                        matRipple
                        [ngClass]="{
                            'bg-[#FF02D0]': selectedChart === chart,
                            'bg-slate-50/[.06]': selectedChart !== chart,
                            'ltr:rounded-l-lg rtl:rounded-r-lg': first,
                            'ltr:rounded-r-lg rtl:rounded-l-lg': last
                        }"
                        class="flex cursor-pointer justify-center w-36 items-center text-xs text-gray-200 py-2 mat-button mat-button-base px-4 transition-colors"
                    >
                        {{ 'communications.info.' + chart | translate }}
                    </span>
                </li>
            </ul>
        </div>
    </div>

    <div class="col-span-12 xl:col-span-5 main-card p-3">
        <div class="mb-3 text-start">
            <div>
                <span class="text-[#8E91D1] text-xs font-normal leading-relaxed">{{ today }}</span>
            </div>
            <div>
                <span class="text-base font-bold leading-tight uppercase text-[#E1DEF5]">
                    {{ 'communications.info.growth' | translate }}</span
                >
            </div>
        </div>

        <highcharts-chart
            [Highcharts]="developmentChart.highcharts"
            [options]="developmentChart.options"
            style="width: 100%; height: 375px; display: block"
        >
        </highcharts-chart>
    </div>
    <div class="col-span-12 xl:col-span-3 main-card p-3">
        <div class="flex justify-between">
            <div>
                <span class="text-base font-bold leading-tight uppercase text-[#E1DEF5]">
                    {{ 'tags.your_tags' | translate }}</span
                >
            </div>
            <div *ngIf="isAbleEditTags" (click)="openEditTagsModal(editTagsTemplate)">
                <i class="ph ph-pencil text-lg cursor-pointer"></i>
            </div>
        </div>

        <div class="mt-3 mb-1 flex flex-wrap gap-2">
            <ng-container *ngFor="let tag of userTags">
                <app-tag [tag]="tag"></app-tag>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #editTagsTemplate>
    <div
        class="py-8 px-14 2xl:py-10 2xl:px-16 bg-r h-[520px] max-w-[1000px] min-w-[942px] flex flex-col overflow-hidden"
    >
        <div class="mb-3 text-start">
            <span class="text-[#E1DEF5] text-3xl font-medium leading-snug uppercase">
                {{ 'welcome_pop_up.tags_title' | translate }}
            </span>
        </div>

        <div class="mb-2 text-start">
            <div class="text-sm leading-7 text-[#EDF2FF]">
                <span class="flex"> {{ 'welcome_pop_up.tags_desc' | translate }} </span>
            </div>
        </div>
        <div class="my-3 text-start">
            <span class="text-xs leading-4 text-[#8E91D1]">{{ 'welcome_pop_up.user_choice' | translate }}:</span>
        </div>
        <div class="mb-4 flex flex-wrap gap-2">
            <ng-container *ngFor="let tag of selectedTags">
                <app-tag [tag]="tag" (clicked)="removeTag(tag)"></app-tag>
            </ng-container>
        </div>
        <div class="mb-3"></div>
        <div class="mb-3 text-start">
            <span class="text-xs leading-4 text-[#8E91D1]">{{ 'welcome_pop_up.all_tags' | translate }}:</span>
        </div>
        <div class="mb-6 flex flex-wrap gap-2">
            <ng-container *ngFor="let tag of availableTags">
                <span
                    class="bg-[#363160] text-[#9F8DD0] px-2 py-1 capitalize rounded-md cursor-pointer transition-all"
                    (click)="pushTag(tag)"
                >
                    <div class="flex items-center gap-x-1">
                        <i class="ph text-base" [ngClass]="'ph-' + tag.icon"></i>
                        <span>
                            {{ tag.label }}
                        </span>
                    </div>
                </span>
            </ng-container>
        </div>

        <div class="flex items-center mt-auto">
            <div class="col-span-2 flex justify-end ltr:mr-auto rtl:ml-auto">
                <div>
                    <app-un-back-button (click)="closeDialog()"></app-un-back-button>
                </div>
            </div>

            <div class="col-span-10 flex justify-center gap-x-2"></div>

            <div class="col-span-2 flex justify-end ltr:ml-auto rtl:mr-auto">
                <div>
                    <app-un-save-button
                        [text]="'welcome_pop_up.save_button' | translate"
                        (click)="setUserTags()"
                    ></app-un-save-button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
