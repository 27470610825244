<ng-container *ngIf="!isButton">
    <div *ngIf="!isLoading" class="generate-wrapper">
        <img class="mb-2" src="/assets/uni-1.png" alt="" style="max-width: 100px" />

        <div class="generate-wrapper-text mb-3">
            <span>Our AI can generate teams for your school.</span>
            <span>To generate team, click button below:</span>
        </div>

        <app-un-save-button
            class="mx-auto"
            [text]="'generate teams'"
            (click)="openDialog(generateTeamTemplate)"
        ></app-un-save-button>
    </div>

    <ng-container *ngIf="isLoading">
        <app-un-game-loader></app-un-game-loader>

        <div class="generate-wrapper-text mt-5">
            <span>please wait</span>
            <span>command generation in progress</span>
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="isButton">
    <app-un-magic-button
        [fullWidth]="true"
        [bordered]="true"
        [fullWidth]="true"
        [disabled]="isLoading"
        (click)="openDialog(generateTeamTemplate)"
    >
        <div class="flext items-center justify-center">
            <svg
                *ngIf="isLoading"
                class="h-4 w-4 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
            <svg
                *ngIf="!isLoading"
                class="h-4 w-4 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
        </div>
    </app-un-magic-button>
</ng-container>

<ng-template #generateTeamTemplate>
    <ng-container *ngIf="!isTopicsLoading">
        <div class="flex flex-col items-center text-cyan-400">
            <i class="ph ph-faders-horizontal text-6xl"></i>
        </div>
        <div mat-dialog-content style="width: 400px">
            <app-table-form
                #generateTeamModalForm
                [form]="form"
                [formElements]="form.form.elements"
                (onSubmit)="generate($event)"
            ></app-table-form>
        </div>
        <div mat-dialog-actions class="row justify-between">
            <div class="col-auto"><app-un-back-button (сlicked)="closeDialog()"></app-un-back-button></div>
            <div class="col-auto flex justify-between gap-x-3">
                <app-un-save-button
                    [text]="'communications.info.submit' | translate"
                    (сlicked)="generate(generateTeamModalForm.getValue())"
                    [disabled]="!generateTeamModalForm.isValid()"
                ></app-un-save-button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isTopicsLoading">
        <div class="flex h-40 w-96 items-center justify-center">
            <app-un-game-loader></app-un-game-loader>
        </div>
    </ng-container>
</ng-template>
