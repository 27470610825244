import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-un-see-all',
    templateUrl: './un-see-all.component.html',
    styleUrls: ['./un-see-all.component.scss'],
})
export class UnSeeAllComponent {
    @Input() name: string = '';


    constructor() {}


}
