<div [@flyInOut] class="mb-4">
    <app-un-page-sub-panel
        [title]="'studio.tools_block.create_tasks' | translate"
        [backUrl]="'/studio'"
        [onlyBack]="true"
    ></app-un-page-sub-panel>
</div>

<div class="grid grid-cols-12 gap-x-4 lg:gap-x-6 game-details">
    <div class="col-span-12 lg:col-span-8 order-2 lg:order-1">
        <div class="mx-auto grid-system-container-unity aspect-16">
            <ng-container *ngIf="!isReady">
                <div class="loader-wrapper aspect-16">
                    <div class="progress" style="width: 400px">
                        <div
                            class="progress-bar"
                            style="background-color: #ff02d0"
                            role="progressbar"
                            [style.width.%]="progress * 100"
                            [attr.aria-valuenow]="progress * 100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                    </div>
                </div>
            </ng-container>

            <div [ngStyle]="{ display: isReady ? 'block' : 'none' }">
                <div #ucontainer id="canvas" class="unity-desktop aspect-16">
                    <canvas #ucanvas id="unity-canvas" class="aspect-16"></canvas>
                    <div #uloading id="unity-loading-bar">
                        <div id="unity-logo"></div>
                        <div id="unity-progress-bar-empty">
                            <div #uprogress id="unity-progress-bar-full"></div>
                        </div>
                    </div>
                    <div id="unity-warning"></div>
                    <div id="unity-footer">
                        <div id="unity-webgl-logo"></div>
                        <div #ufullscreen id="unity-fullscreen-button"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [@flyRigthIn] class="col-span-12 lg:col-span-4 order-1 lg:order-2 flex flex-col mb-3">
        <button
            matRipple
            class="text-uppercase font-weight-bold mb-3 bg-[#ff02d0] text-base tracking-[2px] border-none text-[#e1def5] rounded-lg py-2 px-4"
            (click)="setFullScreen()"
        >
            {{ 'studio.manage.fullscreen' | translate }}
        </button>

        <div class="flex justify-between py-2 border-y text-xs text-[#9d97c3] border-[#615b8e]">
            <div><span>Platform</span></div>
            <div>
                <span><i class="ph-google-chrome-logo"></i></span>
            </div>
        </div>
    </div>
</div>
