import { Component, Input } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { UsersService } from 'src/app/services/users.service';
import { Tools } from 'src/app/tools';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-un-lang-switch',
    templateUrl: './un-lang-switch.component.html',
    styleUrls: ['./un-lang-switch.component.scss'],
})
export class UnLangSwitchComponent {
    isHebrew = false;

    storageUrl: string = environment.storageUrl;

    constructor(
        private sessionService: SessionService,
        private usersService: UsersService,
    ) {}

    ngOnInit() {
        this.isHebrew = this.sessionService.getLanguage() !== environment.defaultLanguage;
    }

    changeLang(): void {
        localStorage.setItem('language', this.isHebrew ? 'heb' : environment.defaultLanguage);
        this.updateUser();
    }

    updateUser() {
        this.usersService
            .updateCurrentUser(Tools.getFormData({ language: this.isHebrew ? 'heb' : 'en' }))
            .subscribe(({ data }: any) => {
                if (data?.school) {
                    this.sessionService.setCurrentUserSchool(data.school);
                }

                this.sessionService.refreshPage();
            });
    }

    get isAbleChangeLang(): boolean {
        return this.sessionService.getCurrentUserSchool()?.is_change_language;
    }
}
