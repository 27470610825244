import { config } from './editorConfig';

const backendUrl = 'https://portal.staging.unispher.com/api';

export const environment = {
    production: true,
    backendUrl: backendUrl,
    apiUrl: backendUrl + '/api',
    chatUrl: 'https://chat.unispher.com/',
    storageUrl: 'https://portal.staging.unispher.com' + '/bucket/',
    gameUrl: 'https://portal.staging.unispher.com/games/space-game/',
    unispherToolUrl: 'https://portal.staging.unispher.com/games/UnispherTools/',
    serverGamesFolder: 'https://portal.staging.unispher.com/games/',
    version: '0.1.1',
    questionnaireUrl: '/questionnaire',
    surveysUrl: '/surveys',
    defaultLanguage: 'en',
    socketUrl: { url: 'portal.staging.unispher.com', options: { path: '/socket.io' } },
};

export const settings = {
    title: 'T Unispher | ',
    theme: () => {
        const ls = localStorage.getItem('theme');
        return ls ? ls : 'dark';
    },
    setTheme: (theme: any) => {
        localStorage.setItem('theme', theme);
    },
    themes: ['dark', 'light'],
    tooltipOptions: {
        placement: 'top',
        'tooltip-class': 'tooltip',
        theme: 'light',
    },
    editorConfig: config,
    highcharts: {
        print: {
            chart: {
                events: {
                    beforePrint: function () {
                        // this.reflow();
                    },
                    afterPrint: function () {
                        // this.reflow();
                    },
                    load: function () {
                        const beforePrint = () => {
                            // this.reflow();
                        };
                        const afterPrint = () => {
                            // this.reflow();
                        };
                        if (window.matchMedia) {
                            const mediaQueryList = window.matchMedia('print');
                            mediaQueryList.addListener((mql) => {
                                mql.matches ? beforePrint() : afterPrint();
                            });
                        } else {
                            window.addEventListener('onbeforeprint', beforePrint);
                            window.addEventListener('onafterprint', afterPrint);
                        }
                    },
                },
            },
        },
        colors: {
            light: {
                theme: {
                    colors: [
                        '#69c',
                        '#444444',
                        '#FE6763',
                        '#4FD1C5',
                        '#F6E05E',
                        '#19A188',
                        '#ED8936',
                        '#805AD5',
                        '#ED64A6',
                    ],
                    chart: {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderColor: 'rgba(0, 0, 0, 0)',
                    },
                    title: {
                        style: {
                            color: 'rgba(0,0,0,0.7)',
                            font: 'bold 14px "FC Sans", sans-serif',
                        },
                    },
                },
                accent: '',
                warn: '',
                green: '#64E9B9',
                yellow: '#E9CE63',
                orange: '#FEA26E',
                red: '#FE6B72',
                blue: '#007bff',
                purple: '#6f42c1',
            },
            dark: {
                theme: {
                    colors: [
                        '#69c',
                        '#909090',
                        '#FE6763',
                        '#4FD1C5',
                        '#F6E05E',
                        '#19A188',
                        '#ED8936',
                        '#805AD5',
                        '#ED64A6',
                    ],
                    chart: {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderColor: 'rgba(0, 0, 0, 0)',
                    },
                    title: {
                        style: {
                            color: 'rgba(255,255,255,0.7)',
                            font: 'bold 14px "FC Sans", sans-serif',
                        },
                    },
                    legend: {
                        itemStyle: {
                            color: 'rgba(255,255,255,0.7)',
                            font: '14px "FC Sans", sans-serif',
                        },
                        itemHoverStyle: {
                            color: '#ffffff',
                        },
                        itemHiddenStyle: {
                            color: '#777777',
                        },
                    },
                },
                accent: '',
                warn: '',
                green: '#64E9B9',
                yellow: '#E9CE63',
                orange: '#FEA26E',
                red: '#FE6B72',
                blue: '#007bff',
                purple: '#6f42c1',
            },
        },
        timeZone: {
            time: {
                timezone: 'Europe/Kiev',
            },
        },
        global: {
            lang: {
                decimalPoint: ',',
                thousandsSep: '',
                loading: 'Data loading...',
                months: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                ],
                weekdays: ['Sunday ', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                exportButtonTitle: 'Export',
                printButtonTitle: 'Print',
                rangeSelectorFrom: 'From',
                rangeSelectorTo: 'To',
                rangeSelectorZoom: 'Zoom',
                downloadPNG: 'Download as PNG',
                downloadJPEG: 'Download as JPEG',
                downloadPDF: 'Download als PDF',
                downloadSVG: 'Download as SVG',
                resetZoom: 'Reset zoom',
                resetZoomTitle: 'Reset zoom title',
            },
        },
    },
};
