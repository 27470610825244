<div *ngIf="card" class="com-info-card cursor-pointer game-card">
    <div class="mb-3">
        <div class="name">
            <h6 style="font-weight: 900; font-size: 0.7em">{{ card.name }}</h6>
        </div>

        <div class="name" style="margin-top: -12px">
            <span style="opacity: 0.35; font-size: 0.65em">{{ 'studio.skill_card.platform_level' | translate }}</span>
        </div>
    </div>

    <div class="mb-4">
        <div class="flex justify-between" style="font-size: 0.65em; opacity: 0.8">
            <span>{{ 'studio.skill_card.progress' | translate }}</span>
            <span>{{ card.progress }}%</span>
        </div>

        <div class="mb-2">
            <div class="progress">
                <span class="progress-bar" style="width: 75%"></span>
            </div>
        </div>
    </div>

    <div class="flex justify-between" style="font-size: 0.65em">
        <span>
            <i class="fas fa-clock mr-1"></i>
            <span style="opacity: 0.8">{{ 'studio.skill_card.last_modified' | translate }} {{ card.modified_at }}</span>
        </span>
    </div>
</div>
