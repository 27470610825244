import { environment } from 'src/environments/environment';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { UserSubjectInterestsChart } from 'src/app/charts/UserSubjectInterestsChart';
import { UserGroupSubjectInterestsChart } from 'src/app/charts/UserGroupSubjectInterestsChart';

enum Langs {
    Eng,
    Heb,
}

@Component({
    selector: 'app-user-experience-skills',
    templateUrl: './user-experience-skills.component.html',
    styleUrls: ['./user-experience-skills.component.scss'],
    providers: [],
})
export class UserExperienceSkillsComponent implements OnInit {
    isLoading: boolean = false;
    user: any | undefined;

    colors = ['#FF02D0', '#FF9900', '#00A3FF', '#7B61FF', '#B6A8FF', '#00A3FF', '#FF02D0', '#FF9900'];

    userTags: any[] = [
        {
            color: 'red',
            name: '#Leader',
        },
        {
            color: 'green',
            name: '#Games',
        },
        {
            color: 'orange',
            name: '#Teamwork',
        },
        {
            color: 'green',
            name: '#Creativity',
        },
        {
            color: 'red',
            name: '#Communication',
        },
    ];

    categories = {
        eng: ['Biology', 'Chemistry', 'Physics', 'History', 'Art', 'Literature', 'Tanah', 'Israel culture'],
        heb: ['ביולוגיה', 'כִּימִיָה', 'פיזיקה', 'הִיסטוֹרִיָה', 'אומנות', 'סִפְרוּת', 'תנא', 'תרבות ישראל'],
    };

    chartData = {
        title: 'Skill Breakdown',
        categories: this.categories.eng,
        series: {
            name: '',
            data: [2, 3, 4, 3, 4, 4, 5, 3],
        },
    };

    chartGroupData = {
        title: 'Skill Breakdown by Group',
        categories: this.categories.eng,
        series: [
            {
                type: 'area',
                name: 'Carl',
                data: [2, 3, 4, 3, 4, 4, 5, 3],
                pointPlacement: 'on',
            },
            {
                type: 'area',
                name: 'Peter',
                data: [4, 3, 4, 3, 2, 5, 5, 1],
                pointPlacement: 'on',
            },
            {
                type: 'area',
                name: 'Josh',
                data: [4, 3, 2, 3, 1, 4, 2, 3],
                pointPlacement: 'on',
            },
            {
                type: 'area',
                name: 'Mikel',
                data: [1, 3, 2, 3, 5, 2, 5, 1],
                pointPlacement: 'on',
            },
            {
                type: 'area',
                name: 'Keren',
                data: [5, 4, 3, 5, 4, 4, 1, 2],
                pointPlacement: 'on',
            },
            {
                type: 'area',
                name: 'Obiang',
                data: [2, 2, 4, 5, 2, 3, 3, 3],
                pointPlacement: 'on',
            },
            {
                type: 'area',
                name: 'Samuel',
                data: [1, 4, 2, 2, 5, 2, 5, 1],
                pointPlacement: 'on',
            },
            {
                type: 'area',
                name: 'Marek',
                data: [2, 2, 2, 4, 4, 2, 2, 1],
                pointPlacement: 'on',
            },
        ],
    };

    chart = new UserSubjectInterestsChart();
    groupChart = new UserGroupSubjectInterestsChart();

    isHebrew: boolean = false;

    storageUrl: string = environment.storageUrl;

    constructor(
        private title: Title,
        private userService: UserService,
        private sessionService: SessionService,
        private notificationService: NotificationService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.getUser();
        this.chart.build(this.chartData);
        this.groupChart.build(this.chartGroupData, this.colors);
    }

    getUser() {
        this.user = this.sessionService.getCurrentUser();
    }

    changeLang() {
        this.chartData.categories = this.isHebrew ? this.categories.heb : this.categories.eng;
        this.chartData.title = this.isHebrew ? 'פירוט מיומנויות' : 'Skill Breakdown';
        this.chart.build(this.chartData);

        this.chartGroupData.categories = this.isHebrew ? this.categories.heb : this.categories.eng;
        this.chartGroupData.title = this.isHebrew ? 'פירוט מיומנויות לפי קבוצה' : 'Skill Breakdown by Group';
        this.groupChart.build(this.chartGroupData, this.colors);
    }
}
