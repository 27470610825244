<div class="flex h-full">
    <div class="h-full w-full">
        <app-un-tabs [tabs]="tabs" (tabChanged)="setTab($event)">
            <div header class="flex flex-1 items-start" style="gap: 48px">
                <div class="w-full">
                    <app-un-gradient-text [text]="schoolName" size="big"></app-un-gradient-text>
                </div>
            </div>

            <ng-container body>
                <app-un-tab *ngIf="activeTab.selector === 'edit-surveys'">
                    <div class="my-4">
                        <app-manage-surveys></app-manage-surveys>
                    </div>
                </app-un-tab>

                <app-un-tab *ngIf="activeTab.selector === 'surveys-answers'">
                    <div class="my-4"></div>
                    <app-manage-surveys-answers></app-manage-surveys-answers>
                </app-un-tab>
            </ng-container>
        </app-un-tabs>
    </div>
</div>
