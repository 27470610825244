<div *ngIf="teams.length > 0" class="mt-4 flex flex-col-reverse lg:flex-row" style="gap: 40px">
    <div class="grid grid-cols-2 gap-3 2xl:grid-cols-3">
        <ng-container *ngFor="let team of teams">
            <app-team-box
                [team]="team"
                (showTeamChartClicked)="getChartData($event)"
                (changeMentorClicked)="openChangeMentorModal($event)"
            ></app-team-box>
        </ng-container>
    </div>

    <div
        *ngIf="teamChartData && !isLoading"
        class="main-card flex flex-col p-3 lg:ml-auto lg:rtl:ml-0 lg:rtl:mr-auto"
        style="height: max-content; min-height: 325px"
    >
        <div class="mb-2 flex items-center">
            <span class="font-weight-bold text-uppercase text-large">
                {{ 'communications.info.disciplines' | translate }}
            </span>
            <i class="ph-info-bold interactive mx-2"></i>

            <div *ngIf="selectedTeamChart" class="flex w-full justify-end self-center">
                <div class="flex flex-col">
                    <div class="flex items-center gap-2">
                        <app-un-avatar
                            [name]="selectedTeamChart.name"
                            [color]="selectedTeamChart.icon_color.class"
                            [icon]="selectedTeamChart.icon_class.class"
                            [image]="selectedTeamChart.image_path"
                        ></app-un-avatar>
                        <span>{{ selectedTeamChart.name }}</span>
                    </div>
                    <div class="flex justify-end" (click)="getAllChartsData()">
                        <app-un-see-all name="All teams"></app-un-see-all>
                    </div>
                </div>
            </div>
        </div>
        <div class="relative flex justify-center self-center" style="max-width: 380px">
            <app-team-chart [chart]="teamChartData"></app-team-chart>
        </div>
    </div>

    <div
        *ngIf="isChartLoading"
        class="main-card ml-auto flex items-center justify-center p-3 rtl:ml-0 rtl:mr-auto"
        style="height: max-content; min-height: 370px; width: 400px"
    >
        <app-un-game-loader message="data loading, please wait patiently shein"></app-un-game-loader>
    </div>
</div>

<!--<div *ngIf="!isLoading && teams.length === 0" class="mt-4 flex h-[75%] items-center justify-center">
    <app-team-generator (teamsGenerated)="fetch()"></app-team-generator>
</div>-->

<div *ngIf="isLoading" class="mt-4 flex items-center justify-center" style="height: 75%">
    <app-un-game-loader message="data loading, please wait"></app-un-game-loader>
</div>

<ng-template #changeMentorTemplate>
    <app-un-change-mentor [users]="mentors" (mentorsChanged)="updateTeamMentors($event)"> </app-un-change-mentor>
</ng-template>
