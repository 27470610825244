import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-game-container',
    templateUrl: './game-container.component.html',
    styleUrls: ['./game-container.component.scss'],
    providers: [],
})
export class GameContainerComponent implements OnInit {
    isLoading: boolean = false;
    subscriptions: Subscription[] = [];

    gameUrl: string = '';

    constructor(private activateRouter: ActivatedRoute) {}

    ngOnInit() {
        this.setGameFromURl();
    }

    setGameFromURl() {
        this.subscriptions.push(
            this.activateRouter.queryParams.subscribe((params) => {
                this.gameUrl = params['src'];
            })
        );
    }
}
