import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../../services/session.service';
import { SurveysService } from '../../../services/surveys.service';
import { NotificationService } from '../../../services/notification.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-surveys',
    templateUrl: './surveys.component.html',
    styleUrls: ['./surveys.component.scss'],
})
export class SurveysComponent implements OnInit {
    eligibleSurveys: any[] = [];
    currentSurveyData: any[] = [];
    currentSurveyIndex: number = 0;
    currentTargetIndex: number = 0;
    surveyQuestions: any[] = [];
    currentTarget: any;
    selectedAnswers: { [questionId: number]: string } = {};
    isLoading: boolean = true;
    hasError: boolean = false;
    showWelcome: boolean = false;
    showEndScreen: boolean = false;
    currentUser: any;
    ableStartPlatform: boolean = false;
    currentState: string = '';

    constructor(
        private surveysService: SurveysService,
        private sessionService: SessionService,
        private notificationService: NotificationService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.currentUser = this.sessionService.getCurrentUser();
        this.fetchEligibleSurveys();
    }

    fetchEligibleSurveys(): void {
        this.isLoading = true;
        this.surveysService.getEligibleSchoolSurveys().subscribe({
            next: (response: any) => {
                this.eligibleSurveys = response.data.surveys || [];
                this.currentState = response.data.state;
                this.isLoading = false;
                
                if (this.eligibleSurveys.length > 0) {
                    console.log('length', this.eligibleSurveys.length);
                    this.showWelcome = true;
                } else {
                    this.checkSurveyCompletion();
                }
            },
            error: (error) => {
                console.error('Error fetching eligible surveys:', error);
                this.isLoading = false;
                this.hasError = true;
                this.notificationService.error('Failed to fetch eligible surveys');
            },
        });
    }

    start(): void {
        if (this.eligibleSurveys.length > 0) {
            this.loadSurveyQuestions(this.currentSurveyIndex);
            this.showWelcome = false;
        }
    }

    loadSurveyQuestions(surveyIndex: number): void {
        this.isLoading = true;
        const surveyId = this.eligibleSurveys[surveyIndex].id;
        this.surveysService.getSurveyQuestionsUser(surveyId).subscribe({
            next: (response: any) => {
                this.currentSurveyData = response.data;
                this.loadNextTarget();
                this.isLoading = false;
            },
            error: (error) => {
                console.error('Error fetching survey questions:', error);
                this.isLoading = false;
                this.hasError = true;
                this.notificationService.error('Failed to fetch survey questions');
            },
        });
    }

    loadNextTarget(): void {
        if (this.currentTargetIndex < this.currentSurveyData.length) {
            this.currentTarget = this.currentSurveyData[this.currentTargetIndex].target;
            this.surveyQuestions = this.currentSurveyData[this.currentTargetIndex].questions;
            this.selectedAnswers = {};
        } else {
            this.moveToNextSurvey();
        }
    }

    setAnswer(questionId: number, answer: number | string): void {
        const stringAnswer = answer.toString();
        this.submitAnswer(questionId, stringAnswer);
    }

    submitAnswer(questionId: number, answer: string): void {
        const question = this.surveyQuestions.find(q => q.id === questionId);
        if (!question) return;

        const answerData = {
            project_id: question.project_id,
            response: answer,
            school_id: question.school_id,
            school_survey_question_id: questionId,
            school_survey_id: question.school_survey_id,
            target_student_id: this.currentTarget.id,
            team_id: this.currentUser.team_id,
            user_id: this.currentUser.id,
        };

        this.surveysService.addSurveyAnswer(answerData).subscribe({
            next: () => {
                this.selectedAnswers[questionId] = answer;
                this.notificationService.success('Answer submitted successfully');
            },
            error: (error) => {
                console.error('Error submitting answer:', error);
                this.notificationService.error('Failed to submit answer. Please try again.');
                // Remove the answer from selectedAnswers if it was previously set
                if (questionId in this.selectedAnswers) {
                    delete this.selectedAnswers[questionId];
                }
            },
        });
    }

    areAllQuestionsAnswered(): boolean {
        return this.surveyQuestions.every((question) => this.selectedAnswers[question.id] !== undefined);
    }

    moveToNextTarget(): void {
        this.currentTargetIndex++;
        this.loadNextTarget();
    }

    moveToNextSurvey(): void {
        this.currentSurveyIndex++;
        this.currentTargetIndex = 0;
        if (this.currentSurveyIndex < this.eligibleSurveys.length) {
            this.loadSurveyQuestions(this.currentSurveyIndex);
        } else {
            this.checkSurveyCompletion();
        }
    }

    checkSurveyCompletion(): void {
        this.isLoading = true;
        this.surveysService.getEligibleSchoolSurveys().subscribe({
            next: (response: any) => {
                this.currentState = response.data.state;
                this.ableStartPlatform = this.currentState === 'platform';
                
                // Update the current user's state in the session
                this.sessionService.setCurrentUser({ ...this.currentUser, state: this.currentState });
                
                if (this.ableStartPlatform) {
                    this.showEndScreen = true;
                    this.notificationService.success('All surveys completed!');
                } else {
                    // If there are new surveys available, refresh the list
                    this.eligibleSurveys = response.data.surveys || [];
                    if (this.eligibleSurveys.length > 0) {
                        this.currentSurveyIndex = 0;
                        this.loadSurveyQuestions(this.currentSurveyIndex);
                    }
                }
                this.isLoading = false;
            },
            error: (error) => {
                console.error('Error checking survey completion:', error);
                this.isLoading = false;
                this.hasError = true;
                this.notificationService.error('Failed to check survey completion');
            },
        });
    }

    proceedToPlatform(): void {
        this.router.navigate(['/home']);
    }
}
