import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { TeamsService } from 'src/app/services/team.service';
import { UsersService } from 'src/app/services/users.service';
import { Tools } from 'src/app/tools';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'src/app/services/session.service';
import { ProjectsService } from '../../../../services/projects.service';
import { finalize } from 'rxjs';

@Component({
    selector: 'app-team-info',
    templateUrl: './team-info.component.html',
    styleUrls: ['./team-info.component.scss'],
})
export class TeamInfoComponent implements OnInit {
    isLoading: boolean = true;

    users: any = [];
    team: any;
    colors = ['#FF02D0', '#FF9900', '#00A3FF', '#7B61FF', '#B6A8FF', '#00A3FF', '#FF02D0', '#FF9900'];
    teamChartData: any;
    skillsChartData: any;
    teamName: string = 'hula team';
    storageUrl: string = environment.storageUrl;
    defaultImageUrl: string = '/assets/blank-profile.png';
    leaderId: number = 0;

    otherStudents: any[] = [];
    otherStudentsTotal = 0;
    icons: any[] = [];
    colorsItems: any[] = [];

    changeStudent: any;

    teamNameControl = new UntypedFormControl('');
    sloganControl = new UntypedFormControl('');

    currentColor = '';
    currentTeamIcon = '';
    currentChartType = 'disciplines';

    uploadHelper: any = {
        file: undefined,
        valid: false,
    };

    allowedExtensions = this.teamsService.getAllowedExtensions();

    isAdmin: boolean = this.session.getCurrentUser().is_admin;

    selectedUser: any;
    searchText = new FormControl('');

    assignedUsers: any[] = [];
    isAssignedUsersLoading: boolean = false;

    constructor(
        private teamsService: TeamsService,
        private usersService: UsersService,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
        private dialog: MatDialog,
        private projectsService: ProjectsService,
        public translate: TranslateService,
        public session: SessionService,
    ) {}

    ngOnInit() {
        this.getColorsAndIcons();
        const teamId = this.route.snapshot.paramMap.get('id');

        if (teamId) {
            this.fetch(teamId);
        }
    }

    fetch(teamId: string) {
        this.getTeam(teamId);
    }

    getOtherStudents(page = 1) {
        const filters = [
            { key: 'role', value: 'student', selected: 'student' },
            { key: 'mentor', value: 'false', selected: 'false' },
            { key: 'project', value: this.team.project_id, selected: this.team.project_id },
        ];

        this.usersService.getUsers(page, 8, filters, this.searchText.value).subscribe(({ data }: any) => {
            this.otherStudentsTotal = data.total;
            this.otherStudents = data.users.map((element: any, index: number) => ({
                ...element,
                color: this.colors[index],
            }));
        });
    }

    searchInput(event: any) {
        event.preventDefault();
        if (event.keyCode === 13) {
            this.getOtherStudents(1);
        }
    }

    otherStudentsPageChange(page) {
        this.getOtherStudents(page);
    }

    getTeam(team: string) {
        this.isLoading = true;
        this.teamsService.getById(parseInt(team)).subscribe({
            next: ({ data }: any) => {
                this.team = data;
                this.sloganControl.setValue(data.slogan || '');
                this.mapColors(data.members.students);
                this.teamChartData = data.chart.disciplines;
                this.skillsChartData = data.chart.skills;
                this.leaderId = data.leader_id;
                this.isLoading = false;
            },
            error: (error) => this.notificationService.error(error.error.message, this.ERROR_TOASTR),
        });
    }

    mapColors(students: any) {
        this.users = students.map((element: any, index: number) => ({ ...element, color: this.colors[index] }));
    }

    getColorsAndIcons() {
        this.teamsService.getTeamColors().subscribe(({ data }: any) => {
            this.icons = data.icons;
            this.colorsItems = data.colors;
        });
    }

    getAssignedUsers() {
        this.isAssignedUsersLoading = true;
        this.projectsService
            .getAssignedUsers(this.team.project_id)
            .pipe(finalize(() => (this.isAssignedUsersLoading = false)))
            .subscribe(({ data }: any) => {
                this.assignedUsers = data || [];
            });
    }

    updateTeamLeader(student: any) {
        const studentId = student.id;
        this.teamsService.update(this.team.id, { leader_id: studentId }).subscribe({
            next: () => {
                this.notificationService.success(this.LEADER_CHANGED_SUCCESS_TOASTR);
                this.fetch(this.team.id);
            },
            error: (error) => this.notificationService.error(error.error.message, this.ERROR_TOASTR),
        });
    }

    updateSlogan() {
        this.teamsService.update(this.team.id, { slogan: this.sloganControl.value }).subscribe({
            next: () => {
                this.notificationService.success(this.LEADER_CHANGED_SUCCESS_TOASTR);
                this.fetch(this.team.id);
            },
            error: (error) => this.notificationService.error(error.error.message, this.ERROR_TOASTR),
        });
    }

    openDialog(dialogRef: TemplateRef<any>, user: any) {
        this.searchText.setValue('');
        this.changeStudent = user;
        this.getOtherStudents();
        this.dialog.open(dialogRef);
    }

    openEditTeamDialog(dialogRef: TemplateRef<any>) {
        if (!this.isAdmin) {
            return;
        }

        this.teamNameControl.setValue(this.team?.name);
        this.currentColor = this.team.icon_color.class;
        this.currentTeamIcon = this.team.icon_class.class;
        this.dialog.open(dialogRef, { width: '600px' });
    }

    setCurrentColor(color: any) {
        this.currentColor = color.class;
    }

    setCurrentIcon(icon: any) {
        this.currentTeamIcon = icon.class;
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    changeStudentFromOtherTeam(newStudent: any) {
        this.teamsService
            .changeStudents(Tools.getFormData({ user1_id: this.changeStudent.id, user2_id: newStudent.id }))
            .subscribe({
                next: () => {
                    const teamId = this.route.snapshot.paramMap.get('id');

                    if (teamId) {
                        this.fetch(teamId);
                    }

                    this.closeDialog();
                    this.notificationService.success(this.STUDENT_CHANGED_SUCCESS_TOASTR);
                },
                error: (error) => this.notificationService.error(error.error.message, this.ERROR_TOASTR),
            });
    }

    saveTeamEdit() {
        this.teamsService
            .update(this.team.id, {
                name: this.teamNameControl.value,
                team_icon_color_id: this.colorsItems.find((color: any) => color.class === this.currentColor).id,
                team_icon_class_id: this.icons.find((icon: any) => icon.class === this.currentTeamIcon).id,
            })
            .subscribe({
                next: () => {
                    this.notificationService.success(this.TEAM_CHANGED_SUCCESS_TOASTR);
                    this.fetch(this.team.id);
                    this.closeDialog();
                },
                error: (error) => this.notificationService.error(error.error.message, this.ERROR_TOASTR),
            });
    }

    toggleChart() {
        this.currentChartType = this.currentChartType === 'skills' ? 'disciplines' : 'skills';
    }

    get ERROR_TOASTR() {
        return this.translate.instant('communications.info.error_toastr');
    }

    get LEADER_CHANGED_SUCCESS_TOASTR() {
        return this.translate.instant('communications.info.leader_changed_success_toastr');
    }

    get STUDENT_CHANGED_SUCCESS_TOASTR() {
        return this.translate.instant('communications.info.student_changed_success_toastr');
    }

    get TEAM_CHANGED_SUCCESS_TOASTR() {
        return this.translate.instant('communications.info.team_changed_success_toastr');
    }

    get IMAGE_CHANGED_SUCCESS_TOASTR() {
        return this.translate.instant('communications.info.team_image_changed_success_toastr');
    }

    openUploadDialog(dialogRef: TemplateRef<any>) {
        this.uploadHelper.file = undefined;
        this.uploadHelper.valid = false;
        this.dialog.open(dialogRef);
    }

    fileChange(fileChangeEvent: any) {
        this.uploadHelper.file = fileChangeEvent.file[0] || undefined;
        this.uploadHelper.valid = fileChangeEvent.valid;
        this.upload();
    }

    upload() {
        const formData = new FormData();

        if (this.uploadHelper.file) {
            formData.append('image_path', this.uploadHelper.file, this.uploadHelper.file.name);
        } else {
            // Upload empty value
            formData.append('image_path', '');
        }

        this.teamsService.update(this.team.id, formData).subscribe({
            next: () => {
                this.notificationService.success(this.IMAGE_CHANGED_SUCCESS_TOASTR);
                this.fetch(this.team.id);
                this.closeDialog();
            },
            error: (error) => this.notificationService.error(error.error.message, this.ERROR_TOASTR),
        });
    }

    deleteTeamImage() {
        this.uploadHelper['file'] = undefined;
        this.teamsService.deleteTeamLogo(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe({
            next: () => {
                this.notificationService.success(this.IMAGE_CHANGED_SUCCESS_TOASTR);
                this.fetch(this.team.id);
                this.closeDialog();
            },
            error: (error) => this.notificationService.error(error.error.message, this.ERROR_TOASTR),
        });
    }

    openMenu(event: any, user: any) {
        this.selectedUser = user;

        const parent = event.target.parentElement.closest('.user-info-row');
        if (parent) {
            const menuButton = parent.getElementsByClassName('menu-trigger');
            if (menuButton) {
                menuButton[0].click();
            }
        }
    }

    openAddStudentModal(dialogRef: TemplateRef<any>) {
        this.getAssignedUsers();
        this.dialog.open(dialogRef);
    }

    addUserToTeam(user) {
        this.teamsService.addUserToTeam(this.team.id, user.id).subscribe({
            next: () => {
                this.fetch(this.team.id);
                this.closeDialog();
            },
            error: (error) => this.notificationService.error(error.error.message, this.ERROR_TOASTR),
        });
    }

    deleteUserFromTeam(user) {
        this.teamsService.deleteUserFromTeam(this.team.id, user.id).subscribe({
            next: () => {
                this.fetch(this.team.id);
                this.closeDialog();
            },
            error: (error) => this.notificationService.error(error.error.message, this.ERROR_TOASTR),
        });
    }

    ableManageStudents(): boolean {
        return (
            this.isAdmin || (this.session.getCurrentUser()?.role === 'Teacher' && this.session.getCurrentUser()?.mentor)
        );
    }
}
