import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

enum ProgressColors {
    red = '#BE9A54',
    yellow = '#BE6969',
    green = '#7CA38B',
}

@Component({
    selector: 'app-create-task',
    templateUrl: './create-task.component.html',
    styleUrls: ['./create-task.component.scss'],
})
export class CreateTaskComponent {
    date: string = moment().format('DD MMM YYYY');

    isLoading: boolean = true;

    @Input() card: any;
    @Input() displayTags: boolean = false;
    @Input() isFooter: boolean = true;

    teamUsers: any[] = [
        {
            id: 1,
            name: 'Jean-Claude Van Damme',
            color: '#fff',
            position: 'programmer',
        },
        {
            id: 2,
            name: 'Quentin Tarantino',
            color: '#84D2FF',
            position: 'scenarist',
        },
        {
            id: 3,
            name: 'Karl Marx',
            color: '#B6A8FF',
            position: 'designer',
        },
        {
            id: 4,
            name: 'Quentin Tarantino',
            color: '#73FF92',
            position: 'programmer',
        },
    ];

    getColorByValue(value: number) {
        let color = '';

        if (value >= 0) {
            color = ProgressColors.red;
        }
        if (value >= 50) {
            color = ProgressColors.yellow;
        }
        if (value > 75) {
            color = ProgressColors.green;
        }

        return color;
    }
}
