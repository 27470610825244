import { settings } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { UserService } from 'src/app/services/user.service';
import { LoginService } from 'src/app/services/login.service';
import { finalize } from 'rxjs/operators';
import { StudentsTable } from 'src/app/tables/StudentsTable';
import { UsersService } from 'src/app/services/users.service';
import { Tab, Tabsable } from 'src/app/classes/Tabsable';
import { TeachersTable } from 'src/app/tables/TeachersTable';
import { FcbDataFilter } from 'src/app/ui/table/table.component';
import { Tools } from 'src/app/tools';
import { UsersTable } from 'src/app/tables/UsersTable';
import { Paginable } from 'src/app/classes/Paginable';
import { DataFilter } from 'src/app/ui/table/table-filters/table-filters.component';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css'],
})
export class UsersComponent extends Paginable implements OnInit {
    users: any[] = [];

    search: string = '';

    tabs: Tab[] = [
        {
            name: 'Students',
            active: false,
            selector: 'students',
            visible: true,
        },
        {
            name: 'Teachers',
            active: false,
            selector: 'teachers',
            visible: true,
        },
    ];

    filters: DataFilter[] = [
        {
            key: 'mentor',
            label: 'User type',
            options: [
                { name: '-', value: null },
                { name: 'mentor', value: true },
                { name: 'not mentor', value: false },
            ],
            optionTextSelector: 'name',
            optionValueSelector: 'value',
        },
    ];

    selectedFilters = [];

    usersTable = new UsersTable();

    isLoading: boolean = false;

    dynamicFiltersInited: boolean = false;

    constructor(
        private title: Title,
        private userService: UserService,
        private usersService: UsersService,
        private notificationService: NotificationService,
        private loginService: LoginService,
        public sessionService: SessionService,
    ) {
        super();
    }

    ngOnInit() {
        this.title.setTitle(settings.title + 'Users');
        this.fetch();
    }

    fetch() {
        this.usersService.getUsers(this.page, this.limit, this.filters, this.search).subscribe(({ data }: any) => {
            this.users = data.users || [];
            this.totalItems = data.total;
            this.setDynamicFilters(data.projects, data.roles);
            this.usersTable.buildForm(data.projects);
        });
    }

    setDynamicFilters(projects, roles) {
        if (this.dynamicFiltersInited) {
            return;
        }

        this.filters.push(
            {
                key: 'role',
                label: 'Role',
                options: roles,
                optionTextSelector: 'label',
                optionValueSelector: 'name',
                optionEmpty: true,
                optionEmptyText: '-',
                optionEmptyValue: null,
            },
            {
                key: 'project',
                label: 'Project',
                class: 'w-48',
                options: projects,
                optionTextSelector: 'title',
                optionValueSelector: 'id',
                optionEmpty: true,
                optionEmptyText: '-',
                optionEmptyValue: null,
            },
            {
                key: 'questionnaire',
                type: 'checkbox',
                label: 'Questionnaire',
                options: [
                    { name: 'done', value: true },
                    { name: 'not done', value: false },
                ],
                optionTextSelector: 'name',
                optionValueSelector: 'value',
            },
        );

        this.dynamicFiltersInited = true;
    }

    filter(filters: any) {
        this.selectedFilters = filters;
        this.fetch();
    }

    onSearch(search: string) {
        this.search = search;
        this.fetch();
    }

    register(user: any) {
        this.loginService
            .register(user)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success('User registered successfully');
                    this.fetch();
                },
                error: (err) => this.notificationService.error(err.error.message, 'Error'),
            });
    }

    create(user: any) {
        this.loginService
            .register(user)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success('User registered successfully');
                    this.fetch();
                },
                error: (err) => this.notificationService.error(err.error.message, 'Error'),
            });
    }

    update(user: any) {
        this.userService
            .updateUser(user.id, user)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success('User updated successfully');
                    this.fetch();
                },
                error: (err) => this.notificationService.error(err.error.message, 'Error'),
            });
    }

    delete(user: any) {
        this.userService
            .deleteUser(user.id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success('User deleted successfully');
                    this.fetch();
                },
                error: (err) => this.notificationService.error(err.error.message, 'Error'),
            });
    }
}
