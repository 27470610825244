import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-question',
    templateUrl: './dynamic-form-question.component.html',
    styleUrls: ['./dynamic-form-question.component.scss'],
})
export class DynamicFormQuestionComponent {
    @Input() question: any;
    @Input() form!: UntypedFormGroup;

    @Output() questionUpdated: EventEmitter<any> = new EventEmitter<any>();

    textAreaLimit: number = 300;
    textInputLimit: number = 200;

    options: any[] = [];

    ngOnInit() {
        this.options = this.question.answers;
    }

    setFormValue(value: any) {
        this.form.controls[this.question.id].setValue(value);
    }

    // get isValid() {
    //     return this.form.controls[this.question.id].valid;
    // }
}
