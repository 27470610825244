<div class="user-dashboard main-card" [class.open]="display">
    <div class="px-3 py-1 flex items-center gap-x-3">
        <div class="flex">
            <div
                class="profile-image-q w-11 h-11"
                [ngStyle]="{ background: 'url(' + (storageUrl + user.main_image) + ')' }"
            ></div>
        </div>
        <div>
            <div>
                <span class="name text-sm">{{ user?.nickname }}</span>
            </div>
            <div class="student-level-bage">
                <i class="ph-mountains text-xs"></i>
                <span class="position text-xs">Middle level</span>
            </div>
        </div>
        <!-- <div class="col-auto ml-auto">
            <div class="w-full mb-3">
                <app-un-magic-button [text]="'ui.dashboard.my_skills' | translate" [fullWidth]="true"
                    (click)="openDialog(skillsModalContent, true)" [selected]="skillsSelected">
                </app-un-magic-button>
            </div>
            <div>
                <app-un-magic-button (click)="openDialog(projectModalContent)" [text]="'ui.dashboard.all_projects' | translate"
                    [selected]="projectsSelected">
                </app-un-magic-button>
            </div>
        </div> -->
    </div>
</div>

<ng-template #skillsModalContent>
    <div class="flex">
        <div class="col-auto ml-auto" mat-dialog-close><i class="fas fa-times fa-lg"></i></div>
    </div>

    <div mat-dialog-content style="width: 1000px; border-radius: 12px; padding-bottom: 20px">
        <div class="flex items-center mt-3 mb-3" style="padding-left: 80px">
            <div class="col-auto">
                <div>
                    <h1 class="poppins-bold">LEVEL NOW—</h1>
                    <h1 class="poppins-light">Middle</h1>
                </div>
            </div>
        </div>

        <div class="flex mt-3">
            <div class="col-4">
                <div class="mb-5 flex justify-center">
                    <app-un-skill-card [card]="card"></app-un-skill-card>
                </div>
                <div class="mb-5 flex justify-center">
                    <app-un-skill-card [card]="card"></app-un-skill-card>
                </div>
            </div>

            <div class="col-4 text-center mt-5">
                <div class="mb-2">
                    <app-index-radial [inputIndex]="65"></app-index-radial>
                </div>
                <div>
                    <h4>Personal</h4>
                    <h4>Platform level</h4>
                </div>
            </div>

            <div class="col-4">
                <div class="mb-5 flex justify-center">
                    <app-un-skill-card [card]="card"></app-un-skill-card>
                </div>
                <div class="mb-5 flex justify-center">
                    <app-un-skill-card [card]="card"></app-un-skill-card>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #projectModalContent>
    <div class="flex">
        <div class="col-auto ml-auto" mat-dialog-close><i class="fas fa-times fa-lg"></i></div>
    </div>

    <div
        mat-dialog-content
        style="width: 1000px; border-radius: 12px; padding-bottom: 20px; height: 44vh; min-height: 400px"
    >
        <ng-container>
            <div class="flex justify-center mt-3" style="gap: 20px">
                <ng-container *ngFor="let level of projects">
                    <app-dashboard-level-view
                        *ngIf="isDisplayLevel(level)"
                        [level]="level"
                        style="width: 20%"
                        [ngStyle]="{ width: !selectedLevel ? '20%' : '90%' }"
                        (selected)="setSelectedLevel($event)"
                        style="transition: width 0.25s ease-out"
                    >
                    </app-dashboard-level-view>

                    <!-- <div class="project-item p-4" [class.recommended]="item.recommended">

                    <div *ngIf="item.recommended" class="recommended-title">
                        <span class="roboto fw-700" style="font-size: 0.85em;">Recommended</span>
                    </div>

                    <div class="text-center mt-2 mb-2">
                        <span class="roboto fw-700"><b>{{ item.name }}</b></span>
                    </div>

                    <div class="text-center mb-2">
                        <div class="flex justify-center items-center" style="height: 85px;">
                            <div class="dot" [style.width.px]="item.width">
                                <img [src]="item.icon" style="width: 100%;height: auto;">
                            </div>
                        </div>
                    </div>

                    <div class="text-center mb-2">
                        <span class="roboto fw-700" style="font-size: 0.75em;"><b>Difficulty</b></span>
                    </div>

                    <div class="text-center mb-2">

                        <ng-container *ngFor="let star of stars; let i = index">

                            <ng-container *ngIf="item.difficulty >= i + 1">
                                <i class="fas fa-star mr-1"></i>
                            </ng-container>

                            <ng-container *ngIf="item.difficulty < i + 1">
                                <i class="far fa-star mr-1"></i>
                            </ng-container>

                        </ng-container>

                    </div>

                    <div class="text-center mb-2">
                        <span class="roboto fw-700" style="font-size: 0.75em;"><b>Features</b></span>
                    </div>

                    <div class="mx-auto text-nowrap mb-3">
                        <ng-container *ngFor="let feature of item.features">
                            <span class="roboto" style="font-size: 0.75em;">✓ {{ feature }}</span><br>
                        </ng-container>
                    </div>


                    <div class="flex">
                        <div class="col-6">
                            <button matRipple (click)="openDescription(item)">More</button>
                        </div>
                        <div class="col-6">
                            <button matRipple>Buy</button>
                        </div>
                    </div>

                </div> -->
                </ng-container>
            </div>
        </ng-container>

        <!-- <ng-container *ngIf="selectedLevel">
            {{ selectedLevel.name }}
        </ng-container> -->
    </div>
</ng-template>
