import { settings, environment } from 'src/environments/environment';
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { TeamsService } from 'src/app/services/team.service';
import { SessionService } from 'src/app/services/session.service';
import { SchoolsService } from 'src/app/services/schools.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-school-squad',
    templateUrl: './school-squad.component.html',
    styleUrls: ['./school-squad.component.scss'],
    providers: [],
})
export class SchoolSquadComponent implements OnInit {
    isLoading: boolean = false;

    teams: any[] = [
        // {
        //   id: 1,
        //   name: 'Team Qwerty',
        //   image: '/assets/communications/school/soc.jpg',
        //   role: 'user',
        //   desc: 'Project Hula'
        // },
        // {
        //   id: 2,
        //   name: 'Dream Team',
        //   image: '/assets/communications/school/com.jpg',
        //   role: 'user',
        //   desc: 'Project Hula'
        // },
        // {
        //   id: 3,
        //   name: 'Team 123',
        //   image: '/assets/communications/school/fam.jpg',
        //   role: 'user',
        //   desc: 'Project Jerusalem'
        // },
        // {
        //   id: 4,
        //   name: 'Team school',
        //   image: '/assets/communications/school/turtules.jpg',
        //   role: 'user',
        //   desc: 'Project Jerusalem'
        // },
        // {
        //   id: 5,
        //   name: 'Team school 2',
        //   image: '/assets/communications/school/turtules.jpg',
        //   role: 'user',
        //   desc: 'Project Hula'
        // }
    ];

    users = new UntypedFormControl();

    usersList: string[] = ['Alex', 'Josh', 'Leon', 'Pedro'];

    @Output() teamSelected: EventEmitter<any> = new EventEmitter();

    storageUrl: string = environment.storageUrl;

    dummyImage =
        'https://w7.pngwing.com/pngs/509/744/png-transparent-computer-icons-user-people-icon-miscellaneous-social-group-avatar-thumbnail.png';

    constructor(
        public dialog: MatDialog,
        private schoolsService: SchoolsService,
        private notificationService: NotificationService,
        private sessionService: SessionService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        const user = this.sessionService.getCurrentUser();
        this.schoolsService.getSchoolTeams(user.school_id).subscribe((response: any) => {
            this.teams = this.mapImages(response.data);
        });
    }

    mapImages(data: any) {
        const newArray: any = [];

        data.forEach((item: any) => {
            item.image_path = item.image_path === null ? this.dummyImage : this.storageUrl + '/' + item.image_path;
            newArray.push(item);
        });

        return newArray;
    }

    openModal(dialogRef: TemplateRef<any>) {
        this.users.reset();
        this.dialog.open(dialogRef, { autoFocus: false });
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    addTeammate() {
        this.isLoading = true;

        const index = this.usersList.findIndex((name: string) => name === this.users.value);
        delete this.usersList[index];
        this.usersList = this.usersList.filter((n) => n);

        this.closeDialog();

        setTimeout(() => {
            this.teams.push(this.getFakeUser(this.users.value));
            this.isLoading = false;
            this.notificationService.success(this.TEAMMATE_TOASTR + this.users.value + this.ADD_SUCCESS_TOASTR);
            this.users.reset();
        }, 400);
    }

    getFakeUser(name: any) {
        return {
            id: this.teams.length + 1,
            name: name,
            image: '/assets/rust.png',
            role: 'user',
        };
    }

    selectTeam(team: any) {
        this.teamSelected.emit(team);
    }

    get TEAMMATE_TOASTR() {
        return this.translate.instant('communications.section.teammate_toastr');
    }

    get ADD_SUCCESS_TOASTR() {
        return this.translate.instant('communications.section.add_success_toastr');
    }
}
