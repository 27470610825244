import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FriendsService } from 'src/app/services/friends.service';
import { SessionService } from 'src/app/services/session.service';
import { Tools } from 'src/app/tools';
import { environment } from 'src/environments/environment';


type Friend = {
  name?: string;
  image_path: string;
  nickname: string;
  main_image: string;
  messages: number | null,
  online: boolean;
}

@Component({
  selector: 'app-friends-list',
  templateUrl: './friends-list.component.html',
  styleUrls: ['./friends-list.component.scss']
})
export class FriendsListComponent {

  @Input() outer: boolean = true;
  @Output() userClicked: EventEmitter<any> = new EventEmitter();


  storageUrl: string = environment.storageUrl;

  friends: Friend[] = [];

  filteredFriends: any[] = [];

  blankImageProfilePath: string = '/assets/blank-profile.png';
  chatUrl: string = environment.chatUrl;
  chatFunc: string = 'direct/';

  subscriptions: Subscription[] = [];


  constructor(
    public session: SessionService,
    private friendsService: FriendsService
  ) {
    this.subscriptions.push(
      this.friendsService.searchFriends$.subscribe((search: string) => {
        this.search(search);
      })
    );
  }


  ngOnInit() {
    this.fetch();
  }


  fetch() {
    this.friendsService.getAll().subscribe((response: any) => {
      this.friends = this.mapData(response.data);
      this.filteredFriends = Tools.cloneObject(this.friends);
    });
  }

  get onlineCount(): number {
    return this.filteredFriends.filter((friend: Friend) => friend.online).length;
  }

  get offlineCount(): number {
    return this.filteredFriends.filter((friend: Friend) => !friend.online).length;
  }

  mapData(data: any) {
    return data.map((friend: Friend) => ({ ...friend, image_path: this.getImagePath(friend) }))
  }

  getImagePath(friend: Friend): string {
    return friend.main_image ? this.storageUrl + friend.main_image : this.blankImageProfilePath;
  }


  onKey(event: any) {
    this.filteredFriends = this._filterUsers(event.target.value).filter(n => n);
  }

  goToChat(nickname: string) {
    if (this.outer) {
      window.open(this.chatUrl + this.chatFunc + nickname, '_blank');
      return;
    }

    this.userClicked.emit(nickname);
  }

  private _filterUsers(value: string): any[] {
    const list: any[] = [];
    const input = value ? value.toLowerCase() : '';
    this.friends.forEach((element, key) => {
      if (!input || (input && this.friends[key].nickname.toLowerCase().indexOf(input) > -1)) {
        list[key] = this.friends[key];
      }
    });
    return list;
  }

  search(search: string): void {
    this.filteredFriends = this._filterUsers(search).filter(n => n);
  }

}
