import { forkJoin, Observable, Subscription, from } from 'rxjs';
import * as moment from 'moment';

/**
 * contains a list of loaded scripts
 */
export const LOADED_SCRIPTS: string[] = [];

/**
 * a collection of some common and static
 * methods for usage in different contextes
 */
export class Tools {
    /**
     * convert a object of objects to an array of objects
     * @param object object of objects
     */
    static objectToArray(object: any): any[] {
        let arr = [];
        if (!Array.isArray(object)) {
            for (const key in object) {
                if (object.hasOwnProperty(key)) {
                    arr.push(object[key]);
                }
            }
        } else {
            arr = [...object];
        }
        return arr;
    }

    /**
     * clone object (copy without reference)
     * @param object any data object
     */
    static cloneObject(object: any): any {
        return JSON.parse(JSON.stringify(object));
    }

    /**
     * return the value of a property or default value if property doesn't exist
     * @param object any object
     * @param propertyName any property name string
     * @param defaultValue any default value
     */
    static getProperty(object: any, propertyName: string, defaultValue?: any) {
        if (object && propertyName in object) {
            return object[propertyName];
        }
        return defaultValue;
    }

    static groupBy(arr: any[], key: string) {
        return Object.values(
            arr.reduce(function (groups, item) {
                const val = item[key];
                groups[val] = groups[val] || [];
                groups[val].push(item);
                return groups;
            }, {})
        );
    }

    /**
     * returns a url string with appended params
     * @param url eg. https://www.google.com/index.php?abc=123
     * @param params e.g. [{key: 'test', value: 'hallo'}]
     */
    static appendUrlParams(url: string, params: { key: string; value: string | number }[]): string {
        const _url: URL = new URL(url);
        const _usp: URLSearchParams = new URLSearchParams(_url.search);
        params.forEach((param: any) => {
            _usp.set(param.key, param.value);
        });
        return _url.toString().replace(_url.search, '') + '?' + _usp.toString();
    }

    /**
     * unsubscribe from a list of subscriptions
     * @param subscriptions
     */
    static unsubscribeAll(subscriptions: Subscription[]) {
        subscriptions.forEach((subsciption) => {
            subsciption.unsubscribe();
        });
    }

    /**
     * loads scripts
     * @param scripts
     */
    static loadScripts(...scripts: string[]): Observable<any> {
        const observables: Observable<any>[] = [];
        scripts.forEach((script) => {
            observables.push(
                Observable.create((observer: any) => {
                    if (!(LOADED_SCRIPTS.join(' ').indexOf(script) > -1)) {
                        // create element
                        const elem: HTMLScriptElement = document.createElement('script');
                        elem.type = 'text/javascript';
                        elem.src = script;
                        elem.onload = () => {
                            LOADED_SCRIPTS.push(script);
                            observer.next({ loaded: true });
                            observer.complete();
                        };
                        elem.onerror = (error: any) => {
                            observer.error({ loaded: false, error: error });
                        };

                        // add element to body
                        document.getElementsByTagName('head')[0].appendChild(elem);
                    } else {
                        // already loaded
                        observer.next({ loaded: true });
                        observer.complete();
                    }
                })
            );
        });
        return forkJoin(observables);
    }

    /**
     * new lines 2 line break
     * @param text
     */
    static nl2br(text: string) {
        return text ? text.replace(/(?:\r\n|\r|\n)/g, '<br>') : '';
    }

    /**
     * check is empty object
     * @param text
     */
    static isEmptyObject(obj: any) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    /**
     * show n signs after comma
     * @param value
     * @param precision
     */

    static round(value: number, precision: number = 0): number {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    static getFormData(data: any) {
        const formData = new FormData();

        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const element = data[key];

                //checkbox can be false
                if (element || element === false) {
                    if (key === 'image') {
                        formData.append('image', element, element.name);
                    } else {
                        formData.append(key, element);
                    }
                }
            }
        }

        return formData;
    }

    // static getIconByPath(path: string, large?: boolean) {
    //   return (new IconByPath).get(path, large);
    // }

    /**
     * get random int for debug
     * @param min
     * @param max
     */
    static getRandomInt(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    /**
     * return given string without html tags
     * @param string
     */
    static removeTagsFromString(string: string | null | undefined) {
        if (!string) {
            return '';
        }
        return string.replace(/<[^>]*>/g, '');
    }

    /**
     * return time left
     * @param date
     */
    static getTaskTimeLeft(date: string) {
        const currentDay = moment();
        const deadline = moment(date);

        const diff = moment.duration(deadline.diff(currentDay));

        const months = Math.floor(diff.asMonths());
        const weeks = Math.floor(diff.asWeeks());
        const days = Math.floor(diff.asDays());
        const hours = Math.floor(diff.asHours());

        const timeLeft = this.getTimeLeft(months, weeks, days, hours);

        if (timeLeft.value < 0) {
            return {
                time: '',
                units: 'expired',
            };
        }

        return {
            time: timeLeft.value,
            units: timeLeft.value > 1 ? timeLeft.name + 's' : timeLeft.name,
        };
    }

    static getTimeLeft(months: any, weeks: any, days: any, hours: any) {
        const timeLeft = {
            value: 0,
            name: 'hours',
        };

        if (hours !== 0) {
            timeLeft.value = hours;
            timeLeft.name = 'hour';
        }

        if (days !== 0) {
            timeLeft.value = days;
            timeLeft.name = 'day';
        }

        if (weeks !== 0) {
            timeLeft.value = weeks;
            timeLeft.name = 'week';
        }

        if (months !== 0) {
            timeLeft.value = months;
            timeLeft.name = 'month';
        }

        return timeLeft;
    }
}
