import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { animateOpacity, flyInOut, flyleftIn, flyRigthIn } from 'src/app/animations/fly-animations';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { TagsService } from 'src/app/services/tags.service';
import { UserTagsService } from 'src/app/services/user-tags.service';

type Methodology = {
    name: string;
    steps: any;
    selected?: boolean;
};

@Component({
    selector: 'app-welcome-student-dialog',
    templateUrl: './welcome-student-dialog.component.html',
    styleUrls: ['./welcome-student-dialog.component.scss'],
    animations: [flyleftIn, flyRigthIn, flyInOut, animateOpacity],
})
export class WelcomeStudentDialogComponent {
    pages = [true, false, false];
    availableTags: any[] = [];

    userTags: any[] = [];
    projectTitle: string = '';

    methodologies: Methodology[] = [
        {
            name: this.translate.instant('welcome_pop_up.methodology.for_game'),
            steps: [
                this.translate.instant('welcome_pop_up.methodology.team_building_guide'),
                this.translate.instant('welcome_pop_up.methodology.game_idea_guide'),
                this.translate.instant('welcome_pop_up.methodology.game_scenario_guide'),
                this.translate.instant('welcome_pop_up.methodology.game_detail_guide'),
                this.translate.instant('welcome_pop_up.methodology.game_presentation_guide'),
            ],
            selected: true,
        },
        {
            name: this.translate.instant('welcome_pop_up.methodology.for_start_up'),
            steps: [],
        },
        {
            name: this.translate.instant('welcome_pop_up.methodology.for_video'),
            steps: [],
        },
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        public sessionService: SessionService,
        private notificationService: NotificationService,
        private tagsService: TagsService,
        private userTagsService: UserTagsService,
        private router: Router,
        public translate: TranslateService
    ) {
        this.projectTitle = this.data.project.title;
    }

    ngOnInit() {
        this.tagsService.getAll().subscribe((response: any) => {
            this.availableTags = response.data;
        });
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    moveNextPage() {
        const index = this.pages.findIndex((page) => page);
        this.pages[index] = false;
        this.pages[index + 1] = true;
    }

    movePrevPage() {
        const index = this.pages.findIndex((page) => page);
        this.pages[index] = false;
        this.pages[index - 1] = true;
    }

    setUserTags() {
        this.userTagsService.create({ tags: this.userTags.map((tag) => tag.id) }).subscribe(
            () => {
                this.notificationService.success('Tags updated successfully');
                this.closeDialog();
                this.sessionService.setQuestionnairePopUp(false);
                this.moveToTeamPage();
            },
            (error) => this.notificationService.error(error.error.message, 'Error')
        );
    }

    pushTag(tag: any) {
        this.availableTags = this.availableTags.filter((tagItem) => tagItem.id !== tag.id);
        this.userTags.push(tag);
    }

    removeTag(tag: any) {
        this.userTags = this.userTags.filter((tagItem) => tagItem.id !== tag.id);
        this.availableTags.push(tag);
    }

    moveToTeamPage() {
        this.router.navigateByUrl('/communications/team/' + this.sessionService.getCurrentUser().team_id);
    }

    selectMethodology(methodology: Methodology) {
        const selectedIndex = this.methodologies.findIndex((methodology: Methodology) => methodology.selected);
        this.methodologies[selectedIndex]['selected'] = false;
        methodology.selected = true;
    }

    get TASKS_HEADER() {
        return this.translate.instant('communications.component.tasks');
    }
}
