import { settings } from 'src/environments/environment';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { finalize, findIndex } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users.service';
import { SessionService } from 'src/app/services/session.service';
import { TeamsService } from 'src/app/services/team.service';
import { TasksService } from 'src/app/services/tasks.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { ProjectsService } from 'src/app/services/projects.service';
import { CreateInfoBlockForm } from 'src/app/forms/CreateInfoBlockForm';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-communication-info',
    templateUrl: './communication-info.component.html',
    styleUrls: ['./communication-info.component.scss'],
    providers: [],
})
export class CommunicationInfoComponent implements OnInit, AfterViewInit {
    isLoading: boolean = true;

    teams: any[] = [
        {
            name: 'Hula_Team',
            type: '1',
            childs: 0,
            id: 'terminal-8',
        },
        {
            name: 'Team',
            type: '1',
            childs: 0,
            id: 'terminal-7',
        },
        {
            name: 'Hula_Team',
            type: '1',
            childs: 0,
            id: 'terminal-6',
        },
    ];

    mentors: any[] = [
        {
            name: 'Karl',
            type: '1',
            role: 'Student Mentor',
            childs: ['terminal-8'],
            id: 'terminal-5',
        },
        {
            name: 'Karl',
            type: '1',
            role: 'Student Mentor',
            childs: ['terminal-6, terminal-7'],
            id: 'terminal-4',
        },
    ];

    teachers: any[] = [
        {
            name: 'A.Sun',
            type: '1',
            childs: ['terminal-5'],
            role: 'Teacher Mentor',
            id: 'terminal-2',
        },
        {
            name: 'A.Max',
            type: '1',
            role: 'Teacher Mentor',
            childs: ['terminal-4'],
            id: 'terminal-3',
        },
    ];

    admins: any = [
        {
            name: 'Karl',
            type: '1',
            role: 'School Administrator',
            childs: ['terminal-2', 'terminal-3'],
            id: 'terminal-1',
        },
    ];

    tabs: any = [
        {
            name: 'School Administrator',
            type: '1',
            users: this.admins,
        },
        {
            name: 'Teachers & Teacher Mentors',
            type: '2',
            users: this.teachers,
        },
        {
            name: 'Student Mentors',
            type: '3',
            users: this.mentors,
        },
        {
            name: 'Teams & Students',
            type: '4',
            users: this.teams,
        },
    ];

    testUser = { name: 'A.Sun', role: '', childs: 0 };

    slot = {
        type: 'slot',
    };

    // teams: any = [{ name: 'All teams', value: 'all' }, { name: 'Team 1', value: '1' }, { name: 'Team 2', value: '2' }];
    users: any = [
        { name: 'All', value: 'all' },
        { name: 'User 1', value: '1' },
        { name: 'User 2', value: '2' },
    ];

    @ViewChild('startingElement', { read: ElementRef })
    startingElement: ElementRef;
    @ViewChild('endingElement', { read: ElementRef }) endingElement: ElementRef;

    @ViewChild('start') start!: ElementRef;

    @ViewChild('end') end!: ElementRef;

    isRedraw: boolean = false;

    form = new CreateInfoBlockForm();
    type = 'SquarePath';

    constructor(
        private session: SessionService,
        private dialog: MatDialog,
        private router: Router,
        private title: Title
    ) {}

    ngOnInit() {
        this.fetch();
        setTimeout(() => {}, 800);
    }

    ngAfterViewInit() {
        // const linePath: any = new SquarePath(options);
        // linePath.recalculate();
    }

    fetch() {
        const user = this.session.getCurrentUser();
    }

    getTopOffset(tab: any, user: any, i: number) {
        const baseTop = tab.type !== '1' ? 20 : 0;

        const itemHeight = 70;
        const n = user.childs.length;
        const fullHeight = n * (itemHeight + 20);
        const center = fullHeight / 2;
        const centerOffset = center - itemHeight / 2 + 20 / 2;

        const offset = user.childs !== 0 ? centerOffset : baseTop;
        // let offset = '20px';
        // if (tab.type === '4') {
        //   offset = '40px';
        // }

        return offset + 'px';
    }

    addTestUser(tab: any) {
        this.isRedraw = true;
        setTimeout(() => {
            const index = this.tabs.findIndex((tabItem: any) => tabItem.type === tab.type);
            this.tabs[index].users.push({
                ...this.testUser,
                role: tab.users[0].role,
            });
            this.tabs[index].users[0].childs++;
            this.isRedraw = false;
        }, 800);
    }

    submit(data: any) {
        const index = this.tabs.findIndex((tabItem: any) => tabItem.type === data.column);
        this.tabs[index]['slots'] = [];
        this.tabs[index]['slots'].push({ parent: data.parent, childs: [] });
        this.closeDialog();
    }

    openDialog(dialogRef: TemplateRef<any>, tab: any) {
        const index = this.tabs.findIndex((tabItem: any) => tabItem.type === tab.type);
        // this.tabs[index - 1].users;
        this.form.buildForm(this.tabs[index - 1].users, tab.type);
        this.dialog.open(dialogRef);
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    goToViewPage(tab: any, user: any) {
        if (tab.type === '4') {
            this.router.navigateByUrl('/communications/team/' + user.id);
        }
    }
}
