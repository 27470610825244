import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';

interface Game {
    id?: number;
    image_path: string | null;
    authors: any;
    progress: number | null;
    like: boolean;
}

const ANIMATION_DURATION = 400;

@Component({
    selector: 'app-games-list',
    templateUrl: './games-list.component.html',
    styleUrls: ['./games-list.component.scss'],
    animations: [
        trigger('flyBottomIn', [
            state('in', style({ opacity: 1, transform: 'translateY(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateY(100%)' }),
                animate(ANIMATION_DURATION),
            ]),
            transition('* => void', [
                animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateY(-100%)' })),
            ]),
        ]),
    ],
})
export class GamesListComponent {
    @Input() games: any[] = [];

    isLoading: boolean = false;

    storageUrl: string = environment.storageUrl;

    constructor(private notificationService: NotificationService) {}
}
