import { Table } from './Table';
import * as moment from 'moment';
import { Validators } from '@angular/forms';
import { inject } from '@angular/core';
import { InvitationsService } from '../services/invitations.service';

export class ManageInvitationsTable extends Table {
    invitationsService = inject(InvitationsService);

    public invitationTemplate: any;

    buildColumns() {
        this.columns = [
            {
                name: 'ID',
                selector: 'id',
            },
            {
                name: 'Email',
                selector: 'email',
                headerClass: 'text-left',
                class: () => 'text-left',
            },
            {
                name: 'Project',
                selector: 'project',
                transform: (project) => project.name,
            },
            {
                name: 'Role',
                selector: 'role',
                transform: (role) => role.name,
            },
            {
                name: 'Mentor',
                selector: 'mentor',
                headerClass: 'text-center',
                class: () => 'text-center',
                transform: (mentor) =>
                    mentor ? '<i class="ph ph-circle text-green"></i>' : '<i class="ph ph-circle text-red"></i>',
            },
            // {
            //     name: 'Active',
            //     selector: 'active',
            //     headerClass: 'text-center',
            //     class: () => 'text-center',
            //     transform: (active) =>
            //         active ? '<i class="ph ph-circle text-green"></i>' : '<i class="ph ph-circle text-red"></i>',
            // },
            {
                name: '',
                selector: 'active',
                headerClass: 'text-center',
                class: () => 'text-center interactive',
                content: {
                    template: this.invitationTemplate,
                    context: (row) => {
                        return {
                            invitation: row,
                        };
                    },
                },
            },
        ];
    }

    buildForm(projects, roles): void {
        this.form = {
            title: () => 'Manage Invitations',
            elements: [
                {
                    name: 'ID',
                    selector: 'id',
                    type: 'text',
                    visible: false,
                },
                {
                    name: 'Email',
                    selector: 'email',
                    type: 'text',
                    class: 'col-span-12',
                    validators: [Validators.required],
                },
                {
                    name: 'Select project',
                    selector: 'project_id',
                    class: 'col-span-12',
                    type: 'select',
                    options: projects,
                    optionValueSelector: 'id',
                    optionTextSelector: 'title',
                    validators: [Validators.required],
                },
                {
                    name: 'Select role',
                    selector: 'role_id',
                    class: 'col-span-12',
                    type: 'select',
                    options: roles,
                    optionValueSelector: 'id',
                    optionTextSelector: 'name',
                    validators: [Validators.required],
                },
                {
                    name: 'Mentor',
                    selector: 'mentor',
                    type: 'checkbox',
                    class: 'col-span-12',
                },
            ],
        };
    }
}
