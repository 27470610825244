import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { TabComponent } from './tab/tab.component';

@Component({
    selector: 'app-tabs',
    template: `<div class="relative">
        <ul class="flex justify-end items-center border-b border-b-slate-300/25 " role="tablist">
            <li
                *ngFor="let tab of tabs"
                class="flex justify-center items-center h-full cursor-pointer text-[#615B8E] hover:text-white font-medium min-w-[132px] box-content	"
            >
                <button
                    (click)="setActiveTab(tab)"
                    class="w-full h-full rounded-t-lg px-4 py-3 transition duration-300 box-content	"
                    [ngClass]="{
                        'border-b-[#FF02D0] text-[#FF02D0] z-20 bg-gradient-to-r from-pink-400/5 via-gray-200/5 to-violet-400/5  ':
                            activeTab === tab,
                        ' text-gray-500 hover:text-white': activeTab !== tab
                    }"
                    [ngStyle]="{ 'border-bottom': activeTab === tab ? '2px solid ' : '' }"
                    type="button"
                    role="tab"
                >
                    {{ tab.title }}
                </button>
            </li>
        </ul>
        <div class="mt-3">
            <ng-content></ng-content>
        </div>
    </div>`,
    styles: [':host { display: block; }'],
})
export class TabsComponent implements AfterContentInit {
    @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
    activeTab: TabComponent | undefined = undefined;

    ngAfterContentInit() {
        const defaultTab = this.tabs.find((tab) => tab.defaultTab);
        this.activeTab = defaultTab || this.tabs.first;
    }

    setActiveTab(tab: TabComponent) {
        this.activeTab = tab;
    }
}
