import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FriendsService } from 'src/app/services/friends.service';
import { SessionService } from 'src/app/services/session.service';
import { UsersService } from 'src/app/services/users.service';
import { Tools } from 'src/app/tools';
import { environment } from 'src/environments/environment';

type Friend = {
    name?: string;
    image_path: string;
    nickname: string;
    main_image: string;
};

@Component({
    selector: 'app-find-friends-list',
    templateUrl: './find-friends-list.component.html',
    styleUrls: ['./find-friends-list.component.scss'],
})
export class FindFriendsListComponent {
    storageUrl: string = environment.storageUrl;
    friends: Friend[] = [];
    filteredFriends: any[] = [];
    blankImageProfilePath: string = '/assets/blank-profile.png';
    subscriptions: Subscription[] = [];
    search: string = '';
    isLoading: boolean = false;

    constructor(
        public session: SessionService,
        private notificationService: NotificationService,
        private friendsService: FriendsService
    ) {
        this.subscriptions.push(
            this.friendsService.findFriends$.subscribe((search: string) => {
                this.search = search;
                this.fetch();
            })
        );
    }

    ngOnInit() {
        // this.fetch();
    }

    fetch() {
        this.isLoading = true;
        this.friends = [];
        this.friendsService
            .getUserFriends(this.search)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.friends = this.mapData(response.data);
                    this.filteredFriends = Tools.cloneObject(this.friends);
                },
                () => {
                    this.notificationService.error('Something goes wrong');
                }
            );
    }

    mapData(data: any) {
        return data.map((friend: Friend) => ({ ...friend, image_path: this.getImagePath(friend) }));
    }

    getImagePath(friend: Friend): string {
        return friend.main_image ? this.storageUrl + friend.main_image : this.blankImageProfilePath;
    }

    sendFriendsRequest(userId: number) {
        this.friendsService.sendFriendsRequest(Tools.getFormData({ friend_user_id: userId })).subscribe(
            () => {
                this.fetch();
                this.notificationService.success('Request status updated');
            },
            () => {
                this.notificationService.error('Something goes wrong');
            }
        );
    }
}
