<app-card class="mb-3 h-full" [title]="'All surveys'" [paddingX]="false">
    <app-table
        [columns]="table.columns"
        [data]="surveys"
        [form]="table.form"
        [create]="true"
        (onCreate)="create($event)"
        [edit]="true"
        (onEdit)="update($event)"
        [delete]="true"
        (onDelete)="delete($event)"
        [deletionConfirm]="true"
        [dialogConfig]="{ width: '600px' }"
        [itemsPerPage]="10"
        [itemsPerPageSelect]="[10]"
    >
    </app-table>
</app-card>

<app-card *ngIf="selectedSurvey" class="mb-3 h-full" [title]="'Survey Questions'" [paddingX]="false">
    <app-table
        [columns]="questionsTable.columns"
        [data]="surveyQuestions"
        [form]="questionsTable.form"
        [create]="true"
        (onCreate)="createQuestion($event)"
        [edit]="true"
        (onEdit)="updateQuestion($event)"
        [delete]="true"
        (onDelete)="deleteQuestion($event)"
        [deletionConfirm]="true"
        [dialogConfig]="{ width: '600px' }"
    >
    </app-table>
</app-card>

<ng-template #selectButtonTemplate let-survey="survey">
    <app-un-header-button
        class="inline-block"
        [text]="'Show Questions'"
        [selected]="selectedSurvey?.id === survey.id"
        (click)="onSurveySelect(survey)"
        [truncate]="25"
    ></app-un-header-button>
    <!--    <app-un-magic-button
        [text]="'Show Questions'"
        [selected]="selectedSurvey?.id === survey.id"
        (click)="onSurveySelect(survey)"
    ></app-un-magic-button>-->
</ng-template>
