import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnChanges {
    @Input() data: any;
    @Input() columns: any;
    @Input() page: number = 1;
    @Input() totalItems: number = 0;
    @Input() limit: number = 15;
    @Input() itemsPerPageSelect: number[] = [5, 10, 15, 30, 100];
    @Input() hidePerPageSelect: boolean = false;

    @Output() pageChanged = new EventEmitter<any>();
    @Output() limitChanged = new EventEmitter<any>();

    pages: any[] = [];

    constructor(public sessionService: SessionService) {}

    ngOnInit() {
        this.getPages();
    }

    ngOnChanges() {
        this.getPages();
    }

    changePage(page: number) {
        if (this.page === page) {
            return;
        }
        this.page = page !== -1 ? page : this.getTotalPages();
        this.pageChanged.emit(this.page);
    }

    getPages() {
        this.pages = [];
        const totalPages = this.getTotalPages();
        for (let i = 1; i <= totalPages; i++) {
            this.pages.push(i);
        }

        if (this.pages.length > 5) {
            let start = this.page > 3 ? this.page - 3 : 0;
            if (this.page > 5 && totalPages - this.page < 2) {
                start = this.page - (5 - (totalPages - this.page));
            }
            const end = this.page > 3 ? this.page + 2 : 5;
            this.pages = this.pages.slice(start, end);
        }
    }

    getTotalPages() {
        const totalPages_pre = this.totalItems / this.limit;
        return Math.floor(this.totalItems % this.limit === 0 ? totalPages_pre : totalPages_pre + 1);
    }

    changeLimit(limit: number) {
        this.limit = limit;
        this.page = 1;
        this.limitChanged.emit(this.limit);
    }
}
