import { Chart } from './Chart';

export class ProjectStatisticChart extends Chart {
    inited = false;
    updateFlag = false;

    update() {
        this.updateFlag = true;
    }

    build(chart: any) {
        this.options = {
            ...this.options,
            ...{
                colors: ['#FF02D0', '#FF9900', '#00A3FF', '#7B61FF', '#B6A8FF', '#00A3FF', '#FF02D0', '#FF9900'],
                chart: {
                    type: 'column',
                    zoomType: 'x',
                    spacingBottom: 20,
                },
                legend: {
                    enabled: true,
                    verticalAlign: 'top',
                },
                xAxis: {
                    categories: chart.categories,
                    labels: {
                        style: {
                            color: '#E1DEF5',
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        style: {
                            color: '#C6C2DF',
                        },
                    },
                },
                plotOptions: {
                    column: {
                        borderWidth: 0,

                        dataLabels: {
                            enabled: false,
                            borderWidth: 0,

                            // formatter: function () {
                            //     return (this.y > 0) ? Math.round(this.y).toString() : null;
                            // }
                        },
                    },
                },

                series: chart.series,
            },
        };

        this.inited = true;
    }
}
