import { Component } from '@angular/core';
import * as moment from 'moment';


@Component({
  selector: 'app-school-section',
  templateUrl: './school-section.component.html',
  styleUrls: ['./school-section.component.scss'],
  providers: [],
})
export class SchoolSectionComponent {

  isLoading: boolean = false;

  projects: any = [
    {
      icon: 'domain',
      name: 'HULA'
    },
    {
      icon: 'spa',
      name: 'Test project 2'
    }, {
      icon: 'brightness_5',
      name: 'Test project 3'
    }
  ];


  project = this.projects[0].name;

  card: any = {
    name: 'Creation',
    progress: 91,
    modified_at: '1 days ago'
  };

  opened = false;

  selectedTeam: any;


  constructor() { }


  selectProject(name: string) {
    this.project = name;
  }

  showInfo(user: any) {

    if (this.opened) {


      if (user.id !== this.selectedTeam.id) {

        this.opened = false;

        setTimeout(() => {
          this.showTeamSidebar(user);
        }, 500);

      }


      return;
    }

    this.showTeamSidebar(user);

  }

  showTeamSidebar(event: any) {
    this.opened = true;
    this.selectedTeam = event;
  }

  closeInfo() {
    this.opened = false;
    this.selectedTeam = undefined;
  }


}
