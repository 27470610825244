<ng-container (ngSubmit)="submit()" [formGroup]="formGroup">
    <div class="mb-4 grid grid-cols-12 justify-center gap-x-5">
        <div class="col-span-12">
            <div class="grid grid-cols-12 gap-4">
                <ng-container *ngFor="let element of formElements; let i = index">
                    <ng-container *ngIf="!element.rightSide">
                        <ng-container
                            *ngTemplateOutlet="formItemTemplate; context: { $implicit: element, group: formGroup }"
                        >
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <ng-template #formItemTemplate let-element let-formGroup="group">
            <ng-container [ngSwitch]="element.type">
                <!-- Text -->
                <!-- <ng-container *ngSwitchCase="'text'">
                    <mat-form-field class="mb-2" [ngClass]="element.class" [hidden]="!isElementPresent(element)">
                        <input
                            matInput
                            type="text"
                            placeholder="{{ element.name }}"
                            [id]="element.selector"
                            [formControl]="element.formControl"
                            [value]="element.value ? element.value : ''"
                            [formControlName]="element.selector"
                            [errorStateMatcher]="matcher"
                            autocomplete="off"
                            spellcheck="false"
                        />
                        <mat-error *ngIf="element.formControl.hasError('required')">Please fill in</mat-error>
                        <mat-error
                            *ngIf="!element.formControl.hasError('required') && element.formControl.status == 'INVALID'"
                            >{{ element.errorText }}</mat-error
                        >
                        <ng-container *ngIf="element.legend">
                            <i class="ml-2 cursor-pointer fas fa-info-circle legend" (click)="showLegend(element)"></i>
                        </ng-container>
                    </mat-form-field>
                </ng-container> -->

                <ng-container *ngSwitchCase="'text'">
                    <app-text-input
                        [ngClass]="element.class"
                        [hidden]="!isElementPresent(element)"
                        [formControlName]="element.selector"
                        [formControl]="element.formControl"
                        [label]="element.name"
                        [placeholder]="element.placeholder"
                        [isDisabled]="element.disabled"
                    >
                    </app-text-input>

                    <ng-container *ngIf="element.legend">
                        <i class="fas fa-info-circle legend cursor-pointer" (click)="showLegend(element)"></i>
                    </ng-container>
                </ng-container>

                <!-- Number-->
                <ng-container *ngSwitchCase="'number'">
                    <div
                        class="-mb-3.5 block text-sm font-medium text-gray-500 dark:font-normal dark:text-gray-400"
                        [ngClass]="element.class"
                    >
                        {{ element.name }}
                    </div>
                    <mat-form-field class="mb-2" [ngClass]="element.class" [hidden]="!isElementPresent(element)">
                        <input
                            matInput
                            type="number"
                            placeholder="{{ element.name }}"
                            [id]="element.selector"
                            [formControl]="element.formControl"
                            [formControlName]="element.selector"
                            [errorStateMatcher]="matcher"
                            spellcheck="false"
                            [attr.min]="element.minValue || element.minValue === 0 ? element.minValue : ''"
                            [attr.max]="element.maxValue || element.maxValue === 0 ? element.maxValue : ''"
                            [attr.step]="element.step ? element.step : ''"
                        />
                        <mat-error *ngIf="element.formControl.hasError('required')">Please fill in</mat-error>
                        <mat-error
                            *ngIf="!element.formControl.hasError('required') && element.formControl.status == 'INVALID'"
                            >{{ element.errorText }}</mat-error
                        >
                        <ng-container *ngIf="element.legend">
                            <i class="fas fa-info-circle legend ml-2 cursor-pointer" (click)="showLegend(element)"></i>
                        </ng-container>
                    </mat-form-field>
                </ng-container>

                <!-- Textarea -->
                <!-- <ng-container *ngSwitchCase="'textarea'">
                    <mat-form-field class="mb-2" [ngClass]="element.class" [hidden]="!isElementPresent(element)">
                        <textarea
                            matInput
                            autosize
                            [minRows]="3"
                            placeholder="{{ element.name }}"
                            [id]="element.selector"
                            [formControl]="element.formControl"
                            [formControlName]="element.selector"
                            [errorStateMatcher]="matcher"
                            spellcheck="false"
                        ></textarea>
                        <mat-error *ngIf="element.formControl.hasError('required')">Please fill in</mat-error>
                        <mat-error
                            *ngIf="!element.formControl.hasError('required') && element.formControl.status == 'INVALID'"
                            >{{ element.errorText }}</mat-error
                        >
                        <ng-container *ngIf="element.legend">
                            <i class="fas fa-info-circle legend ml-2 cursor-pointer" (click)="showLegend(element)"></i>
                        </ng-container>
                    </mat-form-field>
                </ng-container> -->

                <ng-container *ngSwitchCase="'textarea'">
                    <app-textarea
                        [ngClass]="element.class"
                        [hidden]="!isElementPresent(element)"
                        [formControlName]="element.selector"
                        [formControl]="element.formControl"
                        [label]="element.name"
                        [rows]="getTextAreaMinRows(element)"
                    >
                    </app-textarea>

                    <ng-container *ngIf="element.legend">
                        <i class="fas fa-info-circle legend cursor-pointer" (click)="showLegend(element)"></i>
                    </ng-container>
                </ng-container>

                <!-- Checkbox -->
                <ng-container *ngSwitchCase="'checkbox'">
                    <app-checkbox-input
                        [ngClass]="element.class"
                        [hidden]="!isElementPresent(element)"
                        [formControlName]="element.selector"
                        [formControl]="element.formControl"
                        [name]="element.name"
                        [label]="element.checkboxLabel"
                        [selector]="element.selector"
                    ></app-checkbox-input>
                    <!-- <div
                        class="custom-control custom-checkbox align-self-center mb-2 pl-2"
                        [ngClass]="element.class"
                        [matTooltip]="getToolip(element.name)"
                        [hidden]="!isElementPresent(element)"
                    >
                        <mat-checkbox [formControlName]="element.selector" (click)="checkbox(element.selector)">
                            {{ element.name | truncate: charactersLimit }}
                        </mat-checkbox>
                        <mat-error *ngIf="element.formControl.hasError('required')">Please fill in</mat-error>
                        <mat-error
                            *ngIf="!element.formControl.hasError('required') && element.formControl.status == 'INVALID'"
                            >{{ element.errorText }}</mat-error
                        >
                        <ng-container *ngIf="element.legend">
                            <i class="fas fa-info-circle legend cursor-pointer" (click)="showLegend(element)"></i>
                        </ng-container>
                    </div> -->
                </ng-container>

                <!-- Checkboxes  -->
                <ng-container *ngSwitchCase="'checkboxes'">
                    <app-checkbox-array-input
                        [ngClass]="element.class"
                        [matTooltip]="getToolip(element.name)"
                        [hidden]="!isElementPresent(element)"
                        [selector]="element.selector"
                        [formControlName]="element.selector"
                        [formControl]="element.formControl"
                        [label]="element.name"
                        [optionValueSelector]="element.optionValueSelector"
                        [optionTextSelector]="element.optionTextSelector"
                        [options]="element.options"
                    >
                    </app-checkbox-array-input>

                    <ng-container *ngIf="element.legend">
                        <i class="fas fa-info-circle legend cursor-pointer" (click)="showLegend(element)"></i>
                    </ng-container>
                </ng-container>

                <!-- Checkbox array -->
                <ng-container *ngSwitchCase="'checkbox-array'">
                    <ng-container *ngIf="isFirstCheckboxArr(element)">
                        <div class="text-large mb-2 w-full">{{ element.sectionName }}</div>
                    </ng-container>
                    <div
                        class="custom-control custom-checkbox mb-2 pl-3"
                        [ngClass]="element.class"
                        [matTooltip]="getToolip(element.name)"
                        [hidden]="!isElementPresent(element)"
                    >
                        <mat-checkbox [formControlName]="element.selector" (click)="checkbox(element.selector)">
                            {{ element.name | truncate: charactersLimit }}
                        </mat-checkbox>
                        <mat-error *ngIf="element.formControl.hasError('required')">Please fill in</mat-error>
                        <mat-error
                            *ngIf="!element.formControl.hasError('required') && element.formControl.status == 'INVALID'"
                            >{{ element.errorText }}</mat-error
                        >
                    </div>
                    <ng-container *ngIf="element.legend">
                        <i class="fas fa-info-circle legend cursor-pointer" (click)="showLegend(element)"></i>
                    </ng-container>
                </ng-container>

                <!-- Date -->
                <ng-container *ngSwitchCase="'date'">
                    <app-date-input
                        [ngClass]="element.class"
                        [hidden]="!isElementPresent(element)"
                        [formControlName]="element.selector"
                        [formControl]="element.formControl"
                        [label]="element.name"
                        [isDisabled]="element.disabled"
                    >
                    </app-date-input>

                    <ng-container *ngIf="element.legend">
                        <i class="fas fa-info-circle legend cursor-pointer" (click)="showLegend(element)"></i>
                    </ng-container>
                </ng-container>
                <!-- <ng-container *ngSwitchCase="'date'">
                    <mat-form-field class="mb-2" [ngClass]="element.class" [hidden]="!isElementPresent(element)">
                        <input
                            matInput
                            [matDatepicker]="picker"
                            placeholder="{{ element.name }}"
                            [min]="element.minValue"
                            [max]="element.maxValue"
                            [id]="element.selector"
                            [formControl]="element.formControl"
                            [formControlName]="element.selector"
                            [errorStateMatcher]="matcher"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker-toggle
                            *ngIf="element.formControl.value && !element.disabled"
                            matSuffix
                            (click)="clearFormControl(element.formControl)"
                        >
                            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="element.formControl.hasError('required')">Please fill in</mat-error>
                        <mat-error
                            *ngIf="!element.formControl.hasError('required') && element.formControl.status == 'INVALID'"
                            >{{ element.errorText }}</mat-error
                        >
                    </mat-form-field>
                </ng-container> -->

                <!-- Date & Time -->
                <ng-container *ngSwitchCase="'datetime'">
                    <div class="mb-2" [ngClass]="element.class" [hidden]="!isElementPresent(element)">
                        <div class="no-gutter flex">
                            <mat-form-field class="col-6">
                                <input
                                    matInput
                                    [matDatepicker]="picker"
                                    placeholder="{{ element.name }}"
                                    [min]="element.minValue"
                                    [max]="element.maxValue"
                                    [id]="element.selector"
                                    [formControl]="element.formControl"
                                    [formControlName]="element.selector"
                                    [errorStateMatcher]="matcher"
                                />
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error *ngIf="element.formControl.hasError('required')">Please fill in</mat-error>
                                <mat-error
                                    *ngIf="
                                        !element.formControl.hasError('required') &&
                                        element.formControl.status == 'INVALID'
                                    "
                                    >{{ element.errorText }}</mat-error
                                >
                            </mat-form-field>
                            <mat-form-field class="col-3">
                                <mat-select
                                    placeholder="HH"
                                    [formControl]="element.hhFormControl"
                                    [id]="element.selector + '_hh'"
                                    [errorStateMatcher]="matcher"
                                >
                                    <ng-container *ngFor="let iEmpty of rangeHours; let i = index">
                                        <mat-option [value]="i">{{ i }}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="col-3">
                                <mat-select
                                    placeholder="MM"
                                    [formControl]="element.mmFormControl"
                                    [id]="element.selector + '_mm'"
                                    [errorStateMatcher]="matcher"
                                >
                                    <ng-container *ngFor="let iEmpty of rangeMinutes; let i = index">
                                        <mat-option [value]="i" *ngIf="i % 5 == 0">{{ i }}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>

                <!-- Time -->
                <ng-container *ngSwitchCase="'time'">
                    <div class="mb-2" [ngClass]="element.class" [hidden]="!isElementPresent(element)">
                        <div class="no-gutter flex">
                            <mat-form-field class="col-6">
                                <mat-select
                                    [value]="element.hhFormControl.value"
                                    placeholder="HH"
                                    [formControl]="element.hhFormControl"
                                    [id]="element.selector + '_hh'"
                                    [errorStateMatcher]="matcher"
                                >
                                    <ng-container *ngFor="let iEmpty of rangeHours; let i = index">
                                        <mat-option [value]="i">{{ i }}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="col-6">
                                <mat-select
                                    [value]="element.mmFormControl.value"
                                    placeholder="MM"
                                    [formControl]="element.mmFormControl"
                                    [id]="element.selector + '_mm'"
                                    [errorStateMatcher]="matcher"
                                >
                                    <ng-container *ngFor="let iEmpty of rangeMinutes; let i = index">
                                        <mat-option [value]="i" *ngIf="i % 5 == 0">{{ i }}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>

                <!-- New time -->
                <ng-container *ngSwitchCase="'time-new'">
                    <div class="mb-2" [ngClass]="element.class" [hidden]="!isElementPresent(element)">
                        <div class="no-gutter flex">
                            <mat-form-field class="">
                                <input
                                    matInput
                                    type="time"
                                    [id]="element.selector"
                                    [formControl]="element.formControl"
                                    [formControlName]="element.selector"
                                    [value]="element.formControl.value"
                                    placeholder="{{ element.name }}"
                                    [errorStateMatcher]="matcher"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>

                <!-- Select -->
                <ng-container *ngSwitchCase="'select'">
                    <div
                        class="-mb-3.5 block text-sm font-medium text-gray-500 dark:font-normal dark:text-gray-400"
                        [ngClass]="element.class"
                    >
                        {{ element.name }}
                    </div>
                    <mat-form-field class="mb-2" [ngClass]="element.class" [hidden]="!isElementPresent(element)">
                        <mat-select
                            placeholder="{{ element.name }}"
                            [formControl]="element.formControl"
                            [errorStateMatcher]="matcher"
                        >
                            <ng-container *ngIf="element.optionEmpty">
                                <mat-option [value]="element.optionEmptyValue">{{
                                    element.optionEmptyText
                                }}</mat-option>
                            </ng-container>

                            <!-- with groups -->
                            <ng-container *ngIf="element.optionGroupSelector">
                                <mat-optgroup *ngFor="let group of getOptionGroups(element)" [label]="group">
                                    <mat-option
                                        *ngFor="let o of getOptionsForGroup(element, group)"
                                        [value]="o[element.optionValueSelector]"
                                    >
                                        {{ o[element.optionTextSelector] }}
                                    </mat-option>
                                </mat-optgroup>
                            </ng-container>

                            <!-- default -->
                            <ng-container *ngIf="!element.optionGroupSelector">
                                <mat-option *ngFor="let o of element.options" [value]="o[element.optionValueSelector]">
                                    {{ o[element.optionTextSelector] }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="element.formControl.hasError('required')">Please fill in</mat-error>
                        <mat-error
                            *ngIf="!element.formControl.hasError('required') && element.formControl.status == 'INVALID'"
                            >{{ element.errorText }}</mat-error
                        >
                    </mat-form-field>
                </ng-container>

                <!-- Radio -->
                <ng-container *ngSwitchCase="'radio'">
                    <div class="mb-3" [ngClass]="element.class" [hidden]="!isElementPresent(element)">
                        <mat-radio-group [formControl]="element.formControl" [formControlName]="element.selector">
                            <div class="label">
                                {{ element.name }}
                            </div>
                            <ng-container *ngIf="element.legend">
                                <i
                                    class="fas fa-info-circle legend ml-2 cursor-pointer"
                                    style="right: 7px"
                                    (click)="showLegend(element)"
                                ></i>
                            </ng-container>
                            <div class="">
                                <mat-radio-button
                                    [value]="o[element.optionValueSelector]"
                                    class="pr-4"
                                    *ngFor="let o of element.options; let i = index"
                                >
                                    {{ o[element.optionTextSelector] }}
                                </mat-radio-button>
                            </div>
                            <mat-error *ngIf="element.formControl.hasError('required')">Please fill in</mat-error>
                            <mat-error
                                *ngIf="
                                    !element.formControl.hasError('required') && element.formControl.status == 'INVALID'
                                "
                                >{{ element.errorText }}</mat-error
                            >
                        </mat-radio-group>
                    </div>
                </ng-container>

                <!-- File Upload -->
                <ng-container *ngSwitchCase="'image'">
                    <div class="mb-3" [ngClass]="element.class" [hidden]="!isElementPresent(element)">
                        <div class="label mb-3">
                            {{ element.name }}
                        </div>

                        <ng-container *ngIf="element.value && !element.showUpload">
                            <div class="image-form-container relative" (click)="element.showUpload = true">
                                <img class="image-form" [src]="storageUrl + element.value" />
                                <span class="edit-btn"><i class="ph ph-pencil-simple-line fa-lg"></i></span>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!element.value || element.showUpload">
                            <app-un-file-upload
                                (fileChanged)="uploadFile($event, element.selector)"
                            ></app-un-file-upload>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'stringlist'">
                    <app-string-list
                        [(strings)]="element.formControl.value"
                        (stringsChange)="element.formControl.setValue($event)"
                        [ngClass]="element.class"
                        [title]="element.name"
                    >
                    </app-string-list>
                </ng-container>
            </ng-container>
        </ng-template>
    </div>
</ng-container>
