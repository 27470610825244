<div *ngIf="!isLoading" class="flex h-full flex-col justify-between">
    <div #massagesBox class="un-scroll grid h-full overflow-y-auto p-2" [scrollTop]="massagesBox.scrollHeight">
        <div class="mt-auto">
            <ng-container *ngFor="let msg of messages">
                <div
                    class="message-container flex w-full items-center gap-2 break-words"
                    [class.mine]="msg.user.id === user.id"
                >
                    <div *ngIf="msg.user.id !== user.id" [matTooltip]="msg.user.nickname" matTooltipPosition="above">
                        <img
                            [src]="msg.user.main_image ? storageUrl + msg.user.main_image : defaultImageUrl"
                            class="h-5 w-5 min-w-[20px] max-w-full rounded-full"
                        />
                    </div>
                    <div class="message my-1" [class.mine]="msg.user.id === user.id">
                        <span class="message-time">{{ msg.date | uDate: 'HH:mm' }}</span>
                        {{ msg.message }}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="sticky bottom-0 w-full">
        <div
            class="justify-content-start my-3 grid grid-cols-12 items-center gap-2 px-2"
            (keydown)="keyDownFunction($event)"
        >
            <div class="col-span-10">
                <textarea
                    [formControl]="message"
                    [maxLength]="320"
                    [placeholder]="'communications.workspace.chat_description' | translate"
                    class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] text-xs leading-[1.6] text-[#8E91D1] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-[#8e91d1]"
                    rows="1"
                    style="border: 1px dashed #8e91d1 !important"
                    #textArea
                    (input)="onInput()"
                ></textarea>
                <div class="flex justify-end text-xs text-gray-500 dark:text-gray-400">
                    {{ message.value.length }} / {{ 256 }}
                </div>
            </div>

            <div
                class="send-message-button interactive col-span-2 flex items-center justify-center"
                (click)="sendMessage()"
            >
                <i class="ph-paper-plane-right text-lg"></i>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading" class="flex h-full max-h-[400px] items-center justify-center">
    <mat-spinner></mat-spinner>
</div>
