import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FileValidators } from 'ngx-file-drag-drop';
import { UntypedFormControl } from '@angular/forms';



@Component({
  selector: 'app-un-image-upload',
  templateUrl: './un-image-upload.component.html',
  styleUrls: ['./un-image-upload.component.scss'],
  styles: [':host { display: flex;width: 100%; height: 100%; justify-content: center; align-items: center; border: 1px dashed #8E91D1; border-radius: 8px; cursor: pointer;}'],
})
export class UnImageUploadComponent implements OnInit {

  file = new UntypedFormControl(
    [],
    [FileValidators.required,
    FileValidators.maxFileCount(1)]
  );

  @Output() fileChanged: EventEmitter<any> = new EventEmitter();


  imagePath: string;
  imgURL: any;
  message: string;

  url: any;
  format: any;

  imageUploadHelper: any;
  imageError: boolean = false;

  ngOnInit(): void {
    this.file.valueChanges.subscribe(() => this.fileChanged.emit(this.file.value as File));
  }

  preview(files: any) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    this.fileChanged.emit(files[0]);

    const reader = new FileReader();
    this.imagePath = files;
    this.imageUploadHelper = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.imgURL = reader.result;
      
      // this.imageError = false;
    };
  }

}
