import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { UserSubjectInterestsChart } from 'src/app/charts/UserSubjectInterestsChart';

@Component({
    selector: 'app-user-info-chart',
    templateUrl: './user-info-chart.component.html',
    styleUrls: ['./user-info-chart.component.scss'],
    providers: [],
})
export class UserInfoChartComponent implements OnInit {
    isLoading: boolean = false;
    user: any | undefined;

    @Input() inputChartData?: any;

    categories = {
        eng: ['Biology', 'Chemistry', 'Physics', 'History', 'Art', 'Literature', 'Tanah', 'Israel culture'],
    };

    chartData = {
        title: 'Skill Breakdown',
        categories: this.categories.eng,
        seriesData: {
            name: '',
            data: [0, 0, 0, 0, 0, 0, 0, 0],
        },
    };

    chart = new UserSubjectInterestsChart();

    constructor(
        private userService: UserService,
        private sessionService: SessionService,
        private notificationService: NotificationService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.getUser();
        const data = this.inputChartData ? this.inputChartData : this.chartData;
        this.chart.build(data);
    }

    getUser() {
        this.user = this.sessionService.getCurrentUser();
    }
}
