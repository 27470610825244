export type Tab = {
    name: string;
    active: boolean;
    selector?: string;
    visible?: boolean;
};

export abstract class Tabsable {
    activeTab: Tab;

    abstract tabs: Tab[];

    setTab(tab: Tab) {
        this.activeTab = tab;
    }
}
