import { settings } from 'src/environments/environment';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SessionService } from 'src/app/services/session.service';
import { TranslateService } from '@ngx-translate/core';
import { StatisticsService } from 'src/app/services/statistics.service';
import { ProjectStatisticChart } from 'src/app/charts/ProjectStatisticChart';
import { TeamsService } from 'src/app/services/team.service';
import { finalize } from 'rxjs';
import * as moment from 'moment';
import { StudentStatisticChart } from 'src/app/charts/StudentStatisticChart';

@Component({
    selector: 'app-project-statistic',
    templateUrl: './project-statistic.component.html',
    styleUrls: ['./project-statistic.component.scss'],
})
export class ProjectStatisticComponent implements OnInit, OnChanges {
    @Input() projectId: number = 0;
    isLoading: boolean = true;

    today = moment();

    selectedTeamId = 0;
    selectedStudentId = 0;
    selectedTeam;

    teams: any[] = [];
    user = this.session.getCurrentUser();

    tasksChart = new ProjectStatisticChart();
    timeChart = new ProjectStatisticChart();
    documentsChart = new ProjectStatisticChart();
    mentor_survey = new ProjectStatisticChart();

    userCharts = {
        tasks: new StudentStatisticChart(),
        documents: new StudentStatisticChart(),
        time: new StudentStatisticChart(),
        inited: false,
    };

    currentStats = {
        completed_tasks: 0,
        documents: 0,
        time: 0,
        uncompleted_tasks: 0,
    };

    constructor(
        private session: SessionService,
        private teamsService: TeamsService,
        private title: Title,
        private statisticsService: StatisticsService,
        public translate: TranslateService,
    ) {}

    ngOnInit() {
        this.title.setTitle(settings.title + 'Statistics');
        // this.getTeams();
        // this.fetch();
    }

    ngOnChanges() {
        this.selectedStudentId = null;
        this.userCharts.inited = false;
        this.userCharts.inited = false;
        this.selectedTeamId = null;
        this.selectedTeam = undefined;

        this.getTeams();
    }

    getTeams() {
        this.teamsService.getTeamsByProjectId(this.projectId).subscribe({
            next: ({ data }: any) => {
                this.teams = data.teams || [];
                this.fetch();
            },
        });
    }

    getTeam() {
        this.selectedTeam = this.teams.find((team) => team.id === this.selectedTeamId);
        this.selectedStudentId = null;
        this.userCharts.inited = false;
        this.userCharts.inited = false;

        this.fetch();
    }

    getStudentChartData() {
        this.fetch();
    }

    fetch() {
        // this.isLoading = true;
        this.statisticsService
            .getAll(this.projectId, this.selectedTeamId, this.selectedStudentId)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: ({ data }: any) => {
                    this.currentStats = data.stats;

                    if (!this.selectedStudentId) {
                        this.tasksChart.build(data.charts.tasks);
                        this.timeChart.build(data.charts.time);
                        this.documentsChart.build(data.charts.documents);
                        this.mentor_survey.build(data.charts.mentor_survey);
                        this.tasksChart.update();
                        this.documentsChart.update();
                        this.timeChart.update();
                        this.mentor_survey.update();
                    }

                    if (this.selectedStudentId) {
                        this.mentor_survey.build(data.charts.mentor_survey);
                        this.mentor_survey.update();
                        this.userCharts.tasks.build(data.charts.tasks);
                        this.userCharts.tasks.update();
                        this.userCharts.documents.build(data.charts.documents);
                        this.userCharts.documents.update();
                        this.userCharts.time.build(data.charts.time);
                        this.userCharts.time.update();
                        this.userCharts.inited = true;
                    }
                },
            });
    }

    calculatePercentage(value1: number, value2: number, decimalPlaces: number = 0): number {
        const percentage = value1 && value2 ? (value1 / value2) * 100 : 0;
        return Number(percentage.toFixed(decimalPlaces));
    }
}
