import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-un-page-sub-panel',
    templateUrl: './un-page-sub-panel.component.html',
    styleUrls: ['./un-page-sub-panel.component.scss'],
    providers: [],
})
export class UnPageSubPanelComponent implements OnInit {
    @Input() title: string = '';
    @Input() backUrl: string = '/';
    @Input() saveText: string = 'save';

    @Input() onlyBack: boolean = false;
    @Input() allowRename: boolean = false;

    @Input() enableAvatarByName: boolean = false;

    @Input() textDecoration: string = '';
    @Input() avatarIcon: string = '';
    @Input() avatarColor: string = '';
    @Input() image?: string = '';

    @Output() onSubmit: EventEmitter<any> = new EventEmitter();
    @Output() avatarClicked: EventEmitter<any> = new EventEmitter();
    @Output() onRename: EventEmitter<any> = new EventEmitter();

    constructor(
        private router: Router,
        public dialog: MatDialog,
    ) {}

    ngOnInit() {}

    backToPage() {
        this.router.navigateByUrl(this.backUrl);
    }

    submit() {
        this.onSubmit.emit();
    }

    clickAvatar() {
        this.avatarClicked.emit();
    }

    rename() {
        this.onRename.emit();
    }
}
