import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-un-header-button',
    templateUrl: './un-header-button.component.html',
    styleUrls: ['./un-header-button.component.scss'],
})
export class UnHeaderButtonComponent implements OnInit {
    @Input() text: string = '';
    @Input() selected: boolean = false;
    @Input() fullWidth: boolean = false;
    @Input() bordered: boolean = false;
    @Input() disabled: boolean = false;
    @Input() icon: string = '';
    @Input() isDropdown: boolean = false;
    @Input() truncate: number = 12;

    @Output() сhanged: EventEmitter<any> = new EventEmitter();

    ngOnInit(): void {}
}
