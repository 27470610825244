import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { flyInOut, flyleftIn, flyRigthIn } from 'src/app/animations/fly-animations';
import { MiniGamesService } from 'src/app/services/mini-games.service';
import { StudioService } from 'src/app/services/studio.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-view-container',
    templateUrl: './view-container.component.html',
    styleUrls: ['./view-container.component.scss'],
    animations: [flyleftIn, flyRigthIn, flyInOut],
})
export class ViewContainerComponent {
    isLoading: boolean = true;

    storageUrl: string = environment.storageUrl;

    project: any;
    isAbleExit: boolean = false;

    UNITY_ID: number = 13;

    constructor(
        private route: ActivatedRoute,
        private studioService: StudioService,
    ) {}

    ngOnInit() {
        this.getGame();
    }

    getGame() {
        this.isLoading = true;
        const projectId = this.route.snapshot.paramMap.get('id');
        if (projectId) {
            setTimeout(() => {
                this.project = this.studioService.getProjectById(parseInt(projectId));
                if (parseInt(projectId) === this.UNITY_ID) {
                    this.fetch();
                }
                this.isLoading = false;
            }, 800);
        }
    }

    fetch() {
        this.studioService.getUnityLink().subscribe(({ data }: any) => {
            this.project.link = data.url;
        });
    }

    openLinkInNewTab(url: string) {
        window.open(url, '_blank');
    }
}
