import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Tools } from '../tools';
import { Url } from '../url';

type PreUserRole = 'student' | 'teacher';
type UserRole = 'student' | 'teacher';

@Injectable()
export class UsersService {
    private USERS_PER_PAGE: number = 15;

    fakeUsers: any[] = [
        {
            id: 2,
            name: 'Sofi Heart',
            image: '/assets/rust.jpg',
        },
        {
            id: 2,
            name: 'Sofi Heart',
            image: '/assets/rust.jpg',
        },
        {
            id: 2,
            name: 'Sofi Heart',
            image: '/assets/rust.jpg',
        },
    ];

    constructor(private http: HttpClient) {}

    getAll(nickname?: string) {
        const url = new Url('/users');

        url.addQueryParameter('nickname', nickname);

        return this.http.get(url.path);
    }

    addUser(user: any) {
        return this.http.post(environment.apiUrl + '/users', user);
    }

    getUser(userId: number) {
        return this.http.get(environment.apiUrl + '/users/' + userId);
    }

    updateUser(id: number, user: any) {
        return this.http.put(environment.apiUrl + '/users/' + id, user);
    }

    updateCurrentUser(user: any) {
        return this.http.put(environment.apiUrl + '/user/me', user);
    }

    deleteUser(userId: number) {
        return this.http.delete(environment.apiUrl + '/users/' + userId);
    }

    updatePassword(userId: number, password: string, passwordConfirmation: string) {
        return this.http.put(environment.apiUrl + '/users/' + userId + '/password', {
            password: password,
            password_confirmation: passwordConfirmation,
        });
    }

    getPermissions(userId: number) {
        return this.http.get(environment.apiUrl + '/users/' + userId + '/permissions');
    }

    addPermission(userId: number, permissionId: number, level: number) {
        return this.http.post(environment.apiUrl + '/users/' + userId + '/permissions', {
            permission_id: permissionId,
            level: level,
        });
    }

    deletePermission(userId: number, permissionId: number, level: number) {
        return this.http.delete(environment.apiUrl + '/users/' + userId + '/permissions/' + permissionId, {
            params: { level: level.toString() },
        });
    }

    putPermission(userId: number, fromUserId: number) {
        return this.http.put(environment.apiUrl + '/users/' + userId + '/permissions', {
            from_user_id: fromUserId,
        });
    }

    addTeam(userId: number, teamId: number, level: number) {
        return this.http.post(environment.apiUrl + '/users/' + userId + '/teams', {
            team_id: teamId,
            level: level,
        });
    }

    changeTeam(user: any) {
        return this.http.put(environment.apiUrl + '/users/' + user.id + '/teams', user);
    }

    removeTeam(userId: number, teamId: number) {
        return this.http.delete(environment.apiUrl + '/users/' + userId + '/teams/' + teamId);
    }

    updateDetails(userId: number, details: any) {
        return this.http.put(environment.apiUrl + '/users/' + userId + '/details', details);
    }

    //just for prototyping
    getFakeUsers() {
        return this.fakeUsers;
    }

    getUserStats(userId: number) {
        return this.http.get(environment.apiUrl + '/users/' + userId + '/stats');
    }

    getUsersPerPage(): number {
        return this.USERS_PER_PAGE;
    }

    uploadUsersFromFile(data: any) {
        return this.http.post(environment.apiUrl + '/users/users-from-excel', data);
    }

    getInviteListUsers() {
        return this.http.get(environment.apiUrl + '/pre-users/');
    }

    getInviteUsersListByRole(role: PreUserRole) {
        const url = new Url('/pre-users');
        url.addQueryParameter('role', role);
        return this.http.get(url.path);
    }

    getInviteUsersListByRoleID(roleID: number) {
        return this.http.get(environment.apiUrl + '/pre-users/role/' + roleID);
    }

    createInviteUser(data: any) {
        return this.http.post(environment.apiUrl + '/pre-users', data);
    }

    updateInviteUser(id: number, data: any) {
        return this.http.put(environment.apiUrl + '/pre-users/' + id, data);
    }

    deleteInviteUser(id: number) {
        return this.http.delete(environment.apiUrl + '/pre-users/' + id);
    }

    getUsersByRole(role: UserRole) {
        const url = new Url('/users');
        url.addQueryParameter('role', role);

        return this.http.get(url.path);
    }

    getUsers(page: number, limit: number, filters?: any, search?: string) {
        const url = new Url('/users');

        url.addQueryParameter('page', page);
        url.addQueryParameter('limit', limit);
        url.addQueryParameter('search', search);

        url.addFilters(filters);

        return this.http.get(url.path);
    }

    getAllTeachers() {
        return this.http.get(environment.apiUrl + '/users/teachers');
    }

    getAllStudents() {
        return this.http.get(environment.apiUrl + '/users/students');
    }
}
