import { Component } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
    isLoading: boolean = false;

    submited: boolean = false;

    formGroup = this.fb.group({
        password: [null, Validators.required],
        confirm_password: [null, [Validators.required, Validators.minLength(8)]],
    });

    token: string = '';

    constructor(
        private notificationService: NotificationService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService
    ) {
        this.route.queryParams.subscribe((params) => (this.token = params['token']));
    }

    onSubmit() {
        this.resetPassword();
    }

    get confirmPassword() {
        return this.formGroup.get('confirm_password');
    }

    get isValid() {
        if (!this.formGroup.get('password')?.value || !this.formGroup.get('confirm_password')?.value) {
            return false;
        }
        return this.formGroup.get('password')?.value === this.formGroup.get('confirm_password')?.value;
    }

    resetPassword() {
        const password = this.formGroup.get('password')?.value;
        const confirm_password = this.formGroup.get('confirm_password')?.value;

        this.userService
            .resetPassword(this.token, {
                confirm_password: confirm_password,
                password: password,
            })
            .subscribe(
                () => {
                    this.notificationService.success('Password has been successfully changed');
                    this.submited = true;
                    this.router.navigateByUrl('/login');
                },
                (err) => {
                    this.notificationService.error(err.error.reason, 'Error');
                }
            );
    }
}
