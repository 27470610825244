<div class="mb-3 flex items-center">
    <h4 class="mr-3">{{ 'communications.section.project_plan' | translate }}</h4>

    <mat-form-field>
        <mat-select [(value)]="viewMode">
            <mat-select-trigger class="flex items-center">
                <mat-icon>view_list</mat-icon>&nbsp;{{ viewName }}
            </mat-select-trigger>
            <mat-option *ngFor="let option of viewModes" [value]="option.value">
                <mat-icon>view_list</mat-icon>{{ option.viewValue }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="ml-auto">
        <button matRipple class="mr-2 btn-primary small-control">
            <i class="fas fa-cog fa-lg"></i>
        </button>
        <button matRipple class="btn-danger bg-pink small-control">
            <i class="fas fa-plus fa-lg"></i>
        </button>
    </div>
</div>

<app-table [columns]="table.columns" [data]="planSteps" [slim]="true"></app-table>
