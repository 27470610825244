import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-checkbox-input',
    template: `
        <div>
            <label
                *ngIf="label"
                class="block text-sm font-medium text-gray-500 dark:font-normal dark:text-gray-400"
                [innerHTML]="!label || label?.length === 0 ? '&nbsp;' : label"
            >
            </label>
            <div class="mt-0.5 flex  items-center">
                <input
                    type="checkbox"
                    [value]="isChecked"
                    [checked]="isChecked"
                    (change)="toggleChecked()"
                    [disabled]="isDisabled"
                    class="h-5 w-5 cursor-pointer rounded-sm border-gray-300 bg-transparent text-green-400 transition duration-300 focus:ring-green-400 disabled:text-gray-500 dark:border-gray-600 dark:text-green-400 dark:ring-offset-slate-400 dark:focus:ring-green-400 dark:disabled:text-gray-300"
                />

                <label
                    class="ml-2 cursor-pointer select-none py-2 text-sm"
                    [ngClass]="{ 'pointer-events-none text-gray-300': isDisabled }"
                    (click)="toggleChecked()"
                >
                    {{ name }}
                </label>
            </div>
        </div>
    `,
    styles: [':host { display: block; }'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxInputComponent),
            multi: true,
        },
    ],
})
export class CheckboxInputComponent implements ControlValueAccessor {
    @Input() name: string = '';
    @Input() label: string = '';
    // @Input() value: string = '';
    @Input() isDisabled: boolean = false;

    public isChecked: boolean | undefined;

    charactersLimit: number = 50;

    private onChange: (value: boolean) => void;
    private onTouched: () => void;

    writeValue(value: boolean): void {
        this.isChecked = value;
    }

    registerOnChange(fn: (value: boolean) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    toggleChecked(): void {
        this.isChecked = !this.isChecked;
        this.onChange(this.isChecked);
        this.onTouched();
    }
}
