import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { StudentDevelopmentChart } from 'src/app/charts/StudentDevelopmentChart';
import { UserSubjectInterestsChart } from 'src/app/charts/UserSubjectInterestsChart';
import { StudentsService } from 'src/app/services/students.service';
import * as moment from 'moment';
import { TagsService } from 'src/app/services/tags.service';
import { UserTagsService } from 'src/app/services/user-tags.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from 'src/app/services/session.service';
import { UsersService } from 'src/app/services/users.service';
import { Tools } from 'src/app/tools';

@Component({
    selector: 'app-user-data-info',
    templateUrl: './user-data-info.component.html',
    styleUrls: ['./user-data-info.component.scss'],
    providers: [],
})
export class UserDataInfoComponent implements OnInit {
    isLoading: boolean = true;

    @Input() user: any;
    @Input() isProfile: boolean = false;

    chart = new UserSubjectInterestsChart();
    chartData: any;
    developmentChart = new StudentDevelopmentChart();

    today = moment().format('DD MMM YYYY');

    availableTags: any[] = [];
    assignedTags: any[] = [];
    userTags: any[] = [];
    selectedTags: any[] = [];

    TAGS_DIALOG_WIDTH: string = '800px';

    selectedChart = 'disciplines';
    charts: string[] = ['disciplines', 'skills'];

    disciplinesChartData: any;
    skillsChartData: any;

    constructor(
        private studentsService: StudentsService,
        private tagsService: TagsService,
        private userTagsService: UserTagsService,
        private usersService: UsersService,
        public notificationService: NotificationService,
        public session: SessionService,
        public dialog: MatDialog
    ) {}

    get isAbleEditTags() {
        return this.user.id === this.session.getCurrentUser().id;
    }

    ngOnInit() {
        this.getTags();
        this.fetch();
    }

    fetch() {
        this.studentsService.getById(this.user.id).subscribe(({ data }: any) => {
            this.userTags = data.user.tags || [];
            this.disciplinesChartData = data?.chart.disciplines;
            this.skillsChartData = data?.chart.skills;
            this.chart.build(data.chart.disciplines);
            this.developmentChart.build([1, 2, 3, 5, 5]);
            this.isLoading = false;
        });
    }

    getTags() {
        this.tagsService.getAll().subscribe((response: any) => {
            this.assignedTags = response.data;
        });
    }

    setUserTags() {
        this.usersService.updateCurrentUser({ tags: this.selectedTags.map((tag) => tag.id) }).subscribe(
            ({ data }: any) => {
                this.notificationService.success('Tags updated successfully');
                this.userTags = data.tags;
                this.closeDialog();
            },
            (error) => this.notificationService.error(error.error.message, 'Error')
        );
    }

    pushTag(tag: any) {
        this.availableTags = this.availableTags.filter((tagItem) => tagItem.id !== tag.id);
        this.selectedTags.push(tag);
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    removeTag(tag: any) {
        this.selectedTags = this.selectedTags.filter((tagItem) => tagItem.id !== tag.id);
        this.availableTags.push(tag);
    }

    openEditTagsModal(dialogRef: TemplateRef<any>) {
        this.selectedTags = Tools.cloneObject(this.userTags) || [];
        this.availableTags = this.assignedTags.filter(
            (tagItem) => !this.selectedTags.map((tag) => tag.id).includes(tagItem.id)
        );

        this.dialog.open(dialogRef, { autoFocus: false });
    }

    selectChart(chart: string) {
        this.selectedChart = chart;
    }
}
