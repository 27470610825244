import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-un-user-row',
    templateUrl: './un-user-row.component.html',
    styleUrls: ['./un-user-row.component.scss'],
    animations: [
        trigger('flyRigthIn', [
            state('in', style({ opacity: 1, transform: 'translateX(0)' })),
            transition('void => *', [style({ opacity: 0, transform: 'translateX(100%)' }), animate(200)]),
            transition('* => void', [animate(200, style({ opacity: 0, transform: 'translateX(-100%)' }))]),
        ]),
    ],
})
export class UnUserRowComponent {
    @Input() user: any;

    storageUrl: string = environment.storageUrl;
    defaultImageUrl: string = '/assets/blank-profile.png';

    constructor(private dialog: MatDialog) {}

    openModal(dialogRef: TemplateRef<any>) {
        this.dialog.open(dialogRef, { autoFocus: false });
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }
}
