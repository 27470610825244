<div
    class="task-card main-card relative border-2"
    [class.closed-overall]="isTaskComplete"
    [class.guide]="tasksService.isGuide(card.task_type.type)"
    (mouseenter)="card.hover = true"
    (mouseleave)="card.hover = false"
    (click)="openMenu($event)"
    [ngClass]="{
        'border-blue-400 ': card.status?.status === TaskStatuses.Progress,
        'border-yellow-300 ': card.status?.status === TaskStatuses.Review,
        'border-green-300 ': card.status?.status === TaskStatuses.Done
    }"
>
    <div class="h-fit w-full p-3">
        <div
            class="menu-trigger invisible absolute right-2 top-1 rtl:left-2 rtl:right-auto"
            [matMenuTriggerFor]="menu"
        ></div>

        <div class="task-card-body">
            <div
                *ngIf="card.task_type"
                class="grid grid-cols-12 gap-2"
                [ngClass]="{ 'mb-2': card.tags.length > 0, 'mb-3': card.tags.length === 0 }"
            >
                <div *ngIf="tasksService.isGuide(card.task_type.type)" class="col-span-11 flex">
                    <span
                        class="task-chip"
                        [ngStyle]="{ color: card.task_type.color, 'border-color': card.task_type.border }"
                    >
                        <i [ngClass]="card.task_type.icon"></i>
                        {{ card.task_type?.title }}
                    </span>
                </div>

                <div *ngIf="!tasksService.isGuide(card.task_type.type)" class="col-span-11 mb-2 flex flex-wrap gap-2">
                    <ng-container *ngFor="let tag of card.tags">
                        <span class="task-chip" [ngStyle]="{ color: tag.color, 'border-color': tag.border }">
                            <i [ngClass]="'ph-' + tag.icon"></i>
                            {{ tag?.label }}
                        </span>
                    </ng-container>
                </div>

                <div class="col-span-1 p-0 text-end">
                    <i>
                        <ng-content></ng-content>
                    </i>
                </div>
            </div>

            <div class="text-start">
                <div class="mb-1" [class.task-closed]="isTaskComplete">
                    <span class="word-break">{{ card.name }}</span>
                </div>
                <div *ngIf="card.description && !isTaskComplete">
                    <span class="word-break mb-2 line-clamp-2 text-xs text-[#b6b2d4]">{{
                        card.description | safe: 'stripHtmlTags'
                    }}</span>
                </div>
            </div>

            <div *ngIf="card.progress" class="relative my-4">
                <div class="progress">
                    <span
                        class="progress-bar"
                        [style.background]="getColorByValue(card.progress)"
                        [style.width.%]="card.progress"
                    ></span>
                    <div
                        class="percent-item"
                        [style.background]="getColorByValue(card.progress)"
                        [style.left.%]="card.progress"
                    >
                        {{ card.progress }}%
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isFooter && !isTaskComplete" class="task-card-footer">
            <div class="flex justify-end">
                <div *ngIf="card.deadline_time" class="flex items-center" style="gap: 4px">
                    <i class="ph-timer-bold" style="color: #ff02d0; font-size: 1.2em"></i>
                    <span style="color: #edf2ff; font-size: 0.85em">
                        {{ getTaskTimeLeft(card.deadline_time).time }}
                        {{ getTaskTimeLeft(card.deadline_time).units | translate }}
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="isFooter" class="task-card-footer">
            <div class="flex justify-end">
                <div
                    *ngIf="!tasksService.isGuide(card.task_type.type)"
                    class="flex items-center gap-1"
                    [ngClass]="{
                        'text-blue-400 ': card.status?.status === TaskStatuses.Progress,
                        'text-yellow-300 ': card.status?.status === TaskStatuses.Review,
                        'text-green-300 ': card.status?.status === TaskStatuses.Done
                    }"
                >
                    <i class="ph-circle-bold" style="font-size: 1.2em"></i>
                    <span style="font-size: 0.85em">
                        {{ card.status?.label | translate }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<mat-menu #menu="matMenu" backdropClass="customize" style="top: -58px">
    <div mat-menu-item (click)="goToTaskPage()">
        <div class="flex items-center gap-3">
            <i class="ph-eye"></i>
            <span>{{ 'communications.workspace.view_task' | translate }}</span>
        </div>
    </div>

    <div *ngIf="canDeleteTask(card)" mat-menu-item (click)="deleteTask()">
        <div class="flex items-center gap-3">
            <i class="ph-trash"></i>
            <span>{{ 'communications.workspace.delete_task' | translate }}</span>
        </div>
    </div>
</mat-menu>
