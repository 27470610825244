import { settings } from 'src/environments/environment';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { TeamsService } from 'src/app/services/team.service';
import { SessionService } from 'src/app/services/session.service';


@Component({
  selector: 'app-school-manage-team',
  templateUrl: './school-manage-team.component.html',
  styleUrls: ['./school-manage-team.component.scss'],
  providers: [],
})
export class SchoolManageTeamComponent implements OnInit {


  @Input() team: any;
  @Output() closed: EventEmitter<any> = new EventEmitter();

  taskTypes: any[] = [
    {
      name: 'Personal',
      color: 'blue'
    }, {
      name: 'Team',
      color: 'orange'
    }, {
      name: 'School',
      color: 'pink'
    }, {
      name: 'Network',
      color: 'violet'
    }
  ];

  users: any[] = [];

  taskName: string = '';

  teamInfo: any = {
    participant: {},
    additionally: {}
  };


  constructor(
    private teamsService: TeamsService,
    private userService: UserService,
    private sessionService: SessionService
    ) { }



  ngOnInit() {
    this.users = this.userService.getFakeUsers();
  }

  ngOnChanges(changes: SimpleChanges) {

    if ('team' in changes && changes.team.currentValue) {

      if (!changes.team.previousValue) {
        this.setTeam(changes.team.currentValue);
        return;
      }

      if ((changes.team.currentValue.id !== changes.team.previousValue.id)) {
        this.setTeam(changes.team.currentValue);
      }

    }
  }

  setTeam(team: any) {
    this.team = team;
    this.teamInfo = { participant: {}, additionally: {} };
    this.fetch();
  }


  close() {
    this.closed.emit();
  }

  fetch() {

    this.teamsService.getTeamProfile(this.team.id).subscribe((response: any) => {
      this.teamInfo.participant = response.data.participant;
      this.teamInfo.additionally = response.data.additionally;
    });

  }

}
