<div>
    <h2 class="print-only text-center"></h2>

    <nav mat-tab-nav-bar class="print-none mb-3">
        <a
            mat-tab-link
            *ngFor="let menu of nav"
            class="tab-name"
            [ngClass]="{
                'active-tab ': menu.component === component
            }"
            [routerLink]="baseRoute + '/' + menu.component"
            routerLinkActive
            #rla="routerLinkActive"
            [active]="menu.component === component"
            [hidden]="!isActive(menu.component)"
        >
            {{ menu.title }}
        </a>
    </nav>

    <div *ngIf="component == 'users'">
        <app-users></app-users>
    </div>

    <div *ngIf="component == 'games'">
        <app-manage-games></app-manage-games>
    </div>

    <div *ngIf="component == 'schools'">
        <app-manage-schools></app-manage-schools>
    </div>

    <!-- <div *ngIf="component == 'vote'">
        <app-manage-votes></app-manage-votes>
    </div> -->
</div>
