<div class="relative h-6 w-full">
    <!-- Background bar -->
    <div class="absolute left-0 top-1/2 h-px w-full -translate-y-1/2 bg-gray-200"></div>
    <!-- Progress bar -->
    <div class="absolute left-0 top-1/2 h-px -translate-y-1/2" [ngStyle]="{'width': rate + '%'}" [ngClass]="inputColor ? bgColor : color"></div>
    <!-- Progress indicator container with padding -->
    <div class="absolute h-6 w-auto px-0 inset-0 mr-7">
        <!-- Progress indicator -->
        <div class="absolute top-1/2 -translate-y-1/2 flex items-center justify-center px-1 py-1 rounded-full"
             [ngStyle]="{'left': rate + '%'}" [ngClass]="inputColor ? bgColor : color">
            <div class="text-center text-xs font-bold text-white">
                <span>{{ displayValue }}</span><span>{{ stringEnd }}</span>
            </div>
        </div>
    </div>
</div>
