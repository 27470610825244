<div class="flex">
    <div class="col-auto ml-auto" mat-dialog-close><i class="fas fa-times fa-lg"></i></div>
</div>

<div mat-dialog-content class="skill-cards-dialog-container">
    <div class="skill-cards-container">
        <div class="flex items-center mb-1">
            <div class="col-auto">
                <div>
                    <h3 class="poppins-bold m-0">{{ 'studio.skill_card.level_now' | translate }}</h3>
                    <h4 class="poppins-light">{{ 'studio.skill_card.middle' | translate }}</h4>
                </div>
            </div>
        </div>

        <div class="flex justify-between" style="height: 75%">
            <div class="cards-column">
                <app-studio-skill-card [card]="cards[0]"></app-studio-skill-card>
                <app-studio-skill-card [card]="cards[1]"></app-studio-skill-card>
            </div>

            <div class="flex flex-col justify-center items-center">
                <div class="mb-3 mx-auto" style="width: 120px">
                    <app-index-radial [inputIndex]="65" type="personal"></app-index-radial>
                </div>
                <div class="st-title text-center">
                    <h5 class="mb-2">{{ 'studio.skill_card.personal' | translate }}</h5>
                    <span style="font-size: 1.2em">{{ 'studio.skill_card.user_platform_level' | translate }}</span>
                </div>
            </div>

            <div class="cards-column">
                <app-studio-skill-card [card]="cards[2]"></app-studio-skill-card>
                <app-studio-skill-card [card]="cards[3]"></app-studio-skill-card>
            </div>
        </div>
    </div>
</div>
