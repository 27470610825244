import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-un-skill-card',
  templateUrl: './un-skill-card.component.html',
  styleUrls: ['./un-skill-card.component.scss']
})
export class UnSkillCardComponent {

  @Input() card: any;

  storageUrl: string = environment.storageUrl;



  constructor() { }


  like() {

    if (this.card['animation']) {
      return;
    }

    this.card['animation'] = true;

    setTimeout(() => {
      this.card.like = !this.card.like;
      this.card['animation'] = false;
    }, 1200);

  }

}
