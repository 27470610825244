import { Table } from './Table';
import * as moment from 'moment';
import { Validators } from '@angular/forms';

export class ManageProjectsTable extends Table {
    constructor() {
        super();
        this.buildColumns();
    }

    buildColumns() {
        this.columns = [
            {
                name: 'ID',
                selector: 'id',
            },
            {
                name: 'Title',
                selector: 'title',
                headerClass: 'text-left',
                class: () => 'text-left',
            },
            {
                name: 'Description',
                selector: 'description',
                transform: (description) =>
                    description.length > 100 ? description.substr(0, 100) + '...' : description,
            },
            {
                name: 'Start date',
                selector: 'start_date',
                transform: (created) => moment(created).format('DD.MM.YYYY'),
            },
            {
                name: 'End date',
                selector: 'end_date',
                transform: (end_date) => moment(end_date).format('DD.MM.YYYY'),
            },
            // {
            //     name: 'Access',
            //     selector: 'access',
            //     headerClass: 'text-center',
            //     class: () => 'text-center',
            // },
            {
                name: 'Active',
                selector: 'active',
                headerClass: 'text-center',
                class: () => 'text-center',
                transform: (active) =>
                    active ? '<i class="ph ph-circle text-green"></i>' : '<i class="ph ph-circle text-red"></i>',
            },
        ];
    }

    buildForm(): void {
        this.form = {
            title: () => 'Manage projects',
            elements: [
                {
                    name: 'ID',
                    selector: 'id',
                    type: 'text',
                    visible: false,
                },
                {
                    name: 'Title',
                    selector: 'title',
                    type: 'text',
                    class: 'col-span-12',
                    validators: [Validators.required],
                },
                {
                    name: 'Start',
                    selector: 'start_date',
                    type: 'date',
                    class: 'col-span-12',
                    validators: [Validators.required],
                },
                {
                    name: 'Deadline',
                    selector: 'end_date',
                    type: 'date',
                    class: 'col-span-12',
                    validators: [Validators.required],
                },
                // {
                //     name: 'Access',
                //     selector: 'access',
                //     type: 'radio',
                //     value: 'custom',
                //     options: [
                //         {
                //             name: 'public',
                //             value: 'public',
                //         },
                //         {
                //             name: 'privated',
                //             value: 'privated',
                //         },
                //     ],
                //     optionTextSelector: 'name',
                //     optionValueSelector: 'value',
                //     defaultValue: 'custom',
                //     class: 'col-span-7',
                //     validators: [Validators.required],
                // },
                {
                    name: 'Description',
                    selector: 'description',
                    type: 'textarea',
                    class: 'col-span-12',
                    validators: [Validators.required],
                },
            ],
        };
    }
}
