<!-- <div class="container">

  <app-info-box class="mb-5" [name]="'questionnaire.student.welcom_header' | translate" [description]="'questionnaire.student.welcom_desc' | translate" [imagePath]="imagePath" [centerText]="false"></app-info-box>

  <div class="player-box mx-auto py-4 px-3 mb-5">
    <div class="player-box-image-wrapper mb-4">
      <img [src]="user.main_image ? storageUrl + '/' + user.main_image : '/assets/profile-placeholder.jpg'">
    </div>
    <div class="player-box-name text-center">
      <span>{{ user.nickname }}</span>
    </div>
  </div>

  <div class="mx-auto flex justify-center" style="width: 400px;">
    <app-un-save-button [text]="'questionnaire.student.go_to_platform' | translate" (click)="finish()"></app-un-save-button>
  </div>

</div> -->

<div class="container mx-auto mt-5">
    <div class="flex" style="gap: 100px">
        <div class="w-50 flex flex-col justify-between">
            <div>
                <h1 class="mb-4">Thanks for your vote</h1>
                <div class="text-mute">
                    <span>
                        ​See what learning & gaming events are on the horizon. Join digital conferences, and be a part
                        of the key events for the gamedev sector. ​See what learning &gaming events are on the horizon.
                    </span>
                </div>
            </div>

            <div *ngIf="isVoteCompleted" class="flex justify-content-start mt-5">
                <app-un-save-button text="start project" (click)="finish()"></app-un-save-button>
            </div>
        </div>
        <div *ngIf="theme" class="w-50">
            <div class="main-card p-3 mx-auto" style="min-width: 300px; max-width: 333px">
                <div class="mb-3 topic-box-row">
                    <div class="mb-3">
                        <img
                            [class.image-border]="theme.image"
                            [src]="theme.image ? storageUrl + theme.image : '/assets/img.png'"
                        />
                    </div>
                    <div class="text-start">
                        <div class="mb-2 topic-box-row-name">{{ theme.title }}</div>
                        <div class="topic-box-row-desc">{{ theme.description }}</div>
                    </div>
                </div>

                <div *ngIf="!isVoteCompleted" style="width: max-content">
                    <app-un-save-button [text]="'Choose other topic'" style="zoom: 0.75" (click)="selectAnotherTheme()">
                    </app-un-save-button>
                </div>
            </div>
        </div>
    </div>
</div>
