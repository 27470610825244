<div
    class="task-card main-card border-2 p-1 duration-200 hover:scale-[103%]"
    [class.closed-overall]="isTaskComplete"
    [class.guide]="tasksService.isGuide(card.task_type.type)"
    (click)="goToTaskPage()"
    (mouseenter)="card.hover = true"
    (mouseleave)="card.hover = false"
    [ngClass]="{
        'border-blue-400': card.status?.status === TaskStatuses.Progress,
        'border-yellow-300': card.status?.status === TaskStatuses.Review,
        'border-green-300': card.status?.status === TaskStatuses.Done
    }"
>
    <div class="task-card-body h-5/6">
        <div class="">
            <div class="card-name mx-auto line-clamp-2">
                <div>
                    <div class="mb-1" [class.task-closed]="isTaskComplete">
                        <span class="break-words">{{ card.name | truncate: 120 }}</span>
                    </div>
                </div>
            </div>

            <!--            <div class="px-4 absolute bottom-4">
                <div class="text-start" [class.task-closed]="isTaskComplete">
                    <div class="text-start">
                        <span class="task-action-button cursor-pointer">{{ startButtonText }}</span>
                    </div>
                </div>
            </div>-->
        </div>

        <div class="card-animated-image" [ngStyle]="{ 'background-image': 'url(' + getImage() + ')' }"></div>

        <div class="absolute inset-0 col-span-1 p-2.5 text-end">
            <i>
                <ng-content></ng-content>
            </i>
        </div>
    </div>
    <div class="task-card-footer mt-auto h-auto content-end justify-end p-3">
        <div class="flex justify-end">
            <div
                *ngIf="!tasksService.isGuide(card.task_type.type)"
                class="flex items-center gap-1"
                [ngClass]="{
                    'text-blue-400 ': card.status?.status === TaskStatuses.Progress,
                    'text-yellow-300 ': card.status?.status === TaskStatuses.Review,
                    'text-green-300 ': card.status?.status === TaskStatuses.Done
                }"
            >
                <i class="ph-circle-bold" style="font-size: 1.2em"></i>
                <span style="font-size: 0.85em">
                    {{ card.status?.label | translate }}
                </span>
            </div>
        </div>
    </div>
</div>
