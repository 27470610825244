import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[numberAnimation]',
})
export class NumberAnimationDirective implements OnChanges {
    @Input() numberValue: number;

    private previousValue: number = null;
    private animationInProgress = false;

    constructor(private elementRef: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.numberValue && !changes.numberValue.firstChange) {
            const newValue = changes.numberValue.currentValue;

            if (newValue !== this.previousValue) {
                if (this.animationInProgress) {
                    // Animation in progress, return
                    return;
                } else {
                    // Start the animation
                    this.animateNumber(newValue);
                }
            }
        }
    }

    private animateNumber(newValue: number): void {
        const duration = 800; // Animation duration in milliseconds
        const fps = 60; // Frames per second
        const frameDuration = 800 / fps;
        const frames = Math.ceil(duration / frameDuration);
        const increment = (newValue - this.previousValue) / frames;

        let current = this.previousValue;
        let frame = 0;

        const animate = () => {
            current += increment;
            frame++;

            if (frame < frames) {
                this.elementRef.nativeElement.innerText = Math.round(current).toString();
                setTimeout(animate, frameDuration);
            } else {
                current = newValue;
                this.previousValue = newValue;
                this.animationInProgress = false;
            }
        };

        this.animationInProgress = true;
        this.previousValue = this.numberValue;

        animate();
    }
}
