import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'uDate',
})
export class UDatePipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {}

    transform(value: any, format: any) {
        value = value !== null ? value.replace(/\s/g, 'T') : value;
        return this.datePipe.transform(value, format);
    }
}
