import { trigger, style, animate, transition } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { FriendsService } from 'src/app/services/friends.service';
import { SessionService } from 'src/app/services/session.service';
import { Tools } from 'src/app/tools';
import { environment } from 'src/environments/environment';


type Friend = {
  name?: string;
  image_path: string;
  nickname: string;
  main_image: string;
  messages: number | null,
  online: boolean;
}

@Component({
  selector: 'app-un-friends-bar',
  templateUrl: './un-friends-bar.component.html',
  styleUrls: ['./un-friends-bar.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('280ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('280ms', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ]
    )
  ],
})
export class UnFriendsBarComponent {

  @Input() card: any;

  barOpened: boolean = false;
  storageUrl: string = environment.storageUrl;

  friends: Friend[] = [];

  filteredFriends: any[] = [];

  blankImageProfilePath: string = '/assets/blank-profile.png';
  chatUrl: string = environment.chatUrl;
  chatFunc: string = 'direct/';

  subscriptions: Subscription[] = [];


  constructor(
    public session: SessionService,
    private friendsService: FriendsService
  ) {
    this.subscriptions.push(
      this.friendsService.toggleFriendsSidebar$.subscribe(() => {
        this.toggleFriendsBar();
      })
    );
  }


  ngOnInit() {
    this.fetch();
  }

  ngOnDestroy(): void {
    Tools.unsubscribeAll(this.subscriptions);
  }

  fetch() {
    this.friendsService.getAll().subscribe((response: any) => {
      this.friends = this.mapData(response.data);
      this.filteredFriends = this.friends;
    });
  }

  get onlineCount(): number {
    return this.filteredFriends.filter((friend: Friend) => friend.online).length;
  }

  get offlineCount(): number {
    return this.filteredFriends.filter((friend: Friend) => !friend.online).length;
  }

  mapData(data: any) {
    return data.map((friend: Friend) => ({ ...friend, image_path: this.getImagePath(friend) }))
  }

  getImagePath(friend: Friend): string {
    return friend.main_image ? this.storageUrl + friend.main_image : this.blankImageProfilePath;
  }

  toggleFriendsBar() {
    this.filteredFriends = this.friends;
    this.barOpened = !this.barOpened;
  }

  close() {
    this.barOpened = false;
  }

  onKey(event: any) {
    this.filteredFriends = this._filterUsers(event.target.value).filter(n => n);
  }

  goToChat(nickname: string) {
    window.open(this.chatUrl + this.chatFunc + nickname, '_blank')
  }

  private _filterUsers(value: string): any[] {
    const list: any[] = [];
    const input = value ? value.toLowerCase() : '';
    this.friends.forEach((element, key) => {
      if (!input || (input && this.friends[key].nickname.toLowerCase().indexOf(input) > -1)) {
        list[key] = this.friends[key];
      }
    });
    return list;
  }


}
