<div class="group absolute bottom-12 right-0 z-50 w-40 overflow-hidden">
    <span
        *ngIf="!isChatRolled"
        class="absolute top-0 hidden h-5 w-5 transition-all duration-200 group-hover:block"
        style="inset-inline-start: 122px"
        (click)="toggleChatToEdge()"
    >
        <i class="ph ph-x interactive text-2xl font-semibold"></i>
    </span>
    <div
        *ngIf="!isChatOpened"
        class="uni-badge flex h-36 w-36 cursor-pointer items-center justify-center rounded-full bg-[#512BB6] shadow-lg"
        [class.rolled]="isChatRolled"
        (click)="toggleChat()"
    >
        <img src="/assets/uni-1.png" class="w-1/2 transition-transform duration-200 group-hover:scale-125" alt="" />

        <ng-container *ngFor="let stage of stages">
            <div class="top" [style.transform]="'rotate(' + stage + 'deg)'">
                <ng-container *ngFor="let char of text.split(''); let i = index">
                    <span class="text-char char{{ i + 1 }}"> {{ char }}</span>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>

<div *ngIf="isChatOpened" class="uni-chat-container absolute bottom-8 right-8 px-2 pt-4" [class.extened]="extended">
    <span class="absolute end-7 top-1 h-5 w-5" (click)="toggleWidth()">
        <i
            class="ph cursor-pointer text-xl font-semibold text-[#8E91D1] hover:text-[#FF02D0]"
            [class.ph-corners-out]="!extended"
            [class.ph-corners-in]="extended"
        ></i>
    </span>
    <span class="absolute end-1 top-1 h-5 w-5" (click)="toggleChat()">
        <i class="ph ph-x cursor-pointer text-xl font-semibold text-[#8E91D1] hover:text-[#FF02D0]"></i>
    </span>

    <app-uni-chat></app-uni-chat>
</div>
