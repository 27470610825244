import { Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TasksService } from 'src/app/services/tasks.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment, settings } from 'src/environments/environment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS_CUSTOM } from 'src/app/app.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'src/app/services/session.service';
import { Subscription } from 'rxjs';
import { Tools } from 'src/app/tools';
import { PresentationService } from 'src/app/services/presentation.service';
import { TagsService } from 'src/app/services/tags.service';
import { MatDialog } from '@angular/material/dialog';
import { DateChangedEvent } from 'src/app/ui/date-range-picker/date-range-picker.component';
import { ConfettiService } from '../../../../services/confetti.service';

@Component({
    selector: 'app-manage-task',
    templateUrl: './manage-task.component.html',
    styleUrls: ['./manage-task.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS_CUSTOM },
    ],
})
export class ManageTaskComponent implements OnInit {
    taskId: number = 0;
    date: string = '';

    start: any;
    end: any;

    finalVersion = new UntypedFormControl('');

    isLoading: boolean = true;
    task: any;

    htmlContent: string = '';
    enableEditor: boolean = false;

    students: any[] = [];

    editorConfig: AngularEditorConfig = settings.editorConfig;
    @ViewChild('editor') editor: ElementRef;

    TASK_DEFAULT_STATUS: string = 'default';

    taskTypes: any[] = [];
    tags: any[] = [];
    taskTags: any[] = [];
    taskData;

    availableTags: any[] = [];
    selectedTags: any[] = [];

    statuses: any[] = [];

    currentStatus: number = 0;

    storageUrl: string = environment.storageUrl;

    isPresentationLoading: boolean = false;

    subscriptions: Subscription[] = [];

    taskName: string = '';

    taskNameControl = new UntypedFormControl('');

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private notificationService: NotificationService,
        private presentationService: PresentationService,
        private tagsService: TagsService,
        public sessionService: SessionService,
        public tasksService: TasksService,
        public translate: TranslateService,
        public dialog: MatDialog,
        public confettiService: ConfettiService,
    ) {
        this.subscriptions.push(this.sessionService.changeLang$.subscribe(() => this.loadTask()));
    }

    ngOnInit() {
        this.loadTask();
        this.getTaskTypes();
        this.getTaskTags();
    }

    loadTask() {
        const id = this.route.snapshot.paramMap.get('id');

        if (id) {
            this.taskId = parseInt(id);
            this.fetch(parseInt(id));
        }
    }

    ngOnDestroy() {
        Tools.unsubscribeAll(this.subscriptions);
    }

    fetch(taskId: number) {
        this.isLoading = true;
        this.tasksService
            .getTeamTasks(taskId)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: ({ data }: any) => {
                    this.taskData = data;
                    this.task = data.team_task;
                    this.taskName = data.team_task.name;
                    this.htmlContent = data.team_task.description;
                    this.finalVersion.setValue(data.team_task.final_version);

                    this.statuses = data.status_list;
                    this.currentStatus = data.team_task.task_status_id;

                    this.start = data.start_time ? moment(data.team_task.start_time).format('YYYY-MM-DD') : null;
                    this.end = data.deadline_time ? moment(data.team_task.deadline_time).format('YYYY-MM-DD') : null;

                    this.taskTags = data.tags;
                    this.selectedTags = Tools.cloneObject(this.taskTags) || [];
                    this.getTeamStudents();
                },
                error: () => {
                    this.notificationService.error(this.SOMETHING_WRONG_TOASTR);
                    this.router.navigate(['communications'], { queryParams: { tab: 'tasks' } });
                },
            });
    }

    getTaskTypes() {
        this.tasksService.getTaskTypes().subscribe((response: any) => {
            this.taskTypes = response.data;
        });
    }

    get availableTypes() {
        return this.taskTypes.filter((taskType) => this.task.task_type.id !== taskType.id);
    }

    get isCompleted(): boolean {
        return this.task.status === 'completed';
    }

    getTeamStudents() {
        this.tasksService.getTeamStudents(this.task.team_id).subscribe((response: any) => {
            const usersIds = this.taskData.users.map((user: any) => user.id);
            this.students = response.data.map((student: any) => ({
                ...student,
                checked: usersIds.includes(student.id),
            }));
        });
    }

    @HostListener('document:click', ['$event'])
    clickout(event: any) {
        this.enableEditor = this.editor && this.editor.nativeElement.contains(event.target);
    }

    getDateValue(date: any, format?: boolean) {
        if (!date) {
            return null;
        }
        return format ? moment(date).format('YYYY-MM-DD') : moment(date);
    }

    save() {
        const startTime = this.getDateValue(this.start, true);
        const deadlineTime = this.getDateValue(this.end, true);
        const finalVersion = this.finalVersion.value;

        this.isLoading = true;
        this.tasksService
            .update(this.task.id, {
                name: this.taskName,
                description: this.htmlContent,
                start_time: startTime,
                deadline_time: deadlineTime,
                final_version: finalVersion,
                tags: this.selectedTags.map((tag) => tag.id),
            })
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (): any => this.notificationService.success(this.TASK_UPDATED_SUCCESS_TOASTR),
                error: (err: any) => this.notificationService.error(this.SOMETHING_WRONG_TOASTR),
            });
    }

    runFireWorks() {
        let count = 0;

        const intervalId = setInterval(() => {
            if (count < 3) {
                this.confettiService.shoot();
                count++;
            } else {
                clearInterval(intervalId);
            }
        }, 800);
    }

    changeStatus(status: number) {
        this.isLoading = true;
        this.tasksService
            .update(this.task.id, { task_status_id: status })
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (): any => {
                    this.loadTask();
                    this.notificationService.success(this.TASK_STATUS_CHANGED_SUCCESS_TOASTR);

                    if (status === 3) {
                        this.runFireWorks();
                    }
                },
                error: (err: any) => this.notificationService.error(this.SOMETHING_WRONG_TOASTR),
            });
    }

    updateStudents(studentsIds: number[]) {
        this.tasksService.update(this.task.id, { description: this.htmlContent, users: studentsIds }).subscribe({
            next: (): any => {
                this.notificationService.success(this.TASK_ASSIGNEES_CHANGED_SUCCESS_TOASTR);
                this.loadTask();
            },
            error: (err: any) => this.notificationService.error(this.SOMETHING_WRONG_TOASTR),
        });
    }

    setTaskType(taskType: any) {
        this.isLoading = true;
        this.tasksService
            .update(this.task.id, { task_type_id: taskType.id })
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (): any => {
                    this.loadTask();
                    this.notificationService.success(this.TASK_UPDATED_TYPE_TOASTR);
                },
                error: (err: any) => {
                    this.notificationService.error(this.SOMETHING_WRONG_TOASTR);
                },
            });
    }

    getTaskTags() {
        this.tagsService.getAll().subscribe(({ data }: any) => {
            this.tags = data || [];
        });
    }

    generetePresentation() {
        this.isPresentationLoading = true;
        this.presentationService
            .generate()
            .pipe(finalize(() => (this.isPresentationLoading = false)))
            .subscribe({
                next: ({ data }: any) => this.presentationService.downloadPPTX(data.encoded_file, data.presentation),
                error: (err: any) => this.notificationService.error(this.SOMETHING_WRONG_TOASTR),
            });
    }

    setTaskDate(event: DateChangedEvent) {
        this.start = event.begin ? event.begin : null;
        this.end = event.end ? event.end : null;
    }

    pushTag(tag: any) {
        this.availableTags = this.availableTags.filter((tagItem) => tagItem.id !== tag.id);
        this.selectedTags.push(tag);
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    removeTag(tag: any) {
        this.selectedTags = this.selectedTags.filter((tagItem) => tagItem.id !== tag.id);
        this.availableTags.push(tag);
    }

    openEditTagsModal(dialogRef: TemplateRef<any>) {
        this.availableTags = this.tags.filter(
            (tagItem) => !this.selectedTags.map((tag) => tag.id).includes(tagItem.id),
        );

        this.dialog.open(dialogRef, { autoFocus: false });
    }

    openRenameTaskDialog(dialogRef: TemplateRef<any>) {
        this.taskNameControl.setValue(this.taskName);

        this.dialog.open(dialogRef, {autoFocus: true,});
    }

    saveRenamedTaskName() {
        this.taskName = this.taskNameControl.value;
        this.closeDialog();
    }

    setTaskTags() {
        this.taskTags = this.selectedTags;
        this.closeDialog();
    }

    get SOMETHING_WRONG_TOASTR() {
        return this.translate.instant('communications.workspace.something_wrong_toastr');
    }

    get TASK_UPDATED_SUCCESS_TOASTR() {
        return this.translate.instant('communications.workspace.task_updated_success_toastr');
    }

    get TASK_STATUS_CHANGED_SUCCESS_TOASTR() {
        return this.translate.instant('communications.workspace.task_status_changed_success_toastr');
    }

    get TASK_ASSIGNEES_CHANGED_SUCCESS_TOASTR() {
        return this.translate.instant('communications.workspace.task_assignees_changed_success_toastr');
    }

    get TASK_UPDATED_TYPE_TOASTR() {
        return this.translate.instant('communications.workspace.task_updated_success_toastr');
    }

    get isBasicTaskType() {
        return this.task?.created_type === 'basic';
    }
}
