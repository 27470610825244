import { environment } from 'src/environments/environment';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { VotesTopicsService } from 'src/app/services/votes-topics.service';
import { VotesResultsTable } from 'src/app/tables/VotesResultsTable';

@Component({
    selector: 'app-votes-results',
    templateUrl: './votes-results.component.html',
    styleUrls: ['./votes-results.component.css'],
})
export class VotesResultsComponent implements OnInit {
    results: any[] = [];
    isLoading: boolean = true;
    storageUrl: string = environment.storageUrl;
    ENTRY_NAME: string = 'Last vote results';

    table = new VotesResultsTable();

    @ViewChild('resultBarTemplate') resultBarTemplate: TemplateRef<any>;
    @ViewChild('usersTemplate') usersTemplate: TemplateRef<any>;

    constructor(private votesService: VotesTopicsService, public sessionService: SessionService) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.fetch();
    }

    fetch() {
        this.isLoading = true;
        this.votesService.getResults().subscribe((response: any) => {
            this.results = response.data || [];
            this.table.resultBarTemplate = this.resultBarTemplate;
            this.table.usersTemplate = this.usersTemplate;
            this.table.buildColumns();
            this.isLoading = false;
        });
    }
}
