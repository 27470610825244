import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { InstitutionsService } from 'src/app/services/institutions.service';
import { environment } from 'src/environments/environment';

const ANIMATION_DURATION = 400;

@Component({
    selector: 'app-all-institutions',
    templateUrl: './all-institutions.component.html',
    styleUrls: ['./all-institutions.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('in', style({ opacity: 1, transform: 'translateY(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateY(-100%)' }),
                animate(ANIMATION_DURATION),
            ]),
            transition('* => void', [
                animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateY(100%)' })),
            ]),
        ]),
    ],
})
export class AllInstitutionsComponent implements OnInit {
    isLoading: boolean = false;

    categories: any[] = [];
    storageUrl: string = environment.storageUrl;

    constructor(private institutionsService: InstitutionsService) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.institutionsService.getAll().subscribe((response: any) => {
            this.categories = response.data || [];
        });
    }
}
