<ng-container *ngIf="!isLoading">
    <div [@flyInOut] class="mb-4">
        <app-un-page-sub-panel [title]="game?.name" [backUrl]="'/games'" [onlyBack]="true"></app-un-page-sub-panel>
    </div>

    <div class="grid grid-cols-12 gap-4 game-details">
        <div [@flyleftIn] class="col-span-12 lg:col-span-8">
            <img class="game-image-screen" [src]="storageUrl + game?.image_path" alt="" />
        </div>
        <div [@flyRigthIn] class="col-span-12 lg:col-span-4 flex flex-col">
            <button
                matRipple
                class="play-game-button text-uppercase font-weight-bold mb-3"
                (click)="goToGamePage(game?.game_url)"
            >
                Start Play
            </button>
            <!-- <button matRipple class="download-game-button text-uppercase font-weight-bold mb-4">Download game</button> -->
            <div class="flex justify-between game-details-row py-2">
                <div><span>Platform</span></div>
                <div>
                    <span><i class="ph-google-chrome-logo"></i></span>
                </div>
            </div>
            <div class="flex justify-between game-details-row py-2">
                <div><span>Developer</span></div>
                <div><span>free</span></div>
            </div>
            <div class="flex justify-between game-details-row mb-4 py-2">
                <div><span>Size</span></div>
                <div><span>1.34 MB</span></div>
            </div>
            <!-- <div class="flex justify-between" style="gap: 16px">
                <div class="flex-grow-1 flex-shrink-1" style="flex: 1">
                    <button matRipple class="game-action-button w-full"><i class="ph-github-logo"></i>Git Repository</button>
                </div>
                <div class="flex-grow-1 flex-shrink-1" style="flex: 1">
                    <button matRipple class="game-action-button w-full"><i class="ph-share-network"></i>Share</button>
                </div>
            </div> -->
        </div>
    </div>
</ng-container>

<div *ngIf="isLoading" class="flex justify-center items-center" style="height: 440px">
    <app-un-game-loader></app-un-game-loader>
</div>
