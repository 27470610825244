import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-un-game-loader',
    templateUrl: './un-game-loader.component.html',
    styleUrls: ['./un-game-loader.component.scss'],
})
export class UnGameLoaderComponent {
    @Input() message: string = '';

    constructor() {}
}
