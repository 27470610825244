<div class="py-8 px-14 2xl:py-10 2xl:px-16 bg-r h-[520px] max-w-[1000px] min-w-[942px] flex flex-col overflow-hidden">
    <ng-container *ngIf="pages[0]">
        <div class="grid grid-cols-12 gap-x-4 mb-3">
            <div class="col-span-9 flex flex-col text-start">
                <div>
                    <span [@flyleftIn] class="text-[#E1DEF5] text-3xl font-medium leading-snug uppercase">
                        {{ 'welcome_pop_up.welcom_to_unispher' | translate }}
                    </span>
                </div>
                <div class="mb-3">
                    <span class="text-[#E1DEF5] text-3xl font-medium leading-snug uppercase">
                        {{ 'welcome_pop_up.project' | translate }} <span class="text-[#FF02D0]">{{ projectTitle }}</span
                        >!
                    </span>
                </div>
                <div>
                    <div class="mb-1">
                        <span class="text-xs leading-4 text-[#8E91D1]">
                            {{ 'welcome_pop_up.description' | translate }} :</span
                        >
                    </div>
                    <div class="text-sm leading-7 text-[#EDF2FF]">
                        <span class="flex">
                            {{ 'welcome_pop_up.description_part_one' | translate }}
                        </span>
                        <span class="flex">
                            {{ 'welcome_pop_up.description_part_two' | translate }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-span-3 flex justify-center">
                <img class="w-auto h-60" src="/assets/rob.png" alt="" />
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="pages[1]">
        <div class="grid grid-cols-12 gap-x-4 max-w-[1000px] mb-3">
            <div class="col-span-12 flex flex-col text-start">
                <div class="mb-3">
                    <span [@flyleftIn] class="text-[#E1DEF5] text-3xl font-medium leading-snug uppercase">
                        {{ 'welcome_pop_up.methodology_title' | translate }}
                    </span>
                </div>

                <div class="mb-2">
                    <div class="text-sm leading-7 text-[#EDF2FF]">
                        <span class="flex">
                            {{ 'welcome_pop_up.methodology_desc' | translate }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12 gap-x-8 mb-3">
            <ng-container *ngFor="let methodology of methodologies">
                <div
                    class="col-span-4 methodology-box hover:shadow-lg hover:-translate-y-1 cursor-pointer p-4 text-start"
                    [class.methodology-box-selected]="methodology.selected"
                    (click)="selectMethodology(methodology)"
                >
                    <div class="mb-2">
                        <span class="text-base font-bold leading-5 uppercase text-[#E1DEF5]">
                            {{ methodology.name }}
                        </span>
                    </div>
                    <ul class="list-disc text-xs text-[#B6B2D4] leading-7 px-4 mb-0">
                        <li *ngFor="let step of methodology.steps">{{ step }}</li>
                        <ng-container *ngIf="methodology.steps.length === 0">
                            {{ 'welcome_pop_up.methodology.coming_soon' | translate }} ...
                        </ng-container>
                    </ul>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="pages[2]">
        <div class="grid grid-cols-12 gap-x-4 max-w-[1000px] mb-3 text-start">
            <div class="col-span-12 flex flex-col">
                <div class="mb-3">
                    <span [@flyleftIn] class="text-[#E1DEF5] text-3xl font-medium leading-snug uppercase">
                        {{ 'welcome_pop_up.tags_title' | translate }}
                    </span>
                </div>

                <div class="mb-2">
                    <div class="text-sm leading-7 text-[#EDF2FF]">
                        <span class="flex"> {{ 'welcome_pop_up.tags_desc' | translate }} </span>
                    </div>
                </div>
                <div class="my-3">
                    <span class="text-xs leading-4 text-[#8E91D1]">
                        {{ 'welcome_pop_up.user_choice' | translate }}:
                    </span>
                </div>
                <div class="mb-1 flex flex-wrap gap-2">
                    <ng-container *ngFor="let tag of userTags">
                        <app-tag [tag]="tag" (clicked)="removeTag(tag)"></app-tag>
                    </ng-container>
                </div>
                <div class="mb-3"></div>
                <div class="mb-3">
                    <span class="text-xs leading-4 text-[#8E91D1]">{{ 'welcome_pop_up.all_tags' | translate }}:</span>
                </div>
                <div class="mb-1 flex flex-wrap gap-2">
                    <ng-container *ngFor="let tag of availableTags">
                        <span
                            class="bg-[#363160] text-[#9F8DD0] px-2 py-1 capitalize rounded-md cursor-pointer transition-all"
                            (click)="pushTag(tag)"
                        >
                            <div class="flex items-center gap-x-1">
                                <i class="ph text-base" [ngClass]="'ph-' + tag.icon"></i>
                                <span>
                                    {{ tag.label }}
                                </span>
                            </div>
                        </span>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="flex items-center mt-auto">
        <div class="col-span-2 flex justify-end ltr:mr-auto rtl:ml-auto">
            <div [class.invisible]="pages[0]">
                <app-un-back-button (click)="movePrevPage()"></app-un-back-button>
            </div>
        </div>

        <div class="col-span-10 flex justify-center gap-x-2">
            <ng-container *ngFor="let page of pages">
                <div
                    class="w-2 h-2 rounded-full transition-all"
                    [ngClass]="{ '!bg-[#FF02D0]': page, 'bg-transparent': !page, border: !page }"
                ></div>
            </ng-container>
        </div>

        <div class="col-span-2 flex justify-end ltr:ml-auto rtl:mr-auto">
            <div>
                <app-un-save-button
                    *ngIf="!pages[pages.length - 1]"
                    [text]="'welcome_pop_up.next_button' | translate"
                    (click)="moveNextPage()"
                ></app-un-save-button>
                <app-un-save-button
                    *ngIf="pages[pages.length - 1]"
                    [text]="'welcome_pop_up.save_button' | translate"
                    (click)="setUserTags()"
                ></app-un-save-button>
            </div>
        </div>
    </div>
</div>
