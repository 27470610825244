import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UnTabComponent } from './un-tab/un-tab.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-un-tabs',
    templateUrl: './un-tabs.component.html',
    styleUrls: ['./un-tabs.component.scss'],
})
export class UnTabsComponent implements OnInit {
    @Input() tabs: any = [];
    @Input() selectedTabIndex: number = 0;

    @Output() tabChanged: EventEmitter<any> = new EventEmitter();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        const activeTabs = this.tabs.filter((tab: any) => tab.active);

        if (activeTabs.length === 0) {
            this.selectTab(this.tabs[this.selectedTabIndex]);
        }
    }

    selectTab(tab: any) {
        this.tabs.forEach((tab: any) => (tab.active = false));
        tab.active = true;
        this.tabChanged.emit(tab);

        // Update URL with query parameter:
        this.router.navigate([], {
            relativeTo: this.route, // Navigate relative to the current route
            queryParams: { tab: tab.selector }, // Set the 'tab' query parameter
            queryParamsHandling: 'merge', // Preserve existing query parameters
        });
    }
}
