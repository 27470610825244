import { Component } from '@angular/core';
import { FriendsService } from 'src/app/services/friends.service';
import { SessionService } from 'src/app/services/session.service';



@Component({
  selector: 'friends-bar-header',
  templateUrl: './friends-bar-header.component.html',
  styleUrls: ['./friends-bar-header.component.scss'],

})
export class FriendsBarHeaderComponent {

  search: string = '';
  mode: string = 'list';



  constructor(
    public session: SessionService,
    private friendsService: FriendsService
  ) { }


  submit() {
    this.friendsService.searchFriends(this.search);
  }

  submitByKey() {
    if(this.mode === 'list') {
      this.submit();
    }
  }

  setMode(mode: string) {
    this.search = '';
    this.mode = mode;
    this.friendsService.changeSideBarFriendsListMode(mode);
  }

  findFriend() {
    this.friendsService.findFriends(this.search);
  }


}
