import { Component, Output, EventEmitter, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { Tools } from 'src/app/tools';
import { Subscription } from 'rxjs';
// import { LoaderService } from 'src/app/services/loader.service';

@Component({
    selector: 'app-modal-footer',
    templateUrl: './modal-footer.component.html',
    styleUrls: ['./modal-footer.component.css'],
})
export class ModalFooterComponent implements OnChanges, OnDestroy {
    @Input() isFormValid?: boolean = false;
    @Input() showDelete?: boolean = false;
    @Input() showSave?: boolean = true;
    loading?: boolean = false;

    @Output() closed: EventEmitter<any> = new EventEmitter();
    @Output() saved: EventEmitter<any> = new EventEmitter();
    @Output() deleted: EventEmitter<any> = new EventEmitter();

    subscriptions: Subscription[] = [];

    // constructor(public loaderService: LoaderService | undefined ) {
    //   this.loaderService.httpProgress().subscribe((val: boolean) => {
    //     if (val) {
    //       this.loading = false;
    //       this.close();
    //     }
    //   });
    // }

    constructor() {
        this.loading = false;
    }

    /**
     * on component param change
     */
    ngOnChanges(changes: SimpleChanges): void {
        if ('isFormValid' in changes) {
            this.isFormValid = changes.isFormValid.currentValue;
        }
    }

    ngOnDestroy() {
        Tools.unsubscribeAll(this.subscriptions);
    }

    /**
     * trigger close
     */
    close() {
        this.closed?.emit([]);
    }

    /**
     * trigger save
     */
    save() {
        this.saved?.emit([]);
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 600);
    }

    /**
     * trigger deleted
     */
    delete() {
        this.deleted?.emit([]);
    }
}
