import { environment } from 'src/environments/environment';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { UsersService } from 'src/app/services/users.service';
import { finalize } from 'rxjs/operators';
import { UploadUsersFromFileForm } from 'src/app/forms/UploadUsersFromFileForm';
import { MatDialog } from '@angular/material/dialog';
import { InviteStudentsListTable } from 'src/app/tables/InviteStudentsListTable';
import { Tools } from 'src/app/tools';
import { RolesService } from 'src/app/services/roles.service';
import { InviteService } from 'src/app/services/invite.service';

@Component({
    selector: 'app-manage-students',
    templateUrl: './manage-students.component.html',
    styleUrls: ['./manage-students.component.css'],
})
export class ManageStudentsComponent implements OnInit {
    students: any[] = [];
    isLoading: boolean = true;
    invitesLoading: boolean = false;

    storageUrl: string = environment.storageUrl;
    ENTRY_NAME: string = 'Students';
    STUDENT_ROLE_ID: number = 2;

    usersPerPage: number = this.usersService.getUsersPerPage();

    table = new InviteStudentsListTable();

    @ViewChild('invitationTemplate') invitationTemplate: TemplateRef<any>;

    uploadForm = new UploadUsersFromFileForm();

    constructor(
        private usersService: UsersService,
        private rolesService: RolesService,
        private inviteService: InviteService,
        private notificationService: NotificationService,
        private dialog: MatDialog,
        public sessionService: SessionService
    ) {}

    ngOnInit() {
        this.uploadForm.buildForm();
        this.getRoles();
        this.fetch();
    }

    fetch() {
        this.isLoading = true;
        this.usersService.getInviteUsersListByRole('student').subscribe((response: any) => {
            this.students = response.data || [];
            this.table.invitationTemplate = this.invitationTemplate;
            this.table.buildColumns();
            this.isLoading = false;
        });
    }

    getRoles() {
        this.rolesService.getAll().subscribe((response: any) => {
            const studentRoleId = response.data.find((role: any) => role.name === 'student').id;
            this.table.buildForm(studentRoleId);
        });
    }

    openDialog(dialogRef: TemplateRef<any>) {
        this.dialog.open(dialogRef);
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    create(data: any) {
        this.usersService
            .createInviteUser(Tools.getFormData(data))
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' created successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    update(data: any) {
        this.usersService
            .updateInviteUser(data.id, Tools.getFormData(data))
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' updated successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    delete(data: any) {
        this.usersService
            .deleteInviteUser(data.id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' deleted successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    upload(data: any) {
        const formData = new FormData();
        formData.append('file', data.file, data.file.name);
        this.usersService.uploadUsersFromFile(formData).subscribe(
            (response: any) => {
                this.students = this.students.concat(response.data);
                // this.fetch();
                this.closeDialog();
            },
            (err) => {
                this.notificationService.error(err.error.message, 'Error');
            }
        );
    }

    sendInvites(usersIds: number[]) {
        this.invitesLoading = true;
        this.inviteService
            .sendInvites(usersIds)
            .pipe(finalize(() => (this.invitesLoading = false)))
            .subscribe(
                () => {
                    this.notificationService.success('invitations sent successfully');
                },
                (err: any) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }
}
