import { settings } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { StudioService } from 'src/app/services/studio.service';

@Component({
    selector: 'app-studio',
    templateUrl: './studio.component.html',
    styleUrls: ['./studio.component.scss'],
})
export class StudioComponent implements OnInit {
    isLoading: boolean = false;

    tools: any[] = this.studioService.getTools();
    projects: any[] = this.studioService.getFakeProjects();

    selectedProject: any;

    constructor(private title: Title, private studioService: StudioService) {}

    ngOnInit() {
        this.title.setTitle(settings.title + 'Studio');
        this.fetch();
    }

    fetch() {
        this.studioService.getUnityLink().subscribe((response: any) => {
            this.tools[2].link = response.data.url;
            this.studioService.setUnityLink(response.data.url);
            this.tools[2].loading = false;
        });
    }
}
