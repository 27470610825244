import { settings } from 'src/environments/environment';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UntypedFormGroup } from '@angular/forms';
import { SessionService } from 'src/app/services/session.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { QuestionControlService } from 'src/app/services/question-control.service';

@Component({
    selector: 'app-student-questionnaire',
    templateUrl: './student-questionnaire.component.html',
    styleUrls: ['./student-questionnaire.component.scss'],
})
export class StudentQuestionnaireComponent implements OnInit {
    @Output() finished: EventEmitter<any> = new EventEmitter();

    groups: any[] = [];

    disciplinesFormGroup: UntypedFormGroup;

    questions: any[] = [];
    disciplines: any[] = [];

    answers: any = {};
    selectedDisciplines: any = {};

    isLoading: boolean = false;
    isDisplayStartScreen: boolean = true;

    constructor(
        private router: Router,
        private notificationService: NotificationService,
        private questionControlService: QuestionControlService,
        private sessionService: SessionService,
        private questionnaireService: QuestionnaireService,
        public translate: TranslateService,
        private title: Title
    ) {}

    ngOnInit() {
        this.title.setTitle(settings.title + 'Questionnaire');
        if (this.sessionService.getQuestionnaire()) {
            this.router.navigateByUrl('/home');
        }

        this.fetch();
    }

    fetch() {
        this.questionnaireService.getBySchool().subscribe((response: any) => {
            this.disciplines = response.data.disciplines || [];
            this.groups = response.data.groups;
            this.questions = response.data.groups[0].questions;
            this.disciplinesFormGroup = this.questionControlService.toFormGroup(this.disciplines);
            this.setDisciplinesAnswersFromApi();
        });
    }

    setDisciplinesAnswersFromApi(): void {
        this.disciplines.forEach((discipline) => {
            if (discipline.mark) {
                this.setDisciplineInterest(discipline, discipline.mark);
            }
        });
    }

    next(formValue: any, stepper: MatStepper, last: boolean) {
        this.setAnswers(formValue);
        if (last) {
            this.submit();
            return;
        }
        stepper.next();
        this.scrollToSectionHook();
    }

    skipStep(stepper: MatStepper) {
        stepper.next();
        this.scrollToSectionHook();
    }

    private scrollToSectionHook() {
        const element = document.querySelector('.quest-container');
        if (element) {
            setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }, 250);
        }
    }

    setAnswers(formData: any) {
        for (const key in formData) {
            if (formData.hasOwnProperty(key)) {
                this.answers[key] = formData[key];
            }
        }
    }

    submit() {
        this.saveResult({ disciplines: this.selectedDisciplines, questions: this.answers });
    }

    saveResult(result: any) {
        this.questionnaireService.saveResult(result).subscribe(
            (response: any) => {
                this.notificationService.success(this.SUCCESS_SUBMIT_TOASTR);
                this.finished.emit();
            },
            (error) => {
                this.notificationService.error(this.ERROR_SUBMIT_TOASTR);
            }
        );
    }

    setDisciplineInterest(discipline: any, mark: number) {
        this.selectedDisciplines[discipline.id] = mark;
        this.disciplinesFormGroup.controls[discipline.id].setValue(mark);
    }

    goBack(stepper: MatStepper) {
        stepper.previous();
    }

    start() {
        this.isDisplayStartScreen = false;
    }

    get SUCCESS_SUBMIT_TOASTR() {
        return this.translate.instant('questionnaire.student.submit_toastr');
    }

    get ERROR_SUBMIT_TOASTR() {
        return this.translate.instant('questionnaire.student.error_toastr');
    }

    get disciplineText() {
        return this.sessionService.getCurrentUserSchool().questionnaire_details?.discipline_text;
    }
}
