import { finalize } from 'rxjs/operators';
import { SessionService } from './session.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class LoginService {
    constructor(private http: HttpClient, private sessionSerice: SessionService) {}

    logIn(data: any) {
        return this.http.post(environment.apiUrl + '/login', data);
    }

    register(data: any) {
        return this.http.post(environment.apiUrl + '/register', data);
    }

    /**
     * logout
     *
     * delete token and finally call sessionService.clear()
     */
    logOut() {
        return this.http.post(environment.apiUrl + '/logout', {}).pipe(
            finalize(() => {
                this.sessionSerice.clear();
            })
        );
    }

    isLoggedIn() {
        return this.sessionSerice.getToken();
    }

    forgotPassword(email: any) {
        const params = new HttpParams().set('forgot-password', 'true');
        return this.http.post(environment.apiUrl + '/forgot-password', { email: email }, { params: params });
    }

    resetPassword(data: any) {
        return this.http.post(environment.apiUrl + '/reset-password', data);
    }
}
