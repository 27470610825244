import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Url } from '../url';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root',
})
export class TasksService {
    public API: string = '/tasks';

    public GUIDE_TYPE: string = 'guide';

    public BASIC_TYPE: string = 'basic';

    constructor(
        private http: HttpClient,
        private notificationService: NotificationService,
    ) {}

    getAll() {
        return this.http.get(new Url(this.API).path);
    }

    getById(id: number) {
        return this.http.get(new Url(this.API + '/' + id).path);
    }

    create(data: any) {
        return this.http.post(environment.apiUrl + '/team-tasks', data);
    }

    update(id: number, data: any) {
        return this.http.put(environment.apiUrl + '/team-tasks/' + id, data);
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + '/team-tasks/' + id);
    }

    getTasksStats() {
        return this.http.get(environment.apiUrl + this.API + '/stats');
    }

    getTeamTasksStages(teamId: number) {
        return this.http.get(environment.apiUrl + '/teams/development-stages/team/' + teamId);
    }

    getTeamTasks(taskId: number) {
        return this.http.get(environment.apiUrl + '/team-tasks/' + taskId);
    }

    getTaskTypes() {
        return this.http.get(environment.apiUrl + '/task-types');
    }

    // getTaskTypes() {
    //     return this.http.get(new Url('/task-types').path).pipe(
    //         map(({ data }: any) => {
    //             return data;
    //         }),
    //         catchError((error) => {
    //             this.notificationService.error('Error: ' + error);
    //             return [];
    //         })
    //     );
    // }

    getTeamStudents(teamId: number) {
        return this.http.get(environment.apiUrl + '/user-teams/team/' + teamId);
    }

    setTaskOrder(id: number, data: any) {
        return this.http.put(environment.apiUrl + '/team-tasks/order/' + id, data);
    }

    assignTaskStudents() {}

    isGuide(taskType: string): boolean {
        return taskType === this.GUIDE_TYPE;
    }

    isBasic(createdType: string): boolean {
        return createdType === this.BASIC_TYPE;
    }
}
