<button
    matRipple
    class="py-2"
    [class.selected]="selected"
    [ngClass]="{ 'w-full': fullWidth, bordered: bordered }"
    [disabled]="disabled"
>
    {{ text }}
    <ng-content></ng-content>
</button>
