import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent {
    title: string = 'Are you sure?';

    constructor(@Inject(MAT_DIALOG_DATA) data: any) {
        this.title = data.title;
    }
}
