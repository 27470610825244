import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Tools } from 'src/app/tools';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { TasksService } from 'src/app/services/tasks.service';
import { TaskStatuses } from '../../../task-card/task-card.component';

@Component({
    selector: 'app-simple-card',
    templateUrl: './simple-card.component.html',
    styleUrls: ['./simple-card.component.scss'],
    providers: [],
})
export class SimpleCardComponent {
    @Input() card: any;

    @Output() taskUpdated: EventEmitter<any> = new EventEmitter();

    readonly TaskStatuses = TaskStatuses;

    storageUrl: string = environment.storageUrl;

    constructor(
        private router: Router,
        public translate: TranslateService,
        public tasksService: TasksService,
    ) {}

    get isTaskComplete(): boolean {
        return this.card.status?.status === TaskStatuses.Done;
    }

    get startButtonText() {
        return this.tasksService.isGuide(this.card.task_type.type)
            ? this.READ_GUIDE_BUTTON
            : this.START_THIS_TASK_BUTTON;
    }

    get isCustomType() {
        return this.card.created_type === 'custom';
    }

    getImage() {
        return this.isCustomType ? '/assets/custom-task-view.png' : this.storageUrl + this.card.image;
    }

    goToTaskPage() {
        this.router.navigateByUrl('/communications/manage-task/' + this.card.id);
    }

    removeTagsFromString(string: string) {
        return Tools.removeTagsFromString(string);
    }

    get READ_GUIDE_BUTTON() {
        return this.translate.instant('communications.workspace.read_guide_button');
    }

    get START_THIS_TASK_BUTTON() {
        return this.translate.instant('communications.workspace.start_this_task_button');
    }
}
