<app-card class="mb-3" [title]="ENTRY_NAME" [paddingX]="false">
  <div class="mb-1 flex justify-end px-3">
    <button mat-button color="accent" (click)="downloadAnswersCSV()">
      <i class="ph-download-simple text-large"></i>
      <span>CSV</span>
    </button>
    <button mat-button color="accent" (click)="downloadAnswersXLSX()">
      <i class="ph-download-simple text-large"></i>
      <span>XLS</span>
    </button>
  </div>
  <div *ngIf="!isLoading">
    <app-table
      [columns]="table.columns"
      [data]="surveysAnswers"
      [itemsPerPage]="10"
      [itemsPerPageSelect]="[10, 25, 50]"
      
    >
    </app-table>
  </div>
  <app-un-game-loader *ngIf="isLoading"></app-un-game-loader>
</app-card>
