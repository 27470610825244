import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';
import { TeamsService } from 'src/app/services/team.service';
import { GenerateTeamForm } from 'src/app/forms/GenerateTeamForm';
import { MatDialog } from '@angular/material/dialog';
import { ProjectsService } from 'src/app/services/projects.service';

@Component({
    selector: 'app-team-generator',
    templateUrl: './team-generator.component.html',
    styleUrls: ['./team-generator.component.scss'],
})
export class TeamGeneratorComponent {
    @Input() isButton: boolean = false;

    form = new GenerateTeamForm();

    @Output() teamsGenerated: EventEmitter<any> = new EventEmitter();

    isLoading: boolean = false;

    isTopicsLoading: boolean = false;

    constructor(
        private teamsService: TeamsService,
        private notificationService: NotificationService,
        private projectsService: ProjectsService,
        public dialog: MatDialog,
    ) {}

    openDialog(dialogRef: TemplateRef<any>) {
        this.dialog.open(dialogRef);
        this.getTopics();
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    generate(data: any) {
        this.isLoading = true;
        this.closeDialog();

        this.teamsService
            .generateTeams(data)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => this.teamsGenerated.emit(),
                error: () => this.notificationService.error('an error occurred while generating teams', 'Error'),
            });
    }

    getTopics() {
        this.isTopicsLoading = true;
        this.projectsService
            .getAll()
            .pipe(finalize(() => (this.isTopicsLoading = false)))
            .subscribe({
                next: ({ data }: any) => this.form.buildForm(data.filter((project) => !project.active)),
                error: () => this.notificationService.error('an error occurred while getting projects', 'Error'),
            });
    }
}
