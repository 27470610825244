<div class="flex mb-3">
    <div class="col-auto ml-auto">
        <button
            class="btn btn-danger ml-auto"
            (click)="close()"
            [disabled]="!isReady"
            style="background-color: #ff02d0"
        >
            <i class="fas fa-long-arrow-alt-left"></i>{{ 'games.back' | translate }}
        </button>
    </div>
</div>

<ng-container *ngIf="!isReady">
    <div class="flex">
        <div class="col-6 mx-auto">
            <app-un-game-loader></app-un-game-loader>
            <div style="height: 28vh; display: grid; align-items: center">
                <div>
                    <div class="progress">
                        <div
                            class="progress-bar"
                            style="background-color: #ff02d0"
                            role="progressbar"
                            [style.width.%]="progress * 100"
                            [attr.aria-valuenow]="progress * 100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- <div #unityEl id="gameContainer"
     style="display: block; width: 100%; min-height: 0px; margin: auto"></div> -->
<div [ngStyle]="{ display: isReady ? 'block' : 'none' }">
    <div class="flex">
        <div class="col-11">
            <div #ucontainer id="canvas" class="unity-desktop">
                <canvas #ucanvas id="unity-canvas" width="960" height="600"></canvas>
                <div #uloading id="unity-loading-bar">
                    <div id="unity-logo"></div>
                    <div id="unity-progress-bar-empty">
                        <div #uprogress id="unity-progress-bar-full"></div>
                    </div>
                </div>
                <div id="unity-warning"></div>
                <div id="unity-footer">
                    <div id="unity-webgl-logo"></div>
                    <div #ufullscreen id="unity-fullscreen-button"></div>
                    <div id="unity-build-title">Game Prototype</div>
                    <div>build in development</div>
                    <div>for demonstration only</div>
                </div>
            </div>
        </div>
        <div class="col-1">
            <div>
                <button class="btn btn-primary mb-3" (click)="setFullScreen()" [disabled]="!isReady">
                    <i class="fas fa-compress"></i>
                    full screen
                </button>
                <!-- <i class="fas fa-info-circle fa-2x" matTooltip="to go back press the Esc key"></i> -->
                <!-- <h6>to go back press the Esc key </h6> -->
            </div>
            <h6>to go back press the Esc key</h6>
        </div>
    </div>
</div>
