import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    isLoading: boolean = false;

    submited: boolean = false;

    formGroup = this.fb.group({
        email: [null, Validators.required],
    });

    constructor(
        private notificationService: NotificationService,
        private fb: UntypedFormBuilder,
        private userService: UserService
    ) {}

    ngOnInit() {}

    onSubmit() {
        this.login();
    }

    login() {
        const email = this.formGroup.get('email')?.value;
        this.userService.resetPasswordByEmail(email).subscribe(
            () => {
                this.notificationService.success('please check your mailbox to reset password');
                this.submited = true;
            },
            (err) => {
                this.notificationService.error(err.error.reason, 'Error');
            }
        );
    }
}
