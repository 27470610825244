<div #tasksContainer class="w-full px-2">
    <div class="item">
        <div class="my-3 flex h-auto justify-between">
            <span class="text-base font-bold uppercase text-[#e1def5]">
                {{ title }}
            </span>
            <div class="flex items-center gap-2">
                <div
                    class="me-2 inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 text-xs font-semibold text-gray-800 dark:bg-gray-700 dark:text-gray-300"
                >
                    {{ tasksArray.length > 99 ? '99+' : tasksArray.length + guides.length }}
                </div>
                <app-un-create-button
                    *ngIf="taskTemplates.length === 0"
                    (click)="createNewTask()"
                ></app-un-create-button>
            </div>
        </div>
    </div>

    <div class="progress">
        <span class="progress-bar" [style.width.%]="doneTasksPercent"></span>
        <div class="percent-item" [style.left.%]="doneTasksPercent"></div>
    </div>

    <div class="tasks-column gap-4 overflow-y-auto" cdkScrollable>
        <div class="my-3" *ngFor="let task of taskTemplates">
            <app-task-template
                [taskTypes]="taskTypes"
                [taskTags]="taskTags"
                (taskCreated)="addTask($event)"
                (taskClosed)="restoreTemplate()"
            >
            </app-task-template>
        </div>

        <div *ngIf="isLoading" class="my-3">
            <!-- <app-task-template [isLoading]="true"></app-task-template>-->
        </div>

        <div *ngIf="guides.length > 0" class="guides-section my-3 flex flex-col gap-3">
            <div *ngFor="let guideTask of guides">
                <app-task-card [card]="guideTask" (taskUpdated)="taskUpdated.emit()"> </app-task-card>
            </div>
        </div>

        <div
            cdkDropList
            [cdkDropListData]="tasksArray"
            (cdkDropListDropped)="drop($event)"
            class="drop-list my-3 flex min-h-[400px] touch-auto flex-col gap-3 overflow-hidden pb-3 after:block after:h-[200px]"
        >
            <app-task-card
                class="drag-element"
                *ngFor="let task of tasksArray"
                cdkDrag
                [card]="task"
                [displayTags]="true"
                (taskUpdated)="taskUpdated.emit()"
            >
                <div cdkDragHandle class="drag-handle mb-2">
                    <i class="ph-arrows-out-cardinal-bold cursor-grab text-2xl text-un-pink"></i>
                </div>

                <div
                    class="min-h-[150px] w-full rounded-[8px] border-4 border-dashed border-un-pink/80 bg-[#08051F]/70 p-4 text-center align-middle text-un-pink transition duration-200"
                    *cdkDragPlaceholder
                >
                    <div>move your task here</div>
                </div>
            </app-task-card>
        </div>
    </div>
</div>
