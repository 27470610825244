import { Table } from './Table';

export class VotesResultsTable extends Table {
    public resultBarTemplate: any;
    public usersTemplate: any;

    buildColumns() {
        this.columns = [
            //   {
            //       name: 'Author',
            //       selector: 'author',
            //       class: () => 'text-nowrap',
            //       transform: (author) => author.nickname
            //   },
            {
                name: 'Title',
                selector: 'title',
                class: () => 'text-nowrap',
            },
            //   {
            //     name: 'Description',
            //     selector: 'description',
            //   },
            {
                name: 'Result',
                selector: 'votes',
                headerClass: 'text-center',
                sort: true,
                class: () => 'text-center cursor-pointer',
                content: {
                    template: this.resultBarTemplate,
                    context: (row) => ({ votes: row.votes_percent }),
                },
            },
            {
                name: '',
                selector: 'result',
                headerClass: 'text-center',
                class: () => 'text-center cursor-pointer',
                content: {
                    template: this.usersTemplate,
                    context: (row) => ({ users: row.users }),
                },
            },
            // {
            //     name: 'Image',
            //     selector: 'image',
            //     headerClass: 'text-nowrap text-center',
            //     class: () => 'text-center',
            //     type: 'image',
            //     trustHtml: true,
            // },
            // {
            //     name: 'Type',
            //     selector: 'type',
            //     headerClass: 'text-nowrap',
            // },
            // {
            //     name: 'Active',
            //     selector: 'active',
            //     headerClass: 'text-nowrap text-center',
            //     class: () => 'text-center',
            //     transform: (active) => active ? '<i class="ph ph-circle text-green"></i>' : '<i class="ph ph-circle text-red"></i>'
            // }
        ];
    }
}
