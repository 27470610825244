import { SessionService } from '../services/session.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';

@Injectable()
export class LoginGuard  {
    constructor(private router: Router, private sessionService: SessionService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const redirectUrl = route.pathFromRoot
            .map((v) => v.url.map((segment) => segment.toString()).join('/'))
            .join('/');

        if (this.sessionService.getToken()) {
            return true;
        }

        this.router.navigateByUrl(this.router.createUrlTree(['/login'], { queryParams: { redirectUrl } }), {
            skipLocationChange: true,
        });

        return false;
    }
}
