<div *ngIf="!isFirstTry && !isFinished" class="col-auto mx-4">
    <app-un-back-button (сlicked)="goBack()"></app-un-back-button>
</div>

<ng-container *ngIf="!isFinished">
    <ng-container *ngIf="user.role === STUDENT_ROLE">
        <app-student-questionnaire (finished)="finished()"></app-student-questionnaire>
    </ng-container>

    <ng-container *ngIf="user.role === TEACHER_ROLE">
        <app-teacher-questionnaire (finished)="finished()"></app-teacher-questionnaire>
    </ng-container>

    <ng-container *ngIf="user.role !== TEACHER_ROLE && user.role !== STUDENT_ROLE">
        <div class="flex justify-center items-center min-h-[300px]">
            <div class="border border-[#FF02D0] p-4 rounded-lg">
                <span class="text-3xl text-[#FF02D0]">{{ 'questionnaire.access_denied' | translate }}</span>
                <div class="flex justify-end mt-4">
                    <app-un-save-button
                        [text]="'questionnaire.success.start_platform_button' | translate"
                        (click)="gotToPlatform()"
                    ></app-un-save-button>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="isFinished">
    <app-questionnaire-success [user]="user" (finishedQuestionnaire)="gotToPlatform()"> </app-questionnaire-success>
    <!-- <app-questionnaire-success *ngIf="isDisplaySuccessPage" [user]="user" (finishedQuestionnaire)="gotToPlatform()">
    </app-questionnaire-success>

    <app-questionnaire-theme-selector *ngIf="isDisplayVotes" (themeSelected)="voteTheme($event)">
    </app-questionnaire-theme-selector>

    <app-questionnaire-theme-selector *ngIf="isDisplayCurrentVote" [suggestThemeMode]="false" (themeSelected)="voteTheme($event)"></app-questionnaire-theme-selector>

    <app-submit-selected-theme *ngIf="submitTheme" [user]="user" (reselectTheme)="selectAnotherTheme()">
    </app-submit-selected-theme> -->
</ng-container>
