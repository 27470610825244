import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgxGlideComponent } from 'ngx-glide';
import { flyBottomIn, flyInOut, flyleftIn, flyRigthIn } from 'src/app/animations/fly-animations';
import { TasksService } from 'src/app/services/tasks.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-simple-stage-view',
    templateUrl: './simple-stage-view.component.html',
    styleUrls: ['./simple-stage-view.component.scss'],
    animations: [flyleftIn, flyBottomIn, flyRigthIn, flyInOut],
})
export class SimpleStageViewComponent {
    @Input() title: string = '';
    @Input() tasks: any[] = [];

    @Input() stageId: number = 0;
    @Input() teamId: number = 0;
    @Input() stageStatus: string;

    tasksArray: any[] = [];
    guides: any[] = [];

    isLoading: boolean = false;
    isClosed: boolean = false;

    @ViewChild('ngxGlide') ngxGlide!: NgxGlideComponent;
    @ViewChild('customArrowLeft', { static: true }) customArrowLeftTemplate!: TemplateRef<any>;
    @ViewChild('customArrowRight', { static: true }) customArrowRightTemplate!: TemplateRef<any>;

    @ViewChild('scrollContainer') scrollContainer: ElementRef;

    @Output() taskUpdated: EventEmitter<any> = new EventEmitter();

    private scrollInterval: any;
    private holdStartTime: number;

    drop(event: CdkDragDrop<any[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }

        // Prepare the data for the PUT request
        const movedTask = event.container.data[event.currentIndex];
        const updateData = {
            order_id: event.currentIndex + this.guides.length,
            team_dev_stage_id: this.stageId,
        };

        this.reorderTask(movedTask, updateData);
    }

    reorderTask(movedTask: any, updateData: any) {
        this.isLoading = true;
        this.tasksService
            .setTaskOrder(movedTask.id, updateData)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (response) => {
                    //this.taskUpdated.emit();
                },
                error: (error) => {},
                complete: () => {},
            });
    }
    @HostListener('mouseup', ['$event'])
    @HostListener('mouseleave', ['$event'])
    onMouseUp() {
        this.stopScroll();
    }

    constructor(
        private router: Router,
        private renderer: Renderer2,
        private tasksService: TasksService,
    ) {}

    ngOnInit() {
        this.tasksArray = this.tasks.filter((task) => !this.tasksService.isGuide(task.task_type.type));
        this.guides = this.tasks.filter((task) => this.tasksService.isGuide(task.task_type.type));
    }

    scrollLeft() {
        this.scrollContainer.nativeElement.scrollTo({
            left: this.scrollContainer.nativeElement.scrollLeft - 400, // Separate click scroll distance
            behavior: 'smooth',
        });
    }

    scrollRight() {
        this.scrollContainer.nativeElement.scrollTo({
            left: this.scrollContainer.nativeElement.scrollLeft + 400, // Separate click scroll distance
            behavior: 'smooth',
        });
    }

    startScroll(direction: 'left' | 'right') {
        this.stopScroll(); // Stop any ongoing scrolling

        this.holdStartTime = Date.now();
        let speed = 10;

        this.scrollInterval = setInterval(() => {
            if (direction === 'left') {
                this.scrollContainer.nativeElement.scrollLeft -= speed; // Adjust the scroll distance as needed
            } else {
                this.scrollContainer.nativeElement.scrollLeft += speed; // Adjust the scroll distance as needed
            }

            // Increase speed gradually based on hold duration
            const holdDuration = Date.now() - this.holdStartTime;
            speed = Math.min(speed + 5, 50 + Math.floor(holdDuration / 100)); // Adjust the maximum speed as needed
        }, 50); // Adjust the interval based on the desired scrolling speed
    }

    stopScroll() {
        clearInterval(this.scrollInterval);
    }

    goToManageTask(taskId: number) {
        this.router.navigateByUrl('/communications/manage-task/' + taskId);
    }

    toggleStage(): void {
        this.isClosed = !this.isClosed;
    }

    get isClosedStage(): boolean {
        return this.stageStatus === 'closed';
    }

    get isExpired(): boolean {
        return this.stageStatus === 'deadline';
    }
}
