<div *ngIf="!isLoading" class="flex flex-col justify-between h-full">
    <div class="sticky w-full top-0">
        <div class="flex items-center p-2 text-[#8E91D1]">
            <div *ngIf="showRecent" (click)="toggleDateFilter()" class="flex items-center gap-2">
                <span>{{ 'communications.workspace.show_recent' | translate }}</span>
                <i class="ph-caret-down-bold"></i>
            </div>

            <div *ngIf="!showRecent" (click)="toggleDateFilter()" class="flex items-center gap-2">
                <span>{{ 'communications.workspace.show_first' | translate }}</span>
                <i class="ph-caret-up-bold"></i>
            </div>

            <div class="ml-auto rtl:mr-auto rtl:ml-0 gap-5 flex items-center">
                <i
                    *ngIf="teamId && !isFolderDownloading"
                    class="ph-download-simple text-lg hover:text-[#FF02D0] cursor-pointer"
                    (click)="downloadFolder()"
                ></i>

                <div *ngIf="isFolderDownloading" class="flex justify-center">
                    <mat-spinner [diameter]="18"></mat-spinner>
                </div>

                <div class="flex gap-1">
                    <input
                        *ngIf="showSearch"
                        [@animateOpacity]
                        appAutofocus
                        class="documents-search-input px-2"
                        [(ngModel)]="query"
                    />
                    <i
                        class="ph-magnifying-glass-bold hover:text-[#FF02D0]"
                        style="font-size: 1.4em"
                        (click)="toggleSearch()"
                    ></i>
                </div>
                <div *ngIf="isUpload">
                    <app-un-create-button (click)="openDialog(uploadModal)"></app-un-create-button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isBreadcrumbs" class="px-2">
        <app-breadcrumbs
            [breadcrumbs]="breadcrumbs"
            (breadcrumbClicked)="navigateByBreadcrumbs($event)"
        ></app-breadcrumbs>
    </div>
    <div class="p-2 un-scroll flex flex-col mb-auto gap-y-2 overflow-y-auto">
        <div *ngIf="documents.length > 0 || folders.length > 0; else noDocuments">
            <div class="files-grid">
                <ng-container *ngIf="!selectedFolder && folders.length > 0">
                    <div class="file-block folder cursor-pointer p-1">
                        <div><i class="ph-folder-simple-star text-[#FF02D0]"></i></div>
                        <div>Final version</div>
                        <div class="item-action-overlay" (click)="openFinalVersionFolder(finalVersion)">
                            <div><i class="ph-arrow-fat-line-right"></i></div>
                        </div>
                    </div>
                    <ng-container *ngFor="let folder of folders | search: 'name' : query">
                        <div class="file-block folder cursor-pointer p-1">
                            <div><i class="ph-folder-simple"></i></div>
                            <div>{{ folder.name | truncate: 12 }}</div>
                            <div class="item-action-overlay" (click)="openFolder(folder)">
                                <div><i class="ph-arrow-fat-line-right"></i></div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="selectedFolder && !selectedFolder.final">
                    <div class="file-block folder cursor-pointer p-1">
                        <div><i class="ph-folder-simple"></i></div>
                        <div>...{{ selectedFolder.name | truncate: 12 }}</div>
                        <div class="item-action-overlay" (click)="closeFolder()">
                            <div><i class="ph-arrow-fat-line-left"></i></div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="selectedFolder && selectedFolder.final">
                    <div class="file-block folder cursor-pointer p-1">
                        <div><i class="ph-folder-simple"></i></div>
                        <div>...{{ selectedFolder.name | truncate: 12 }}</div>
                        <div class="item-action-overlay" (click)="closeFinalFolder()">
                            <div><i class="ph-arrow-fat-line-left"></i></div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="selectedFolder && selectedFolder.final && finalVersionFolders.length > 0">
                    <!-- <div class="file-block folder cursor-pointer p-1">
                        <div><i class="ph-folder-simple-star text-[#FF02D0]"></i></div>
                        <div>Final version</div>
                        <div class="item-action-overlay" (click)="openFinalVersionFolder(finalVersion)">
                            <div><i class="ph-arrow-fat-line-right"></i></div>
                        </div>
                    </div> -->
                    <ng-container *ngFor="let folder of finalVersionFolders | search: 'name' : query">
                        <div class="file-block folder cursor-pointer p-1">
                            <div><i class="ph-folder-simple"></i></div>
                            <div>{{ folder.name | truncate: 12 }}</div>
                            <div class="item-action-overlay" (click)="openFolder(folder)">
                                <div><i class="ph-arrow-fat-line-right"></i></div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngFor="let document of documents | search: 'file_name' : query">
                    <div *ngIf="isNonPreviewType(document.doc_type.type)">
                        <div class="file-block cursor-pointer p-1">
                            <div><i [ngClass]="getIconByType(document.doc_type.type)"></i></div>
                            <div [ngClass]="document.doc_type.type">{{ document.file_name | truncate: 12 }}</div>

                            <div class="item-action-overlay">
                                <button mat-button (click)="openDocumentModal(viewDocumentModal, document)">
                                    <i class="ph-magnifying-glass-plus"></i>
                                </button>
                            </div>
                            <app-favorite [document]="document" (clicked)="addToFavorite($event)"></app-favorite>
                        </div>
                    </div>

                    <div *ngIf="isVideoType(document.doc_type.type)" class="file-block cursor-pointer p-1">
                        <div><i [ngClass]="getIconByType(document.doc_type.type)"></i></div>
                        <div [ngClass]="document.doc_type.type">{{ document.file_name | truncate: 12 }}</div>

                        <div class="item-action-overlay">
                            <button mat-button (click)="openVideoModal(viewVideoModal, document)">
                                <i class="ph-magnifying-glass-plus"></i>
                            </button>
                        </div>
                        <app-favorite [document]="document" (clicked)="addToFavorite($event)"></app-favorite>
                    </div>

                    <div *ngIf="isAudioType(document.doc_type.type)" class="file-block cursor-pointer p-1">
                        <div><i [ngClass]="getIconByType(document.doc_type.type)"></i></div>
                        <div [ngClass]="document.doc_type.type">{{ document.file_name | truncate: 12 }}</div>

                        <div class="item-action-overlay">
                            <button mat-button (click)="openAudioModal(viewAudioModal, document)">
                                <i class="ph-magnifying-glass-plus"></i>
                            </button>
                        </div>
                        <app-favorite [document]="document" (clicked)="addToFavorite($event)"></app-favorite>
                    </div>

                    <div *ngIf="isImageType(document.doc_type.type)" class="file-block image cursor-pointer p-1">
                        <img class="mb-1" [src]="document.link" alt="" />
                        <div [ngClass]="document.doc_type.type" style="font-size: 10px">
                            {{ document.file_name | truncate: 12 }}
                        </div>
                        <div class="item-action-overlay">
                            <button mat-button (click)="openImageModal(viewImageModal, document)">
                                <i class="ph-magnifying-glass-plus"></i>
                            </button>
                        </div>
                        <app-favorite [document]="document" (clicked)="addToFavorite($event)"></app-favorite>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading" class="flex justify-center items-center h-[400px]">
    <mat-spinner></mat-spinner>
</div>

<ng-template #noDocuments>
    <span style="font-size: 12px; color: #8e91d1">{{ 'ui.drive.upload_documents_clue' | translate }}</span>
</ng-template>

<ng-template #uploadModal>
    <div class="un-scroll" mat-dialog-content style="min-width: 380px; min-height: 200px">
        <div class="flex mb-2 justify-start items-center">
            <span class="font-weight-bold text-uppercase text-large" style="font-size: 14px">
                {{ 'ui.drive.upload_document' | translate }}
            </span>
            <app-info-icon text="Select file and press upload button"></app-info-icon>
        </div>
        <div *ngIf="!isLoading" class="my-5 items-center justify-center h-full">
            <app-un-file-upload
                [allowedExtensions]="allowedExtensions"
                (fileChanged)="fileChange($event)"
            ></app-un-file-upload>
        </div>

        <div *ngIf="isLoading" class="flex justify-center items-center" style="height: 200px">
            <app-un-game-loader style="zoom: 0.7"></app-un-game-loader>
        </div>
    </div>

    <div *ngIf="!isLoading" mat-dialog-actions class="row justify-between">
        <div class="col-auto ml-auto rtl:ml-0 rtl:mr-auto" style="zoom: 0.7">
            <app-un-save-button
                [text]="'ui.drive.upload_button' | translate"
                (сlicked)="upload()"
                [disabled]="!uploadHelper.valid"
            >
            </app-un-save-button>
        </div>
    </div>
</ng-template>

<ng-template #viewDocumentModal>
    <div class="my-2 flex items-center justify-between gap-x-3 place-items-center">
        <app-un-gradient-text [text]="selectedDocument.file_name" size="small"> </app-un-gradient-text>

        <div class="flex items-center gap-x-2">
            <app-favorite
                [document]="selectedDocument"
                [absolute]="false"
                (clicked)="addToFavorite($event)"
            ></app-favorite>

            <a class="flex items-center text-decoration-none shadow-none outline-none" [href]="selectedDocument.link">
                <i class="ph-download-simple text-lg"></i>
            </a>

            <span
                class="flex items-center text-decoration-none shadow-none outline-none"
                (click)="deleteDocument(selectedDocument)"
            >
                <i class="ph ph-trash text-lg interactive hover:text-white"></i>
            </span>
        </div>
    </div>

    <div class="un-scroll flex justify-center items-center h-48" mat-dialog-content style="min-width: 580px">
        <div><i class="text-5xl" [ngClass]="getIconByType(selectedDocument.doc_type.type)"></i></div>
    </div>
</ng-template>

<ng-template #viewImageModal>
    <div class="my-2 flex items-center justify-between gap-x-3 place-items-center">
        <app-un-gradient-text [text]="selectedImage.file_name" size="small"> </app-un-gradient-text>

        <div class="flex items-center gap-x-2">
            <app-favorite
                [document]="selectedImage"
                [absolute]="false"
                (clicked)="addToFavorite($event)"
            ></app-favorite>

            <a class="flex items-center text-decoration-none shadow-none outline-none" [href]="selectedImage.link">
                <i class="ph-download-simple text-lg"></i>
            </a>

            <span
                class="flex items-center text-decoration-none shadow-none outline-none"
                (click)="deleteDocument(selectedImage)"
            >
                <i class="ph ph-trash text-lg interactive hover:text-white"></i>
            </span>
        </div>
    </div>

    <div class="un-scroll" mat-dialog-content style="min-width: 580px; min-height: 300px">
        <div class="w-full h-full">
            <img [src]="selectedImage.link" style="width: 100%; height: auto" alt="" />
        </div>
    </div>
</ng-template>

<ng-template #viewVideoModal>
    <div class="my-2 flex items-center justify-between place-items-center">
        <app-un-gradient-text [text]="selectedVideo.file_name" size="small"> </app-un-gradient-text>
        <!-- <a class="text-decoration-none shadow-none outline-none" [href]="selectedVideo.link">
            <i class="ph-download-simple text-lg"></i>
        </a> -->
        <div class="flex items-center gap-x-2">
            <app-favorite
                [document]="selectedVideo"
                [absolute]="false"
                (clicked)="addToFavorite($event)"
            ></app-favorite>

            <a class="flex items-center text-decoration-none shadow-none outline-none" [href]="selectedVideo.link">
                <i class="ph-download-simple text-lg"></i>
            </a>

            <span
                class="flex items-center text-decoration-none shadow-none outline-none"
                (click)="deleteDocument(selectedVideo)"
            >
                <i class="ph ph-trash text-lg interactive hover:text-white"></i>
            </span>
        </div>
    </div>

    <div class="un-scroll" mat-dialog-content style="min-width: 580px; min-height: 300px">
        <div class="w-full h-full">
            <video [src]="selectedVideo.link" width="720" autoplay poster="posterimage.jpg">
                Sorry, your browser doesn't support embedded videos, but don't worry, you can
                <a [href]="selectedVideo.link">download it</a>
                and watch it with your favorite video player!
            </video>
        </div>
    </div>
</ng-template>

<ng-template #viewAudioModal>
    <div class="my-2 flex items-center justify-between place-items-center">
        <app-un-gradient-text [text]="selectedAudio.file_name" size="small"> </app-un-gradient-text>
        <div class="flex items-center gap-x-2">
            <app-favorite
                [document]="selectedAudio"
                [absolute]="false"
                (clicked)="addToFavorite($event)"
            ></app-favorite>

            <a class="flex items-center text-decoration-none shadow-none outline-none" [href]="selectedAudio.link">
                <i class="ph-download-simple text-lg"></i>
            </a>

            <span
                class="flex items-center text-decoration-none shadow-none outline-none"
                (click)="deleteDocument(selectedAudio)"
            >
                <i class="ph ph-trash text-lg interactive hover:text-white"></i>
            </span>
        </div>
    </div>

    <div class="un-scroll" mat-dialog-content style="min-width: 580px; min-height: 300px">
        <div class="w-full h-full flex flex-col items-center gap-8 text-cyan-400">
            <i class="ph ph-music-notes text-9xl"></i>
            <audio controls class="w-full">
                <source [src]="selectedAudio.link" />
                Your browser does not support the audio element.
            </audio>
        </div>
    </div>
</ng-template>
