import { settings, environment } from 'src/environments/environment';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SessionService } from 'src/app/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
    isLoading: boolean = false;
    user: any | undefined;
    questionnaireUrl: string = environment.questionnaireUrl;

    constructor(
        private title: Title,
        private sessionService: SessionService,
        private router: Router,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.title.setTitle(settings.title + 'My account');
        this.getUser();
    }

    getUser() {
        this.user = this.sessionService.getCurrentUser();
    }

    goToQuestionary() {
        this.sessionService.setQuestionnaire(false);
        this.router.navigateByUrl(this.questionnaireUrl);
    }
}
