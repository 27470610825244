import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-pre-users-select',
    templateUrl: './pre-users-select.component.html',
    styleUrls: ['./pre-users-select.component.scss'],
})
export class PreUsersSelectComponent {
    @Input() users: any[] = [];
    @Input() loading: boolean = false;

    @Output() usersUpdated: EventEmitter<any> = new EventEmitter();

    storageUrl: string = environment.storageUrl;
    defaultImageUrl: string = '/assets/blank-profile.png';

    selectedAll: boolean = true;

    constructor(private dialog: MatDialog) {}

    get assignedUsers() {
        return this.users.filter((user) => user.checked);
    }

    get assignedUsersPreview() {
        return this.assignedUsers;
    }

    openModal(dialogRef: TemplateRef<any>) {
        this.selectedAll = true;
        this.users = this.users.map((user) => ({
            ...user,
            nickname: user.name,
            checked: true,
            personal_type: { types: user.email },
        }));
        this.dialog.open(dialogRef, { autoFocus: false });
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    selectStudent(user: any) {
        user['checked'] = !user['checked'];
    }

    getSelectedIds() {
        return this.users.filter((user) => user.checked).map((user) => user.id);
    }

    toggleUsers() {
        this.selectedAll = !this.selectedAll;
        this.users.forEach((user) => (user.checked = this.selectedAll));
    }

    submit() {
        if (this.assignedUsers.length === 0) {
            return;
        }
        this.usersUpdated.emit(this.getSelectedIds());
        this.closeDialog();
    }
}
