import {
    Component,
    OnInit,
    Renderer2,
    AfterViewInit,
    ElementRef,
    ViewChild,
    Output,
    Input,
    EventEmitter,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { LoginService } from 'src/app/services/login.service';
import { Title } from '@angular/platform-browser';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { NotificationService } from 'src/app/services/notification.service';

interface MenuItem {
    name: string;
    icon: string;
    link: string;
    active: boolean;
}

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
    @Input() focusHotkey: string = '/';

    @Output() submitted = new EventEmitter<any>();

    search: any;
    min: number = 3;

    showBar: boolean = false;

    @ViewChild('searchbox') searchbox: ElementRef | undefined;

    hotkey: Hotkey = new Hotkey([this.focusHotkey], (event: KeyboardEvent): boolean => {
        this.toggle();
        return false;
    });

    constructor(private notificationService: NotificationService, private hotkeysService: HotkeysService) {}

    ngOnInit() {
        this.hotkeysService.add(this.hotkey);
    }

    ngOnDestroy() {
        this.hotkeysService.remove(this.hotkey);
    }

    submit() {
        if (this.search.length >= this.min) {
            // this.submitted.emit(this.search);
            // this.searchbox.nativeElement.value = '';
        } else {
            this.notificationService.error('Gib mindestens drei Zeichen ein');
        }
    }

    toggle() {
        this.showBar = !this.showBar;
        // setTimeout(() => {
        //   this.searchbox.nativeElement.focus();
        // }, 200);
    }
}
