import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    providers: [],
})
export class ChangePasswordComponent implements OnInit {
    isLoading: boolean = false;
    user: any | undefined;

    changePasswordForm = new FormGroup({
        current: new FormControl('', [Validators.required]),
        new: new FormControl('', [Validators.required, Validators.minLength(8)]),
        confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    });

    constructor(
        private userService: UserService,
        private sessionService: SessionService,
        private notificationService: NotificationService,
        public dialog: MatDialog,
    ) {}

    ngOnInit() {
        this.getUser();
    }

    getUser() {
        this.user = this.sessionService.getCurrentUser();
    }

    get isValid() {
        if (!this.changePasswordForm.get('current')?.value || !this.changePasswordForm.get('new')?.value) {
            return false;
        }
        return this.changePasswordForm.get('confirmPassword')?.value === this.changePasswordForm.get('new')?.value;
    }

    get isConfirmed() {
        return this.changePasswordForm.get('confirmPassword')?.value === this.changePasswordForm.get('new')?.value;
    }

    get newPassword() {
        return this.changePasswordForm.get('new');
    }

    get confirmPassword() {
        return this.changePasswordForm.get('confirmPassword');
    }

    changePassword() {
        if (!this.isValid || !this.isConfirmed) {
            return;
        }

        this.trimFormSpaces();

        this.isLoading = true;
        this.userService
            .changePassword(this.changePasswordForm.get('current')?.value, this.changePasswordForm.get('new')?.value)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success('Changed successfully');
                    this.changePasswordForm.reset();
                },
                error: (err: any) => {
                    this.notificationService.error(err.error.reason, 'Error');
                },
            });
    }

    disableSpaces(event: KeyboardEvent): void {
        if (event.key === ' ') {
            event.preventDefault();
        }
    }

    trimFormSpaces(): void {
        for (const controlName in this.changePasswordForm.controls) {
            const control = this.changePasswordForm.controls[controlName];
            if (control.value && typeof control.value === 'string') {
                control.setValue(control.value.trim());
            }
        }
    }
}
