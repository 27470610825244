import { environment } from 'src/environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocationsService } from 'src/app/services/locations.service';
import { VotesTopicsService } from 'src/app/services/votes-topics.service';
import { style, trigger, state, transition, animate } from '@angular/animations';
import { UploadUsersFromFileForm } from 'src/app/forms/UploadUsersFromFileForm';

const ANIMATION_DURATION = 400;

@Component({
    selector: 'app-questionnaire-theme-selector',
    templateUrl: './questionnaire-theme-selector.component.html',
    styleUrls: ['./questionnaire-theme-selector.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('in', style({ opacity: 1, transform: 'translateY(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateY(-100%)' }),
                animate(ANIMATION_DURATION),
            ]),
            transition('* => void', [
                animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateY(100%)' })),
            ]),
        ]),
        trigger('flyleftIn', [
            state('in', style({ opacity: 1, transform: 'translateX(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateX(-100%)' }),
                animate(ANIMATION_DURATION),
            ]),
            transition('* => void', [
                animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateX(100%)' })),
            ]),
        ]),
        trigger('flyRigthIn', [
            state('in', style({ opacity: 1, transform: 'translateX(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateX(100%)' }),
                animate(ANIMATION_DURATION),
            ]),
            transition('* => void', [
                animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateX(-100%)' })),
            ]),
        ]),
    ],
})
export class QuestionnaireThemeSelectorComponent implements OnInit {
    isLoading: boolean = false;

    storageUrl: string = environment.storageUrl;
    locations: any[] = [];
    themes: any[] = [];

    selectedTheme: any;

    form = new UploadUsersFromFileForm();

    isFormOpen: boolean = false;

    @Input() suggestThemeMode = true;

    @Output() themeSelected: EventEmitter<any> = new EventEmitter<any>();

    constructor(private locationsService: LocationsService, private votesService: VotesTopicsService) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.isLoading = true;
        this.locationsService.getAll().subscribe((response: any) => {
            this.locations = response.data || [];
        });
        this.votesService.getStudentsTopics().subscribe((response: any) => {
            this.themes = response.data || [];
            this.isLoading = false;
        });
    }

    selectTheme(theme: any) {
        this.selectedTheme = theme;
    }

    closeThemeView() {
        this.selectedTheme = undefined;
    }

    chooseTheme(theme: any) {
        this.themeSelected.emit(theme);
    }

    upload(data: any) {
        const formData = new FormData();
    }
}
