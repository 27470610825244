<div *ngIf="team" id="custom-scroll" class="project-side">
    <div class="flex justify-end">
        <i class="fas fa-times fa-lg cursor-pointer project-side-close" (click)="close()"></i>
    </div>

    <div class="w-full flex" style="gap: 12px">
        <h3>{{ 'communications.section.team_profile' | translate }}</h3>
        <span><i class="fas fa-share-square" style="margin-top: 10px"></i></span>
    </div>

    <div class="st-title">
        <div class="flex justify-between">
            <h5 class="mb-2">{{ team.name }}</h5>
        </div>
        <span class="mb-2">{{ team.desc }}</span>
    </div>

    <div class="team-profile-users-row my-2">
        <i class="fas fa-user-friends"></i>
        <app-un-users-group-view [users]="users"></app-un-users-group-view>
        <button matRipple class="bg-transparent small-control">
            <i class="fas fa-plus fa-lg"></i>
        </button>
    </div>

    <hr />

    <div class="mb-2">
        <h5>{{ 'communications.section.participant' | translate }}</h5>
    </div>

    <div class="mb-2">
        <table style="font-size: 0.75em">
            <tr>
                <td class="text-center"><i class="fas fa-university mr-1"></i></td>
                <td class="text-nowrap">
                    <span class="large">{{ 'communications.section.institution' | translate }}</span>
                </td>
                <td class="w-full text-right">
                    <span class="large">{{ teamInfo.participant.institution }}</span>
                </td>
            </tr>
            <tr>
                <td class="text-center"><i class="fas fa-briefcase mr-1"></i></td>
                <td class="text-nowrap">
                    <span class="large">{{ 'communications.section.teachers' | translate }}</span>
                </td>
                <td class="w-full text-right">
                    <span class="large">{{ teamInfo.participant.teachers }}</span>
                </td>
            </tr>
            <tr>
                <td class="text-center"><i class="fas fa-wrench mr-1"></i></td>
                <td class="text-nowrap">
                    <span class="large">{{ 'communications.section.mentors' | translate }}</span>
                </td>
                <td class="w-full text-right">
                    <span class="large">{{ teamInfo.participant.mentors }}</span>
                </td>
            </tr>
            <tr>
                <td class="text-center"><i class="fas fa-user-graduate mr-1"></i></td>
                <td class="text-nowrap">
                    <span class="large">{{ 'communications.section.students' | translate }}</span>
                </td>
                <td class="w-full text-right">
                    <span class="large">{{ teamInfo.participant.students }}</span>
                </td>
            </tr>
            <tr>
                <td class="text-center"><i class="far fa-clock mr-1"></i></td>
                <td class="text-nowrap">
                    <span class="large">{{ 'communications.section.start_date' | translate }}</span>
                </td>
                <td class="w-full text-right">
                    <span class="large">{{ teamInfo.participant.start_date }}</span>
                </td>
            </tr>
            <tr>
                <td class="text-center"><i class="fas fa-male mr-1"></i></td>
                <td class="text-nowrap">
                    <span class="large">{{ 'communications.section.mentoring' | translate }}</span>
                </td>
                <td class="w-full text-right">
                    <span class="large">{{ teamInfo.participant.mentoring }}</span>
                </td>
            </tr>
            <tr>
                <td class="text-center"><i class="far fa-file mr-1"></i></td>
                <td class="text-nowrap">
                    <span class="large">{{ 'communications.section.project' | translate }}</span>
                </td>
                <td class="w-full text-right">
                    <span class="large">{{ teamInfo.participant.project }}</span>
                </td>
            </tr>
            <tr>
                <td class="text-center"><i class="fas fa-map-marker-alt mr-1"></i></td>
                <td class="text-nowrap">
                    <span class="large">{{ 'communications.section.final_presentation' | translate }}</span>
                </td>
                <td class="w-full text-right">
                    <span class="large">{{ teamInfo.participant.final_presentation }}</span>
                </td>
            </tr>
            <tr>
                <td class="text-center"><i class="fas fa-briefcase mr-1"></i></td>
                <td class="text-nowrap">
                    <span class="large">{{ 'communications.section.projects_year' | translate }}</span>
                </td>
                <td class="w-full text-right">
                    <span class="large">{{ teamInfo.additionally.projects_in_year }}</span>
                </td>
            </tr>
            <tr>
                <td class="text-center"><i class="fas fa-wrench mr-1"></i></td>
                <td class="text-nowrap">
                    <span class="large">{{ 'communications.section.hours_year' | translate }}</span>
                </td>
                <td class="w-full text-right">
                    <span class="large">{{ teamInfo.additionally.hours_in_year }}</span>
                </td>
            </tr>
        </table>
    </div>

    <hr />

    <div>
        <div class="mb-2">
            <h5>{{ 'communications.section.additionally' | translate }}</h5>
        </div>

        <div class="mb-2">
            <div class="flex"></div>
            <div style="font-size: 0.75em">
                <div class="flex items-center" style="gap: 10px">
                    <div class="text-center"><i class="fas fa-user-friends mr-1"></i></div>
                    <div class="text-nowrap">
                        <div class="team-profile-users-row ml-2">
                            <app-un-users-group-view [users]="users"></app-un-users-group-view>
                            <button matRipple class="bg-transparent small-control">
                                <i class="fas fa-plus fa-lg"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="flex w-full">
                    <div class="mt-5 w-full flex justify-center">
                        <img src="/assets/team-add-diagram.png" alt="" style="height: 120px" />
                    </div>
                </div>
                <div class="my-3 flex justify-center" style="gap: 24px">
                    <div class="flex items-center" style="gap: 5px">
                        <span
                            ><div style="border-radius: 50%; background-color: #b0b8ba; width: 10px; height: 10px"></div
                        ></span>
                        <span>{{ 'communications.section.art' | translate }}</span>
                    </div>

                    <div class="flex items-center" style="gap: 5px">
                        <span
                            ><div style="border-radius: 50%; background-color: #83bf4f; width: 10px; height: 10px"></div
                        ></span>
                        <span>{{ 'communications.section.design' | translate }}</span>
                    </div>

                    <div class="flex items-center" style="gap: 5px">
                        <span
                            ><div style="border-radius: 50%; background-color: #75d6ff; width: 10px; height: 10px"></div
                        ></span>
                        <span>{{ 'communications.section.programming' | translate }}</span>
                    </div>

                    <div class="flex items-center" style="gap: 5px">
                        <span
                            ><div style="border-radius: 50%; background-color: #3c63ae; width: 10px; height: 10px"></div
                        ></span>
                        <span>{{ 'communications.section.biology' | translate }}</span>
                    </div>

                    <div class="flex items-center" style="gap: 5px">
                        <span
                            ><div style="border-radius: 50%; background-color: #7b61ff; width: 10px; height: 10px"></div
                        ></span>
                        <span>{{ 'communications.section.math' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
