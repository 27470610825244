import { settings, environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';


@Component({
  selector: 'app-com-personal-info',
  templateUrl: './com-personal-info.component.html',
  styleUrls: ['./com-personal-info.component.scss'],
  providers: [],
})
export class ComPersonalInfoComponent implements OnInit {

  isLoading: boolean = false;

  cards: any[] = [
    {
      title: 'Project Statistics',
      index: 75,
      count: 657,
      info: 'Сommon Today'
    },
    {
      title: 'Personal Tasks',
      index: 18,
      count: 15,
      info: 'Сommon Today'
    },
    {
      title: 'Event',
      index: 65,
      count: 5,
      info: 'Сommon Today'
    },
    {
      title: 'Team Tasks',
      index: 25,
      count: 48,
      info: 'Сommon Today'
    }
  ];

  teams: any[] = [
    {
      id: 1,
      name: 'Team QWERTY',
    },
    {
      id: 2,
      name: 'ABC-team',
    },
    {
      id: 3,
      name: 'Dream team',
    }
  ];

  teamUsers: any[] = [
    {
      id: 1,
      name: 'Jean-Claude Van Damme',
      color: '#fff',
      position: 'programmer'
    },
    {
      id: 2,
      name: 'Quentin Tarantino',
      color: '#84D2FF',
      position: 'scenarist'
    },
    {
      id: 3,
      name: 'Karl Marx',
      color: '#B6A8FF',
      position: 'designer'
    },
    {
      id: 4,
      name: 'Quentin Tarantino',
      color: '#73FF92',
      position: 'programmer'
    }
  ];

  statistics: any[] = [
    {
      id: 1,
      name: 'Project Name',
    },
    {
      id: 2,
      name: 'Project Aim',
    },
    {
      id: 3,
      name: 'Project Theme',
    },
    {
      id: 4,
      name: 'Activity Game',
    }
  ];

  tasks: any[] = [
    {
      id: 1,
      name: 'Update Game',
    },
    {
      id: 2,
      name: '  2. Create character ',
    }
  ];

  card: any = {
    name: 'Creation',
    progress: 91,
    modified_at: '1 days ago'
  };


  constructor(private title: Title) { }


  ngOnInit() {
    this.title.setTitle(settings.title + 'Communications');
  }



}
