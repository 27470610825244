<div class="flex h-full">
    <div class="h-full w-full">
        <app-un-tabs [tabs]="tabs" [selectedTabIndex]="selectedTabIndex" (tabChanged)="setTab($event)">
            <div header class="flex flex-1 flex-col items-start gap-x-12 gap-y-3 xl:flex-row">
                <div *ngIf="activeTab.selector !== 'information'" class="mb-4 flex flex-wrap gap-4 xl:mb-0 xl:gap-16">
                    <!-- <app-un-gradient-text
                        *ngIf="!isUserAbleChangeTeam()"
                        [text]="currentUserProjectName"
                        size="big"
                    ></app-un-gradient-text> -->

                    <app-select
                        class="w-[240px]"
                        [label]="'communications.section.project' | translate"
                        [placeholder]="'communications.section.project' | translate"
                        [options]="schoolProjects"
                        [optionValueSelector]="'id'"
                        [optionTextSelector]="'title'"
                        [(ngModel)]="selectedProjectId"
                        (ngModelChange)="getTeamsByProjectId()"
                    >
                    </app-select>

                    <div class="flex gap-4 xl:gap-16">
                        <div *ngIf="activeTab.selector === 'tasks' && teams?.length > 0" class="flex gap-10">
                            <app-select
                                class="w-[240px]"
                                [label]="'communications.component.teams' | translate"
                                [placeholder]="'communications.component.teams' | translate"
                                [options]="teams"
                                [optionValueSelector]="'id'"
                                [optionTextSelector]="'name'"
                                [(ngModel)]="selectedTeam"
                                (ngModelChange)="teamChanged()"
                            >
                            </app-select>
                        </div>

                        <div *ngIf="activeTab.selector === 'tasks'" class="flex gap-10">
                            <div class="project-filter-name flex flex-col justify-center">
                                <span class="tab-filter-label">{{ 'communications.component.view' | translate }}</span>
                                <div>
                                    <label class="switch" style="margin: 0">
                                        <input type="checkbox" [(ngModel)]="tasksView" (ngModelChange)="changeView()" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="activeTab.selector === 'tasks' && tutorialSource" class="flex gap-10">
                            <div class="flex flex-col justify-center">
                                <span class="tab-filter-label capitalize">
                                    {{ 'communications.component.tutorial' | translate }}:
                                </span>
                                <app-tutorial [tutorialSource]="tutorialSource"></app-tutorial>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="activeTab.selector === 'information'" class="flex w-full justify-between">
                    <div class="flex flex-col gap-2">
                        <app-un-gradient-text [text]="schoolName" size="big"></app-un-gradient-text>
                        <div class="flex items-center gap-3 py-2">
                            <app-select
                                class="w-[240px]"
                                [placeholder]="'communications.section.project' | translate"
                                [options]="schoolProjects"
                                [optionValueSelector]="'id'"
                                [optionTextSelector]="'title'"
                                [(ngModel)]="selectedProjectId"
                                (ngModelChange)="getTeamsByProjectId()"
                            >
                            </app-select>

                            <app-team-generator
                                *ngIf="isSchoolAdmin()"
                                class="me-16 self-center"
                                [isButton]="true"
                            ></app-team-generator>

                            <div class="flex flex-col justify-center">
                                <span class="tab-filter-label">
                                    {{ 'communications.component.administrator' | translate }}
                                </span>
                                <div>
                                    <app-un-users-group-view
                                        class="px-2"
                                        title="administrators"
                                        [users]="administrators"
                                        [limit]="1"
                                        style="display: flex"
                                    ></app-un-users-group-view>
                                </div>
                            </div>
                            <div class="flex flex-col justify-center">
                                <span class="tab-filter-label">
                                    {{ 'communications.component.all_students' | translate }}
                                </span>
                                <div>
                                    <app-un-users-group-view
                                        class="px-2"
                                        [users]="students"
                                        title="students"
                                        style="display: flex"
                                    >
                                    </app-un-users-group-view>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container body>
                <app-un-tab *ngIf="activeTab.selector === 'workplace'">
                    <app-workspace></app-workspace>
                </app-un-tab>

                <app-un-tab *ngIf="activeTab.selector === 'tasks' && selectedTeam">
                    <app-workplace-tasks [teamId]="selectedTeam" [viewTask]="tasksView"></app-workplace-tasks>
                </app-un-tab>

                <app-un-tab *ngIf="activeTab.selector === 'information' && selectedProjectId">
                    <app-commmunication-info-ver-2
                        [selectedProjectId]="selectedProjectId"
                    ></app-commmunication-info-ver-2>
                </app-un-tab>
                <app-un-tab *ngIf="activeTab.selector === 'statistic' && selectedProjectId">
                    <app-project-statistic [projectId]="selectedProjectId"></app-project-statistic>
                </app-un-tab>
            </ng-container>
        </app-un-tabs>
    </div>
</div>

<app-un-gpt-chat-button></app-un-gpt-chat-button>

<!-- <app-tabs>
    <app-tab title="Tasks">
        <app-workplace-tasks [teamId]="selectedTeam" [viewTask]="tasksView"></app-workplace-tasks>
    </app-tab>
    <app-tab title="Information">
        <app-commmunication-info-ver-2></app-commmunication-info-ver-2>
    </app-tab>
    <app-tab title="Statistic"> </app-tab>
</app-tabs> -->
