<div class="party position-relative">
    <button matRipple class="nav-btn">
        <div *ngIf="!barOpened" (click)="showBar()">
            <i class="fas fa-user-friends mx-2 circle-icon"></i>
            <span>{{ 'navbar.create_party' | translate }}</span>
        </div>

        <div *ngIf="barOpened" [@enterAnimation] class="position-relative">
            <div class="flex justify-between items-center">
                <ng-container *ngFor="let friend of filteredFriends; let i = index">
                    <ng-container *ngIf="i < 4">
                        <ng-template *ngTemplateOutlet="templateFriend; context: { $implicit: friend }"></ng-template>
                    </ng-container>
                </ng-container>

                <div class="mx-1"><i class="fas fa-plus-circle controls"></i></div>

                <div class="mx-1 divider"></div>

                <div class="mx-1" (click)="close()"><i class="far fa-times-circle controls"></i></div>
            </div>
        </div>
    </button>
</div>

<ng-template #templateFriend let-friend>
    <div class="position-relative mx-1" style="width: 22px; height: 22px">
        <img class="friend-img" [src]="friend?.image_path" />
    </div>
</ng-template>
