import { Component, OnInit } from '@angular/core';
import { SurveysService } from '../../../../services/surveys.service';
import { NotificationService } from '../../../../services/notification.service';
import { SurveysAnswersTable } from '../../../../tables/SurveysAnswersTable';
import { ExportService } from '../../../../services/export.service';

@Component({
  selector: 'app-manage-surveys-answers',
  templateUrl: './manage-surveys-answers.component.html'
})
export class ManageSurveysAnswersComponent implements OnInit {
  surveysAnswers: any[] = [];
  isLoading: boolean = true;
  table = new SurveysAnswersTable();
  ENTRY_NAME: string = 'Surveys Answers';

  constructor(
    private surveysService: SurveysService,
    private notificationService: NotificationService,
    private exportService: ExportService
  ) {}

  ngOnInit() {
    this.fetch();
  }

  fetch() {
    this.isLoading = true;
    this.surveysService.getAllSurveysAnswers().subscribe({
      next: (response: any) => {
        this.surveysAnswers = response.data || [];
        this.isLoading = false;
      },
      error: (error) => {
        this.notificationService.error('Failed to fetch surveys answers', 'Error');
        this.isLoading = false;
      }
    });
  }

  downloadAnswersCSV() {
    this.exportService.exportAsCSVFile(this.surveysAnswers, 'surveys_answers');
  }

  downloadAnswersXLSX() {
    this.exportService.exportAsExcelFile(this.surveysAnswers, 'surveys_answers');
  }
}