export const items = [
    {
        name: 'Junior',
        recommended: false,
        difficulty: 2,
        icon: '/assets/project/levels/earth.png',
        width: '70',
        features: [
            'School Disciplines',
            'Painting',
            'Information Technology'
        ],
        game: 'warrior',
        description: {
            slides: [
                {
                    type: 'text',
                    content: ` - Presents a 3D environment with presets for various types of games: general puzzles, simple programming puzzles, explorations games, etc. Player character behavior and possible interactions is fully defined by the preset;

                            - Presets are 2D scenes in a 3D environment: the camera is fixed with a side view of the scene, and objects can be placed either in the background or the foreground. This makes this editor easy for the younger kids to understand and use;

                            - Objects are pre-baked with all the necessary interactions and physics behaviors. Project Leader can create new objects using their advanced workshop and make them usable for the users at the Beginner level;

                            - Requires no scripting or deeper knowledge: this level of the studio is more of a game in itself.`,
                },
                {
                    type: 'video',
                    content: '/assets/videos/levels/junior/begin.mp4'
                },
                {
                    type: 'video',
                    content: '/assets/videos/levels/junior/puzzle.mp4'
                },
                {
                    type: 'video',
                    content: '/assets/videos/levels/junior/advanced.mp4'
                }
            ]
        }
    },
    {
        name: 'Middle',
        recommended: true,
        difficulty: 3,
        icon: '/assets/project/levels/cloud.png',
        width: '80',
        features: [
            'Design',
            '3D Modeling',
            'School Disciplines'
        ],
        game: 'space-game',
        description: {
            slides: [
                {
                    type: 'text',
                    content: ` - Presents a 3D environment with presets for various types of games: general puzzles, simple programming puzzles, explorations games, etc. Player character behavior and possible interactions is fully defined by the preset;

                            - Presets are 2D scenes in a 3D environment: the camera is fixed with a side view of the scene, and objects can be placed either in the background or the foreground. This makes this editor easy for the younger kids to understand and use;

                            - Objects are pre-baked with all the necessary interactions and physics behaviors. Project Leader can create new objects using their advanced workshop and make them usable for the users at the Beginner level;

                            - Requires no scripting or deeper knowledge: this level of the studio is more of a game in itself.`,
                }
            ]
        }
    },
    {
        name: 'Senior',
        recommended: false,
        difficulty: 4,
        width: '60',
        icon: '/assets/project/levels/water.png',
        features: [
            '4D Animation',
            '3D Modeling',
            'Unity Programming'
        ],
        game: '',
        description: {
            slides: [
                {
                    type: 'text',
                    content: ` - Presents a 3D environment with presets for various types of games: general puzzles, simple programming puzzles, explorations games, etc. Player character behavior and possible interactions is fully defined by the preset;

                            - Presets are 2D scenes in a 3D environment: the camera is fixed with a side view of the scene, and objects can be placed either in the background or the foreground. This makes this editor easy for the younger kids to understand and use;

                            - Objects are pre-baked with all the necessary interactions and physics behaviors. Project Leader can create new objects using their advanced workshop and make them usable for the users at the Beginner level;

                            - Requires no scripting or deeper knowledge: this level of the studio is more of a game in itself.`,
                }
            ]
        }
    },
    {
        name: 'Professor',
        recommended: false,
        difficulty: 5,
        icon: '/assets/project/levels/fire.png',
        width: '55',
        features: [
            'Personal Project',
            'Adaptability',
            'Unity Programming'
        ],
        game: '',
        description: {
            slides: [
                {
                    type: 'text',
                    content: ` - Presents a 3D environment with presets for various types of games: general puzzles, simple programming puzzles, explorations games, etc. Player character behavior and possible interactions is fully defined by the preset;

                            - Presets are 2D scenes in a 3D environment: the camera is fixed with a side view of the scene, and objects can be placed either in the background or the foreground. This makes this editor easy for the younger kids to understand and use;

                            - Objects are pre-baked with all the necessary interactions and physics behaviors. Project Leader can create new objects using their advanced workshop and make them usable for the users at the Beginner level;

                            - Requires no scripting or deeper knowledge: this level of the studio is more of a game in itself.`,
                }
            ]
        }
    }
];