import { Component, EventEmitter, Input, IterableDiffers, Output } from '@angular/core';

@Component({
    selector: 'app-breadcrumbs',
    template: `
        <nav class="flex" aria-label="Breadcrumb">
            <ol class="inline-flex items-center mb-0">
                <ng-container *ngFor="let breadcrumb of breadcrumbs; index as i; first as isFirst">
                    <li class="inline-flex items-center" (click)="clickBreadCrumb(i)">
                        <a
                            class="inline-flex items-center text-sm font-medium select-none cursor-pointer text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white capitalize no-underline"
                        >
                            <svg
                                *ngIf="isFirst"
                                aria-hidden="true"
                                class="w-4 h-4 mr-2"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                                ></path>
                            </svg>
                            <svg
                                *ngIf="!isFirst"
                                aria-hidden="true"
                                aria-hidden="true"
                                class="w-6 h-6 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                            {{ breadcrumb | truncate : 14 }}
                        </a>
                    </li></ng-container
                >
            </ol>
        </nav>
    `,
    styles: [':host { display: block; }'],
})
export class BreadcrumbsComponent {
    @Input() breadcrumbs: string[] = [];
    @Output() breadcrumbClicked: EventEmitter<any> = new EventEmitter();

    iterableDiffer;

    constructor(private iterableDiffers: IterableDiffers) {
        this.iterableDiffer = iterableDiffers.find([]).create(undefined);
    }

    ngDoCheck() {
        let changes = this.iterableDiffer.diff(this.breadcrumbs);
    }

    clickBreadCrumb(index: number) {
        this.breadcrumbClicked.emit(index);
    }
}
