import { Component, Input, OnChanges, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CreateInfoBlockForm } from 'src/app/forms/CreateInfoBlockForm';
import { MatDialog } from '@angular/material/dialog';
import { TeamsService } from 'src/app/services/team.service';
import { SetTeacherMentorForm } from 'src/app/forms/SetTeacherMentorForm';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MentorsService } from 'src/app/services/mentors.service';

@Component({
    selector: 'app-commmunication-info-ver-2',
    templateUrl: './commmunication-info-ver-2.component.html',
    styleUrls: ['./commmunication-info-ver-2.component.scss'],
    providers: [],
})
export class CommmunicationInfoVer2Component implements OnInit, OnChanges {
    @Input() selectedProjectId: number = 0;

    isLoading: boolean = true;
    isChartLoading: boolean = false;

    teams: any[] = [];

    users: any = [];
    teacherMentors: any[] = [];
    studentMentors: any[] = [];
    students: any[] = [];

    changeModalName: string = '';
    mentors: any[] = [];

    form = new CreateInfoBlockForm();
    teacherMentorForm = new SetTeacherMentorForm();
    teamChartData: any;

    selectedTeamChart: any = undefined;

    changeMentor: any;
    selectedTeamId: number = 0;

    storageUrl: string = environment.storageUrl;
    defaultImageUrl: string = '/assets/blank-profile.png';

    @ViewChild('changeMentorTemplate') changeMentorTemplate: TemplateRef<any>;

    constructor(
        private dialog: MatDialog,
        private teamsService: TeamsService,
        private notificationService: NotificationService,
        private mentorsService: MentorsService,
        public translate: TranslateService,
    ) {}

    ngOnInit() {
        this.getMentors();
    }

    ngOnChanges() {
        this.fetch();
    }

    fetch() {
        this.teams = [];
        this.teamChartData = undefined;
        this.isLoading = !this.isChartLoading;

        this.teamsService.getTeamsByProjectId(this.selectedProjectId).subscribe(({ data }: any) => {
            this.teams = data.teams || [];
            this.teamChartData = data.chart;

            this.isLoading = false;
            this.isChartLoading = false;
        });
    }

    getMentors() {
        this.mentorsService.getAll(this.selectedProjectId).subscribe(({ data }: any) => {
            this.teacherMentors = data.teachers.map((item: any) => item.user);
            this.studentMentors = data.students.map((item: any) => item.user);
        });
    }

    getChartData(team: any) {
        this.selectedTeamChart = team;
        this.teamChartData = undefined;
        this.teamsService.getTeamChart(team.id).subscribe(({ data }: any) => {
            this.teamChartData = data;
        });
    }

    getAllChartsData() {
        this.isChartLoading = true;
        this.selectedTeamChart = undefined;
        this.fetch();
    }

    submit(data: any) {
        this.closeDialog();
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    openChangeMentorModal(event: { type: string; teamId: number }): void {
        this.selectedTeamId = event.teamId;

        const team = this.teams.find((team) => team.id === event.teamId);

        const selectetMentors = event.type === 'teacher' ? team.members.teacher_mentors : team.members.student_mentors;
        const selectetMentorsIds = selectetMentors.map((mentor: any) => mentor.id);

        const mentors = event.type === 'teacher' ? this.teacherMentors : this.studentMentors;

        this.mentors = mentors.map((mentor) => ({ ...mentor, checked: selectetMentorsIds.includes(mentor.id) }));

        this.dialog.open(this.changeMentorTemplate);
    }

    updateTeamMentors(mentors: number[]) {
        this.teamsService.update(this.selectedTeamId, { mentors: mentors }).subscribe({
            next: () => {
                this.notificationService.success(this.TEACHER_MENTOR_UPDATED_TOASTR);
                this.fetch();
                this.closeDialog();
            },
            error: () => this.notificationService.error(this.SOMETHING_WRONG_TOASTR),
        });
    }

    get TEACHER_MENTOR_UPDATED_TOASTR() {
        return this.translate.instant('communications.info.teacher_mentor_updated_toastr');
    }

    get STUDENT_MENTOR_UPDATED_TOASTR() {
        return this.translate.instant('communications.info.student_mentor_updated_toastr');
    }

    get SOMETHING_WRONG_TOASTR() {
        return this.translate.instant('communications.info.something_wrong_toastr');
    }
}
