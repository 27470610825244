import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding } from '@angular/core';
import { MenuItemComponent } from '../menu-item/menu-item.component';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    animations: [
        trigger('dropDownMenu', [
            transition(':enter', [
                style({ height: 0, overflow: 'hidden' }),
                query('li', [style({ opacity: 0, transform: 'translateY(-50px)' })]),
                sequence([
                    animate('100ms', style({ height: '*' })),
                    query('li', [stagger(-50, [animate('100ms ease', style({ opacity: 1, transform: 'none' }))])]),
                ]),
            ]),

            transition(':leave', [
                style({ height: '*', overflow: 'hidden' }),
                query('li', [style({ opacity: 1, transform: 'none' })]),
                sequence([
                    query('li', [
                        stagger(50, [animate('100ms ease', style({ opacity: 0, transform: 'translateY(-50px)' }))]),
                    ]),
                    animate('100ms', style({ height: 0 })),
                ]),
            ]),
        ]),
    ],
})
export class MenuComponent {
    @HostBinding('style.display') public display = 'inline-block';
    @HostBinding('style.position') public position = 'absolute';
    @HostBinding('style.inset-inline-end') public inset = '12px';

    private activeMenuItem: MenuItemComponent;

    positionY: string = '0';

    constructor() {}

    ngOnInit() {}

    public registerOpenedMenu(menuItem: MenuItemComponent, positionY: number): void {
        this.positionY = -positionY + 'px';
        this.activeMenuItem = menuItem;
    }

    public closeOpenedMenuIfExists(): void {
        if (this.activeMenuItem) {
            this.activeMenuItem.clearContainer();
        }
    }
}
