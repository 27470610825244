import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-tag',
    template: `
        <span
            class="bg-transparent px-2 py-1 capitalize rounded-md cursor-pointer border-t border-b border-r border-l transition-all"
            [ngStyle]="{ 'border-color': tag.border, color: tag.color }"
            (click)="click()"
        >
            <div class="flex items-center gap-x-1">
                <i class="ph text-base" [ngClass]="'ph-' + tag.icon"></i>
                <span>
                    {{ tag.label }}
                </span>
            </div>
        </span>
    `,
    styles: [':host { display: inline-flex; }'],
})
export class TagComponent {
    @Input() tag: any;

    @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

    click() {
        this.clicked.emit(this.tag);
    }
}
