<iframe
    class="d-none"
    [class.d-block]="loggedIn"
    #iframe
    id="iframe"
    height="100%"
    width="100%"
    [src]="CHAT_URL | safe: 'resourceUrl'"
    style="border: none"
></iframe>

<div *ngIf="!loggedIn" class="flex w-full h-full justify-center items-center" style="background: #020d16">
    <app-un-game-loader></app-un-game-loader>
</div>
