<div>
    <div class="flex flex-col">
        <h6 class="my-3">{{ 'friend_bar.online' | translate }} – {{ onlineCount }}</h6>

        <div class="friends-zone un-scroll">
            <ng-container *ngFor="let friend of filteredFriends">
                <ng-container *ngIf="friend.online">
                    <ng-template *ngTemplateOutlet="templateFriend; context: { $implicit: friend }"></ng-template>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<div>
    <div class="flex flex-col">
        <h6 class="my-3">{{ 'friend_bar.offline' | translate }} – {{ offlineCount }}</h6>

        <div class="friends-zone un-scroll">
            <ng-container *ngFor="let friend of filteredFriends">
                <ng-container *ngIf="!friend.online">
                    <ng-template *ngTemplateOutlet="templateFriend; context: { $implicit: friend }"></ng-template>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <!-- <hr class="m-0"> -->
</div>

<ng-template #templateFriend let-friend>
    <div class="grid-container items-center">
        <div class="grid-item position-relative">
            <div class="position-relative" style="width: 35px; height: 35px">
                <img class="friend-img" [src]="friend?.image_path" />
                <div class="status" [ngClass]="{ offline: !friend.online, online: friend.online }"></div>
            </div>
        </div>

        <div class="grid-item font-weight-400 ml-2" style="font-size: 0.85em">
            <div class="cursor-pointer" (click)="goToChat(friend.nickname)">{{ friend.nickname }}</div>
        </div>

        <div *ngIf="friend.messages" class="grid-item msg font-weight-400 cursor-pointer">
            <span>{{ friend.messages > 99 ? 99 : friend.messages }}</span>
        </div>
    </div>
</ng-template>
