import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class ProjectsService {
    public API: string = '/projects';

    constructor(private http: HttpClient) {}

    getAll(search?: string) {
        const url = new Url(this.API);

        // url.addQueryParameter('page', page);
        // url.addQueryParameter('limit', limit);
        url.addQueryParameter('search', search);

        return this.http.get(url.path);
        // return this.http.get(new Url(this.API).path);
    }

    getById(id: number) {
        return this.http.get(new Url(this.API + '/' + id).path);
    }

    getByTeam(teamId: number) {
        return this.http.get(new Url(this.API + '/team/' + teamId).path);
    }

    create(data: any) {
        return this.http.post(environment.apiUrl + this.API, data);
    }

    update(id: number, data: any) {
        return this.http.put(environment.apiUrl + this.API + '/' + id, data);
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + this.API + '/' + id);
    }

    getProjectStats(id: number) {
        return this.http.get(new Url(this.API + '/' + id + '/stats').path);
    }

    getProjectTeams(id: number) {
        return this.http.get(new Url(this.API + '/' + id + '/teams').path);
    }

    getSchoolProjects() {
        return this.http.get(new Url(this.API + '/active').path);
    }

    getAssignedUsers(projectId: number) {
        return this.http.get(new Url(this.API + '/' + projectId + '/users/no-team').path);
    }
}
