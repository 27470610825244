import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs/operators';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { SurveysService } from '../../services/surveys.service';

export interface User {
    birthday: string;
    email: string;
    id: number;
    is_admin: boolean;
    main_image: string;
    nickname: string;
    questionnaire: boolean;
    state: string;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    isLoading: boolean = false;

    hasError: boolean = false;
    htmlTag: any;
    version = '';
    returnUrl: string = '';

    questionnaireUrl: string = environment.questionnaireUrl;
    surveysUrl: string = environment.surveysUrl;

    showForgotForm: boolean = false;

    errors: any[] = ['Invalid email or password', 'The email address was not found'];

    isSent: boolean = false;

    formGroup = this.fb.group({
        email: [null, Validators.required],
        password: [null, Validators.required],
    });

    forgotFormGroup = this.fb.group({
        email: [null, Validators.required],
    });

    constructor(
        private notificationService: NotificationService,
        private loginService: LoginService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private sessionService: SessionService,
        private surveysService: SurveysService,
    ) {}

    ngOnInit() {
        if (this.loginService.isLoggedIn()) {
            this.router.navigateByUrl('/home');
        } else {
            this.htmlTag = document.getElementsByTagName('html')[0];
            this.htmlTag.classList.add('login');
            this.version = environment.version;
            this.returnUrl = this.route.snapshot.queryParams['redirectUrl'] || '/';
        }
    }

    onSubmit() {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;

        this.login();
    }

    toggleForgotPassword() {
        this.hasError = false;
        this.showForgotForm = !this.showForgotForm;
    }

    login() {
        const email = this.formGroup.get('email')?.value;
        const password = this.formGroup.get('password')?.value;

        const data = new FormData();
        data.append('email', email);
        data.append('password', password);

        this.loginService
            .logIn(data)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    // this.sessionService.setCurrentUser({ ...response.data.user, state: 'start_platform' });
                    this.sessionService.setCurrentUser(response.data.user);
                    this.sessionService.setToken(response.data.auth_token);
                    this.sessionService.setQuestionnaire(this.isQuestionnaireDone(response.data.user));
                    this.sessionService.setEligibleSurveys(this.hasEligibleSurveys(response.data.user));
                    this.sessionService.setCurrentUserSchool(response.data.school);
                    this.sessionService.setCurrentUserProject(response.data.project);

                    this.returnUrl = this.getReturnUrlByUser(response.data.user);

                    const state = this.sessionService.getCurrentUser().state;

                    setTimeout(() => {
                        this.router.navigateByUrl(this.sessionService.getRouteByState(state));
                    }, 200);

                    this.htmlTag.classList.remove('login');
                    sessionStorage.removeItem('redirected');
                    localStorage.setItem('lang', response.data.user?.language || 'en');
                    localStorage.setItem('language', response.data.user?.language || 'en');
                    this.sessionService.changeLanguage();
                },
                (err) => {
                    this.notificationService.error(err.error.reason, 'Error');
                    this.isLoading = false;
                    this.hasError = true;
                },
            );
    }

    getReturnUrlByUser(user: User): string {
        if (this.isQuestionnaireDone(user)) {
            // If the questionnaire is done and the user has eligible surveys, go to surveys
            if (this.hasEligibleSurveys(user)) {
                return this.surveysUrl;
            }
            // Otherwise, return the default return URL
            return this.returnUrl;
        }

        // If the questionnaire is not done, redirect to the questionnaire page
        return this.questionnaireUrl;
    }

    isQuestionnaireDone(user: User): boolean {
        return !user.questionnaire;
    }

    hasEligibleSurveys(user: User): boolean {
        return user.state === 'school_survey';
    }
}
