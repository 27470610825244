import { trigger, style, animate, transition } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FriendsService } from 'src/app/services/friends.service';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';

type Friend = {
  name: string;
  image_path: string;
  messages: number | null,
  online: boolean;
}

@Component({
  selector: 'app-un-create-party',
  templateUrl: './un-create-party.component.html',
  styleUrls: ['./un-create-party.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('280ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('280ms', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ]
    )
  ],
})
export class UnCreatePartyComponent {

  @Input() card: any;
  @Output() clicked: EventEmitter<any> = new EventEmitter();


  barOpened: boolean = false;
  storageUrl: string = environment.storageUrl;

  friends: Friend[] = [];
  filteredFriends: any[] = [];



  constructor(
    public session: SessionService,
    private friendsService: FriendsService
    ) { }


  ngOnInit() {
    this.filteredFriends = this.friends;
    this.fetch();
  }

  fetch() {
    this.friendsService.getAll().subscribe((response: any) => {
    });
  }

  get onlineCount(): number {
    return this.filteredFriends.filter((friend: Friend) => friend.online).length;
  }

  get offlineCount(): number {
    return this.filteredFriends.filter((friend: Friend) => !friend.online).length;
  }

  showBar() {
    this.filteredFriends = this.friends;
    this.barOpened = !this.barOpened;
    this.clicked.emit();
  }

  close() {
    this.barOpened = false;
  }

  onKey(event: any) {
    this.filteredFriends = this._filterUsers(event.target.value).filter(n => n);
  }

  private _filterUsers(value: string): any[] {
    const list: any[] = [];
    const input = value ? value.toLowerCase() : '';
    this.friends.forEach((element, key) => {
      if (!input || (input && this.friends[key].name.toLowerCase().indexOf(input) > -1)) {
        list[key] = this.friends[key];
      }
    });
    return list;
  }



}
