import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { finalize } from 'rxjs/operators';
import { Tools } from 'src/app/tools';
import { VotesTopicsService } from 'src/app/services/votes-topics.service';
import { VotesTopicsTable } from 'src/app/tables/VotesTopicsTable';
import * as moment from 'moment';

@Component({
    selector: 'app-manage-votes',
    templateUrl: './manage-votes.component.html',
    styleUrls: ['./manage-votes.component.css'],
})
export class ManageVotesComponent implements OnInit {
    votesTopics: any[] = [];
    isLoading: boolean = true;
    storageUrl: string = environment.storageUrl;
    ENTRY_NAME: string = 'Vote topic';

    begin: any = moment();
    end: any = moment().add(7, 'days');

    table = new VotesTopicsTable();

    constructor(
        private votesService: VotesTopicsService,
        private notificationService: NotificationService,
        public sessionService: SessionService
    ) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.isLoading = true;
        this.votesService.getAll().subscribe((response: any) => {
            this.votesTopics = response.data || [];
            this.table.buildForm();
            this.isLoading = false;
        });
    }

    create(data: any) {
        this.votesService
            .create(Tools.getFormData(data))
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' created successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    update(data: any) {
        this.votesService
            .update(data.id, Tools.getFormData(data))
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' updated successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    delete(data: any) {
        this.votesService
            .delete(data.id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' deleted successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    startVote() {
        this.votesService.startVotes({ start_date: this.begin, end_date: this.end }).subscribe(
            (response: any) => {
                this.notificationService.success('Vote started');
            },
            (err) => {
                this.notificationService.error(err.error.message, 'Error');
                this.isLoading = false;
            }
        );
    }

    get isVoteAvailable() {
        return this.votesTopics.filter((topic: any) => topic.active).length > 1;
    }
}
