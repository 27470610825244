import { environment } from 'src/environments/environment';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { UsersService } from 'src/app/services/users.service';
import { ManageSchoolStudentsTable } from 'src/app/tables/ManageSchoolStudentsTable';

@Component({
    selector: 'app-manage-student-mentors',
    templateUrl: './manage-student-mentors.component.html',
    styleUrls: ['./manage-student-mentors.component.css'],
})
export class ManageStudentMentorsComponent implements OnInit {
    studentsMentors: any[] = [];
    isLoading: boolean = false;
    ENTRY_NAME: string = 'Student mentors';
    storageUrl: string = environment.storageUrl;

    usersPerPage: number = this.usersService.getUsersPerPage();

    table = new ManageSchoolStudentsTable();

    @ViewChild('invitationTemplate') invitationTemplate: TemplateRef<any>;

    constructor(
        private usersService: UsersService,
        private notificationService: NotificationService,
        public sessionService: SessionService
    ) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.usersService.getAll().subscribe((response: any) => {
            this.studentsMentors = response.data || [];
            this.table.invitationTemplate = this.invitationTemplate;
            this.table.buildColumns();
            this.table.buildForm();
        });
    }

    // create(data: any) {
    //   this.locationsService.create(Tools.getFormData(data)).pipe(finalize(() => this.isLoading = false)).subscribe((response: any) => {
    //     this.notificationService.success(this.ENTRY_NAME + ' created successfully');
    //     this.fetch();
    //   }, (err) => {
    //     this.notificationService.error(err.error.message, 'Error');
    //     this.isLoading = false;
    //   });
    // }

    // update(data: any) {
    //   this.locationsService.update(data.id, Tools.getFormData(data)).pipe(finalize(() => this.isLoading = false)).subscribe((response: any) => {
    //     this.notificationService.success(this.ENTRY_NAME + ' updated successfully');
    //     this.fetch();
    //   }, (err) => {
    //     this.notificationService.error(err.error.message, 'Error');
    //     this.isLoading = false;
    //   });

    // }

    // delete(data: any) {
    //   this.locationsService.delete(data.id).pipe(finalize(() => this.isLoading = false)).subscribe((response: any) => {
    //     this.notificationService.success(this.ENTRY_NAME + ' deleted successfully');
    //     this.fetch();
    //   }, (err) => {
    //     this.notificationService.error(err.error.message, 'Error');
    //     this.isLoading = false;
    //   });
    // }
}
