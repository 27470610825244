import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { flyRigthIn } from 'src/app/animations/fly-animations';
import { settings } from 'src/environments/environment';

@Component({
    selector: 'app-notificator',
    template: `
        <ng-template #notificator>
            <div>
                <app-un-gradient-text class="mb-3" text="Notification" size="big"></app-un-gradient-text>

                <mat-dialog-content class="mat-typography text-lg text-[#9D97C3] uppercase">
                    <p>
                        The service for generating images may not be available at the moment, since that section is at
                        the testing stage.
                    </p>
                    <p>If you need to run it, please contact us by these emails "{{ contactEmail }}"</p>
                </mat-dialog-content>
                <mat-dialog-actions align="end">
                    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>dismiss</button>
                </mat-dialog-actions>
            </div>
        </ng-template>
        <div
            *ngIf="modalClosed"
            [@flyRigthIn]
            class="flex items-center"
            matTooltip="The service for generating images may not be available at the moment, since that section is at the testing stage. If you need to run it, please contact us by these emails {{
                contactEmail
            }}"
        >
            <i class="ph ph-warning text-3xl text-[#FF02D0]"></i>
        </div>
    `,
    styles: [':host { display: block; }'],
    animations: [flyRigthIn],
})
export class NotificatorComponent {
    @Input() text?: string;

    contactEmail: string = 'support@unispher.com, david@unispher.com';

    modalClosed: boolean = false;

    @ViewChild('notificator') dialogTemplate: TemplateRef<any>;

    tooltipOptions = settings.tooltipOptions;

    constructor(public dialog: MatDialog) {}

    ngOnInit() {
        this.modalClosed = this.getNotificatorFromStorage() ? true : false;
    }

    ngAfterViewInit() {
        this.closeDialog();

        if (!this.modalClosed) {
            this.openDialog();
        }
    }

    openDialog() {
        this.dialog
            .open(this.dialogTemplate)
            .afterClosed()
            .subscribe(() => {
                this.modalClosed = true;
                this.setNotificatorToStorage();
            });
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    getNotificatorFromStorage() {
        return localStorage.getItem('studioNotificator');
    }

    setNotificatorToStorage() {
        localStorage.setItem('studioNotificator', JSON.stringify(true));
    }
}
