<div
    *ngIf="card"
    class="com-info-card cursor-pointer game-card p-3"
    (click)="openDialog($event, confirmModal, card.name)"
>
    <div class="mb-3">
        <img class="game-image" [src]="storageUrl + card.image_path" alt="" />
        <div style="position: absolute; right: 30px; top: 30px" (click)="like()">
            <i *ngIf="!card.like" class="far fa-heart fa-lg" [class.like]="card.animation"></i>
            <i *ngIf="card.like" class="fas fa-heart fa-lg" [class.like]="card.animation"></i>
        </div>
    </div>

    <div class="footer">
        <div class="name mt-2">
            <h6>{{ card.name }}</h6>
        </div>

        <div class="text-center my-1" style="font-size: 0.7em; opacity: 0.8">
            <div class="mb-1">
                <span class="mx-auto">{{ 'ui.game_card.creator' | translate }}</span>
            </div>

            <div class="flex justify-center">
                <div style="transform: translateX(4px)">
                    <ng-container *ngFor="let author of card.authors">
                        <img class="author" [src]="storageUrl + author.image_path" alt="" />
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="flex justify-between" style="font-size: 0.7em; opacity: 0.8">
            <span>{{ 'ui.game_card.progress' | translate }}</span>
            <span>79%</span>
        </div>

        <div class="mb-2">
            <div class="progress">
                <span class="progress-bar" style="width: 75%"></span>
            </div>
        </div>

        <div class="flex justify-between" style="font-size: 0.7em; opacity: 0.8">
            <span style="opacity: 0.35"><i class="fas fa-users mr-2"></i>826</span>
        </div>
    </div>
</div>

<ng-template #confirmModal>
    <div mat-dialog-content style="width: 30vw; border-radius: 12px; padding: 30px">
        <div class="flex">
            <div>
                <h3 class="mb-3">{{ selectedEpisode }}</h3>
                <h5>{{ 'ui.game_card.want_start_episode' | translate }}</h5>
            </div>
            <!-- <div>
                <img class="game-image" [src]="storageUrl +  card.image_path" alt="">
            </div> -->
        </div>
    </div>

    <div mat-dialog-actions class="row justify-between">
        <div class="col-auto"></div>
        <div class="col-auto">
            <button class="btn btn-primary mr-2" (click)="no()">{{ 'ui.game_card.cancel' | translate }}</button>
            <button class="btn btn-success" (click)="yes()">{{ 'ui.game_card.start' | translate }}</button>
        </div>
    </div>
</ng-template>
