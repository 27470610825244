<div class="project-item p-4 flex" [class.recommended]="level.recommended">
    <div [ngStyle]="{ width: selectedLevel ? '20%' : '100%' }">
        <div *ngIf="level.recommended" class="recommended-title">
            <span class="roboto fw-700" style="font-size: 0.85em">{{ 'ui.dashboard.recommended' | translate }}</span>
        </div>

        <div class="text-center mt-2 mb-2">
            <span class="roboto fw-700"
                ><b>{{ level.name }}</b></span
            >
        </div>

        <div class="text-center mb-2">
            <div class="flex justify-center items-center" style="height: 85px">
                <div class="dot" [style.width.px]="level.width">
                    <img [src]="level.icon" style="width: 100%; height: auto" />
                </div>
            </div>
        </div>

        <div class="text-center mb-2">
            <span class="roboto fw-700" style="font-size: 0.75em"
                ><b>{{ 'ui.dashboard.difficulty' | translate }}</b></span
            >
        </div>

        <div class="text-center mb-2">
            <ng-container *ngFor="let star of stars; let i = index">
                <ng-container *ngIf="level.difficulty >= i + 1">
                    <i class="fas fa-star mr-1"></i>
                </ng-container>

                <ng-container *ngIf="level.difficulty < i + 1">
                    <i class="far fa-star mr-1"></i>
                </ng-container>
            </ng-container>
        </div>

        <div class="text-center mb-2">
            <span class="roboto fw-700" style="font-size: 0.75em"
                ><b>{{ 'ui.dashboard.features' | translate }}</b></span
            >
        </div>

        <div class="mx-auto text-nowrap mb-3">
            <ng-container *ngFor="let feature of level.features">
                <span class="roboto" style="font-size: 0.75em">✓ {{ feature }}</span
                ><br />
            </ng-container>
        </div>

        <div class="row">
            <div class="col-6">
                <button matRipple (click)="openDescription(level)">{{ displayButtonText }}</button>
            </div>
            <div class="col-6">
                <button matRipple>{{ 'ui.dashboard.buy' | translate }}</button>
            </div>
        </div>
    </div>

    <div
        *ngIf="selectedLevel"
        class="flex justify-center items-center p-4 level-description"
        style="border-left: 1px solid; opacity: 0.7; position: relative; width: 80%"
    >
        <ng-container>
            <ng-container [ngSwitch]="level.description.slides[selectedSlide].type">
                <div *ngIf="!slideReady" class="position-absolute">
                    <mat-spinner></mat-spinner>
                </div>

                <div class="flex justify-center" [style.visibility]="slideReady ? 'visible' : 'hidden'">
                    <!-- Image -->
                    <ng-container *ngSwitchCase="'text'">
                        <div style="font-size: 0.85em" [innerHTML]="content | nl2br | safe : 'html'"></div>
                    </ng-container>

                    <!-- Video -->
                    <ng-container *ngIf="content">
                        <video
                            *ngSwitchCase="'video'"
                            #video
                            id="video"
                            width="100%"
                            height="100%"
                            muted
                            autoplay
                            loop
                            style="height: auto; width: 75%"
                        >
                            <source [src]="content | safe : 'resourceUrl'" type="video/mp4" />
                            {{ 'ui.dashboard.not_support_video' | translate }}
                        </video>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>

        <div *ngIf="level.description.slides.length > 1" class="level-description-controls">
            <ng-container *ngFor="let control of level.description.slides; let i = index">
                <div
                    class="control-circle mr-1"
                    [ngClass]="{ active: i === selectedSlide }"
                    (click)="setSlide(i)"
                ></div>
            </ng-container>
        </div>
    </div>
</div>
