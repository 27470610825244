<div *ngIf="user" id="custom-scroll" class="project-side">
    <div class="flex justify-end">
        <i class="fas fa-times fa-lg cursor-pointer project-side-close" (click)="close()"></i>
    </div>

    <div class="w-full flex" style="gap: 12px">
        <h3>{{ 'communications.section.user_profile' | translate }}</h3>
        <span><i class="fas fa-share-square" style="margin-top: 10px"></i></span>
    </div>

    <div class="st-title">
        <div class="flex justify-between">
            <h5 class="mb-2">{{ user.name }}</h5>
            <div>
                <span>{{ 'communications.section.programmer' | translate }}</span>
            </div>
        </div>
        <span class="mb-2"
            >{{ 'communications.section.mentor_teacher' | translate
            }}<i class="fas fa-crown" style="font-size: 0.8em"></i
        ></span>
    </div>

    <hr />

    <div>
        <div class="st-title mb-3">
            <h5 class="mb-2">{{ 'communications.section.create_new_task' | translate }}</h5>
        </div>

        <div class="mb-2">
            <span>{{ 'communications.section.title' | translate }}</span>
        </div>

        <div class="mb-3">
            <span>
                <div class="task-input-wrap">
                    <input
                        class="task-title-input"
                        type="text"
                        placeholder="Task title"
                        autocomplete="off"
                        [(ngModel)]="taskName"
                    />
                    <button
                        mat-button
                        *ngIf="taskName"
                        matSuffix
                        mat-icon-button
                        aria-label="Clear"
                        (click)="taskName = ''"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </span>
        </div>

        <div class="mb-3">
            <span>{{ 'communications.section.task_type' | translate }}</span>
        </div>

        <div class="mb-3 flex" style="flex-wrap: wrap; gap: 10px">
            <ng-container *ngFor="let type of taskTypes">
                <span class="text-capitalize text-center tag px-3 mr-1 {{ type.color }} type-tag">{{ type.name }}</span>
            </ng-container>
        </div>

        <div class="mb-2">
            <span>{{ 'communications.section.task_detail' | translate }}</span>
        </div>

        <div class="mb-2">
            <table style="font-size: 0.75em">
                <tr>
                    <td class="text-center"><i class="far fa-clock mr-1"></i></td>
                    <td>
                        <span class="large">Sunday, Jan 12 (10:00 AM – 12:00 AM)</span>
                    </td>
                </tr>
                <tr>
                    <td class="text-center"><i class="fas fa-user-friends mr-1"></i></td>
                    <td>
                        <span class="large">Josh, Kevin</span>
                    </td>
                </tr>
                <tr>
                    <td class="text-center"><i class="fas fa-map-marker-alt mr-1"></i></td>
                    <td>
                        <span class="large">{{ 'communications.section.add_location' | translate }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="text-center"><i class="far fa-file mr-1"></i></td>
                    <td>
                        <span class="large">{{ 'communications.section.add_description' | translate }}</span>
                    </td>
                </tr>
            </table>
        </div>

        <!-- <div class="mb-2" style="font-size: 0.75em;">
      <span><i class="far fa-clock mr-1"></i>&nbsp; Sunday, Jan 12 (10:00 AM – 12:00 AM)</span>
    </div>

    <div class="mb-2" style="font-size: 0.75em;">
      <span><i class="fas fa-user-friends mr-1"></i>&nbsp; Josh, Kevin</span>
    </div>

    <div class="mb-2" style="font-size: 0.75em;">
      <span><i class="fas fa-map-marker-alt mr-1"></i>&nbsp; Add location</span>
    </div>

    <div class="mb-3" style="font-size: 0.75em;">
      <span><i class="far fa-file mr-1"></i>&nbsp; Add description</span>
    </div> -->

        <div class="w-full">
            <app-un-magic-button [text]="'Create Task'" [fullWidth]="true" [bordered]="true"></app-un-magic-button>
        </div>
    </div>

    <hr />

    <div class="st-title">
        <h5 class="mb-2">{{ 'communications.section.completed' | translate }}</h5>

        <div class="flex justify-between mb-2">
            <span>Project name: </span>
            <span>HULA</span>
        </div>

        <div class="flex justify-between mb-2">
            <span>Level: </span>
            <span>Junior project</span>
        </div>

        <div class="flex justify-between mb-2">
            <span>Personal Tasks: </span>
            <span>12/20</span>
        </div>

        <div class="flex justify-between mb-2">
            <span>Challenges: </span>
            <span>13/20</span>
        </div>

        <div class="flex justify-between mb-2">
            <span>Attending Events: </span>
            <span>3/8</span>
        </div>

        <div class="flex justify-between mb-2">
            <span>Editing Games: </span>
            <span>1/3</span>
        </div>

        <div class="flex justify-between mb-2">
            <span>Size: </span>
            <span>28 MB</span>
        </div>
    </div>
</div>
