import { Table } from './Table';

export class GroupViewTable extends Table {
    constructor() {
        super();
        this.buildColumns();
    }

    buildColumns() {
        this.columns = [
            {
                name: 'Name',
                selector: 'name',
            },
            {
                name: 'Biology',
                selector: 'biology',
                headerClass: 'text-center',
                class: () => 'text-center',
            },
            {
                name: 'Chemistry',
                selector: 'chemistry',
                headerClass: 'text-center',
                class: () => 'text-center',
            },
            {
                name: 'Physics',
                selector: 'physics',
                headerClass: 'text-center',
                class: () => 'text-center',
            },
            {
                name: 'History',
                selector: 'history',
                headerClass: 'text-center',
                class: () => 'text-center',
            },
            {
                name: 'Art',
                selector: 'art',
                headerClass: 'text-center',
                class: () => 'text-center',
            },
            {
                name: 'Literature',
                selector: 'literature',
                headerClass: 'text-center',
                class: () => 'text-center',
            },
            {
                name: 'Tanah',
                selector: 'tanah',
                headerClass: 'text-center',
                class: () => 'text-center',
            },
            {
                name: 'Israel culture',
                selector: 'culture',
                headerClass: 'text-center',
                class: () => 'text-center',
            },
        ];
    }
}
