import { Component, OnInit, Input, ChangeDetectorRef, OnChanges } from '@angular/core';
import { colors } from './index-data';
import { Tools } from 'src/app/tools';


@Component({
  selector: 'app-index-radial',
  templateUrl: './index-radial.component.html',
  styleUrls: ['./index-radial.component.scss'],
})
export class IndexRadialComponent implements OnInit, OnChanges {

  @Input() inputIndex: any;
  @Input() indexType: any;
  @Input() disabled: boolean = false;

  @Input() type: string = '';


  index: number = 0;
  selectedGradient: any = colors[2];
  colors: any[] = colors;
  dasharray: any[] = [0, 100];


  constructor(private changeDetector: ChangeDetectorRef) { }


  ngOnInit() {
    this.buildIndex();
  }

  ngOnChanges() {
    this.buildIndex();
  }

  buildIndex() {
    if (this.indexType === 'team') {
      this.index = this.inputIndex !== null ? Tools.round(this.inputIndex, 1) : 0;
      this.dasharray[0] = this.index * 10;
      this.getTeamStyle(this.dasharray[0]);
      return;
    }
    if (this.indexType !== 'rating') {
      this.index = this.inputIndex !== null ? Tools.round(this.inputIndex, 0) : 0;
      this.dasharray[0] = this.index >= 98 && this.index < 100 ? 97 : this.index;
    } else {
      this.index = this.inputIndex !== null ? Tools.round(this.inputIndex, 1) : 0;
      this.dasharray[0] = this.index * 10;
    }


    this.getStyleByValue(this.dasharray[0]);
  }

  getStyleByValue(value: number): any {

    if (this.type) {
      this.index = this.inputIndex !== null ? Tools.round(this.inputIndex, 0) : 0;
      this.selectedGradient = this.colors.find((color: any) => color.name === this.type);
      return;
    }

    if (this.type === 'overal') {
      // return {
      //   start: '#00A3FF',
      //   end: '#7B61FF'
      // };
    }

    if (this.disabled || !this.type) {
      return 'gray';
    }

    // if (value < 25) {
    //   this.selectedGradient = this.colors[0];
    // }
    // if (value >= 25 && value < 50) {
    //   this.selectedGradient = this.colors[1];
    // }
    // if (value >= 50 && value < 75) {
    //   this.selectedGradient = this.colors[2];
    // }
    // if (value >= 75 && value <= 100) {
    //   this.selectedGradient = this.colors[3];
    // }

    this.changeDetector.detectChanges();

  }

  getTeamStyle(value: number): any {
    if (this.disabled) {
      return 'gray';
    }

    if (value < 33.3) {
      this.selectedGradient = this.colors[0];
    }
    if (value >= 33.3 && value < 66.6) {
      this.selectedGradient = this.colors[2];
    }
    if (value >= 66.6) {
      this.selectedGradient = this.colors[3];
    }

  }


}
