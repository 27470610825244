import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { Notification, NotificationType } from 'src/app/services/notification.service';
import { Subscription } from 'rxjs';
import { Tools } from 'src/app/tools';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
    notifications: Notification[] = [];
    subscriptions: Subscription[] = [];

    constructor(private notificationService: NotificationService) {
        this.subscriptions.push(
            this.notificationService.triggerToast$.subscribe((notification) => this._addNotification(notification)),
            this.notificationService.notificationClear$.subscribe(() => this.clear())
        );
    }

    ngOnInit() {
        this.getClassName();
    }

    ngOnDestroy(): void {
        Tools.unsubscribeAll(this.subscriptions);
    }

    close(notification: Notification) {
        notification['lastBreath'] = true;
        setTimeout(() => {
            this.notifications = this.notifications.filter((notif) => notif.id !== notification.id);
        }, 500);
    }

    getClassName(type?: string): string {
        return type ? this.getTypesObject()[type] : NotificationType.info;
    }

    getTypesObject() {
        const typesObject: any = {};

        for (const type in NotificationType) {
            typesObject[type] = type;
        }

        return typesObject;
    }

    private _addNotification(notification: Notification) {
        this.notifications.push(notification);

        if (notification.timeout !== 0) {
            setTimeout(() => this.close(notification), notification.timeout);
        }
    }

    private clear(): void {
        this.notifications = [];
    }
}
