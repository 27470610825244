<div
    class="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16"
>
    <div class="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
        <div class="relative flex flex-col gap-6">
            <div class="">
                <div>
                    <div class="my-2 text-[#FF02D0] font-bold text-2xl">
                        {{ 'forbidden.title' | translate }}
                    </div>

                    <p class="my-2 text-gray-200">
                        {{ 'forbidden.description' | translate }}
                    </p>
                </div>
            </div>
            <div>
                <img src="https://i.ibb.co/G9DC8S0/404-2.png" />
            </div>
            <div>
                <button
                    (click)="backToHome()"
                    style="border-color: #ff02d0"
                    class="sm:w-full lg:w-auto my-2 border rounded-lg md py-2 px-8 text-center border-[#FF02D0] bg-transparent text-[FF02D0] hover:text-white hover:bg-[#FF02D0] focus:outline-none focus:ring-opacity-50"
                >
                    {{ 'forbidden.back_button' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
