<ng-container *ngIf="category">
    <div #banner class="py-2 mb-5">
        <div class="banner mt-5" [ngStyle]="{ 'background-image': 'url(' + (storageUrl + category.image_path) + ')' }">
            <h2>{{ category.name }}</h2>
        </div>
    </div>

    <div class="">
        <div class="col-12">
            <h2>{{ 'games.best_game' | translate }}</h2>
        </div>
    </div>

    <div class="cards-container mt-3 mb-5" style="min-height: 85vh">
        <div class="flex">
            <ng-container *ngFor="let game of games">
                <app-un-game-card [card]="game"></app-un-game-card>
            </ng-container>
        </div>
    </div>
</ng-container>
