import { settings } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SessionService } from 'src/app/services/session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Location } from '@angular/common';
import { TeamsService } from 'src/app/services/team.service';
import { SchoolsService } from 'src/app/services/schools.service';
import { TranslateService } from '@ngx-translate/core';
import { TutorialsService } from 'src/app/services/tutorials.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { WorkplaceTasksComponent } from './workspace/tasks/workplace-tasks.component';

@Component({
    selector: 'app-communications',
    templateUrl: './communications.component.html',
    styleUrls: ['./communications.component.scss'],
    providers: [],
})
export class CommunicationsComponent implements OnInit {
    isLoading: boolean = true;

    user = this.session.getCurrentUser();

    //todo: translate
    DEFAULT_SCHOOL_NAME: string = 'School';

    tabs: any = [
        {
            name: this.TASKS_HEADER,
            selector: 'tasks',
            active: false,
            visible: true,
        },
        {
            name: this.INFORMATION_HEADER,
            active: false,
            selector: 'information',
            visible: true,
        },
        {
            name: this.STATISTIC_HEADER,
            active: false,
            selector: 'statistic',
            visible: this.session.isAdmin() || this.session.isTeacher(),
        },
    ];

    teams: any = [];

    users: any = [
        { name: 'All', value: 'all' },
        { name: 'User 1', value: '1' },
        { name: 'User 2', value: '2' },
    ];

    activeTab: any;

    selectedTabIndex = 0;
    selectedProjectId = 0;

    userTeamId = 0;
    adminTeamId = 0;

    students: any[] = [];
    administrators: any[] = [];
    schoolProjects: any[] = [];

    tasksView: boolean = false;

    tutorialSource: string = '';
    DEFAULT_PROJECT_NAME: 'Project name';

    selectedTeam: number = 0;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private session: SessionService,
        private userService: UserService,
        private location: Location,
        private teamsService: TeamsService,
        private schoolsService: SchoolsService,
        private title: Title,
        private tutorialsService: TutorialsService,
        private projectsService: ProjectsService,
        public translate: TranslateService,
    ) {}

    ngOnInit() {
        this.title.setTitle(settings.title + 'Communications');
        this.fetch();
        this.users = this.userService.getFakeUsers().concat(this.userService.getFakeUsers());

        const urlTab = this.route.snapshot.queryParamMap.get('tab');

        const searchTabIndex = this.tabs.findIndex((tab: any) => tab.selector.toLowerCase() === urlTab);
        this.selectedTabIndex = searchTabIndex !== -1 ? searchTabIndex : 0;

        this.userTeamId = this.session.getCurrentUser().team_id;

        this.tasksView = this.session.getStudentTasksViewMode() ? true : false;
        // this.selectedTeam = this.userTeamId;

        this.getTutorial();
    }

    fetch() {
        this.getSchoolProjects();
        this.getSchoolStudents();
        this.getSchoolManagers();
    }

    get isAdmin() {
        return this.session.getCurrentUser().is_admin;
    }

    getTeamId() {
        if (!this.session.getCurrentUserSchool().is_admin) {
            return this.session.getCurrentUserSchool().team_id;
        }
    }

    getSchoolStudents() {
        this.schoolsService.getStudents().subscribe((response: any) => {
            this.students = response.data;
        });
    }

    getSchoolProjects() {
        this.projectsService.getSchoolProjects().subscribe(({ data }: any) => {
            this.schoolProjects = data;
            this.selectedProjectId = data[0]?.id;
            this.getTeamsByProjectId();
        });
    }

    getTeamsByProjectId() {
        this.projectsService.getProjectTeams(this.selectedProjectId).subscribe(({ data }: any) => {
            this.teams = data.teams;
            this.selectedTeam = this.teams[0]?.id;
            this.session.changeProject(this.selectedProjectId);
        });
    }

    getTutorial() {
        this.tutorialsService.getByPage('tasks').subscribe((response: any) => {
            this.tutorialSource = response.data.path;
        });
    }

    getSchoolManagers() {
        this.schoolsService.getManagers().subscribe((response: any) => {
            this.administrators = response.data;
        });
    }

    setTab(tab: any) {
        this.activeTab = tab;
        this.pushTabToUrl(tab);
    }

    get schoolName(): string {
        const school = this.session.getCurrentUserSchool();
        return school ? school.name : this.DEFAULT_SCHOOL_NAME;
    }

    get currentUserProjectName() {
        const project = this.session.getCurrentUserProject();
        return project ? project.title : this.DEFAULT_PROJECT_NAME;
    }

    isUserAbleChangeTeam(): boolean {
        return this.user.is_admin || this.user.role === 'Teacher';
    }

    isSchoolAdmin(): boolean {
        return this.user.is_admin || this.user.role === 'School Admin';
    }

    pushTabToUrl(tab: any) {
        const url = this.router
            .createUrlTree(['/communications'], {
                relativeTo: this.route,
                queryParams: { tab: tab.selector.toLowerCase() },
            })
            .toString();
        this.location.go(url);
    }

    changeView() {
        this.session.setStudentTasksViewMode(this.tasksView);
    }

    teamChanged() {
        this.session.changeTeam(this.selectedTeam);
    }

    get TASKS_HEADER() {
        return this.translate.instant('communications.component.tasks');
    }

    get INFORMATION_HEADER() {
        return this.translate.instant('communications.component.information');
    }

    get STATISTIC_HEADER() {
        return this.translate.instant('communications.component.statistic');
    }
}
