<div class="mb-3 flex items-center gap-2" (keydown)="keyDownFunction($event)">
    <app-un-create-button (click)="pushItem()"></app-un-create-button>

    <app-text-input class="w-full" [placeholder]="placeHolder" [formControl]="newItemName" [maxLength]="inputLimit">
    </app-text-input>
</div>

<div class="flex flex-col flex-wrap gap-3">
    <table>
        <ng-container *ngFor="let item of subtasks">
            <tr
                class="control-list-item relative mb-3 flex items-center"
                (mouseenter)="item.hover = true"
                (mouseleave)="item.hover = false"
            >
                <td class="flex self-center p-0 align-middle">
                    <ng-container *ngIf="item.loading">
                        <i class="fa fa-spinner fa-spin control-list-item-square"></i>
                    </ng-container>
                    <ng-container *ngIf="!item.loading">
                        <i
                            *ngIf="item.status === 'to_do'"
                            class="ph-square control-list-item-square"
                            (click)="complete(item)"
                        ></i>
                        <i
                            *ngIf="item.status === 'completed'"
                            class="ph-check-square control-list-item-square control-list-item-complete"
                            (click)="uncomplete(item)"
                        ></i>
                    </ng-container>
                </td>
                <td class="word-break break-words p-0 align-middle">
                    <span>{{ item.title }}</span>
                </td>
                <td class="flex self-center align-middle">
                    <i class="ph-trash control-list-item-delete" (click)="delete(item.id)"></i>
                </td>
            </tr>
        </ng-container>
    </table>
</div>
