import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-un-tab',
  templateUrl: './un-tab.component.html',
  styleUrls: ['./un-tab.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out', style({ height: 300, opacity: 1 }))
          ]
        )
      ]
    )
  ]

})
export class UnTabComponent {

  // @Input('tabTitle') title: string;
  // @Input() active = false;

  constructor() { }


}
