<div class="sub-panel grid grid-cols-12 gap-3 py-3">
    <div class="col-span-12 lg:col-span-1" (click)="backToPage()">
        <app-un-back-button></app-un-back-button>
    </div>

    <div class="col-span-12 lg:col-span-5 flex items-center flex-fill gap-x-3">
        <ng-container *ngIf="enableAvatarByName">
            <app-un-avatar
                [name]="title"
                [small]="false"
                [icon]="avatarIcon"
                [color]="avatarColor"
                [image]="image"
                (click)="clickAvatar()"
            ></app-un-avatar>
        </ng-container>

        <ng-container *ngIf="allowRename">
            <button (click)="rename()">
                <i class="ph-pencil-simple text-3xl"></i>
            </button>
        </ng-container>

        <app-un-gradient-text [text]="title" [decoration]="textDecoration" size="big"></app-un-gradient-text>
    </div>

    <div class="col-span-12" [ngClass]="!onlyBack ? 'lg:col-span-4' : 'lg:col-span-6'">
        <ng-content></ng-content>
    </div>

    <div *ngIf="!onlyBack" class="col-span-12 md:col-span-2 flex justify-end">
        <app-un-save-button [text]="saveText" (click)="submit()"></app-un-save-button>
    </div>
</div>
