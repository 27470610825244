import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-workspace',
    templateUrl: './workspace.component.html',
    styleUrls: ['./workspace.component.scss'],
    providers: [],
})
export class WorkspaceComponent implements OnInit {
    date: string = moment().format('DD MMM YYYY');

    isLoading: boolean = true;

    teamUsers: any[] = [
        {
            id: 1,
            name: 'Jean-Claude Van Damme',
            color: '#fff',
            position: 'programmer',
        },
        {
            id: 2,
            name: 'Quentin Tarantino',
            color: '#84D2FF',
            position: 'scenarist',
        },
        {
            id: 3,
            name: 'Karl Marx',
            color: '#B6A8FF',
            position: 'designer',
        },
        {
            id: 4,
            name: 'Quentin Tarantino',
            color: '#73FF92',
            position: 'programmer',
        },
    ];

    cards: any[] = [
        {
            name: 'Complete Games Scenario',
            desc: `See what learning & gaming events are...`,
            count: 0,
            info: 'Сommon Today',
            progress: 12,
            time_left: '1 day left',
            type: 'personal',
            users: this.teamUsers,
            // image: 'https://images.unsplash.com/photo-1533090161767-e6ffed986c88?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80'
        },

        {
            name: 'Add new team',
            desc: 'See what learning & gaming events are...',
            count: 0,
            info: 'Сommon Today',
            progress: 80,
            time_left: '2 days left',
            type: 'team',
            users: this.teamUsers,
        },

        {
            name: 'Create Moodboard',
            desc: 'See what learning & gaming events are...',
            count: 0,
            info: 'Сommon Today',
            progress: 45,
            time_left: '1 day left',
            type: 'school',
            users: this.teamUsers,
        },

        {
            name: 'Add new teacher',
            desc: 'See what learning & gaming events are...',
            count: 48,
            info: 'Сommon Today',
            progress: 10,
            time_left: '3 days left',
            type: 'network',
            users: this.teamUsers,
        },

        {
            name: 'Add new team',
            desc: 'See what learning & gaming events are...',
            count: 0,
            info: 'Сommon Today',
            progress: 85,
            time_left: '4 days left',
            type: 'team',
            users: this.teamUsers,
        },

        {
            name: 'Create Moodboard',
            desc: 'See what learning & gaming events are...',
            count: 0,
            info: 'Сommon Today',
            progress: 40,
            time_left: '1 day left',
            type: 'school',
            users: this.teamUsers,
        },
    ];

    teams: any[] = [
        {
            id: 1,
            name: 'Jerusalem student team',
        },
        {
            id: 2,
            name: 'Hula pupils team',
        },
    ];

    constructor() {}

    ngOnInit() {
        this.fetch();
    }

    ngAfterViewInit() {
        const slider = document.querySelector('.items') as HTMLElement | null;
        let isDown = false;
        let startX: any;
        let scrollLeft: any;

        if (slider != null) {
            slider.addEventListener('mousedown', (e: any) => {
                isDown = true;
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
            });
            slider.addEventListener('mousemove', (e: any) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
        }
    }

    fetch() {}
}
