<div class="">
    <div class="flex flex-1 items-center" style="gap: 48px">
        <div class="flex-fill">
            <app-un-gradient-text text="Organisations" size="big"></app-un-gradient-text>
        </div>

        <div class="ml-auto">
            <app-un-create-button></app-un-create-button>
        </div>
    </div>

    <div class="flex w-full">
        <hr class="w-full" />
    </div>

    <app-card class="mb-3 h-full" [paddingX]="false">
        <app-table [columns]="table.columns" [data]="organizations"></app-table>
    </app-card>
</div>
