<app-card class="mb-3 h-full" [paddingX]="false">
    <div class="mb-1 flex justify-end px-3">
        <button mat-button color="accent" (click)="openDialog(uploadFormTemplate)">
            <i class="ph-upload-simple text-large"></i>
        </button>
    </div>

    <app-table
        [columns]="table.columns"
        [data]="invitations"
        [form]="table.form"
        [filters]="filters"
        [create]="true"
        (onCreate)="create($event)"
        [edit]="true"
        (onEdit)="update($event)"
        [delete]="true"
        (onDelete)="delete($event)"
        (onFiltersChanged)="filter($event)"
        [search]="true"
        (onSearch)="onSearch($event)"
        [deletionConfirm]="true"
        [totalItems]="totalItems"
        (pageChange)="pageChange($event)"
        (limitChange)="limitChange($event)"
        [dialogConfig]="{ width: '600px' }"
    >
    </app-table>
</app-card>

<ng-template #uploadFormTemplate>
    <div mat-dialog-content style="width: 400px">
        <app-table-form
            #uploadModalForm
            [form]="uploadForm"
            [formElements]="uploadForm.form.elements"
            (onSubmit)="upload($event)"
        ></app-table-form>
    </div>
    <div mat-dialog-actions class="row justify-between">
        <div class="col-auto"></div>
        <div class="col-auto">
            <button mat-flat-button color="primary" class="mr-2" (click)="closeDialog()">Cancel</button>
            <button mat-flat-button color="accent" (click)="upload(uploadModalForm.getValue())">Submit</button>
        </div>
    </div>
</ng-template>

<ng-template #invitationTemplate let-invitation="invitation">
    <app-send-invite [invitation]="invitation"></app-send-invite>
</ng-template>
