import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { SessionService } from 'src/app/services/session.service';
import { LoginService } from 'src/app/services/login.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { Moment } from 'moment';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoginHttpInterceptor implements HttpInterceptor {
    constructor(
        private sessionService: SessionService,
        private notificationService: NotificationService,
        private loginService: LoginService
    ) {}

    /**
     * intercepts any request
     *
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // append token
        const token = this.sessionService.getToken();
        const isDetailError = request.params.get('error-object');

        if (token) {
            request = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + token),
            });
        }

        if (isDetailError) {
            request.params.delete('error-object');
        }

        // convert moment dates to strings
        if (request.body) {
            Object.keys(request.body).forEach((key) => {
                const value = request.body[key];
                if (value && typeof value === 'object' && value.hasOwnProperty('_isAMomentObject')) {
                    const d = <Moment>value;
                    request.body[key] =
                        d.hour() === 0 && d.minute() === 0 ? d.format('YYYY-MM-DD') : d.format('YYYY-MM-DD HH:mm');
                }
            });
        }

        return next.handle(request).pipe(
            tap((evt) => {
                if (evt instanceof HttpResponse) {
                    if (evt.headers && evt.headers.get('Client-Version')) {
                        if (environment.version !== evt.headers.get('Client-Version')) {
                            // this.interceptorService.onNotify(true);
                        }
                    }
                }
            }),
            catchError((error) => {
                const redirected = sessionStorage.getItem('redirected') ? true : false;

                if (error.status === 401 && !redirected) {
                    sessionStorage.setItem('redirected', 'true');
                    this.loginService
                        .logOut()
                        .pipe(finalize(() => location.reload()))
                        .subscribe(() => {});
                }

                if (isDetailError) {
                    error = error;
                }

                return throwError(error);
            })
        );
    }
}
