<app-card class="mb-3 h-full" [title]="ENTRY_NAME" [paddingX]="false">
    <app-table
        [columns]="table.columns"
        [data]="mentors"
        [form]="table.form"
        [create]="true"
        [edit]="true"
        [itemsPerPage]="usersPerPage"
    >
    </app-table>
</app-card>

<ng-template #invitationTemplate let-user="user">
    <app-send-invite [user]="user" [roleId]="3"></app-send-invite>
</ng-template>
