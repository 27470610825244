import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    private changeLangSource = new Subject<void>();
    changeLang$ = this.changeLangSource.asObservable();

    private changeUserSource = new Subject<void>();
    changeUser$ = this.changeUserSource.asObservable();

    private sideBarToggledSource = new Subject<void>();
    sideBarToggled$ = this.sideBarToggledSource.asObservable();

    private teamChangedSource = new Subject<number>();
    teamChanged$ = this.teamChangedSource.asObservable();

    private projectChangedSource = new Subject<number>();
    projectChanged$ = this.projectChangedSource.asObservable();

    private viewChangedSource = new Subject<void>();
    viewChanged$ = this.viewChangedSource.asObservable();

    rawAttributes: any[] = [];
    immutableParameters: any[] = [
        'theme',
        'catapultAttributes',
        'setSkillabsInTrainingPlan',
        'setIndividualTrainingsPlan',
    ];
    WOMAN_TEAMS_IDS = [9];
    DEFAULT_LANG_SHORT: string = 'en';

    constructor(private userService: UserService) {}

    /**
     * set current user
     * @param currentUser
     */
    setCurrentUser(currentUser: any) {
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
    }

    /**
     * get current user
     */
    getCurrentUser(): any {
        const user = localStorage.getItem('currentUser');
        return user ? JSON.parse(user) : {};
    }

    isTeacher(): boolean {
        return this.getCurrentUser().role === 'Teacher';
    }

    isAdmin() {
        return this.getCurrentUser().is_admin;
    }

    changeLanguage() {
        this.changeLangSource.next();
    }

    changeUser() {
        this.changeUserSource.next();
    }

    toggleSideBar() {
        this.sideBarToggledSource.next();
    }

    can(type: string, group: string, scope: string) {
        return true;

        const user = this.getCurrentUser();

        if (user.admin) {
            return true;
        }
        return (
            user.permissions.findIndex((permission: any) => {
                return this.isPermission(permission, type, group, scope);
            }) >= 0
        );
    }

    cannot(type: string, group: string, scope: string) {
        return false;

        return !this.can(type, group, scope);
    }

    isPermission(permission: any, type: string, group: string, scope: string): boolean {
        if (scope === '*') {
            return permission.group === group || permission.type === type;
        } else {
            return permission.group === group && permission.type === type && permission.scope === scope;
        }
    }

    /**
     * set token
     * @param token
     */
    setToken(token: any) {
        sessionStorage.setItem('token', JSON.stringify(token));
    }

    /**
     * get token
     */
    getToken(): any {
        const item = sessionStorage.getItem('token');
        return item ? JSON.parse(item) : false;
    }

    /**
     * set is user complete questionnaire
     * @param completed
     */
    setQuestionnaire(completed: boolean): void {
        sessionStorage.setItem('questionnaire', JSON.stringify(completed));
    }

    /**
     * get is user complete questionnaire
     */
    getQuestionnaire(): boolean {
        const questionnaire = sessionStorage.getItem('questionnaire');
        return questionnaire ? JSON.parse(questionnaire) : false;
    }

    /**
     * set is user complete questionnaire
     * @param completed
     */
    setQuestionnairePopUp(completed: boolean): void {
        sessionStorage.setItem('questionnaire_popup', JSON.stringify(completed));
    }

    /**
     * get is user complete questionnaire
     */
    getQuestionnairePopUp(): boolean {
        const questionnaire_popup = sessionStorage.getItem('questionnaire_popup');
        return questionnaire_popup ? JSON.parse(questionnaire_popup) : false;
    }

    isQuestionnairePageActive(): boolean {
        if (this.isAdmin()) {
            return false;
        }
        return !this.getQuestionnaire() || !this.getCurrentUser()?.inited;
    }

    /**
     * set token
     * @param token
     */
    setChatToken(token: any) {
        sessionStorage.setItem('chat_token', JSON.stringify(token));
    }

    /**
     * get token
     */
    getChatToken(): any {
        const item = sessionStorage.getItem('chat_token');
        return item ? JSON.parse(item) : false;
    }

    /**
     * set team
     * @param team
     */
    setTeam(team: any) {
        localStorage.setItem('team', JSON.stringify(team));
    }

    /**
     * set current user school
     * @param currentUserSchool
     */
    setCurrentUserSchool(currentUserSchool: any) {
        localStorage.setItem('currentUserSchool', JSON.stringify(currentUserSchool));
    }

    setCurrentUserProject(currentUserProject: any) {
        localStorage.setItem('currentUserProject', JSON.stringify(currentUserProject));
    }

    getCurrentUserProject() {
        const item = localStorage.getItem('currentUserProject');
        return item ? JSON.parse(item) : false;
    }

    /**
     * get current user school
     */
    getCurrentUserSchool() {
        const item = localStorage.getItem('currentUserSchool');
        return item ? JSON.parse(item) : false;
    }

    /**
     * get team
     */
    getTeam(): any {
        // return JSON.parse(localStorage.getItem('team'));
    }

    /**
     * set language
     * @param language
     */
    setLanguage(language: string) {
        localStorage.setItem('language', language);
    }

    getLanguage(): string {
        return localStorage.getItem('language') || environment.defaultLanguage;
    }

    ungroup(array: any) {
        return [].concat.apply([], array);
    }

    setSkillabsInTrainingPlan(data: any) {
        localStorage.setItem('setSkillabsInTrainingPlan', data);
    }

    getSkillabsInTrainingPlan() {
        return localStorage.getItem('setSkillabsInTrainingPlan');
    }

    setStudentTasksViewMode(mode: any) {
        localStorage.setItem('studentTasksViewMode', mode);
        this.viewChangedSource.next();
    }

    getStudentTasksViewMode() {
        const storageMode = localStorage.getItem('studentTasksViewMode');

        if (!storageMode) {
            return true;
        }

        return storageMode && storageMode !== 'false' ? true : false;
    }

    /**
     * clear
     */
    clear() {
        const parameters = this.getImmutableParameters();
        localStorage.clear();
        sessionStorage.clear();
        this.setImmutableParameters(parameters);
    }

    getImmutableParameters() {
        return this.immutableParameters
            .filter((parameter) => localStorage.getItem(parameter))
            .map((parameter) => ({
                name: parameter,
                value: localStorage.getItem(parameter),
            }));
    }

    setImmutableParameters(parameters: any) {
        parameters.forEach((parameter: any) => {
            localStorage.setItem(parameter.name, parameter.value);
        });
    }

    refreshPage(): void {
        window.location.reload();
    }

    pushCurrentUserUniChatHistory(request: string) {
        const history = this.getCurrentUserUniChatHistory();
        history.push(request);
        localStorage.setItem('currentUserUniChatHistory', JSON.stringify(history));
    }

    getCurrentUserUniChatHistory() {
        const historyItem = localStorage.getItem('currentUserUniChatHistory');
        return historyItem ? JSON.parse(historyItem) : [];
    }

    getRouteByState(state: string) {
        var routes: any = {
            survey: '/questionnaire',
            survey_done: '/questionnaire',
            start_platform: '/questionnaire',
            platform: '/home',
            school_survey: '/surveys',
            default: '/home',
        };
        return routes[state] || routes['default'];
    }

    changeTeam(teamId: number) {
        this.teamChangedSource.next(teamId);
    }

    changeProject(projectId: number) {
        this.projectChangedSource.next(projectId);
    }

    setEligibleSurveys(completed: boolean): void {
        sessionStorage.setItem('hasEligibleSurveys', JSON.stringify(completed));
    }

    getEligibleSurveys(): any[] {
        const surveys = sessionStorage.getItem('hasEligibleSurveys');
        return surveys ? JSON.parse(surveys) : false;
    }
}
