import { Table } from './Table';

export class ProjectCalendarTable extends Table {
    constructor() {
        super();
        this.buildColumns();
    }

    buildColumns() {
        this.columns = [
            {
                name: '№',
                selector: 'id',
                headerClass: 'text-center',
                class: () => 'text-center',
            },
            {
                name: 'Sunday',
                selector: 'sunday',
                headerClass: 'text-center',
                class: () => 'text-center',
                transform: (item) =>
                    `<span class="text-capitalize text-center tag px-3 mr-1 ${this.getColor(
                        item
                    )}" style="color: #fff !important;min-width: 78px;display: block;">${item}</span>`,
            },
            {
                name: 'Monday',
                selector: 'monday',
                transform: (item) =>
                    `<span class="text-capitalize text-center tag px-3 mr-1 ${this.getColor(
                        item
                    )}" style="color: #fff !important;min-width: 78px;display: block;">${item}</span>`,
                headerClass: 'text-center',
                class: () => 'text-center',
            },
            {
                name: 'Tuesday',
                selector: 'tuesday',
                trustHtml: true,
                transform: (item) =>
                    `<span class="text-capitalize text-center tag px-3 mr-1 ${this.getColor(
                        item
                    )}" style="color: #fff !important;min-width: 78px;display: block;">${item}</span>`,
                headerClass: 'text-center',
                class: () => 'text-center',
            },
            {
                name: 'Wednesday',
                selector: 'wednesday',
                headerClass: 'text-center',
                class: () => 'text-center',
                transform: (item) =>
                    `<span class="text-capitalize text-center tag px-3 mr-1 ${this.getColor(
                        item
                    )}" style="color: #fff !important;min-width: 78px;display: block;">${item}</span>`,
            },
            {
                name: 'Thursday',
                selector: 'thursday',
                headerClass: 'text-center',
                class: () => 'text-center',
                transform: (item) =>
                    `<span class="text-capitalize text-center tag px-3 mr-1 ${this.getColor(
                        item
                    )}" style="color: #fff !important;min-width: 78px;display: block;">${item}</span>`,
            },
            {
                name: 'Friday',
                selector: 'friday',
                headerClass: 'text-center',
                class: () => 'text-center',
                transform: (item) =>
                    `<span class="text-capitalize text-center tag px-3 mr-1 ${this.getColor(
                        item
                    )}" style="color: #fff !important;min-width: 78px;display: block;">${item}</span>`,
            },
        ];
    }

    getColor(item: any) {
        return item === 'Project' ? 'pink' : '';
    }

    getStatus(status: string): any {
        return {
            false: '<i class="far fa-square"></i>',
            progress: '<i class="fas fa-square"></i>',
            done: '<i class="far fa-check-square"></i>',
        }[status];
    }
}
