export abstract class Paginable {
    page: number = 1;
    limit: number = 15;
    totalItems: number = 0;

    abstract fetch(): void;

    pageChange(page: number): void {
        this.page = page;
        this.fetch();
    }

    limitChange(limit: number): void {
        this.limit = limit;
        this.page = 1;
        this.fetch();
    }

    setDefaultPagination(): void {
        this.page = 1;
        this.limit = 15;
    }
}
