<div class="container mx-auto px-4">
    <div class="border-t border-indigo-500/25 p-3">
        <app-un-gradient-text [text]="'account.general_information' | translate" size="medium"> </app-un-gradient-text>

        <div class="max-w-[600px] mx-auto text-lg p-1">
            <div class="grid grid-cols-12">
                <div class="col-span-12 md:col-span-4 text-start">
                    <span class="text-slate-400 font-normal">{{ 'account.email' | translate }}:</span>
                </div>
                <div class="col-span-12 md:col-span-8 text-start">
                    {{ user.email }}
                </div>
            </div>
        </div>

        <div class="max-w-[600px] mx-auto text-lg p-1">
            <div class="grid grid-cols-12">
                <div class="col-span-12 md:col-span-4 text-start">
                    <span class="text-slate-400 font-normal">{{ 'account.nickname' | translate }}:</span>
                </div>
                <div class="col-span-12 md:col-span-7 text-start">
                    {{ user.nickname }}
                </div>
                <div class="col-span-12 md:col-span-1">
                    <button
                        mat-button
                        class="button-small flex items-center justify-center"
                        (click)="openModal(updateNicknameModalContent)"
                    >
                        <i class="ph ph-pencil-simple-line"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="max-w-[600px] mx-auto text-lg p-1">
            <div class="grid grid-cols-12">
                <div class="col-span-12 md:col-span-4 text-start">
                    <span class="text-slate-400 font-normal">{{ 'account.birthday' | translate }}:</span>
                </div>
                <div class="col-span-12 md:col-span-7 text-start">
                    {{ user.birthday | date: 'dd.MM.yy' }}
                </div>
                <div class="col-span-12 md:col-span-1">
                    <button
                        mat-button
                        class="button-small flex items-center justify-center"
                        (click)="openModal(updateBirthdayModalContent)"
                    >
                        <i class="ph ph-pencil-simple-line"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="border-t border-indigo-500/25 p-3">
        <app-un-gradient-text [text]="'account.my_profile' | translate" size="medium"> </app-un-gradient-text>

        <div class="max-w-[600px] mx-auto">
            <form class="px-3">
                <div class="flex items-center gap-x-6">
                    <input hidden #file type="file" accept="image/*" (change)="preview(file.files)" />

                    <label
                        *ngIf="imgURL"
                        class="img-upload btn-bwm rounded-full col-span-4 bg-cover bg-no-repeat w-24 h-24"
                        (click)="file.click()"
                        [ngStyle]="{ 'background-image': 'url(' + imgURL + ')' }"
                    >
                    </label>

                    <label
                        *ngIf="!imgURL"
                        class="img-upload btn-bwm rounded-full col-span-4 bg-cover bg-no-repeat w-24 h-24"
                        (click)="file.click()"
                        style="background-image: url('assets/profile-placeholder.jpg')"
                    >
                    </label>

                    <span class="text-gray-500 hover:text-white/75 cursor-pointer text-base" (click)="file.click()">
                        {{ 'account.change_your_profile_picture' | translate }}
                    </span>
                </div>

                <div class="flex justify-end">
                    <app-un-save-button
                        [text]="'account.save' | translate"
                        [disabled]="!imgURL"
                        (click)="saveProfileImage()"
                    ></app-un-save-button>
                </div>
            </form>
        </div>
    </div>

    <div class="settings-section border-t border-indigo-500/25 p-3">
        <app-un-gradient-text [text]="'account.password' | translate" size="medium"> </app-un-gradient-text>

        <div class="max-w-[600px] mx-auto">
            <div *ngIf="!isChangePassword" class="w-full my-4">
                <app-un-magic-button
                    [text]="'account.change_password' | translate"
                    [fullWidth]="true"
                    [bordered]="true"
                    (click)="toggleChangePassword()"
                ></app-un-magic-button>
            </div>
            <app-change-password *ngIf="isChangePassword"></app-change-password>
        </div>
    </div>

    <div class="border-t border-indigo-500/25 p-3">
        <app-un-gradient-text [text]="'account.language' | translate" size="medium"> </app-un-gradient-text>

        <div class="max-w-[600px] mx-auto">
            <span class="text-slate-400 font-normal">{{ 'account.current_language' | translate }}: </span>
            <span mat-raised-button (click)="openBottomSheet(langSelector)">
                {{ langName }}<i class="ph ph-globe-hemisphere-westfa-lg px-1"></i>
            </span>
        </div>
    </div>
</div>

<ng-template #updateNicknameModalContent>
    <div mat-dialog-content style="width: 500px">
        <div class="flex justify-center">
            <div class="col-12">
                <mat-form-field class="col-12 mb-2" hintLabel="Max {{ nicknameLimit }} characters">
                    <input
                        #nicknameInput
                        matInput
                        type="text"
                        [placeholder]="'account.nickname' | translate"
                        [maxLength]="nicknameLimit"
                        [formControl]="nicknameFormControl"
                        spellcheck="false"
                    />
                    <mat-hint align="end"> {{ nicknameInput.value.length || 0 }}/{{ nicknameLimit }} </mat-hint>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="flex justify-between mt-4">
        <app-un-back-button (сlicked)="closeDialog()"></app-un-back-button>
        <app-un-save-button
            [text]="'communications.info.submit' | translate"
            (сlicked)="updateUser('nickname')"
            [disabled]="nicknameFormControl.invalid"
        ></app-un-save-button>
    </div>
</ng-template>

<ng-template #updateEmailModalContent>
    <div mat-dialog-content style="width: 500px">
        <div class="flex justify-center">
            <div class="col-12">
                <mat-form-field class="col-12 mb-2">
                    <input
                        matInput
                        type="email"
                        placeholder="Email"
                        [formControl]="emailFormControl"
                        spellcheck="false"
                    />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="row justify-between">
        <div class="col-auto"></div>
        <div class="col-auto">
            <button class="btn btn-primary mr-2" (click)="closeDialog()">Cancel</button>
            <button
                class="btn btn-success"
                type="submit"
                [disabled]="emailFormControl.invalid"
                (click)="updateUser('email')"
            >
                {{ 'communications.info.submit' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #updateBirthdayModalContent>
    <div mat-dialog-content style="width: 500px">
        <div class="flex justify-center row">
            <div class="col-12">
                <mat-form-field class="col-12 mb-2">
                    <mat-label>Birthday</mat-label>
                    <input
                        matInput
                        [matDatepicker]="birthday"
                        placeholder="Birthday"
                        [formControl]="birthdayFormControl"
                    />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="birthday"></mat-datepicker-toggle>
                    <mat-datepicker #birthday></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="flex justify-between mt-4">
        <app-un-back-button (сlicked)="closeDialog()"></app-un-back-button>
        <app-un-save-button
            [text]="'communications.info.submit' | translate"
            (сlicked)="updateUser('birthday')"
            [disabled]="nicknameFormControl.invalid"
        ></app-un-save-button>
    </div>
</ng-template>

<ng-template #langSelector>
    <mat-nav-list>
        <mat-option *ngFor="let option of langs" mat-list-item (click)="setLang($event, option)">
            <mat-icon>outlined_flag</mat-icon>{{ option.name }}
        </mat-option>
    </mat-nav-list>
</ng-template>
