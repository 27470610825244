import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-pagination',
    template: `
        <nav aria-label="Page navigation">
            <ul class="list-style-none flex items-center gap-2 p-0" *ngIf="totalPages > 1">
                <li *ngIf="currentPage > 1">
                    <button
                        (click)="goToPreviousPage()"
                        class="flex text-gray-500 hover:text-[#FF02D0] focus:outline-none"
                    >
                        <i class="ph ph-caret-circle-left text-base rtl:hidden"></i>
                        <i class="ph ph-caret-circle-right text-base ltr:hidden"></i>
                    </button>
                </li>
                <li *ngFor="let pageNumber of getPageNumbers(); trackBy: trackByFn">
                    <button
                        (click)="goToPage(pageNumber)"
                        class="flex px-2 py-1 focus:outline-none focus:ring-0 "
                        [ngClass]="{
                            '!text-[#FF02D0]': pageNumber === currentPage,
                            'text-gray-500': pageNumber !== currentPage
                        }"
                    >
                        {{ pageNumber }}
                    </button>
                </li>
                <li *ngIf="currentPage < totalPages">
                    <button (click)="goToNextPage()" class="flex text-gray-500 hover:text-[#FF02D0] focus:outline-none">
                        <i class="ph ph-caret-circle-right text-base rtl:hidden"></i>
                        <i class="ph ph-caret-circle-left text-base ltr:hidden"></i>
                    </button>
                </li>
            </ul>
        </nav>
    `,
    styles: [':host { display: block; }'],
})
export class PaginationComponent {
    @Input() currentPage: number = 1;
    @Input() totalItems: number = 0;
    @Input() pageSize: number = 8;
    @Output() pageChange = new EventEmitter<number>();

    get totalPages(): number {
        return Math.ceil(this.totalItems / this.pageSize);
    }

    getPageNumbers(): number[] {
        const pageNumbers: number[] = [];
        const numPagesToShow = 3; // You can adjust this to show more or less pages
        const startPage = Math.max(1, this.currentPage - Math.floor(numPagesToShow / 2));
        const endPage = Math.min(this.totalPages, startPage + numPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    }

    trackByFn(index: number, item: number) {
        return item;
    }

    goToPreviousPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.pageChange.emit(this.currentPage);
        }
    }

    goToNextPage() {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.pageChange.emit(this.currentPage);
        }
    }

    goToPage(pageNumber: number) {
        if (pageNumber > 0 && pageNumber <= this.totalPages) {
            this.currentPage = pageNumber;
            this.pageChange.emit(this.currentPage);
        }
    }
}
