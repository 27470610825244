import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class MentorsService {
    public API: string = '/mentors';

    constructor(private http: HttpClient) {}

    getAll(projectId: number) {
        const url = new Url(this.API);

        url.addQueryParameter('project_id', projectId);

        return this.http.get(url.path);
    }
}
