import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChatsService } from 'src/app/services/chat.service';
import { SessionService } from 'src/app/services/session.service';
import { Tools } from 'src/app/tools';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-chat-iframe',
  templateUrl: './chat-iframe.component.html',
  styleUrls: ['./chat-iframe.component.scss']
})
export class ChatIframeComponent implements OnInit {

  CHAT_ORIGIN: string = environment.chatUrl;
  CHAT_URL: string = '';

  @ViewChild('iframe', { static: false, read: ElementRef })
  private iframe: ElementRef;

  loggedIn: boolean = false;

  @Output() chatNameChanged: EventEmitter<any> = new EventEmitter();

  subscriptions: Subscription[] = [];



  constructor(
    public session: SessionService,
    private chatsService: ChatsService
  ) {
    this.subscriptions.push(
      this.chatsService.chatSelect$.subscribe((data) => {
        if (this.loggedIn) {
          this.selectChat(data);
        }
      })
    );
  }



  ngOnInit() {
  }

  ngOnDestroy() {
    Tools.unsubscribeAll(this.subscriptions);
  }


  ngAfterViewInit() {

    this.chatsService.getLastActivity().subscribe((response: any) => {

      const uri = response.data.url ? response.data.url : 'channel/general';
      this.CHAT_URL = 'https://chat.unispher.com' + uri + '?layout=embedded&resetlogins=true';
      const lastActivityName = /[^/]*$/.exec(response.data.url);
      const tempName = lastActivityName ? lastActivityName[0] : 'General';

      this.setChatName(tempName);

      if (this.iframe.nativeElement) {
        this.iframe.nativeElement.addEventListener('load', this.onLoad.bind(this));
      }


    });
  }

  onLoad(e: any) {

    this.iframe.nativeElement.contentWindow?.postMessage({
      externalCommand: 'login-with-token',
      token: this.session.getChatToken()
    }, '*');

    window.addEventListener('message', (e) => {
      if (e.data.eventName === 'Custom_Script_Logged_In') {
        this.loggedIn = true;
      }

      // if (e.data.eventName === 'Custom_Script_On_Logout') {
      // }
    });

  }

  logout() {
    this.iframe.nativeElement.contentWindow?.postMessage({
      externalCommand: 'logout',
      token: this.session.getChatToken()
    }, '*');
  }


  setChatName(chatName: string) {
    this.chatNameChanged.emit(chatName);
  }

  selectChat(chat: any) {
    this.setChatName(chat.name);
    this.iframe.nativeElement.contentWindow.postMessage({
      externalCommand: 'go',
      path: chat.url
    }, '*')
  }

}
