import { Table } from './Table';

export class NetworkProjectsTable extends Table {
    constructor() {
        super();
        this.buildColumns();
    }

    buildColumns() {
        this.columns = [
            {
                name: 'School',
                selector: 'school',
                class: () => 'text-nowrap',
            },
            {
                name: 'Projects',
                selector: 'projects',
                // class: () => 'text-nowrap'
            },
            {
                name: 'Users',
                selector: 'users',
            },
            {
                name: 'Location',
                selector: 'location',
                trustHtml: true,
            },
            {
                name: 'Professional Sphere',
                selector: 'professional_sphere',
                headerClass: 'text-nowrap',
            },
        ];
    }

    getColor(item: any) {
        return item === 'Project' ? 'pink' : '';
    }

    getStatus(status: string): any {
        return {
            false: '<i class="far fa-square"></i>',
            progress: '<i class="fas fa-square"></i>',
            done: '<i class="far fa-check-square"></i>',
        }[status];
    }
}
