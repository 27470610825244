<!-- <svg width="105%" [attr.height]="height" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <linearGradient id="gradient" y1="0" y2="1">
            <stop stop-color="#CF88C2" offset="0" />
            <stop stop-color="#966FFF" offset="1" />
        </linearGradient>
    </defs>
    <g>
        <text
            id="text"
            [attr.y]="size"
            font-family="sans-serif"
            [attr.text-decoration]="decoration"
            [attr.font-size]="size"
            font-weight="bold"
            stroke-width="2"
            stroke="url(#gradient)"
            fill="none"
        >
            {{ text }}
        </text>
    </g>
</svg> -->

<span class="word-break line-clamp-1 uppercase" [title]="text" [class]="fontSizeClass">{{ text }}</span>

<!-- <span>{{ text }}</span> -->
