<app-layout *ngIf="!isLoading">
    <div>
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
</app-layout>

<ng-container *ngIf="isLoading">
    <div class="spinner-overlay">
        <div class="lds-hourglass"></div>
    </div>
</ng-container>

<app-notification></app-notification>
