import { environment } from 'src/environments/environment';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { finalize } from 'rxjs/operators';
import { InvitationsService } from 'src/app/services/invitations.service';
import { ManageInvitationsTable } from 'src/app/tables/ManageInvitationsTable';
import { MatDialog } from '@angular/material/dialog';
import { UploadInvitationsForm } from 'src/app/forms/UploadInvitationsForm';
import { Paginable } from 'src/app/classes/Paginable';

interface Invitation {
    id?: number;
    mentor: boolean;
    role_id: number;
    project_id: number;
    email: string;
    title: string;
    role: any;
}

@Component({
    selector: 'app-manage-invitations',
    templateUrl: './manage-invitations.component.html',
    styleUrls: ['./manage-invitations.component.css'],
})
export class ManageInvitationsComponent extends Paginable implements OnInit {
    invitations: Invitation[] = [];

    ENTRY_NAME: string = 'Invitations';

    filters = [];
    selectedFilters = [];

    project;
    role;

    roles = [];

    storageUrl: string = environment.storageUrl;

    page = 1;
    limit = 15;

    search: string = '';

    uploadForm = new UploadInvitationsForm();

    inited = false;

    @ViewChild('invitationTemplate') invitationTemplate: TemplateRef<any>;

    table = new ManageInvitationsTable();

    isLoading: boolean = true;

    constructor(
        private invitationsService: InvitationsService,
        private notificationService: NotificationService,
        private dialog: MatDialog,
        public sessionService: SessionService,
    ) {
        super();
    }

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.isLoading = true;
        this.invitationsService
            .getAll(this.page, this.limit, this.selectedFilters, this.search)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(({ data }: any) => {
                this.invitations = data.invitations || [];
                this.roles = data.roles;
                this.totalItems = data.total;

                this.table.invitationTemplate = this.invitationTemplate;
                this.table.buildColumns();

                if (!this.inited) {
                    this.initFilters(data);
                    this.inited = true;
                }

                this.uploadForm.buildForm(data.projects);
                this.table.buildForm(data.projects, data.roles);
            });
    }

    initFilters(data) {
        this.filters = [
            {
                key: 'role',
                label: 'Role',
                options: data.roles,
                optionTextSelector: 'name',
                optionValueSelector: 'id',
                optionEmpty: true,
                optionEmptyText: '-',
                optionEmptyValue: null,
            },
            {
                key: 'project',
                label: 'Project',
                class: 'w-48',
                options: data.projects,
                optionTextSelector: 'title',
                optionValueSelector: 'id',
                optionEmpty: true,
                optionEmptyText: '-',
                optionEmptyValue: null,
            },
        ];
    }

    create(data: Invitation) {
        this.invitationsService
            .create(data)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success(this.ENTRY_NAME + ' created successfully');
                    this.fetch();
                },
                error: (error) => this.notificationService.error(error.error.reason, 'Error'),
            });
    }

    update(data: Invitation) {
        this.invitationsService
            .update(data.id, data)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success(this.ENTRY_NAME + ' updated successfully');
                    this.fetch();
                },
                error: (error) => this.notificationService.error(error.error.reason, 'Error'),
            });
    }

    delete(data: Invitation) {
        this.invitationsService
            .delete(data.id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.notificationService.success(this.ENTRY_NAME + ' deleted successfully');
                    this.fetch();
                },
                error: (error) => this.notificationService.error(error.error.reason, 'Error'),
            });
    }

    filter(filters: any) {
        this.selectedFilters = filters;
        this.fetch();
    }

    openDialog(dialogRef: TemplateRef<any>) {
        this.dialog.open(dialogRef);
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    upload(data: any) {
        const formData = new FormData();
        formData.append('file', data.file, data.file.name);

        this.invitationsService.upload(data.project_id, formData).subscribe({
            next: () => {
                this.fetch();
                this.closeDialog();
            },
            error: (err) => this.notificationService.error(err.error.message, 'Error'),
        });
    }

    onSearch(search: string) {
        this.search = search;
        this.fetch();
    }
}
