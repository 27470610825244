import { environment } from 'src/environments/environment';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { Tools } from 'src/app/tools';
import { ManageSchoolStudentsTable } from 'src/app/tables/ManageSchoolStudentsTable';
import { UsersService } from 'src/app/services/users.service';

@Component({
    selector: 'app-manage-mentors',
    templateUrl: './manage-mentors.component.html',
    styleUrls: ['./manage-mentors.component.css'],
})
export class ManageMentorsComponent implements OnInit {
    ENTRY_NAME: string = 'Mentors';

    mentors: any[] = [];
    isLoading: boolean = false;
    storageUrl: string = environment.storageUrl;

    usersPerPage: number = this.usersService.getUsersPerPage();

    table = new ManageSchoolStudentsTable();

    @ViewChild('invitationTemplate') invitationTemplate: TemplateRef<any>;

    constructor(
        private usersService: UsersService,
        private notificationService: NotificationService,
        public dialog: MatDialog,
        public sessionService: SessionService
    ) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.usersService.getAll().subscribe((response: any) => {
            this.mentors = response.data || [];
            this.table.invitationTemplate = this.invitationTemplate;
            this.table.buildColumns();
            this.table.buildForm();
        });
    }

    // create(data: any) {
    //   this.miniGamesService.create(Tools.getFormData(data)).pipe(finalize(() => this.isLoading = false)).subscribe((response: any) => {
    //     this.notificationService.success(this.ENTRY_NAME + ' created successfully');
    //     this.fetch();
    //   }, (err) => {
    //     this.notificationService.error(err.error.message, 'Error');
    //     this.isLoading = false;
    //   });
    // }

    // update(data: any) {
    //   this.miniGamesService.update(data.id, Tools.getFormData(data)).pipe(finalize(() => this.isLoading = false)).subscribe((response: any) => {
    //     this.notificationService.success(this.ENTRY_NAME + ' updated successfully');
    //     this.fetch();
    //   }, (err) => {
    //     this.notificationService.error(err.error.message, 'Error');
    //     this.isLoading = false;
    //   });
    // }

    // delete(data: any) {
    //   this.miniGamesService.delete(data.id).pipe(finalize(() => this.isLoading = false)).subscribe((response: any) => {
    //     this.notificationService.success(this.ENTRY_NAME + ' deleted successfully');
    //     this.fetch();
    //   }, (err) => {
    //     this.notificationService.error(err.error.message, 'Error');
    //     this.isLoading = false;
    //   });
    // }
}
