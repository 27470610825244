<div *ngIf="!isLoading" class="flex h-full flex-col justify-between">
    <div #content class="un-scroll grid h-full overflow-y-auto p-2">
        <div class="mt-auto">
            <ng-container *ngFor="let msg of messages">
                <div
                    #messages
                    class="message-container flex w-full items-center gap-2"
                    [class.mine]="msg.user.id === user.id"
                >
                    <div
                        *ngIf="msg.user.id !== user.id"
                        class="flex h-6 w-6 items-center justify-center rounded-full bg-[#512BB6]"
                        style="border: 1px solid #363160"
                        [matTooltip]="msg.user.nickname"
                        matTooltipPosition="above"
                    >
                        <img [src]="defaultImageUrl" class="h-5 w-5 min-w-[20px] max-w-full rounded-full" />
                    </div>
                    <div class="message my-1 flex items-end gap-x-1" [class.mine]="msg.user.id === user.id">
                        <span class="message-time self-center whitespace-nowrap">{{ msg.date | date: 'HH:mm' }}</span>
                        <div class="un-scroll self-center" [innerHTML]="msg.message | codeToPre | nl2br"></div>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="isTyping" class="message-container flex w-full items-center gap-2">
                <div
                    class="flex h-6 w-6 items-center justify-center rounded-full bg-[#512BB6]"
                    style="border: 1px solid #363160"
                    [matTooltip]="uniBot.nickname"
                    matTooltipPosition="above"
                >
                    <img [src]="defaultImageUrl" class="h-5 w-5 min-w-[20px] max-w-full rounded-full" />
                </div>
                <div class="message my-1 flex w-20 items-center justify-between">
                    <div class="pulse-dot pulse-dot-1"></div>
                    <div class="pulse-dot pulse-dot-2"></div>
                    <div class="pulse-dot pulse-dot-3"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="sticky bottom-0 w-full">
        <div
            class="justify-content-start my-3 grid grid-cols-12 items-center gap-2 px-2"
            (keydown)="keyDownFunction($event)"
        >
            <div class="col-span-10">
                <textarea
                    [formControl]="message"
                    [placeholder]="'communications.workspace.chat_description' | translate"
                    class="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] text-xs leading-[1.6] text-[#8E91D1] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-[#8e91d1]"
                    rows="1"
                    style="border: 1px dashed #8e91d1 !important"
                    #textArea
                    (keyup.arrowup)="moveByHistory(-1)"
                    (keyup.arrowdown)="moveByHistory(1)"
                    (input)="onInput()"
                ></textarea>

                <div class="flex justify-end text-xs text-gray-500 dark:text-gray-400">
                    {{ message.value.length }} / {{ 256 }}
                </div>
            </div>
            <div
                matRipple
                class="send-message-button interactive col-span-2 flex items-center justify-center"
                (click)="sendMessage()"
            >
                <i class="ph-paper-plane-right text-lg"></i>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading" class="flex h-full max-h-[400px] items-center justify-center">
    <mat-spinner></mat-spinner>
</div>
