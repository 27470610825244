import { Validators } from '@angular/forms';
import { Table } from './Table';

export class VotesTopicsTable extends Table {
    constructor() {
        super();
        this.buildColumns();
    }

    buildColumns() {
        this.columns = [
            {
                name: 'Author',
                selector: 'author',
                class: () => 'text-nowrap',
                transform: (author) => author.nickname,
            },
            {
                name: 'Title',
                selector: 'title',
                // class: () => 'text-nowrap'
            },
            {
                name: 'Description',
                selector: 'description',
            },
            {
                name: 'Image',
                selector: 'image',
                headerClass: 'text-nowrap text-center',
                class: () => 'text-center',
                type: 'image',
                trustHtml: true,
            },
            {
                name: 'Type',
                selector: 'type',
                headerClass: 'text-nowrap',
            },
            {
                name: 'Active',
                selector: 'active',
                headerClass: 'text-nowrap text-center',
                class: () => 'text-center',
                transform: (active) =>
                    active ? '<i class="ph ph-circle text-green"></i>' : '<i class="ph ph-circle text-red"></i>',
            },
        ];
    }

    buildForm(): void {
        this.form = {
            title: () => '',
            elements: [
                {
                    name: 'ID',
                    selector: 'id',
                    type: 'text',
                    visible: false,
                },
                {
                    name: 'Image',
                    selector: 'image',
                    type: 'image',
                    class: 'col-span-12',
                    // validators: [Validators.required]
                },
                {
                    name: 'Title',
                    selector: 'title',
                    type: 'text',
                    class: 'col-span-12',
                    validators: [Validators.required],
                },
                {
                    name: 'Type',
                    selector: 'type',
                    type: 'radio',
                    value: 'custom',
                    options: [
                        {
                            name: 'Basic',
                            value: 'basic',
                        },
                        {
                            name: 'Custom',
                            value: 'custom',
                        },
                    ],
                    optionTextSelector: 'name',
                    optionValueSelector: 'value',
                    defaultValue: 'custom',
                    class: 'col-span-7',
                    visible: false,
                },
                {
                    name: 'Description',
                    selector: 'description',
                    type: 'textarea',
                    class: 'col-span-12',
                    validators: [Validators.required],
                },
                // {
                //     name: 'author_id',
                //     selector: 'author_id',
                //     type: 'text'
                // },

                {
                    name: 'Active',
                    selector: 'active',
                    defaultValue: false,
                    class: 'col-span-12',
                    type: 'checkbox',
                },
            ],
        };
    }
}
