export const hubItems = [
    {
        name: 'Spielervergleich',
        link: '/management/player-comparison',
        color: '#eef0f2',
        icon: '',
        type: 'link',
        queryParams: '',
        transform: {
            x: 78,
            y: 76
        }
    }, {
        name: 'Player Index',
        link: '/management/player-index',
        color: '#eef0f2',
        icon: '',
        type: 'link',
        queryParams: '',
        transform: {
            x: 50,
            y: -26
        }
    }, {
        name: 'Portal',
        link: '/dashboard',
        color: '#eef0f2',
        icon: '',
        type: 'hide',
        queryParams: '',
        transform: {
            x: -50,
            y: 178
        }
    },
    // {
    //     name: 'Trainer Scouting',
    //     link: 'management/trainer-scouting',
    //     color: '#eef0f2',
    //     icon: '',
    //     type: 'link',
    //     queryParams: {
    //         type: 'trainer'
    //     },
    //     transform: {
    //         x: -78,
    //         y: 76
    //     }
    // },
    {
        name: 'Spielanalyse',
        link: 'library/match-analysis',
        color: '#eef0f2',
        icon: '',
        type: 'link',
        queryParams: '',
        transform: {
            x: -78,
            y: 76
        }
    }
];

