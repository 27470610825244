<div class="flex px-3 mt-4">
    <ul class="pagination flex gap-4 text-pink-500" [hidden]="totalItems <= limit">
        <li class="page-item" [ngClass]="{ disabled: pages[0] == page }">
            <span class="page-link cursor-pointer" (click)="changePage(1)">«</span>
        </li>

        <li *ngFor="let item of pages" class="page-item" [ngClass]="{ '!text-gray-200': item == page }">
            <span class="page-link cursor-pointer" (click)="changePage(item)">{{ item }}</span>
        </li>

        <li class="page-item" [ngClass]="{ disabled: pages[pages.length - 1] == page }">
            <span class="page-link cursor-pointer" (click)="changePage(-1)">»</span>
        </li>
    </ul>

    <ul *ngIf="!hidePerPageSelect" class="pagination print-none ml-auto flex gap-4 text-pink-500 justify-end">
        <li *ngFor="let item of itemsPerPageSelect" class="page-item" [ngClass]="{ '!text-gray-200': item === limit }">
            <span class="page-link cursor-pointer" (click)="changeLimit(item)">{{ item }}</span>
        </li>
    </ul>
</div>
