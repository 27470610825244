<div>
    <div class="flex flex-col">
        <div class="friends-zone un-scroll">
            <div *ngIf="!isLoading">
                <ng-container *ngIf="filteredFriends.length > 0">
                    <ng-container *ngFor="let friend of filteredFriends">
                        <ng-template *ngTemplateOutlet="templateFriend; context: { $implicit: friend }"></ng-template>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="filteredFriends.length === 0">
                    <span>{{ 'friend_bar.not_found' | translate }}</span>
                </ng-container>
            </div>
        </div>

        <div class="flex justify-center my-5">
            <mat-spinner *ngIf="isLoading" [diameter]="70"></mat-spinner>
        </div>
    </div>
</div>

<ng-template #templateFriend let-friend>
    <div class="grid-container items-center">
        <div class="grid-item position-relative">
            <div class="position-relative" style="width: 35px; height: 35px">
                <img class="friend-img" [src]="friend?.image_path" />
            </div>
        </div>

        <div class="grid-item font-weight-400 ml-2" style="font-size: 0.85em">
            <div class="cursor-pointer">{{ friend.nickname }}</div>
            <div class="font-weight-400 cursor-pointer flex" style="gap: 8px">
                <div *ngIf="!friend.invited">
                    <button matRipple class="set-friend-button accept" (click)="sendFriendsRequest(friend.id)">
                        {{ 'friend_bar.send_request' | translate }}
                    </button>
                </div>
                <div *ngIf="friend.invited === 'requested'">
                    <button matRipple class="set-friend-button decline">
                        {{ 'friend_bar.requested' | translate }}
                    </button>
                </div>
                <div *ngIf="friend.invited === 'blocked'">
                    <button matRipple class="set-friend-button decline">{{ 'friend_bar.blocked' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
