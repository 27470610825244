<div class="w-full my-3 p-3 roadmap-wrap main-card">
    <span class="card-info"
        >{{ 'communications.calendar.today' | translate }} {{ this.range.from.format('DD')
        }}{{ 'communications.calendar.of' | translate }} {{ this.range.to.format('MMMM') }}
    </span>
    <button matRipple class="add-item-btn"><i class="ph-plus-bold"></i></button>

    <div class="w-full main-card-wrap-header">
        <div>
            <h5 class="main-card-title">
                {{ this.range.to.format('MMMM') }} {{ this.range.from.format('DD') }} -
                {{ this.range.to.format('DD') }}, {{ this.range.to.format('YYYY') }}
            </h5>
        </div>
        <div class="main-card-wrap-filters flex">
            <span class="main-card-wrap-filter"
                ><span class="card-info" style="font-weight: 600">{{ 'communications.calendar.show' | translate }}</span
                >:<span class="ml-1 cursor-pointer">{{ 'communications.calendar.all_teams' | translate }}</span>
                <i class="ph-caret-down"></i>
            </span>
            <span class="main-card-wrap-filter"
                ><span class="card-info" style="font-weight: 600">{{
                    'communications.calendar.timeframe' | translate
                }}</span
                >:<span class="ml-1 cursor-pointer">{{ 'communications.calendar.all_time' | translate }}</span>
                <i class="ph-caret-down"></i>
            </span>
        </div>
        <div></div>
    </div>

    <ng-container>
        <div class="flex mt-4">
            <div class="">
                <div class="table-responsive">
                    <table class="table table-sm table-bordered table-hover">
                        <thead>
                            <th style="text-align: center"></th>
                            <th *ngFor="let h of hours" style="text-align: center">{{ format(h.begin) }}</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let day of days" style="border: 1px solid rgba(237, 242, 255, 0.1)">
                                <td style="text-align: center; min-width: 50px">
                                    <div style="min-height: 20vh">
                                        <div>
                                            {{ day.date.format('ddd') }}
                                        </div>
                                        <div>
                                            {{ day.date.format('DD') }}
                                        </div>
                                    </div>
                                </td>

                                <td
                                    *ngFor="let hour of day.hours"
                                    class="relative day"
                                    (mouseover)="hour.hover = true"
                                    (mouseout)="hour.hover = false"
                                    style="border-right: 1px solid rgba(237, 242, 255, 0.1)"
                                >
                                    <div class="flex mb-1" style="min-width: 10vw">
                                        <!-- <span class="ml-auto mr-1">{{ hour.date.format("DD.MM.") }}</span> -->
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>
</div>
