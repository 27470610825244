<div class="flex mt-5 mb-3 w-full">
    <div class="col-10 flex">
        <div class="st-title">
            <h3>{{ 'communications.personal_info.challenges' | translate }}</h3>
            <span>{{ 'communications.personal_info.update' | translate }}</span>
        </div>
    </div>
</div>

<div class="flex mt-5 mb-3 w-full">
    <div class="col-12 flex" style="gap: 20px">
        <ng-container *ngFor="let card of challenges">
            <div class="com-info-card cursor-pointer game-card p-3">
                <div class="footer">
                    <div class="mb-4 st-title">
                        <h6 style="font-size: 0.7em">{{ card.name }}</h6>
                        <span style="font-size: 0.7em; opacity: 0.35">{{ card.type }}</span>
                    </div>

                    <div class="text-center my-1" style="font-size: 0.7em; opacity: 0.8">
                        <div class="flex justify-center">
                            <div style="transform: translateX(4px)">
                                <ng-container *ngFor="let user of card.users">
                                    <img class="author" [src]="user.image_path" alt="" />
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4">
                        <div class="flex justify-between" style="font-size: 0.7em; opacity: 0.8">
                            <span>{{ 'communications.personal_info.progress' | translate }}</span>
                            <span>{{ card.progress }}%</span>
                        </div>

                        <div class="mb-2">
                            <div class="progress">
                                <span class="progress-bar" [style.width.%]="card.progress"></span>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-between" style="font-size: 0.7em; opacity: 0.8">
                        <span style="opacity: 0.35"> <i class="fas fa-users"></i> {{ card.contributors }} </span>
                        <span style="opacity: 0.35"> <i class="fas fa-check"></i> {{ card.approved }} </span>
                        <span>
                            <span class="tag gray"><i class="fas fa-clock"></i> {{ card.time_left }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
