<app-login-card>
    <form class="space-y-4 md:space-y-6 mb-4" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div>
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                New password
            </label>
            <input
                type="password"
                name="password"
                id="password"
                placeholder="{{ 'account.new_password' | translate }}"
                formControlName="password"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
        </div>
        <div>
            <label for="confirm_password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Confirm password
            </label>
            <input
                name="confirm_password"
                placeholder="{{ 'account.confirm_password' | translate }}"
                type="password"
                class="form-control"
                formControlName="confirm_password"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
        </div>
        <div *ngIf="confirmPassword?.errors?.minlength" class="flex w-full justify-start text-sm text-red-500">
            {{ 'account.password_lenght_error' | translate }}
        </div>
        <div
            *ngIf="!confirmPassword?.errors?.minlength && !isValid && confirmPassword?.value"
            class="flex w-full justify-start text-sm text-red-500"
        >
            {{ 'account.password_match' | translate }}
        </div>
        <div class="flex items-center justify-center mb-3">
            <a routerLink="/login" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">
                Go back to login?
            </a>
        </div>
        <div>
            <app-un-magic-button
                style="width: 100%"
                (clicked)="onSubmit()"
                [text]="'Submit'"
                [fullWidth]="true"
                [disabled]="!isValid || isLoading"
                [bordered]="true"
                [fullWidth]="true"
            ></app-un-magic-button>
        </div>
    </form>
</app-login-card>
