<div class="board-wrapper">
    <ng-container *ngFor="let tab of tabs">
        <div class="h-full px-3 p-2 my-4" style="border-left: 1px dashed #9f8dd054; flex: 1">
            <div class="flex items-center">
                <span style="color: #9f8dd0">{{ tab.name }}</span>
                <app-un-create-button
                    class="ml-auto"
                    (click)="openDialog(uploadFormTemplate, tab)"
                ></app-un-create-button>
            </div>

            <ng-container *ngIf="!isRedraw">
                <ng-container *ngFor="let user of tab.users; let i = index">
                    <div
                        class="item"
                        id="{{ user.id ? user.id : '' }}"
                        [style.marginTop]="getTopOffset(tab, user, i)"
                        (click)="goToViewPage(tab, user)"
                    >
                        <div class="flex aling-items-center mb-2" style="gap: 8px">
                            <div style="width: 30px">
                                <img src="/assets/blank-profile.png" style="max-width: 100%; border-radius: 50%" />
                            </div>
                            <div class="flex flex-col">
                                <span class="name">{{ user.name }}e</span>
                                <span
                                    *ngIf="tab.type !== '4'"
                                    class="role text-nowrap"
                                    style="font-size: 0.9em; color: #8e91d1"
                                    >{{ user.role }}</span
                                >
                            </div>
                        </div>
                        <div class="task-card-footer mt-auto">
                            <div class="flex justify-between">
                                <div class="flex items-center" style="gap: 4px">
                                    <i class="ph-chat-teardrop-dots" style="color: #ff02d0; font-size: 1.2em"></i>
                                    <span style="color: #edf2ff; font-size: 0.85em">2</span>
                                </div>
                                <div class="flex items-center" style="gap: 4px">
                                    <i class="ph-timer" style="color: #ff02d0; font-size: 1.2em"></i>
                                    <span style="color: #edf2ff; font-size: 0.85em">1 years</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngFor="let slot of tab.slots; let i = index">
                    <div class="item" id="{{ slot.id ? slot.id : '' }}" [style.marginTop]="getTopOffset(tab, slot, i)">
                        <div class="flex aling-items-center mb-2" style="gap: 8px">
                            <div style="width: 30px">
                                <app-un-create-button class="ml-auto"></app-un-create-button>
                            </div>
                            <div class="flex flex-col">
                                <span class="role text-nowrap" style="font-size: 0.9em; color: #8e91d1">{{
                                    'communications.info.add_student_mentor' | translate
                                }}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>

<ng-template #uploadFormTemplate>
    <div mat-dialog-content style="width: 400px">
        <app-table-form
            #uploadModalForm
            [form]="form"
            [formElements]="form.form.elements"
            (onSubmit)="submit($event)"
        ></app-table-form>
    </div>
    <div mat-dialog-actions class="row justify-between">
        <div class="col-auto"></div>
        <div class="col-auto">
            <button mat-flat-button color="primary" class="mr-2" (click)="closeDialog()">
                {{ 'communications.info.cancel' | translate }}
            </button>
            <button mat-flat-button color="accent" (click)="submit(uploadModalForm.getValue())">
                {{ 'communications.info.submit' | translate }}
            </button>
        </div>
    </div>
</ng-template>
