import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SessionService } from 'src/app/services/session.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { MatDialog } from '@angular/material/dialog';
import { NavProgressService } from 'src/app/services/nav-progress.service';
import { DisciplinesService } from 'src/app/services/disciplines.service';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-teacher-questionnaire',
    templateUrl: './teacher-questionnaire.component.html',
    styleUrls: ['./teacher-questionnaire.component.scss'],
})
export class TeacherQuestionnaireComponent implements OnInit {
    @Output() finished: EventEmitter<any> = new EventEmitter();

    isLoading: boolean = false;

    isDisplayStartScreen: boolean = true;

    infoFormGroup: UntypedFormGroup;
    firstFormGroup: UntypedFormGroup;
    secondFormGroup: UntypedFormGroup;
    thirdFormGroup: UntypedFormGroup;

    isEditable = false;

    rate: number = 0;

    disciplines: any[] = ['biology', 'chemistry', 'physics', 'math', 'programming', 'history', 'art', 'literature'];
    disciplinesArr: any[] = [];

    textAreaLimit: number = 300;
    textInputLimit: number = 200;

    constructor(
        public dialog: MatDialog,
        private navProgressService: NavProgressService,
        private router: Router,
        private notificationService: NotificationService,
        private _formBuilder: UntypedFormBuilder,
        private sessionService: SessionService,
        private disciplinesService: DisciplinesService,
        private questionnaireService: QuestionnaireService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        if (this.sessionService.getQuestionnaire()) {
            this.router.navigateByUrl('/home');
        }

        this.getDisciplines();

        this.infoFormGroup = this._formBuilder.group({
            name: ['', Validators.required],
        });

        this.navProgressService.changeRate(this.rate);

        this.firstFormGroup = this._formBuilder.group({
            majorDiscipline: ['', Validators.required],
            disciplineExpertise: ['', Validators.required],
        });

        this.secondFormGroup = this._formBuilder.group({
            biology: ['', Validators.required],
            chemistry: ['', Validators.required],
            physics: ['', Validators.required],
            math: ['', Validators.required],
            programming: ['', Validators.required],
            history: ['', Validators.required],
            art: ['', Validators.required],
            literature: ['', Validators.required],
        });

        this.thirdFormGroup = this._formBuilder.group({
            interestsAndHobbies: ['', Validators.required],
        });

        this.setAnswers();
    }

    setAnswers() {
        this.questionnaireService.get().subscribe((response: any) => {
            if (Object.keys(response.data).length !== 0) {
                const parsedData = response.data;
                for (const key in response.data) {
                    if (Object.prototype.hasOwnProperty.call(parsedData, key)) {
                        const element = parsedData[key];
                        if (this.firstFormGroup.get(key)) {
                            this.firstFormGroup.get(key)?.setValue(element);
                        }
                        if (this.secondFormGroup.get(key)) {
                            this.secondFormGroup.get(key)?.setValue(element);
                        }
                        if (this.thirdFormGroup.get(key)) {
                            this.thirdFormGroup.get(key)?.setValue(element);
                        }
                        if (this.infoFormGroup.get(key)) {
                            this.infoFormGroup.get(key)?.setValue(element);
                        }
                    }
                }
            }
        });
    }

    submit() {
        const formsData = this.getData();
        const formData = new FormData();
        formData.append('data', JSON.stringify(formsData));
        this.saveResult(formData);
    }

    saveResult(formData: any) {
        this.questionnaireService
            .update(
                this.sessionService.getCurrentUser().id,
                formData,
                this.sessionService.getCurrentUser().role.toLowerCase()
            )
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.SUCCESS_SUBMIT_TOASTR);
                    this.finished.emit();
                },
                (error) => {
                    this.notificationService.error(this.ERROR_SUBMIT_TOASTR);
                }
            );
    }

    getData() {
        return Object.assign(
            {},
            this.infoFormGroup.getRawValue(),
            this.firstFormGroup.getRawValue(),
            this.secondFormGroup.getRawValue(),
            this.thirdFormGroup.getRawValue()
        );
    }

    getDisciplines() {
        this.disciplinesService.getAll().subscribe((response: any) => {
            this.disciplinesArr = response.data;
        });
    }

    setDisciplineInterest(discipline: string, grade: number) {
        this.secondFormGroup.get(discipline)?.setValue(grade);
    }

    goBack(stepper: MatStepper) {
        stepper.previous();
    }

    start() {
        this.isDisplayStartScreen = false;
    }

    next(stepper: MatStepper) {
        stepper.next();
    }

    get SUCCESS_SUBMIT_TOASTR() {
        return this.translate.instant('questionnaire.teacher.submit_toastr');
    }

    get ERROR_SUBMIT_TOASTR() {
        return this.translate.instant('questionnaire.teacher.error_toastr');
    }
}
