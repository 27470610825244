import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-un-save-button',
    templateUrl: './un-save-button.component.html',
    styleUrls: ['./un-save-button.component.scss'],
})
export class UnSaveButtonComponent {
    @Input() text: string = '';
    @Input() disabled: boolean | undefined = false;

    @Output() сlicked: EventEmitter<any> = new EventEmitter();

    emitClick() {
        this.сlicked.emit();
    }
}
