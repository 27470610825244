import { Chart } from './Chart';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
HighchartsMore(Highcharts);

export class UserGroupSubjectInterestsChart extends Chart {
    valid: boolean = false;

    build(chartData: any, colors: any) {
        if (chartData === null) {
            this.valid = false;
            return;
        }

        this.options = {
            ...this.options,
            ...{
                colors: colors,
                chart: {
                    polar: true,
                },
                credits: {
                    enabled: false,
                },
                legend: {
                    enabled: false,
                },
                xAxis: {
                    type: 'category',
                    tickInterval: 1,
                    categories: chartData.categories,
                    min: 0,
                    max: chartData.categories?.length || 9,
                    tickmarkPlacement: 'on',
                    lineWidth: 0,
                    labels: {
                        style: {
                            color: '#fff',
                        },
                    },
                },
                yAxis: {
                    gridLineInterpolation: 'polygon',
                    min: 0,
                    max: 5,
                    tickInterval: 1,
                    minorTickInterval: 0.5,
                    showLastLabel: 'true',
                },
                plotOptions: {
                    area: {
                        shadow: true,
                        fillOpacity: 0.4,
                        dataLabels: {
                            enabled: false,
                        },
                    },
                    series: {
                        pointStart: 0,
                        pointInterval: 1,
                        marker: {
                            enabled: false,
                        },
                    },
                    column: {
                        pointPadding: 0,
                        groupPadding: 0,
                    },
                },
                series: this.mapSeries(chartData.seriesData),
            },
        };
    }

    mapSeries(series: any) {
        return series && series.length > 0
            ? series.map((item: any) => ({
                  name: item.name,
                  data: item.data.map((value: string) => parseInt(value)),
                  type: 'area',
                  pointPlacement: 'on',
              }))
            : [];
    }

    get exists(): boolean {
        return this.valid;
    }
}
