import { Component, OnInit, Input, ChangeDetectorRef, OnChanges } from '@angular/core';


@Component({
  selector: 'app-progress-slide',
  templateUrl: './progress-slide.component.html',
  styleUrls: ['./progress-slide.component.scss'],
})
export class ProgressSlideComponent implements OnInit, OnChanges {

  @Input() rate: number = 0;

  isPercents: boolean = false;
  percents: number = 0;


  constructor(private changeDetector: ChangeDetectorRef) { }


  ngOnInit() {
    this.buildIndex();
  }

  ngOnChanges() {
    this.buildIndex();
  }

  buildIndex() {

  }



}
