import { Component } from '@angular/core';

@Component({
    selector: 'app-chart-loader',
    template: ` <div role="status" class="shadow animate-pulse">
        <div class="flex items-baseline mt-4">
            <div class="w-full bg-gray-200 rounded-t-lg h-24 dark:bg-gray-400"></div>
            <div class="w-full h-56 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-24 ms-6 dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-18 dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-24 ms-6 dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-18 dark:bg-gray-400"></div>
            <div class="w-full h-64 ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-18 dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-36 ms-6 dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-24 ms-6 dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-24 ms-6 dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-18 dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-36 ms-6 dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-18 dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-24 ms-6 dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-36 ms-6 dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-48 ms-6 dark:bg-gray-400"></div>
            <div class="w-full bg-gray-200 rounded-t-lg h-18 dark:bg-gray-400"></div>

            <div class="w-full bg-gray-200 rounded-t-lg h-36 ms-6 dark:bg-gray-400"></div>
        </div>
        <span class="sr-only">Loading...</span>
    </div>`,
    styles: [':host { display: block; }'],
})
export class ChartLoaderComponent {}
