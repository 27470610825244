import { animate, state, style, transition, trigger } from '@angular/animations';

const ANIMATION_DURATION = 300;
const ANIMATION_DURATION_FAST = 100;

export const flyInOut = trigger('flyInOut', [
    state('in', style({ opacity: 1, transform: 'translateY(0)' })),
    transition('void => *', [style({ opacity: 0, transform: 'translateY(-100%)' }), animate(ANIMATION_DURATION)]),
    transition('* => void', [animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateY(100%)' }))]),
]);

export const flyleftIn = trigger('flyleftIn', [
    state('in', style({ opacity: 1, transform: 'translateX(0)' })),
    transition('void => *', [style({ opacity: 0, transform: 'translateX(-100%)' }), animate(ANIMATION_DURATION)]),
    transition('* => void', [animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateX(100%)' }))]),
]);

export const flyRigthIn = trigger('flyRigthIn', [
    state('in', style({ opacity: 1, transform: 'translateX(0)' })),
    transition('void => *', [style({ opacity: 0, transform: 'translateX(100%)' }), animate(ANIMATION_DURATION)]),
    transition('* => void', [animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateX(-100%)' }))]),
    transition(':leave', [animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateX(-100%)' }))]),
]);

export const flyBottomIn = trigger('flyBottomIn', [
    state('in', style({ opacity: 1, transform: 'translateY(0)' })),
    transition('void => *', [style({ opacity: 0, transform: 'translateY(100%)' }), animate(ANIMATION_DURATION)]),
    transition('* => void', [animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateY(-100%)' }))]),
]);

export const animateOpacity = trigger('animateOpacity', [
    state('in', style({ opacity: 1 })),
    transition('void => *', [style({ opacity: 0 }), animate(ANIMATION_DURATION)]),
    transition(':leave', [animate(ANIMATION_DURATION, style({ opacity: 0 }))]),
]);

export const animateFastOpacity = trigger('animateOpacity', [
    state('in', style({ opacity: 1 })),
    transition('void => *', [style({ opacity: 0 }), animate(ANIMATION_DURATION_FAST)]),
    transition(':leave', [animate(ANIMATION_DURATION_FAST, style({ opacity: 0 }))]),
]);
