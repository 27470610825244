import { settings } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SessionService } from 'src/app/services/session.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DATE_FORMATS_CUSTOM } from 'src/app/app.component';

@Component({
    selector: 'app-manage-schools',
    templateUrl: './manage-schools.component.html',
    styleUrls: ['./manage-schools.component.css'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS_CUSTOM },
    ],
})
export class ManageSchoolsComponent implements OnInit {
    isLoading: boolean = false;

    constructor(private title: Title, public sessionService: SessionService) {}

    ngOnInit() {
        this.title.setTitle(settings.title + 'School');
        this.fetch();
    }

    fetch() {}
}
