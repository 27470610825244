<!--<div class="flex items-center gap-6">-->
<!--    &lt;!&ndash; Text Span &ndash;&gt;-->
<!--    &lt;!&ndash; This span displays the text passed from Angular and styles it to be uppercase with specific size and color &ndash;&gt;-->
<!--    <span class="text-lg uppercase tracking-widest text-[#E1DEF5] lg:text-base">{{ text }}</span>-->

<!--    &lt;!&ndash; Button Container &ndash;&gt;-->
<!--    &lt;!&ndash; This div acts as a button container with relative positioning for absolute positioning of the icon &ndash;&gt;-->
<!--    <div-->
<!--        (click)="emitClick()"-->
<!--        class="duration-400 ease-[cubic-bezier(0.335, 0.01, 0.03, 1.36)] group relative flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg border-2 border-[#FF02D0] text-white transition-all hover:bg-[#FF02D0]"-->
<!--    >-->
<!--        &lt;!&ndash; Icon &ndash;&gt;-->
<!--        &lt;!&ndash; This icon starts half outside the button on the left and moves fully inside on hover &ndash;&gt;-->
<!--        <i-->
<!--            class="ph-arrow-right-light duration-400 ease-[cubic-bezier(0.335, 0.01, 0.03, 1.36)] absolute left-[-1.25rem] text-4xl transition-transform group-hover:translate-x-[0.9rem] group-hover:text-white"-->
<!--        ></i>-->
<!--    </div>-->
<!--</div>-->
<div class="flex items-center gap-6">
    <!-- Text Span -->
    <!-- This span displays the text passed from Angular and styles it to be uppercase with specific size and color -->
    <span class="text-lg uppercase tracking-widest text-[#E1DEF5] lg:text-base">{{ text }}</span>

    <!-- Button Container -->
    <!-- This div acts as a button container with relative positioning for absolute positioning of the icon -->
    <div
        (click)="emitClick()"
        class="duration-400 ease-[cubic-bezier(0.335, 0.01, 0.03, 1.36)] group relative flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg border-2 border-[#FF02D0] text-white transition-all hover:bg-[#FF02D0]"
    >
        <!-- Icon -->
        <!-- This icon starts half outside the button on the left and moves fully inside on hover -->
        <i
            class="ph-arrow-right-light duration-400 ease-[cubic-bezier(0.335, 0.01, 0.03, 1.36)] absolute left-[-1.25rem] text-4xl transition-transform group-hover:translate-x-[0.9rem] group-hover:text-white rtl:left-auto rtl:right-[-1.25rem] rtl:scale-x-[-1] rtl:group-hover:translate-x-[-0.9rem]"
        ></i>
    </div>
</div>
