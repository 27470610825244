import { Component, Input, OnInit } from '@angular/core';
import { UserSubjectInterestsChart } from '../../../charts/UserSubjectInterestsChart';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '../../../services/home.service';
import { TeamsService } from '../../../services/team.service';
import { ProjectsService } from '../../../services/projects.service';
import { UsersService } from '../../../services/users.service';
import { StudentsService } from '../../../services/students.service';
import { StudentDevelopmentChart } from '../../../charts/StudentDevelopmentChart';

@Component({
    selector: 'app-homepage-dashboard',
    templateUrl: './homepage-dashboard.component.html',
    styleUrls: ['./homepage-dashboard.component.scss'],
})
export class HomepageDashboardComponent implements OnInit {
    @Input() homeData: any;
    @Input() session: any;

    teams: any[] = [];

    isLoading: boolean = true;
    isChartLoading: boolean = false;

    teamChartData: any;
    selectedTeamChart: any = undefined;

    selectedProjectId: number = 0;

    chart = new UserSubjectInterestsChart();
    developmentChart = new StudentDevelopmentChart();
    disciplinesChartData: any;
    skillsChartData: any;
    selectedChart = 'disciplines';

    charts: string[] = ['disciplines', 'skills'];

    exampleTag = {
        border: '#3498db',
        color: '#3498db',
        icon: 'tag',
        label: 'Example Tag',
    };

    constructor(
        private userService: UserService,
        private router: Router,
        private homeService: HomeService,
        private teamsService: TeamsService,
        private projectsService: ProjectsService,
        private route: ActivatedRoute,
        private usersService: UsersService,
        private studentsService: StudentsService,
    ) {}

    fakeUsers = this.userService.getFakeUsers();

    categories = {
        eng: ['Biology', 'Chemistry', 'Physics', 'History', 'Art', 'Literature', 'Tanah', 'Israel culture'],
        heb: ['ביולוגיה', 'כִּימִיָה', 'פיזיקה', 'הִיסטוֹרִיָה', 'אומנות', 'סִפְרוּת', 'תנא', 'תרבות ישראל'],
    };

    chartData = {
        title: 'Skill Breakdown',
        categories: this.categories.eng,
        series: {
            name: 'test',
            data: [2, 3, 4, 3, 4, 4, 5, 3],
        },
    };

    chartGroupData = {
        title: 'Skill Breakdown by Group',
        categories: this.categories.eng,
        series: [
            {
                type: 'area',
                name: 'Carl',
                data: [2, 3, 4, 3, 4, 4, 5, 3],
                pointPlacement: 'on',
            },
            {
                type: 'area',
                name: 'Peter',
                data: [4, 3, 4, 3, 2, 5, 5, 1],
                pointPlacement: 'on',
            },
            {
                type: 'area',
                name: 'Josh',
                data: [4, 3, 2, 3, 1, 4, 2, 3],
                pointPlacement: 'on',
            },
            {
                type: 'area',
                name: 'Mikel',
                data: [1, 3, 2, 3, 5, 2, 5, 1],
                pointPlacement: 'on',
            },
            {
                type: 'area',
                name: 'Keren',
                data: [5, 4, 3, 5, 4, 4, 1, 2],
                pointPlacement: 'on',
            },
            {
                type: 'area',
                name: 'Obiang',
                data: [2, 2, 4, 5, 2, 3, 3, 3],
                pointPlacement: 'on',
            },
            {
                type: 'area',
                name: 'Samuel',
                data: [1, 4, 2, 2, 5, 2, 5, 1],
                pointPlacement: 'on',
            },
            {
                type: 'area',
                name: 'Marek',
                data: [2, 2, 2, 4, 4, 2, 2, 1],
                pointPlacement: 'on',
            },
        ],
    };

    getNewsTypes(): string[] {
        return Object.keys(this.homeData.news);
    }

    getTag(newsType: string): any {
        return {
            border: '#3498db',
            color: '#3498db',
            icon: 'tag',
            label: newsType,
        };
    }
    ngOnInit() {
        console.log(this.homeData);
        console.log(this.session);
        this.fetch();
    }

    // ngOnChanges() {
    //     this.fetchChartData();
    // }

    getCompletionRate(project: any): number {
        return Math.round((project.tasks_done / project.tasks_total) * 100);
    }

    goToTaskPage(taskId: number) {
        this.router.navigateByUrl('/communications/manage-task/' + taskId);
    }

    navigateToCommunications() {
        this.router.navigate(['/communications'], { queryParams: { tab: 'tasks' } });
    }

    getSchoolProject() {
        this.projectsService.getSchoolProjects().subscribe(({ data }: any) => {
            // Select the last project ID
            this.selectedProjectId = data[data.length - 1]?.id;

            // Now that selectedProjectId has a value, get the teams
            if (this.selectedProjectId) {
                this.getTeamsByProjectId(this.selectedProjectId);
            } else {
                console.log('No projects found.');
            }
        });
    }

    getTeamsByProjectId(projectId: number) {
        this.teamsService.getTeamsByProjectId(projectId).subscribe(({ data }: any) => {
            this.teams = data.teams;
        });
    }

    fetch() {
        this.isLoading = true;
        this.getSchoolProject();

        this.studentsService.getById(this.session.id).subscribe(({ data }: any) => {
            this.disciplinesChartData = data?.chart.disciplines;
            this.skillsChartData = data?.chart.skills;
            this.chart.build(data.chart.disciplines);
            this.developmentChart.build([1, 2, 3, 5, 5]);
            this.isLoading = false;
        });
    }

    //user charts

    selectChart(chart: string) {
        this.selectedChart = chart;
    }

    //team charts

    fetchChartData() {
        this.teamChartData = undefined;
        this.teamsService.getTeamsByProjectId(this.selectedProjectId).subscribe(({ data }: any) => {
            this.teamChartData = data.chart;
            this.isLoading = false;
            this.isChartLoading = false;

            this.getChartData(data.teams[0]);
        });
    }

    getChartData(team: any) {
        this.selectedTeamChart = team;
        this.teamChartData = undefined;
        this.teamsService.getTeamChart(team.id).subscribe(({ data }: any) => {
            this.teamChartData = data;
        });
    }

    getAllChartsData() {
        this.isChartLoading = true;
        this.selectedTeamChart = undefined;
        this.fetchChartData();
    }
}
