import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tab-image',
    template: `
        <div [hidden]="!active" class="rounded-b-lg rounded-r-lg bg-custom-gradient px-2 py-3">
            <ng-content></ng-content>
        </div>
    `,
})
export class TabImageComponent {
    @Input('tabTitle') title: string;
    @Input() active = false;
    @Input() selector = '';
}
