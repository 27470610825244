<div *ngIf="filters.length > 0" class="flex gap-3">
    <ng-container *ngFor="let filter of filters">
        <ng-container *ngIf="isFilterVisible(filter)">
            <app-checkbox-input
                *ngIf="filter.type === 'checkbox'"
                [class]="filter.class"
                [(ngModel)]="filter.selected"
                [name]="filter.label"
                (ngModelChange)="filtersChangedEmit()"
            >
            </app-checkbox-input>

            <!-- Options -->

            <app-select
                *ngIf="filter.type === 'options' || filter.type === '' || !filter.type"
                class="min-w-[140px]"
                [class]="filter.class"
                [placeholder]="filter.label"
                [options]="filter.options"
                [optionValueSelector]="filter.optionValueSelector"
                [optionTextSelector]="filter.optionTextSelector"
                [optionEmpty]="filter.optionEmpty"
                [optionEmptyValue]="filter.optionEmptyValue"
                [optionEmptyText]="filter.optionEmptyText"
                [(ngModel)]="filter.selected"
                (ngModelChange)="filtersChangedEmit()"
            >
            </app-select>
        </ng-container>
    </ng-container>
</div>
