<div class="flex flex-col justify-between gap-4 pt-6 md:flex-row">
    <div class="w-full text-start md:w-1/2 ltr:pl-5 rtl:pr-5">
        <h3 class="text-uppercase hi-image relative mb-2 text-3xl text-[#FF02D0]">
            {{ 'questionnaire.student.create_profile_header' | translate }}
        </h3>
        <div class="text-mute mb-4">
            <div class="mb-3 mt-3 text-base">
                <span>{{ 'questionnaire.student.create_profile_desc' | translate }}</span>
            </div>
        </div>
    </div>

    <div class="main-card w-full p-3 md:w-1/2 md:p-5">
        <div class="mb-4">
            <app-user-avatar-selector></app-user-avatar-selector>
        </div>

        <form [formGroup]="infoFormGroup">
            <div class="mb-4">
                <app-text-input
                    class="w-full"
                    [placeholder]="'questionnaire.student.info_form_nickname_placeholder' | translate"
                    formControlName="nickname"
                    maxLength="48"
                >
                </app-text-input>
            </div>

            <div class="mb-4">
                <app-select
                    [label]="'questionnaire.student.info_form_class_placeholder' | translate"
                    [options]="grades"
                    formControlName="grade"
                    optionValueSelector="value"
                    optionTextSelector="name"
                ></app-select>
            </div>

            <div class="mb-4">
                <app-date-input [label]="'account.birthday' | translate" formControlName="birthday"> </app-date-input>
            </div>

            <div class="mb-4">
                <mat-radio-group formControlName="gender" class="flex gap-x-3">
                    <mat-radio-button class="ltr:mr-3 rtl:ml-3" value="male">
                        {{ 'questionnaire.gender.male' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="female">
                        {{ 'questionnaire.gender.female' | translate }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </form>

        <div class="mx-auto flex justify-center">
            <app-un-save-button
                [text]="'questionnaire.student.info_form_next_button' | translate"
                (сlicked)="submitStep()"
                [disabled]="!infoFormGroup.valid"
            ></app-un-save-button>
        </div>
    </div>
</div>
