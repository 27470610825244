<div class="items-center mb-5">
    <div [@flyRigthIn] class="games-grid flex-wrap">
        <ng-container *ngFor="let game of testGames">
            <div class="main-card p-2 h-full game-box cursor-pointer" [routerLink]="'/student-games/' + game.id">
                <div *ngIf="game.id !== selectedGame?.id || !isGameLoaded" class="game-box-row">
                    <div
                        class="image-row"
                        [ngStyle]="{
                            'background-image':
                                'linear-gradient(180deg, rgba(0, 0, 0, 0) 48.96%, rgba(0, 0, 0, 0.75) 100%), url(' +
                                game.image_path +
                                ')'
                        }"
                    >
                        <div class="px-2 pb-2 flex justify-content-start w-full game-info-block" style="gap: 28px">
                            <div class="flex items-center" style="gap: 2px">
                                <i class="ph-git-branch" style="font-size: 1.4em"></i> <span>{{ game.gits }}</span>
                            </div>
                            <div class="flex items-center" style="gap: 4px">
                                <i class="ph-calendar-blank" style="font-size: 1.4em"></i> <span>2022-10-03</span>
                            </div>
                        </div>
                    </div>
                    <div class="text-center p-1">
                        <div class="game-box-row-name">
                            <div class="flex items-center footer" style="gap: 10px">
                                <div class="flex">
                                    <div class="play cursor-pointer">
                                        <app-un-avatar [name]="game.author" [small]="false"></app-un-avatar>
                                    </div>
                                </div>
                                <div class="text-start w-full">
                                    <div class="mb-0">
                                        <span class="name">{{ game.author }}</span>
                                    </div>
                                    <div class="game-box-row-title-wrapper flex items-center w-full">
                                        <span class="game-box-row-title text-large">{{ game.name }}</span>
                                        <span class="game-box-row-title likes ml-auto rtl:ml-0 rtl:mr-auto"
                                            >4 <i class="ph-heart"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div *ngIf="game.id === selectedGame?.id">
                    <div
                        [ngClass]="{
                            'absolute w-full h-full top-0 left-0 z-[9999999] p-3 overflow-hidden bg-[#08051F]':
                                selectedGame && isGameLoaded
                        }"
                    >
                        <app-server-game-container
                            [game]="selectedGame"
                            (gameLoaded)="gameLoad()"
                            (gameClosed)="close()"
                        >
                        </app-server-game-container>
                    </div>
                </div> -->
            </div>
        </ng-container>
    </div>
</div>

<!-- <div
    *ngIf="selectedGame && isGameLoaded"
    class="absolute w-full h-full top-0 left-0 z-[9999999] p-3 overflow-hidden bg-[#08051F]"
>
    <app-server-game-container [game]="selectedGame" (gameLoaded)="gameLoad()" (gameClosed)="close()">
    </app-server-game-container>
</div> -->
