<div class="task-card p-3 main-card">
    <div class="task-card-body">
        <!-- <div *ngIf="displayTags" class="flex mb-3 flex-wrap" style="gap: 8px;">
            <button matRipple class="create-btn"><i class="ph-plus-bold"></i></button>
            <span class="task-chip"><i class="ph-fire-simple"></i>Label</span>
        </div> -->

        <div *ngIf="card.image" class="task-card-image mb-3">
            <img [src]="card.image" />
        </div>

        <div>
            <div class="mb-1">
                <span>{{ card.name }}</span>
            </div>
            <div *ngIf="card.desc">
                <span style="font-size: 0.9em; color: #b6b2d4">{{ card.desc }}</span>
            </div>
        </div>

        <div class="my-4 relative">
            <div class="progress">
                <span
                    class="progress-bar"
                    [style.background]="getColorByValue(card.progress)"
                    [style.width.%]="card.progress"
                ></span>
                <div
                    class="percent-item"
                    [style.background]="getColorByValue(card.progress)"
                    [style.left.%]="card.progress"
                >
                    {{ card.progress }}%
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isFooter" class="task-card-footer">
        <div class="text-center my-1" style="font-size: 0.7em; opacity: 0.8">
            <div class="flex justify-center">
                <div style="transform: translateX(4px)">
                    <ng-container *ngFor="let user of card.users">
                        <img class="author" [src]="user.image_path" alt="" />
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="flex justify-between">
            <div class="flex items-center" style="gap: 4px">
                <i class="ph-users-four" style="color: #ff02d0; font-size: 1.2em"></i>
            </div>
            <div class="flex items-center" style="gap: 4px">
                <i class="ph-chat-teardrop-dots" style="color: #ff02d0; font-size: 1.2em"></i>
                <span style="color: #edf2ff; font-size: 0.85em">{{ card.count }}</span>
            </div>
            <div class="flex items-center" style="gap: 4px">
                <i class="ph-timer" style="color: #ff02d0; font-size: 1.2em"></i>
                <span style="color: #edf2ff; font-size: 0.85em">{{ card.time_left }}</span>
            </div>
        </div>
    </div>
</div>
