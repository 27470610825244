import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../url';

export type PromptStyle = {
    prompt: string;
    name: string;
    image?: string;
};

@Injectable({
    providedIn: 'root',
})
export class Text2imgService {
    styles: PromptStyle[] = [
        {
            prompt: '',
            name: 'default',
        },
        {
            prompt: 'pixel art, blocky, colorful, retro, vibrant, nostalgic',
            name: 'Pixel',
            image: 'pixel.png',
        },
        {
            prompt: 'futuristic, cyberpunk, neon, dark, high contrast, dystopian',
            name: 'Cyberpunk',
            image: 'cyberpunk.png',
        },
        {
            prompt: 'cartoon, exaggerated, whimsical, bold, playful, vibrant',
            name: 'Cartoon',
            image: 'cartoon.png',
        },
        {
            prompt: 'realistic, detailed, photographic, accurate colors, professional quality',
            name: 'Photo',
            image: 'photo.png',
        },
    ];

    constructor(private http: HttpClient) {}

    generate(prompt: string) {
        return this.http.post(new Url('/text2img').path, { prompt: prompt });
    }

    generateDalle(prompt: string) {
        return this.http.post(new Url('/generate-image').path, { prompt: prompt });
    }

    getPropmtStyles() {
        return this.styles;
    }
}
