import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Tools } from '../tools';
import { Url } from '../url';

@Injectable()
export class UserService {
    fakeUsers: any[] = [
        {
            id: 2,
            name: 'Sofi Heart',
            nickname: 'Sofi Heart',
            image: '/assets/rust.jpg',
        },
        {
            id: 2,
            name: 'Sofi Heart',
            nickname: 'Sofi Heart',
            image: '/assets/rust.jpg',
        },
        {
            id: 2,
            name: 'Sofi Heart',
            nickname: 'Sofi Heart',
            image: '/assets/rust.jpg',
        },
    ];

    constructor(private http: HttpClient) {}

    getAll() {
        return this.http.get(environment.apiUrl + '/users');
    }

    addUser(user: any) {
        return this.http.post(environment.apiUrl + '/users', user);
    }

    getUser(userId: number) {
        return this.http.get(environment.apiUrl + '/users/' + userId);
    }

    updateUser(id: number, user: any) {
        return this.http.put(environment.apiUrl + '/users/' + id, user);
    }

    deleteUser(userId: number) {
        return this.http.delete(environment.apiUrl + '/users/' + userId);
    }

    updatePassword(userId: number, password: string, passwordConfirmation: string) {
        return this.http.put(environment.apiUrl + '/users/' + userId + '/password', {
            password: password,
            password_confirmation: passwordConfirmation,
        });
    }

    getPermissions(userId: number) {
        return this.http.get(environment.apiUrl + '/users/' + userId + '/permissions');
    }

    addPermission(userId: number, permissionId: number, level: number) {
        return this.http.post(environment.apiUrl + '/users/' + userId + '/permissions', {
            permission_id: permissionId,
            level: level,
        });
    }

    deletePermission(userId: number, permissionId: number, level: number) {
        return this.http.delete(environment.apiUrl + '/users/' + userId + '/permissions/' + permissionId, {
            params: { level: level.toString() },
        });
    }

    putPermission(userId: number, fromUserId: number) {
        return this.http.put(environment.apiUrl + '/users/' + userId + '/permissions', {
            from_user_id: fromUserId,
        });
    }

    addTeam(userId: number, teamId: number, level: number) {
        return this.http.post(environment.apiUrl + '/users/' + userId + '/teams', {
            team_id: teamId,
            level: level,
        });
    }

    changeTeam(user: any) {
        return this.http.put(environment.apiUrl + '/users/' + user.id + '/teams', user);
    }

    removeTeam(userId: number, teamId: number) {
        return this.http.delete(environment.apiUrl + '/users/' + userId + '/teams/' + teamId);
    }

    updateDetails(userId: number, details: any) {
        return this.http.put(environment.apiUrl + '/users/' + userId + '/details', details);
    }

    //just for prototyping
    getFakeUsers() {
        return this.fakeUsers;
    }

    getTeacherMentorsWithoutGroup() {
        return this.http.get(environment.apiUrl + '/mentors/teacher');
    }

    getStudentMentorsWithoutGroup() {
        return this.http.get(environment.apiUrl + '/mentor/student');
    }

    changePassword(oldPassword: string, newPassword: string) {
        return this.http.post(environment.apiUrl + '/user/change-password', {
            old_password: oldPassword,
            new_password: newPassword,
        });
    }

    resetPasswordByEmail(email: string) {
        return this.http.post(new Url('/check-email').path, { email: email });
    }

    resetPassword(token: string, data: any) {
        const url = new Url('/forgot-password');
        url.addQueryParameter('token', token);

        return this.http.post(url.path, data);
    }
}
