<div class="flex p-2 user-info-row mb-1" [class.checked-user-row]="user.checked" style="gap: 10px">
    <div style="width: 30px">
        <img class="user-img" [src]="user.main_image ? storageUrl + user.main_image : defaultImageUrl" />
    </div>
    <div class="flex flex-col">
        <span class="name" style="font-size: 0.8em">
            {{ user.nickname }}
            <!-- <span *ngIf="user.id === leaderId" class="mentor-badge"> student leader</span> -->
        </span>
        <span class="role text-nowrap" style="font-size: 0.7em; color: #8e91d1">
            <span *ngIf="user.personal_type?.types">{{ user.personal_type?.types }}</span>
            <span *ngIf="user.social_type?.types && user.personal_type?.types">, </span>
            <span *ngIf="user.social_type?.types">{{ user.social_type?.types }}</span>
        </span>
    </div>
    <div class="ml-auto rtl:mr-auto rtl:ml-0 flex items-center" style="gap: 20px">
        <div *ngIf="user.checked" [@flyRigthIn] class="user-checked"><i class="ph-check"></i></div>
        <div class="user-color-stick" [style.backgroundColor]="user.color"></div>
    </div>
</div>
