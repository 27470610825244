<div class="flex flex-col md:flex-row" style="gap: 10px" [class.horizontal]="horizontal">
    <ng-container *ngFor="let option of options">
        <button
            class="custom-radio-option w-full md:w-36"
            [class.active]="selectedOptionValue === option.id"
            (click)="select(option)"
        >
            {{ option.answer_en }}
        </button>
    </ng-container>
</div>
