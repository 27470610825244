import { environment } from 'src/environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionnaireService } from 'src/app/services/questionnaire.service';
import { flyBottomIn, flyInOut, flyleftIn, flyRigthIn } from 'src/app/animations/fly-animations';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-questionnaire-success',
    templateUrl: './questionnaire-success.component.html',
    styleUrls: ['./questionnaire-success.component.scss'],
    animations: [flyInOut, flyBottomIn, flyRigthIn, flyleftIn],
})
export class QuestionnaireSuccessComponent implements OnInit {
    isLoading: boolean = true;

    @Input() user: any;
    @Output() finishedQuestionnaire: EventEmitter<any> = new EventEmitter<any>();

    imagePath: string = '/assets/uni-love.png';

    storageUrl: string = environment.storageUrl;
    isVoteStarted: boolean = false;

    chartData: any;

    ableStartPlatform: boolean = false;

    selectedChart = 'disciplines';
    charts: string[] = ['disciplines', 'skills'];

    disciplinesChartData: any;
    skillsChartData: any;

    ableStartStates = ['start_platform', 'platform'];

    constructor(private questionnaireService: QuestionnaireService, private sessionService: SessionService) {}

    ngOnInit() {
        this.user = this.sessionService.getCurrentUser();
        this.questionnaireService.getDisciplinesInterests().subscribe((response: any) => {
            this.chartData = response.data?.chart.disciplines;
            this.disciplinesChartData = response.data?.chart.disciplines;
            this.skillsChartData = response.data?.chart.skills;

            this.ableStartPlatform = this.ableStartStates.includes(response.data?.state);
            this.sessionService.setCurrentUser({ ...this.user, state: response.data?.state });
            this.isLoading = false;
        });
    }

    finish() {
        this.finishedQuestionnaire.emit();
    }

    selectChart(chart: string) {
        this.selectedChart = chart;
    }
}
