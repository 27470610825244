<div class="flex w-full justify-center mt-3 mb-3">
    <i class="fas fa-chevron-down fa-2x" style="opacity: 0.7"></i>
</div>

<div class="overflow-hidden w-full">
    <div class="" style="min-height: 65vh; overflow-x: hidden">
        <div class="flex mt-5 mb-5">
            <div class="st-title">
                <h3>{{ 'communications.section.project_network' | translate }}</h3>
            </div>
        </div>

        <div class="flex">
            <div class="col-auto mb-4 flex justify-between flex-wrap" style="gap: 25px">
                <ng-container *ngFor="let statisticBlock of statisticBlocks">
                    <div class="network py-3 px-3">
                        <h6>{{ statisticBlock.name }}</h6>
                        <div class="flex items-center" style="gap: 15px">
                            <span class="network-all">{{ statisticBlock.total }}</span>
                            <span class="network-new">+ {{ statisticBlock.last_year }}</span>
                        </div>
                        <div>
                            <span class="network-footer">
                                {{ statisticBlock.info }}
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div *ngIf="!isLoading" class="flex">
            <div class="col-6 network-chart-wraper px-5 py-3">
                <div class="flex items-center justify-between">
                    <div>
                        <mat-form-field>
                            <mat-select [(value)]="viewMode">
                                <mat-select-trigger class="flex items-center">
                                    <mat-icon>view_list</mat-icon>&nbsp;{{ viewName }}
                                </mat-select-trigger>
                                <mat-option *ngFor="let option of viewModes" [value]="option.value">
                                    <mat-icon>view_list</mat-icon>{{ option.viewValue }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <i class="fas fa-times fa-lg mr-3"></i>
                        <i class="fas fa-expand fa-lg mr-3"></i>
                        <i class="fas fa-chevron-left fa-lg"></i>
                    </div>
                </div>

                <highcharts-chart [Highcharts]="chart.highcharts" [options]="chart.options"></highcharts-chart>
            </div>

            <div class="col-6 network-detail px-5 py-3">
                <div class="mb-3 mt-4">
                    <h6>England (1) ...</h6>

                    <div class="mb-4 flex justify-between flex-wrap" style="gap: 15px">
                        <div class="network-detail-info-block py-3 px-3">
                            <div class="flex items-center network-detail-info-block-head">
                                <span><i class="fas fa-university mr-2"></i></span>
                                <span>{{ 'communications.section.institution' | translate }}</span>
                            </div>
                            <div class="text-center">
                                <span class="network-detail-info-block-data" style="font-size: 16px">{{
                                    institution.name
                                }}</span>
                            </div>
                        </div>

                        <div class="network-detail-info-block py-3 px-3">
                            <div class="flex items-center network-detail-info-block-head">
                                <span><i class="fas fa-briefcase mr-2"></i></span>
                                <span>{{ 'communications.section.teachers' | translate }}</span>
                            </div>
                            <div class="text-center">
                                <span class="network-detail-info-block-data">{{ institution.teachers }}</span>
                            </div>
                        </div>

                        <div class="network-detail-info-block py-3 px-3">
                            <div class="flex items-center network-detail-info-block-head">
                                <span><i class="fas fa-wrench mr-2"></i></span>
                                <span>{{ 'communications.section.mentors' | translate }}</span>
                            </div>
                            <div class="text-center">
                                <span class="network-detail-info-block-data">{{ institution.mentors }}</span>
                            </div>
                        </div>

                        <div class="network-detail-info-block py-3 px-3">
                            <div class="flex items-center network-detail-info-block-head">
                                <span><i class="fas fa-user-graduate mr-2"></i></span>
                                <span>{{ 'communications.section.students' | translate }}</span>
                            </div>
                            <div class="text-center">
                                <span class="network-detail-info-block-data">{{ institution.students }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <h4 class="mb-3">{{ 'communications.section.general_coincidences' | translate }}</h4>

                    <div class="flex items-center">
                        <span class="text-large mr-4">{{ 'communications.section.all_projects' | translate }}</span>

                        <mat-form-field>
                            <mat-select [(value)]="viewMode">
                                <mat-select-trigger class="flex items-center">
                                    <mat-icon>view_list</mat-icon>&nbsp;{{ viewName }}
                                </mat-select-trigger>
                                <mat-option *ngFor="let option of viewModes" [value]="option.value">
                                    <mat-icon>view_list</mat-icon>{{ option.viewValue }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <app-table [columns]="table.columns" [data]="projects" [slim]="true" [pagination]="true">
                    </app-table>
                </div>
            </div>
        </div>
    </div>
</div>
