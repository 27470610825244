import { Chart } from './Chart';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
HighchartsMore(Highcharts);

export class UserSubjectInterestsChart extends Chart {
    build(chartData: any) {
        this.options = {
            ...this.options,
            ...{
                colors: ['#FF02D0', '#FF9900', '#00A3FF', '#7B61FF', '#B6A8FF', '#00A3FF', '#FF02D0', '#FF9900'],
                chart: {
                    polar: true,
                },
                credits: {
                    enabled: false,
                },

                legend: {
                    enabled: false,
                },
                xAxis: {
                    type: 'category',
                    tickInterval: 1,
                    categories: chartData.categories,
                    min: 0,
                    max: chartData.categories.length || 9,
                    tickmarkPlacement: 'on',
                    lineWidth: 0,
                    labels: {
                        style: {
                            color: '#fff',
                        },
                    },
                },
                yAxis: {
                    gridLineInterpolation: 'polygon',
                    min: 0,
                    max: 5,
                    tickInterval: 1,
                    minorTickInterval: 0.5,
                    showLastLabel: 'true',
                },
                plotOptions: {
                    area: {
                        shadow: true,
                        fillOpacity: 0.4,
                        dataLabels: {
                            enabled: false,
                        },
                    },
                    series: {
                        pointStart: 0,
                        pointInterval: 1,
                        marker: {
                            enabled: false,
                        },
                    },
                    column: {
                        pointPadding: 0,
                        groupPadding: 0,
                    },
                },
                series: [
                    {
                        type: 'area',
                        name: chartData.seriesData.name,
                        data: chartData.seriesData.data,
                        pointPlacement: 'on',
                    },
                ],
            },
        };
    }
}
