import { Component, Input, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TextDirectionController } from 'src/app/classes/TextDirectionController';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-un-users-group-view',
    templateUrl: './un-users-group-view.component.html',
    styleUrls: ['./un-users-group-view.component.scss'],
})
export class UnUsersGroupViewComponent {
    @Input() users: any[] = [];
    @Input() title: string = '';

    @Input() limit: number = 3;

    storageUrl: string = environment.storageUrl;
    defaultImageUrl: string = '/assets/blank-profile.png';
    public directionController = new TextDirectionController();

    constructor(private dialog: MatDialog) {}

    get previewUsers() {
        return this.users ? this.users.slice(0, this.limit) : [];
    }

    get dir() {
        return this.directionController.textDirection;
    }

    openModal(dialogRef: TemplateRef<any>) {
        this.dialog.open(dialogRef, { autoFocus: false });
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    getTranslate(i: number) {
        return i * 8 * (this.dir === 'ltr' ? -1 : 1);
    }
}
