import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-un-gpt-chat-button',
    templateUrl: './un-gpt-chat-button.component.html',
    styleUrls: ['./un-gpt-chat-button.component.scss'],
    styles: [':host { display: flex; flex: 1 1 auto !important; }'],
})
export class UnGptChatButtonComponent {
    @Input() text: string = 'Uni Chat';
    @Input() size: 'small' | 'big' | 'medium' = 'medium';
    @Input() decoration: string = 'none';

    isChatOpened: boolean = false;
    isChatRolled: boolean = false;

    extended: boolean = false;

    stages: string[] = ['-110', '20', '132'];

    toggleChat() {
        if (this.isChatRolled) {
            this.isChatRolled = false;
            return;
        }
        this.isChatOpened = !this.isChatOpened;
        this.extended = false;
    }

    toggleWidth() {
        this.extended = !this.extended;
    }

    toggleChatToEdge() {
        this.isChatRolled = !this.isChatRolled;
    }
}
