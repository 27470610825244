import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-student-types-table',
    templateUrl: './student-types-table.component.html',
    styleUrls: ['./student-types-table.component.scss'],
    providers: [],
})
export class StudentTypesTableComponent implements OnInit {
    @Input() testName: string = '';
    @Input() students: any[] = [];
    @Input() type: string = '';

    typeCategories: any[] = [];

    ngOnInit() {
        const types = this.mapStudentTypes(this.students, this.type);
        this.typeCategories = [];

        types.forEach((type: any) => {
            const item: any = {
                name: type,
                students: [],
            };

            this.students.forEach((student) => {
                if (student[this.type]?.types === type) {
                    item.students.push(student);
                }
            });

            this.typeCategories.push(item);
        });
    }

    mapStudentTypes(students: any, type: string) {
        return students
            .map((student: any) => student[type]?.types)
            .filter((type: any) => type)
            .filter((v: any, i: number, a: any) => a.indexOf(v) === i);
    }
}
