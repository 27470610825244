import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { animateOpacity } from 'src/app/animations/fly-animations';
import { ActivitiesService } from 'src/app/services/activities.service';
import { Tools } from 'src/app/tools';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],
    animations: [animateOpacity],
})
export class HistoryComponent implements OnInit {
    @Input() taskId: number = 0;

    date: string = moment().format('DD MMM YYYY');

    isLoading: boolean = true;

    activities: any[] = [];

    query: string = '';
    showSearch: boolean = false;

    showRecent: boolean = true;

    selectedImage: any;

    storageUrl: string = environment.storageUrl;
    defaultImageUrl: string = '/assets/blank-profile.png';

    constructor(private activitiesService: ActivitiesService, public dialog: MatDialog) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.isLoading = true;
        this.activitiesService
            .getByTaskId(this.taskId)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe((response: any) => {
                this.activities = response.data.map((item: any) => ({
                    ...item,
                    name: item.activity_type.title,
                    userNickname: item.user.nickname,
                }));
            });
    }

    isDocumentType(type: string) {
        return type === 'added_doc';
    }

    isDeleted(type: string) {
        return type === 'deleted_doc';
    }

    openImageModal(dialogRef: TemplateRef<any>, image: any) {
        this.selectedImage = image;
        this.dialog.open(dialogRef);
    }

    isImageType(type: string): boolean {
        return type === 'image';
    }

    toggleSearch() {
        this.showSearch = !this.showSearch;
    }

    toggleDateFilter() {
        this.showRecent = !this.showRecent;
        this.sortByDate();
    }

    sortByDate() {
        this.activities.sort((a, b) => {
            return this.showRecent
                ? moment(b.datetime).unix() - moment(a.datetime).unix()
                : moment(a.datetime).unix() - moment(b.datetime).unix();
        });
        this.activities = Tools.cloneObject(this.activities);
    }
}
