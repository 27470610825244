import { settings } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SessionService } from 'src/app/services/session.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DATE_FORMATS_CUSTOM } from 'src/app/app.component';
import { Tab, Tabsable } from 'src/app/classes/Tabsable';

@Component({
    selector: 'app-manage-games',
    templateUrl: './manage-games.component.html',
    styleUrls: ['./manage-games.component.css'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS_CUSTOM },
    ],
})
export class ManageGamesComponent extends Tabsable implements OnInit {
    tabs: Tab[] = [
        {
            name: 'Disciplines',
            active: false,
            visible: true,
        },
        {
            name: 'Institutions',
            active: false,
            visible: true,
        },
        {
            name: 'Locations',
            active: false,
            visible: true,
        },
        {
            name: 'Mini-games',
            active: false,
            visible: true,
        },
    ];

    isLoading: boolean = false;

    constructor(private title: Title, public sessionService: SessionService) {
        super();
    }

    ngOnInit() {
        this.title.setTitle(settings.title + 'Games');
        this.fetch();
    }

    fetch() {}
}
