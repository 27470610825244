<div [@flyInOut] class="mb-4">
    <app-un-page-sub-panel
        [title]="'studio.create_image' | translate"
        [backUrl]="'/studio'"
        [onlyBack]="true"
    ></app-un-page-sub-panel>
</div>
<div class="flex flex-col-reverse gap-6 lg:flex-row">
    <div class="mb-6 w-full xl:w-8/12">
        <div
            *ngIf="images.length === 0"
            class="placeholder-image flex h-96 w-full flex-col items-center justify-center gap-4 rounded-lg"
        >
            <i class="ph-bold ph-file-image text-5xl text-[#FF02D0]"></i>
            <span class="text-center text-lg uppercase text-[#9D97C3]">
                {{ 'studio.image_placeholder_text' | translate }}
            </span>
        </div>

        <ng-container *ngIf="images.length > 0">
            <div class="image-container relative col-span-4 h-full rounded-lg" (click)="selectImage('')">
                <img class="h-[32rem] w-full rounded-lg object-contain" [src]="images[0]" alt="" />

                <a
                    (click)="downloadImage($event, images[0], 'image')"
                    class="absolute right-1 top-1 no-underline hover:no-underline"
                    style="inset-inline-end: 8px"
                >
                    <div class="my-1 flex w-max items-center justify-center rounded-md bg-[#FF02D0] p-1 xl:mb-2">
                        <i class="ph-bold ph-download-simple text-base text-[#E1DEF5]"></i>
                    </div>
                </a>
            </div>
        </ng-container>
    </div>
    <div class="flex w-full flex-col gap-4 xl:w-4/12">
        <div class="flex items-center gap-x-4 text-start">
            <span class="text-lg text-[#EDF2FF]">{{ 'studio.what_you_want_to_create' | translate }}</span>
            <app-notificator></app-notificator>
        </div>
        <div class="image-prompt mb-3 min-h-[6rem] w-full rounded-lg p-3">
            <app-textarea
                class="w-full"
                [label]="'studio.promt_placeholder' | translate"
                [formControl]="promtFormControl"
                [isDisabled]="isLoading"
                [rows]="4"
                maxLength="320"
            >
            </app-textarea>
            <div class="relative mt-4 w-full">
                <button
                    matRipple
                    class="text-uppercase font-weight-bold flex w-full items-center justify-center overflow-hidden rounded-lg bg-[#FF02D0] py-3 text-base tracking-widest disabled:pointer-events-none disabled:bg-[#626262]"
                    (click)="generate()"
                    [disabled]="!promtFormControl.valid || isLoading"
                >
                    <ng-container *ngIf="!isLoading"> {{ 'studio.create_image' | translate }} </ng-container>
                    <ng-container *ngIf="isLoading">
                        <div class="flex items-center gap-1">
                            <mat-spinner diameter="24"></mat-spinner>
                            <span *ngIf="timerRunning">about {{ remainingTime }} seconds left </span>
                            <span *ngIf="isLoading && !timerRunning">wait a bit more, pls</span>
                        </div>
                    </ng-container>
                </button>
                <div
                    *ngIf="timerRunning && isLoading"
                    class="absolute top-0 h-full rounded-lg bg-gray-50/25"
                    [style.width.%]="100 - calculateWidthPercentage()"
                ></div>
            </div>
        </div>
    </div>
</div>
