<div class="mb-2 block text-sm font-medium text-gray-500 dark:font-normal dark:text-gray-400" *ngIf="title">
    {{ title }}
</div>

<div class="mb-3 flex items-center gap-2" (keydown)="keyDownFunction($event)">
    <app-un-create-button (click)="addString()"></app-un-create-button>

    <app-text-input class="w-full" [placeholder]="placeholder" [formControl]="newString" [maxLength]="inputLimit">
    </app-text-input>
</div>

<div class="flex flex-col flex-wrap gap-3">
    <table>
        <ng-container *ngFor="let str of strings; let i = index">
            <tr
                class="control-list-item color-[#8E91D1] relative mb-3 flex items-center gap-4 transition duration-300 ease-in-out"
            >
                <td class="word-break break-words p-0 align-middle">
                    <span>{{ str }}</span>
                </td>
                <td class="flex self-center align-middle">
                    <i
                        class="ph-trash control-list-item-delete cursor-pointer hover:text-[#FF02D0]"
                        (click)="removeString(i)"
                    >
                    </i>
                </td>
            </tr>
        </ng-container>
    </table>
</div>
