import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-info-icon',
    template: `
        <i
            class="ph-info-bold interactive mx-2"
            [matTooltip]="text"
            matTooltipPosition="above"
            style="font-size: 1.4em"
        >
        </i>
    `,
    styles: [':host { display: flex; }'],
})
export class UnInfoIconComponent {
    @Input() text: string = '';
}
