<div class="flex justify-center" style="font-size: 0.7em; opacity: 0.8" (click)="openModal(usersModal)">
    <ng-container *ngFor="let user of previewUsers; let i = index">
        <img
            class="user-preview-circle cursor-pointer object-cover"
            [ngStyle]="{ transform: 'translateX(' + getTranslate(i) + 'px)' }"
            [src]="user.main_image ? storageUrl + user.main_image : defaultImageUrl"
        />
    </ng-container>

    <ng-container *ngIf="users && users.length > limit">
        <div class="center count mx-1 flex cursor-pointer items-center justify-center px-2">
            <span>+</span><span>{{ users.length - limit }}</span>
        </div>
    </ng-container>
</div>

<ng-template #usersModal>
    <div mat-dialog-content class="un-scroll" style="height: 44vh; min-height: 400px; min-width: 480px">
        <div *ngIf="title" class="mb-2 flex items-center">
            <span class="font-weight-bold text-uppercase text-large" style="font-size: 14px">
                {{ title }}
            </span>
            <i class="ph-info-bold interactive mx-2" style="font-size: 1.4em"></i>
        </div>

        <ng-container *ngFor="let user of users">
            <app-un-user-row class="students" [user]="user"></app-un-user-row>
        </ng-container>
    </div>
</ng-template>
