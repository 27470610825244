<div *ngIf="!isLoading" class="mt-2">
    <app-table [columns]="table.columns" [data]="results"> </app-table>
</div>

<ng-template #resultBarTemplate let-votes="votes">
    <div style="min-width: 200px">
        <app-progress-bar [rate]="votes" [steps]="[19, 55]"></app-progress-bar>
    </div>
</ng-template>

<ng-template #usersTemplate let-users="users">
    <div class="mx-auto" style="min-width: 100px; width: max-content">
        <app-un-users-group-view [users]="users"></app-un-users-group-view>
    </div>
</ng-template>
