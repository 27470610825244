<div class="flex gray-card-bg squad p-4" style="gap: 15px; flex-wrap: wrap">
    <div class="flex justify-center text-center flex-col teammate-wrap">
        <div class="teammate add flex justify-center items-center mb-1" (click)="openModal(addTeammateModal)">+</div>
        <span class="teammate-title">{{ 'communications.section.add_user' | translate }}</span>
    </div>

    <div
        *ngFor="let teammate of teammates"
        class="flex justify-center text-center flex-col teammate-wrap"
        (click)="selectTeammate(teammate)"
    >
        <div class="teammate flex justify-center items-center mb-1">
            <img [src]="teammate.image" style="width: 100%; height: 100%; border-radius: 99px" />
        </div>
        <span class="teammate-title">{{ teammate.name }}</span>
    </div>

    <div *ngIf="isLoading" class="flex justify-center text-center flex-col">
        <mat-spinner style="zoom: 0.6"></mat-spinner>
        <span class="teammate-title">loading</span>
    </div>

    <div class="see-all poppins-light" (click)="openModal(allTeammatesModal)">
        {{ 'communications.section.see_all' | translate }}<i class="fas fa-long-arrow-alt-right"></i>
    </div>
</div>

<ng-template #addTeammateModal>
    <div mat-dialog-content style="height: 44vh; min-height: 400px; min-width: 320px">
        <div class="flex justify-center row">
            <mat-form-field class="w-full">
                <mat-label>{{ 'users.users' | translate }}</mat-label>
                <mat-select [formControl]="users">
                    <mat-option *ngFor="let user of usersList" [value]="user">{{ user }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions class="row justify-between">
        <div class="col-auto"></div>
        <div class="col-auto">
            <button class="btn btn-primary mr-2" (click)="closeDialog()">
                {{ 'communications.info.cancel' | translate }}
            </button>
            <button class="btn btn-success" type="submit" [disabled]="!users.value" (click)="addTeammate()">
                {{ 'communications.info.submit' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #allTeammatesModal>
    <div mat-dialog-content style="height: 44vh; min-height: 400px">
        <ng-container *ngFor="let user of teammates">
            <div class="grid-container items-center">
                <div class="grid-item position-relative">
                    <div class="position-relative" style="width: 40px; height: 40px">
                        <img class="friend-img" [src]="user?.image" />
                        <!-- <div class="status" [ngClass]="{ 'offline': !user.online, 'online': user.online }"></div> -->
                    </div>
                </div>

                <div class="grid-item font-weight-400" style="font-size: 0.85em">
                    <div class="cursor-pointer">{{ user.name }}</div>
                    <span style="font-size: 0.75em; opacity: 0.7">{{
                        'communications.section.programmer' | translate
                    }}</span>
                </div>

                <div class="grid-item msg font-weight-400 cursor-pointer">
                    <span><i class="fas fa-share-square"></i></span>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
