export const cards = [
    {
        name: 'Creation',
        progress: 91,
        modified_at: '1 days ago',
    },
    {
        name: 'Community',
        progress: 75,
        modified_at: '1 days ago',
    },
    {
        name: 'Programming',
        progress: 40,
        modified_at: '2 hours ago',
    },
    {
        name: 'School Disciplines',
        progress: 55,
        modified_at: '7 mins ago',
    },
];
