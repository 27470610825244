<div class="flex">
    <div class="col-auto ml-auto">
        <button
            class="btn btn-danger ml-auto"
            [routerLink]="['/games']"
            [queryParams]="{ tab: 'games' }"
            style="background-color: #ff02d0"
        >
            <i class="fas fa-long-arrow-alt-left"></i>{{ 'games.back' | translate }}
        </button>
    </div>
</div>

<div class="container my-4" style="height: 100ch">
    <div style="margin: 0px; padding: 0px; overflow: hidden; height: 100%">
        <iframe
            [src]="gameUrl | safe : 'resourceUrl'"
            frameborder="0"
            style="overflow: hidden; height: 100%; width: 100%"
            height="100%"
            width="100%"
        ></iframe>
    </div>
</div>
