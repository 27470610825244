import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { Tools } from 'src/app/tools';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() rate: number = 0;
  @Input() precision: number = 1;
  @Input() steps: any = undefined;
  @Input() actualValue: any;
  @Input() inputColor: string = '';

  constructor() {}

  get displayValue() {
    return this.actualValue ? this.actualValue : this.roundedRate;
  }

  get roundedRate() {
    return this.rate !== null && !isNaN(this.rate) && isFinite(this.rate)
      ? Tools.round(this.rate, this.precision)
      : '';
  }

  get color() {
    if (!this.steps) {
      return 'bg-green';
    }

    if (this.rate <= this.steps[0]) {
      return 'bg-red';
    }
    if (this.rate <= this.steps[1]) {
      return 'bg-yellow';
    }
    return 'bg-green';
  }

  get bgColor() {
    return 'bg-' + this.inputColor;
  }

  get stringEnd() {
    return this.actualValue === undefined ? '%' : '';
  }
}
