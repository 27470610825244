<div *ngIf="active" class="menu-wrap ml-auto">
    <ul class="menu">
        <li class="menu-item flex">
            <!-- <i class="ph-dots-three-vertical ml-auto cursor-pointer" style="color: #FF02D0;"></i> -->
            <ul class="drop-menu">
                <li (click)="itemClick()" class="drop-menu-item px-3 py-3">
                    <span class="flex items-center item-link" style="gap: 16px"><i class="ph-eye"></i>View team</span>
                </li>
                <!-- <li (click)="getChartData(team)" class="drop-menu-item px-3 py-3">
                    <span class="flex items-center item-link" style="gap: 16px;"><i class="ph-chart-bar"></i>Show
                        chart</span>
                <li class="drop-menu-item px-3 py-3">
                    <span class="flex items-center item-link" style="gap: 16px;"><i
                            class="ph-user-switch"></i>Change
                        Teacher Mentor</span>
                </li>
                <li class="drop-menu-item px-3 py-3">
                    <span class="flex items-center item-link" style="gap: 16px;"><i
                            class="ph-user-switch"></i>Change
                        Student Mentor</span>
                </li>
                <li class="drop-menu-item px-3 py-3">
                    <span class="flex items-center item-link" style="gap: 16px;"><i class="ph-pencil"></i>Edit
                        team
                        information</span>
                </li>
                <li class="drop-menu-item px-3 py-3">
                    <span class="flex items-center item-link" style="gap: 16px;"><i class="ph-trash"> </i>Delete
                        team</span>
                </li> -->
            </ul>
        </li>
    </ul>
</div>
