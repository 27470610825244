<div class="mt-4">
    <!-- Header -->
    <div class="mb-4 text-4xl font-bold">
        <app-un-gradient-text [text]="'HI, ' + session.name + '!'" size="big"></app-un-gradient-text>
    </div>

    <!-- Whole Layout -->
    <div class="grid grid-cols-1 gap-4 xl:grid-cols-12">
        <!-- Left Column Sections -->
        <div class="grid grid-cols-1 gap-4 xl:col-span-10 xl:grid-cols-10">
            <!--First Row-->
            <div class="xl:col-span-7">
                <app-card class="h-full" [title]="'PROJECTS / TEAMS'">
                    <div class="grid grid-cols-1 gap-2 2xl:grid-cols-2">
                        <div class="space-y-2">
                            <ng-container *ngFor="let project of homeData.projects">
                                <app-card [paddingX]="false" [paddingY]="false">
                                    <div class="px-3 pb-2">
                                        <div class="mt-3 flex flex-col gap-2">
                                            <span class="line-clamp-1 rtl:text-right">{{ project.title }}</span>
                                            <span class="line-clamp-1 text-[#8E91D1] rtl:text-right">
                                                See what learning & gaming events are on the horizon...
                                            </span>
                                            <div class="flex items-center">
                                                <span class="w-full">
                                                    <app-progress-slider-round
                                                        class=""
                                                        [steps]="[
                                                            project.tasks_done,
                                                            project.tasks_total - project.tasks_done,
                                                        ]"
                                                        [rate]="getCompletionRate(project)"
                                                    ></app-progress-slider-round>
                                                </span>
                                                <span
                                                    ><app-un-proceed-button
                                                        (clicked)="navigateToCommunications()"
                                                    ></app-un-proceed-button
                                                ></span>
                                            </div>
                                        </div>
                                    </div>
                                </app-card>
                            </ng-container>
                        </div>

                        <app-card [paddingX]="false" [paddingY]="false">
                            <div class="px-3 pb-2">
                                <div class="mt-3 flex flex-col gap-2">
                                    <span class="line-clamp-1 rtl:text-right">Teams</span>

                                    <ng-container *ngFor="let team of teams">
                                        <div
                                            *ngIf="!isLoading && teams && teams.length > 0"
                                            class="my-2 flex items-center gap-4"
                                        >
                                            <!-- Use ngFor here to iterate through teams -->

                                            <app-un-avatar
                                                [name]="team.name"
                                                [image]="team.image_path"
                                                extraLarge="true"
                                            ></app-un-avatar>
                                            <div class="flex flex-col gap-2.5">
                                                <span class="line-clamp-1 rtl:text-right">{{ team.name }}</span>
                                                <span class="line-clamp-1 text-[#8E91D1] rtl:text-right">{{
                                                    team.slogan
                                                }}</span>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <span class="line-clamp-1 rtl:text-right">Activity</span>

                                    <!--Avatar with name and activity-->
                                    <ng-container *ngFor="let team of homeData.teams">
                                        <div
                                            class="mt-2 flex cursor-pointer items-center gap-4"
                                            (click)="goToTaskPage(team.id)"
                                        >
                                            <app-un-avatar
                                                [name]="team.nickname"
                                                [image]="team.main_image"
                                                extraLarge="true"
                                            ></app-un-avatar>
                                            <div class="flex flex-col gap-2.5">
                                                <span class="line-clamp-1 rtl:text-right">{{ team.nickname }}</span>
                                                <span class="line-clamp-1 text-[#8E91D1] rtl:text-right">{{
                                                    team.activity
                                                }}</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div></app-card
                        >
                    </div>
                </app-card>
            </div>

            <div class="xl:col-span-3">
                <!-- Tasks / Planner Section -->
                <app-card [title]="'TASKS'" class="h-full">
                    <div class="space-y-2">
                        <ng-container *ngFor="let task of homeData.tasks">
                            <app-card class="w-full shrink-0 cursor-pointer" (click)="goToTaskPage(task.id)">
                                <div class="flex items-end justify-start gap-2">
                                    <app-tag *ngIf="task.tags.length > 0" [tag]="task.tags[0]"></app-tag>
                                    <span class="line-clamp-1" *ngIf="task.tags.length > 1">...</span>
                                    <span class="line-clamp-1 text-[#8E91D1]"> {{ task.created_at | date }} </span>
                                </div>
                                <div class="">
                                    <div class="mt-3 flex flex-col gap-2">
                                        <span class="line-clamp-1 rtl:text-right">{{ task.name }}</span>
                                        <span class="line-clamp-1 text-[#8E91D1] rtl:text-right">{{
                                            task.description | safe: 'stripHtmlTags'
                                        }}</span>
                                    </div>
                                </div>
                            </app-card>
                        </ng-container>
                        <div class="flex items-end justify-end">
                            <div>
                                <app-un-proceed-button
                                    (clicked)="navigateToCommunications()"
                                    text="Proceed"
                                ></app-un-proceed-button>
                            </div>
                        </div>
                    </div>
                </app-card>
            </div>

            <!--Second Row-->
            <!-- Statistics Section -->
            <div class="xl:col-span-10">
                <div class="grid grid-cols-1 gap-2 xl:grid-cols-10">
                    <div class="xl:col-span-4">
                        <app-card class="" [gradientBorder]="true" [title]="'STATISTICS'">
                            <div *ngIf="!isLoading" class="relative flex justify-center self-center">
                                <div class="flex flex-col items-center justify-center">
                                    <app-user-info-chart
                                        *ngIf="selectedChart === 'disciplines'"
                                        [inputChartData]="disciplinesChartData"
                                    ></app-user-info-chart>

                                    <app-user-skills-chart
                                        *ngIf="selectedChart === 'skills'"
                                        [chart]="skillsChartData"
                                    ></app-user-skills-chart>

                                    <ul
                                        class="mt-3 hidden divide-gray-200 rounded-lg text-center text-sm font-medium text-gray-500 shadow dark:divide-gray-700 dark:text-gray-400 sm:flex"
                                    >
                                        <li
                                            *ngFor="let chart of charts; let last = last; let first = first"
                                            class="w-full"
                                            (click)="selectChart(chart)"
                                        >
                                            <span
                                                matRipple
                                                [ngClass]="{
                                                    'bg-[#FF02D0]': selectedChart === chart,
                                                    'bg-slate-50/[.06]': selectedChart !== chart,
                                                    'ltr:rounded-l-lg rtl:rounded-r-lg': first,
                                                    'ltr:rounded-r-lg rtl:rounded-l-lg': last,
                                                }"
                                                class="mat-button mat-button-base flex w-36 cursor-pointer items-center justify-center px-4 py-2 text-xs text-gray-200 transition-colors"
                                            >
                                                {{ 'communications.info.' + chart | translate }}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </app-card>
                    </div>
                    <div class="xl:col-span-6">
                        <app-card class="h-full" [title]="'NEWS WIDGET'">
                            <div class="un-scroll !scroll-my-2 overflow-x-auto pb-2">
                                <div class="flex gap-2 md:grid-cols-2 lg:grid-cols-4">
                                    <ng-container *ngFor="let newsType of getNewsTypes()">
                                        <ng-container *ngFor="let newsItem of homeData.news[newsType]">
                                            <app-card class="h-[350px] w-[300px] shrink-0">
                                                <div class="flex items-center justify-between gap-2">
                                                    <app-tag [tag]="getTag(newsItem.news_type)"></app-tag>
                                                    <span class="line-clamp-1 text-[#8E91D1]">{{
                                                        newsItem.created_at | date
                                                    }}</span>
                                                </div>
                                                <div class="mt-3">
                                                    <img
                                                        *ngIf="newsItem.image_path"
                                                        class="h-[128px] w-full max-w-full rounded-lg object-cover"
                                                        [src]="newsItem.image_path"
                                                        alt=""
                                                    />
                                                    <img
                                                        *ngIf="!newsItem.image_path"
                                                        class="h-[128px] w-full max-w-full rounded-lg object-cover"
                                                        src="https://flowbite.com/docs/images/examples/image-1.jpg"
                                                        alt=""
                                                    />
                                                    <div class="mt-3 flex flex-col gap-2">
                                                        <span class="line-clamp-1 rtl:text-right">{{
                                                            newsItem.title
                                                        }}</span>
                                                        <span class="line-clamp-2 text-[#8E91D1] rtl:text-right">{{
                                                            newsItem.description
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </app-card>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </app-card>
                    </div>
                </div>
            </div>
        </div>

        <!-- Right Column -->
        <div class="flex flex-col xl:col-span-2 xl:w-fit">
            <app-card [title]="'ACHIEVEMENTS'" [gradientBorder]="true" class="flex-grow">
                <div class="py-2">
                    <div class="mt-3 flex flex-col gap-2">
                        <span class="line-clamp-1 rtl:text-right">Focused</span>
                        <span class="line-clamp-2 text-[#8E91D1] rtl:text-right"> Completed all related tasks</span>
                        <div class="flex items-center">
                            <span class="w-full"
                                ><app-progress-slider-round
                                    class=""
                                    [steps]="[19, 55]"
                                    rate="90"
                                ></app-progress-slider-round
                            ></span>
                        </div>
                    </div>
                </div>
                <div class="py-2">
                    <div class="mt-3 flex flex-col gap-2">
                        <span class="line-clamp-1 rtl:text-right">Team Player</span>
                        <span class="line-clamp-2 text-[#8E91D1] rtl:text-right">
                            Participated in not related tasks</span
                        >
                        <div class="flex items-center">
                            <span class="w-full"
                                ><app-progress-slider-round
                                    class=""
                                    [steps]="[19, 55]"
                                    rate="33"
                                ></app-progress-slider-round
                            ></span>
                        </div>
                    </div>
                </div>
                <div class="py-2">
                    <div class="mt-3 flex flex-col gap-2">
                        <span class="line-clamp-1 rtl:text-right">Communicator</span>
                        <span class="line-clamp-2 text-[#8E91D1] rtl:text-right">
                            Messaged in each chat! (such spammer hehe xD)</span
                        >
                        <div class="flex items-center">
                            <span class="w-full"
                                ><app-progress-slider-round
                                    class=""
                                    [steps]="[19, 55]"
                                    rate="15"
                                ></app-progress-slider-round
                            ></span>
                        </div>
                    </div>
                </div>
            </app-card>
        </div>
    </div>
</div>
