import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SubtasksService } from 'src/app/services/subtasks.service';
import { Tools } from 'src/app/tools';
import { TranslateService } from '@ngx-translate/core';

type Subtask = {
    title: string;
    status: string;
    loading?: boolean;
};

@Component({
    selector: 'app-control-list',
    templateUrl: './control-list.component.html',
    styleUrls: ['./control-list.component.scss'],
})
export class ControlListComponent {
    @Input() taskId: number = 0;
    @Input() subtasks: any[] = [];

    @Output() subTaskUpdated: EventEmitter<any> = new EventEmitter();

    newItemName = new UntypedFormControl('');
    inputLimit: number = 200;

    placeHolder: string = this.ADD_CONTROL_LIST;
    loading: boolean = false;

    constructor(
        private subTasksService: SubtasksService,
        public translate: TranslateService,
    ) {}

    pushItem() {
        if (this.newItemName.value.length > 0) {
            this.create({
                title: this.newItemName.value,
                status: 'to_do',
            });

            this.newItemName.setValue('');
        }
    }

    keyDownFunction(event: any) {
        if (event.keyCode === 13) {
            this.pushItem();
        }
    }

    complete(item: any) {
        item.loading = true;
        this.subTasksService.update(item.id, Tools.getFormData({ status: 'completed' })).subscribe((respoonse: any) => {
            this.subTaskUpdated.emit();
        });
    }

    uncomplete(item: any) {
        item.loading = true;
        this.subTasksService.update(item.id, Tools.getFormData({ status: 'to_do' })).subscribe((respoonse: any) => {
            this.subTaskUpdated.emit();
        });
    }

    create(subtask: Subtask) {
        this.subTasksService.create(this.taskId, Tools.getFormData(subtask)).subscribe((respoonse: any) => {
            this.subTaskUpdated.emit();
        });
    }

    delete(itemId: number) {
        this.subTasksService.delete(itemId).subscribe((respoonse: any) => {
            this.subTaskUpdated.emit();
        });
    }

    get ADD_CONTROL_LIST() {
        return this.translate.instant('communications.workspace.add_control_list');
    }
}
