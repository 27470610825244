<!-- <h1 *ngIf="isReady" class="mb-3">{{ game.name }}</h1> -->

<div class="mx-auto grid-system-container-unity aspect-16">
    <ng-container *ngIf="!isReady">
        <div class="loader-wrapper aspect-16">
            <div class="progress" style="width: 400px">
                <div
                    class="progress-bar"
                    style="background-color: #ff02d0"
                    role="progressbar"
                    [style.width.%]="progress * 100"
                    [attr.aria-valuenow]="progress * 100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
        </div>
    </ng-container>

    <div [ngStyle]="{ display: isReady ? 'block' : 'none' }">
        <div #ucontainer id="canvas" class="unity-desktop aspect-16">
            <canvas #ucanvas id="unity-canvas" class="aspect-16"></canvas>
            <div #uloading id="unity-loading-bar">
                <div id="unity-logo"></div>
                <div id="unity-progress-bar-empty">
                    <div #uprogress id="unity-progress-bar-full"></div>
                </div>
            </div>
            <div id="unity-warning"></div>
            <div id="unity-footer">
                <div id="unity-webgl-logo"></div>
                <div #ufullscreen id="unity-fullscreen-button"></div>
            </div>
        </div>
    </div>
</div>
