import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'codeToPre' })
export class CodeToPrePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (typeof value !== 'string') {
            return value;
        }
        // const codeRegex = /`([^`]*)`/g;
        // const codeBlockRegex = /([\s\S]*?)/g;
        // const codeBlockMatches = value.match(codeBlockRegex);
        // console.log(codeBlockMatches);

        // value = value.replace(codeRegex, '<pre>$1</pre>');
        // let regex = /([\s\S]*?)/gm;
        // return value.replace(regex, '$1');

        const codeBlockRegex = /```([\s\S]*?)```/g;
        return value.replace(codeBlockRegex, '<pre>$1</pre>');

        return value;
    }
}
