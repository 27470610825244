import { Component, Input } from '@angular/core';
import { StudentSkillsChart } from 'src/app/charts/StudentSkillsChart';

@Component({
    selector: 'app-user-skills-chart',
    templateUrl: './user-skills-chart.component.html',
    styleUrls: ['./user-skills-chart.component.scss'],
})
export class UserSkillsChartComponent {
    @Input() chart: any;

    colors = ['#FF02D0', '#FF9900', '#00A3FF', '#7B61FF', '#B6A8FF', '#00A3FF', '#FF02D0', '#FF9900'];

    groupChart = new StudentSkillsChart();

    ngOnChanges() {
        const chart = this.chart ? this.chart : { categories: [], seriesData: [] };
        this.groupChart.build(chart, this.colors);
    }
}
