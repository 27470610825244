import { environment } from 'src/environments/environment';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { SessionService } from 'src/app/services/session.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-start-screen',
    templateUrl: './start-screen.component.html',
    styleUrls: ['./start-screen.component.scss'],
    providers: [],
})
export class StartScreenComponent implements OnInit {
    @Output() clickedStart: EventEmitter<any> = new EventEmitter<any>();

    user: any;
    imagePath: string = '/assets/uni-love.png';

    storageUrl: string = environment.storageUrl;

    constructor(
        private userService: UserService,
        private sessionService: SessionService,
        private notificationService: NotificationService
    ) {}

    ngOnInit() {
        this.getUser();
    }

    getUser() {
        this.user = this.sessionService.getCurrentUser();
    }

    start() {
        this.clickedStart.emit();
    }

    get welcomeText() {
        return this.sessionService.getCurrentUserSchool().questionnaire_details?.welcome_text;
    }
}
