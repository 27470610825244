import { settings, environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    providers: [],
})
export class DashboardComponent implements OnInit {
    isLoading: boolean = false;

    constructor(private title: Title, private notificationService: NotificationService) {}

    ngOnInit() {
        this.title.setTitle(settings.title + 'Dashboard');
    }

    go() {
        this.notificationService.success(' created successfully');
    }
}
