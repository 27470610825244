<div class="img-upload-container">
    <input hidden #file type="file" accept="image/*" (change)="preview(file.files)" />

    <div
        *ngIf="imgURL"
        class="img-upload img-preview"
        (click)="file.click()"
        [ngStyle]="{ 'background-image': 'url(' + imgURL + ')' }"
    ></div>

    <div
        *ngIf="!imgURL"
        class="img-upload img-preview mb-3"
        (click)="file.click()"
        style="background-image: url('assets/profile-placeholder.jpg')"
    ></div>

    <div>
        <span class="text-mute cursor-pointer" (click)="file.click()">{{
            'questionnaire.student.info_form_choose_avatar' | translate
        }}</span>
    </div>
</div>
