import { Component, Input } from '@angular/core';

enum ToolTypes {
    Drive = 'drive',
    Chat = 'chat',
    TaskHistory = 'history',
}

@Component({
    selector: 'app-task-tools',
    templateUrl: './task-tools.component.html',
    styleUrls: ['./task-tools.component.scss'],
    providers: [],
})
export class TaskToolsComponent {
    @Input() taskId: number = 0;
    @Input() teamId: number = 0;

    isLoading: boolean = true;

    selectedTool = 'drive';

    public get toolTypes(): typeof ToolTypes {
        return ToolTypes;
    }

    setTool(tool: string) {
        this.selectedTool = tool;
    }
}
