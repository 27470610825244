<div class="user-info-row relative grid grid-cols-12 gap-2 p-2" (click)="openMenu($event, user)">
    <div class="col-span-1 w-7" style="width: 30px">
        <img class="friend-img" [src]="user.main_image ? storageUrl + user.main_image : defaultImageUrl" />
    </div>
    <div class="col-span-10 flex flex-col">
        <span class="name text-start text-sm"
            >{{ user.nickname }}
            <span *ngIf="isLeader" class="mentor-badge">
                {{ 'communications.info.student_leader' | translate }}
            </span>
        </span>

        <div class="col-span-1 mb-1 flex flex-wrap gap-2">
            <ng-container *ngFor="let tag of user.tags">
                <app-tag [tag]="tag"></app-tag>
            </ng-container>
        </div>
    </div>
    <div class="ml-auto rtl:ml-0 rtl:mr-auto">
        <div class="user-color-stick" [style.backgroundColor]="user.color"></div>
    </div>

    <div class="menu-trigger invisible absolute right-2 top-1" [matMenuTriggerFor]="menu"></div>
</div>

<mat-menu #menu="matMenu" backdropClass="customize" style="top: -58px">
    <div mat-menu-item [routerLink]="'/communications/user/' + user.id">
        <div class="flex items-center gap-3">
            <i class="ph-eye"></i>
            <span>{{ 'communications.info.view' | translate }}</span>
        </div>
    </div>
    <div *ngIf="ableChangeTeamLeader()" mat-menu-item (click)="updateTeamLeaderEmit()">
        <div class="flex items-center gap-3">
            <i class="ph-star-bold"></i>
            <span>{{ 'communications.info.set_leader' | translate }}</span>
        </div>
    </div>

    <div *ngIf="ableManageStudents()" mat-menu-item (click)="changeStudentEmit()">
        <div class="flex items-center gap-3">
            <i class="ph-user-switch"></i>
            <span>{{ 'communications.info.switch_to_student' | translate }}</span>
        </div>
    </div>

    <div *ngIf="ableManageStudents()" mat-menu-item (click)="removeFromTeam()">
        <div class="flex items-center gap-3">
            <i class="ph ph-user-minus"></i>
            <span>{{ 'communications.info.delete_team_student' | translate }}</span>
        </div>
    </div>
</mat-menu>
