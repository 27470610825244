import { AfterViewInit, Component } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';

declare let particlesJS: any;

@Component({
    selector: 'app-login-card',
    templateUrl: './login-card.component.html',
    styleUrls: ['./login-card.component.scss'],
})
export class LoginCardComponent implements AfterViewInit {
    constructor(private loginService: LoginService) {}

    ngAfterViewInit() {
        if (!this.loginService.isLoggedIn()) {
            particlesJS.load('particles-js', 'assets/particles.json', function () {});
        }
    }
}
