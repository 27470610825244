<div class="flex w-full justify-center mt-3 mb-3">
    <i class="fas fa-chevron-down fa-2x" style="opacity: 0.7"></i>
</div>

<div class="flex" style="min-height: 65vh">
    <div class="col-8 activity-main">
        <div class="flex mt-5 mb-5 w-full">
            <div class="col-10 flex">
                <div class="st-title">
                    <h3>{{ 'communications.personal_info.latest_activity' | translate }}</h3>
                    <span>Update 10 minutes ago</span>
                </div>
                <div class="ml-auto">
                    <button matRipple class="mr-3 btn btn-primary">
                        <i class="fas fa-cog fa-lg"></i>
                    </button>
                    <button matRipple class="btn btn-danger bg-pink">
                        <i class="fas fa-plus fa-lg"></i>
                    </button>
                </div>
            </div>
        </div>

        <app-com-latest-activity></app-com-latest-activity>

        <app-com-personal-challenges></app-com-personal-challenges>
    </div>

    <div class="col-3">
        <app-com-overall-card></app-com-overall-card>
    </div>
</div>
