<app-un-back-button (сlicked)="goBack()"></app-un-back-button>

<div class="start-screen-wrapper container">
    <div class="flex justify-center mb-5">
        <div class="col-6 text-start">
            <h1 [@flyInOut] class="mb-5" style="width: 80%">{{ theme?.title }}</h1>

            <div class="text-mute mb-4">
                <div class="mb-3" style="width: 80%">
                    <div [@flyleftIn] class="mb-5">{{ theme?.description }}</div>

                    <div class="underline-spacer"></div>
                </div>
            </div>

            <div [@flyInOut]>
                <app-un-save-button
                    [text]="('questionnaire.theme.choose' | translate) + theme?.title"
                    (click)="selectTheme()"
                ></app-un-save-button>
            </div>
        </div>

        <div class="col-6 flex justify-center items-center px-5 hi-image">
            <img
                [@flyRigthIn]
                class="theme-image"
                [src]="theme.image ? storageUrl + theme.image : '/assets/img.png'"
                alt=""
            />
        </div>
    </div>
</div>
