<button mat-button color="accent" (click)="openModal(usersModal)" [disabled]="loading">
    <i *ngIf="loading" class="fas fa-spinner fa-spin"></i>
    <i *ngIf="!loading" class="ph-envelope-simple text-large"></i>
</button>

<ng-template #usersModal>
    <div class="un-scroll" mat-dialog-content style="height: 44vh; min-height: 400px; min-width: 380px">
        <div class="flex mb-2 items-center">
            <span class="font-weight-bold text-uppercase text-large" style="font-size: 14px"> select users </span>
            <i class="ph-info-bold interactive mx-2" style="font-size: 1.4em"></i>
        </div>
        <ng-container *ngFor="let user of users">
            <app-un-user-row class="students" [user]="user" (click)="selectStudent(user)"></app-un-user-row>
        </ng-container>
    </div>

    <div mat-dialog-actions class="row justify-between">
        <div class="col-auto">
            <button mat-button color="accent" (click)="toggleUsers()">
                <i *ngIf="selectedAll" class="ph-check-square text-large"></i>
                <i *ngIf="!selectedAll" class="ph-square text-large"></i>
                <span class="mx-2 toggle-all">toggle all</span>
            </button>
        </div>
        <div class="col-auto" style="zoom: 0.7">
            <app-un-save-button
                text="send"
                (click)="submit()"
                [disabled]="assignedUsers.length === 0"
            ></app-un-save-button>
        </div>
    </div>
</ng-template>
