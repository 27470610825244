import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileValidators } from 'ngx-file-drag-drop';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-un-file-upload',
    template: `<div class="w-full">
        <ngx-file-drag-drop
            [formControl]="fileControl"
            activeBorderColor="#FF02D0"
            style="width: 100%"
            [emptyPlaceholder]="'communications.workspace.file_input_text' | translate"
            required
        ></ngx-file-drag-drop>

        <ng-container *ngIf="allowedExtensions.length > 0">
            <div class="flex gap-1 text-start text-xs text-gray-500">
                <div>{{ 'ui.drive.allowed_file_types' | translate }}:</div>
                <span *ngFor="let extension of allowedExtensions; let last = last">
                    {{ extension }}<ng-container *ngIf="!last">, </ng-container>
                </span>
            </div>
        </ng-container>

        <div *ngIf="showPreview && imagePreviewUrl" class="mt-4">
            <img
                [src]="imagePreviewUrl"
                alt="Image Preview"
                class="mx-auto h-auto w-full max-w-xs rounded border border-gray-300"
            />
        </div>
    </div> `,
    styleUrls: ['./un-file-upload.component.scss'],
})
export class UnFileUploadComponent implements OnInit {
    @Input() allowedExtensions: string[] = [];
    @Input() showPreview: boolean = false;

    extensions: string[] = [
        'pdf',
        'pptx',
        'ppt',
        'txt',
        'doc',
        'docx',
        'png',
        'jpeg',
        'jpg',
        'gif',
        'csv',
        'xls',
        'xlsx',
    ];

    errors: any[] = [];
    imagePreviewUrl: string | null = null;

    private fileValidators = [FileValidators.required, FileValidators.maxFileCount(1)];

    fileControl = new UntypedFormControl([]);

    @Output() fileChanged: EventEmitter<any> = new EventEmitter();

    ngOnInit(): void {
        if (this.allowedExtensions.length > 0) {
            this.fileControl.setValidators(
                this.fileValidators.concat(FileValidators.fileExtension(this.allowedExtensions)),
            );
        } else {
            this.fileControl.setValidators(this.fileValidators);
        }

        this.fileControl.valueChanges.subscribe((files) => {
            const file = files && files[0] ? files[0] : null;
            if (file && this.showPreview) {
                this.previewImage(file);
            } else {
                this.imagePreviewUrl = null;
            }

            this.fileChanged.emit({
                file: this.fileControl.value as File,
                valid: this.fileControl.valid,
            });
        });
    }

    previewImage(file: File): void {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            this.imagePreviewUrl = e.target.result;
        };
        reader.readAsDataURL(file);
    }
}
