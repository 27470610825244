import { Validators } from '@angular/forms';
import { Form } from './Form';

export class CreateInfoBlockForm extends Form {
    elements = [];
    title: () => '';

    buildForm(users: any, column: any): void {
        this.form = {
            title: (): string => 'Upload document',
            elements: [
                {
                    name: 'Parent',
                    selector: 'parent',
                    type: 'select',
                    options: users,
                    optionValueSelector: 'id',
                    optionTextSelector: 'name',
                    validators: [Validators.required],
                },
                {
                    name: 'Column',
                    selector: 'column',
                    type: 'text',
                    value: column,
                    visible: false,
                },
            ],
        };
    }
}
