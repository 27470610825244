<div class="start-screen-wrapper px-4 md:px-0">
    <div class="mb-5 flex flex-col justify-center gap-4 md:flex-row">
        <div class="w-full text-start md:w-1/4">
            <h1 class="hi-image relative mb-0">{{ 'questionnaire.student.greeting_header_part_one' | translate }}</h1>
            <h1>{{ 'questionnaire.student.greeting_header_part_two' | translate }}!</h1>
        </div>
        <div
            *ngIf="welcomeText"
            class="content w-full text-start md:w-1/2"
            [innerHTML]="welcomeText | safe: 'html'"
        ></div>
        <div *ngIf="!welcomeText" class="content w-full text-start md:w-1/2">
            <h3 class="text-uppercase mb-2 text-lg">
                {{ 'questionnaire.student.greeting_desc_header_part_one' | translate }}
                <span class="text-pink">
                    {{ 'questionnaire.student.greeting_desc_header_part_two' | translate }}
                </span>
            </h3>
            <div class="text-mute mb-4">
                <div class="mb-3">
                    {{ 'questionnaire.student.greeting_desc_part_one' | translate }}
                </div>

                <div class="mb-3">
                    {{ 'questionnaire.student.greeting_desc_part_two' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div class="flex flex-col justify-center gap-4 md:flex-row">
        <div class="w-full md:w-1/4">
            <div class="underline-spacer"></div>
        </div>
        <div class="flex w-full justify-end py-4 md:w-1/2 md:py-0">
            <app-un-save-button
                text="{{ 'questionnaire.student.greeting_button_start' | translate }}"
                (сlicked)="start()"
            ></app-un-save-button>
        </div>
    </div>
</div>
