import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionControlService } from 'src/app/services/question-control.service';

@Component({
    selector: 'app-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styles: [':host { display: block; }'],
})
export class DynamicFormComponent implements OnInit {
    @Input() questions: any = [];
    @Input() isLast: boolean = false;

    form!: UntypedFormGroup;
    payLoad = '';

    @Output() nextClicked: EventEmitter<any> = new EventEmitter();
    @Output() backClicked: EventEmitter<any> = new EventEmitter();

    constructor(private questionControlService: QuestionControlService) {}

    ngOnInit() {
        this.form = this.questionControlService.toFormGroup(this.questions);
    }

    get buttonText(): string {
        return 'questionnaire.student.' + (this.isLast ? 'form_button_submit' : 'info_form_next_button');
    }

    back() {
        this.backClicked.emit();
    }

    next() {
        this.nextClicked.emit(this.form.getRawValue());
    }

    onSubmit() {
        this.payLoad = JSON.stringify(this.form.getRawValue());
    }
}
