import { settings, environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { SessionService } from 'src/app/services/session.service';
import { ActivitiesService } from 'src/app/services/activities.service';

@Component({
    selector: 'app-com-latest-activity',
    templateUrl: './com-latest-activity.component.html',
    styleUrls: ['./com-latest-activity.component.scss'],
    providers: [],
})
export class ComLatestActivityComponent implements OnInit {
    isLoading: boolean = false;

    date: string = moment().format('MMM DD, YYYY');

    ranges: string[] = ['day', 'week', 'month', 'year'];
    selectedRange: string = this.ranges[0];

    dayRange: string[] = ['1 pm', '2 pm', '3 pm', '4 pm', '5 pm', '6 pm', '7 pm', '8 pm'];
    weekRange: string[] = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    monthRange: number[] = [1, 2, 3, 4];
    yearRange: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    timeRange: any[] = this.dayRange;

    selectedType: string = 'community';
    types: string[] = ['general', 'creation', 'community', 'programming', 'disciplines'];

    statistics: any[] = [
        {
            id: 1,
            name: 'Project Name',
        },
        {
            id: 2,
            name: 'Project Aim',
        },
        {
            id: 3,
            name: 'Project Theme',
        },
        {
            id: 4,
            name: 'Activity Game',
        },
    ];

    tasks: any[] = [
        {
            id: 1,
            name: 'Update Game',
        },
        {
            id: 2,
            name: '2. Create character ',
        },
    ];

    overall: any = {
        network: null,
        personal: null,
        school: null,
        team: null,
    };

    constructor(private session: SessionService, private activitiesService: ActivitiesService) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        const user = this.session.getCurrentUser();

        this.activitiesService.getUserActivitiesProgress(user.id).subscribe((response: any) => {
            this.overall = response.data[0];
        });
    }

    selectRange(range: string) {
        this.selectedRange = range;

        if (range === 'day') {
            this.timeRange = this.dayRange;
        }

        if (range === 'week') {
            this.timeRange = this.weekRange;
        }

        if (range === 'month') {
            this.timeRange = this.monthRange;
        }

        if (range === 'year') {
            this.timeRange = this.yearRange;
        }
    }

    selectType(type: string) {
        this.selectedType = type;
    }

    getLineHeight(index: number): string {
        const move = (index + 1) * 70;
        return `calc(100% - ${move}px)`;
    }

    getTopMove(index: number): string {
        return index.toString() + 'px';
    }

    getFirstBlockHeight() {
        const diff = this.timeRange.length - 3;
        const multi = diff * 7;
        const newHeight = 30 + multi;

        return newHeight + 'vh';
    }

    getZoom() {
        return 1 - (this.timeRange.length - 4) * 0.0625;
    }
}
