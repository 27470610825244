import { environment, settings } from 'src/environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectPreview } from 'src/app/services/studio.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-project-item',
    templateUrl: './project-item.component.html',
    styleUrls: ['./project-item.component.scss'],
    providers: [],
})
export class ProjectItemComponent implements OnInit {
    isLoading: boolean = false;

    @Input() create: boolean = false;
    @Input() project: ProjectPreview;
    @Input() image?: boolean = false;

    @Output() clicked: EventEmitter<any> = new EventEmitter();

    storageUrl: string = environment.storageUrl;

    constructor(
        private router: Router,
        public dialog: MatDialog,
    ) {}

    ngOnInit() {}

    showInfo(project: ProjectPreview) {
        this.clicked.emit(project);
    }

    navigateToProject(projectId: number) {
        const projectLink =
            this.project.applicationType?.type === 'creator' ? this.project.link : '/studio/view-project/' + projectId;
        this.router.navigateByUrl(projectLink);
    }

    navigateToOuterLink(link: string) {
        window.open(link, '_blank');
    }

    downloaProject(event: any, url: string | undefined) {
        event.stopPropagation();
        window.open(url, '_blank');
    }
}
