
import { Component, OnDestroy, OnInit, HostListener, Renderer2, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/services/session.service';
import { LoginService } from 'src/app/services/login.service';
import { Title } from '@angular/platform-browser';
import { fromEvent, Subscription } from 'rxjs';
import { params } from './particles-params';
import { hubItems } from './HubItems';
import { Tools } from 'src/app/tools';
import { MenuFactory } from 'src/app/factories/menu.factory';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit,  OnDestroy {

  isManager: boolean = false;
  isManagerMenuOpened: boolean = true;

  @Output() viewChanged = new EventEmitter<boolean>();

  userName: string = '';
  userId: number = 0;

  teams: any = {
    primary: [],
    secondary: []
  };
  activeTeam: any = {};

  players: any;
  playersByPosition: any;
  viewPlayers: boolean = false;
  playerActive: number = 0;
  playerKeyboardSubscription: boolean = false;
  playerKeyboardAnimating: boolean = false; // maybe we can add animations later
  playerAvoidNavigationOn: any = ['input', 'select', 'textarea', 'mat-select'];


  subscriptions: Subscription[] = [];

  hubMenuItems: any[] = hubItems;
  scrollBackground: boolean = false;
  settingsTheme: boolean = true;
  menuFactory = new MenuFactory(this.session);
  headerItems: any[] = this.menuFactory.make();






  constructor(
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private router: Router,
    private title: Title,
    public session: SessionService,
    public loginService: LoginService,
  ) { }

  /**
   * on component init
   */
  ngOnInit() {

    this.init();

  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    Tools.unsubscribeAll(this.subscriptions);
  }

  /**
   * init
   */
  init() {
    // user
    // const user = this.session.getCurrentUser();
    // this.userName = user.name;
    // this.userId = user.id;
    // this.teams.primary = user.primary_teams;
    // this.teams.secondary = user.secondary_teams;

    // if (!this.session.getTeam()) {
    //   this.session.setTeam(user.team);
    // }
    // this.activeTeam = this.session.getTeam();
  }





  /**
   * log out
   */
  logOut() {
    this.loginService.logOut().subscribe(() => {
       this.router.navigateByUrl('/login');
    });
  }


  navigateMenu(){
    this.router.navigate(['/my-account']);
  }








}
