import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
declare let particlesJS: any;

@Component({
    selector: 'app-sign-up-wrapper',
    templateUrl: './sign-up-wrapper.component.html',
    styleUrls: ['./sign-up-wrapper.component.scss'],
})
export class SignUpWrapperComponent implements OnInit, AfterViewInit {
    isLoading: boolean = false;
    hasError: boolean = false;
    htmlTag: any;
    version = '';
    returnUrl: string = '';

    constructor(private notificationService: NotificationService) {}

    ngOnInit() {}

    ngAfterViewInit() {
        particlesJS.load('particles-js', 'assets/particles.json', function () {});
    }

    onSubmit() {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;

        this.register();
    }

    register() {
        this.notificationService.success('User registered successfully.');
    }
}
