export const colors: any[] = [
    {
        name: 'personal',
        start: '#00A3FF',
        end: '#00A3FF'
    },
    {
        name: 'team',
        start: '#fea331',
        end: '#fbc077'
    },
    {
        name: 'school',
        start: '#FF02D0',
        end: '#FF02D0'
    },
    {
        name: 'network',
        start: '#7B61FF',
        end: '#7B61FF'
    },
    {
        name: 'overal',
        start: '#00A3FF',
        end: '#7B61FF'
    }
];