import { environment } from 'src/environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocationsService } from 'src/app/services/locations.service';
import { VotesTopicsService } from 'src/app/services/votes-topics.service';
import { style, trigger, state, transition, animate } from '@angular/animations';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

const ANIMATION_DURATION = 400;

@Component({
    selector: 'app-theme-view',
    templateUrl: './theme-view.component.html',
    styleUrls: ['./theme-view.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('in', style({ opacity: 1, transform: 'translateY(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateY(-100%)' }),
                animate(ANIMATION_DURATION),
            ]),
            transition('* => void', [
                animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateY(100%)' })),
            ]),
        ]),
        trigger('flyleftIn', [
            state('in', style({ opacity: 1, transform: 'translateX(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateX(-100%)' }),
                animate(ANIMATION_DURATION),
            ]),
            transition('* => void', [
                animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateX(100%)' })),
            ]),
        ]),
        trigger('flyRigthIn', [
            state('in', style({ opacity: 1, transform: 'translateX(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateX(100%)' }),
                animate(ANIMATION_DURATION),
            ]),
            transition('* => void', [
                animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateX(-100%)' })),
            ]),
        ]),
    ],
})
export class ThemeViewComponent implements OnInit {
    @Input() theme: any;

    @Output() backTrigerred: EventEmitter<any> = new EventEmitter<any>();
    @Output() themeSelected: EventEmitter<any> = new EventEmitter<any>();

    storageUrl: string = environment.storageUrl;

    constructor(private notificationService: NotificationService, public translate: TranslateService) {}

    ngOnInit() {
        // console.log(this.theme)
    }

    selectTheme() {
        this.notificationService.success(this.VOTE_SUBMIT_TOASTR);
        this.themeSelected.emit(this.theme);
    }

    goBack() {
        this.backTrigerred.emit();
    }

    get VOTE_SUBMIT_TOASTR() {
        return this.translate.instant('questionnaire.theme.vote_toastr');
    }
}
