<div class="flex-wrapper">

  <div class="radial-chart">
    <svg viewBox="0 0 36 36" class="circular-chart green mx-auto">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" [attr.stop-color]="selectedGradient.start" />
          <stop offset="100%" [attr.stop-color]="selectedGradient.end" />
        </linearGradient>
        <filter id="glow">
          <!-- <feGaussianBlur stdDeviation="2.15 result="coloredBlur"></feGaussianBlur> -->
          <feGaussianBlur in="SourceGraphic" stdDeviation="2.5" result="coloredBlur" />
          <feMerge>
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>

      <path class="circle-bg" [ngStyle]="{ 'opacity' : type === 'overal' ? '1' : '0.35'}" [attr.stroke]="type === 'overal' ? '#979797' : selectedGradient.start"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" />

      <ng-container>
        <path transform="scale(-1,1)" class="circle" [attr.stroke]="selectedGradient.start" [attr.stroke-linecap]="type === 'overal' ? 'round' : ''" [attr.stroke-dasharray]="dasharray"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831" />
        <text x="19" y="20.35" class="percentage" [attr.fill]="type === 'overal' ? '#fff' : selectedGradient.start" [attr.font-weight]="type === 'overal' ? '600' : '400'">{{ index }}%</text>
      </ng-container>

    </svg>
  </div>


</div>