import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberAnimation',
})
export class NumberAnimationPipe implements PipeTransform {
    private previousValue: number = null;
    private animationInProgress = false;

    transform(value: number): any {
        if (value !== this.previousValue) {
            if (this.animationInProgress) {
                // Animation in progress, return the previous value
                return this.previousValue;
            } else {
                // Start the animation
                this.animateNumber(value);
            }
        }

        return value;
    }

    private animateNumber(newValue: number): void {
        const duration = 1000; // Animation duration in milliseconds
        const fps = 60; // Frames per second
        const frameDuration = 1000 / fps;
        const frames = Math.ceil(duration / frameDuration);
        const increment = (newValue - this.previousValue) / frames;

        let current = this.previousValue;
        let frame = 0;

        const animate = () => {
            current += increment;
            frame++;

            if (frame < frames) {
                setTimeout(animate, frameDuration);
            } else {
                current = newValue;
                this.animationInProgress = false;
            }
        };

        this.animationInProgress = true;
        this.previousValue = newValue;

        setTimeout(animate, frameDuration);
    }
}
