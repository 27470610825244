<div class="p-4 mt-5 overall-card">
    <div class="st-title mb-4">
        <h3>{{ 'communications.overvall_card.progress' | translate }}</h3>
        <span>{{ 'communications.overvall_card.all_system' | translate }}</span>
    </div>

    <div class="mb-4 col-4 mx-auto">
        <app-index-radial [inputIndex]="78" type="overal"></app-index-radial>
    </div>

    <div class="st-title mb-4">
        <h3>{{ 'communications.overvall_card.recent_activities' | translate }}</h3>
        <span>{{ date }}</span>
    </div>

    <ng-container *ngFor="let activity of activities">
        <div class="grid-container items-center">
            <div class="grid-item"><img class="author" [src]="activity.user.image_path" alt="" /></div>

            <div class="grid-item" style="font-weight: 400">
                <div>{{ activity.user.name }}</div>
                <span style="font-size: 0.7em; opacity: 0.7">{{ activity.activity }}</span>
            </div>

            <div class="grid-item" style="font-weight: 400">
                <span style="font-size: 0.7em; opacity: 0.7">{{ activity.date }}</span>
            </div>
        </div>
    </ng-container>
</div>
