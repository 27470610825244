import { settings } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SessionService } from 'src/app/services/session.service';
import { Router } from '@angular/router';
import { VotesTopicsService } from 'src/app/services/votes-topics.service';
import { Tools } from 'src/app/tools';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-questionnaire',
    templateUrl: './questionnaire.component.html',
    styleUrls: ['./questionnaire.component.scss'],
    providers: [],
})
export class QuestionnaireComponent implements OnInit {
    isLoading: boolean = false;
    user: any;
    isFinished: boolean = this.sessionService.getQuestionnaire();
    isFirstTry: boolean = false;
    isDisplayVotes: boolean = true;
    isDisplayCurrentVote: boolean = false;
    submitTheme: boolean = false;

    STUDENT_ROLE: string = 'Student';
    TEACHER_ROLE: string = 'Teacher';

    isDisplaySuccessPage: boolean = false;

    isFinishedStates: string[] = ['survey_done', 'start_platform'];

    constructor(
        private router: Router,
        private sessionService: SessionService,
        private votesTopicsService: VotesTopicsService,
        private userService: UserService,
        private title: Title
    ) {}

    ngOnInit() {
        this.title.setTitle(settings.title + 'Questionnaire');
        this.user = this.sessionService.getCurrentUser();
        this.isFirstTry = this.user.questionnaire;
        this.isFinished = this.isFinishedStates.includes(this.user.state);

        // if (this.sessionService.getQuestionnaire()) {
        //     this.router.navigateByUrl('/home');
        // }
    }

    setTheme(theme: any) {
        // this.submitTheme = submitTheme;
        // this.isDisplayCurrentVote = !this.submitTheme;
        this.isDisplayVotes = false;
    }

    voteTheme(theme: any) {
        this.votesTopicsService
            .voteByTheme(Tools.getFormData({ voting_topics_id: theme.id }))
            .subscribe((response: any) => {});
        this.isDisplayCurrentVote = false;
        this.submitTheme = false;
        this.isDisplayVotes = false;
        this.isDisplaySuccessPage = true;
    }

    goBack() {
        this.sessionService.setQuestionnaire(true);
        this.router.navigateByUrl('/profile');
    }

    selectAnotherTheme() {
        this.isDisplayCurrentVote = true;
    }

    finished() {
        this.isFinished = true;

        if (this.user.role === this.TEACHER_ROLE) {
            this.sessionService.setQuestionnaire(true);
            this.router.navigateByUrl('/home');
        }
    }

    gotToPlatform() {
        this.initUser();
    }

    initUser() {
        this.userService.updateUser(this.user.id, { state: 'platform' }).subscribe(() => {
            this.user.inited = true;
            this.sessionService.setCurrentUser({ ...this.user, state: 'platform' });
            this.sessionService.setQuestionnaire(true);
            this.sessionService.setQuestionnairePopUp(true);
            this.router.navigateByUrl('/home');
        });
    }
}
