import { Chart } from './Chart';
import { settings } from 'src/environments/environment';


export class SchoolNetworkChart extends Chart {


    build(data: any) {

        this.options = {
            ...this.options, ...{
                colors: this.colors(),
                chart: {
                    type: 'networkgraph',
                    height: '80%'
                },
                tooltip: {
                    enabled: false
                },
                plotOptions: {
                    networkgraph: {
                        keys: ['from', 'to'],
                        layoutAlgorithm: {
                            enableSimulation: true,
                            friction: -0.9
                        },
                        link: {
                            color: '#DADADA',
                            width: 2
                        }
                    }
                },
                series: [
                    {
                        dataLabels: {
                            enabled: true,
                            linkFormat: '',
                            color: 'black',
                        },
                        id: 'lang-tree',
                        data: data,
                        // data: [
                        //     ['School UNIVERSE', 'Russia'],
                        //     ['Russia', 'School 87'],
                        //     ['Russia', 'School 88'],
                        //     ['School UNIVERSE', 'Israel'],
                        //     ['Israel', 'Atid School'],
                        //     ['Israel', 'School 123'],
                        //     ['Israel', 'Jabotinsky School'],
                        //     ['School UNIVERSE', 'England'],
                        //     ['School UNIVERSE', 'Italy'],
                        //     ['Italy', 'School ITL'],
                        //     ['School UNIVERSE', 'The United States'],
                        //     ['The United States', 'School Wolf'],
                        //     ['England', 'School Eng'],
                        // ],
                        nodes: [
                            {
                                id: 'School UNIVERSE',
                                marker: {
                                    radius: 24,
                                },
                                color: '#7B61FF',
                                dataLabels: {
                                    color: '#7B61FF',
                                    borderColor: 'transparent',
                                }
                            },
                            {
                                id: 'Russia',
                                marker: {
                                    radius: 10,
                                    fillColor: '#181C25',
                                    lineWidth: 4,
                                    lineColor: '#7B61FF'

                                },
                                color: '#7B61FF',
                                dataLabels: {
                                    color: '#7B61FF',
                                }
                            },
                            {
                                id: 'Israel',
                                marker: {
                                    radius: 10,
                                    fillColor: '#181C25',
                                    lineWidth: 4,
                                    lineColor: '#7B61FF'
                                },
                                color: '#7B61FF',
                                dataLabels: {
                                    color: '#7B61FF',
                                }
                            },
                            {
                                id: 'Italy',
                                marker: {
                                    radius: 10,
                                    fillColor: '#181C25',
                                    lineWidth: 4,
                                    lineColor: '#7B61FF'
                                },
                                color: '#7B61FF',
                                dataLabels: {
                                    color: '#7B61FF',
                                }
                            },
                            {
                                id: 'The United States',
                                marker: {
                                    radius: 10,
                                    fillColor: '#181C25',
                                    lineWidth: 4,
                                    lineColor: '#7B61FF'
                                },
                                color: '#7B61FF',
                                dataLabels: {
                                    color: '#7B61FF',
                                }
                            },
                            {
                                id: 'England',
                                marker: {
                                    radius: 10,
                                    fillColor: '#181C25',
                                    lineWidth: 4,
                                    lineColor: '#7B61FF'
                                },
                                color: '#7B61FF',
                                dataLabels: {
                                    color: '#7B61FF',
                                }
                            },
                            {
                                id: 'England',
                                marker: {
                                    radius: 10,
                                },
                                color: '#7B61FF',
                                dataLabels: {
                                    color: '#7B61FF',
                                }
                            },
                            {
                                id: 'School Wolf',
                                marker: {
                                    radius: 10,
                                },
                                color: '#FF9900',
                                dataLabels: {
                                    color: '#FF9900',
                                }
                            },
                            {
                                id: 'Atid School',
                                marker: {
                                    radius: 10,
                                },
                                color: '#FF9900',
                                dataLabels: {
                                    color: '#FF9900',
                                }
                            },
                            {
                                id: 'Jabotinsky School',
                                marker: {
                                    radius: 10,
                                },
                                color: '#FF9900',
                                dataLabels: {
                                    color: '#FF9900',
                                }
                            },
                            {
                                id: 'School 123',
                                marker: {
                                    radius: 10,
                                },
                                color: '#83BF4F',
                                dataLabels: {
                                    color: '#83BF4F',
                                }
                            },
                            {
                                id: 'School ITL',
                                marker: {
                                    radius: 10,
                                },
                                color: '#FFD18B',
                                dataLabels: {
                                    color: '#FFD18B',
                                }
                            },
                            {
                                id: 'School 87',
                                marker: {
                                    radius: 10,
                                },
                                color: '#83BF4F',
                                dataLabels: {
                                    color: '#83BF4F',
                                }
                            },
                            {
                                id: 'School 88',
                                marker: {
                                    radius: 10,
                                },
                                color: '#DBFFBB',
                                dataLabels: {
                                    color: '#DBFFBB',
                                }
                            },
                            {
                                id: 'School Eng',
                                marker: {
                                    radius: 10,
                                },
                                color: '#DBFFBB',
                                dataLabels: {
                                    color: '#DBFFBB',
                                }
                            }
                        ]
                    }]
            }
        };

    }


    colors() {
        return settings.theme() === 'light' ?
            ['#FE6763', '#444444', '#69c', '#4FD1C5', '#F6E05E', '#19A188', '#ED8936', '#805AD5', '#ED64A6'] :
            ['#FE6763', '#909090', '#69c', '#4FD1C5', '#F6E05E', '#19A188', '#ED8936', '#805AD5', '#ED64A6'];
    }


}
