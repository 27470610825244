import { settings } from 'src/environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';

enum Level {
  JUNIOR,
  MIDDLE,
  SENIOR,
  PROFESSOR,
}

type LevelTypes = keyof typeof Level;


export interface ProjectPreview {
  name: string;
  image_path: string;
  type: LevelTypes;
  description: string;
}

@Component({
  selector: 'app-team-manage-user',
  templateUrl: './team-manage-user.component.html',
  styleUrls: ['./team-manage-user.component.scss'],
  providers: [],
})
export class TeamManageUserComponent implements OnInit {


  @Input() user: any;
  @Output() closed: EventEmitter<any> = new EventEmitter();

  taskTypes: any[] = [
    {
      name: 'Personal',
      color: 'blue'
    }, {
      name: 'Team',
      color: 'orange'
    }, {
      name: 'School',
      color: 'pink'
    }, {
      name: 'Network',
      color: 'violet'
    }
  ];

  taskName: string = '';


  constructor() { }



  ngOnInit() {
  }

  close() {
    this.closed.emit();
  }


}
