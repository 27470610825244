<div class="flex flex-col gap-24 mx-auto w-full md:flex-row md:w-3/4 mt-5 px-4">
    <div class="w-full md:w-1/2 text-start">
        <h2 [@flyInOut] class="mb-4">{{ 'questionnaire.success.thanks_for_testing' | translate }}</h2>

        <div [@flyleftIn] class="text-mute">
            <span>{{ 'questionnaire.success.description' | translate }}</span>
        </div>

        <div *ngIf="ableStartPlatform" [@flyBottomIn] class="flex justify-content-start mt-5">
            <app-un-save-button
                [text]="'questionnaire.success.start_platform_button' | translate"
                (click)="finish()"
            ></app-un-save-button>
        </div>
    </div>
    <div [@flyRigthIn] class="w-full md:w-1/2 p-3 md:p-5 main-card">
        <div class="flex mb-2 items-center">
            <span class="font-weight-bold text-uppercase text-large">
                {{ 'communications.info.' + selectedChart | translate }}
            </span>
            <i class="ph-info-bold interactive mx-2"></i>
        </div>
        <div *ngIf="!isLoading" class="flex flex-col items-center justify-center">
            <app-user-info-chart
                *ngIf="selectedChart === 'disciplines'"
                [inputChartData]="disciplinesChartData"
            ></app-user-info-chart>

            <app-user-skills-chart *ngIf="selectedChart === 'skills'" [chart]="skillsChartData"></app-user-skills-chart>

            <ul
                class="hidden mt-3 text-sm font-medium text-center text-gray-500 divide-gray-200 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400"
            >
                <li
                    *ngFor="let chart of charts; let last = last; let first = first"
                    class="w-full"
                    (click)="selectChart(chart)"
                >
                    <span
                        matRipple
                        [ngClass]="{
                            'bg-[#FF02D0]': selectedChart === chart,
                            'bg-slate-50/[.06]': selectedChart !== chart,
                            'ltr:rounded-l-lg rtl:rounded-r-lg': first,
                            'ltr:rounded-r-lg rtl:rounded-l-lg': last
                        }"
                        class="flex cursor-pointer justify-center w-36 items-center text-xs text-gray-200 py-2 mat-button mat-button-base px-4 transition-colors"
                    >
                        {{ 'communications.info.' + chart | translate }}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</div>
