import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-team-section',
    templateUrl: './team-section.component.html',
    styleUrls: ['./team-section.component.scss'],
    providers: [],
})
export class TeamSectionComponent implements OnInit {
    isLoading: boolean = false;

    projects: any = [
        {
            icon: 'domain',
            name: 'HULA',
        },
        {
            icon: 'spa',
            name: 'Test project 2',
        },
        {
            icon: 'brightness_5',
            name: 'Test project 3',
        },
    ];

    project = this.projects[0].name;

    card: any = {
        name: 'Creation',
        progress: 91,
        modified_at: '1 days ago',
    };

    opened = false;

    selectedUser: any;

    currentUser = this.sessionService.getCurrentUser();

    constructor(private projectsService: ProjectsService, public sessionService: SessionService) {}

    ngOnInit() {
        // if (this.currentUser.team_id) {
        //   this.projectsService.getByTeam(this.currentUser.team_id).subscribe((repsonse: any) => {
        //   });
        // }
    }

    selectProject(name: string) {
        this.project = name;
    }

    showInfo(user: any) {
        if (this.opened) {
            if (user.id !== this.selectedUser.id) {
                this.opened = false;

                setTimeout(() => {
                    this.showUserSidebar(user);
                }, 500);
            }

            return;
        }

        this.showUserSidebar(user);
    }

    showUserSidebar(event: any) {
        this.opened = true;
        this.selectedUser = event;
    }

    closeInfo() {
        this.opened = false;
        this.selectedUser = undefined;
    }
}
