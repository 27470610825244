import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Tools } from '../tools';
import { Url } from '../url';

@Injectable({
    providedIn: 'root',
})
export class MiniGamesService {
    studentGames = [
        {
            game_url: 'tetris',
            id: 55,
            image_path: '/assets/students-games/history-valley.jpg',
            info: 'Game',
            name: 'History Valley',
            author: 'Yosef',
            likes: 18,
            gits: 41,
            gameUrl: 'GameByYosef/',
        },
        {
            game_url: 'tetris',
            id: 56,
            image_path: '/assets/students-games/math-explorer.jpg',
            info: 'Game',
            name: 'The Math Explorer',
            author: 'Sagi',
            likes: 24,
            gits: 54,
            gameUrl: 'GameBySagi/',
        },
        {
            game_url: 'tetris',
            id: 57,
            image_path: '/assets/students-games/JerusalemStories.png',
            info: 'Game',
            name: 'Jerusalem Stories',
            author: 'Yosef',
            likes: 44,
            gits: 18,
            gameUrl: 'JerusalemStories/',
        },
        {
            game_url: 'tetris',
            id: 59,
            image_path: '/assets/students-games/workshop-2023-international.jpg',
            info: 'Game',
            name: 'Workshop 2023 international',
            author: 'Champions',
            likes: 44,
            gits: 18,
            gameUrl: 'Workshop2023International/',
        },
    ];

    private closeGameSource = new Subject<void>();
    closeGame$ = this.closeGameSource.asObservable();

    constructor(private http: HttpClient) {}

    getAll() {
        return this.http.get(new Url('/mini-games').path);
    }

    getById(id: number) {
        return this.http.get(new Url('/mini-games/' + id).path);
    }

    create(data: any) {
        return this.http.post(environment.apiUrl + '/mini-games', data);
    }

    update(id: number, data: any) {
        return this.http.put(environment.apiUrl + '/mini-games/' + id, data);
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + '/mini-games/' + id);
    }

    getStudentGames() {
        return this.studentGames;
    }

    getStudentGameById(id: number) {
        return this.studentGames.find((game) => game.id === id);
    }

    closeGame() {
        this.closeGameSource.next();
    }
}
