import { settings } from 'src/environments/environment';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-games',
    templateUrl: './games.component.html',
    styleUrls: ['./games.component.scss'],
    providers: [],
})
export class GamesComponent implements OnInit, AfterViewInit {
    isLoading: boolean = false;
    tabIndex = 0;
    subscriptions: Subscription[] = [];

    constructor(private title: Title, private activateRouter: ActivatedRoute) {}

    ngOnInit() {
        this.title.setTitle(settings.title + 'Games');
    }

    setGamesFromURl() {}

    ngAfterViewInit() {
        this.setGamesFromURl();
    }

    watch() {
        const sliderVideo: any = document.getElementById('game-video');
        sliderVideo.pause();
        let video: any = document.getElementById('locations-video');
        video.play();
    }

    setTabIndex(index: number) {
        const tabCount = 4;
        this.tabIndex = (index + 1) % tabCount;
    }
}
