import { settings, environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { finalize } from 'rxjs/operators';
import { DisciplinesService } from 'src/app/services/disciplines.service';
import { ManageDisciplinesTable } from 'src/app/tables/ManageDisciplinesTable';
import { Tools } from 'src/app/tools';

@Component({
    selector: 'app-manage-disciplines',
    templateUrl: './manage-disciplines.component.html',
    styleUrls: ['./manage-disciplines.component.css'],
})
export class ManageDisciplinesComponent implements OnInit {
    disciplines: any[] = [];
    isLoading: boolean = false;
    storageUrl: string = environment.storageUrl;
    ENTRY_NAME: string = 'Discipline';

    table = new ManageDisciplinesTable();

    constructor(
        private title: Title,
        private disciplinesService: DisciplinesService,
        private notificationService: NotificationService,
        public sessionService: SessionService
    ) {}

    ngOnInit() {
        this.title.setTitle(settings.title + 'Users');
        this.fetch();
    }

    fetch() {
        this.disciplinesService.getAll().subscribe((response: any) => {
            this.disciplines = response.data || [];
        });
    }

    create(data: any) {
        this.disciplinesService
            .create(Tools.getFormData(data))
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' created successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    update(data: any) {
        this.disciplinesService
            .update(data.id, Tools.getFormData(data))
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' updated successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }

    delete(data: any) {
        this.disciplinesService
            .delete(data.id)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.notificationService.success(this.ENTRY_NAME + ' deleted successfully');
                    this.fetch();
                },
                (err) => {
                    this.notificationService.error(err.error.message, 'Error');
                    this.isLoading = false;
                }
            );
    }
}
