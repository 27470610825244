import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-studio-skill-card',
    templateUrl: './studio-skill-card.component.html',
    styleUrls: ['./studio-skill-card.component.scss'],
})
export class StudioSkillCardComponent {
    @Input() card: any;
    storageUrl: string = environment.storageUrl;
}
