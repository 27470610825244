import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { SessionService } from 'src/app/services/session.service';
import { GroupsService } from 'src/app/services/groups.service';
import { GroupsChart } from 'src/app/charts/GroupsChart';
import { map } from 'highcharts';
import { GroupViewTable } from 'src/app/tables/GroupViewTable';

@Component({
    selector: 'app-manage-groups',
    templateUrl: './manage-groups.component.html',
    styleUrls: ['./manage-groups.component.scss'],
})
export class ManageGroupsComponent implements OnInit {
    teachers: any[] = [];
    isLoading: boolean = false;
    isTableView: boolean = false;
    isFullView: boolean = false;
    ENTRY_NAME: string = 'Groups';

    storageUrl: string = environment.storageUrl;
    page: number = 1;

    selectedMentor_1 = 1;
    selectedMentor_2 = 2;
    selectedMentor_3 = 3;

    mentors = [
        {
            name: 'Jonatan',
            id: 1,
        },
        {
            name: 'Miguel',
            id: 2,
        },
        {
            name: 'Ricardo',
            id: 3,
        },
    ];

    users = this.groupsService.getUsers();

    charts: any[] = [];
    tables: any[] = [];

    constructor(
        private groupsService: GroupsService,
        private notificationService: NotificationService,
        public sessionService: SessionService
    ) {}

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.isLoading = true;
        this.groupsService.getGroupsInfo().subscribe((response: any) => {
            this.mapGroupsCharts(response.data);
            this.mapTableData(response.data);
            this.isLoading = false;
        });
    }

    mapGroupsCharts(data: any) {
        this.charts = [];
        data.forEach((chartData: any) => {
            this.charts.push({
                chart: new GroupsChart(this.getChartItem(chartData.chart)),
            });
        });
    }

    getChartItem(chartData: any) {
        return {
            title: '',
            categories: chartData[0].categories,
            series: this.mapSeries(chartData),
        };
    }

    mapSeries(chartData: any) {
        return chartData.map((userChartData: any) => {
            return {
                type: 'area',
                name: userChartData.seriesData.name,
                data: userChartData.seriesData.data,
                pointPlacement: 'on',
            };
        });
    }

    mapTableData(data: any) {
        this.tables = [];

        data.forEach((item: any) => {
            this.tables.push({
                columns: item.chart[0].categories,
                rows: item.table,
                table: new GroupViewTable(),
            });
        });
    }

    setPage(event: any) {
        this.page = event;
    }

    toggleView() {
        this.isTableView = !this.isTableView;
    }

    toggleFullView() {
        this.isFullView = !this.isFullView;
    }

    generateTeams() {
        //TODO call generate teams method
    }
}
