<div
    *ngIf="small && !image"
    class="text-uppercase flex items-center justify-center rounded-full border border-sky-300/50"
    [style.background-color]="color"
    [class.large]="!small"
    [ngClass]="{ '!h-4 !w-4': select, '!h-16 !w-16': extraLarge, 'h-6 w-6': !extraLarge && !select }"
>
    <ng-container *ngIf="icon; else elseBlock">
        <i [ngClass]="icon" class="text-xs mix-blend-plus-lighter"></i>
    </ng-container>
    <ng-template #elseBlock>
        <span class="text-xs text-white/50 mix-blend-plus-lighter">{{ letterName }}</span>
    </ng-template>
</div>

<div
    *ngIf="!small && !image"
    class="text-uppercase flex h-8 w-8 items-center justify-center rounded-full border border-sky-300/50 text-lg"
    [style.background-color]="color"
>
    <ng-container *ngIf="icon; else elseBlock">
        <i [ngClass]="icon" class="text-base mix-blend-plus-lighter"></i>
    </ng-container>
    <ng-template #elseBlock>
        <span class="text-xs text-white/50 mix-blend-plus-lighter">{{ letterName }}</span>
    </ng-template>
</div>

<div
    *ngIf="image && !small"
    class="text-uppercase flex h-8 w-8 items-center justify-center rounded-full border border-sky-300/50 bg-cover bg-center text-lg"
    [style.background-image]="'url(' + storageUrl + image + ')'"
></div>

<div
    *ngIf="image && small"
    class="text-uppercase flex items-center justify-center rounded-full border border-sky-300/50 bg-cover bg-center text-lg"
    [style.background-image]="'url(' + storageUrl + image + ')'"
    [ngClass]="{ '!h-16 !w-16': extraLarge, 'h-6 w-6': !extraLarge }"
></div>
