import { AfterViewInit, Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { AnimationBuilder } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-games-slider',
  templateUrl: './games-slider.component.html',
  styleUrls: ['./games-slider.component.scss']
})
export class GamesSliderComponent implements AfterViewInit {

  mute: boolean = false;
  paused: boolean = false;
  prevSlide: number = 1;

  // @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;

  slides: any[] = [
    {
      id: 1,
      src: '/assets/videos/preview-game.mp4',
      type: 'video',
      selected: true
    },
    {
      id: 2,
      src: '/assets/games-slider/2.jpg',
      type: 'image',
      selected: false
    },
    {
      id: 3,
      src: '/assets/games-slider/3.jpg',
      type: 'image',
      selected: false
    },
    {
      id: 4,
      src: '/assets/games-slider/4.jpg',
      type: 'image',
      selected: false
    },
    {
      id: 5,
      src: '/assets/games-slider/1.jpg',
      type: 'image',
      selected: false
    }
  ];



  constructor(private dialog: MatDialog) { }


  openDialog(dialogRef: TemplateRef<any>) {
    this.dialog.open(dialogRef);

    const myVideo: any = document.getElementById('game-video');
    myVideo.play();
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  playPause() {
    const myVideo: any = document.getElementById('game-video');
    if (myVideo.paused) {
      myVideo.play();
      this.paused = false;

    }
    else if (!myVideo.paused) {
      myVideo.pause();
      this.paused = true;

    }
  }

  ngAfterViewInit() {
    const video: any = document.getElementById('game-video');
    video?.play();
  }

  slideChange(e: any) {

    const myVideo: any = document.getElementById('game-video');

    if (e.id !== 1) {
      myVideo.pause();
      this.paused = true;
      return;
    }

  }

}
