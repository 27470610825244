<div class="flex w-full justify-center mt-3 mb-3">
    <i class="fas fa-chevron-down fa-2x" style="opacity: 0.7"></i>
</div>

<mat-sidenav-container class="team-user-sidebar-container" hasBackdrop="false">
    <mat-sidenav-content>
        <div class="overflow-hidden w-full">
            <div style="min-height: 65vh; overflow-x: hidden">
                <div class="flex mt-5 mb-5">
                    <div class="st-title">
                        <h3>{{ 'communications.section.teams' | translate }}</h3>
                        <span>{{ 'communications.section.our_school' | translate }}</span>
                    </div>
                </div>

                <div class="flex">
                    <div class="col-7">
                        <div class="mb-5">
                            <app-school-squad (teamSelected)="showInfo($event)"></app-school-squad>
                        </div>

                        <div>
                            <app-school-project-calendar></app-school-project-calendar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav-content>

    <mat-sidenav #sidenav mode="over" position="end" [(opened)]="opened">
        <app-school-manage-team [team]="selectedTeam" (closed)="closeInfo()"></app-school-manage-team>
    </mat-sidenav>
</mat-sidenav-container>
