<app-start-screen *ngIf="isDisplayStartScreen" (clickedStart)="start()"></app-start-screen>

<div *ngIf="!isDisplayStartScreen" class="flex flex-col gap-x-4 mx-auto w-full md:flex-row md:w-3/4 quest-container">
    <div class="p-0 md:p-5 w-full">
        <mat-stepper linear #stepper class="bg-transparent w-full rounded-xl">
            <mat-step>
                <app-user-info-form (closed)="skipStep(stepper)"></app-user-info-form>
            </mat-step>
            <mat-step>
                <form [formGroup]="disciplinesFormGroup">
                    <div class="question-wrapper mb-3">
                        <div>
                            <span>{{ disciplineText }}</span>
                        </div>
                        <!-- <div>
                            <span>{{ 'questionnaire.student.disciplines_form_desc' | translate }}</span>
                        </div> -->
                    </div>

                    <!-- <div class="question-wrapper mb-3">
                        <span>{{ 'questionnaire.student.disciplines_form_desc_two' | translate }}</span>
                    </div> -->

                    <div class="mb-4">
                        <ng-container *ngFor="let discipline of disciplines">
                            <div class="rank-row w-full my-3">
                                <span class="text-capitalize">
                                    {{ discipline.name }}
                                </span>

                                <mat-radio-group
                                    class="flex flex-row gap-x-3 md:gap-x-5 items-center justify-center md:justify-start"
                                >
                                    <div *ngFor="let mark of discipline.marks">
                                        <button
                                            class="rate-btn py-2 px-3 md:px-6 duration-300"
                                            [class.active]="selectedDisciplines[discipline.id] === mark"
                                            (click)="setDisciplineInterest(discipline, mark)"
                                        >
                                            <span>{{ mark }}</span>
                                        </button>
                                    </div>
                                </mat-radio-group>
                            </div>
                        </ng-container>
                    </div>

                    <div class="flex justify-end mt-5">
                        <div>
                            <app-un-save-button
                                [text]="'questionnaire.student.info_form_next_button' | translate"
                                (сlicked)="skipStep(stepper)"
                                [disabled]="!disciplinesFormGroup.valid"
                            >
                            </app-un-save-button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <ng-container *ngFor="let group of groups; let last = last">
                <mat-step>
                    <app-dynamic-form
                        [questions]="group.questions"
                        [isLast]="last"
                        (nextClicked)="next($event, stepper, last)"
                        (backClicked)="goBack(stepper)"
                    >
                    </app-dynamic-form>
                </mat-step>
            </ng-container>
        </mat-stepper>
    </div>
</div>
