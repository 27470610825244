<div class="flex w-full">
    <div class="col-10 flex">
        <div>
            <h5 style="font-weight: 600; letter-spacing: 1px">
                <i class="far fa-calendar mr-3"></i>
                {{ date }}
            </h5>
        </div>
        <div class="ml-auto">
            <ng-container *ngFor="let range of ranges">
                <span
                    class="mr-4 date-range-item"
                    [ngClass]="{ underline: range === selectedRange }"
                    (click)="selectRange(range)"
                >
                    {{ range }}
                </span>
            </ng-container>
        </div>
    </div>
</div>

<div class="flex mb-3 w-full">
    <div class="col-10 flex">
        <hr class="w-full" style="height: 3px" />
    </div>
</div>

<div class="flex mb-3 w-full">
    <div class="col-10 flex" style="min-height: 30vh; gap: 30px">
        <div class="flex flex-col justify-around">
            <ng-container *ngFor="let type of types">
                <span
                    class="text-capitalize type cursor-pointer"
                    [style.color]="type === selectedType ? '#ff02d0' : ''"
                    (click)="selectType(type)"
                    >{{ type }}</span
                >
            </ng-container>
        </div>

        <div
            class="flex flex-row align-items-end w-full"
            style="font-weight: 600; letter-spacing: 1px; gap: 4vw"
            [style.zoom]="getZoom()"
        >
            <ng-container *ngFor="let range of timeRange; let i = index">
                <div class="ml-1 chart-series" [style.height]="getFirstBlockHeight()">
                    <div *ngIf="i < 6" class="line position-relative" [style.height]="getLineHeight(i)">
                        <div class="event px-2 py-2 flex justify-between align-self-center">
                            <div class="ml-3" style="font-weight: 400">
                                {{ 'communications.personal_info.connect_team' | translate }}
                            </div>
                            <div class="mr-2"><img class="author" src="/assets/rust.jpg" alt="" /></div>
                        </div>
                    </div>

                    <div class="mt-2 text-nowrap">
                        <span>{{ range }}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
