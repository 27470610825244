import { Component, Output, EventEmitter, OnInit, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DATE_FORMATS_CUSTOM } from 'src/app/app.component';

export type DateChangedEvent = {
    begin?: string;
    end?: string;
};

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.css'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS_CUSTOM },
    ],
})
export class DateRangePickerComponent implements OnInit, OnChanges {
    @Input() defaultBegin: any;
    @Input() defaultEnd: any;
    @Input() submitByButton: boolean = false;
    @Input() disabled: boolean = false;
    @Input() label: string = '';

    begin: null;
    end: null;

    @Output() dateChanged: EventEmitter<any> = new EventEmitter<any>();

    constructor(private ref: ChangeDetectorRef) {}

    ngOnInit() {
        this.begin = this.defaultBegin || null;
        this.end = this.defaultEnd || null;
    }

    ngOnChanges() {
        this.begin = this.defaultBegin || null;
        this.end = this.defaultEnd || null;
        this.ref.detectChanges();
    }

    update(type: string) {
        if (this.submitByButton && type === 'input') {
            return;
        }
        this.dateChanged.emit({ begin: this.begin, end: this.end });
    }
}
