import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-card',
    template: `
        <div
            class="relative overflow-hidden rounded-lg bg-custom-gradient shadow-xs print:text-xxs print:shadow-none"
            [ngClass]="{
                'px-3': paddingX,
                'py-3': paddingY,
                'h-full': isExpanded,
                'border-gradient': gradientBorder,
                'border border-[#EDF2FF19]': !gradientBorder
            }"
        >
            <div class="flex justify-between">
                <div *ngIf="title" class="mb-3 text-xl font-medium capitalize" [ngClass]="{ 'px-3': !paddingX }">
                    {{ title }}
                </div>

                <button *ngIf="expandable" (click)="toggleCard()" mat-button class="flex items-center px-2 py-1">
                    <div class="flex h-full items-center">
                        <i
                            [ngClass]="{ 'ph-caret-circle-down': isExpanded, 'ph-caret-circle-up': !isExpanded }"
                            class="ph ph-caret-circle-down text-xl"
                        ></i>
                    </div>
                </button>
            </div>
            <div *ngIf="isExpanded">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
            }
            .border-gradient::before {
                content: '';
                position: absolute;
                inset: 0;
                border-radius: 0.5rem;
                padding: 2px;
                background: linear-gradient(45deg, #cf88c2, #966fff);
                -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                z-index: -1;
            }
        `,
    ],
})
export class CardComponent {
    @Input() paddingX: boolean = true;
    @Input() paddingY: boolean = true;
    @Input() title?: string;
    @Input() expandable?: boolean = false;
    @Input() gradientBorder?: boolean = false;

    isExpanded: boolean = true;

    toggleCard() {
        this.isExpanded = !this.isExpanded;
    }
}
