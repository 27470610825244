import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NotificationService } from 'src/app/services/notification.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FriendsService } from 'src/app/services/friends.service';
import { SessionService } from 'src/app/services/session.service';
import { Tools } from 'src/app/tools';
import { environment } from 'src/environments/environment';

type Friend = {
    name?: string;
    image_path: string;
    nickname: string;
    main_image: string;
};

@Component({
    selector: 'app-friends-requests-list',
    templateUrl: './friends-requests-list.component.html',
    styleUrls: ['./friends-requests-list.component.scss'],
})
export class FriendsRequestsListComponent {
    @Input() outer: boolean = true;
    @Output() userClicked: EventEmitter<any> = new EventEmitter();

    storageUrl: string = environment.storageUrl;
    friends: Friend[] = [];

    received: any[] = [];
    sent: any[] = [];
    selectedRequests: any[] = [];

    blankImageProfilePath: string = '/assets/blank-profile.png';
    chatUrl: string = environment.chatUrl;
    chatFunc: string = 'direct/';

    subscriptions: Subscription[] = [];

    isLoading: boolean = false;
    isSent: boolean = false;

    constructor(
        public session: SessionService,
        private notificationService: NotificationService,
        private friendsService: FriendsService
    ) {
        this.subscriptions.push(
            this.friendsService.searchFriends$.subscribe((search: string) => {
                this.search(search);
            })
        );
    }

    ngOnInit() {
        this.fetch();
    }

    fetch() {
        this.isLoading = true;
        this.friends = [];
        this.friendsService
            .getFriendsRequests()
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (response: any) => {
                    this.sent = this.mapData(response.data.sent);
                    this.received = this.mapData(response.data.received);
                    this.selectedRequests = this.received;
                },
                () => {
                    this.notificationService.error('Something goes wrong');
                }
            );
    }

    slideChanged(event: MatSlideToggleChange) {
        console.log(event.checked);
        this.selectedRequests = !event.checked ? this.received : this.sent;
        this.isSent = event.checked;
    }

    mapData(data: any) {
        return data.map((friend: Friend) => ({ ...friend, image_path: this.getImagePath(friend) }));
    }

    getImagePath(friend: Friend): string {
        return friend.main_image ? this.storageUrl + friend.main_image : this.blankImageProfilePath;
    }

    onKey(event: any) {
        this.selectedRequests = this._filterUsers(event.target.value).filter((n) => n);
    }

    goToChat(nickname: string) {
        if (this.outer) {
            window.open(this.chatUrl + this.chatFunc + nickname, '_blank');
            return;
        }

        this.userClicked.emit(nickname);
    }

    private _filterUsers(value: string): any[] {
        const list: any[] = [];
        const input = value ? value.toLowerCase() : '';
        this.friends.forEach((element, key) => {
            if (!input || (input && this.friends[key].nickname.toLowerCase().indexOf(input) > -1)) {
                list[key] = this.friends[key];
            }
        });
        return list;
    }

    search(search: string): void {
        this.selectedRequests = this._filterUsers(search).filter((n) => n);
    }

    sumbitFriendsRequest(userId: number, status: string) {
        const data = {
            friend_id: userId,
            status: status,
        };

        this.friendsService.sumbitFriendsRequest(Tools.getFormData(data)).subscribe(
            () => {
                this.fetch();
                this.notificationService.success('Request status updated');
            },
            () => {
                this.notificationService.error('Something goes wrong');
            }
        );
    }
}
