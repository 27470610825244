import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TextDirectionController } from 'src/app/classes/TextDirectionController';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-task-students',
    templateUrl: './task-students.component.html',
    styleUrls: ['./task-students.component.scss'],
})
export class TaskStudentsComponent {
    @Input() users: any[] = [];
    @Input() limit: number = 3;

    @Output() usersUpdated: EventEmitter<any> = new EventEmitter();

    storageUrl: string = environment.storageUrl;
    defaultImageUrl: string = '/assets/blank-profile.png';

    public directionController = new TextDirectionController();

    constructor(private dialog: MatDialog) {}

    get previewUsers() {
        return this.users ? this.users.slice(0, this.limit) : [];
    }

    get assignedUsers() {
        return this.users.filter((user) => user.checked);
    }

    get assignedUsersPreview() {
        return this.assignedUsers.slice(0, this.limit);
    }

    get dir() {
        return this.directionController.textDirection;
    }

    openModal(dialogRef: TemplateRef<any>) {
        this.dialog.open(dialogRef, { autoFocus: false });
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }

    selectStudent(user: any) {
        user['checked'] = !user['checked'];
    }

    getSelectedIds() {
        return this.users.filter((user) => user.checked).map((user) => user.id);
    }

    submit() {
        this.usersUpdated.emit(this.getSelectedIds());
        this.closeDialog();
    }

    getTranslate(i: number) {
        return i * 8 * (this.dir === 'ltr' ? -1 : 1);
    }
}
