<div *ngIf="isLoading" class="flex h-screen items-center justify-center">
    <app-un-game-loader></app-un-game-loader>
</div>
<div *ngIf="hasError">An error occurred. Please try again later.</div>

<div *ngIf="!isLoading && !hasError">
    <!-- Welcome screen -->
    <div *ngIf="showWelcome" class="h-full w-full px-8 py-24 sm:px-0 md:px-4 md:py-16">
        <!-- Welcome content (same as your current content) -->
        <div class="mb-5 flex flex-col justify-center gap-4 md:flex-row">
            <div class="flex w-full flex-col items-start text-start md:w-1/4">
                <h1 class="text-6xl leading-none">
                    <span>DEAR</span>
                    <img
                        src="/assets/uni-love.png"
                        alt="Waving Robot"
                        class="ml-2 inline-block h-24 w-24 align-middle sm:h-16 sm:w-16 md:h-20 md:w-20"
                    />
                    <div>FRIEND!</div>
                </h1>
            </div>

            <div class="w-full text-start md:w-1/2">
                <h3 class="text-pink mb-2 text-3xl">
                    You have some surveys to complete. Please take a moment to begin.
                </h3>
                <div class="mb-4 text-base text-gray-400">
                    <p class="mb-3">We value your feedback and appreciate your time in participating.</p>
                    <p class="mb-3">Your responses will help us improve our services.</p>
                </div>
            </div>
        </div>

        <div class="flex flex-col justify-center gap-4 md:flex-row">
            <div class="w-full md:w-1/4">
                <div class="my-4 w-40 border-t-4 border-un-pink"></div>
            </div>
            <div class="flex w-full justify-end py-4 md:w-1/2 md:py-0">
                <app-un-save-button text="Start Survey" (click)="start()"></app-un-save-button>
            </div>
        </div>
    </div>

    <!-- Survey Questions Section -->
    <div *ngIf="!showWelcome && !showEndScreen && currentTarget" class="mt-3 w-full xl:px-20 2xl:px-52">
        <h1 class="mb-6 text-center text-4xl">
            {{ eligibleSurveys[currentSurveyIndex]?.name_en || 'Survey' }}
        </h1>

        <div class="flex items-center gap-4">
            <app-un-avatar
                [image]="currentTarget.main_image"
                extraLarge="true"
                [name]="currentTarget.nickname"
            ></app-un-avatar>
            <div>
                <p class="text-2xl font-bold text-white">
                    <span class="text-un-pink">{{ currentTarget.nickname }}</span> | Survey
                </p>
            </div>
        </div>

        <div *ngFor="let question of surveyQuestions" class="mb-4">
            <!-- Question Text -->
            <div class="mt-5 w-full rounded-md bg-[#08051F] p-3 text-white">
                <h3 class="text-xl font-bold">{{ question.text }}</h3>
            </div>

            <!-- Score Rating (Horizontal, w-full) -->
            <div
                *ngIf="question.max_score && question.options.length === 0"
                class="mt-3 flex w-full flex-row justify-start gap-4"
            >
                <div *ngFor="let score of [].constructor(question.max_score); let idx = index" class="w-full">
                    <input
                        type="radio"
                        name="score-{{ question.id }}"
                        id="score-{{ question.id }}-{{ idx }}"
                        [value]="idx + 1"
                        class="peer hidden"
                        (change)="setAnswer(question.id, idx + 1)"
                    />
                    <label
                        for="score-{{ question.id }}-{{ idx }}"
                        class="flex w-full cursor-pointer rounded-md border border-un-pink bg-[#9A92DC26]/15 p-3 text-center uppercase text-white transition-all duration-150 peer-checked:bg-un-pink"
                    >
                        {{ idx + 1 }}
                    </label>
                </div>
            </div>

            <!-- Options (Vertical) -->
            <div *ngIf="question.options.length > 0" class="mt-3 flex w-full flex-col gap-y-4">
                <div *ngFor="let option of question.options" class="w-full">
                    <input
                        type="radio"
                        name="option-{{ question.id }}"
                        id="option-{{ question.id }}-{{ option }}"
                        [value]="option"
                        class="peer hidden"
                        (change)="setAnswer(question.id, option)"
                        required
                    />
                    <label
                        for="option-{{ question.id }}-{{ option }}"
                        class="flex w-full cursor-pointer rounded-md border border-un-pink bg-[#9A92DC26]/15 p-3 text-center uppercase text-white transition-all duration-150 peer-checked:bg-un-pink"
                    >
                        {{ option }}
                    </label>
                </div>
            </div>
        </div>

        <!-- Next Button, only visible if all questions are answered -->
        <div class="mt-3 flex justify-end">
            <app-un-save-button
                text="Next"
                (click)="moveToNextTarget()"
                *ngIf="areAllQuestionsAnswered()"
            ></app-un-save-button>
        </div>
    </div>

    <!-- End Screen -->
    <div *ngIf="showEndScreen" class="h-full w-full px-8 py-24 sm:px-0 md:px-4 md:py-16">
        <div class="mb-5 flex flex-col justify-center gap-4 md:flex-row">
            <div class="flex w-full flex-col items-start text-start md:w-1/4">
                <h1 class="text-6xl leading-none">
                    <span>THANK</span>
                    <img
                        src="/assets/uni-love.png"
                        alt="Waving Robot"
                        class="ml-2 inline-block h-24 w-24 align-middle sm:h-16 sm:w-16 md:h-20 md:w-20"
                    />
                    <div>YOU!</div>
                </h1>
            </div>

            <div class="w-full text-start md:w-1/2">
                <h3 class="text-pink mb-2 text-3xl">You have completed all the surveys.</h3>
                <div class="mb-4 text-base text-gray-400">
                    <p class="mb-3">We appreciate your time and valuable feedback.</p>
                    <p class="mb-3">Your responses will help us improve our platform and services.</p>
                </div>
            </div>
        </div>

        <div class="flex flex-col justify-center gap-4 md:flex-row">
            <div class="w-full md:w-1/4">
                <div class="my-4 w-40 border-t-4 border-un-pink"></div>
            </div>
            <div class="flex w-full justify-end py-4 md:w-1/2 md:py-0">
                <app-un-save-button 
                    *ngIf="ableStartPlatform"
                    text="Proceed to Platform" 
                    (click)="proceedToPlatform()"
                ></app-un-save-button>
            </div>
        </div>
    </div>
</div>
