<div class="flex gap-4">
    <button class="border-gradient border-gradient-purple h-48 w-60 px-4 py-2">test 1</button>
    <div class="box h-48 w-60 px-4 py-3">test 3</div>
</div>

<div class="mt-4 grid grid-cols-1 gap-4 xl:grid-cols-12">
    <app-card class="col-span-6" [paddingX]="false" [paddingY]="false" [gradientBorder]="true">
        <div class="p-2">
            <img
                class="h-[160px] w-full max-w-full rounded-lg object-cover"
                src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg"
                alt=""
            />
            <div class="mt-3 flex flex-col gap-2">
                <span>gradient border</span>
                <span class="text-[#8E91D1]">​See what learning & gaming events are on the horizon...</span>
            </div>
        </div>
    </app-card>

    <app-card class="col-span-6" [paddingX]="false" [paddingY]="false">
        <div class="p-2">
            <img
                class="h-[160px] w-full max-w-full rounded-lg object-cover"
                src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg"
                alt=""
            />
            <div class="mt-3 flex flex-col gap-2">
                <span>no gradient border</span>
                <span class="text-[#8E91D1]">​See what learning & gaming events are on the horizon...</span>
            </div>
        </div>
    </app-card>

    <div class="col-span-4 mt-4">
        <app-card class="" [title]="'Projects/teams'">
            <div class="">
                <div class="p-4">test 2</div>
            </div>

            <app-un-back-button></app-un-back-button>
            <app-un-create-button></app-un-create-button>
            <app-un-header-button text="TEST"></app-un-header-button>
            <app-un-magic-button text="TEST"></app-un-magic-button>
            <app-un-save-button text="Proceed"></app-un-save-button>

            <app-progress-bar></app-progress-bar>
            <app-progress-slide></app-progress-slide>

            <app-card>
                <app-progress-slider-round class="mt-5" [steps]="[19, 55]" rate="50"></app-progress-slider-round>
            </app-card>

            <div class="mt-4">
                <app-un-proceed-button text="Proceed"></app-un-proceed-button>
            </div>

            <!--Avatar with name and activity-->
            <div class="flex items-center gap-4">
                <app-un-avatar
                    [name]="'Nickname'"
                    [image]="'users/23/avatars/a4ce89ba5e5d187ed0f94f0d20a7005d.gif'"
                    extraLarge="true"
                ></app-un-avatar>
                <div>
                    <p
                        class="text-blue-gray-900 mb-0.5 block font-sans text-base !font-semibold font-light leading-relaxed antialiased"
                    >
                        Nickname
                    </p>
                    <p class="block font-sans text-sm font-normal leading-normal text-gray-700 antialiased">activity</p>
                </div>
            </div>

            <div class="mt-4 w-full">
                <app-un-avatar
                    class="!h-52 !w-56"
                    [name]="'TEST WGEGWEG'"
                    [color]="'Red'"
                    [icon]=""
                    [image]=""
                    [ngClass]="'!h-'"
                ></app-un-avatar>
            </div>
        </app-card>
    </div>
</div>
