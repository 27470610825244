import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MiniGamesService } from 'src/app/services/mini-games.service';
import { environment } from 'src/environments/environment';

const ANIMATION_DURATION = 400;

@Component({
    selector: 'app-students-games',
    templateUrl: './students-games.component.html',
    styleUrls: ['./students-games.component.scss'],
    animations: [
        trigger('flyRigthIn', [
            state('in', style({ opacity: 1, transform: 'translateX(0)' })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateX(100%)' }),
                animate(ANIMATION_DURATION),
            ]),
            transition('* => void', [
                animate(ANIMATION_DURATION, style({ opacity: 0, transform: 'translateX(-100%)' })),
            ]),
        ]),
    ],
})
export class StudentsGamesComponent {
    isLoading: boolean = false;
    isGameLoaded: boolean = false;

    selectedGame: any;

    games: any[] = [];
    storageUrl: string = environment.storageUrl;

    testGames: any[] = this.miniGamesService.getStudentGames();

    constructor(private miniGamesService: MiniGamesService, public dialog: MatDialog) {}
}
