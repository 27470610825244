import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SessionService } from '../../../services/session.service';

@Component({
    selector: 'app-surveys-manager',
    templateUrl: './surveys-manager.component.html',
    styleUrls: ['./surveys-manager.component.scss'],
})
export class SurveysManagerComponent implements OnInit {
    tabs = [
        {
            name: 'Edit Surveys',
            active: false,
            selector: 'edit-surveys',
            visible: true,
        },
        {
            name: 'Answers',
            active: false,
            selector: 'surveys-answers',
            visible: true,
        },
    ];

    activeTab: any;

    votesTopics: any[] = [];
    isLoading: boolean = true;
    storageUrl: string = environment.storageUrl;
    panelOpenState = false;

    constructor(public sessionService: SessionService) {}

    ngOnInit() {
        // this.fetch();
    }

    get schoolName() {
        return this.sessionService.getCurrentUserSchool() ? this.sessionService.getCurrentUserSchool().name : '';
    }

    setTab(tab: any) {
        this.activeTab = tab;
    }
}
