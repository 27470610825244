<app-start-screen *ngIf="isDisplayStartScreen" (clickedStart)="start()"></app-start-screen>

<div *ngIf="!isDisplayStartScreen" class="container">
    <div class="px-2 py-4">
        <mat-stepper linear #stepper style="background: transparent; width: 100%; border-radius: 14px">
            <mat-step [stepControl]="infoFormGroup">
                <div class="flex justify-between pt-4">
                    <div class="col-5 pl-5">
                        <h3 class="text-uppercase mb-2 hi-image">
                            {{ 'questionnaire.teacher.create_profile_header' | translate }}
                        </h3>

                        <div class="text-mute mb-4">
                            <div class="mb-3">
                                <span>{{ 'questionnaire.teacher.create_profile_desc' | translate }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-5 p-5 main-card">
                        <div class="mb-4">
                            <app-user-avatar-selector></app-user-avatar-selector>
                        </div>

                        <form [formGroup]="infoFormGroup">
                            <div class="mb-4">
                                <mat-form-field class="w-full">
                                    <mat-label>
                                        {{ 'questionnaire.teacher.info_form_name_placeholder' | translate }}
                                    </mat-label>
                                    <input matInput formControlName="name" required />
                                </mat-form-field>
                            </div>
                        </form>

                        <div class="flex justify-center mx-auto">
                            <app-un-save-button
                                [text]="'questionnaire.teacher.info_form_next_button' | translate"
                                (сlicked)="next(stepper)"
                                [disabled]="!infoFormGroup.valid"
                            ></app-un-save-button>
                        </div>
                    </div>
                </div>
            </mat-step>

            <mat-step [stepControl]="firstFormGroup">
                <form [formGroup]="firstFormGroup">
                    <div class="col-lg-12 col-xs-12 mb-4">
                        <div class="question-wrapper mb-3">
                            <span>{{ 'questionnaire.teacher.topics_form_major_discipline' | translate }}</span>
                        </div>

                        <mat-form-field class="w-full">
                            <mat-select formControlName="majorDiscipline">
                                <ng-container *ngFor="let discipline of disciplinesArr">
                                    <mat-option [value]="discipline.id">{{ discipline.name }}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="question-wrapper mb-3">
                        <span>{{ 'questionnaire.teacher.topics_form_disciplines_expertise' | translate }}</span>
                    </div>

                    <div class="col-lg-12 col-xs-12 mb-4">
                        <mat-form-field class="w-full" hintLabel="Max {{ textInputLimit }} characters">
                            <input
                                matInput
                                #disciplineExpertiseInput
                                formControlName="disciplineExpertise"
                                [maxlength]="textInputLimit"
                                required
                            />
                            <mat-hint align="end">
                                {{ disciplineExpertiseInput.value.length || 0 }}/{{ textInputLimit }}
                            </mat-hint>
                        </mat-form-field>
                    </div>

                    <div class="flex justify-between mt-5">
                        <div class="mb-3">
                            <app-un-back-button (сlicked)="goBack(stepper)"></app-un-back-button>
                        </div>
                        <div>
                            <app-un-save-button
                                [text]="'questionnaire.teacher.info_form_next_button' | translate"
                                (сlicked)="next(stepper)"
                                [disabled]="!firstFormGroup.valid"
                            ></app-un-save-button>
                        </div>
                    </div>
                </form>
            </mat-step>

            <mat-step [stepControl]="thirdFormGroup">
                <form [formGroup]="thirdFormGroup">
                    <div class="question-wrapper mb-3">
                        <span>{{ 'questionnaire.teacher.topics_form_interests' | translate }}</span>
                    </div>

                    <div class="col-lg-12 col-xs-12 mb-4">
                        <mat-form-field class="w-full" hintLabel="Max {{ textAreaLimit }} characters">
                            <textarea
                                matInput
                                #interestsAndHobbiesArea
                                formControlName="interestsAndHobbies"
                                [maxLength]="textAreaLimit"
                                required
                            ></textarea>
                            <mat-hint align="end">
                                {{ interestsAndHobbiesArea.value.length || 0 }}/{{ textInputLimit }}
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </form>

                <div class="flex justify-between mt-5">
                    <div class="mb-3">
                        <app-un-back-button (сlicked)="goBack(stepper)"></app-un-back-button>
                    </div>
                    <div>
                        <app-un-save-button
                            [text]="'questionnaire.teacher.form_button_submit' | translate"
                            (сlicked)="submit()"
                            [disabled]="!thirdFormGroup.valid"
                        ></app-un-save-button>
                    </div>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
</div>
