<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div *ngFor="let question of questions" class="form-row">
        <app-question class="w-full" [question]="question" [form]="form"></app-question>
    </div>

    <div class="flex justify-between mt-5">
        <div class="mb-3">
            <app-un-back-button (сlicked)="back()"></app-un-back-button>
        </div>
        <div>
            <app-un-save-button [text]="buttonText | translate" [disabled]="!form.valid" (сlicked)="next()">
            </app-un-save-button>
        </div>
    </div>
</form>
