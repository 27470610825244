import { Component, Input, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { UserGroupSubjectInterestsChart } from 'src/app/charts/UserGroupSubjectInterestsChart';

@Component({
    selector: 'app-team-chart',
    templateUrl: './team-chart.component.html',
    styleUrls: ['./team-chart.component.scss'],
    providers: [],
})
export class TeamChartComponent implements OnInit {
    @Input() chart: any;

    data: any;
    isLoading: boolean = true;
    user: any | undefined;

    colors = ['#FF02D0', '#FF9900', '#00A3FF', '#7B61FF', '#B6A8FF', '#00A3FF', '#FF02D0', '#FF9900'];

    categories = {
        eng: ['Biology', 'Chemistry', 'Physics', 'History', 'Art', 'Literature', 'Tanah', 'Israel culture'],
        heb: ['ביולוגיה', 'כִּימִיָה', 'פיזיקה', 'הִיסטוֹרִיָה', 'אומנות', 'סִפְרוּת', 'תנא', 'תרבות ישראל'],
    };

    chartData = {
        title: 'Skill Breakdown',
        categories: this.categories.eng,
        series: {
            name: '',
            data: [0, 0, 0, 0, 0, 0, 0, 0],
        },
    };

    chartGroupData = {
        title: 'Skill Breakdown by Group',
        categories: this.categories.eng,
        series: [],
    };

    groupChart = new UserGroupSubjectInterestsChart();

    constructor(
        private sessionService: SessionService,
        public dialog: MatDialog,
    ) {}

    ngOnInit() {
        this.getUser();
    }

    ngOnChanges() {
        const chart = this.chart ? this.chart : { categories: [], seriesData: [] };
        this.groupChart.build(chart, this.colors);
    }

    getUser() {
        this.user = this.sessionService.getCurrentUser();
    }
}
