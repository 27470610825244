import { Table } from './Table';
import * as moment from 'moment';

export class UsersTable extends Table {
    constructor() {
        super();
        this.buildColumns();
    }

    buildColumns() {
        this.columns = [
            {
                name: 'ID',
                selector: 'id',
            },
            {
                name: 'Nickname',
                selector: 'nickname',
                class: () => 'text-nowrap',
            },
            {
                name: 'Email',
                selector: 'email',
            },
            {
                name: 'Project',
                selector: 'projects',
                trustHtml: true,
                class: () => 'flex flex-wrap gap-1',
                transform: (projects) =>
                    projects
                        .map(
                            (project) =>
                                '<span class="tag bg-purple-600 mx-2 truncate max-w-[120px]">' +
                                project.title +
                                '</span>',
                        )
                        .join(''),
            },
            {
                name: 'Role',
                selector: 'role',
            },
            {
                name: 'Birthday',
                selector: 'birthday',
                transform: (birthday) => moment(birthday).format('DD.MM.YYYY'),
            },
            {
                name: 'Mentor',
                selector: 'mentor',
                headerClass: 'text-nowrap text-center',
                class: () => 'text-center',
                transform: (active) =>
                    active ? '<i class="ph ph-circle text-green"></i>' : '<i class="ph ph-circle text-red"></i>',
            },
            {
                name: 'Questionnaire',
                selector: 'questionnaire',
                headerClass: 'text-nowrap text-center',
                class: () => 'text-center',
                transform: (active) =>
                    active ? '<i class="ph ph-circle text-green"></i>' : '<i class="ph ph-circle text-red"></i>',
            },
        ];
    }

    buildForm(projects): void {
        this.form = {
            title: () => '',
            elements: [
                {
                    name: 'ID',
                    selector: 'id',
                    type: 'text',
                    visible: false,
                },
                {
                    name: 'Select project',
                    selector: 'projects',
                    class: 'col-span-12',
                    type: 'checkboxes',
                    options: projects,
                    optionValueSelector: 'id',
                    optionTextSelector: 'title',
                },
                {
                    name: 'Nickname',
                    selector: 'nickname',
                    class: 'col-span-12',
                    type: 'text',
                },
                {
                    name: 'Mentor',
                    selector: 'mentor',
                    checkboxLabel: 'Assign user to a mentor ',
                    defaultValue: false,
                    class: 'col-span-12',
                    type: 'checkbox',
                },
            ],
        };
    }
}
