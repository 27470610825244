import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-un-change-mentor',
    templateUrl: './un-change-mentor.component.html',
    styleUrls: ['./un-change-mentor.component.scss'],
})
export class UnChangeMentorComponent {
    @Input() users: any[] = [];

    @Output() mentorsChanged: EventEmitter<any> = new EventEmitter<any>();

    toggleMentor(user: any): void {
        user['checked'] = !user['checked'];
    }

    submit(): void {
        this.mentorsChanged.emit(this.users.filter((user) => user.checked).map((user) => user.id));
    }
}
