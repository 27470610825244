import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
    isLoading: boolean = true;

    user: any;

    constructor(private route: ActivatedRoute, private usersService: UsersService) {}

    ngOnInit() {
        const userId = this.route.snapshot.paramMap.get('id');

        if (userId) {
            this.fetch(parseInt(userId));
        }
    }

    fetch(userId: number) {
        this.usersService.getUser(userId).subscribe((response: any) => {
            this.user = response.data;
        });
    }
}
